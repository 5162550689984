/** @typedef {ReturnType<typeof DateTime>} DateTime */

// NOTE: when operating with .set (.offset), be aware that offsetting by a unit
// such as month, which is not the same from month-to-month, will end up with
// drift. E.g. August 31 less one month is July 30, and adding the one month
// back will get you August 30.

const Luxon = require('./vendor/luxon-business-days')
const HolidayMatchers = require('./holidayMatchers')

const WEEKDAYS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
]

/**
 * Parse a date time string in the specified time zone. The following formats are accepted:
 *
 * YYYY-MM-DDTHH:mm:ss.uuuZ
 * YYYY-MM-DD
 * YYYY-MM-DD HH:mm(:ss)?
 * YYYY-MM-DD (h|hh):mm(:ss)? am|pm
 *
 * Where meridians are case insensitive.
 *
 * @param {string} str Formatted string
 * @param {Object} [options]
 * @param {string} [options.timeZone] Defaults to UTC
 * @param {string} [options.serviceCity] Defaults to null
 * @return {ReturnType<typeof DateTime>}
 */
DateTime.parse = (str, { timeZone = 'UTC', serviceCity = null } = {}) => {
  // YYYY-MM-DDTHH:mm:ss.uuuZ
  const utc = /^(?<YYYY>\d{4})-(?<MM>0[1-9]|1[0-2])-(?<DD>0[1-9]|[1-2][0-9]|3[0-1])T(?<HH>0[0-9]|1[0-9]|2[0-4]):(?<Mm>[0-5][0-9]):(?<ss>[0-5][0-9])\.(?<uuu>[0-9]{3})Z$/u.exec(str)
  // YYYY-MM-DD
  const dateOnly = /^(?<YYYY>\d{4})-(?<MM>0[1-9]|1[0-2])-(?<DD>0[1-9]|[1-2][0-9]|3[0-1])$/u.exec(str)
  // YYYY-MM-DD HH:mm(:ss)?
  const hour24 = /^(?<YYYY>\d{4})-(?<MM>0[1-9]|1[0-2])-(?<DD>0[1-9]|[1-2][0-9]|3[0-1]) (?<HH>0[0-9]|1[0-9]|2[0-4]):(?<mm>[0-5][0-9])(:(?<ss>[0-5][0-9]))?$/u.exec(str)
  // YYYY-MM-DD (h|hh):mm(:ss) a
  const hour12 = /^(?<YYYY>\d{4})-(?<MM>0[1-9]|1[0-2])-(?<DD>0[1-9]|[1-2][0-9]|3[0-1]) (?<h>0?[1-9]|1[0-2]):(?<mm>[0-5][0-9])(:(?<ss>[0-5][0-9]))? (?<a>AM|PM)$/ui.exec(str)

  if (utc) {
    return DateTime(new Date(str), { timeZone, serviceCity })
  }

  const m = dateOnly || hour24 || hour12

  if (m) {
    let { groups: { YYYY, MM, DD, HH, h = '0', mm = '0', ss = '0', a = 'am' } } = m

    YYYY = parseInt(YYYY, 10)
    MM = parseInt(MM, 10)
    DD = parseInt(DD, 10)
    HH = parseInt(HH, 10)
    h = parseInt(h, 10)
    mm = parseInt(mm, 10)
    ss = parseInt(ss, 10)

    const daysInMonth = new Date(YYYY, MM, 0).getDate(0)

    if (DD > daysInMonth) {
      throw new Error('Invalid date time')
    }

    return DateTime(
      Luxon.DateTime.fromObject({
        year: YYYY,
        month: MM,
        day: DD,
        hour: isNaN(HH) ? (a.toLowerCase() === 'pm' && h < 12 ? h + 12 : h) : HH,
        minute: mm,
        second: ss
      }, {
        zone: timeZone
      }).toJSDate(),
      { timeZone, serviceCity }
    )
  }

  throw new Error('Invalid date time')
}

/**
 * Create a DateTime instance from an object of date and time components, in the
 * specified time zone.
 *
 * @param {Object} obj Date and time components
 * @param {number} obj.year Full year
 * @param {number} obj.month Month (0-11)
 * @param {number} obj.day Day of the month (1-31)
 * @param {number} obj.hour Hour (0-23)
 * @param {number} obj.minute Minute (0-59)
 * @param {number} obj.second Second (0-59)
 * @param {Object} [options]
 * @param {string} [options.timeZone] Defaults to UTC
 * @param {string} [options.serviceCity] Defaults to null
 * @return {ReturnType<typeof DateTime>}
 */
DateTime.fromObject = (obj, { timeZone = 'UTC', serviceCity = null } = {}) => {
  const {
    year,
    month,
    day,
    hour,
    minute,
    second
  } = obj

  return DateTime(
    Luxon.DateTime.fromObject({
      year,
      month: month + 1,
      day,
      hour,
      minute,
      second
    }, {
      zone: timeZone
    }).toJSDate(),
    { timeZone, serviceCity }
  )
}

/**
 * Factory that creates a DateTime object from Date or another DateTime object
 * and optionally converts to a time zone. All properties and set()/offset()
 * methods will reflect and operate within the specified time zone.
 *
 * @param {Date|Object} [dateOrDateTime] Defaults to now
 * @param {Object} [options]
 * @param {string} [options.timeZone] The time zone name from the IANA time zone DB (i.e. UTC, America/Toronto) [default null (i.e. system time zone)]
 * @param {string} [options.serviceCity] The service city for which to draw holiday matchers for
 */
function DateTime (dateOrDateTime = new Date(), { timeZone = null, serviceCity = null } = {}) {
  let date = typeof ({ ...dateOrDateTime }).toDate === 'function'
    ? dateOrDateTime.toDate()
    : dateOrDateTime

  if (!(date instanceof Date) || isNaN(date.getTime())) {
    throw new Error('Date is invalid :: ' + date)
  }

  const opts = {
    weekday: 'long',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    hour12: false,
    minute: 'numeric',
    second: 'numeric'
    // NOTE (dschnare): This is only supported in Node 13.0+ (or evergreen web browsers)
    // fractionalSecondDigits: 3
  }

  if (typeof timeZone === 'string' && timeZone.trim()) {
    opts.timeZone = timeZone
  } else if (timeZone !== null) {
    throw new Error('Time zone is invalid')
  }

  const holidayMatchers = serviceCity !== null && serviceCity in HolidayMatchers
    ? HolidayMatchers[serviceCity]
    : []

  // https://github.com/amaidah/luxon-business-days/blob/master/src/index.js#L68
  Luxon.DateTime.prototype.holidayMatchers = holidayMatchers

  // NOTE (dschnare): Node prior to 13.0.0 will ignore all locales
  // and silently use en-US. Just keep this in mind is all. This doesn't
  // affect our processing below.
  const format = new Intl.DateTimeFormat('en-CA', opts)
  date = new Date(date)
  const parts = format.formatToParts(date)

  const year = parseInt(parts.find((x) => x.type === 'year').value, 10)
  const month = parseInt(parts.find((x) => x.type === 'month').value, 10) - 1
  const day = parseInt(parts.find((x) => x.type === 'day').value, 10)
  const weekday = WEEKDAYS.indexOf(parts.find((x) => x.type === 'weekday').value)
  let hour = parseInt(parts.find((x) => x.type === 'hour').value, 10)
  const minute = parseInt(parts.find((x) => x.type === 'minute').value, 10)
  const second = parseInt(parts.find((x) => x.type === 'second').value, 10)

  // NOTE: After updating to Node14 getting the hour component for a date like new Date(2021, 8, 1)
  // returns 24 instead of 0.
  hour = hour === 24 ? 0 : hour

  return Object.freeze({
    year,
    month,
    /** Day of the month */
    day,
    weekday,
    hour,
    minute,
    second,
    /** @type {string?} The time zone name as from the IANA time zone DB. If null then local system time. */
    timeZone,
    unixEpoch: Math.round(date.getTime() / 1000),
    isSameDay (dateOrDateTime) {
      return DateTime(dateOrDateTime, { timeZone, serviceCity }).startOfDay().equals(this.startOfDay())
    },
    equals (dateOrDateTime) {
      const d = (typeof { ...dateOrDateTime }.toDate === 'function')
        ? dateOrDateTime.toDate()
        : dateOrDateTime

      return d instanceof Date && date.getTime() === d.getTime()
    },
    startOfDay () {
      return this.set({ hour: 0, minute: 0, second: 0 })
    },
    endOfDay () {
      return this.set({ hour: 23, minute: 59, second: 59 })
    },
    startOfMonth () {
      return this.set({ day: 1 })
    },
    endOfMonth () {
      // TODO: Try to find a way to use .set() or .offset() for this somehow.

      const d = this.toDate()
      const endOfMonth = new Date(d.getFullYear(), d.getMonth() + 1, 0)
      return this.set({ day: endOfMonth.getDate() })
    },
    /**
     * Set the components of this DateTime in it's time zone
     * and return a new DateTime object in the same time zone.
     *
     * @example dateTime.set({ weekday: 0 }) // this Sunday
     * @example dateTime.set({ weekday: 7 }) // next Sunday
     * @example dateTime.set({ weekday: -7 }) // last Sunday
     * @example dateTime.set({ month: 0 }) // January
     * @example dateTime.set({ month: -12 }) // last January
     * @param {{ year?:number, month?:number, day?:number, weekday?:number, hour?:number, minute?:number, second?:number }} values The components to set
     * @return {DateTime}
     */
    set (values) {
      values = { ...values }
      let offsets = {}

      if ('year' in values) offsets = { ...offsets, years: values.year - year }
      if ('month' in values) offsets = { ...offsets, months: values.month - month }
      if ('day' in values) offsets = { ...offsets, days: values.day - day }
      if ('weekday' in values) offsets = { ...offsets, days: values.weekday - weekday }
      if ('hour' in values) offsets = { ...offsets, hours: values.hour - hour }
      if ('minute' in values) offsets = { ...offsets, minutes: values.minute - minute }
      if ('second' in values) offsets = { ...offsets, seconds: values.second - second }

      return this.offset(offsets)
    },
    /**
     * Offset the components of this DateTime
     * and return a new DateTime in the same time zone.
     *
     * @example dateTime.offset({ weeks: 1 })
     * @example dateTime.offset({ days: 1 })
     * @param {{ years?:number, months?:number, days?:number, businessDays?: number, weeks?:number, hours?:number, minutes?:number, seconds?:number }} offsets The offset values
     * @return {DateTime}
     */
    offset (offsets) {
      offsets = { ...offsets }

      return DateTime(
        Luxon.DateTime.fromObject({
          year,
          month: month + 1,
          day,
          hour,
          minute,
          second
        }, {
          zone: timeZone
        })
          .plus({
            years: 'years' in offsets ? offsets.years : 0,
            months: 'months' in offsets ? offsets.months : 0,
            days: 'days' in offsets ? offsets.days : 0,
            weeks: 'weeks' in offsets ? offsets.weeks : 0,
            hours: 'hours' in offsets ? offsets.hours : 0,
            minutes: 'minutes' in offsets ? offsets.minutes : 0,
            seconds: 'seconds' in offsets ? offsets.seconds : 0
          })
          .plusBusiness({
            days: 'businessDays' in offsets ? offsets.businessDays : 0
          })
          .toJSDate(),
        { timeZone, serviceCity }
      )
    },
    toDate () {
      return new Date(date)
    },
    /** @return {string} */
    toString () {
      return date.toISOString()
    },
    /**
     * Formats this DateTime instance to a string in the time zone
     * of the DateTime instance. If 'hour12' is true then the string will
     * be formatted like YYYY-MM-DD h:mm (AM|PM) otherwise the string is formated
     * YYYY-MM-DD HH:mm:ss.
     *
     * @param {Object} [options]
     * @param {string} [options.hour12]
     * @return {string}
     */
    format ({ hour12 = false } = {}) {
      const { year, month, day, hour, minute, second } = this
      const h = hour > 12 ? hour - 12 : hour
      const a = hour >= 12 ? 'PM' : 'AM'

      if (hour12) {
        return [
          [String(year).padStart(4, '0'), String(month + 1).padStart(2, '0'), String(day).padStart(2, '0')].join('-'),
          [h, String(minute).padStart(2, '0')].join(':'),
          a
        ].join(' ')
      } else {
        return [
          [year, String(month + 1).padStart(2, '0'), String(day).padStart(2, '0')].join('-'),
          [String(hour).padStart(2, '0'), String(minute).padStart(2, '0'), String(second).padStart(2, '0')].join(':')
        ].join(' ')
      }
    },
    /**
     * Format this DateTime instance to a date-only string in the time zone of the DateTime instance.
     * Example: Fri Oct 14, 1983
     * @return {string}
     */
    formatDate () {
      return Luxon.DateTime
        .fromJSDate(date, { zone: timeZone })
        .toFormat('ccc LLL dd, yyyy')
    },
    /**
     * Format this DateTime instance to a short month and day string in the time zone of the DateTime instance.
     * Example: Oct 14
     * @return {string}
     */
    formatMonthDay () {
      return Luxon.DateTime
        .fromJSDate(date, { zone: timeZone })
        .toFormat('LLL d')
    }
  })
}

exports.DateTime = DateTime
