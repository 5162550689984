import React from 'react'
import PropTypes from 'prop-types'
import { getFormattedDistanceString } from '@trexity/common/geo'
import { getFormattedDateAndTimeFromDate } from '@trexity/common/temporal'
import { sorter } from '@trexity/common/sort'
import { EVENT_LOG } from '@trexity/common/eventLogs/constants'

import {
  Table,
  Spin,
  Tag,
  Icon,
  Modal,
  Button,
  Input,
  Checkbox
} from 'antd'

export default function ShipmentDrivers ({
  shipment = null,
  drivers = null,
  eventLogs = null,
  disqualificationLogs = null,
  reloadDrivers,
  reloadLatestDisqualificationLogs,
  showLatestDisqualificationLogs,
  hideLatestDisqualificationLogs,
  isShowingLatestDisqualificationLogs,
  isLoadingLatestDisqualificationLogs,
  isUndismissingDriver,
  isRenouncingDriver,
  isAcceptingDriver,
  undismissDriverId,
  renounceShipment,
  acceptDriverId,
  isLoading,
  deps
}) {
  const {
    RouteLink
  } = deps

  const [driverSearchQuery, setDriverSearchQuery] = React.useState('')

  if (shipment && !eventLogs) {
    eventLogs = shipment.eventLogs || []
  }

  const undismissMemo = {}
  const renounceMemo = {}
  const acceptMemo = {}

  const filteredEventLogs = (eventLogs || [])
    .filter((event) => (
      (
        [
          EVENT_LOG.SHIPMENTS.DRIVER_PINGED,
          EVENT_LOG.SHIPMENTS.DRIVER_DISMISSED,
          EVENT_LOG.SHIPMENTS.DRIVER_UNDISMISSED,
          EVENT_LOG.SHIPMENTS.DRIVER_ACCEPTED,
          EVENT_LOG.SHIPMENTS.DRIVER_RENOUNCED
        ].includes(event.action)
      ) || (
        event.action === EVENT_LOG.SHIPMENTS.RENOUNCED_BY_DRIVER &&
        event.data.count === 1 &&
        event.data.merchantId
      )
    ))

  filteredEventLogs.sort(sorter('createdAt', { direction: 'desc' }))

  const dataSource = filteredEventLogs
    .map((event) => {
      return {
        ...event,
        __driverName: (
          // Get driver name from eventLog
          event.data.driverName ||
          // Attempt to get driver name from driverId if not cached already
          ((drivers || []).find(({ id }) => event.driverId === id) || {}).fullName ||
          // Can't determine driver name
          ''
        )
      }
    })
    .map((event) => {
      let action = 'unknown'
      const driverId = event.driverId

      if (event.action === EVENT_LOG.SHIPMENTS.DRIVER_DISMISSED) {
        action = 'dismissed'
      } else if (event.action === EVENT_LOG.SHIPMENTS.DRIVER_UNDISMISSED) {
        action = 'undismissed'
      } else if (event.action === EVENT_LOG.SHIPMENTS.DRIVER_PINGED) {
        action = 'pinged'
      } else if (event.action === EVENT_LOG.SHIPMENTS.DRIVER_ACCEPTED) {
        action = 'accepted'
      } else if (event.action === EVENT_LOG.SHIPMENTS.DRIVER_RENOUNCED) {
        action = 'renounced'
      } else if (event.action === EVENT_LOG.SHIPMENTS.RENOUNCED_BY_DRIVER) {
        // Compatibility with our old technique for getting renounced statuses
        action = 'renounced'
      }

      let canUndismiss = false
      let canRenounce = false
      let canAccept = false

      if (!undismissMemo[driverId]) {
        canUndismiss = (
          ['dismissed', 'renounced'].includes(action) &&
          shipment.currentStatus === 'WAITING_FOR_ACCEPTANCE' &&
          (
            (shipment.dismissedBy || []).includes(event.driverId) ||
            (shipment.softDismissedBy || []).includes(event.driverId)
          ) &&
          !isUndismissingDriver
        )

        // Only allow the first "undismiss" or "renounced" to be clickable. We
        // accomplish this by making sure the list is pre-sorted ahead of time,
        // and that we count on the first one we encounter as being the one we
        // want to allow to undismiss
        undismissMemo[driverId] = canUndismiss
      }

      if (!renounceMemo[driverId]) {
        canRenounce = (
          ['accepted'].includes(action) &&
          ['EN_ROUTE_TO_PICKUP', 'ARRIVED_AT_PICKUP', 'OUT_FOR_DELIVERY', 'ARRIVED_AT_DELIVERY'].includes(shipment.currentStatus) &&
          shipment.driverId === event.driverId &&
          !isRenouncingDriver
        )

        renounceMemo[driverId] = canRenounce
      }

      if (!acceptMemo[driverId]) {
        canAccept = (
          ['pinged'].includes(action) &&
          shipment.currentStatus === 'WAITING_FOR_ACCEPTANCE' &&
          !shipment.driverId &&
          event.driverId &&
          !isAcceptingDriver
        )

        acceptMemo[driverId] = canAccept
      }

      return {
        key: event.id,
        name: event.__driverName || 'N/A',
        createdAt: new Date(event.createdAt),
        pingedAtDistance: event.data.driverToPickupDistance,
        driverId: event.driverId,
        numPushNotificationsSent: 'numSent' in event.data
          ? Number(event.data.numSent)
          : '',
        action,
        canUndismiss,
        canRenounce,
        canAccept,
        softDismiss: Boolean(event.data.soft)
      }
    })
    .filter(({ name }) => {
      return driverSearchQuery
        ? name.includes(driverSearchQuery)
        : true
    })

  const TagWithUndismissAction = ({ record, color = 'red', children }) => {
    return (
      <Tag
        color={color}
        closable={record.canUndismiss}
        onClose={(evt) => {
          evt.preventDefault()

          if (!isUndismissingDriver) {
            Modal.confirm({
              title: `Are you sure you want to un-dismiss this shipment for driver ${record.name}?`,
              onOk: () => undismissDriverId(record.driverId)
            })
          }
        }}
      >
        {children} {isUndismissingDriver ? <Spin style={{ fontSize: 10, lineHeight: '10px', marginLeft: 4 }} indicator={<Icon type='loading' style={{ fontSize: 10 }} spin />} /> : null}
      </Tag>
    )
  }

  const TagWithRenounceAction = ({ record, color = 'green', children }) => {
    return (
      <Tag
        color={color}
        closable={record.canRenounce}
        onClose={(evt) => {
          evt.preventDefault()

          if (!isRenouncingDriver) {
            Modal.confirm({
              title: `Are you sure you want to renounce this shipment for driver ${record.name}?`,
              onOk: () => renounceShipment(record.driverId)
            })
          }
        }}
      >
        {children} {isRenouncingDriver ? <Spin style={{ fontSize: 10, lineHeight: '10px', marginLeft: 4 }} indicator={<Icon type='loading' style={{ fontSize: 10 }} spin />} /> : null}
      </Tag>
    )
  }

  const TagWithAcceptAction = ({ record, color = 'cyan', children }) => {
    return (
      <Tag color={color}>
        {children}
        {record.canAccept ? (
          <Button
            type='link'
            icon='login'
            size='small'
            style={{ fontSize: 12, height: 20 }}
            onClick={() => {
              Modal.confirm({
                title: `Are you sure you want to force accept this shipment for driver ${record.name}?`,
                onOk: () => acceptDriverId(record.driverId)
              })
            }}
          />
        ) : (
          isAcceptingDriver ? (
            <Spin style={{ fontSize: 10, lineHeight: '10px', marginLeft: 4 }} indicator={<Icon type='loading' style={{ fontSize: 10 }} spin />} />
          ) : null
        )}
      </Tag>
    )
  }

  const columns = [
    {
      title: 'Driver Name',
      dataIndex: 'name',
      key: 'name',
      render: function DriverName (_, record) {
        return (
          <RouteLink
            routeKey='Driver Dashboard'
            params={{ id: record.driverId }}
          >
            {record.name}
          </RouteLink>
        )
      }
    },
    {
      title: 'Time',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, record) => getFormattedDateAndTimeFromDate(record.createdAt, { showSeconds: true })
    },
    {
      title: 'Push Devices',
      dataIndex: 'numPushNotificationsSent',
      key: 'numPushNotificationsSent'
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: 185,
      align: 'right',
      render: (_, record) => {
        if (record.action === 'dismissed') {
          return <TagWithUndismissAction color='red' record={record}>Dismissed{record.softDismiss ? ' (soft)' : ''}</TagWithUndismissAction>
        } else if (record.action === 'undismissed') {
          return <Tag color='orange'>Un-dismissed</Tag>
        } else if (record.action === 'pinged') {
          return <TagWithAcceptAction color='cyan' record={record}>Pinged ({getFormattedDistanceString(record.pingedAtDistance)})</TagWithAcceptAction>
        } else if (record.action === 'accepted') {
          return <TagWithRenounceAction color='green' record={record}>Accepted</TagWithRenounceAction>
        } else if (record.action === 'renounced') {
          return <TagWithUndismissAction color='red' record={record}>Renounced</TagWithUndismissAction>
        } else {
          return null
        }
      }
    }
  ]

  return (
    <React.Fragment>
      <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'row' }}>
        <Input.Search
          placeholder='Search…'
          enterButton='Search'
          defaultValue={driverSearchQuery}
          onSearch={(searchQuery) => setDriverSearchQuery(searchQuery)}
        />
        <Button
          loading={isLoading}
          icon='reload'
          onClick={reloadDrivers}
          style={{ marginLeft: 10 }}
        >
          Reload
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={dataSource}
        loading={isLoading}
        size='small'
        bordered={false}
        scroll={{ x: true }}
        pagination={{
          pageSize: 15
        }}
      />
      <div style={{ marginTop: dataSource.length ? 0 : 20 }}>
        <Checkbox
          checked={isShowingLatestDisqualificationLogs}
          disabled={isLoading}
          onChange={() => {
            if (isShowingLatestDisqualificationLogs) {
              hideLatestDisqualificationLogs()
            } else {
              showLatestDisqualificationLogs()
            }
          }}
        >Show latest disqualification logs</Checkbox>
        {isShowingLatestDisqualificationLogs ? (
          <div style={{ marginTop: 5 }}>
            <DisqualificationLogs
              logs={disqualificationLogs || []}
              isLoading={isLoadingLatestDisqualificationLogs}
              reload={reloadLatestDisqualificationLogs}
              deps={{ RouteLink }}
            />
          </div>
        ) : null}
      </div>
    </React.Fragment>
  )
}

ShipmentDrivers.propTypes = {
  shipment: PropTypes.object,
  drivers: PropTypes.array,
  eventLogs: PropTypes.array,
  disqualificationLogs: PropTypes.array,

  reloadDrivers: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,

  reloadLatestDisqualificationLogs: PropTypes.func.isRequired,
  showLatestDisqualificationLogs: PropTypes.func.isRequired,
  hideLatestDisqualificationLogs: PropTypes.func.isRequired,
  isLoadingLatestDisqualificationLogs: PropTypes.bool.isRequired,
  isShowingLatestDisqualificationLogs: PropTypes.bool.isRequired,

  isUndismissingDriver: PropTypes.bool.isRequired,
  isRenouncingDriver: PropTypes.bool.isRequired,
  isAcceptingDriver: PropTypes.bool.isRequired,

  undismissDriverId: PropTypes.func.isRequired,
  renounceShipment: PropTypes.func.isRequired,
  acceptDriverId: PropTypes.func.isRequired,

  deps: PropTypes.exact({
    RouteLink: PropTypes.func
  }).isRequired
}

function DisqualificationLogs ({
  logs,
  isLoading,
  reload,
  deps
}) {
  const {
    RouteLink
  } = deps

  const [logsSearchQuery, setLogsSearchQuery] = React.useState('')

  const columns = [
    {
      title: 'Driver Name',
      dataIndex: 'driverName',
      key: 'driverName',
      render: function DriverName (_, record) {
        return (
          <RouteLink
            routeKey='Driver Dashboard'
            params={{ id: record.driverId }}
          >
            {record.driverName}
          </RouteLink>
        )
      }
    },
    {
      title: 'Time',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, record) => getFormattedDateAndTimeFromDate(record.createdAt, { showSeconds: true })
    },
    {
      title: 'Reason',
      dataIndex: 'reason',
      key: 'reason'
    }
  ]

  const dataSource = logs
    .filter(({ reason, driverName }) => {
      return logsSearchQuery
        ? (
          reason.includes(logsSearchQuery) ||
          driverName.includes(logsSearchQuery)
        )
        : true
    })
    .map((log) => ({
      ...log,
      key: log.id
    }))

  return (
    <React.Fragment>
      <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'row' }}>
        <Input.Search
          placeholder='Search…'
          enterButton='Search'
          defaultValue={logsSearchQuery}
          onSearch={(searchQuery) => setLogsSearchQuery(searchQuery)}
        />
        <Button
          loading={isLoading}
          icon='reload'
          onClick={reload}
          style={{ marginLeft: 10 }}
        >
          Reload
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={dataSource}
        loading={isLoading}
        size='small'
        bordered={false}
        scroll={{ x: true }}
        pagination={{
          pageSize: 10
        }}
      />
    </React.Fragment>
  )
}

DisqualificationLogs.propTypes = {
  logs: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  reload: PropTypes.func.isRequired,
  deps: PropTypes.exact({
    RouteLink: PropTypes.func
  }).isRequired
}
