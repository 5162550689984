import { useJsApiLoader } from '@react-google-maps/api'
import usePropertyWhenDefined from './usePropertyWhenDefined'

const libraries = ['places', 'geometry']

// googleMapsApiKey = Config.GOOGLE_MAPS_API_KEY
export function useGoogleMapsApi ({ googleMapsApiKey }) {
  let useJsApiLoaderResult = null
  let google = window && window.google && window.google.maps ? window.google : null

  // This will load the Google Maps and libraries if they are not loaded already.
  if (!google) {
    useJsApiLoaderResult = useJsApiLoader({
      id: 'google-maps',
      googleMapsApiKey,
      libraries,
      version: '3.47'
    })
  }

  // NOTE: We have to do this for whatever reason because just using useJsApiLoader() alone
  // is not enough. LAME! Even though Google documents it as you are supposed to be able to!
  google = usePropertyWhenDefined(() => {
    const mapsLoaded = Boolean(window && window.google && window.google.maps)
    if (mapsLoaded) return window.google
    return null
  })

  return { isLoaded: (useJsApiLoaderResult && useJsApiLoaderResult.isLoaded) || Boolean(google), google }
}
