import * as React from 'react'
import * as PropTypes from 'prop-types'
import * as ZoneMapAssets from './ZoneMapAssets'
import { AddressAutoComplete } from './AddressAutoComplete'

import {
  Row,
  Col,
  Input,
  Button,
  Icon,
  Spin
} from 'antd'

export default function MerchantAddressChangeForm ({
  address,
  addressNotes,
  proximity,
  registrationAddressPosition, // { lat, lng }
  addressCheckData, // { delivery, pickup },
  onAddressChange,
  isChecking,
  isUpdating,
  onUpdateAddressClicked,
  deps
} = {}) {
  const { tc, google } = deps

  const [newAddress, setNewAddress] = React.useState(address || '')
  const [newAddressNotes, setNewAddressNotes] = React.useState(addressNotes || '')
  const [addressCheckResult, setAddressCheckResult] = React.useState(null)
  const [addressVerified, setAddressVerified] = React.useState(false)

  React.useEffect(() => {
    if (addressCheckData && addressCheckData.delivery) {
      setAddressCheckResult({ deliver: addressCheckData.delivery, pickup: addressCheckData.pickup })
      setAddressVerified(Boolean(addressCheckData.delivery === 'normal' && addressCheckData.pickup))
    } else {
      setAddressCheckResult(null)
      setAddressVerified(false)
    }
  }, [addressCheckData])

  const noDataToUpdate = (String(address) === newAddress && String(addressNotes) === newAddressNotes) || !newAddress

  return (
    <Row gutter={8}>
      <Col xs={24} sm={24} md={10}>
        <AddressAutoComplete
          style={{ width: '100%' }}
          value={newAddress}
          disabled={isChecking || isUpdating}
          onError={(error) => console.error(error)}
          onChange={(changedValue) => {
            onAddressChange(changedValue)
            setNewAddress(changedValue)
          }}
          deps={{ tc }}
        />
        <Input.TextArea
          style={{ marginTop: 10 }}
          value={newAddressNotes}
          disabled={isChecking || isUpdating}
          rows={1}
          placeholder='Buzzer'
          onChange={(evt) => setNewAddressNotes(evt.target.value)}
        />
        <div style={{ marginTop: 10, marginBottom: 10 }}>
          <Button
            style={{ width: '100%' }}
            type='primary'
            size='large'
            loading={isUpdating}
            disabled={!addressVerified || isChecking || noDataToUpdate}
            onClick={() => (onUpdateAddressClicked(newAddress, newAddressNotes))}
          >
            Update address
          </Button>
        </div>
      </Col>
      <Col xs={24} sm={24} md={14}>
        {google ? (
          <ZoneMapAssets.ZoneMap
            enableLegend
            enableAutoMarker
            validityResults={(
              <ZoneMapAssets.ZoneMapResults
                addressCheckResult={(String(address) !== newAddress && newAddress && !isChecking && !isUpdating && addressCheckResult) || undefined}
                rejectIcon={<Icon type='stop' />}
                acceptIcon={<Icon type='check-circle' theme='filled' />}
                infoIcon={<Icon type='info-circle' theme='filled' />}
                errorIcon={<Icon type='warning' theme='filled' />}
              />
            )}
            height={'350px'}
            width={'100%'}
            loader={<Spin spinning size='large' />}
            serviceCity={addressCheckData && addressCheckData.pickup && addressCheckData.serviceCity}
            forceLoading={isChecking || isUpdating}
            deps={deps}
            center={registrationAddressPosition}
          />
        ) : null}
      </Col>
    </Row>
  )
}

MerchantAddressChangeForm.propTypes = {
  address: PropTypes.string,
  addressNotes: PropTypes.string,
  proximity: PropTypes.array.isRequired,
  registrationAddressPosition: PropTypes.object,
  addressCheckData: PropTypes.object,
  onAddressChange: PropTypes.func.isRequired,
  isChecking: PropTypes.bool,
  isUpdating: PropTypes.bool,
  onUpdateAddressClicked: PropTypes.func.isRequired,
  deps: PropTypes.exact({
    tc: PropTypes.object,
    google: PropTypes.object
  }).isRequired
}
