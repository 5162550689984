import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { Icon, Button, Input } from 'antd'
import Dialog from 'rc-dialog'

// Pulled and adapted from antd's source:
// https://github.com/ant-design/ant-design/blob/3.x-stable/components/modal/confirm.tsx

const IS_REACT_16 = !!ReactDOM.createPortal

const destroyFns = []

const ConfirmDialog = (props) => {
  const {
    onCancel,
    onOk,
    close,
    zIndex,
    afterClose,
    visible,
    keyboard,
    centered,
    getContainer,
    maskStyle,
    cancelButtonProps,
    iconType = 'question-circle'
  } = props

  // ------------------------

  const [inputWord, setInputWord] = React.useState('')
  const okDisabled = inputWord !== props.confirmWord

  const content = (
    <React.Fragment>
      {props.content}
      {typeof props.confirmText === 'function'
        ? props.confirmText(props.confirmWord, inputWord)
        : null}
      <Input
        placeholder={props.confirmWord}
        value={inputWord}
        onChange={(evt) => setInputWord(evt.target.value)}
      />
    </React.Fragment>
  )

  const origOkButtonProps = props.okButtonProps || {}

  const okButtonProps = {
    ...origOkButtonProps,
    disabled: 'disabled' in origOkButtonProps ? origOkButtonProps.disabled : okDisabled
  }

  // ------------------------

  // 支持传入{ icon: null }来隐藏`Modal.confirm`默认的Icon
  const icon = props.icon === undefined ? iconType : props.icon
  const okType = props.okType || 'primary'
  const prefixCls = props.prefixCls || 'ant-modal'
  const contentPrefixCls = `${prefixCls}-confirm`
  // 默认为 true，保持向下兼容
  const okCancel = 'okCancel' in props ? props.okCancel : true
  const width = props.width || 416
  const style = props.style || {}
  const mask = props.mask === undefined ? true : props.mask
  // 默认为 false，保持旧版默认行为
  const maskClosable = props.maskClosable === undefined ? false : props.maskClosable
  const okText = props.okText || 'OK'
  const cancelText = props.cancelText || 'Cancel'
  const autoFocusButton = props.autoFocusButton === null ? false : props.autoFocusButton || 'ok'
  const transitionName = props.transitionName || 'zoom'
  const maskTransitionName = props.maskTransitionName || 'fade'

  const classString = [
    contentPrefixCls,
    `${contentPrefixCls}-${props.type}`,
    props.className
  ].filter(Boolean).join(' ')

  const cancelButton = okCancel && (
    <ActionButton
      actionFn={onCancel}
      closeModal={close}
      autoFocus={autoFocusButton === 'cancel'}
      buttonProps={cancelButtonProps}
    >
      {cancelText}
    </ActionButton>
  )

  const iconNode = typeof icon === 'string' ? <Icon type={icon} /> : icon

  return (
    <Dialog
      prefixCls={prefixCls}
      className={classString}
      wrapClassName={({ [`${contentPrefixCls}-centered`]: !!props.centered })}
      onCancel={() => close({ triggerCancel: true })}
      visible={visible}
      title=""
      transitionName={transitionName}
      footer=""
      maskTransitionName={maskTransitionName}
      mask={mask}
      maskClosable={maskClosable}
      maskStyle={maskStyle}
      style={style}
      width={width}
      zIndex={zIndex}
      afterClose={afterClose}
      keyboard={keyboard}
      centered={centered}
      getContainer={getContainer}
    >
      <div className={`${contentPrefixCls}-body-wrapper`}>
        <div className={`${contentPrefixCls}-body`}>
          {iconNode}
          {props.title === undefined ? null : (
            <span className={`${contentPrefixCls}-title`}>{props.title}</span>
          )}
          <div className={`${contentPrefixCls}-content`}>{content}</div>
        </div>
        <div className={`${contentPrefixCls}-btns`}>
          {cancelButton}
          <ActionButton
            type={okType}
            actionFn={onOk}
            closeModal={close}
            autoFocus={autoFocusButton === 'ok'}
            buttonProps={okButtonProps}
          >
            {okText}
          </ActionButton>
        </div>
      </div>
    </Dialog>
  )
}

function confirm (config) {
  const div = document.createElement('div')
  document.body.appendChild(div)
  // eslint-disable-next-line no-use-before-define
  let currentConfig = { ...config, close, visible: true }

  function destroy (...args) {
    const unmountResult = ReactDOM.unmountComponentAtNode(div)

    if (unmountResult && div.parentNode) {
      div.parentNode.removeChild(div)
    }

    const triggerCancel = args.some((param) => param && param.triggerCancel)

    if (config.onCancel && triggerCancel) {
      config.onCancel(...args)
    }

    for (let i = 0; i < destroyFns.length; i++) {
      const fn = destroyFns[i]

      // eslint-disable-next-line no-use-before-define
      if (fn === close) {
        destroyFns.splice(i, 1)
        break
      }
    }
  }

  function render (props) {
    ReactDOM.render(<ConfirmDialog {...props} />, div)
  }

  function close (...args) {
    currentConfig = {
      ...currentConfig,
      visible: false,
      afterClose: destroy.bind(this, ...args)
    }

    if (IS_REACT_16) {
      render(currentConfig)
    } else {
      destroy(...args)
    }
  }

  function update (newConfig) {
    currentConfig = {
      ...currentConfig,
      ...newConfig
    }
    render(currentConfig)
  }

  render(currentConfig)

  destroyFns.push(close)

  return {
    destroy: close,
    update
  }
}

class ActionButton extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      loading: false
    }

    this.onClick = () => {
      const { actionFn, closeModal } = this.props

      if (actionFn) {
        let ret = null

        if (actionFn.length) {
          ret = actionFn(closeModal)
        } else {
          ret = actionFn()

          if (!ret) {
            closeModal()
          }
        }

        if (ret && ret.then) {
          this.setState({ loading: true })

          ret.then(
            (...args) => {
              // It's unnecessary to set loading=false, for the Modal will be unmounted after close.
              // this.setState({ loading: false });
              closeModal(...args)
            },
            (e) => {
              // Emit error when catch promise reject
              // eslint-disable-next-line no-console
              console.error(e)
              // See: https://github.com/ant-design/ant-design/issues/6183
              this.setState({ loading: false })
            }
          )
        }
      } else {
        closeModal()
      }
    }
  }

  componentDidMount () {
    if (this.props.autoFocus) {
      // eslint-disable-next-line react/no-find-dom-node
      const $this = ReactDOM.findDOMNode(this)
      this.timeoutId = setTimeout(() => $this.focus())
    }
  }

  componentWillUnmount () {
    clearTimeout(this.timeoutId)
  }

  render () {
    const { type, children, buttonProps } = this.props
    const { loading } = this.state
    return (
      <Button type={type} onClick={this.onClick} loading={loading} {...buttonProps}>
        {children}
      </Button>
    )
  }
}

export default function ModalConfirmWord ({ confirmText, confirmWord }, props) {
  const config = {
    type: 'confirm',
    okCancel: true,
    confirmText,
    confirmWord,
    ...props
  }

  return confirm(config)
}
