// Reference: https://www.canadapost-postescanada.ca/scp/fr/soutien/sujet/directives-adressage/symboles-et-abreviations.page

/*
################################################################################

STOP: Becareful about changing the mappings! We must ensure that the same mappings
apply. Adding new mappings or changing mappings will need to re-key all existing
Location entities.

    // One way is to load each location then just update the address without
    // any change. This will automatically recompute and update the key.
    await locationRepo.update(location.id, { addressObj: location.addressObj })

################################################################################
*/

// This maps English and French abbrebiations and full names to English full names.
// This is so that for say a Quebec address someone were to use "123 Fake St E"
// and then someone else uses "123 Fake St Est" they would be the same address.
// NOTE: Street directions is the only acceptional case like this because the
// abbreviation for East (and others) is the same in French and English.
//
// In other words street directional suffixes always normalize to the English full
// name, regardless of what is on the "ground".

const pairs = [
  ['East', ['E', 'Est']],
  ['North', ['N', 'Nord']],
  ['Northeast', ['NE', 'Nord-Est']],
  ['Northwest', ['NW', 'Nord-Ouest']],
  ['South', ['S', 'Sud']],
  ['Southest', ['SE', 'Sud-Est']],
  ['Southwest', ['SW', 'SO', 'Sud-Ouest']],
  ['West', ['W', 'O', 'Ouest']]
]

exports.pairs = pairs
