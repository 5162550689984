module.exports = function objectSet (obj, path, value) {
  const parts = path.split('.')
  const segment = parts.shift()

  return {
    ...obj,
    [segment]: parts.length
      ? objectSet(obj[segment], parts.join('.'), value)
      : value
  }
}
