/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useState, useRef } from 'react'

import { COLORS } from '@trexity/common/color/constants'
import { Button } from '../../Button/Button'
import { TextButton } from '../../Button/TextButton'
import { MaterialIcon } from '../../Icons/MaterialIcon'
import { P } from '../../Typography/Text/P'
import { Strong } from '../../Typography/Text/Strong'
import PropTypes from 'prop-types'

export const DragDropStyles = (borderColor, ready) => ({
  width: '100%',
  border: ready ? `3px solid ${COLORS.TREXITY_GREEN}` : `3px dashed ${borderColor}`,
  height: 150,
  borderRadius: 5,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  transition: 'all ease-in-out 0.3s',
  alignItems: 'center',
  color: borderColor
})

export const ReadDroppedFileContents = (file, type = 'asText') => {
  return new Promise((resolve) => {
    const reader = new FileReader()

    reader.onload = function () {
      resolve(reader.result)
    }

    if (type === 'asText') {
      reader.readAsText(file)
    } else if (type === 'asDataUrl') {
      reader.readAsDataURL(file)
    } else if (type === 'asArrayBuffer') {
      reader.readAsArrayBuffer(file)
    } else if (type === 'asBinaryString') {
      reader.readAsBinaryString(file)
    }
  })
}

export const DragDrop = ({
  animatingIn,
  animatingOut,
  mimeTypes,
  dropComplete,
  multi = false,
  ready = false,
  startLoading = false,
  fileName = '',
  textButtonCopy = 'Select File',
  startCopy = 'Start',
  dropCopy = 'Drop file here to upload',
  startDisabled = false,
  onStart = () => {}
}) => {
  const [hovering, setHovering] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)
  const icon = useRef()
  const borderBox = useRef()
  const wrapper = useRef()
  const fileInput = useRef()
  let borderColor = hovering ? COLORS.TREXITY_BLUE : COLORS.MEDIUM
  borderColor = errorMessage ? COLORS.TREXITY_RED : borderColor

  const enableError = (message) => {
    setHovering(false)
    setErrorMessage(message)
  }

  const enableHover = () => {
    setHovering(true)
    setErrorMessage('')
  }

  const clearVisualStatus = () => {
    setHovering(false)
    setErrorMessage('')
  }

  const isMimeTypeValid = (file) => {
    return (!mimeTypes || mimeTypes.includes(file.type))
  }

  const onDrop = async (e) => {
    e.preventDefault()
    const dt = e.dataTransfer
    const files = dt.files
    let filteredFiles = []

    if (multi) {
      filteredFiles = files && Array.from(files).filter((file) => isMimeTypeValid(file))
    } else if (!multi && files && files.length === 1 && isMimeTypeValid(files[0])) {
      filteredFiles = [files[0]]
    }

    if (filteredFiles.length > 0 && filteredFiles.length === files.length) {
      const file = await ReadDroppedFileContents(filteredFiles[0])
      dropComplete({ file, fileRaw: filteredFiles[0] })
      clearVisualStatus()
    } else {
      const foundMimeTypes = files && Array.from(files).map((file) => file.type).filter((type) => !mimeTypes.includes(type))
      const mimeTypeError = `Invalid Mime type. Expected: ${mimeTypes && mimeTypes.join(' ')}, but received: ${foundMimeTypes && foundMimeTypes.join(' ')}`
      if (multi) enableError(mimeTypeError)
      if (!multi && files && files.length === 1) enableError(mimeTypeError)
      if (!multi && files && files.length > 1) enableError('Please upload only one file')
    }
  }

  const active = hovering || errorMessage
  return (
    <div css={{ margin: '0 auto' }} ref={wrapper}>
      <div
        ref={borderBox}
        css={DragDropStyles(borderColor, ready)}
        onDrop={onDrop}
        onDragOver={(e) => { e.preventDefault() }}
        onDragEnter={enableHover}
        onDragLeave={clearVisualStatus}
      >
        <div css={{ pointerEvents: 'none', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
          <div css={{ transition: 'transform ease-in-out 0.4s', transform: `scale(${active ? 1.6 : 1})` }} ref={icon}>
            {ready
              ? <MaterialIcon size={30} color={COLORS.TREXITY_GREEN} icon="check_circle" />
              : errorMessage ? <MaterialIcon size={30} icon="error" /> : <MaterialIcon size={30} icon="move_to_inbox" />
            }
          </div>
          <P style={{ marginBottom: 0, color: borderColor }}><Strong>{dropCopy}</Strong></P>
          {ready ? <P style={{ marginBottom: 0, fontSize: 14, fontStyle: 'italic' }}>{fileName}</P> : null}
        </div>
      </div>
      {errorMessage ? <div css={{ color: COLORS.TREXITY_RED, marginTop: 20 }}>{errorMessage}</div> : null}
      <div css={{ justifyContent: 'space-between', display: 'flex', marginTop: 10 }}>
        <input
          type="file"
          style={{ display: 'none' }}
          accept={mimeTypes}
          ref={fileInput}
          onChange={async (fileChanged) => {
            if (fileChanged && fileChanged.target && fileChanged.target.files && fileChanged.target.files[0]) {
               const fileRaw = fileChanged.target.files[0]
               const file = await ReadDroppedFileContents(fileRaw)
               dropComplete({ file, fileRaw })
            } else {
              enableError('File Upload failed')
            }
        }}
        />
        <TextButton
          animatingOut={animatingOut}
          animatingIn={animatingIn}
          onClick={() => {
          fileInput.current.click()
        }}
        >{textButtonCopy}</TextButton>
        <Button
          loading={startLoading}
          onClick={() => {
          if (ready) {
            onStart()
          } else {
            fileInput.current.click()
          }
          }}
        >{startCopy}</Button>
      </div>
    </div >
  )
}

DragDrop.propTypes = {
  animatingIn: PropTypes.bool,
  animatingOut: PropTypes.bool,
  mimeTypes: PropTypes.array,
  dropComplete: PropTypes.func,
  multi: PropTypes.bool,
  textButtonCopy: PropTypes.string,
  startCopy: PropTypes.string,
  onStart: PropTypes.func,
  dropCopy: PropTypes.string,
  startDisabled: PropTypes.bool,
  ready: PropTypes.bool,
  startLoading: PropTypes.bool,
  fileName: PropTypes.string
}
