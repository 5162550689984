import React from 'react'
import PropTypes from 'prop-types'
import { Tag, Tooltip } from 'antd'
import MerchantDiscountStatusTag from './MerchantDiscountStatusTag'

export default function MerchantDiscountActivationStatusTag ({
  status = null,
  placement = 'bottom',
  ...remainingProps
}) {
  if (status === 'exhausted') {
    return (
      <Tooltip title='You have no balance left.' placement={placement}>
        <Tag color='red' {...remainingProps}>Exhausted</Tag>
      </Tooltip>
    )
  }

  return <MerchantDiscountStatusTag status={status} placement={placement} {...remainingProps} />
}

MerchantDiscountActivationStatusTag.propTypes = {
  status: PropTypes.string,
  placement: PropTypes.oneOf(['top', 'left', 'right', 'bottom', 'topLeft', 'topRight', 'bottomLeft', 'bottomRight', 'leftTop', 'leftBottom', 'rightTop', 'rightBottom'])
}
