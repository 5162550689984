/**
 * @param {Date|{ toLocaleDateString: (locale:string, { timeZone:string }) => string }} date
 * @param {Object} [options]
 * @param {string} [options.locale]
 * @param {string} [options.timeZone]
 * @return {string}
 */
const formatDate = (date, { locale = 'en-CA', timeZone = 'UTC' } = {}) => {
  if (date && typeof date.toDate === 'function') {
    date = date.toDate()
  }

  if (!date || typeof date.toLocaleDateString !== 'function') {
    throw new TypeError('date must have method toLocaleDateString(locale, { timeZone })')
  }

  return (
    // TEMPORARY:
    // NOTE (dschnare): We need Node 14.6+ in order to take
    // advantage of locale. Lower versions of Node hardcode
    // locale to en-US no matter what we pass in.
    date.toLocaleDateString(locale, { timeZone })
  )
}

exports.formatDate = formatDate
