/**
 * Objectify an object into a new object, calling an optional replacer function to
 * replace property values during objectification.
 *
 * To remove keys from the objectify tree the replacer should return 'undefined'.
 *
 * When Array instances are encountered a new Array will be created in the objectified tree.
 * This function in essence makes a deep clone of an object and gives the ability to replace
 * parts of the cloned tree.
 *
 * @param {any} obj The object to objectify
 * @param {(key:string, value, obj) => any} [replacer] The optional replacer function
 * @return {Array|Object}
 */
function objectify (obj, replacer = (key, value, obj) => value) {
  if (typeof replacer !== 'function') {
    throw new TypeError('Replacer must be a function')
  }

  if (Object(obj) !== obj) {
    return obj
  }

  const result = Array.isArray(obj) ? [] : {}
  const stack = [[undefined, '', obj, result]]

  while (stack.length) {
    let [o, k, v, curr] = stack.shift()

    v = replacer(k, v, o)

    if (v === undefined) {
      continue
    }

    if (Array.isArray(v) || (Object(v) === v && Object.getPrototypeOf(v) === Object.prototype)) {
      if (o) {
        curr[k] = Array.isArray(v) ? [] : {}
      }

      stack.push(
        ...Object.keys(v).map((key) => {
          return [v, key, v[key], o ? curr[k] : curr]
        })
      )
    } else {
      curr[k] = v
    }
  }

  return result
}

exports.objectify = objectify
