const geoJsonToPolyline = require('./geoJsonToPolyline')
const decodePath = require('./decodePath')

function mapTravelModeFromGeoJsonDirections (inputTravelMode) {
  switch (inputTravelMode) {
    case 'driving':
      return 'DRIVING'
    default:
      throw new Error('Unsupported travelMode: ' + inputTravelMode)
  }
}

module.exports = function geoJsonDirectionsToGoogleMapsDirections (geojson) {
  const {
    bounds,
    legs,
    travelMode,
    overviewPolyline
  } = geojson

  return {
    request: {
      travelMode: mapTravelModeFromGeoJsonDirections(travelMode)
    },
    routes: [{
      bounds,
      overview_polyline: geoJsonToPolyline(overviewPolyline),
      overview_path: decodePath(geoJsonToPolyline(overviewPolyline)),
      legs: legs.map(({ distance, duration, steps, startLocation, endLocation }) => {
        return {
          distance: { text: `${distance} m`, value: distance },
          duration: { text: `${duration} s`, value: duration },
          start_location: {
            lat: startLocation.latitude,
            lng: startLocation.longitude
          },
          end_location: {
            lat: endLocation.latitude,
            lng: endLocation.longitude
          },
          steps: steps && steps.map(({
            travelMode,
            geoJsonPolyline,
            distance,
            duration
          }) => {
            return {
              travel_mode: mapTravelModeFromGeoJsonDirections(travelMode),
              path: decodePath(geoJsonToPolyline(geoJsonPolyline)),
              // polyline: {
              //   points: geoJsonToPolyline(geoJsonPolyline)
              // },
              distance: { value: distance, text: `${distance} m` },
              duration: { value: duration, text: `${duration} s` }
            }
          })
        }
      })
    }]
  }
}
