const { parseAddressSync } = require('../address')
const { convertOrdersToArray } = require('../models/ShipmentOrder')

const COMPOUND_DELIMITER = ' && '

// Given a shipment object with all normal server properties, returns an object
// containing 3 main keys:
// - searchTerms: an array of search terms used in a search box
// - an optional service city (e.g. "Ottawa, ON")
// - an optional section (e.g. "SECTION:DRAFTS")
// This is intended to be used to determine all possible permutations of a
// search term, a city, and a section, in that order.

function getSearchProfile (shipment) {
  const shipmentId = shipment.id || ''
  const routeIdentifier = shipment.routeIdentifier || ''
  const ordersArray = convertOrdersToArray(shipment.orders)
  const orderIds = ordersArray.filter(({ orderId }) => orderId).map(({ orderId }) => orderId).filter(Boolean).map(String)
  const serviceCity = shipment.pickupAddressServiceCity || ''
  const section = getSectionFromShipmentState(shipment)

  // Sometimes it's convenient to be able to search for the "root" order ID from Shopify
  // without knowing the fulfillment number. For example #123508 vs #123508-F2.
  // Orders from Shopify are always suffixed with "-FN" where N is the fulfillment number.
  const orderIdPrefixesWithoutFulfillmentSuffix = orderIds.map((id) => id.replace(/-F\d+$/u, ''))

  const orderAddresses = ordersArray
    .filter(({ address }) => address)
    .reduce((memo, order) => {
      const address = String(order.address)
      const country = String(order.country)

      const addressObj = parseAddressSync(address, { withoutAddressee: true, postalCodeOptional: true, country })

      return [
        ...memo,
        address,
        addressObj.fullStreet
      ]
    }, [])

  const orderNames = ordersArray
    .filter(({ name }) => name)
    .map(({ name }) => name)
    .map(String)
    .reduce((memo, name) => {
      const firstName = name.split(' ')[0] || ''

      return [
        ...memo,
        name,
        firstName
      ]
    }, [])

  const orderPhones = ordersArray
    .filter(({ phone }) => phone)
    .map(({ phone }) => phone)
    .map(String)
    .reduce((memo, phone) => {
      return [
        ...memo,
        phone,
        phone.replace(/ /gu, '')
      ]
    }, [])

  return {
    searchTerms: [...new Set([
      shipmentId,
      shipmentId.substr(-3),
      ...orderIds,
      ...orderIdPrefixesWithoutFulfillmentSuffix,
      routeIdentifier,
      ...orderAddresses,
      ...orderNames,
      ...orderPhones
    ].filter(Boolean))],

    // Be careful how many you add here -- let's try not to exceed the two that
    // we have here. Permutation count will skyrocket otherwise.
    serviceCity,
    section
  }
}

// Given a "searchProfile" object, get all possible permutations of each
// search term combined with the city and section, if specified.
function getCompoundSearchCombinations (searchProfile) {
  const {
    searchTerms,
    serviceCity,
    section
  } = searchProfile

  const searchCombinations = searchTerms
    .reduce((memo, term) => {
      return [
        ...memo,
        ...getPermutations([term, serviceCity, section].filter(Boolean))
      ]
    }, getPermutations([serviceCity, section].filter(Boolean)))

  const flatSearchCombinations = searchCombinations
    .map((terms) => terms.join(COMPOUND_DELIMITER))

  return flatSearchCombinations
}

// Given an array of searchTerms, an array of service cities, and a section
// name, we provide an "array-contains-any"-compatible query. The parameters
// resemble a searchProfile, but they differ in that serviceCities can be an
// array, and sectionName is the section itself without the prefix. This
// function is meant to be used on the system that makes the query (client).
function buildCompoundSearchTermsQuery (
  searchTerms,
  serviceCities,
  sectionName
) {
  const section = sectionName ? `SECTION:${sectionName}` : null

  const compoundSearchTerms = Array.isArray(serviceCities) && serviceCities.length ? (
    serviceCities.map((serviceCity) => getCompoundSearchTerms({
      searchTerms,
      serviceCity,
      section
    }))
  ) : ([
    getCompoundSearchTerms({
      searchTerms,
      serviceCity: null,
      section
    })
  ])

  const compoundSearchTermsQuery = compoundSearchTerms
    .map((searchTerms) => searchTerms.join(COMPOUND_DELIMITER))
    .filter(Boolean)

  return compoundSearchTermsQuery
}

function getCompoundSearchTerms (searchProfile) {
  const {
    searchTerms,
    serviceCity,
    section
  } = searchProfile

  if (searchTerms.length) {
    return searchTerms
      .reduce((memo, term) => {
        return [
          ...memo,
          ...[term, serviceCity, section].filter(Boolean)
        ]
      }, [])
  } else {
    return [serviceCity, section].filter(Boolean)
  }
}

function getPermutations (list) {
  const set = []
  const listSize = list.length
  const combinationsCount = (1 << listSize)

  for (let i = 1; i < combinationsCount; i++) {
    const combination = []

    for (let j = 0; j < listSize; j++) {
      if ((i & (1 << j))) {
        combination.push(list[j])
      }
    }

    set.push(combination)
  }

  return set
}

function buildSearchTerms (shipment) {
  const searchProfile = getSearchProfile(shipment)
  return getCompoundSearchCombinations(searchProfile)
}

// Given a shipment object with all normal server properties, returns a special
// "section" string used in our compound search query technique. Typically, the
// section is derived from the shipment's currentStatus and potentially other
// properties such as scheduledPostEnabled.
function getSectionFromShipmentState (shipment) {
  if (
    ['RENOUNCED_BY_DRIVER'].includes(shipment.currentStatus) &&
    !shipment.scheduledPostEnabled
  ) {
    return 'SECTION:OTHER'
  }

  if (
    ['', 'ON_HOLD'].includes(shipment.currentStatus) &&
    shipment.scheduledPostEnabled
  ) {
    return 'SECTION:UPCOMING'
  }

  if (
    [''].includes(shipment.currentStatus) &&
    !shipment.scheduledPostEnabled
  ) {
    return 'SECTION:DRAFTS'
  }

  if (['WAITING_FOR_ACCEPTANCE', 'ON_HOLD'].includes(shipment.currentStatus)) {
    return 'SECTION:WAITING'
  }

  if (['EN_ROUTE_TO_PICKUP', 'ARRIVED_AT_PICKUP'].includes(shipment.currentStatus)) {
    return 'SECTION:TO_PICKUP'
  }

  if (['OUT_FOR_DELIVERY', 'ARRIVED_AT_DELIVERY'].includes(shipment.currentStatus)) {
    return 'SECTION:TO_DELIVERY'
  }

  if (['DELIVERED'].includes(shipment.currentStatus)) {
    return 'SECTION:DELIVERED'
  }

  if (['CANCELLED'].includes(shipment.currentStatus)) {
    return 'SECTION:CANCELLED'
  }

  return 'SECTION:NONE'
}

exports.buildSearchTerms = buildSearchTerms
exports.getSearchProfile = getSearchProfile
exports.getCompoundSearchCombinations = getCompoundSearchCombinations
exports.getSectionFromShipmentState = getSectionFromShipmentState
exports.buildCompoundSearchTermsQuery = buildCompoundSearchTermsQuery
