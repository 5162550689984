/**
 * @template T,K
 * @param {Array<T>} items
 * @param {(item:T) => K} extractor Function used to extract group key for an item
 * @param {{ initialGroups?: Map<K, T[]> }} [options]
 * @return {Map<K,T[]>}
 */
function groupBy (items, extractor, { initialGroups = new Map() } = {}) {
  return items.reduce((groups, it) => {
    const group = extractor(it)
    groups.set(group, [].concat(groups.get(group) || [], it))
    return groups
  }, new Map(initialGroups))
}

module.exports = groupBy
