/* eslint-disable camelcase */
import * as React from 'react'
import * as PropTypes from 'prop-types'
import moment from 'moment'
import { isValidDate } from '@trexity/common/temporal'

import {
  Button,
  Input,
  Icon,
  Popover,
  Tag,
  Radio,
  DatePicker,
  Select,
  Checkbox
} from 'antd'

export default function InputSearchWithFilter ({
  filters,
  onReset = null,
  onFilter = null,
  placeholder = '',
  disabled = false,
  disableClear = false,
  resetLabel = 'Reset',
  filterLabel = 'Filter',
  children,
  ...remainingProps
} = {}) {
  const keywordsAsArray = Array.isArray(filters.keywords)

  const mapExternalFilters = (filters) => ({
    ...filters,
    keywords: keywordsAsArray
      ? convertArrayKeywordsToString(filters.keywords)
      : filters.keywords
  })

  const [visible, setVisible] = React.useState(false)
  const [localFilters, setLocalFilters] = React.useState(mapExternalFilters(filters))

  React.useEffect(() => {
    setLocalFilters(mapExternalFilters(filters))
  }, [filters])

  React.useEffect(() => {
    const onResize = () => setWidth(getWidth())
    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [])

  const [width, setWidth] = React.useState(getWidth())
  const isDesktop = (width > 667)

  function getWidth () {
    return (
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
    )
  }

  function callOnReset () {
    onReset()
  }

  function callOnFilter (updatedFilters) {
    if (keywordsAsArray) {
      onFilter({ ...updatedFilters, keywords: convertStringKeywordsToArray(updatedFilters.keywords) })
    } else {
      onFilter(updatedFilters)
    }
  }

  const iterateChildren = (children, callback) => {
    return React.Children.map(children, (component, index) => {
      if (!component) {
        return
      }

      if (component.type === React.Fragment && component.props.children) {
        return iterateChildren(component.props.children, callback)
      }

      if (typeof component.props.$visibleIf === 'function') {
        if (!component.props.$visibleIf(localFilters)) {
          return
        }
      }

      return callback(component, index)
    })
  }

  const parsedChildren = iterateChildren(children, (component, index) => {
    return (
      <div key={`filter-item-key${index}`} style={Styles.filterInputWrapper}>
        {component.props.$label ? (
          <label style={Styles.filterLabel}>{component.props.$label}</label>
        ) : null}
        {React.cloneElement(component, {
          $disabled: disabled,
          $localFilters: localFilters,
          $updateLocalFilters: (set) => {
            setLocalFilters({ ...localFilters, ...set })
          },
          $updateFilters: (set) => {
            const updatedFilters = { ...localFilters, ...set }
            setLocalFilters({ ...localFilters, ...set })
            callOnFilter(updatedFilters)
          }
        })}
      </div>
    )
  })

  const filtersContent = (
    <div style={Styles.container}>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start' }}>
        <h2>Filters</h2>
        <Button
          shape='circle'
          style={{ border: 0, marginTop: 0, boxShadow: 'none' }}
          icon='close'
          onClick={() => {
            setVisible(false)
            setLocalFilters(filters)
          }}
        />
      </div>
      {parsedChildren || 'No filters are available.'}
      <div style={{ ...Styles.filterFooterWrapper, justifyContent: typeof onReset === 'function' ? Styles.filterFooterWrapper.justifyContent : 'flex-end' }}>
        {parsedChildren && typeof onReset === 'function' ? (
          <Button
            type='danger'
            onClick={() => {
              callOnReset()
              setVisible(false)
            }}
          >{resetLabel}</Button>
        ) : null}
        {parsedChildren && typeof onFilter === 'function' ? (
          <Button
            type='primary'
            onClick={() => {
              callOnFilter(localFilters)
              setVisible(false)
            }}
          >{filterLabel}</Button>
        ) : null}
      </div>
    </div>
  )

  const filterTagsContent = (
    <div style={Styles.filterTagsWrapper}>
      {iterateChildren(children, (component, index) => {
        const tagConfig = typeof component.props.$tag === 'function'
          ? component.props.$tag(filters, component.props)
          : null

        if (!tagConfig) {
          return null
        }

        const tagProps = {
          key: `tag-key${index}`,
          style: { marginBottom: 5, whiteSpace: 'normal' },
          visible: tagConfig.visible,
          closable: Boolean(tagConfig.reset),
          onClose: () => {
            const updatedFilters = { ...localFilters, ...tagConfig.reset }
            setLocalFilters({ ...localFilters, ...tagConfig.reset })
            callOnFilter(updatedFilters)
          }
        }

        if ('component' in tagConfig) {
          return tagConfig.component ? React.cloneElement(tagConfig.component, tagProps) : null
        } else {
          return <Tag {...tagProps}>{tagConfig.label}</Tag>
        }
      })}
    </div>
  )

  return (
    <div {...remainingProps}>
      <Input.Search
        size='large'
        disabled={disabled}
        placeholder={placeholder}
        allowClear={!disableClear}
        value={localFilters.keywords}
        onChange={(evt) => setLocalFilters({ ...localFilters, keywords: String(evt.target.value) })}
        enterButton
        onSearch={(value) => {
          const updatedFilters = { ...localFilters, keywords: String(value) }
          setLocalFilters(updatedFilters)
          callOnFilter(updatedFilters)
        }}
        style={{ width: '100%' }}
        addonBefore={(
          <Popover
            content={filtersContent}
            placement='bottomLeft'
            trigger='click'
            visible={visible}
            onVisibleChange={setVisible}
          >
            <span style={{ cursor: 'pointer' }}><Icon type='filter' /> Filters</span>
          </Popover>
        )}
      />
      {isDesktop ? filterTagsContent : null}
    </div>
  )
}

InputSearchWithFilter.MerchantSearch = function InputSearchWithFilter__MerchantSearch ({
  MerchantSearch,
  merchantIdKey = 'merchantId',
  merchantNameKey = 'merchantName',

  $localFilters,
  $updateLocalFilters
}) {
  return (
    <MerchantSearch
      value={$localFilters[merchantIdKey]}
      defaultMerchant={{
        id: $localFilters[merchantIdKey],
        name: $localFilters[merchantNameKey]
      }}
      onChange={(merchantId, merchantName) => {
        $updateLocalFilters({ [merchantIdKey]: merchantId, [merchantNameKey]: merchantName })
      }}
    />
  )
}

InputSearchWithFilter.DriverSearch = function InputSearchWithFilter__DriverSearch ({
  DriverSearch,
  driverIdKey = 'driverId',
  driverNameKey = 'driverName',

  $localFilters,
  $updateLocalFilters
}) {
  return (
    <DriverSearch
      value={$localFilters[driverIdKey]}
      defaultDriver={{
        id: $localFilters[driverIdKey],
        fullName: $localFilters[driverNameKey]
      }}
      onChange={(driverId, driverName) => {
        $updateLocalFilters({ [driverIdKey]: driverId, [driverNameKey]: driverName })
      }}
    />
  )
}

InputSearchWithFilter.AdminSearch = function InputSearchWithFilter__AdminSearch ({
  AdminSearch,
  adminIdKey = 'adminId',
  adminNameKey = 'adminName',

  $localFilters,
  $updateLocalFilters
}) {
  return (
    <AdminSearch
      value={$localFilters[adminIdKey]}
      defaultAdmin={{
        uid: $localFilters[adminIdKey],
        displayName: $localFilters[adminNameKey]
      }}
      onChange={(adminId, adminName) => {
        $updateLocalFilters({ [adminIdKey]: adminId, [adminNameKey]: adminName })
      }}
    />
  )
}

InputSearchWithFilter.RadioButtons = function InputSearchWithFilter__RadioButtons ({
  filterKey,
  buttons,

  $localFilters,
  $updateLocalFilters,
  $disabled
}) {
  let remainingWidth = 100
  const equalWidth = Math.floor(100 / buttons.length)

  return (
    <Radio.Group
      disabled={$disabled}
      buttonStyle='solid'
      value={$localFilters[filterKey]}
      onChange={(evt) => $updateLocalFilters({ [filterKey]: evt.target.value })}
      style={{ width: '100%' }}
    >
      {buttons.map((button, index) => {
        const width = index === buttons.length - 1
          ? remainingWidth
          : equalWidth

        remainingWidth = remainingWidth - width

        return (
          <Radio.Button
            key={button.value}
            style={{ textAlign: 'center', width: `${width}%` }}
            value={button.value}
          >
            {button.label}
          </Radio.Button>
        )
      })}
    </Radio.Group>
  )
}

InputSearchWithFilter.Select = function InputSearchWithFilter__Select ({
  filterKey,
  options,
  multiple = false,
  disableClear = false,
  placeholder = '',

  $localFilters,
  $updateLocalFilters,
  $disabled
}) {
  return (
    <Select
      disabled={$disabled}
      allowClear={!disableClear}
      value={$localFilters[filterKey]}
      onChange={(value) => $updateLocalFilters({ [filterKey]: value })}
      placeholder={placeholder}
      style={{ width: '100%' }}
      {...(multiple && { mode: 'multiple' })}
    >
      {options.map((option) => {
        return (
          <Select.Option
            key={option.value}
            value={option.value}
          >
            {option.label}
          </Select.Option>
        )
      })}
    </Select>
  )
}

InputSearchWithFilter.Checkbox = function InputSearchWithFilter__Checkbox ({
  filterKey,
  children,

  $localFilters,
  $updateLocalFilters,
  $disabled
}) {
  return (
    <Checkbox
      disabled={$disabled}
      checked={$localFilters[filterKey]}
      onChange={(evt) => $updateLocalFilters({ [filterKey]: evt.target.checked })}
    >
      {children}
    </Checkbox>
  )
}

InputSearchWithFilter.DateRange = function InputSearchWithFilter__DateRange ({
  startDateKey,
  endDateKey,
  showTime = false,
  disableClear = false,

  $localFilters,
  $updateLocalFilters,
  $disabled
}) {
  return (
    <DatePicker.RangePicker
      disabled={$disabled}
      showTime={showTime}
      allowClear={!disableClear}
      value={(
        $localFilters[startDateKey] && $localFilters[endDateKey]
          ? [moment($localFilters[startDateKey]), moment($localFilters[endDateKey])]
          : undefined
      )}
      onChange={(dates) => {
        dates.sort((a, b) => a.valueOf() - b.valueOf())

        if (!dates[1]) {
          dates[1] = dates[0]
        }

        dates = dates.filter(Boolean)

        if (dates.length === 0) {
          $updateLocalFilters({ [startDateKey]: null, [endDateKey]: null })
        }

        if (dates.length === 2 && isValidDate(dates[0].toDate()) && isValidDate(dates[1].toDate())) {
          $updateLocalFilters({ [startDateKey]: dates[0].toDate(), [endDateKey]: dates[1].toDate() })
        }
      }}
    />
  )
}

const Styles = {
  container: {
    maxWidth: 400,
    padding: 5
  },
  filterInputWrapper: {
    marginBottom: 15,
    width: 350
  },
  filterFooterWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 15
  },
  filterLabel: {
    fontWeight: 600,
    marginBottom: 5
  },
  filterTagsWrapper: {
    marginTop: 10
  }
}

InputSearchWithFilter.propTypes = {
  filters: PropTypes.shape({
    keywords: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired
  }).isRequired,
  onReset: PropTypes.func,
  onFilter: PropTypes.func,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  disableClear: PropTypes.bool,
  resetLabel: PropTypes.string,
  filterLabel: PropTypes.string,
  keywordsAsArray: PropTypes.bool,
  children: PropTypes.node
}

function convertStringKeywordsToArray (value) {
  return (String(value || '').match(/(?:[^\s"]+|"[^"]*")+/gu) || [])
    .filter(Boolean)
    .map((term) => term.replace(/^"(.*)"$/u, '$1'))
}

function convertArrayKeywordsToString (value) {
  return (value || [])
    .map((term) => term.includes(' ') ? `"${term}"` : term)
    .join(' ')
}
