/**
 * A sort function factory that generates sort functions that
 * sort by property or a nested property.
 *
 * If the property is not found then the value tested in the
 * sort is the object/item itself.
 *
 * @example
 * const ppl = [
 *   { name: 'Dave', child: { name: 'Becki' } },
 *   { name: 'Mike', child: { name: 'Alex' } }
 * ]
 * ppl.sort(sorter('child.name', { direction: 'asc' })) // [{name:'Mike',...},{name'Dave',...}]
 * @param {string|{ orderBy?: string, direction?: 'asc'|'desc', locale?: string, sensitivity?: 'base'|'accent'|'case'|'variant', caseFirst?: 'upper'|'lower'|'false' }} [orderBy] The property or property chain to order by
 * @param {{ direction?: 'asc'|'desc', locale?: string, sensitivity?: 'base'|'accent'|'case'|'variant', caseFirst?: 'upper'|'lower'|'false' }} [options]
 * @return {(a, b) => number}
 */
function sorter (orderBy, { direction = 'desc', locale = 'en', sensitivity = 'variant', caseFirst = 'false' } = {}) {
  if (arguments.length === 0) {
    orderBy = ''
  }

  if (arguments.length === 1 && Object(orderBy) === orderBy) {
    // @ts-ignore
    ({ orderBy = '', direction = 'desc', locale = 'en', sensitivity = 'variant', caseFirst = 'false' } = orderBy)
  }

  if (typeof orderBy !== 'string') {
    throw new TypeError('Argument "orderBy" must be a string')
  }

  if (direction !== 'desc' && direction !== 'asc') {
    throw Object.assign(
      new Error(`Argument "options.direction" must be either 'desc' or 'asc' : ${direction}`),
      {
        name: 'ArgumentError',
        argName: 'options.direction',
        argValue: direction
      }
    )
  }

  if (typeof locale !== 'string') {
    throw new TypeError('Argument "options.locale" must be a string')
  }

  if (!locale.trim()) {
    throw Object.assign(
      new Error('Argument "options.locale" cannot be blank'),
      { name: 'ArgumentError', argName: 'options.locale', argValue: locale }
    )
  }

  if (!['base', 'accent', 'case', 'variant'].includes(sensitivity)) {
    throw Object.assign(
      new Error(`Argument "options.sensitivity" must be either 'base', 'accent', 'case', 'variant' : ${sensitivity}`),
      {
        name: 'ArgumentError',
        argName: 'options.sensitivity',
        argValue: sensitivity
      }
    )
  }

  if (!['upper', 'lower', 'false'].includes(caseFirst)) {
    throw Object.assign(
      new Error(`Argument "options.caseFirst" must be either 'upper', 'lower', 'false' : ${caseFirst}`),
      {
        name: 'ArgumentError',
        argName: 'options.caseFirst',
        argValue: caseFirst
      }
    )
  }

  return (a, b) => {
    const aGet = get(a, orderBy)
    const bGet = get(b, orderBy)

    a = aGet !== null && aGet !== undefined ? valueOf(aGet) : a
    b = bGet !== null && bGet !== undefined ? valueOf(bGet) : b

    if (typeof a === 'string') {
      const comp = a.localeCompare(b, locale, { usage: 'sort', sensitivity, caseFirst })
      return direction === 'asc' ? comp : -comp
    } else {
      return direction === 'asc' ? a - b : b - a
    }
  }
}

// Retrieve the value of a key or key path on an object.
// If the key or path cannot be found on the object then
// returns 'null'.
const get = (obj, path) => {
  if (Object(obj)) {
    let o = obj
    const segments = path.split('.')
    let segment = ''

    while (segments.length && Object(o) === o) {
      segment = segments.shift()
      o = o[segment]
    }

    return segments.length ? undefined : o
  } else {
    return null
  }
}

// Get the primitive value of an object.
// Calls toDate() if it exists on the object, otherwise
// attempts to call valueOf(). If valueOf() is not present
// then the entire object is returned.
// Date instances are returned as-is.
const valueOf = (obj) => {
  if (obj instanceof Date) {
    return obj
  // An object
  } else if (Object(obj) === obj) {
    // A Firebase Timestamp or object that converts to a Date instance
    if (typeof obj.toDate === 'function') {
      return obj.toDate()
    // A moment object or a value object
    // (should be all objects since this is part of the Object API)
    } else if (typeof obj.valueOf === 'function') {
      return obj.valueOf()
    // For safety we fallback to the object iteself
    } else {
      return obj
    }
  // A literal value
  } else {
    return obj
  }
}

module.exports = exports = sorter
