const { DateOnly } = require('../../types')

/**
 * Determines if a string is a valid calendar date.
 * Format: MM-DD
 * @example isCalendarDay('07-13') // true
 * @example isCalendarDay('3-27') // false
 */
function isCalendarDay (dayStr) {
  const notification = DateOnly.from('2000-' + dayStr)
  return notification.errors.length === 0
}

exports.isCalendarDay = isCalendarDay
