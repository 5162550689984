import React from 'react'
import PropTypes from 'prop-types'
import { Popover } from 'antd'

export default function HoverPhoto ({ title, photoSrc, thumbnailSize, displaySize, placement }) {
  const largeImage = (
    <img src={photoSrc} width={displaySize} alt='' />
  )

  return (
    <Popover content={largeImage} title={title} placement={placement}>
      <img src={photoSrc} width={thumbnailSize} alt='' />
    </Popover>
  )
}

HoverPhoto.defaultProps = {
  thumbnailSize: 25,
  displaySize: 300,
  placement: 'right'
}

HoverPhoto.propTypes = {
  title: PropTypes.string.isRequired,
  photoSrc: PropTypes.string.isRequired,
  thumbnailSize: PropTypes.number,
  displaySize: PropTypes.number,
  placement: PropTypes.string
}
