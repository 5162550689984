import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Input, Form } from 'antd'
import { LoadingWrapper } from '../../LoadingWrapper'

export function PenalizeDriverDialog ({
  deps
}) {
  const {
    send,
    current,
    data
  } = deps

  const context = data.penalizeDriverContext || {}

  const showPenalizeDriverDialog = current.within('Showing Penalize Driver Dialog')
  const isBusy = current.within('Penalizing Driver')

  const [reason, setReason] = React.useState('')

  const resetState = () => {
    setReason('')
  }

  React.useEffect(() => {
    if (!showPenalizeDriverDialog) {
      resetState()
    }
  }, [showPenalizeDriverDialog])

  return (
    <Modal
      title={`Penalize Driver for ${context.orderId || ''}`}
      visible={showPenalizeDriverDialog}
      onOk={() => {
        if (!reason) {
          Modal.warning({
            title: 'Missing information',
            content: 'Please enter a reason'
          })
        } else {
          send({ type: 'penalize driver with reason', orderUuid: context.orderUuid, reason })
        }
      }}
      onCancel={() => {
        send('close penalize driver dialog')
      }}
    >
      <LoadingWrapper active={isBusy}>
        <Form.Item label='Reason' required>
          <Input
            value={reason}
            onChange={(evt) => setReason(evt.target.value)}
          />
        </Form.Item>
      </LoadingWrapper>
    </Modal>
  )
}

PenalizeDriverDialog.propTypes = {
  deps: PropTypes.exact({
    send: PropTypes.func,
    current: PropTypes.object,
    data: PropTypes.object
  }).isRequired
}
