/**
 * Contains KmlCacheItems as values of properties. Eg. {{fetchableKmlUrl:string}:KmlCacheItem, ...}
 * @typedef {object} KmlCache
 * @see KmlCacheItem
 */

/**
 * Used in KmlCache
 * @typedef {object} KmlCacheItem
 * @property {XMLDocument} kmlDoc
 * @property {{ city:string, type:'delivery area'|'delivery zone'|'pickup area', coords:[number, number][] }[]} polys
 * @see KmlCache
 */

/**
 * @type {KmlCache}
 */
const cache = {}

/**
 * @param {object} options
 * @param {string} options.fetchableKmlUrl
 * @param {boolean} [options.bustCache]
 * @returns {Promise<XMLDocument>}
 */
const loadKml = async ({ fetchableKmlUrl, bustCache }) => {
  if (bustCache && cache[fetchableKmlUrl]) {
    delete cache[fetchableKmlUrl]
  }

  if (cache[fetchableKmlUrl] && cache[fetchableKmlUrl].kmlDoc) {
    return cache[fetchableKmlUrl].kmlDoc
  }

  const resp = await fetch(fetchableKmlUrl)

  if (!resp.ok) {
    throw new Error(`Failed to load KML :: (${resp.status}) ${await resp.text()}`)
  }

  const parser = new DOMParser()
  const kml = await resp.text()

  if (!cache[fetchableKmlUrl]) {
    cache[fetchableKmlUrl] = {}
  }

  cache[fetchableKmlUrl].kmlDoc = parser.parseFromString(kml, 'application/xml')

  return cache[fetchableKmlUrl].kmlDoc
}

/**
 * @param {object} options
 * @param {string} options.fetchableKmlUrl
 * @param {boolean} [options.bustCache]
 * @returns {Promise<{city: string, type: ("delivery area"|"delivery zone"|"pickup area"), coords: number[][]}[]>}
 */
export const getKmlPolys = async ({
  fetchableKmlUrl,
  bustCache
}) => {
  if (bustCache && cache[fetchableKmlUrl]) {
    delete cache[fetchableKmlUrl]
  }

  if (cache[fetchableKmlUrl] && cache[fetchableKmlUrl].polys) {
    return cache[fetchableKmlUrl].polys.slice()
  }

  const kmlDoc = await loadKml({ fetchableKmlUrl, bustCache })
  const placeEls = [...kmlDoc.querySelectorAll('Placemark')]

  const polys = placeEls.map((el) => {
    const name = el.querySelector('name').innerHTML
    let [city, type] = name.split(/\s+\(/u)
    type = type.replace(/\)/u, '').trim()

    // lat,lon pairs
    const coords = el.querySelector('Polygon outerBoundaryIs coordinates').innerHTML.trim().split('\n').map((s) => {
      return s.trim().split(',').slice(0, 2).reverse().map(Number)
    })

    return { city, type, coords }
  })

  if (!cache[fetchableKmlUrl]) {
    cache[fetchableKmlUrl] = {}
  }

  cache[fetchableKmlUrl].polys = polys.slice()

  return cache[fetchableKmlUrl].polys
}
