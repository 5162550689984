// Valid license plate rules:
// - Can only have a alphanumeric characters and hyphen OR space
// - Without the hyphen/space character must be between 2 and 8 characters
module.exports = function validateLicensePlate (licensePlate) {
  const checkForHyphenAndSpacePattern = /\s|-/gu

  licensePlate = String(licensePlate)

  if ((licensePlate.match(checkForHyphenAndSpacePattern) || []).length > 1) {
    return false
  }

  const licensePlateWithoutSpacesOrHyphens = licensePlate.replace(/\s|-/gu, '')
  return !(/[^A-Za-z0-9]/u.test(licensePlateWithoutSpacesOrHyphens)) && licensePlateWithoutSpacesOrHyphens.length >= 2 && licensePlateWithoutSpacesOrHyphens.length <= 8
}
