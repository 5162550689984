const { objectify } = require('./objectify')

// A factory function that returns a JSON.stringify and/or objectify replacer
// function that handles circular references.
function circularReplacer () {
  const wkmap = new WeakMap()

  return (key, value) => {
    if (wkmap.has(value)) {
      return undefined
    } else if (Object(value) === value) {
      wkmap.set(value, true)
      return value
    } else {
      return value
    }
  }
}

function doHydrateFirestoreObject (staticFirestore, property, value) {
  if (Object(value) === value && !Array.isArray(value)) {
    if (!(value instanceof staticFirestore.Timestamp) && '_seconds' in value && '_nanoseconds' in value) {
      value = new staticFirestore.Timestamp(value._seconds, value._nanoseconds)
    } else if (!(value instanceof staticFirestore.GeoPoint) && '_latitude' in value && '_longitude' in value) {
      value = new staticFirestore.GeoPoint(value._latitude, value._longitude)
    }
  }

  return value
}

function hydrateFirestoreObject (staticFirestore, obj) {
  const _circularReplacer = circularReplacer()

  return objectify(obj, (k, v) => {
    return doHydrateFirestoreObject(staticFirestore, k, _circularReplacer(k, v))
  })
}

function doConvertDatesToFirestoreTimestamps (staticFirestore, property, value) {
  if (Object(value) === value && !Array.isArray(value)) {
    if ((value instanceof Date)) {
      value = staticFirestore.Timestamp.fromDate(value)
    }
  }

  return value
}

function convertDatesToFirestoreTimestamps (staticFirestore, obj) {
  const _circularReplacer = circularReplacer()

  return objectify(obj, (k, v) => {
    return doConvertDatesToFirestoreTimestamps(staticFirestore, k, _circularReplacer(k, v))
  })
}

exports.convertDatesToFirestoreTimestamps = convertDatesToFirestoreTimestamps
exports.hydrateFirestoreObject = hydrateFirestoreObject
