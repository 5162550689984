/**
 * Helper function to stringify a URL together. The reason for the wrapper is to take into account
 * accepting a key/value object of query params
 * @param {string} url
 * @param {string} [baseUrl]
 * @param {object} [queryParams] - eg. { queryName: queryValue, queryName2: [queryValue2, queryValue3, ...], ... }
 * @returns {string}
 */
export function stringifyUrl (url, { baseUrl, queryParams } = {}) {
  // Unfortunate workaround due to Safari < 14 bug
  // Reference: https://bugs.webkit.org/show_bug.cgi?id=216841
  const modifiedUrl = baseUrl === undefined
    ? new URL(url)
    : new URL(url, baseUrl)

  if (queryParams) {
    for (const [paramKey, paramValue] of Object.entries(queryParams)) {
      if (Array.isArray(paramValue)) {
        paramValue.forEach((arrayVal) => {
          if (arrayVal !== null && arrayVal !== undefined) {
            modifiedUrl.searchParams.append(paramKey, arrayVal)
          }
        })
      } else {
        if (paramValue !== null && paramValue !== undefined) {
          modifiedUrl.searchParams.set(paramKey, paramValue)
        }
      }
    }
  }

  return modifiedUrl.toString()
}
