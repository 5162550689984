import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { COLORS } from '@trexity/common/color/constants'
import { gsap } from 'gsap'
import { ButtonBase } from './button.styles'

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'

export const Button = ({
  onClick = () => {},
  animatingIn = true,
  animatingOut,
  animatingDelay = 0,
  children,
  loading = false,
  bg = COLORS.TREXITY_BLUE,
  size,
  type,
  disabled,
  disabledToolTip,
  onFocus = () => { },
  onBlur = () => { },
  ...rest
}) => {
  const button = useRef()
  const spinnyT = useRef()
  const [active, setActive] = useState(false)

  useEffect(() => {
    const tl = gsap.timeline()

    if (animatingIn) {
      tl.to(button.current, { y: 0, opacity: 1, delay: animatingDelay })
    } else if (animatingOut) {
      tl.to(button.current, { y: 20, opacity: 0 })
    }
  }, [animatingIn, animatingOut, animatingDelay])

  useEffect(() => {
    if (disabled) {
      gsap.to(button.current, { opacity: 0.2 })
    } else {
      gsap.to(button.current, { opacity: 1 })
      const existing = document.querySelector('.trx-button-tooltip')

      const body = document.querySelector('body')

      if (existing) {
        body.removeChild(existing)
      }
    }
  }, [disabled])

  useEffect(() => {
    if (loading) {
      gsap.to(spinnyT.current, { rotate: 15000, duration: 180 })
    }
  }, [loading])

  const setSize = () => {
    switch (size) {
      case 'small':
        return { font: 10, height: 20 }
      case 'medium':
        return { font: 14, height: 44 }
      case 'large':
        return { font: 18, height: 70 }
      default:
        return { font: 14, height: 44 }
    }
  }

  return (
    <button
      ref={button}
      type={type}
      onClick={(e) => {
        if (!disabled) {
          onClick(e)
        } else if (disabledToolTip) {
          const existing = document.querySelector('.trx-button-tooltip')

          const body = document.querySelector('body')

          if (existing) {
            body.removeChild(existing)
          }

          const div = document.createElement('div')
          div.classList.add('trx-button-tooltip')
          const box = button.current.getBoundingClientRect()
          let left = box.left - 50

          if (disabledToolTip.length > 30) {
            left = box.left - 150
          }

          div.style = `background-color:${COLORS.TREXITY_BLUE};position:fixed;left:${left}px;top:${box.top - 50}px;color:${COLORS.WHITE};padding:5px 20px;border-radius:5px;opacity:0;`
          div.innerText = disabledToolTip

          div.addEventListener('click', () => {
            body.removeChild(div)
          })
          body.appendChild(div)
          gsap.to('.trx-button-tooltip', { opacity: 1 })

          setTimeout(() => {
            try {
              gsap.to('.trx-button-tooltip', {
                opacity: 0,
                onComplete: () => {
                  const existing = document.querySelector('.trx-button-tooltip')

                  if (existing) {
                    body.removeChild(existing)
                  }
                }
              })
            } catch (e) {
              console.log(e)
            }
          }, 5000)
        }
      }}
      onFocus={(e) => { onFocus(e); setActive(true) }}
      onBlur={(e) => { onBlur(e); setActive(false) }}
      css={[ButtonBase, ButtonAnimation(active), ButtonStyles(bg, setSize(), disabled)]}
      {...rest}
    >
      {loading
        ? <div style={{ fontFamily: 'CaslonGraphiqueEF' }} ref={spinnyT}>T</div>
        : children}
    </button>
  )
}

export const ButtonAnimation = (active) => ({
  opacity: 0,
  boxShadow: active ? `0px 0px 5px ${COLORS.TREXITY_BLUE}` : 'none',
  transform: 'translateY(20px)',
  transition: 'none'
})

export const ButtonStyles = (bg, size, disabled = false) => ({
  backgroundColor: disabled ? COLORS.MEDIUM : bg,
  fontSize: size.font,
  height: size.height,
  borderColor: bg,
  opacity: disabled ? 0.1 : 1,
  color: COLORS.WHITE
})

Button.propTypes = {
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  children: PropTypes.node,
  animatingIn: PropTypes.bool,
  animatingOut: PropTypes.bool,
  animatingDelay: PropTypes.number,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  bg: PropTypes.string,
  size: PropTypes.string,
  disabledToolTip: PropTypes.string
}
