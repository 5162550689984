import * as React from 'react'
import { stateValueLeafIds } from '../util'

const debouncedTreeLog = makeDebouncedFn(treeLog)

const defaultOptions = {
  immediate: true
}

export function useMachine (
  machine,
  interpret,
  context,
  refId,
  {
    onSend = null,
    machineOptions = defaultOptions,
    enableLogging = false
  } = {}
) {
  // Reference the service
  const serviceRef = React.useRef(null)

  // Keep track of the current machine state
  const [current, setCurrent] = React.useState(null)

  // Create the service only once
  // See https://reactjs.org/docs/hooks-faq.html#how-to-create-expensive-objects-lazily
  if (serviceRef.current === null) {
    const { guards, actions, activities, services, delays } = machineOptions

    const customMachine = machine.withConfig({
      guards,
      actions,
      activities,
      services,
      delays
    }).withContext(context)

    serviceRef.current = interpret(customMachine, machineOptions)

    const listener = (state) => {
      // Update the current machine state when a transition occurs
      if (state.changed) {
        enableLogging && logStateValue(refId, state.value)
        setCurrent(state)
      }
    }

    serviceRef.current.onTransition(listener)

    const send = serviceRef.current.send

    serviceRef.current.send = (...args) => {
      enableLogging && logSend(refId, args)

      if (typeof onSend === 'function') {
        onSend(args, serviceRef.current, context)
      }

      send(...args)
    }

    setCurrent(serviceRef.current.initialState)

    // @ts-ignore
    window.service = {
      // @ts-ignore
      ...window.service,
      [refId]: {
        current: serviceRef.current,
        data: context
      }
    }
  }

  const service = serviceRef.current

  // Start service immediately (before mount) if specified in machineOptions
  if (machineOptions && machineOptions.immediate) {
    service.start()
  }

  React.useEffect(() => {
    // Start the service when the component mounts.
    // Note: the service will start only if it hasn't started already.
    service.start()

    enableLogging && logStateValue(refId, service.state.value, { label: 'initial state' })

    return () => {
      // Stop the service when the component unmounts
      service.stop()
    }
  }, [])

  return {
    data: context,
    current: {
      ...current,
      matches: (matches) => (
        Array.isArray(matches)
          ? matches.some((value) => current.matches(value))
          : current.matches(matches)
      ),
      is: (id) => (
        Array.isArray(id)
          ? id.some((value) => stateValueLeafIds(current.value).includes(value))
          : stateValueLeafIds(current.value).includes(id)
      ),
      within: (id) => {
        const states = current.toStrings()

        if (Array.isArray(id)) {
          return id.some((value) => {
            const count = value.split('.').length
            return states.some((state) => state.split('.').slice(-count).join('.') === value)
          })
        } else {
          const count = id.split('.').length
          return states.some((state) => state.split('.').slice(-count).join('.') === id)
        }
      }
    },
    send: service.send,
    service
  }
}

// -----------------------------------------------------------------------------

function logStateValue (refId, stateValue, {
  label = 'state'
} = {}) {
  const key = refId + JSON.stringify(stateValue, getCircularReplacer())
  debouncedTreeLog(key, `${new Date().toISOString()} [useMachine] [${refId}] ${label}:`, stateValue)
}

function logSend (refId, args) {
  const type = typeof args[0] === 'string'
    ? args[0]
    : args[0].type

  const title = `${new Date().toISOString()} [useMachine] [${refId}] send: ${type}`

  const logArgs = typeof args[0] !== 'string'
    ? Object.entries(args[0]).filter(([key]) => key !== 'type').reduce((memo, [key, value]) => ({
      ...memo,
      [key]: stringifyArg(value)
    }), {})
    : {}

  treeLog(title, {
    ...logArgs,
    ...(args.length > 1 && { extraArgs: args.slice(1) })
  })
}

function treeLog (title, item, {
  maxDepth = 100,
  depth = 0,
  log = console.log.bind(console),
  group = console.group.bind(console),
  groupEnd = console.groupEnd.bind(console),
  groupCollapsed = console.groupCollapsed.bind(console)
} = {}) {
  if (depth === 0) {
    groupCollapsed(title)
  }

  if (depth > maxDepth) {
    log(item)
    return
  }

  if (typeof item === 'object' && item !== null) {
    Object.entries(item).forEach(([key, value]) => {
      group(key)

      treeLog(title, value, {
        maxDepth,
        depth: depth + 1,
        log,
        group,
        groupEnd,
        groupCollapsed
      })

      groupEnd()
    })
  } else {
    log(item)
  }

  if (depth === 0) {
    groupEnd(title)
  }
}

function makeDebouncedFn (fn) {
  let memo = ''

  return (key, ...args) => {
    if (key !== memo) {
      fn(...args)
      memo = key
    }
  }
}

function stringifyArg (arg, { maxStringLength = 100 } = {}) {
  const circularReplacer = getCircularReplacer()

  return JSON.stringify(arg, function (key, value) {
    value = circularReplacer(key, value)
    return typeof this[key] === 'string' && this[key].length > maxStringLength ? value.substr(0, maxStringLength) + '…' : value
  }, 2)
}

function getCircularReplacer () {
  const seen = new WeakSet()

  return (key, value) => {
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) {
        return
      }

      seen.add(value)
    }

    return value
  }
}
