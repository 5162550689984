import * as React from 'react'
import { wrappedFetch as fetch, parseApiResponse } from '../util'

/**
 * @param {string} address
 * @param {Object} options
 * @param {string[]} [options.restrictedDeliveryServiceCities] Array of services that we want to restrict delivery to
 * @param {string} options.apiV1ServiceBaseUrl API V1 service base URL
 * @return {{ isLoaded:boolean, error?:Error, addressCheck?:{ pickup: boolean, delivery: 'normal'|'extended' } }}
 */
export function useAddressCheckResult (address, { restrictedDeliveryServiceCities = null, apiV1ServiceBaseUrl } = {}) {
  const [result, setResult] = React.useState({ isLoaded: false, error: null, addressCheck: null })

  React.useEffect(() => {
    let disposed = false
    setResult({ isLoaded: false, error: null, addressCheck: null })

    if (!address) return

    fetch(`${apiV1ServiceBaseUrl}/addresses/check`, {
      method: 'GET',
      queryParams: { address }
    })
      .then(parseApiResponse)
      .then((result) => (result && result.data))
      .then((result) => {
        if (disposed) return

        if (result) {
          const addressCheck = {
            lngLat: result.lngLat,
            delivery: result.delivery,
            pickup: result.pickup,
            serviceCity: result.serviceCity
          }

          const noDelivery = { ...addressCheck, delivery: 'none' }

          if (!restrictedDeliveryServiceCities || !restrictedDeliveryServiceCities.length || restrictedDeliveryServiceCities.includes(addressCheck.serviceCity)) {
            setResult({ isLoaded: true, error: null, addressCheck })
          } else {
            setResult({ isLoaded: true, error: null, addressCheck: noDelivery })
          }
        } else {
          setResult({ isLoaded: true, error: new Error('Address not found'), addressCheck: null })
        }
      })
      .catch((error) => {
        setResult({ isLoaded: true, error, addressCheck: null })
      })

    return () => {
      disposed = true
    }
  }, [address])

  return result
}
