import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { COLORS } from '@trexity/common/color/constants'

export const InputError = ({ active, children, opacity, marginBottom = 0 }) => {
  const error = useRef()
  let opacityVal = 1
  let heightVal = 0
  let paddingVal = '0px 10px'

  if (opacity && !active) {
    opacityVal = 0
  }

  if (active) {
    heightVal = 'auto'
    paddingVal = '5px 10px'
  }

  useEffect(() => {
    const len = children && children.length

    if (len && len > 55 && error.current.getBoundingClientRect().width < 420) {
      heightVal = 60
    }
  }, [children])

  return (
    <div
      ref={error}
      style={{
        marginBottom: active ? marginBottom : 0,
        borderWidth: 1,
        borderRadius: 10,
        backgroundColor: COLORS.TREXITY_RED,
        height: heightVal,
        padding: paddingVal,
        transform: active ? 'translateY(0px)' : `translateY(-${heightVal}px)`,
        display: 'flex',
        alignItems: 'center',
        transition: 'all ease-in 0.3s',
        pointerEvents: 'none',
        opacity: opacityVal,
        fontSize: '14px',
        fontWeight: 600,
        color: COLORS.WHITE
      }}
    >
      {children}
    </div>
  )
}

InputError.propTypes = {
  style: PropTypes.object,
  children: PropTypes.node,
  active: PropTypes.bool,
  opacity: PropTypes.bool,
  marginBottom: PropTypes.number
}
