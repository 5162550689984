const { Time } = require('./Time')

// Helper function that adds 'st', 'nd', 'rd' or 'th' to an integer value
// for display purposes.
const th = (x) => {
  const y = x.toString()

  if (y.endsWith('1') && x !== 11) {
    return `${x}st`
  }

  if (y.endsWith('2') && x !== 12) {
    return `${x}nd`
  }

  if (y.endsWith('3') && x !== 13) {
    return `${x}rd`
  }

  return `${x}th`
}

/**
 * A class that represents a UTC repeatable time window.
 *
 * A time window resembles a cron tab schedule, only it is parsed a bit differently
 * particularly the hour slot.
 *
 * @see TimeWindow.from
 */
exports.TimeWindow = class TimeWindow {
  /**
   * Attempts to parse a time window string into a TimeWindow.
   * Returns a notification with all errors.
   *
   * UTC is the expected referance frame.
   *
   * Format:
   *
   * '* (time) * (day) * (month)'
   *
   * time:
   *   - '*' all hours
   *   - 'n' at the nth hour of the day
   *   - '*\/n' every nth hour in the day (without space)
   *   - 'n-s' between nth and sth hour in the day
   *
   * WHERE 'n' is of the form 'HH:mm' (i.e. 24 hour time)
   *
   * day:
   *   - '*' every day of month
   *   - 'n' on the nth day of the month
   *   - 'wn' on the nth day of the week (where n is 1 = Sunday, 2 = Saturday)
   *   - '*\/n' every nth day of the month (without the backslash)
   *   - '*\/wn' every nth day of the week (without the backslask) (where n is 1 = Sunday, 2 = Saturday)
   *   - 'n-s' between the nth and sth day of the month
   *
   * Where 'n' is a number between 1 - 31
   *
   * month:
   *   - '*' every month
   *   - 'n' on the nth month
   *   - '*\/n' every nth month (without the space)
   *   - 'n-s' between the nth and sth month
   *
   * Where 'n' is a number from 1 to 12.
   *
   * @see {TimeWindow#constructor}
   * @example
   * TimeWindow.from('* * *') // every day all day every month
   * TimeWindow.from('12:00 * *') // at noon every day every month
   * TimeWindow.from('13:45 * 12') // at 1:45pm every day in December
   * TimeWindow.from('09:00 12 2') // at 9:00am on the 12th of February
   * TimeWindow.from('00:00-3:30 * *') // from midnight to 3:30am every day every month
   * @param {string} value The time window string
   * @return {{ errors: string[], value?: TimeWindow }}
   */
  static from (value) {
    const notification = { errors: [], value: null }

    if (typeof value !== 'string') {
      notification.errors.push('time window can only be convert from a string')
      return notification
    }

    const [t, d, m] = value.split(' ')
    const time = this._fromTime(t)
    const day = this._fromDay(d)
    const month = this._fromMonth(m)

    notification.errors.push(
      ...time.errors.map((e) => `time : ${e}`),
      ...day.errors.map((e) => `day : ${e}`),
      ...month.errors.map((e) => `month : ${e}`)
    )

    if (!notification.errors.length) {
      notification.value = new TimeWindow(time.value, day.value, month.value)
    }

    return notification
  }

  /**
   * @param {null | [Time] | ['*', Time] | [Time, Time]} time
   * @param {null | [number] | ['w', number] | ['*', number] | ['w*', number] | [number, number]} day
   * @param {null | [number] | ['*', number] | [number, number]} month
   */
  constructor (time, day, month) {
    if (time !== null && !Array.isArray(time)) {
      throw new TypeError('Argument "time" must be an array')
    } else if (time !== null && !(time[0] === '*' && time[1] instanceof Time) && !time.every((x) => x instanceof Time)) {
      throw new TypeError('Argument "time" must be an array of Time instances')
    }

    if (day !== null && !Array.isArray(day)) {
      throw new TypeError('Argument "day" must be an array')
    } else if (day !== null &&
        !(day[0] === 'w' && Number.isSafeInteger(day[1]) && day[1] >= 1 && day[1] <= 7) &&
        !(day.length === 2 && day[0][0] === 'w' && Number.isSafeInteger(day[0][1]) && day[0][1] >= 1 && day[0][1] <= 7) &&
        !(day.length === 2 && day[1][0] === 'w' && Number.isSafeInteger(day[1][1]) && day[1][1] >= 1 && day[1][1] <= 7) &&
        !(day[0] === 'w*' && Number.isSafeInteger(day[1]) && day[1] >= 1 && day[1] <= 7) &&
        !(day[0] === '*' && Number.isSafeInteger(day[1]) && day[1] >= 1 && day[1] <= 31) &&
        !day.every((x) => Number.isSafeInteger(x) && x >= 1 && x <= 31)) {
      throw new TypeError('Argument "day" must be an array of valid dates')
    }

    if (month !== null && !Array.isArray(month)) {
      throw new TypeError('Argument "month" must be an array')
    } else if (month !== null && !(month[0] === '*' && Number.isSafeInteger(month[1]) && month[1] >= 1 && month[1] <= 12) && !month.every((x) => Number.isSafeInteger(x) && x >= 1 && x <= 12)) {
      throw new TypeError('Argument "month" must be an array of valid months')
    }

    /** @private */
    this._time = time
    /** @private */
    this._day = day
    /** @private */
    this._month = month
  }

  /**
   * Determines if a Date overlaps this time window.
   *
   * @param {Date} date
   */
  overlapsWithDate (date) {
    return this._overlapTime(date) &&
      this._overlapDay(date) &&
      this._overlapMonth(date)
  }

  toString () {
    return [
      this._time
        ? this._time[0] === '*'
          ? `Every ${this._time[1]} hours`
          : this._time[1]
            ? `Between the hours of ${this._time.join(' and ')}`
            : `At ${this._time[0]}`
        : 'All day',
      this._day
        ? this._day[0] === '*'
          ? `every ${th(this._day[1])} day`
          : this._day[1]
            ? `from the ${this._day.map(th).join(' to the ')}`
            : `on the ${th(this._day[0])}`
        : 'of every day',
      this._month
        ? this._month[0] === '*'
          ? `every ${th(this._month[1])} month`
          : this._month[1]
            ? `from the ${this._month.map(th).join('month to ')} month`
            : `of the ${th(this._month[0])} month`
        : 'each month'
    ].join(' ')
  }

  toJSON () {
    return [
      this._time
        ? this._time.join(this._time[0] === '*' ? '/' : '-')
        : '*',
      this._day
        ? this._day.join(this._day[0] === '*' ? '/' : '-')
        : '*',
      this._month
        ? this._month.join(this._month[0] === '*' ? '/' : '-')
        : '*'
    ].join(' ')
  }

  /** @private Determines if a date's time overlaps this time window */
  _overlapTime (date) {
    const hours = date.getUTCHours()
    const minutes = date.getUTCMinutes()

    let pass = false

    if (!this._time) {
      pass = true
    } else if (this._time && this._time[0] === '*') {
      const mPrime = this._time[1].hours * 60 + this._time[1].minutes
      const m = hours * 60 + minutes
      pass = m % mPrime === 0
    } else if (this._time && this._time.length === 2) {
      // @ts-ignore
      pass = hours >= this._time[0].hours &&
      // @ts-ignore
        minutes >= this._time[0].minutes &&
        hours <= this._time[1].hours &&
        minutes <= this._time[1].minutes
    } else if (this._time) {
      // @ts-ignore
      pass = hours === this._time[0].hours &&
      // @ts-ignore
        minutes === this._time[0].minutes
    }

    return pass
  }

  /** @private Determines if a date's day overlaps this time window */
  _overlapDay (date) {
    const day = date.getUTCDate()
    const weekday = date.getUTCDay() + 1

    let pass = false

    // day
    if (!this._day) {
      pass = true
    } else if (this._day && this._day[0] === 'w*') {
      pass = weekday % this._day[1] === 0
    } else if (this._day && this._day[0] === '*') {
      pass = day % this._day[1] === 0
    } else if (this._day && this._day[0] === 'w') {
      pass = weekday === this._day[1]
    } else if (this._day && this._day.length === 2) {
      if (this._day[0][0] === 'w') {
        pass = weekday >= this._day[0][1]
      } else {
        pass = day >= this._day[0]
      }

      if (pass && this._day[1][0] === 'w') {
        pass = weekday <= this._day[1][1]
      } else if (pass) {
        pass = day <= this._day[1]
      }
    } else if (this._day) {
      pass = day === this._day[0]
    }

    return pass
  }

  /** @private Determines if a date's month overlaps this time window */
  _overlapMonth (date) {
    const month = date.getUTCMonth() + 1

    let pass = false

    if (!this._month) {
      pass = true
    } else if (this._month && this._month[0] === '*') {
      pass = month % this._month[1] === 0
    } else if (this._month && this._month.length === 2) {
      pass = month >= this._month[0] &&
        month <= this._month[1]
    } else if (this._month) {
      pass = month === this._month[0]
    }

    return pass
  }

  /**
   * @private
   * @param {string} time
   * @return {{ errors: string[], value: null | [Time] | ['*', Time] | [Time, Time] }}
   */
  static _fromTime (time) {
    const notification = { errors: [], value: null }

    if (time === '*') {
      return notification
    // repeat
    } else if (time.startsWith('*/')) {
      const n = Time.from(time.slice(2))
      notification.errors.push(...n.errors)

      notification.value = notification.errors.length
        ? null
        : ['*', n.value]

      return notification
    // range
    } else if (time.includes('-')) {
      const list = time.split('-').slice(0, 2).map(Time.from)
      notification.errors.push(...list[0].errors.map((e) => `time 1 : ${e}`))
      notification.errors.push(...list[1].errors.map((e) => `time 2 : ${e}`))

      if (!notification.errors.length) {
        if (list[0].value.hours < list[1].value.hours) {
          notification.value = list.map((x) => x.value)
        } else {
          notification.errors.push('hours range is in reverse')
        }
      }

      return notification
    // fixed value
    } else {
      const n = Time.from(time)
      notification.errors.push(...n.errors)
      notification.value = [n.value]
      return notification
    }
  }

  /**
   * @private
   * @param {string} day
   * @return {{ errors: string[], value: null | [number] | ['*', number] | [number, number] }}
   */
  static _fromDay (day) {
    const notification = { errors: [], value: null }

    if (day === '*') {
      return notification
    } else if (day.startsWith('w') && !day.includes('-')) {
      const wd = parseInt(day.slice(1), 10)

      if (wd < 1 || wd > 7) {
        notification.errors.push('weekday is out of range')
      } else {
        notification.value = ['w', wd]
      }

      return notification
    // repeat weekday
    } else if (day.startsWith('*/w')) {
      const wd = parseInt(day.slice(3), 10)

      if (wd < 1 || wd > 7) {
        notification.errors.push('weekday is out of range')
      } else {
        notification.value = ['w*', wd]
      }

      return notification
    // repeat
    } else if (day.startsWith('*/')) {
      const d = parseInt(day.slice(2), 10)

      if (d < 1 || d > 31) {
        notification.errors.push('day is out of range')
        notification.value = null
      } else {
        notification.value = ['*', d]
      }

      return notification
    // range
    } else if (day.includes('-')) {
      const days = day.split('-').slice(0, 2)
      const n = days.map((d) => this._fromDay(d))

      notification.errors = [
        ...n[0].errors.map((s) => `day 1: ${s}`),
        ...n[1].errors.map((s) => `day 2: ${s}`)
      ]

      if (!notification.errors.length) {
        notification.value = n.map((x) => {
          // If the value is [n] then we need to rip the number out
          return Array.isArray(x.value) && x.value.length === 1
            ? x.value[0]
            : x.value
        })

        if (!(
          notification.value.some(Array.isArray) ||
          (notification.value[0] < notification.value[1])
        )) {
          notification.value = null
          notification.errors.push('day range is in reverse')
        }
      }

      return notification
    // fixed value
    } else {
      const d = parseInt(day, 10)

      if (isNaN(d) || d < 1 || d > 31) {
        notification.errors.push('day is out of range')
        notification.value = null
      } else {
        notification.value = [d]
      }

      return notification
    }
  }

  /**
   * @private
   * @param {string} month
   * @return {{ errors: string[], value: null | [number] | ['*', number] | [number, number] }}
   */
  static _fromMonth (month) {
    const notification = { errors: [], value: null }

    if (month === '*') {
      return notification
    // repeat
    } else if (month.startsWith('*/')) {
      const m = parseInt(month.slice(2), 10)

      if (isNaN(m) || m < 1 || m > 12) {
        notification.errors.push('month is out of range')
        notification.value = null
      } else {
        notification.value = ['*', m]
      }

      return notification
    // range
    } else if (month.includes('-')) {
      const months = month.split('-').slice(0, 2)
      const m1 = parseInt(months[0], 10)
      const m2 = parseInt(months[1], 10)

      if (isNaN(m1) || m1 < 1 || m1 > 12) {
        notification.errors.push('month 1 : month is out of range')
        notification.value = null
      } else if (isNaN(m2) || m2 < 1 || m2 > 12) {
        notification.errors.push('month 2 : month is out of range')
        notification.value = null
      } else if (!notification.errors.length) {
        if (m1 < m2) {
          notification.value = [m1, m2]
        } else {
          notification.errors.push('month range is in reverse')
        }
      }

      return notification
    // fixed value
    } else {
      const m = parseInt(month, 10)

      if (isNaN(m) || m < 1 || m > 12) {
        notification.errors.push('month is out of range')
        notification.value = null
      } else {
        notification.value = [m]
      }

      return notification
    }
  }
}
exports.TimeWindow.allDayEveryDay = exports.TimeWindow.from('* * *').value
