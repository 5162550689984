const isIntervalOverlapping = require('./isIntervalOverlapping')

/**
 * @param {{fromTime: string, toTime: string}} interval1 An object describing the starting time in 24 hours format (example: { fromTime: '14:00', toTime: '15:00' })
 * @param {{fromTime: string, toTime: string}} interval2 An object describing the end time in 24 hours format (example: { fromTime: '18:00', toTime: '19:00' })
 * @return {boolean} Returns true if there is intersection between the two specified time intervals.
 */
module.exports = function isTimeIntervalOverlapping (interval1, interval2) {
  const { fromTime: aFromTime, toTime: aToTime } = interval1
  const { fromTime: bFromTime, toTime: bToTime } = interval2

  const [aFromHour, aFromMinute] = aFromTime.split(':').map((n) => Number(n))
  const [aToHour, aToMinute] = aToTime.split(':').map((n) => Number(n))
  const [bFromHour, bFromMinute] = bFromTime.split(':').map((n) => Number(n))
  const [bToHour, bToMinute] = bToTime.split(':').map((n) => Number(n))

  // Convert them to regular numbers, and reduce the problem to
  // finding out when (a, b) intersects (c, d), unsorted.
  const aFrom = aFromHour + Number((aFromMinute / 60).toFixed(3))
  const aTo = aToHour + Number((aToMinute / 60).toFixed(3))
  const bFrom = bFromHour + Number((bFromMinute / 60).toFixed(3))
  const bTo = bToHour + Number((bToMinute / 60).toFixed(3))

  return isIntervalOverlapping({ start: aFrom, end: aTo }, { start: bFrom, end: bTo })
}
