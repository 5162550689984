/*
Module that checks GeoJSON objects to determine if they are valid.

NOTE: These validation iterates over all positions to validate their values.
If you experience performance issues then use the "isGeoJSON*Fast" functions
instead of the "isGeoJSON*" functions. The fast versions don't validate the coordinate
values.

> Why "GeoJSON" and not "GeoJson"?
>
> Because GeoJSON follows the established naming convention of the JSON API from
> the EcmaScript runtime.
---
> Why "isGeoJSON" and not "isGeoJson" or "isValid"?
>
> Because "isGeoJSON" follows the established naming convention of Array.isArray
> API from the EcmaScript runtime.

Usage:

const GeoJSON = require('@trexity/common/geo/GeoJSON')

const GeoJSON.isGeoJSON({ ... })
const GeoJSON.isGeoJSONFast({ ... })

See: https://tools.ietf.org/html/rfc7946#section-3.1
*/

// A point is a pair of long,lat values.
const isPoint = (a) => {
  return Array.isArray(a) &&
    a.length === 2 &&
    a.every(Number.isFinite) &&
    // longitude
    a[0] >= -180 &&
    a[0] <= 80 &&
    // latitude
    a[1] >= -90 &&
    a[1] <= 90
}

// An array of points.
const isMultiPoint = (a) => {
  return Array.isArray(a) && a.every(isPoint)
}

// An array of 2 or more points.
const isLineString = (a) => {
  return Array.isArray(a) && a.length >= 2 && a.every(isPoint)
}

// An array of line strings.
const isMultiLineString = (a) => {
  return Array.isArray(a) && a.every(isLineString)
}

// A linear ring is a line string where the first and last points are identical.
const isLinearRing = (a) => {
  return isLineString(a) &&
    // First and last points must be identical
    a[0][0] === a[a.length - 1][0] &&
    a[0][1] === a[a.length - 1][1]
}

// An array of one or more linear rings.
const isPolygon = (a) => {
  return Array.isArray(a) &&
    a.length >= 1 &&
    a.every(isLinearRing)
}

// An array of polygons.
const isMultiPolygon = (a) => {
  return Array.isArray(a) && a.every(isPolygon)
}

/**
 * Determines if the object describes a GeoJSON geometry object.
 *
 * @param {{ type?, coordinates?, geometries? }} object
 * @return {boolean}
 */
const isGeoJSONGeometry = (object) => {
  if (Object(object) !== object) {
    return false
  }

  const { type, coordinates, geometries } = object

  switch (type) {
    case 'Point': return isPoint(coordinates)
    case 'MultiPoint': return isMultiPoint(coordinates)
    case 'LineString': return isLineString(coordinates)
    case 'MultiLineString': return isMultiLineString(coordinates)
    case 'Polygon': return isPolygon(coordinates)
    case 'MultiPolygon': return isMultiPolygon(coordinates)
    case 'GeometryCollection': return Array.isArray(geometries) &&
      geometries.every(isGeoJSONGeometry)
    default: return false
  }
}

/**
 * Determines if the object "could be" a GeoJSON geometry object without validating
 * the coordinates.
 *
 * @param {{ type?, coordinates?, geometries? }} object
 * @return {boolean}
 */
const isGeoJSONGeometryFast = (object) => {
  if (Object(object) !== object) {
    return false
  }

  const { type, coordinates, geometries } = object

  switch (type) {
    case 'Point': return Array.isArray(coordinates) && coordinates.length === 2
    case 'MultiPoint': return Array.isArray(coordinates)
    case 'LineString': return Array.isArray(coordinates)
    case 'MultiLineString': return Array.isArray(coordinates)
    case 'Polygon': return Array.isArray(coordinates)
    case 'MultiPolygon': return Array.isArray(coordinates)
    case 'GeometryCollection': return Array.isArray(geometries) &&
      geometries.every(isGeoJSONGeometryFast)
    default: return false
  }
}

const isFeature = (object) => {
  if (Object(object) !== object) {
    return false
  }

  const { type, geometry, features } = object

  switch (type) {
    case 'Feature':
      return geometry === null || isGeoJSONGeometry(geometry)
    case 'FeatureCollection':
      return Array.isArray(features) && features.every(isFeature)
    default:
      return false
  }
}

const isFeatureFast = (object) => {
  if (Object(object) !== object) {
    return false
  }

  const { type, geometry, features } = object

  switch (type) {
    case 'Feature':
      return geometry === null || isGeoJSONGeometryFast(geometry)
    case 'FeatureCollection':
      return Array.isArray(features) && features.every(isFeatureFast)
    default:
      return false
  }
}

/**
 * @param {{ type?, features?, coordinates?, geometry?, geometries? }} object
 */
const isGeoJSON = (object) => {
  if (Object(object) !== object) {
    return false
  }

  const { type } = object

  switch (type) {
    case 'Feature': return isFeature(object)
    case 'FeatureCollection': return isFeature(object)
    default: return isGeoJSONGeometry(object)
  }
}

/**
 * @param {{ type?, features?, coordinates?, geometry?, geometries? }} object
 */
const isGeoJSONFast = (object) => {
  if (Object(object) !== object) {
    return false
  }

  const { type } = object

  switch (type) {
    case 'Feature': return isFeatureFast(object)
    case 'FeatureCollection': return isFeatureFast(object)
    default: return isGeoJSONGeometryFast(object)
  }
}

exports.isGeoJSONGeometry = isGeoJSONGeometry
exports.isGeoJSON = isGeoJSON
exports.isGeoJSONGeometryFast = isGeoJSONGeometryFast
exports.isGeoJSONFast = isGeoJSONFast
