const { Std, Check } = require('../../core')
const { isServiceHours } = require('./isServiceHours')
const { isCalendarDay } = require('./isCalendarDay')
const { pad } = require('./pad')

/**
 * Retrieve a copy of the calendar day hours for the specified calendar day. If the calendar day has no
 * hours then returns null.
 *
 * @example ServiceHours.getCalendarDayHours(myServiceHours, '01-12') // Jan. 12
 * @example ServiceHours.getCalendarDayHours(myServiceHours, [1, 12]) // Jan. 12
 * @param {ServiceHours} serviceHours
 * @param {string|[number,number]|Date} day String like 'MM-DD', [month (1-based), date] tuple, or Date instance (uses local month and date)
 * @return {CalendarDeliveryDay}
 */
function getCalendarDayHours (serviceHours, day) {
  if (!isServiceHours(serviceHours)) {
    throw Std.err('TypeError', 'Service hours is invalid')
  }

  if (Check.isdate(day)) {
    day = [day.getMonth() + 1, day.getDate()]
  }

  if (Check.and(Check.isarr, Check.of(Check.isint), (x) => x.length === 2)(day)) {
    day = `${pad(day[0])}-${pad(day[1])}`
  }

  if (!isCalendarDay(day)) {
    throw Std.err('ArgumentError', `calendar day is invalid : ${day}`)
  }

  const deliveryDay = (serviceHours.calendarDays || []).find((d) => d.day === day)
  return deliveryDay ? Std.clone(deliveryDay) : null
}

exports.getCalendarDayHours = getCalendarDayHours
