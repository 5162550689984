/**
 * Get duration as an object with absolute hours, minutes and seconds components.
 *
 * @param {number} durationInSeconds
 * @returns {object}
 */
module.exports = function getDurationObject (durationInSeconds) {
  let hours = 0
  let minutes = 0
  const duration = durationInSeconds < 0 ? durationInSeconds * -1 : durationInSeconds

  if (duration > 3600) {
    hours = Math.floor(duration / 3600)
    minutes = Math.floor((duration - (hours * 3600)) / 60)
  } else {
    if (duration === 0) {
      minutes = 0
    } else if (duration < 60) {
      minutes = 0
    } else {
      minutes = Math.floor(duration / 60)
    }
  }

  const seconds = duration - (3600 * hours) - (60 * minutes)

  return {
    hours,
    minutes,
    seconds
  }
}
