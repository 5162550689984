module.exports = function generateBounds (coords) {
  let x0 = null
  let y0 = null
  let x1 = null
  let y1 = null

  for (let idx = 0, len = coords.length; idx < len; idx++) {
    const latitude = 'latitude' in coords[idx]
      ? coords[idx].latitude
      : coords[idx].lat

    const longitude = 'longitude' in coords[idx]
      ? coords[idx].longitude
      : coords[idx].lng

    const lat = Number(latitude)
    const lng = Number(longitude)

    if (x0 === null) {
      x0 = x1 = Number(lat)
      y0 = y1 = Number(lng)
    } else {
      if (lat > x1) x1 = lat
      if (lat < x0) x0 = lat
      if (lng > y1) y1 = lng
      if (lng < y0) y0 = lng
    }
  }

  return {
    northeast: {
      lat: x1,
      lng: y1
    },
    southwest: {
      lat: x0,
      lng: y0
    }
  }
}
