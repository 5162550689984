const { getFormattedDateFromDate } = require('../temporal')

function isDisabled (driver, {
  currentDate = new Date()
} = {}) {
  const expiresDate = getDisabledExpiresDate(driver)

  return expiresDate
    ? currentDate < expiresDate
    : false
}

function getFormattedExpiryDate (driver) {
  return getFormattedDateFromDate(getDisabledExpiresDate(driver))
}

function getReason (driver, {
  currentDate = new Date()
} = {}) {
  return isDisabled(driver, { currentDate })
    ? (driver.cachedDisabledReason || '')
    : null
}

function getDisabledInDays (driver, {
  currentDate = new Date()
} = {}) {
  return isDisabled(driver, { currentDate })
    ? Math.ceil((getDisabledExpiresDate(driver) - new Date()) / 1000 / 60 / 60 / 24)
    : -1
}

function getDisabledExpiresDate (driver) {
  return (driver.cachedDisabledExpiresAt && (
    'toDate' in driver.cachedDisabledExpiresAt
      ? driver.cachedDisabledExpiresAt.toDate()
      : new Date(driver.cachedDisabledExpiresAt)
  )) || null
}

function prepDisabledEvents (disabledEvents, {
  currentDate = new Date()
} = {}) {
  return (disabledEvents || []).map((event) => {
    if (!event.created) {
      // TODO: we should set the date to be 23:59:59 in the driver's timezone.
      // We'll need to pass along the driver's timezone as a parameter and do
      // the computation here.
      const expires = event.expires ? new Date(event.expires) : currentDate
      expires.setHours(23, 59, 59, 999)
      return { ...event, created: currentDate.toISOString(), expires: expires.toISOString() }
    } else {
      return event
    }
  })
}

async function updateDriverCachedProps ({
  driver,
  updateDriver
}) {
  if (driver.disabledEvents && driver.disabledEvents.length) {
    const latestExpiringEvent = driver.disabledEvents.reduce((memo, event) => {
      if (event.expires && (!memo.expires || new Date(event.expires) > new Date(memo.expires))) {
        return event
      } else {
        return memo
      }
    }, { expires: null, reason: null })

    if (latestExpiringEvent.expires) {
      const expiresDate = new Date(latestExpiringEvent.expires)

      await updateDriver({
        cachedDisabledExpiresAt: expiresDate,
        cachedDisabledReason: latestExpiringEvent.reason,
        ...(driver.active && new Date() < expiresDate && { active: false })
      })
    }
  } else {
    if (driver.cachedDisabledExpiresAt) {
      await updateDriver({
        cachedDisabledExpiresAt: null,
        cachedDisabledReason: null
      })
    }
  }
}

exports.isDisabled = isDisabled
exports.getFormattedExpiryDate = getFormattedExpiryDate
exports.getReason = getReason
exports.getDisabledInDays = getDisabledInDays
exports.prepDisabledEvents = prepDisabledEvents
exports.updateDriverCachedProps = updateDriverCachedProps
