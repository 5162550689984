module.exports = function lightenDarkenColor (col, amt) {
  let hash = ''

  if (col.substr(0, 1) === '#') {
    col = col.replace(/^#/u, '')
    hash = '#'
  }

  col = parseInt(col, 16)
  return hash + (((col & 0x0000FF) + amt) | ((((col >> 8) & 0x00FF) + amt) << 8) | (((col >> 16) + amt) << 16)).toString(16)
}
