import { jsx } from '@emotion/core'
/** @jsxRuntime classic */
/** @jsx jsx */
import { COLORS } from '@trexity/common/color/constants'

import PropTypes from 'prop-types'
import { ClearButton } from '../Button/ClearButton'
import { useState, useRef, useEffect } from 'react'
import gsap, { Power3 } from 'gsap'

const SpanStyle = {
  width: 25, height: 2, backgroundColor: COLORS.WHITE, display: 'block', marginBottom: 4
}

export const HeaderLayout = ({ children, Navigation }) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const wrapper = useRef()
  const [tl, setTl] = useState(gsap.timeline())

  useEffect(() => {
    setTl(gsap.timeline())

    if (menuOpen) {
      tl.to(wrapper.current, { x: 300, ease: Power3.easeOut }, 'in')
    } else {
      tl.to(wrapper.current, { x: 0, ease: Power3.easeOut, clearProps: 'x' }, 'out')
    }
  }, [menuOpen])

  return (
    <section css={{ color: COLORS.WHITE, width: '100%', overflowX: 'hidden', height: '100vh' }}>
      <div css={{
            backgroundColor: COLORS.TREXITY_MERCHANT_BURGUNDY,
            padding: '15px 30px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: 70
      }}
      >
        <ClearButton onClick={() => setMenuOpen(!menuOpen)}>
          <div css={{ display: 'flex', alignItems: 'center' }}>
            <div>
              <span css={SpanStyle}></span>
              <span css={SpanStyle}></span>
              <span css={{ ...SpanStyle, marginBottom: 0 }}></span>
            </div>
            <div css={{ marginLeft: 15, marginTop: 3, color: COLORS.WHITE }}>
              MENU
            </div>
          </div>
        </ClearButton>
      </div>
      <div>
        <Navigation visible={menuOpen} timeline={tl} />
      </div>
      <div ref={wrapper} css={{ height: 'calc(100vh - 70px)', display: 'flex', flexDirection: 'column' }}>
        {children}
      </div>
    </section>
  )
}

HeaderLayout.propTypes = {
  children: PropTypes.node,
  Navigation: PropTypes.func
}
