/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import PropTypes from 'prop-types'
import { COLORS } from '@trexity/common/color/constants'

export const Well = ({
  color,
  light,
  size,
  transparent,
  label,
  children,
  fullWidth = false,
  onClick,
  ...rest
}) => {
  const setSize = () => {
    switch (size) {
      case 'tiny':
        return '5px 15px'
      case 'compact':
        return '15px 30px'
      case 'normal':
        return 30
      case 'callout':
        return 60
      default:
        return 30
    }
  }

  return (
    <div css={[WellStyles(color, light, transparent, fullWidth, setSize()), { cursor: onClick ? 'pointer' : 'auto' }]} {...rest} onClick={(e) => { if (onClick) { onClick(e) } }}>
      {label || children}
    </div>
  )
}

export const WellStyles = (color, light, transparent, fullWidth, size) => ({
  display: 'inline-block',
  padding: size,
  borderWidth: 1,
  borderStyle: 'solid',
  borderRadius: 5,
  backgroundColor: transparent ? 'transparent' : color,
  borderColor: color,
  width: fullWidth ? '100%' : 'auto',
  color: light ? COLORS.WHITE : COLORS.DARK
})

Well.propTypes = {
  color: PropTypes.string,
  transparent: PropTypes.bool,
  light: PropTypes.bool,
  size: PropTypes.string,
  label: PropTypes.string,
  children: PropTypes.node,
  fullWidth: PropTypes.bool,
  onClick: PropTypes.func
}
