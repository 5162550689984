// This tests a specific ISO-8601 date string,
// not the whole ISO standard.
//
// Valid examples:
//
// "2020-07-25T10:03:29.901Z"
// "2019-03-10T05:30:44.901Z"
// "2009-07-25T07:10:21.901Z"

module.exports = function validateISODateString (str) {
  try {
    const d = new Date(str)
    return d.toISOString() === str
  } catch (error) {
    return false
  }
}
