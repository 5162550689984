import React from 'react'
import PropTypes from 'prop-types'
import { Spin } from 'antd'

export const LoadingWrapper = ({ active, children }) => active ? (
  <Spin>{children}</Spin>
) : (
  children
)

LoadingWrapper.propTypes = {
  active: PropTypes.bool.isRequired,
  children: PropTypes.node
}
