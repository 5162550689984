import React from 'react'
import { Pagination, Spin } from 'antd'
import { getFormattedDateAndTimeFromDate, getFormattedDurationString } from '@trexity/common/temporal'
import { getFormattedMonetaryValueString } from '@trexity/common/pricing'
import { convertOrdersToArray } from '@trexity/common/models/ShipmentOrder'
import { sortOrdersByShipmentAndDriver } from '@trexity/common/shipments'
import ShipmentStatus from '../ShipmentStatus'
import { calculateEstimatedMinutes } from '../../util'

export default function ShipmentsTable ({
  deps,
  shipments,
  driver = null,
  displayDropOffsColumn = false,
  pageSize = 5,
  loading = false
}) {
  const {
    RouteLink
  } = deps

  const getPaginatedArray = (array, page = 1) => {
    const start = (page - 1) * pageSize
    const end = start + pageSize
    return array.slice(start, end)
  }

  const [pageNumber, setPageNumber] = React.useState(1)

  const LoadingWrapper = loading
    ? ({ children }) => <Spin>{children}</Spin>
    : ({ children }) => children

  return (
    <LoadingWrapper>
      <div style={{ overflowX: 'auto' }}>
        <table border={1} cellPadding={2} style={{ borderColor: '#aaa', width: '100%' }}>
          <thead>
            <tr>
              <th>ID</th>
              <th>Posted</th>
              <th>Merchant</th>
              <th>Status</th>
              {displayDropOffsColumn ? <th>Drop-offs</th> : null}
              <th>Earnings</th>
              <th>Timing</th>
            </tr>
          </thead>
          <tbody>
            {shipments.length ? (
              getPaginatedArray(shipments, pageNumber).map((shipment) => {
                const {
                  id,
                  merchantId,
                  cachedMerchantName,
                  currentStatus,
                  postedAt,
                  scheduledPostAt,
                  scheduledPostEnabled,
                  cachedDriverCompensation,
                  cachedPenaltyDriverCompensation,
                  linkedParentShipment,
                  routeIdentifier
                } = shipment

                const ordersArray = convertOrdersToArray(shipment.orders)

                const linkedParentType = (linkedParentShipment && linkedParentShipment.type) || null

                const {
                  estPickupArrivalText,
                  estDeliveryArrivalText,
                  deliveredInText
                } = estimateTimings(shipment, driver)

                return (
                  <tr key={id}>
                    <td><RouteLink routeKey='Shipment Dashboard' params={{ id }}>{id}{routeIdentifier ? ` (${routeIdentifier})` : ''}</RouteLink></td>
                    <td>{(postedAt && getFormattedDateAndTimeFromDate(postedAt.toDate())) || 'n/a'}</td>
                    <td><RouteLink routeKey='Merchant Dashboard' params={{ id: merchantId }}>{cachedMerchantName}</RouteLink></td>
                    <td>
                      <ShipmentStatus
                        status={currentStatus}
                        scheduledPostAt={scheduledPostAt}
                        scheduledPostEnabled={scheduledPostEnabled}
                        linkedParentType={linkedParentType}
                        numberOfUndeliverableItems={ordersArray.filter(({ deliveredAt, undeliverable, misdelivered }) => deliveredAt && undeliverable).length}
                        numberOfMisdeliveredItems={ordersArray.filter(({ deliveredAt, undeliverable, misdelivered }) => deliveredAt && misdelivered).length}
                        numberOfDeliveredItems={ordersArray.filter(({ deliveredAt, undeliverable, misdelivered }) => deliveredAt && !undeliverable && !misdelivered).length}
                        numberOfProblemItems={ordersArray.filter(({ deliveredAt, undeliverable, misdelivered }) => deliveredAt && (undeliverable || misdelivered)).length}
                        totalItems={ordersArray.length}
                      />
                    </td>
                    {displayDropOffsColumn ? <td>{ordersArray.length}</td> : null}
                    <td>{cachedPenaltyDriverCompensation ? getFormattedMonetaryValueString(cachedPenaltyDriverCompensation) : getFormattedMonetaryValueString(cachedDriverCompensation)}</td>
                    <td>{(
                      estPickupArrivalText ||
                      estDeliveryArrivalText ||
                      deliveredInText ||
                      'n/a'
                    )}</td>
                  </tr>
                )
              })
            ) : (
              <tr><td colSpan={99}><em>No routes available</em></td></tr>
            )}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={10} align='right' style={{ backgroundColor: '#fafafa' }}>
                <Pagination
                  current={pageNumber}
                  onChange={(page) => setPageNumber(page)}
                  total={shipments.length}
                  pageSize={pageSize}
                  size='small'
                />
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </LoadingWrapper>
  )
}

function estimateTimings (shipment, driver) {
  const {
    completedAt,
    currentStatus,
    currentDriverEstimatedSecondsToPickup,
    currentDriverEstimatedSecondsToPickupComputedAt,
    driverPickedUpAt
  } = shipment

  const ordersArray = convertOrdersToArray(sortOrdersByShipmentAndDriver({ shipment, driver }))

  let estPickupArrivalText = null
  let estDeliveryArrivalText = null
  let deliveredInText = null

  if (['EN_ROUTE_TO_PICKUP', 'ARRIVED_AT_PICKUP'].includes(currentStatus) && currentDriverEstimatedSecondsToPickup) {
    const estPickupArrivalMins = calculateEstimatedMinutes(currentDriverEstimatedSecondsToPickup, currentDriverEstimatedSecondsToPickupComputedAt)

    estPickupArrivalText = currentDriverEstimatedSecondsToPickupComputedAt
      ? `Pickup in ${estPickupArrivalMins} min${estPickupArrivalMins === 1 ? '' : 's'}`
      : null
  }

  if (['OUT_FOR_DELIVERY', 'ARRIVED_AT_DELIVERY'].includes(currentStatus)) {
    const idx = ordersArray.findIndex(({ deliveredAt }) => !deliveredAt)
    const order = ordersArray[idx] || {}

    if (Number.isFinite(order.currentDriverEstimatedSecondsToDelivery)) {
      const estDeliveryArrivalMins = calculateEstimatedMinutes(
        order.currentDriverEstimatedSecondsToDelivery,
        order.currentDriverEstimatedSecondsToDeliveryComputedAt
      )

      estDeliveryArrivalText = order.currentDriverEstimatedSecondsToDeliveryComputedAt
        ? `Dropoff in ${estDeliveryArrivalMins} min${estDeliveryArrivalMins === 1 ? '' : 's'}`
        : null
    }
  }

  if (currentStatus === 'DELIVERED') {
    const pickedUpAtSeconds = (driverPickedUpAt && driverPickedUpAt.seconds) || 0
    const completedAtSeconds = (completedAt && completedAt.seconds) || 0
    const diff = completedAtSeconds - pickedUpAtSeconds

    deliveredInText = diff > 0
      ? `Delivered in ${getFormattedDurationString(diff)}`
      : null
  }

  return {
    estPickupArrivalText,
    estDeliveryArrivalText,
    deliveredInText
  }
}
