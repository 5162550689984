/* eslint-disable require-await */

// This "Privilege" service is meant to house the conditions that determine if
// a driver has earned the privilege to have greater access to certain features.

const types = {
  // When a driver is too far from the delivery location, we usually force them
  // to get in touch with support to proceed. This privilege allows them to
  // bypass support and self-certify that they did the delivery correctly
  // without being blocked by support.
  TRUST_SELF_CERTIFY_DISTANCE_CHECK: 'trust_self_certify_distance_check',

  // Only drivers who have this privilege will be pinged for bundles, i.e.
  // routes with 2 or more deliveries. This means that new drivers will only get
  // single deliveries, which de-risks the more complex routes.
  ALLOW_BUNDLES_IN_BROADCAST: 'allow_bundles_in_broadcast'
}

// This is a temporary implementation that will eventually live on the server.
// However our current requirements are very simple, and are based on data that
// is available directly on the driver object.
async function checkPrivilege ({
  type,
  driver,
  date
} = {}) {
  let hasPrivilege = false
  let failedReason = null

  switch (type) {
    case types.TRUST_SELF_CERTIFY_DISTANCE_CHECK: {
      // Drivers who have completed 100 or more routes can self-certify
      const targetAmount = 100
      const currentAmount = driver.lifetimeShipmentsCount

      hasPrivilege = currentAmount >= targetAmount

      if (!hasPrivilege) {
        failedReason = `Needs to have completed ${targetAmount} or more routes (currently at ${currentAmount}) in order to self-certify the distance check.`
      }

      break
    }

    case types.ALLOW_BUNDLES_IN_BROADCAST: {
      // Drivers who have done 1 or more routes can be pinged for bundles
      const targetAmount = 1
      const currentAmount = driver.lifetimeShipmentsCount

      hasPrivilege = (
        // Always enabled for now based on an ask from Rob via Slack
        // on Dec 3 at 5:50 PM
        true ||
        currentAmount >= targetAmount ||
        (
          // Only specific service cities support this for now. Generally
          // speaking, new cities shouldn't check this privilege, as we need
          // all the drivers we can get! Basically, add more "stable" cities
          // in this list manually.
          ![
            'Ottawa, ON',
            'Gatineau, QC'
          ].includes(driver.mailingAddressServiceCity)
        )
      )

      if (!hasPrivilege) {
        failedReason = `Needs to have completed ${targetAmount} or more routes (currently at ${currentAmount}) to be pinged for bundled routes.`
      }

      break
    }
  }

  return [hasPrivilege, failedReason]
}

async function checkPrivilegeServer (appContext, {
  type,
  driverId,
  date
} = {}) {
  const { getRepoProvider, firebaseAdmin } = appContext

  const driver = await getRepoProvider().getDriverRepository({ firebaseAdmin }).getDriver(driverId)

  // Call temporary "common" version of checkPrivilege
  return checkPrivilege({
    type,
    driver,
    date
  })
}

async function checkPrivilegeClient ({
  type,
  driverId,
  date,
  tc = null,
  driver = null
} = {}) {
  if (!driver) {
    // Until we implement querying the server for privileges, we need to have
    // the driver object passed along
    throw new TypeError('checkPrivilegeClient requires driver parameter to be specified')
  }

  if (!driverId) {
    throw new TypeError('Even though the driverId param is not in use, please provide it for forward compatibility')
  }

  if (!tc) {
    throw new TypeError('Even though the tc param is not in use, please provide it for forward compatibility')
  }

  // Call temporary "common" version of checkPrivilege
  return checkPrivilege({
    type,
    driver,
    date
  })
}

async function checkPrivilegeService ({
  type,
  driverId,
  appContext = null,
  date = new Date(),
  driver = null,
  tc = null
} = {}) {
  if (!Object.values(types).find((t) => t === type)) {
    throw new TypeError(`Invalid type: ${type}`)
  }

  if (appContext) {
    // -------------------------------------------------------------------------
    // Server
    // -------------------------------------------------------------------------

    if (driver) {
      throw new TypeError('The driver parameter should not be specified on the server')
    }

    if (tc) {
      throw new TypeError('The tc parameter should not be specified on the server')
    }

    return checkPrivilegeServer(appContext, {
      type,
      driverId,
      date
    })
  } else if (tc) {
    // -------------------------------------------------------------------------
    // Client
    // -------------------------------------------------------------------------

    return checkPrivilegeClient({
      type,
      driverId,
      date,
      driver,
      tc
    })
  } else {
    throw new TypeError('Parameter mismatch: unable to determine server/client codepath for checkPrivilege service')
  }
}

exports.checkPrivilege = checkPrivilegeService
exports.types = types
