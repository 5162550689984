const DRIVER_POSITION_STALE_THRESHOLD = 5 * 60 * 1000 /* 5 min */

class DriverPosition {
  constructor (coordinates, receivedAt = new Date()) {
    this.coordinates = [coordinates[0], coordinates[1]]

    this.receivedAt = (receivedAt && typeof receivedAt === 'object' && 'toDate' in receivedAt)
      ? receivedAt.toDate()
      : new Date(receivedAt)
  }

  isStale (date = new Date(), {
    staleMs = DRIVER_POSITION_STALE_THRESHOLD
  } = {}) {
    return new Date(date) - this.receivedAt > staleMs
  }
}

DriverPosition.DRIVER_POSITION_STALE_THRESHOLD = DRIVER_POSITION_STALE_THRESHOLD

DriverPosition.fromLatitudeLongitudeObject = (latitudeLongitudeObject, receivedAt = new Date()) => {
  return new DriverPosition(
    [latitudeLongitudeObject.longitude, latitudeLongitudeObject.latitude],
    receivedAt
  )
}

module.exports = exports = DriverPosition
