/**
 * @param {Date|{ toLocaleTimeString: (locale:string, { timeZone:string }) => string }} date
 * @param {Object} [options]
 * @param {string} [options.locale]
 * @param {string} [options.timeZone]
 * @return {string}
 */
const formatTime = (date, { locale = 'en-CA', timeZone = 'UTC' } = {}) => {
  if (date && typeof date.toDate === 'function') {
    date = date.toDate()
  }

  if (!date || typeof date.toLocaleTimeString !== 'function') {
    throw new TypeError('date must have method toLocaleTimeString(locale, { timeZone })')
  }

  locale = locale || 'en-CA'
  timeZone = timeZone || 'UTC'

  return (
    date.toLocaleTimeString(locale, { timeZone })
  )
}

exports.formatTime = formatTime
