import * as React from 'react'
import * as PropTypes from 'prop-types'

import {
  Row,
  Col,
  Input,
  Form,
  Button
} from 'antd'

export default function EmailChangeVerificationForm ({
  emailVerification, // emailVerification object obtained from user.customClaims (if available)
  onVerificationRequestClicked,
  onVerificationConfirmationClicked,
  isProcessingEmailVerification,
  isProcessingEmailConfirmation,
  isEmailVerified,
  newEmailLabel = 'New email',
  verificationCodeInputProps = {},
  newEmailInputProps = {},
  verificationRequestButtonText = 'Send Verification',
  errorOccurred
} = {}) {
  const [email, setEmail] = React.useState('')
  const [verificationCode, setVerificationCode] = React.useState('')
  const [verificationStatus, setVerificationStatus] = React.useState('')
  const [validEmail, setValidEmail] = React.useState({ valid: true })
  const [isThrottlingEmailSend, setIsThrottlingEmailSend] = React.useState(null)
  const [countdownEmailSend, setCountdownEmailSend] = React.useState(0)

  React.useEffect(() => {
    return () => {
      clearInterval(isThrottlingEmailSend)
    }
  }, [])

  React.useEffect(() => {
    if (errorOccurred) {
      clearThrottleEmailSend()
    }
  }, [errorOccurred])

  React.useEffect(() => {
    if (emailVerification && !emailVerification.verifiedAt) {
      emailValidator(emailVerification.email)
      setEmail(emailVerification.email)
    }

    if (emailVerification && emailVerification.verificationRequest && emailVerification.verificationRequest.status) {
      setVerificationStatus(emailVerification.verificationRequest.status)
    } else {
      setVerificationStatus('')
    }
  }, [emailVerification])

  function emailValidator (value) {
    setValidEmail({
      valid: !value || /@/u.test(value),
      message: 'Please include an "@" symbol in the email'
    })
  }

  function clearThrottleEmailSend () {
    clearInterval(isThrottlingEmailSend)
    setIsThrottlingEmailSend(null)
    setCountdownEmailSend(0)
  }

  function throttleEmailSend () {
    clearThrottleEmailSend()
    let countdown = 10
    setCountdownEmailSend(countdown)

    const handleInterval = setInterval(() => {
      if (countdown) {
        countdown--
        setCountdownEmailSend(countdown)

        if (!countdown) {
          clearThrottleEmailSend()
        }
      }
    }, 1000)

    setIsThrottlingEmailSend(handleInterval)
  }

  return (
    <Form
      colon={false}
      hideRequiredMark={true}
      layout='vertical'
    >
      <Form.Item
        // label={newEmailLabel + (verificationStatus ? ` (${verificationStatus})` : '')}
        label='Verify email'
        validateStatus={!validEmail.valid ? 'error' : undefined}
        help={!validEmail.valid ? validEmail.message : undefined}
      >
        <Row gutter={20}>
          <Col span={14}>
            <Input
              size='large'
              type='email'
              value={email}
              disabled={isEmailVerified}
              {...newEmailInputProps}
              onChange={(evt) => {
                emailValidator(evt.target.value)
                setEmail(evt.target.value)
              }}
            />
          </Col>
          <Col span={10}>
            <Button
              type='primary'
              size='large'
              loading={isProcessingEmailVerification}
              disabled={!email || isProcessingEmailVerification || isProcessingEmailConfirmation || countdownEmailSend || isEmailVerified}
              onClick={() => {
                setVerificationCode('')
                throttleEmailSend()
                onVerificationRequestClicked(email)
              }}
              block
            >
              {countdownEmailSend ? `${countdownEmailSend}` : verificationRequestButtonText}
            </Button>
          </Col>
        </Row>
      </Form.Item>
      <Form.Item
        label='Verification code'
      >
        <Row gutter={20}>
          <Col span={14}>
            <Input
              size='large'
              value={verificationCode}
              disabled={isEmailVerified}
              {...verificationCodeInputProps}
              onChange={(evt) => {
                setVerificationCode(evt.target.value)
              }}
            />
          </Col>
          <Col span={10}>
            <Button
              type='primary'
              size='large'
              loading={isProcessingEmailConfirmation}
              disabled={!verificationCode || isProcessingEmailVerification || isProcessingEmailConfirmation || isEmailVerified}
              onClick={() => onVerificationConfirmationClicked(verificationCode)}
              block
            >
              Verify
            </Button>
          </Col>
        </Row>
      </Form.Item>
    </Form>
  )
}

EmailChangeVerificationForm.propTypes = {
  emailVerification: PropTypes.object,
  isProcessingEmailVerification: PropTypes.bool,
  isProcessingEmailConfirmation: PropTypes.bool,
  isEmailVerified: PropTypes.bool,
  newEmailLabel: PropTypes.string,
  onVerificationRequestClicked: PropTypes.func.isRequired,
  onVerificationConfirmationClicked: PropTypes.func.isRequired,
  verificationCodeInputProps: PropTypes.object,
  newEmailInputProps: PropTypes.object,
  verificationRequestButtonText: PropTypes.string,
  errorOccurred: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
}
