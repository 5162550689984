import React from 'react'
import PropTypes from 'prop-types'
import { Tag, Tooltip } from 'antd'

export default function DriverChallengeQualificationStatusTag ({
  status = null
}) {
  if (status === 'suspended') {
    return (
      <Tooltip title="The driver is currently suspended from this challenge.">
        <Tag color='gold'>Suspended</Tag>
      </Tooltip>
    )
  } else if (status === 'earned') {
    return (
      <Tooltip title="The driver has completed the challenge and has been paid the bonus.">
        <Tag color='green'>Earned</Tag>
      </Tooltip>
    )
  } else if (status === 'active') {
    return (
      <Tooltip title='The driver is currently working on this challenge.'>
        <Tag color='blue'>Earning</Tag>
      </Tooltip>
    )
  } else if (status === 'disqualified') {
    return (
      <Tooltip title="The driver has been explicity disqualified from this challenge.">
        <Tag color='cyan'>Disqualified</Tag>
      </Tooltip>
    )
  } else if (status === 'expired') {
    return (
      <Tooltip title="The challenge has expired before the driver had a chance to complete it.">
        <Tag color='red'>Expired</Tag>
      </Tooltip>
    )
  } else {
    return 'N/A'
  }
}

DriverChallengeQualificationStatusTag.propTypes = {
  status: PropTypes.string
}
