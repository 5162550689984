// @ts-nocheck
module.exports = function getImageSize (base64image) {
  return new Promise((resolve, reject) => {
    try {
      const img = new Image()

      img.onload = function () {
        resolve({ width: this.width, height: this.height })
      }

      img.src = base64image
    } catch (error) {
      reject(error)
    }
  })
}
