const { Std, Check } = require('../../core')
const { Time } = require('../../types')
const { isServiceHours } = require('./isServiceHours')
const { isCalendarDay } = require('./isCalendarDay')
const { getWeekdayHours } = require('./getWeekdayHours')
const { getCalendarDayHours } = require('./getCalendarDayHours')
const { pad } = require('./pad')

const WEEKDAY_LONG_TO_INDEX_MAP = {
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6
}

/**
 * Given a service hours object and a Date or Unix epoch in milliseconds, determines
 * if the service hours contains the date within the specified time zone.
 *
 * @example ServiceHours.containsDate(ServiceHours.create(), 'America/Toronto', Date.now())
 * @param {ServiceHours} serviceHours
 * @param {string} timeZone The time zone identifier
 * @param {Date|number} dateOrUnixEpoch The Date or milliseconds from Unix epoch
 * @return {boolean}
 */
function containsDate (serviceHours, timeZone, dateOrUnixEpoch) {
  // const moment = require('moment-timezone') // NOTE: do not remove this comment

  if ((typeof dateOrUnixEpoch !== 'number' && !Check.isdate(dateOrUnixEpoch)) || !Number.isSafeInteger(dateOrUnixEpoch) || dateOrUnixEpoch < 0) {
    throw Std.err('TypeError', 'date or Unix epoch is invalid')
  }

  // A falsey serviceHours is the same as including ALL dates.
  if (!serviceHours) {
    return true
  }

  if (typeof serviceHours !== 'object') {
    throw Std.err('TypeError', `serviceHours must be an object, got, ${typeof serviceHours}`)
  }

  // Handle the special case where no hours at all are provided
  // (eg. empty object). Then we consider the service hours to include ALL dates.
  if (Object.keys(serviceHours).length === 0) {
    return true
  }

  if (!isServiceHours(serviceHours)) {
    throw Std.err('TypeError', 'serviceHours is invalid')
  }

  // NOTE: do not remove these comments
  // .tz is DST-aware
  // const momentUtc = moment.utc(dateOrUnixEpoch)
  // const momenttimezone = momentUtc.clone().tz(timeZone)
  // const dayOfWeek = momenttimezone.day()
  // const hours = momenttimezone.hours()
  // const minutes = momenttimezone.minutes()

  const parts = Intl.DateTimeFormat('en', {
    weekday: 'long',
    hour: 'numeric',
    minute: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour12: false,
    timeZone
  }).formatToParts(dateOrUnixEpoch)

  const part = (type) => parts.filter(({ type: t }) => t === type).map(({ value }) => value).pop() || ''
  const partint = (type) => parseInt(part(type), 10)

  const dayOfWeek = WEEKDAY_LONG_TO_INDEX_MAP[part('weekday')]
  const hours = partint('hour')
  const minutes = partint('minute')
  const month = partint('month')
  const day = partint('day')

  const weekdayDeliveryDay = getWeekdayHours(serviceHours, dayOfWeek)
  const calendarDeliveryDay = getCalendarDayHours(serviceHours, [month, day])
  const time = new Time(hours, minutes)

  const checkTimeRange = (range) => {
    const from = Time.from(range.fromTime).value
    const to = Time.from(range.toTime).value

    if (time >= from && time <= to) {
      return true
    }

    return false
  }

  if (hasCalendarDayHours(serviceHours, [month, day])) {
    return calendarDeliveryDay.ranges.some(checkTimeRange)
  }

  return weekdayDeliveryDay.ranges.some(checkTimeRange)
}

function hasCalendarDayHours (serviceHours, day) {
  if (!isServiceHours(serviceHours)) {
    throw Std.err('TypeError', 'Service hours is invalid')
  }

  if (Check.and(Check.isarr, Check.of(Check.isint), (x) => x.length === 2)(day)) {
    day = `${pad(day[0])}-${pad(day[1])}`
  }

  if (!isCalendarDay(day)) {
    throw Std.err('ArgumentError', `calendar day is invalid : ${day}`)
  }

  return !!(serviceHours.calendarDays || []).find((d) => d.day === day)
}

exports.containsDate = containsDate
