/*
A component that takes a MerchantDiscountProgram object (same shape as the entity),
and displays it in a card format. The card format shows the discount per delivery
and the the total discount per activated merchant that can be applied and its constraints.
*/

import React from 'react'
import * as PropTypes from 'prop-types'
import { Card, Typography } from 'antd'
import { getFormattedMonetaryValueString } from '@trexity/common/pricing'
import { MerchantDiscountLogic } from '@trexity/common/merchantDiscounts'

export default function MerchantDiscountDisplayCard ({ discountProgram }) {
  const dp = new MerchantDiscountLogic(discountProgram)
  const { type, percentage, numberOfDeliveries } = discountProgram
  const individualMerchantBudget = dp.getIndividualMerchantBudget()
  const discountPerDelivery = dp.getDiscountPerDelivery()

  return (
    <Card bodyStyle={{ textAlign: 'center' }}>
      <Typography.Title level={4}>Discount per delivery</Typography.Title>
      {type === 'fixed' ? (
        <div>
          <div style={{ fontSize: 36 }}>
            up to {getFormattedMonetaryValueString(discountPerDelivery || 0)}
          </div>
          up to a total of {getFormattedMonetaryValueString(individualMerchantBudget || 0)} across {numberOfDeliveries} delivered deliveries
        </div>
      ) : null}
      {type === 'percentage' ? (
        <div>
          <div style={{ fontSize: 36 }}>
            {percentage}%
          </div>
          up to a total of {getFormattedMonetaryValueString(individualMerchantBudget || 0)} across all delivered deliveries
        </div>
      ) : null}
    </Card>
  )
}

MerchantDiscountDisplayCard.propTypes = {
  discountProgram: PropTypes.shape({
    type: PropTypes.oneOf(['fixed', 'percentage']),
    percentage: PropTypes.number,
    totalBudget: PropTypes.number.isRequired,
    numberOfSeats: PropTypes.number.isRequired,
    numberOfDeliveries: PropTypes.number.isRequired
  })
}
