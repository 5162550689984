/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'

export const HeadingBase = {
  fontFamily: 'canada-type-gibson',
  fontWeight: 600,
  marginBottom: 15,
  fontSize: '30px',
  lineHeight: '1.2em'
}
