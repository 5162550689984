import React from 'react'
import PropTypes from 'prop-types'
import { Skeleton } from 'antd'
import ShipmentStatusTimeline from '../ShipmentStatusTimeline'

export default function Timeline ({
  isAdmin = false,
  deps
}) {
  const {
    send,
    current,
    data,
    RouteLink
  } = deps

  const {
    shipmentDashboardShipment: shipment,
    shipmentDashboardDriver: driver,
    shipmentDashboardStatuses: statuses
  } = data

  const isLoading = current.within('Loading Timeline')
  const isReady = current.within('Showing Timeline') && shipment && shipment.id

  return (
    isReady ? (
      <ShipmentStatusTimeline
        shipment={shipment}
        driver={driver}
        statuses={statuses}
        isLoading={isLoading}
        isAdmin={isAdmin}
        deps={{ send, current, RouteLink }}
      />
    ) : (
      <Skeleton active />
    )
  )
}

Timeline.propTypes = {
  isAdmin: PropTypes.bool,
  deps: PropTypes.exact({
    send: PropTypes.func,
    current: PropTypes.object,
    data: PropTypes.object,
    tc: PropTypes.object,
    RouteLink: PropTypes.func
  }).isRequired
}
