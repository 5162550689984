import React, { useState } from 'react'
import { COLORS } from '@trexity/common/color/constants'
import PropTypes from 'prop-types'
import Icon from '@material-ui/core/Icon'

export const IconCheckbox = ({ label, icon, ...rest }) => {
  const [checked, setChecked] = useState(false)
  const size = 16

  const checkboxContainer = {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    fontSize: size,
    padding: '0.4em 0.8em',
    borderRadius: 5,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: checked ? COLORS.TREXITY_BLUE : COLORS.TREXITY_MERCHANT_BURGUNDY,
    color: checked ? COLORS.WHITE : COLORS.TREXITY_MERCHANT_BURGUNDY,
    backgroundColor: checked ? COLORS.TREXITY_BLUE : COLORS.WHITE
  }

  const iconStyle = {
    fontSize: size,
    marginRight: 5
  }

  return (
    <label style={checkboxContainer}>
      <input type='checkbox' checked={checked} onChange={() => setChecked(!checked)} style={{ display: 'none' }} {...rest}></input>
      <Icon style={iconStyle}>{icon}</Icon> {label}
    </label>
  )
}

IconCheckbox.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.string
}
