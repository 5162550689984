import { sleep } from '@trexity/common/util'

export async function handleGettingPossibleBundlingSolution (tc, evt, send, data, { isAdmin }) {
  try {
    let shouldStillCheck = true

    const {
      shipmentIds = null,
      shipmentQuery = null,
      driverCapacity = null
    } = evt

    let targetDeliveryDuration = null

    if (evt.targetDeliveryDuration || evt.targetDeliveryDuration === 0) {
      targetDeliveryDuration = evt.targetDeliveryDuration
    }

    const bundlingSolutionRequest = {
      shipmentIds,
      shipmentQuery
    }

    if (isAdmin) {
      if (driverCapacity) bundlingSolutionRequest.driverCapacity = driverCapacity
      if (targetDeliveryDuration) bundlingSolutionRequest.targetDeliveryDuration = targetDeliveryDuration
    }

    const result = isAdmin
      ? await tc.shipments.getBundlingSolution(bundlingSolutionRequest)
      : await tc.merchants.getBundlingSolution(bundlingSolutionRequest)

    if (result.error && result.error.message) {
      throw result.error
    }

    let attempts = 0
    const maxAttempts = 500
    let routingSolutions = null

    while (shouldStillCheck) {
      const tryAgainInSeconds = getTryAgainInSeconds(attempts)
      await sleep(tryAgainInSeconds * 1000)

      if (attempts > maxAttempts) {
        shouldStillCheck = false
        console.log('Timed out while waiting bundling solution.')
        send({ type: 'error getting possible solution', error: new Error('Timed out.') })
        return
      }

      const check = await tc.shipments.checkBundlingOperationProgress(result.responses)

      if (check.status !== 'ready') {
        console.log(`(attempt ${attempts}) Still not ready, checking again after ${tryAgainInSeconds}`)
        attempts++

        continue
      }

      routingSolutions = check.routingSolutions
      shouldStillCheck = false
    }

    let unassignedOrders = []
    let routes = []
    let merchantId = null
    let updatedDriverCapacity = null
    let updatedTargetDeliveryDuration = null

    if (routingSolutions) {
      await modifyRoutingSolutionsWithCosts(tc, routingSolutions)
      unassignedOrders = getUnassignedOrders(routingSolutions)
      merchantId = getMerchantId(routingSolutions)
      routes = getRoutes(routingSolutions)
      updatedDriverCapacity = getDriverCapacity(routingSolutions)
      updatedTargetDeliveryDuration = getTargetDeliveryDuration(routingSolutions)
    }

    data.possibleBundlingSolutionRoutes = routes
    data.possibleBundlingSolutionMerchantId = merchantId
    data.possibleBundlingSolutionUnassigneds = unassignedOrders
    data.possibleBundlingSolutionShipmentIds = result.metadata.originalShipmentIds
    data.possibleBundlingSolutionShipmentQuery = shipmentQuery
    data.possibleBundlingSolutionDriverCapacity = updatedDriverCapacity
    data.possibleBundlingSolutionTargetDeliveryDuration = updatedTargetDeliveryDuration
    data.possibleBundlingSolutionAverageCostPerOldDelivery = Number(result.metadata.averageCostPerCurrentDelivery)
    data.possibleBundlingSolutionTotalNumberOfDeliveriesForBundling = Number(result.metadata.totalNumberOfDeliveriesForBundling)
    data.possibleBundlingSolutionTotalCostForCurrentRoutes = Number(result.metadata.totalCostForCurrentRoutes)

    send('success getting possible solution')
  } catch (error) {
    console.error(error)
    send({ type: 'error getting possible solution', error })
  }
}

/**
 * @param {Object} tc SDK instance
 * @param {Object[]} routingSolutions modified by reference
 */
async function modifyRoutingSolutionsWithCosts (tc, routingSolutions) {
  const costRequests = []

  for (const routingSolution of routingSolutions) {
    const metadata = routingSolution.metadata

    for (const route of routingSolution.routes) {
      costRequests.push({
        pickupLocation: metadata.shipmentPickup.pickupAddressLocation,
        requirements: metadata.shipmentPickup.requirements,
        merchantId: metadata.shipmentPickup.merchantId,

        deliveryOrders: route.steps
          .filter((step) => step.type === 'dropOff')
          .map((step) => metadata.shipmentOrders.find(({ id }) => id === step.orderId))
      })
    }
  }

  const costResponse = await tc.shipments.bulkCalculateShipmentValues(costRequests)

  if (Array.isArray(costResponse)) {
    let index = 0

    for (const routingSolution of routingSolutions) {
      for (const route of routingSolution.routes) {
        const values = costResponse[index++]

        if (values.success) {
          route.costTotal = values.total
          route.costDriver = values.driverTakehome
        } else {
          route.costError = values.error
        }
      }
    }
  }
}

function getUnassignedOrders (routingSolutions) {
  const orders = []

  for (const routingSolution of routingSolutions) {
    for (const unservedOrderUuid of routingSolution.unservedOrderIds) {
      const order = routingSolution.metadata.shipmentOrders.find(({ id }) => id === unservedOrderUuid)
      orders.push(order)
    }
  }

  return orders
}

function getRoutes (routingSolutions) {
  const routes = []

  for (const routingSolution of routingSolutions) {
    const { shipmentOrders, shipmentPickup } = routingSolution.metadata

    for (const route of routingSolution.routes) {
      routes.push({
        ...route,
        shipmentPickup,
        steps: route.steps
          .map((step) => {
            return {
              ...step,
              ...(step.orderId && {
                shipmentOrder: shipmentOrders.find(({ id }) => id === step.orderId)
              })
            }
          })
      })
    }
  }

  return routes
}

function getMerchantId (routingSolutions) {
  return routingSolutions[0].metadata.shipmentPickup.merchantId
}

function getDriverCapacity (routingSolutions) {
  return routingSolutions[0].metadata.driverCapacity
}

function getTargetDeliveryDuration (routingSolutions) {
  return routingSolutions[0].metadata.targetDeliveryDuration
}

function getTryAgainInSeconds (attempts) {
  if (attempts <= 5) return 1
  if (attempts <= 10) return 2
  if (attempts <= 20) return 3
  if (attempts <= 30) return 5
  if (attempts <= 40) return 8
  return 10
}
