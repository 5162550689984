// Reference: https://pe.usps.com/text/pub28/28apc_002.htm

/*

################################################################################

STOP: Becareful about changing the mappings! We must ensure that the same mappings
apply. Adding new mappings or changing mappings will need to re-key all existing
Location entities.

    // One way is to load each location then just update the address without
    // any change. This will automatically recompute and update the key.
    await locationRepo.update(location.id, { addressObj: location.addressObj })

################################################################################

Copy this block of code in the browser console of the page referenced above.
Your copy buffer will have the code already. Now paste into a file and remove
all double quotes. At the end of the block there is a copy() function call that
will copy a global variable into the copy buffer, but this has only been tested
on Chrome and webkit-based browsers.

This block will scrape the page and assemble the terms into an array like this:

    [
      [key, [val, val, ...]],
      ...
    ]

Where `key` is the capitalized, full street suffix (i.e. the preferred value
after normalizing).

Each `val` is to be a known abbreviation. These can be all uppercase or not.
However, if a suffix does not have any known abbreviations then key it to itself:

    [key, [key]]

--------------------------------------------------------------------------------
a = (function extractUSPS () {
  const rows = [...document.querySelectorAll('#ep533076 tr')].slice(1)
  const names = rows.map(r => {
    const tds = [...r.querySelectorAll('td')]
    return tds.length > 1 ? tds[0].textContent.trim() : null
  }).filter(Boolean)
  const pairs = []

  const capitalize = s => s.toLowerCase().replace(/\b(.)/gu, (_, c) => c.toUpperCase())

  for (const name of names) {
    const j = rows.findIndex(r => (r.querySelector('td:first-child') || { textContent: '' }).textContent.trim() === name)
    let k = rows.findIndex((r, i) => {
      const tds = [...r.querySelectorAll('td')]
      return i > j && tds.length > 1 && tds[0].textContent.trim() !== name
    })
    if (k < 0) k = rows.length

    const vals = new Set(
      rows
        .slice(j, k)
        .reduce((arr, r) => {
          return [
            ...arr,
            ...[...r.querySelectorAll('td')].map(e => e.textContent.trim())
          ]
        }, []).filter(val => val !== name)
    )

    if (vals.size) {
      pairs.push([capitalize(name), [...vals]])
    } else {
      pairs.push([capitalize(name), [capitalize(name)]])
    }
  }

  return pairs.map(([a, b]) => `['${a}', ['${b.join("', '")}']]`)
}())
copy(a)
--------------------------------------------------------------------------------
*/

const pairs = [
  ['Alley', ['ALLEE', 'ALY', 'ALLY']],
  ['Anex', ['ANX', 'ANNEX', 'ANNX']],
  ['Arcade', ['ARC']],
  ['Avenue', ['AV', 'AVE', 'AVEN', 'AVENU', 'AVN', 'AVNUE']],
  ['Bayou', ['BAYOO', 'BYU']],
  ['Beach', ['BCH']],
  ['Bend', ['BND']],
  ['Bluff', ['BLF', 'BLUF']],
  ['Bluffs', ['BLFS']],
  ['Bottom', ['BOT', 'BTM', 'BOTTM']],
  ['Boulevard', ['BLVD', 'BOUL', 'BOULV']],
  ['Branch', ['BR', 'BRNCH']],
  ['Bridge', ['BRDGE', 'BRG']],
  ['Brook', ['BRK']],
  ['Brooks', ['BRKS']],
  ['Burg', ['BG']],
  ['Burgs', ['BGS']],
  ['Bypass', ['BYP', 'BYPA', 'BYPAS', 'BYPS']],
  ['Camp', ['CP', 'CMP']],
  ['Canyon', ['CANYN', 'CYN', 'CNYN']],
  ['Cape', ['CPE']],
  ['Causeway', ['CSWY', 'CAUSWA']],
  ['Center', ['CEN', 'CTR', 'CENT', 'CENTR', 'CENTRE', 'CNTER', 'CNTR']],
  ['Centers', ['CTRS']],
  ['Circle', ['CIR', 'CIRC', 'CIRCL', 'CRCL', 'CRCLE']],
  ['Circles', ['CIRS']],
  ['Cliff', ['CLF']],
  ['Cliffs', ['CLFS']],
  ['Club', ['CLB']],
  ['Common', ['CMN']],
  ['Commons', ['CMNS']],
  ['Corner', ['COR']],
  ['Corners', ['CORS']],
  ['Course', ['CRSE']],
  ['Court', ['CT']],
  ['Courts', ['CTS']],
  ['Cove', ['CV']],
  ['Coves', ['CVS']],
  ['Creek', ['CRK']],
  ['Crescent', ['CRES', 'CRSENT', 'CRSNT']],
  ['Crest', ['CRST']],
  ['Crossing', ['XING', 'CRSSNG']],
  ['Crossroad', ['XRD']],
  ['Crossroads', ['XRDS']],
  ['Curve', ['CURV']],
  ['Dale', ['DL']],
  ['Dam', ['DM']],
  ['Divide', ['DIV', 'DV', 'DVD']],
  ['Drive', ['DR', 'DRIV', 'DRV']],
  ['Drives', ['DRS']],
  ['Estate', ['EST']],
  ['Estates', ['ESTS']],
  ['Expressway', ['EXP', 'EXPY', 'EXPR', 'EXPRESS', 'EXPW']],
  ['Extension', ['EXT', 'EXTN', 'EXTNSN']],
  ['Extensions', ['EXTS']],
  ['Fall', ['Fall']],
  ['Falls', ['FLS']],
  ['Ferry', ['FRY', 'FRRY']],
  ['Field', ['FLD']],
  ['Fields', ['FLDS']],
  ['Flat', ['FLT']],
  ['Flats', ['FLTS']],
  ['Ford', ['FRD']],
  ['Fords', ['FRDS']],
  ['Forest', ['FRST', 'FORESTS']],
  ['Forge', ['FORG', 'FRG']],
  ['Forges', ['FRGS']],
  ['Fork', ['FRK']],
  ['Forks', ['FRKS']],
  ['Fort', ['FT', 'FRT']],
  ['Freeway', ['FWY', 'FREEWY', 'FRWAY', 'FRWY']],
  ['Garden', ['GDN', 'GARDN', 'GRDEN', 'GRDN']],
  ['Gardens', ['GDNS', 'GRDNS']],
  ['Gateway', ['GTWY', 'GATEWY', 'GATWAY', 'GTWAY']],
  ['Glen', ['GLN']],
  ['Glens', ['GLNS']],
  ['Green', ['GRN']],
  ['Greens', ['GRNS']],
  ['Grove', ['GROV', 'GRV']],
  ['Groves', ['GRVS']],
  ['Harbor', ['HARB', 'HBR', 'HARBR', 'HRBOR']],
  ['Harbors', ['HBRS']],
  ['Haven', ['HVN']],
  ['Heights', ['HT', 'HTS']],
  ['Highway', ['HWY', 'HIGHWY', 'HIWAY', 'HIWY', 'HWAY']],
  ['Hill', ['HL']],
  ['Hills', ['HLS']],
  ['Hollow', ['HLLW', 'HOLW', 'HOLLOWS', 'HOLWS']],
  ['Inlet', ['INLT']],
  ['Island', ['IS', 'ISLND']],
  ['Islands', ['ISS', 'ISLNDS']],
  ['Isle', ['ISLES']],
  ['Junction', ['JCT', 'JCTION', 'JCTN', 'JUNCTN', 'JUNCTON']],
  ['Junctions', ['JCTNS', 'JCTS']],
  ['Key', ['KY']],
  ['Keys', ['KYS']],
  ['Knoll', ['KNL', 'KNOL']],
  ['Knolls', ['KNLS']],
  ['Lake', ['LK']],
  ['Lakes', ['LKS']],
  ['Land', ['Land']],
  ['Landing', ['LNDG', 'LNDNG']],
  ['Lane', ['LN']],
  ['Light', ['LGT']],
  ['Lights', ['LGTS']],
  ['Loaf', ['LF']],
  ['Lock', ['LCK']],
  ['Locks', ['LCKS']],
  ['Lodge', ['LDG', 'LDGE', 'LODG']],
  ['Loop', ['LOOPS']],
  ['Mall', ['Mall']],
  ['Manor', ['MNR']],
  ['Manors', ['MNRS']],
  ['Meadow', ['MDW']],
  ['Meadows', ['MDW', 'MDWS', 'MEDOWS']],
  ['Mews', ['Mews']],
  ['Mill', ['ML']],
  ['Mills', ['MLS']],
  ['Mission', ['MISSN', 'MSN', 'MSSN']],
  ['Motorway', ['MTWY']],
  ['Mount', ['MNT', 'MT']],
  ['Mountain', ['MNTAIN', 'MTN', 'MNTN', 'MOUNTIN', 'MTIN']],
  ['Mountains', ['MNTNS', 'MTNS']],
  ['Neck', ['NCK']],
  ['Orchard', ['ORCH', 'ORCHRD']],
  ['Oval', ['OVL']],
  ['Overpass', ['OPAS']],
  ['Park', ['PRK']],
  ['Parks', ['PARK']],
  ['Parkway', ['PKWY', 'PARKWY', 'PKWAY', 'PKY']],
  ['Parkways', ['PKWY', 'PKWYS']],
  ['Pass', ['Pass']],
  ['Passage', ['PSGE']],
  ['Path', ['PATHS']],
  ['Pike', ['PIKES']],
  ['Pine', ['PNE']],
  ['Pines', ['PNES']],
  ['Place', ['PL']],
  ['Plain', ['PLN']],
  ['Plains', ['PLNS']],
  ['Plaza', ['PLZ', 'PLZA']],
  ['Point', ['PT']],
  ['Points', ['PTS']],
  ['Port', ['PRT']],
  ['Ports', ['PRTS']],
  ['Prairie', ['PR', 'PRR']],
  ['Radial', ['RAD', 'RADL', 'RADIEL']],
  ['Ramp', ['Ramp']],
  ['Ranch', ['RNCH', 'RANCHES', 'RNCHS']],
  ['Rapid', ['RPD']],
  ['Rapids', ['RPDS']],
  ['Rest', ['RST']],
  ['Ridge', ['RDG', 'RDGE']],
  ['Ridges', ['RDGS']],
  ['River', ['RIV', 'RVR', 'RIVR']],
  ['Road', ['RD']],
  ['Roads', ['RDS']],
  ['Route', ['RTE']],
  ['Row', ['Row']],
  ['Rue', ['Rue']],
  ['Run', ['Run']],
  ['Shoal', ['SHL']],
  ['Shoals', ['SHLS']],
  ['Shore', ['SHOAR', 'SHR']],
  ['Shores', ['SHOARS', 'SHRS']],
  ['Skyway', ['SKWY']],
  ['Spring', ['SPG', 'SPNG', 'SPRNG']],
  ['Springs', ['SPGS', 'SPNGS', 'SPRNGS']],
  ['Spur', ['Spur']],
  ['Spurs', ['SPUR']],
  ['Square', ['SQ', 'SQR', 'SQRE', 'SQU']],
  ['Squares', ['SQRS', 'SQS']],
  ['Station', ['STA', 'STATN', 'STN']],
  ['Stravenue', ['STRA', 'STRAV', 'STRAVEN', 'STRAVN', 'STRVN', 'STRVNUE']],
  ['Stream', ['STRM', 'STREME']],
  ['Street', ['ST', 'STRT', 'STR']],
  ['Streets', ['STS']],
  ['Summit', ['SMT', 'SUMIT', 'SUMITT']],
  ['Terrace', ['TER', 'TERR']],
  ['Throughway', ['TRWY']],
  ['Trace', ['TRCE', 'TRACES']],
  ['Track', ['TRAK', 'TRACKS', 'TRK', 'TRKS']],
  ['Trafficway', ['TRFY']],
  ['Trail', ['TRL', 'TRAILS', 'TRLS']],
  ['Trailer', ['TRLR', 'TRLRS']],
  ['Tunnel', ['TUNEL', 'TUNL', 'TUNLS', 'TUNNELS', 'TUNNL']],
  ['Turnpike', ['TRNPK', 'TPKE', 'TURNPK']],
  ['Underpass', ['UPAS']],
  ['Union', ['UN']],
  ['Unions', ['UNS']],
  ['Valley', ['VLY', 'VALLY', 'VLLY']],
  ['Valleys', ['VLYS']],
  ['Viaduct', ['VDCT', 'VIA', 'VIADCT']],
  ['View', ['VW']],
  ['Views', ['VWS']],
  ['Village', ['VILL', 'VLG', 'VILLAG', 'VILLG', 'VILLIAGE']],
  ['Villages', ['VLGS']],
  ['Ville', ['VL']],
  ['Vista', ['VIS', 'VIST', 'VST', 'VSTA']],
  ['Walk', ['Walk']],
  ['Walks', ['WALK']],
  ['Wall', ['Wall']],
  ['Way', ['WY']],
  ['Ways', ['Ways']],
  ['Well', ['WL']],
  ['Wells', ['WLS']]
]

exports.pairs = pairs
