// NOTE: this is used for display purposes only. It is also not in a
// "transactions" namespace because not all of these are limited to
// being transactions specifically (e.g. message-queue requests made to
// look like transactions)
exports.TRANSACTION_TYPE_LABELS = {
  'preauth': 'Pre-Authorization',
  'preauth-reversal': 'Pre-Authorization Reversal',
  'penalty': 'Penalty',
  'capture': 'Capture',
  'capture-customer-tip': 'Capture Customer Tip',
  'transfer': 'Transfer',
  'transfer-reversal': 'Transfer Reversal',
  'transfer-manual': 'Manual Transfer (paid out of band)',
  'transfer-discretionary': 'Discretionary Transfer',
  'transfer-driver-challenge': 'Transfer Driver Challenge',
  'transfer-driver-referral': 'Transfer Driver Referral',
  'transfer-customer-tip': 'Transfer Customer Tip',
  'refund': 'Refund',
  'payout': 'Payout',

  // The following aren't currently stored in the transactions table, but are
  // pulled from message_queue and made to look like transactions. They are not
  // actually transactions (just queued requests), but we choose to show them
  // much like transactions. Therefore, we have a transaction type defined for
  // them, and we make the queued requests "look" like transactions for the UI.
  'pending-transfer-request': 'Transfer Request',
  'failed-transfer-request': 'Transfer Request',
  'pending-payout-request': 'Payout Request',
  'failed-payout-request': 'Payout Request'
}
