const { LINKED_SHIPMENT_TYPES } = require('../shipments/constants')
const createModel = require('./createModel')
const { PropTypes } = require('../util')
const { MODES } = require('./constants')
const { CAPABILITIES } = require('../capReq/constants')
const { ShipmentOrder } = require('./ShipmentOrder')

const {
  SERVER_READ,
  SERVER_WRITE,
  CLIENT_READ,
  CLIENT_WRITE,
  CLIENT_ROLE_MERCHANT_READ,
  CLIENT_ROLE_DRIVER_READ
} = MODES

const linkedShipmentTypeArray = Object.keys(LINKED_SHIPMENT_TYPES).map((key) => LINKED_SHIPMENT_TYPES[key])

const schema = {
  id: {
    type: PropTypes.string,
    pgType: 'varchar(1024)',
    mode: SERVER_READ | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ
  },

  // Notes for support team to fill in for route
  internalNotes: {
    type: PropTypes.string,
    pgType: 'text',
    mode: SERVER_READ | CLIENT_READ | CLIENT_WRITE | SERVER_WRITE
  },

  // Date when this record was created
  createdAt: {
    type: PropTypes.firestoreTimestamp,
    pgType: 'timestamptz',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ
  },

  // Shipment is locked for changes until this time
  lockedUntil: {
    type: PropTypes.firestoreTimestamp,
    pgType: 'timestamptz',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ,
    default: null
  },

  // Merchant ID to whom this shipment belongs
  merchantId: {
    type: PropTypes.string,
    pgType: 'varchar(1024)',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_WRITE | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ
  },

  // Simpler identifier whose sequence is re-used for the same merchant.
  routeIdentifier: {
    type: PropTypes.string,
    pgType: 'varchar(1024)',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    default: null
  },

  // Date when the shipment should be broadcasted to the next driver
  broadcastAt: {
    type: PropTypes.firestoreTimestamp,
    pgType: 'timestamptz',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    default: null
  },

  // Requirements for the shipment that are writable by the merchant
  requirements: {
    // Must be "shape" instead of "exact" because we have some deprecated
    // capabilities (e.g. femaleOnly) that may still be in older shipment
    // records but still need e.g. displayName in order to render them.
    type: PropTypes.shape({
      vehicleType: PropTypes.oneOf([
        'any',
        'too_heavy', // has special handling in capabilities.js
        ...CAPABILITIES.vehicleType.data.map((obj) => obj.id)
      ]),
      specificMerchantId: PropTypes.bool,
      internalTrexityStaff: PropTypes.bool,
      internalTrexityDriverSupport: PropTypes.bool,
      internalTrexityMerchantSupport: PropTypes.bool, // leaving for backwards-compat
      legalAgeAlcohol: PropTypes.bool,
      chargeless: PropTypes.bool,
      returnReusables: PropTypes.bool
    }),
    pgType: 'jsonb',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_WRITE | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    get default () {
      return {}
    }
  },

  // Array of potential drivers for this shipment
  potentialDrivers: {
    // type: PropTypes.array, // can't restrict to array -- could be FieldValue.arrayUnion/arrayRemove
    pgType: 'jsonb',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    get default () {
      return []
    }
  },

  // Array of drivers who have dismissed this shipment
  dismissedBy: {
    // type: PropTypes.array, // can't restrict to array -- could be FieldValue.arrayUnion/arrayRemove
    pgType: 'jsonb',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ,
    get default () {
      return []
    }
  },

  // Array of drivers who have soft-dismissed (timed out) this shipment
  softDismissedBy: {
    // type: PropTypes.array, // can't restrict to array -- could be FieldValue.arrayUnion/arrayRemove
    pgType: 'jsonb',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ,
    get default () {
      return []
    }
  },

  softDismissedByDepth: {
    type: PropTypes.number,
    pgType: 'integer',
    mode: SERVER_READ | SERVER_WRITE,
    default: 0
  },

  // When empty, this shipment is orphaned. The broadcasting to drivers will
  // start when both the driverId is empty AND there is a
  // WAITING_FOR_ACCEPTANCE status. A shipment is in "draft" mode when it has
  // no status records at all.
  driverId: {
    type: PropTypes.string,
    pgType: 'varchar(1024)',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ
  },

  assignedByAdmin: {
    type: PropTypes.bool,
    pgType: 'boolean',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ,
    default: false
  },

  // TEMP: compatibility with old deliveryIndex
  deliveryIndex: {
    type: PropTypes.number,
    pgType: 'integer',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    default: null
  },

  orders: {
    type: PropTypes.objectOf(
      PropTypes.shape(
        Object.keys(ShipmentOrder.schema).reduce((memo, prop) => ({
          ...memo,
          [prop]: ShipmentOrder.schema[prop].type
        }), {})
      )
    ),
    pgType: 'jsonb',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_WRITE | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    get default () {
      return {}
    }
  },

  pickupAddress: {
    type: PropTypes.string,
    pgType: 'varchar(1024)',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_WRITE | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    default: ''
  },

  // Automatically computed when pickupAddress field is updated
  pickupAddressLocation: {
    type: PropTypes.object,
    pgType: 'geometry(Point,4326)',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ
  },

  // Automatically computed when pickupAddress field is updated
  pickupAddressProvince: {
    type: PropTypes.string,
    pgType: 'varchar(1024)',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    default: ''
  },

  // Automatically computed when pickupAddress field is updated
  pickupAddressCountry: {
    type: PropTypes.string,
    pgType: 'varchar(1024)',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    default: ''
  },

  // Automatically computed when pickupAddress field is updated
  pickupAddressCity: {
    type: PropTypes.string,
    pgType: 'varchar(1024)',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    default: ''
  },

  // Automatically computed when pickupAddress field is updated
  pickupAddressServiceCity: {
    type: PropTypes.string,
    pgType: 'varchar(1024)',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    default: ''
  },

  // DEPRECATED: This property is only still listed here to maintain backwards
  // compatibility with postgres syncing. Please use pickupAddressServiceCity
  // instead of this property.
  serviceCity: {
    type: PropTypes.string,
    pgType: 'varchar(1024)',
    mode: SERVER_READ | SERVER_WRITE,
    default: ''
  },

  pickupAddressNotes: {
    type: PropTypes.string,
    pgType: 'text',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_WRITE | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    default: ''
  },

  pickupNotes: {
    type: PropTypes.string,
    pgType: 'text',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_WRITE | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    default: ''
  },

  // Photo taken when the parcel is picked up
  pickupPhotoUrl: {
    type: PropTypes.string,
    pgType: 'text',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    default: ''
  },

  // Directions results after running the Google map's Directions service
  // against our pickup and delivery addresses
  directionsResult: {
    type: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string
    ]),
    pgType: 'text',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    default: null
  },

  // Delivery zone polygon (GeoJSON) JSON-stringified used in the shipment rate
  // calculations
  deliveryZone: {
    type: PropTypes.string,
    pgType: 'text',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ,
    default: null
  },

  // Snapshot of the shipment rate object
  shipmentRate: {
    type: PropTypes.object,
    pgType: 'jsonb',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    default: null
  },

  // Snapshot of the penalty shipment rate object, if any
  cancellationPenaltyRate: {
    type: PropTypes.object,
    pgType: 'jsonb',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    default: null
  },

  // Used in conjunction with orders for querying
  cachedOrdersData: {
    type: PropTypes.shape({
      orderId: PropTypes.arrayOf(PropTypes.string),
      shopifyOrderId: PropTypes.arrayOf(PropTypes.number),
      orderUuid: PropTypes.arrayOf(PropTypes.string),
      customerUpdateAddress_emailUuid: PropTypes.arrayOf(PropTypes.string)
    }),
    pgType: 'jsonb',
    mode: SERVER_READ | SERVER_WRITE,
    get default () {
      return {}
    }
  },

  // Flag that indicates that this shipment was redacted due to GDPR
  gdprRedacted: {
    type: PropTypes.bool,
    pgType: 'boolean',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ,
    default: false
  },

  // The Shipment is set to archived once it is COMPLETED, CANCELLED or EXPIRED
  archived: {
    type: PropTypes.bool,
    pgType: 'boolean',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    default: false
  },

  // Automatically computed based on latest status
  //
  // '' = DRAFT
  // WAITING_FOR_ACCEPTANCE = posted to drivers
  // EN_ROUTE_TO_PICKUP = driver accpeted and is now driving to pickup
  // OUT_FOR_DELIVERY = driver has picked up and is now driving to customer
  // RENOUNCED_BY_DRIVER
  // DELIVERED
  // ON_HOLD
  // CANCELLED
  // EXPIRED
  currentStatus: {
    type: PropTypes.string,
    pgType: 'varchar(1024)',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    default: ''
  },

  // Name of the driver who currently accepted the shipment
  cachedDriverName: {
    type: PropTypes.string,
    pgType: 'varchar(1024)',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ
  },

  cachedDriverCompensation: {
    type: PropTypes.number,
    pgType: 'numeric(12,3)',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_DRIVER_READ
  },

  cachedDriverDistanceBonus: {
    type: PropTypes.number,
    pgType: 'numeric(12,3)',
    default: 0,
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ
  },

  cachedDriverFuelBonus: {
    type: PropTypes.number,
    pgType: 'numeric(12,3)',
    default: 0,
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ
  },

  cachedDriverOversizedBonus: {
    type: PropTypes.number,
    pgType: 'numeric(12,3)',
    default: 0,
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ
  },

  cachedTotalRouteDistance: {
    type: PropTypes.number,
    pgType: 'numeric(12,3)',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ
  },

  cachedTotalRouteDuration: {
    type: PropTypes.number,
    pgType: 'numeric(12,3)',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ
  },

  cachedInitialFare: {
    type: PropTypes.number,
    pgType: 'numeric(12,3)',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ
  },

  cachedTotalWithoutTaxes: {
    type: PropTypes.number,
    pgType: 'numeric(12,3)',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ
  },

  cachedTaxes: {
    type: PropTypes.object,
    pgType: 'jsonb',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ
  },

  cachedTaxRates: {
    type: PropTypes.object,
    pgType: 'jsonb',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ
  },

  cachedTotal: {
    type: PropTypes.number,
    pgType: 'numeric(12,3)',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ
  },

  cachedMerchantName: {
    type: PropTypes.string,
    pgType: 'varchar(1024)',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ
  },

  cachedMerchantPhone: {
    type: PropTypes.string,
    pgType: 'varchar(1024)',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ
  },

  cachedMerchantEmail: {
    type: PropTypes.string,
    pgType: 'varchar(1024)',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ
  },

  cachedMerchantAddress: {
    type: PropTypes.string,
    pgType: 'varchar(1024)',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ
  },

  // Whenever fees are calculated, we store the current date
  feesCalculatedAt: {
    type: PropTypes.firestoreTimestamp,
    pgType: 'timestamptz',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    default: null
  },

  driverAcceptedAt: {
    type: PropTypes.firestoreTimestamp,
    pgType: 'timestamptz',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    default: null
  },

  driverStartedAt: {
    type: PropTypes.firestoreTimestamp,
    pgType: 'timestamptz',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    default: null
  },

  driverTimeToPickup: {
    type: PropTypes.number,
    pgType: 'numeric(12,3)',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ
  },

  driverTimePickingUp: {
    type: PropTypes.number,
    pgType: 'numeric(12,3)',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ
  },

  driverArrivedPickupAt: {
    type: PropTypes.firestoreTimestamp,
    pgType: 'timestamptz',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    default: null
  },

  driverPickedUpAt: {
    // type: Date, // Note: uses server/client firebase Timestamp object
    pgType: 'timestamptz',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    default: null
  },

  // Date when the current driver should arrive at pickup
  driverTargetPickupAt: {
    type: PropTypes.firestoreTimestamp,
    pgType: 'timestamptz',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    default: null
  },

  // Date when the current driver should deliver the entire shipment
  driverTargetDeliverAt: {
    type: PropTypes.firestoreTimestamp,
    pgType: 'timestamptz',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    default: null
  },

  // Distance in meters from the driver's location to the pickup location
  driverToPickupDistance: {
    type: PropTypes.number,
    pgType: 'numeric(12,3)',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    default: null
  },

  // When a shipment is saved, it will copy the current merchant value of
  // defaultTargetDeliveryDuration into the shipment. It's important for the
  // default value to be null, which implies that it has not yet been set. This
  // allows us to only set this once, and thereafter it's locked in. Note that
  // we allow the client to set this value, so that it has the opportunity to
  // set it to any value (including 0), and the code that copies it from the
  // merchant property understands that if the value is already present on the
  // shipment, will preserve that value.
  targetDeliveryDuration: {
    type: PropTypes.number,
    pgType: 'bigint',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_WRITE | CLIENT_ROLE_MERCHANT_READ,
    default: null
  },

  // This is when the Shipment cycle is complete
  // Should be set the moment a shipment is completed or cancelled
  completedAt: {
    type: PropTypes.firestoreTimestamp,
    pgType: 'timestamptz',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    default: null
  },

  // Computed Status prior to setting shipment to CANCELLED
  statusBeforeCancellation: {
    type: PropTypes.string,
    pgType: 'varchar(1024)',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ
  },

  cachedPenaltyDriverCompensation: {
    type: PropTypes.number,
    pgType: 'numeric(12,3)',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_DRIVER_READ
  },

  cachedPenaltyTotalWithoutTaxes: {
    type: PropTypes.number,
    pgType: 'numeric(12,3)',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ
  },

  cachedPenaltyTaxes: {
    type: PropTypes.object,
    pgType: 'jsonb',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ
  },

  cachedPenaltyTotal: {
    type: PropTypes.number,
    pgType: 'numeric(12,3)',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ
  },

  // Whenever penalty fees are calculated, we store the current date
  penaltyFeesCalculatedAt: {
    type: PropTypes.firestoreTimestamp,
    pgType: 'timestamptz',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    default: null
  },

  // Timestamp set when the shipment was first posted, or in other words when
  // its status goes to WAITING_FOR_ACCEPTANCE for the first time
  postedAt: {
    type: PropTypes.firestoreTimestamp,
    pgType: 'timestamptz',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    default: null
  },

  // Persist the awknowledgement of the driver when a merchant cancels
  // an accepted shipment.
  cancellationAcknowledgedByDriver: {
    type: PropTypes.bool,
    pgType: 'boolean',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_DRIVER_READ,
    default: false
  },

  scheduledPostAt: {
    type: PropTypes.firestoreTimestamp,
    pgType: 'timestamptz',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_WRITE | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    default: null
  },

  scheduledPostEnabled: {
    type: PropTypes.bool,
    pgType: 'boolean',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_WRITE | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    default: false
  },

  // Deprecated, but we continue to fill it out
  scheduledPostError: {
    type: PropTypes.string,
    pgType: 'text',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ,
    default: ''
  },

  postErrors: {
    type: PropTypes.arrayOf(PropTypes.exact({
      date: PropTypes.ISODateString.isRequired,
      key: PropTypes.string.isRequired,
      message: PropTypes.maxString(1024).isRequired,
      acknowledged: PropTypes.bool.isRequired,
      metadata: PropTypes.object.isRequired
    })),
    pgType: 'jsonb',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ,
    get default () {
      return []
    }
  },

  broadcastErrors: {
    type: PropTypes.arrayOf(PropTypes.exact({
      date: PropTypes.ISODateString.isRequired,
      key: PropTypes.string.isRequired,
      message: PropTypes.maxString(1024).isRequired,
      acknowledged: PropTypes.bool.isRequired,
      metadata: PropTypes.object.isRequired
    })),
    pgType: 'jsonb',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ,
    get default () {
      return []
    }
  },

  cachedPostErrorsHasUnacknowledged: {
    type: PropTypes.bool,
    pgType: 'boolean',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ,
    default: false
  },

  cachedBroadcastErrorsHasUnacknowledged: {
    type: PropTypes.bool,
    pgType: 'boolean',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ,
    default: false
  },

  cachedErrorsHasUnacknowledged: {
    type: PropTypes.bool,
    pgType: 'boolean',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ,
    default: false
  },

  // Estimated seconds to pickup location for the current driverId on this
  // shipment. This value changes over time
  currentDriverEstimatedSecondsToPickup: {
    type: PropTypes.number,
    pgType: 'numeric(12,3)',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    default: null
  },

  // Timestamp when the estimated seconds to pickup location for the current
  // driverId was taken. This value also changes over time, and allows us to
  // space out how often we request updated directions
  currentDriverEstimatedSecondsToPickupComputedAt: {
    type: PropTypes.firestoreTimestamp,
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    pgType: 'timestamptz',
    default: null
  },

  // For linked shipments. It is an object of the parent's shipmentId
  // and the type of linking
  linkedParentShipment: {
    type: PropTypes.shape({
      shipmentId: PropTypes.string,
      type: PropTypes.oneOf(linkedShipmentTypeArray),
      shipmentRate: PropTypes.object // NOTE: CLIENT_ROLE_MERCHANT_READ is present, yet does this get filtered in the publication view? Probably not... and it probably should.
    }),
    pgType: 'jsonb',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_WRITE | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    default: null
  },

  // For linked shipments. It is an array of objects containing the linked children's
  // shipmentIds and the types
  cachedLinkedChildShipments: {
    type: PropTypes.arrayOf(
      PropTypes.shape({
        shipmentId: PropTypes.string,
        type: PropTypes.oneOf(linkedShipmentTypeArray)
      })
    ),
    pgType: 'jsonb',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    get default () {
      return []
    }
  },

  partnerData: {
    type: PropTypes.shape({
      ppid: PropTypes.id,
      name: PropTypes.string,
      phone: PropTypes.string,
      email: PropTypes.string
    }),
    pgType: 'jsonb',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_WRITE | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    default: null
  },

  // FR: This is temporary added field until we come up with a subscriptions server.
  // This will increase when event logs for this shipment have been added.
  // (we use this as a signalling mechanism for polling in admin app)
  tempEventLogCounter: {
    type: PropTypes.number,
    pgType: 'integer',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ,
    default: 0
  },

  searchTerms: {
    type: PropTypes.array,
    pgType: 'jsonb',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ,
    get default () {
      return []
    }
  },

  // Metadata field for carry-along data that is meant to only be written and
  // read after being queried, but not used in 'where' queries. The root-level
  // keys should be roles that are allowed to read this data. Publication views
  // are currently leveraged to clear out non-accessible properties by role.
  metadata: {
    type: PropTypes.exact({
      any: PropTypes.any,
      server: PropTypes.any,
      merchant: PropTypes.any,
      driver: PropTypes.any,

      // Backwards compatibility
      shopify: PropTypes.any,
      zapiet: PropTypes.any,
      combinedLinkedParentTypes: PropTypes.any
    }),
    pgType: 'jsonb',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    get default () {
      return {}
    }
  },

  // Snapshot of routePlanResults related to this shipment
  cachedRoutePlanResults: {
    type: PropTypes.arrayOf(PropTypes.exact({
      createdAt: PropTypes.firestoreTimestamp.isRequired,
      updatedAt: PropTypes.firestoreTimestamp, // no .isRequired for backwards-compatibility
      shipmentIds: PropTypes.arrayOf(PropTypes.id).isRequired,
      data: PropTypes.string.isRequired
    })),
    pgType: 'jsonb',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ,
    default: []
  },

  // ---------------------------------------------------------------------------
  // Actual vs planned segment timings/distances

  pickupSegmentPlannedDistance: {
    type: PropTypes.number,
    pgType: 'numeric(12,3)',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    default: null
  },

  pickupSegmentPlannedTravelDuration: {
    type: PropTypes.number,
    pgType: 'numeric(12,3)',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    default: null
  },

  pickupSegmentPlannedDwellDuration: {
    type: PropTypes.number,
    pgType: 'numeric(12,3)',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    default: null
  },

  pickupSegmentPlannedTotalDuration: {
    type: PropTypes.number,
    pgType: 'numeric(12,3)',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    default: null
  },

  pickupSegmentActualDistance: {
    type: PropTypes.number,
    pgType: 'numeric(12,3)',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    default: null
  },

  pickupSegmentActualTravelDuration: {
    type: PropTypes.number,
    pgType: 'numeric(12,3)',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    default: null
  },

  pickupSegmentActualDwellDuration: {
    type: PropTypes.number,
    pgType: 'numeric(12,3)',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    default: null
  },

  pickupSegmentActualTotalDuration: {
    type: PropTypes.number,
    pgType: 'numeric(12,3)',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    default: null
  }
}

module.exports = createModel('Shipment', schema, MODES, {
  ServerReadDto: require('./dto/internal/ShipmentServerReadDto'),
  ServerWriteDto: require('./dto/internal/ShipmentServerWriteDto'),
  ClientReadDto: require('./dto/internal/ShipmentClientReadDto'),
  ClientWriteDto: require('./dto/internal/ShipmentClientWriteDto')
})
