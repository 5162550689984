module.exports = function createChangedSet (allowedKeys, newData, currentData) {
  return allowedKeys.reduce((memo, fieldName) => {
    const {
      [fieldName]: value = null
    } = newData

    if (value !== null && currentData[fieldName] !== value) {
      return {
        ...memo,
        [fieldName]: value
      }
    } else {
      return memo
    }
  }, {})
}
