import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { COLORS } from '@trexity/common/color/constants'

export const SelectWrapper = ({ children, active, bg = COLORS.TREXITY_BLUE }) => {
  const wrapper = useRef()
  const [bottom, setBottom] = useState({ bottom: -115 })

  useEffect(() => {
    const rect = wrapper.current.getBoundingClientRect()

    if (active) {
      if (window.innerHeight - rect.top < 300) {
        setBottom({ top: 13, opacity: 1 })
      } else {
        setBottom({ top: 40, opacity: 1 })
      }
    } else {
      setBottom({ bottom: 0, opacity: 0 })
    }
  }, [active])

  return (
    <div
      className='select-wrapper'
      ref={wrapper}
      style={{
        backgroundColor: bg,
        borderRadius: 8,
        marginTop: 8,
        opacity: 0,
        position: 'absolute',
        width: '100%',
        zIndex: 999,
        overflow: 'hidden',
        ...bottom
      }}
    >
      {children}
    </div>
  )
}

SelectWrapper.propTypes = {
  children: PropTypes.node,
  active: PropTypes.bool,
  bg: PropTypes.string
}
