import { twoDigitPad } from './twoDigitPad'

// Returns a date only string like YYYY-MM-DD.
// Uses the Date's local time to get the date-only components.
export function dateOnly (date, { includeDay = true } = {}) {
  return [
    date.getFullYear(),
    twoDigitPad(date.getMonth() + 1),
    includeDay ? twoDigitPad(date.getDate()) : null
  ].filter(Boolean).join('-')
}
