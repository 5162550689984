import React from 'react'
import PropTypes from 'prop-types'
import { useMediaQuery } from 'react-responsive'
import { COLORS } from '@trexity/common/color/constants'
import { MEDIA_QUERIES } from '../../constants'
import { Switch, Spin, Typography } from 'antd'

const { MQ_MEDIUM } = MEDIA_QUERIES

/**
 * @param {object} options
 * @param {object} [options.isLoading]
 * @param {object} [options.loader]
 * @param {object} [options.onLegendItemClicked]
 * @param {object} [options.pickupZoneVisibleOnLoad=true]
 * @param {object} [options.deliveryZoneVisibleOnLoad=true]
 * @param {object} [options.extendedDeliveryZoneVisibleOnLoad=true]
 * @param {object} [options.disablePickupZoneToggle=false]
 * @param {object} [options.disableDeliveryZoneToggle=false]
 * @param {object} [options.disableExtendedDeliveryZoneToggle=false]
 * @param {object} options.children
 * @returns {JSX.Element}
 * @constructor
 */
export default function ZoneMapWrapper ({
  isLoading,
  loader,
  onLegendItemClicked,
  pickupZoneVisibleOnLoad = true,
  deliveryZoneVisibleOnLoad = true,
  extendedDeliveryZoneVisibleOnLoad = true,
  disablePickupZoneToggle = false,
  disableDeliveryZoneToggle = false,
  disableExtendedDeliveryZoneToggle = false,
  children
}) {
  const isDesktop = useMediaQuery(MQ_MEDIUM)

  const [pickupZoneVisible, setPickupZoneVisible] = React.useState(pickupZoneVisibleOnLoad)
  const [deliveryZoneVisible, setDeliveryZoneVisible] = React.useState(deliveryZoneVisibleOnLoad)
  const [extendedDeliveryZoneVisible, setExtendedDeliveryZoneVisible] = React.useState(extendedDeliveryZoneVisibleOnLoad)

  return (
    <div style={{ boxShadow: '0px 2px 15px rgba(0,0,0,0.10)', height: isDesktop ? '100%' : 'auto', minHeight: 500, display: 'flex', flex: 1, flexDirection: 'column' }}>
      <div style={{ display: 'flex', flex: 1 }}>
        {children}
      </div>
      <div style={{ display: 'flex', flex: 0, position: 'relative' }}>
        {isLoading ? <div style={{ position: 'absolute', left: '45%', top: '45%' }}>{loader || <Spin size='large' spinning />}</div> : null}
        <div style={{ padding: 40, width: '100%' }}>
          <div style={{ marginBottom: 10 }}>
            <strong>Toggle zones</strong>
          </div>
          <div style={{ marginBottom: 10 }}>
            <a
              href='#'
              onClick={(evt) => {
                evt.preventDefault()

                if (disablePickupZoneToggle) {
                  return
                }

                setPickupZoneVisible(!pickupZoneVisible)

                if (typeof onLegendItemClicked === 'function') {
                  onLegendItemClicked({ legendName: 'pickup', enabled: !pickupZoneVisible })
                }
              }}
              style={{
                display: 'flex',
                alignItems: 'center',
                cursor: disablePickupZoneToggle ? 'not-allowed' : 'pointer',
                color: pickupZoneVisible && !disablePickupZoneToggle ? COLORS.TREXITY_MERCHANT_BLUE : 'lightgrey'
              }}
            >
              <Switch
                disabled={isLoading || disablePickupZoneToggle}
                style={{
                  backgroundColor: pickupZoneVisible ? COLORS.TREXITY_MERCHANT_BLUE : 'lightgrey',
                  marginRight: 5
                }}
                checked={pickupZoneVisible}
              /> Supported pickup zone
            </a>
          </div>
          <div style={{ marginBottom: 10 }}>
            <a
              href='#'
              onClick={(evt) => {
                evt.preventDefault()

                if (disableDeliveryZoneToggle) {
                  return
                }

                setDeliveryZoneVisible(!deliveryZoneVisible)

                if (typeof onLegendItemClicked === 'function') {
                  onLegendItemClicked({ legendName: 'delivery', enabled: !deliveryZoneVisible })
                }
              }}
              style={{
                display: 'flex',
                alignItems: 'center',
                cursor: disableDeliveryZoneToggle ? 'not-allowed' : 'pointer',
                color: deliveryZoneVisible && !disableDeliveryZoneToggle ? COLORS.TREXITY_MERCHANT_PINK : 'lightgrey'
              }}
            >
              <Switch
                disabled={isLoading || disableDeliveryZoneToggle}
                style={{
                  backgroundColor: deliveryZoneVisible ? COLORS.TREXITY_MERCHANT_PINK : 'lightgrey',
                  marginRight: 5
                }}
                checked={deliveryZoneVisible}
              /> Normal delivery zone
            </a>
          </div>
          <div>
            <a
              href='#'
              onClick={(evt) => {
                evt.preventDefault()

                if (disableExtendedDeliveryZoneToggle) {
                  return
                }

                setExtendedDeliveryZoneVisible(!extendedDeliveryZoneVisible)

                if (typeof onLegendItemClicked === 'function') {
                  onLegendItemClicked({ legendName: 'extended', enabled: !extendedDeliveryZoneVisible })
                }
              }}
              style={{
                display: 'flex',
                alignItems: 'center',
                cursor: disableExtendedDeliveryZoneToggle ? 'not-allowed' : 'pointer',
                color: extendedDeliveryZoneVisible && !disableExtendedDeliveryZoneToggle ? COLORS.TREXITY_MERCHANT_LIGHT_BLUE : 'lightgrey'
              }}
            >
              <Switch
                disabled={isLoading || disableExtendedDeliveryZoneToggle}
                style={{
                  backgroundColor: extendedDeliveryZoneVisible ? COLORS.TREXITY_MERCHANT_LIGHT_BLUE : 'lightgrey',
                  marginRight: 5
                }}
                checked={extendedDeliveryZoneVisible}
              /> Extended delivery zone
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

ZoneMapWrapper.propTypes = {
  containerStyle: PropTypes.object,
  zonesLegendStyle: PropTypes.object,
  isLoading: PropTypes.bool,
  loader: PropTypes.node,
  onLegendItemClicked: PropTypes.func,
  pickupZoneVisibleOnLoad: PropTypes.bool,
  deliveryZoneVisibleOnLoad: PropTypes.bool,
  extendedDeliveryZoneVisibleOnLoad: PropTypes.bool,
  disablePickupZoneToggle: PropTypes.bool,
  disableDeliveryZoneToggle: PropTypes.bool,
  disableExtendedDeliveryZoneToggle: PropTypes.bool,
  markers: PropTypes.array,
  center: PropTypes.object,
  googleMapsApiKey: PropTypes.string,
  children: PropTypes.node
}
