import * as React from 'react'
import * as PropTypes from 'prop-types'
import { formatPhoneNumber, validatePhoneNumber } from '@trexity/common/phone'
import { PHONE_EXTENSIONS } from '@trexity/common/phone/constants'

import {
  Row,
  Col,
  Input,
  Form,
  Button
} from 'antd'

const { STORED_EXTENSION } = PHONE_EXTENSIONS

export default function PhoneChangeVerificationForm ({
  phoneVerification,
  onVerificationTextClicked,
  onVerificationCallClicked,
  onVerificationConfirmationClicked,
  isProcessingPhoneVerification,
  isProcessingPhoneConfirmation,
  isPhoneVerified,
  newPhoneLabel = 'New phone number',
  verificationCodeInputProps = {},
  newPhoneInputProps = {},
  newPhoneExtInputProps = {},
  errorOccurred
} = {}) {
  const [loaded, setLoaded] = React.useState(false)
  const [phoneNumber, setPhoneNumber] = React.useState('')
  const [phoneNumberExt, setPhoneNumberExt] = React.useState('')
  const [fullPhoneNumber, setFullPhoneNumber] = React.useState('')
  const [phoneNumberCode, setPhoneNumberCode] = React.useState('')
  const [validContactPhone, setValidContactPhone] = React.useState({ valid: true })
  const [isThrottlingPhoneText, setIsThrottlingPhoneText] = React.useState(null)
  const [isThrottlingPhoneCall, setIsThrottlingPhoneCall] = React.useState(null)
  const [countdownPhoneText, setCountdownPhoneText] = React.useState(0)
  const [countdownPhoneCall, setCountdownPhoneCall] = React.useState(0)
  const [verificationStatus, setVerificationStatus] = React.useState('')

  React.useEffect(() => {
    return () => {
      clearInterval(isThrottlingPhoneText)
      clearInterval(isThrottlingPhoneCall)
    }
  }, [])

  React.useEffect(() => {
    if (errorOccurred) {
      clearThrottlePhoneCall()
      clearThrottlePhoneText()
    }
  }, [errorOccurred])

  React.useEffect(() => {
    if (!loaded && phoneVerification && !phoneVerification.verifiedAt) {
      phoneValidator(phoneVerification.contactPhone)
      setFullPhoneNumber(phoneVerification.contactPhone)
      setPhoneNumber(phoneVerification.contactPhone.split(STORED_EXTENSION)[0])
      setPhoneNumberExt(phoneVerification.contactPhone.indexOf(STORED_EXTENSION) > -1 ? phoneVerification.contactPhone.split(STORED_EXTENSION)[1] : '')
      setLoaded(true)
    }

    if (phoneVerification && phoneVerification.verificationRequest && phoneVerification.verificationRequest.status) {
      setVerificationStatus(phoneVerification.verificationRequest.status)
    } else {
      setVerificationStatus('')
    }
  }, [phoneVerification, loaded])

  function setPhoneNumberWithExt (phone, ext) {
    return (
      ext
        ? `${phone}${STORED_EXTENSION}${ext}`
        : phone
    )
  }

  function phoneValidator (value) {
    setValidContactPhone({
      valid: !value || validatePhoneNumber(value),
      message: 'Please check the phone number pattern "+1 xxx xxx xxxx" with optional extension number'
    })
  }

  function throttlePhoneCall () {
    clearThrottlePhoneCall()
    let countdown = 10
    setCountdownPhoneCall(countdown)

    const handleInterval = setInterval(() => {
      if (countdown) {
        countdown--
        setCountdownPhoneCall(countdown)

        if (!countdown) {
          clearThrottlePhoneCall()
        }
      }
    }, 1000)

    setIsThrottlingPhoneCall(handleInterval)
  }

  function throttlePhoneText () {
    clearThrottlePhoneText()
    let countdown = 10
    setCountdownPhoneText(countdown)

    const handleInterval = setInterval(() => {
      if (countdown) {
        countdown--
        setCountdownPhoneText(countdown)

        if (!countdown) {
          clearThrottlePhoneText()
        }
      }
    }, 1000)

    setIsThrottlingPhoneText(handleInterval)
  }

  function clearThrottlePhoneCall () {
    clearInterval(isThrottlingPhoneCall)
    setIsThrottlingPhoneCall(null)
    setCountdownPhoneCall(0)
  }

  function clearThrottlePhoneText () {
    clearInterval(isThrottlingPhoneText)
    setIsThrottlingPhoneText(null)
    setCountdownPhoneText(0)
  }

  return (
    <Form
      colon={false}
      hideRequiredMark={true}
      layout='vertical'
    >
      <Form.Item
        label={newPhoneLabel + (verificationStatus ? ` (${verificationStatus})` : '')}
        validateStatus={!validContactPhone.valid ? 'error' : undefined}
        help={!validContactPhone.valid ? validContactPhone.message : undefined}
      >
        <Row gutter={[20, { xs: 20, sm: 20, md: 20, lg: 0 }]}>
          <Col sm={24} lg={14}>
            <Input.Group compact>
              <Input
                style={{ width: '70%' }}
                size='large'
                type='tel'
                value={phoneNumber}
                disabled={isPhoneVerified}
                {...newPhoneInputProps}
                onChange={(evt) => {
                  const formattedPhone = formatPhoneNumber(evt.target.value)
                  const fullPhone = setPhoneNumberWithExt(formattedPhone, phoneNumberExt)
                  phoneValidator(fullPhone)
                  setPhoneNumber(formattedPhone)
                  setFullPhoneNumber(fullPhone)
                }}
              />
              <Input
                style={{ width: '30%' }}
                size='large'
                placeholder='Ext.'
                value={phoneNumberExt}
                disabled={isPhoneVerified}
                {...newPhoneExtInputProps}
                onChange={(evt) => {
                  const fullPhone = setPhoneNumberWithExt(phoneNumber, evt.target.value)
                  phoneValidator(fullPhone)
                  setPhoneNumberExt(evt.target.value)
                  setFullPhoneNumber(fullPhone)
                }}
              />
            </Input.Group>
          </Col>
          <Col sm={24} lg={10}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ display: 'flex', flex: 1, marginRight: 20 }}>
                <Button
                  type='primary'
                  size='large'
                  loading={isProcessingPhoneVerification}
                  disabled={!fullPhoneNumber || isProcessingPhoneVerification || isProcessingPhoneConfirmation || countdownPhoneCall || countdownPhoneText || isPhoneVerified}
                  onClick={() => {
                    setPhoneNumberCode('')
                    throttlePhoneCall()
                    onVerificationCallClicked(fullPhoneNumber)
                  }}
                  block
                >
                  {countdownPhoneCall ? `${countdownPhoneCall}` : 'Call me'}
                </Button>
              </div>
              <div style={{ display: 'flex', flex: 1 }}>
                <Button
                  type='primary'
                  size='large'
                  loading={isProcessingPhoneVerification}
                  disabled={!fullPhoneNumber || isProcessingPhoneVerification || isProcessingPhoneConfirmation || countdownPhoneCall || countdownPhoneText || isPhoneVerified}
                  onClick={() => {
                    setPhoneNumberCode('')
                    throttlePhoneText()
                    onVerificationTextClicked(fullPhoneNumber)
                  }}
                  block
                >
                  {countdownPhoneText ? `${countdownPhoneText}` : 'Text me'}
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Form.Item>
      <Form.Item
        label='Verification code'
      >
        <Row gutter={20}>
          <Col span={14}>
            <Input
              size='large'
              value={phoneNumberCode}
              disabled={isPhoneVerified}
              {...verificationCodeInputProps}
              onChange={(evt) => {
                setPhoneNumberCode(evt.target.value)
              }}
            />
          </Col>
          <Col span={10}>
            <Button
              type='primary'
              size='large'
              loading={isProcessingPhoneConfirmation}
              disabled={!phoneNumber || !phoneNumberCode || isProcessingPhoneVerification || isProcessingPhoneConfirmation || isPhoneVerified}
              onClick={() => {
                onVerificationConfirmationClicked(phoneNumberCode)
              }}
              block
            >
              Verify
            </Button>
          </Col>
        </Row>
      </Form.Item>
    </Form>
  )
}

PhoneChangeVerificationForm.propTypes = {
  phoneVerification: PropTypes.object,
  isProcessingPhoneVerification: PropTypes.bool,
  isProcessingPhoneConfirmation: PropTypes.bool,
  isPhoneVerified: PropTypes.bool,
  newPhoneLabel: PropTypes.string,
  onVerificationTextClicked: PropTypes.func.isRequired,
  onVerificationCallClicked: PropTypes.func.isRequired,
  onVerificationConfirmationClicked: PropTypes.func.isRequired,
  verificationCodeInputProps: PropTypes.object,
  newPhoneInputProps: PropTypes.object,
  newPhoneExtInputProps: PropTypes.object,
  errorOccurred: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
}
