import { createConfig, required, toBool } from '@trexity/common/config'

const Config = createConfig({
  PUBLIC_RATES_SERVICE_BASE_URL: required(() => process.env.PUBLIC_RATES_SERVICE_BASE_URL),
  SITE_URL: required(() => process.env.SITE_URL),
  DEVELOPERS_PORTAL_URL: required(() => process.env.DEVELOPERS_PORTAL_URL),
  MERCHANT_PORTAL_URL: required(() => process.env.MERCHANT_PORTAL_URL),
  ENABLE_SOCIAL_TRACKING: toBool(required(() => process.env.ENABLE_SOCIAL_TRACKING)),
  GOOGLE_MAPS_API_KEY: required(() => process.env.SITE_GOOGLE_MAPS_API_KEY),
  CREATE_UPDATE_ADDRESS_TICKET_URL: required(() => process.env.CREATE_UPDATE_ADDRESS_TICKET_URL),
  GET_ORDER_DETAILS_URL: required(() => process.env.GET_ORDER_DETAILS_URL),
  CF_IMPERSONATE_URL: required(() => process.env.CF_IMPERSONATE_URL),
  APP_SERVICE_BASE_URL: required(() => process.env.APP_SERVICE_BASE_URL),
  CLOUD_FUNCTIONS_BASE_URL: required(() => process.env.CLOUD_FUNCTIONS_BASE_URL),
  API_V1_0_SERVICE_BASE_URL: required(() => process.env.API_V1_0_SERVICE_BASE_URL),
  SUBSCRIPTIONS_BASE_URL: required(() => process.env.SUBSCRIPTIONS_BASE_URL),
  SHIPPING_LABELS_SERVICE_BASE_URL: required(() => process.env.SHIPPING_LABELS_SERVICE_BASE_URL),
  STORAGE_SERVICE_BASE_URL: required(() => process.env.STORAGE_SERVICE_BASE_URL),
  SDK_VERSION: required(() => process.env.SDK_VERSION),
  ZONES_PAGE_REDIRECT_URL: `${process.env.MERCHANT_PORTAL_URL}/zones`,
  OPEN_API_BRANCH: process.env.OPEN_API_BRANCH || 'main' // like "main", or "beta"
})

/**
 * Set query parameters on a url. The url can be an absolute url or a relative url.
 *
 * @example setQuery('my-page', { id: 45 })
 * @example setQuery('https://my.domain.com/page', { key: '3456' })
 * @param {string|URL} url
 * @param {{key?:string|number|boolean}} [params={}]
 * @return {string}
 */
function setQuery (url, params = {}) {
  const prefix = String(url).startsWith('/') ? '/' : ''
  const u = new URL(url, 'https://example.com')
  // eslint-disable-next-line guard-for-in
  for (const k in params) u.searchParams.set(k, params[k])
  return prefix + u.href.replace('https://example.com/', '')
}

// Expose "Config" for debugging purposes, but only in an environment that
// supports it (i.e. non-SSR, such as during development)
if (typeof window !== 'undefined') {
  window.Config = Config
}

export default Config
