/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import PropTypes from 'prop-types'
import { COLORS } from '@trexity/common/color/constants'

export const Chart = ({
  data,
  ...rest
}) => {
  return (
    <div css={ChartBase} {...rest}>
      {data.map((row, index) => (
        <div css={ChartRow} key={index}>
          {row.map((col, id) => <div css={ChartCol} key={id}>{col}</div>)}
        </div>
      )
      )}
    </div>
  )
}

export const ChartBase = {
  flex: 1,
  borderWidth: 1,
  borderColor: COLORS.MEDIUM_LIGHT,
  borderStyle: 'solid',
  backgroundColor: COLORS.TREXITY_LIGHT_GREY
}

export const ChartRow = css`
  display: flex;
  flex-direction: row;
  border-width: 0 0 1px 0;
  border-color: ${COLORS.MEDIUM_LIGHT};
  border-style: solid;

  &:last-of-type { border: none; }
`

export const ChartCol = css`
  flex: 2;
  padding: 10px 15px;
  background-color: ${COLORS.WHITE};

  &:first-of-type {
    flex: 1;
    font-weight: 600;
    background-color: ${COLORS.TREXITY_LIGHT_GREY};
  }
`

Chart.propTypes = {
  data: PropTypes.array
}
