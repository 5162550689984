import React from 'react'
import PropTypes from 'prop-types'
import { COLORS } from '@trexity/common/color/constants'
import Icon from '@material-ui/core/Icon'

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'

export const A = ({
  children,
  light,
  external,
  style,
  ...rest
}) => {
  return (
    <a css={[ALinkStyles(light), style]} {...rest}>
      {children} {external ? <Icon style={IconAStyles}>launch</Icon> : null}
    </a>
  )
}

export const ALinkStyles = (light) => ({
  fontSize: 'inherit',
  lineHeight: '1.4em',
  color: light ? COLORS.TREXITY_LIGHT_BLUE : COLORS.TREXITY_BLUE,
  textDecoration: 'underline'
})

export const IconAStyles = {
  position: 'relative',
  fontSize: '0.8em',
  verticalAlign: 'bottom',
  top: '-0.3em'
}

A.propTypes = {
  external: PropTypes.bool,
  lede: PropTypes.bool,
  light: PropTypes.bool,
  children: PropTypes.node,
  style: PropTypes.object
}
