module.exports = function encodePath (path) {
  const result = []
  let start = [0, 0]
  let end = null

  const encodePart = function (part) {
    part = part < 0 ? ~(part << 1) : (part << 1)

    while (part >= 0x20) {
      result.push(String.fromCharCode((0x20 | (part & 0x1f)) + 63))
      part >>= 5
    }

    result.push(String.fromCharCode(part + 63))
  }

  for (let i = 0, I = path.length || 0; i < I; ++i) {
    end = [Math.round(path[i].lat * 1e5), Math.round(path[i].lng * 1e5)]
    encodePart(end[0] - start[0]) // lat
    encodePart(end[1] - start[1]) // lng
    start = end
  }

  return result.join('')
}
