const { toCountryAbbr } = require('./address-extractor')

/**
 * Attempts to remove the country from the given address, and returns it.
 * It only works if the country is at the end of the address.
 *
 * @param {String} address
 * @param {[String, String|null]} tuple of address and countryAbbr (e.g. "CA", "USA"), where countryAbbr can be null if not country is found
 */
function removeCountry (address) {
  const regex = /(ca|canada|can|us|usa|united states|united states of america)$/iu
  const trimmed = address.replace(/[, ]+$/gu, '')
  const match = trimmed.match(regex)
  const country = match && toCountryAbbr(match[1])

  if (country) {
    return [trimmed.replace(regex, '').replace(/[, ]+$/gu, ''), country]
  }

  return [address, null]
}

exports.removeCountry = removeCountry
