const { DateOnly } = require('../types')

const isLastDayInMonth = (day, month, year) => {
  const days = DateOnly.daysInMonth(year, month)
  return day === days
}

const getNearestAcceptableDate = (date, {
  interval = 15
} = {}) => {
  if (!(date instanceof Date) || isNaN(date.getTime())) {
    throw new Error('date is not a Date.')
  }

  if (60 % interval !== 0) {
    throw new Error('Interval must be a divisor of 60.')
  }

  const intervalMs = interval * 60 * 1000
  let t = date.getTime()
  // Round down to the nearest minute
  t = Math.floor(t / 60000) * 60000
  // Then we round up to the nearest interval
  const multiple = Math.ceil(t / intervalMs)
  return new Date(multiple * intervalMs)
}

exports.getNearestAcceptableDate = getNearestAcceptableDate
exports.isLastDayInMonth = isLastDayInMonth
