const { convertOrdersToArray, convertOrdersArrayToObject } = require('../models/ShipmentOrder')

function sortOrdersByDirectionsResult ({
  orders,
  directionsResult
}) {
  const ordersArray = convertOrdersToArray(orders)

  const routes = (directionsResult || {}).routes || []
  const route = routes[0] || {}
  const legs = route.legs || []

  const legIndexes = legs.reduce((memo, leg, index) => ({
    ...memo,
    [(leg && leg.metadata && leg.metadata.orderUuid) || index]: index
  }), {})

  ordersArray.sort((a, b) => {
    return (legIndexes[a.id] || 0) - (legIndexes[b.id] || 0)
  })

  return convertOrdersArrayToObject(ordersArray)
}

exports.sortOrdersByDirectionsResult = sortOrdersByDirectionsResult
