/* eslint-disable camelcase */
const polylineToGeoJson = require('./polylineToGeoJson')
const generateBounds = require('./generateBounds')

function mapTravelModeFromProfile (profile) {
  switch (profile) {
    case 'mapbox/driving':
      return 'driving'
    default:
      throw new Error('Unsupported profile: ' + profile)
  }
}

module.exports = function mapboxDirectionsToGeoJsonDirections (
  optimizationResponse,
  originalRequestParameters
) {
  const {
    geometries,
    roundtrip,
    profile = 'mapbox/driving'
  } = originalRequestParameters || {}

  if (!optimizationResponse) {
    throw new Error('Missing optimizationResponse.')
  }

  if (geometries !== 'polyline') {
    throw new Error('geometries must be "polyline".')
  }

  const { trips, waypoints } = optimizationResponse

  // trips will always be an array of 0 or 1.

  if (trips && trips.length === 0) {
    throw new Error('Missing trips in response object.')
  }

  const trip = trips[0]

  const bounds = generateBounds(waypoints.map(({ location }) => ({
    latitude: location[1],
    longitude: location[0]
  })))

  const legs = trip.legs && trip.legs.map(({
    distance = 0,
    duration = 0,
    steps = []
  }, index) => ({
    distance,
    duration,
    startLocation: {
      latitude: waypoints[index].location[1],
      longitude: waypoints[index].location[0]
    },
    endLocation: {
      latitude: waypoints[
        (roundtrip && (index === waypoints.length - 1)) ? 0 : (index + 1)
      ].location[1],
      longitude: waypoints[
        (roundtrip && (index === waypoints.length - 1)) ? 0 : (index + 1)
      ].location[0]
    },
    steps: steps && steps.map(({
      mode,
      geometry
    }) => ({
      travelMode: mode,
      geoJsonPolyline: polylineToGeoJson(geometry)
    }))
  }))

  return polylineToGeoJson(trip.geometry, {
    bounds,
    legs,
    travelMode: mapTravelModeFromProfile(profile)
  })
}
