const { Std } = require('../../core')
const { isServiceHours } = require('./isServiceHours')

/**
 * Determines if a weekday is closed.
 *
 * @param {ServiceHours} serviceHours
 * @param {number} weekday O = Sunday, 6 = Saturday
 * @return {boolean}
 */
function isWeekdayClosed (serviceHours, weekday) {
  if (!isServiceHours(serviceHours)) {
    throw Std.err('TypeError', 'Service hours is invalid')
  }

  if (weekday < 0 || weekday > 6) {
    throw Std.err('TypeError', 'weekday is out of range')
  }

  if (!serviceHours.days || serviceHours.days.length === 0) {
    return true
  }

  const deliveryDay = serviceHours.days.find((d) => d.day === weekday)
  return !deliveryDay || deliveryDay.ranges.length === 0
}

exports.isWeekdayClosed = isWeekdayClosed
