const Data = require('./data')

const buildMap = (pairs) => {
  const map = new Map()

  const set = (k, v) => {
    return map.set(k.toLowerCase(), v)
  }

  for (const pair of pairs) {
    set(pair[0], pair[0])

    if (Array.isArray(pair[1])) {
      pair[1].forEach((val) => set(val, pair[0]))
    } else {
      set(pair[1], pair[0])
    }
  }

  return map
}

// Mapping of street suffix variants to street suffix full name
//
//  lowercase(Street suffix full name) = Street suffix full name
//  lowercase(Street suffix abbr) = Street suffix full name
//
// Street directions are also in this map.
const canadaMap = buildMap(Data.canada)
const usaMap = buildMap(Data.usa)
const directionsMap = buildMap(Data.directions)

const toFull = (suffix) => canadaMap.get(suffix.toLowerCase()) || usaMap.get(suffix.toLowerCase())

// Normalize street direction and type suffix.
//
// Examples (input => output):
//   123 Fake St => 123 Fake Street
//   123 Fake St E => 123 Fake Street East
//   123 Fake St Est => 123 Fake Street East
//   123 Fake St O => 123 Fake Street West
//   123 Fake Ch O => 123 Fake Chemin West
//   123 Fake CH O => 123 Fake Chemin West
//   123 Fake cheMin Ouest => 123 Fake Chemin West
const normalize = (street, { remove = false } = {}) => {
  if (typeof street !== 'string') {
    throw new Error('street must be a string')
  }

  let dir = ''

  street = street.replace(/ ([^ 0-9]+)$/u, (_, t) => {
    dir = directionsMap.get(t.toLowerCase())
    return dir ? '' : ' ' + t
  }).trim()

  street = street.replace(/ ([^ 0-9]+)$/u, (_, t) => {
    return remove ? '' : ' ' + (toFull(t) || t)
  }).trim()

  // Put the directional suffix back if it was extracted.
  if (!remove && dir) {
    street = [street, dir].join(' ')
  }

  return street
}

// Get the normalized suffix of a street name or empty string if suffix is not recognized.
const suffix = (street) => {
  if (typeof street !== 'string') {
    throw new Error('street must be a string')
  }

  street = street.replace(/ ([^ 0-9]+)$/u, (_, t) => {
    return directionsMap.get(t.toLowerCase()) ? '' : ' ' + t
  }).trim()

  let suffix = ''

  street.replace(/ ([^ 0-9]+)$/u, (_, t) => {
    suffix = toFull(t) || ''
    return ''
  })

  return suffix
}

// Get the normalized street direction of a street name or empty string if direction suffix is not recognized.
const direction = (street) => {
  if (typeof street !== 'string') {
    throw new Error('street must be a string')
  }

  let dir = ''

  street.replace(/\b([^ 0-9]+)$/u, (_, t) => {
    dir = directionsMap.get(t.toLowerCase()) || ''
    return ''
  })

  return dir
}

exports.normalize = normalize
exports.suffix = suffix
exports.direction = direction
