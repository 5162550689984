import React from 'react'
import PropTypes from 'prop-types'
import { Tag, Tooltip } from 'antd'

export default function DriverChallengeStatusTag ({
  status = null,
  ...remainingProps

}) {
  if (status === 'suspended') {
    return (
      <Tooltip title="This Challenge is suspended indefinitely.">
        <Tag color='gold' {...remainingProps}>Suspended</Tag>
      </Tooltip>
    )
  }

  if (status === 'open') {
    return (
      <Tooltip title="This Challenge is open.">
        <Tag color='blue' {...remainingProps}>Open</Tag>
      </Tooltip>
    )
  } else if (status === 'pending') {
    return (
      <Tooltip title="This Challenge will be available in the future.">
        <Tag color='cyan' {...remainingProps}>Pending</Tag>
      </Tooltip>
    )
  } else if (status === 'closed') {
    return (
      <Tooltip title="This Challenge is closed and not available anymore.">
        <Tag color='red' {...remainingProps}>Closed</Tag>
      </Tooltip>
    )
  } else {
    return 'N/A'
  }
}

DriverChallengeStatusTag.propTypes = {
  status: PropTypes.string
}
