import React, { useEffect, useState } from 'react'
import { Input } from '../Input/Input'
import { InputErrorWrapper } from '../Input/InputErrorWrapper'
import { InputError } from '../Input/InputError'
import PropTypes from 'prop-types'

export const Field = ({
  activeError = '',
  value = '',
  onChange = () => {},
  disabled = false,
  disableAnimations = true,
  label = '',
  marginBottom = 10,
  type = 'input',
  formItemStyle = {},
  onFocus,
  iconRight,
  iconRightClick,
  onBlur,
  animatingIn,
  animatingOut,
  animatingDelay,
  multi = false
}) => {
  const [internalValue, setInternalValue] = useState(value)

  useEffect(() => {
    setInternalValue(value)
  }, [value])

  return (
    <div style={{ marginBottom, ...formItemStyle }}>
      <InputErrorWrapper active={!!activeError} disableAnimations={disableAnimations} animatingIn={animatingIn} animatingOut={animatingOut} animatingDelay={animatingDelay}>
        <Input
          onFocus={onFocus}
          onBlur={onBlur}
          multi={multi}
          type={type}
          errorActive={!!activeError}
          label={label}
          iconRight={iconRight}
          iconRightClick={iconRightClick}
          value={internalValue}
          disabled={disabled}
          onChange={(evt) => {
            setInternalValue(evt.target.value)
            onChange(evt.target.value)
           }}
        />
        <InputError active={!!activeError}>{activeError}</InputError>
      </InputErrorWrapper>
    </div>
  )
}

Field.propTypes = {
  activeError: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  disableAnimations: PropTypes.bool,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  marginBottom: PropTypes.number,
  formItemStyle: PropTypes.object,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  animatingIn: PropTypes.bool,
  animatingOut: PropTypes.bool,
  animatingDelay: PropTypes.number,
  multi: PropTypes.bool,
  label: PropTypes.string,
  iconRight: PropTypes.string,
  iconRightClick: PropTypes.func
}
