import React from 'react'
import { Pagination, Spin } from 'antd'
import { getFormattedDateAndTimeFromDate } from '@trexity/common/temporal'
import { sorter } from '@trexity/common/sort'

export default function BroadcastDisqualificationLogsTable ({
  deps,
  logs = [],
  pageSize = 5,
  loading = false
}) {
  const { RouteLink } = deps

  const getPaginatedArray = (array, page = 1) => {
    const start = (page - 1) * pageSize
    const end = start + pageSize
    return array.slice(start, end)
  }

  const [pageNumber, setPageNumber] = React.useState(1)

  const LoadingWrapper = loading
    ? ({ children }) => <Spin>{children}</Spin>
    : ({ children }) => children

  logs.sort(sorter('createdAt', { direction: 'desc' }))

  return (
    <LoadingWrapper>
      <div style={{ overflowX: 'auto' }}>
        <table border={1} cellPadding={2} style={{ borderColor: '#aaa', width: '100%' }}>
          <thead>
            <tr>
              <th>Route ID</th>
              <th>Logged At</th>
              <th>Reason</th>
            </tr>
          </thead>
          <tbody>
            {logs.length ? (
              getPaginatedArray(logs, pageNumber).map((log) => {
                const {
                  shipmentId,
                  reason,
                  createdAt,
                  id
                } = log

                return (
                  <tr key={id}>
                    <td><RouteLink routeKey='Shipment Dashboard' params={{ id: shipmentId }}>{shipmentId}</RouteLink></td>
                    <td>{(createdAt && getFormattedDateAndTimeFromDate(createdAt)) || 'n/a'}</td>
                    <td>{reason}</td>
                  </tr>
                )
              })
            ) : (
              <tr><td colSpan={99}><em>No logs available</em></td></tr>
            )}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={10} align='right' style={{ backgroundColor: '#fafafa' }}>
                <Pagination
                  current={pageNumber}
                  onChange={(page) => setPageNumber(page)}
                  total={logs.length}
                  pageSize={pageSize}
                  size='small'
                />
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </LoadingWrapper>
  )
}
