const { toTimeRange } = require('./toTimeRange')
const { create } = require('./create')

/**
 * Returns a new service hours object that is the result of conforming serviceHours to
 * serviceHoursTarget.
 *
 * Calendar days are conformed to the target service hours same calendar day. If
 * there is no calendar day in the target service hours, then conforms calendar day
 * to the first day of the workweek in the target service hours. Otherwise removes
 * the calendar service hours. We do this because the day of the week a calendar
 * falls on changes every year.
 *
 * For example if serviceHours has hours for March 5, but the serviceHoursTarget
 * does not have hours specifically for March 5, then a) conform March 5 to the
 * hours of the fist day in the workweek in the target, or b) remove March 5's
 * hours entirely.
 *
 * @param {ServiceHours} serviceHours The service hours to conform
 * @param {ServiceHours} serviceHoursTarget The target service hours to conform to
 * @return {ServiceHours}
 */
function conformTo (serviceHours, serviceHoursTarget) {
  serviceHoursTarget = create(serviceHoursTarget)
  serviceHours = create(serviceHours)

  const serviceHoursConformed = create({ days: [], calendarDays: [] })

  // Weekday hours
  for (const dayTarget of serviceHoursTarget.days) {
    const day = serviceHours.days.find((d) => d.day === dayTarget.day)
    if (!day) continue

    const ranges = conformTimeRangesTo(day.ranges, dayTarget.ranges)
    serviceHoursConformed.days.push({ day: dayTarget.day, ranges })
  }

  // Calendar day hours
  for (const dayTarget of serviceHoursTarget.calendarDays) {
    const day = serviceHours.calendarDays.find((d) => d.day === dayTarget.day)
    if (!day) continue

    const ranges = conformTimeRangesTo(day.ranges, dayTarget.ranges)
    serviceHoursConformed.calendarDays.push({ day: dayTarget.day, ranges })
  }

  // We now conform any calendar days in the serviceHours that DO NOT
  // have an associated calendar day in the serviceHoursTarget to the serviceHoursTarget's
  // service hours for the first day of the workweek.
  const firstDayOfWorkweekTarget = serviceHoursTarget.days.find((d) => d.day > 0 && d.day < 6)

  if (firstDayOfWorkweekTarget) {
    for (const day of serviceHours.calendarDays) {
      const dayTarget = serviceHoursTarget.calendarDays.find((d) => d.day === day.day)
      if (dayTarget) continue

      const ranges = conformTimeRangesTo(day.ranges, firstDayOfWorkweekTarget.ranges)
      serviceHoursConformed.calendarDays.push({ day: day.day, ranges })
    }
  }

  return create(serviceHoursConformed)
}

/**
 * @param {TimeRange[]} timeRanges
 * @param {TimeRange[]} timeRangesTarget
 * @return {TimeRange[]}
 */
function conformTimeRangesTo (timeRanges, timeRangesTarget) {
  const timeRangesConformed = []
  timeRangesTarget = timeRangesTarget.map(toTimeRange)
  timeRanges = timeRanges.map(toTimeRange)

  const rangeContainsRangeFrom = (a, b) => a.fromTime <= b.fromTime && a.toTime >= b.fromTime
  const rangeContainsRangeTo = (a, b) => a.fromTime <= b.toTime && a.toTime >= b.toTime

  for (const rangeB of timeRanges) {
    // If range B is completely within any of the target ranges then we use range B as-is
    if (timeRangesTarget.some((a) => rangeContainsRangeFrom(a, rangeB) && rangeContainsRangeTo(a, rangeB))) {
      timeRangesConformed.push({ fromTime: rangeB.fromTime.toString(), toTime: rangeB.toTime.toString() })
    } else {
      const from = timeRangesTarget.find((a) => rangeContainsRangeFrom(a, rangeB))
      const to = timeRangesTarget.find((a) => rangeContainsRangeTo(a, rangeB))

      if (from && to) {
        const k = timeRangesTarget.indexOf(from)
        const j = timeRangesTarget.indexOf(to)

        timeRangesConformed.push({ fromTime: rangeB.fromTime.toString(), toTime: from.toTime.toString() })

        for (let q = k + 1; q < j; q += 1) {
          timeRangesConformed.push({ fromTime: timeRangesTarget[q].fromTime.toString(), toTime: timeRangesTarget[q].toTime.toString() })
        }

        timeRangesConformed.push({ fromTime: to.fromTime.toString(), toTime: rangeB.toTime.toString() })
      } else if (from) {
        const j = timeRangesTarget.indexOf(from)

        timeRangesConformed.push({ fromTime: rangeB.fromTime.toString(), toTime: from.toTime.toString() })

        for (let q = j + 1; q < timeRangesTarget.length; q += 1) {
          timeRangesConformed.push({ fromTime: timeRangesTarget[q].fromTime.toString(), toTime: timeRangesTarget[q].toTime.toString() })
        }
      } else if (to) {
        const j = timeRangesTarget.indexOf(to)

        for (let q = 0; q < j; q += 1) {
          timeRangesConformed.push({ fromTime: timeRangesTarget[q].fromTime.toString(), toTime: timeRangesTarget[q].toTime.toString() })
        }

        timeRangesConformed.push({ fromTime: to.fromTime.toString(), toTime: rangeB.toTime.toString() })
      }
    }
  }

  return timeRangesConformed
}

exports.conformTo = conformTo
