const PASSWORD_STRENGTHS = {
  WEAK: 'weak',
  MEDIUM: 'medium',
  STRONG: 'strong'
}

/**
 * Eg. use - passwordValidator({ password: 'mypassword', strengthEnforced: PASSWORD_STRENGTHS.MEDIUM })
 * @param {string} password
 * @param {(PASSWORD_STRENGTHS.WEAK|PASSWORD_STRENGTHS.MEDIUM|PASSWORD_STRENGTHS.STRONG)} strengthEnforced
 * @returns {{isValid: boolean, error: Error}}
 */
function passwordStrength ({ password, strengthEnforced }) {
  let validity = {
    isValid: false,
    error: new Error('Invalid password provided')
  }

  if (password && typeof password === 'string' && password.trim()) {
    switch (strengthEnforced || PASSWORD_STRENGTHS.STRONG) {
      case PASSWORD_STRENGTHS.WEAK:
        validity = validateWeakPassword(password)
        break
      case PASSWORD_STRENGTHS.MEDIUM:
        validity = validateMediumPassword(password)
        break
      case PASSWORD_STRENGTHS.STRONG:
        validity = validateStrongPassword(password)
        break
    }
  }

  return validity
}

function validateWeakPassword (password) {
  if (password.length >= 8) {
    return {
      isValid: true
    }
  }

  return {
    isValid: false,
    error: Object.assign(new Error('Validation error'), { metadata: { messages: ['8 characters/numbers or more'] } })
  }
}

function validateMediumPassword (password) {
  const validity = validateWeakPassword(password)

  if (validity.isValid && /[0-9]+/u.test(password) && /[a-zA-Z\W]+/u.test(password)) {
    return {
      isValid: true
    }
  }

  return {
    isValid: false,
    error: Object.assign(new Error('Validation error'), {
      metadata: {
        messages: ((validity.error && validity.error.metadata && validity.error.metadata.messages) || []).concat(['at least one character and one number'])
      }
    })
  }
}

function validateStrongPassword (password) {
  const validity = validateMediumPassword(password)

  if (validity.isValid && /[a-z]+/u.test(password) && /[A-Z]+/u.test(password) && /[\W]+/u.test(password)) {
    return {
      isValid: true
    }
  }

  return {
    isValid: false,
    error: Object.assign(new Error('Validation error'), {
      metadata: {
        messages: ((validity.error && validity.error.metadata && validity.error.metadata.messages) || []).concat(['at least one special character (eg. % or !)'])
      }
    })
  }
}

module.exports = {
  passwordStrength,
  getPasswordStrengths: () => ({ ...PASSWORD_STRENGTHS })
}
