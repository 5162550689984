const createModel = require('./createModel')
const { PropTypes } = require('../util')
const { MODES } = require('./constants')
const { SHIPMENT_STATUS } = require('../shipments/constants')

const { SERVER_READ, SERVER_WRITE, CLIENT_READ } = MODES

const schema = {
  id: {
    type: PropTypes.string,
    pgType: 'varchar(1024)',

    mode: SERVER_READ | CLIENT_READ
  },

  createdAt: {
    type: PropTypes.firestoreTimestamp,
    pgType: 'timestamptz',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ
  },

  // The shipment id to which this status relates
  shipmentId: {
    type: PropTypes.string,
    pgType: 'varchar(1024)',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ
  },

  // Driver assigned to the shipment at this status
  driverId: {
    type: PropTypes.string,
    pgType: 'varchar(1024)',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ
  },

  // Driver object with relevant information (name, car information, photo, etc)
  cachedDriver: {
    type: PropTypes.shape({
      id: PropTypes.id,
      fullName: PropTypes.string,
      licencePlateNumber: PropTypes.string,
      vehicleColor: PropTypes.string,
      vehicleMake: PropTypes.string,
      vehicleModel: PropTypes.string,
      vehicleYear: PropTypes.string,
      rating: PropTypes.number,
      driverPhotoUrl: PropTypes.string,
      contactPhone: PropTypes.string
    }),
    pgType: 'jsonb',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ
  },

  // Keep a copy of the image URL pertaining to this status, if any
  cachedImageUrl: {
    type: PropTypes.string,
    pgType: 'text',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ,
    default: null
  },

  // DEPRECATED: Shipment's "deliveryIndex" property, which indicates which
  // delivery was in process when this status was created (i.e. multiple
  // delivery addresses for a single "shipment").
  // Superceded by orderUuid
  deliveryIndex: {
    type: PropTypes.number,
    pgType: 'integer',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ,
    default: null
  },

  // Preferred field over deliveryIndex
  orderUuid: {
    type: PropTypes.string,
    pgType: 'varchar(128)',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ,
    default: null
  },

  // Current status
  status: {
    type: PropTypes.oneOf(['', ...Object.keys(SHIPMENT_STATUS)]),
    pgType: 'varchar(1024)',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ,
    default: '' // blank is NOT_YET_SUBMITTED
  },

  // Notes left by the driver once they finish delivering the shipment, or
  // when they renounce the shipment. In the future, we may support adding notes
  // for other statuses
  notes: {
    type: PropTypes.string,
    pgType: 'text',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ,
    default: ''
  }
}

module.exports = createModel('ShipmentStatus', schema, MODES, {
  ServerReadDto: require('./dto/internal/ShipmentStatusServerReadDto'),
  ServerWriteDto: require('./dto/internal/ShipmentStatusServerWriteDto'),
  ClientReadDto: require('./dto/internal/ShipmentStatusClientReadDto'),
  ClientWriteDto: require('./dto/internal/ShipmentStatusClientWriteDto')
})
