const { Std } = require('../../core')
const { isServiceHours } = require('./isServiceHours')
const { isTimeRange } = require('./isTimeRange')
const { create } = require('./create')

/**
 * Returns a new service hours object with the time range(s) for the specified day of the week set.
 *
 * @param {ServiceHours} serviceHours
 * @param {number} weekday The index of the day of the week (0 = Sun, 6 = Sat)
 * @param {TimeRange|TimeRange[]} ranges The time range or time ranges to set
 * @return {ServiceHours}
 */
function setWeekdayHours (serviceHours, weekday, ranges) {
  if (!isServiceHours(serviceHours)) {
    throw Std.err('TypeError', 'Service hours is invalid')
  }

  if (weekday < 0 || weekday > 6) {
    throw Std.err('TypeError', 'weekday is out of range')
  }

  if ((!Array.isArray(ranges) && !isTimeRange(ranges)) || !ranges.every(isTimeRange)) {
    throw Std.err('TypeError', 'ranges are is invalid')
  }

  serviceHours = create(serviceHours)

  for (const day of serviceHours.days) {
    if (day.day === weekday) {
      day.ranges = Array.isArray(ranges) ? ranges : [ranges]
      return create(serviceHours)
    }
  }

  serviceHours.days.push({ day: weekday, ranges })
  return create(serviceHours)
}

exports.setWeekdayHours = setWeekdayHours
