/**
 * @param {Date} date
 * @returns {string}
 */
module.exports = function getFormattedDateFromDate (date, {
  useRegularSpaces = false,
  includeWeekDay = true,
  includeDay = true
} = {}) {
  const shortNameMonths = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ]

  const space = useRegularSpaces ? ' ' : '\u00A0'

  const shortNameWeekdays = [
    'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'
  ]

  const weekday = shortNameWeekdays[date.getDay()]
  const day = date.getDate()
  const month = shortNameMonths[date.getMonth()]

  const year = date.getFullYear()

  let dayString = day.toString()

  if (day < 10) {
    dayString = `0${day}`
  }

  let ret = ''

  if (includeWeekDay) {
    ret += `${weekday}${space}`
  }

  ret += `${month}${includeDay ? `${space}${dayString}` : ''},${space}${year}`

  return ret
}
