const { assertPropTypes } = require('../vendor/check-prop-types')

function getValue (name, key, entry, dataValue) {
  if (dataValue !== undefined) {
    if ('type' in entry) {
      try {
        assertPropTypes({ [key]: entry.type }, { [key]: dataValue }, key, name)
      } catch (error) {
        if (entry.error) {
          throw Object.assign(new Error(entry.error.message), entry.error)
        } else {
          throw error
        }
      }
    }

    return dataValue
  } else {
    const defaultValue = 'default' in entry
      ? entry.default
      : null

    if ('type' in entry) {
      try {
        assertPropTypes({ [key]: entry.type }, { [key]: defaultValue }, key, name)
      } catch (error) {
        if (entry.error) {
          throw Object.assign(new Error(entry.error.message), entry.error)
        } else {
          throw error
        }
      }
    }

    return defaultValue
  }
}

/**
 * @param {string} name
 * @param {{ [key:string]: { type: Function, default?: any, mode?: number, error?: string | {message:string, name?:string, code?:string} } }} schema
 * @param {*} [modes]
 * @param {*} [dtos]
 * @param {Array<(data) => any>} [converters] Converters are functions that convert the data before running through the schema.
 */
module.exports = function createModel (name, schema, modes, dtos = {}, converters = []) {
  if (!Array.isArray(converters) || converters.some((f) => typeof f !== 'function')) {
    throw new Error('converters must be an array of functions')
  }

  // Make a shallow copy of the schema to mitigate state related issues
  // if the schema object were to be changed.
  schema = { ...schema }

  // Normalize schema.error to always be an object
  if (schema.error) {
    schema.error = typeof schema.error === 'string'
      // @ts-ignore
      ? { message: schema.error }
      : { ...schema.error }
  }

  const Model = {
    ...modes,
    name,
    schema,
    getValue,

    /**
     * complete: provide any number of valid properties for a create operation,
     * and the returned object will have all properties in the schema
     */
    complete (data) {
      data = converters.reduce((data, fn) => fn(data), { ...data })
      return Object.keys(schema)
        .reduce((memo, key) => ({
          ...memo,
          [key]: getValue(name, key, schema[key], data[key])
        }), {})
    },

    /**
     * partial: provide any number of valid properties for a partial operation,
     * and the returned object will be typecast correctly, and only those
     * properties that are valid according to mode will remain
     */
    partial (data, modeFilter = 0) {
      data = converters.reduce((data, fn) => fn(data), { ...data })
      return Object.keys(data)
        .filter((key) => key in schema)
        .filter((key) => !('mode' in schema[key]) || (schema[key].mode & modeFilter))
        .reduce((memo, key) => ({
          ...memo,
          [key]: getValue(name, key, schema[key], data[key])
        }), {})
    }
  }

  return Object.assign(
    Model,
    Object.keys(dtos).reduce((memo, key) => ({
      ...memo,
      [key]: dtos[key](Model)
    }), {})
  )
}
