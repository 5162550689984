import React from 'react'
import PropTypes from 'prop-types'

import {
  Button,
  Upload,
  Result,
  Spin,
  Icon,
  Alert
} from 'antd'

import { InputErrorTable } from './InputErrorTable'
import { InputSuccessTable } from './InputSuccessTable'
import { SummaryTable } from './SummaryTable'

DownloadTemplate.propTypes = {
  templateUrl: PropTypes.string
}

function DownloadTemplate ({ templateUrl }) {
  if (typeof templateUrl !== 'string' || !templateUrl.trim()) {
    return null
  }

  const ext = templateUrl.split('.').pop().toUpperCase() || 'Import'

  return (
    <Alert
      message='Download Template'
      description={<span>Download the <a href={templateUrl} download>{ext} template</a> to get started.</span>}
      type='info'
      showIcon
    />
  )
}

UploadAreaWidget.propTypes = {
  onUpload: PropTypes.func.isRequired,
  disabled: PropTypes.bool
}

function UploadAreaWidget ({ disabled, onUpload }) {
  return (
    <Upload.Dragger
      accept='.csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      disabled={disabled}
      showUploadList={false}
      style={{ marginBottom: 11 }}
      beforeUpload={onUpload}
    >
      <p className='ant-upload-drag-icon'>
        <Icon type='inbox' style={{ color: disabled ? '#cccccc' : '' }} />
      </p>
      <p className='ant-upload-text' style={{ color: disabled ? '#cccccc' : '' }}>Click or drag CSV/Excel(.xlsx) file to this area to upload</p>
    </Upload.Dragger>
  )
}

export default function Import ({
  merchantOptional,
  deps,
  templateUrl
}) {
  const {
    current,
    send,
    data,
    // RouteLink,
    WhenState,
    merchantId
  } = deps

  const {
    importedInternalOrdersErrors,
    importedInternalOrders,
    bulkCreateResult,
    bulkCreateErrors,
    bulkCreateValidationError
  } = data

  const isUploading = current.within('Uploading CSV')
  const [importedInternalOrdersCheckedArray, setImportedInternalOrdersCheckedArray] = React.useState([])
  const defaultMerchantId = merchantId || ''
  const isDefaultMerchantSelected = Boolean(defaultMerchantId)
  const isUploadCsvButtonDisabled = isUploading || (!!merchantOptional && !isDefaultMerchantSelected)
  const hasCreated = bulkCreateResult && bulkCreateResult.length
  const hasErrored = (bulkCreateErrors && bulkCreateErrors.length) || bulkCreateValidationError
  const hasInputErrors = importedInternalOrdersErrors && importedInternalOrdersErrors.length

  const handleCsvUpload = (file) => {
    send({ type: 'upload csv', file, defaultMerchantId })
    return false
  }

  const bulkResultsWithErrors = [
    ...(bulkCreateResult || []),
    ...((bulkCreateErrors || []).map(({ error, order }) => ({
      ...order,
      __error: error
    })))
  ]

  return (
    <div style={{ dislay: 'flex', flex: 1 }}>
      <WhenState within={'Import Modal Idle'}>
        <UploadAreaWidget disabled={isUploadCsvButtonDisabled} onUpload={handleCsvUpload} />
        <DownloadTemplate templateUrl={templateUrl} />
      </WhenState>
      <WhenState within={'Uploading CSV'}>
        <div style={{ textAlign: 'center' }}>
          <h2>Loading…</h2>
          <Spin size='large' />
        </div>
      </WhenState>
      <WhenState within={'Confirm Deliveries For Creation'}>
        {
        hasInputErrors
          ? (
            <React.Fragment>
              <Result
                status='warning'
                title='Issues Detected'
                subTitle='Issues were detected in the uploaded file. Please correct the following issues and upload again.'
              />
              <InputErrorTable orders={importedInternalOrdersErrors} />
              <h3 style={{ textAlign: 'center' }}>Re-upload File</h3>
              <UploadAreaWidget disabled={isUploadCsvButtonDisabled} onUpload={handleCsvUpload} />
            </React.Fragment>
          )
          : (
            <React.Fragment>
              <Result
                status='info'
                title='Verify Selections'
                subTitle='Review the delivery addresses for accuracy, then select which deliveries to create or update.'
              />
              {importedInternalOrders ? (
                <InputSuccessTable
                  orders={importedInternalOrders}
                  onSelection={(orders) => setImportedInternalOrdersCheckedArray(orders)}
                />
              ) : null}

              <Button
                disabled={!importedInternalOrdersCheckedArray.length}
                type='primary'
                icon='import'
                size='large'
                block
                onClick={() => {
                  send({
                    type: 'create selected deliveries',
                    orders: importedInternalOrdersCheckedArray
                  })
                }}
                style={{ marginBottom: 15 }}
              >
                Import deliveries
              </Button>
              <Alert message='Non-scheduled deliveries will be created as draft Routes. Deliveries will never notify drivers until they are scheduled to, or posted manually' type="info" showIcon />
            </React.Fragment>
          )
        }
      </WhenState>
      <WhenState within={'Creating Deliveries'}>
        <div style={{ textAlign: 'center' }}>
          <h2>Importing deliveries…</h2>
          <Spin size='large' />
        </div>
      </WhenState>
      <WhenState within={'Delivery Creation Results'}>
        {hasCreated && hasErrored ? (
          <Result
            status='warning'
            title='Import completed (with some errors)'
            subTitle='We have encountered errors while attempting to import deliveries. Please pay attention to the rows in red.'
          />
        ) : hasErrored ? (
          <Result
            status='error'
            title='Import not completed'
            subTitle='We have encountered errors while attempting to import deliveries.'
          />
        ) : (
          <Result
            status='success'
            title='Import completed successfully'
            subTitle='Deliveries have been successfully imported.'
          />
        )}

        {
          bulkCreateValidationError
            ? (
              <Alert
                message='Validation Error'
                description={bulkCreateValidationError}
                type='error'
                showIcon
              />
            )
            : (<SummaryTable orders={bulkResultsWithErrors} />)
        }
      </WhenState>
    </div>
  )
}

Import.propTypes = {
  merchantOptional: PropTypes.bool,
  // url to the template CSV or Excel file
  templateUrl: PropTypes.string,
  deps: PropTypes.exact({
    current: PropTypes.object,
    send: PropTypes.func,
    data: PropTypes.object,
    MerchantSearch: PropTypes.func,
    DriverSearch: PropTypes.func,
    RouteLink: PropTypes.func,
    WhenState: PropTypes.func,
    merchantId: PropTypes.string
  }).isRequired
}
