/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import PropTypes from 'prop-types'
import { useEffect } from 'react'

export const FontSizeWrapper = ({ children }) => {
  useEffect(() => {
    document.querySelector('html').classList.add('new-font-size')

    return () => {
      document.querySelector('html').classList.remove('new-font-size')
    }
  }, [])

  return children
}

FontSizeWrapper.propTypes = {
  children: PropTypes.node
}
