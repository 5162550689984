/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import PropTypes from 'prop-types'
import { useRef } from 'react'

export const Radio = ({ id, name, label, onChange = () => {}, value }) => {
  const radioRef = useRef()
  return (
    <div css={{ display: 'flex' }}>
      <input
        value={value}
        checked={value}
        ref={radioRef}
        type='radio'
        name={name}
        id={id}
        css={{ marginRight: 10 }}
        onChange={(e) => {
          onChange(id, radioRef.current.checked)
      }}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  )
}

Radio.propTypes = {
  value: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func
}
