import React from 'react'
import PropTypes from 'prop-types'
import { Icon, Popover } from 'antd'
import { SHIPMENT_STATUS, NO_SHIPMENT_STATUS, LINKED_SHIPMENT_TYPES } from '@trexity/common/shipments/constants'
import { getFormattedDateAndTimeFromDate, getFormattedTimeFromDate } from '@trexity/common/temporal'
import { getFormattedMonetaryValueString } from '@trexity/common/pricing'
import TagWithTooltip from './TagWithTooltip'

const {
  RETURN_TO_SENDER,
  RETURN_REUSABLES
} = LINKED_SHIPMENT_TYPES

export default function ShipmentStatus ({
  status = '',
  linkedParentType = null,
  numberOfUndeliverableItems = 0,
  numberOfMisdeliveredItems = 0,
  numberOfDeliveredItems = 0,
  numberOfProblemItems = 0,
  totalItems = 0,
  scheduledPostAt = null,
  scheduledPostEnabled = null
}) {
  const message = SHIPMENT_STATUS[status]
  const style = { marginRight: 0 }
  const hasDeliveredItems = totalItems !== numberOfUndeliverableItems

  switch (String(status)) {
    case '':
      return scheduledPostEnabled && scheduledPostAt
        ? <Scheduled scheduledPostAtDate={scheduledPostAt.toDate()} style={style} />
        : <TagWithTooltip tagColor='purple' tagStyle={style} tipTitle='Route has been created, but not yet submitted.'>{NO_SHIPMENT_STATUS}</TagWithTooltip>
    case 'WAITING_TO_START':
      return <TagWithTooltip tagColor='gold' tagStyle={style} tipTitle='Route has not yet started, but will soon.'>{message}</TagWithTooltip>
    case 'WAITING_FOR_ACCEPTANCE':
      return <TagWithTooltip tagColor='gold' tagStyle={style} tipTitle='Waiting for a driver to accept the route.'>{message}</TagWithTooltip>
    case 'EN_ROUTE_TO_PICKUP':
      return <TagWithTooltip tagColor='cyan' tagStyle={style} tipTitle='Driver is currently headed to the pickup location.'>{message}</TagWithTooltip>
    case 'ARRIVED_AT_PICKUP':
      return <TagWithTooltip tagColor='cyan' tagStyle={style} tipTitle='Driver has arrived at the pickup location.'>{message}</TagWithTooltip>
    case 'OUT_FOR_DELIVERY':
      return [RETURN_TO_SENDER].includes(linkedParentType) ? (
        <TagWithTooltip tagColor='blue' tagStyle={style} tipTitle='Driver is currently returning the package'>Out For Return</TagWithTooltip>
      ) : [RETURN_REUSABLES].includes(linkedParentType) ? (
        <TagWithTooltip tagColor='blue' tagStyle={style} tipTitle='Driver is currently returning reusables'>Returning Reusables</TagWithTooltip>
      ) : (
        <TagWithTooltip tagColor='blue' tagStyle={style} tipTitle='Driver is currently delivering'>
          {totalItems > 1 ? `${message} ${Math.min(numberOfDeliveredItems + numberOfProblemItems + 1, totalItems)} of ${totalItems}` : message}
        </TagWithTooltip>
      )
    case 'ARRIVED_AT_DELIVERY':
      return [RETURN_TO_SENDER, RETURN_REUSABLES].includes(linkedParentType) ? (
        <TagWithTooltip tagColor='blue' tagStyle={style} tipTitle='Driver has arrived at the return location'>Arrived At Return</TagWithTooltip>
      ) : (
        <TagWithTooltip tagColor='blue' tagStyle={style} tipTitle='Driver has arrived at the delivery location'>
          {totalItems > 1 ? `${message} ${Math.min(numberOfDeliveredItems + numberOfProblemItems + 1, totalItems)} of ${totalItems}` : message}
        </TagWithTooltip>
      )
    case 'DELIVERED':
      return [RETURN_TO_SENDER].includes(linkedParentType) ? (
        <TagWithTooltip tagColor='green' tagStyle={style} tipTitle='Package(s) have been returned'>Returned</TagWithTooltip>
      ) : [RETURN_REUSABLES].includes(linkedParentType) ? (
        <TagWithTooltip tagColor='green' tagStyle={style} tipTitle='Reusables have been returned'>Returned</TagWithTooltip>
      ) : (
        numberOfUndeliverableItems || numberOfMisdeliveredItems
          ? <WithUndeliverableItems hasDeliveredItems={hasDeliveredItems} numberOfMisdeliveredItems={numberOfMisdeliveredItems} numberOfUndeliverableItems={numberOfUndeliverableItems} totalItems={totalItems} style={style} />
          : <TagWithTooltip tagColor='green' tagStyle={style} tipTitle='Package(s) have been delivered'>{message}</TagWithTooltip>
      )
    case 'ON_HOLD':
      return scheduledPostEnabled && scheduledPostAt
        ? <Scheduled scheduledPostAtDate={scheduledPostAt.toDate()} style={style} />
        : <TagWithTooltip tagColor='geekblue' tagStyle={style} tipTitle='Route has been paused'>{message}</TagWithTooltip>
    case 'EXPIRED':
      // backwards-compat
      return scheduledPostEnabled && scheduledPostAt
        ? <Scheduled scheduledPostAtDate={scheduledPostAt.toDate()} style={style} />
        : <TagWithTooltip tagColor='red' tagStyle={style} tipTitle='Route has expired. No drivers available.'>{message}</TagWithTooltip>
    case 'CANCELLED':
      return <TagWithTooltip tagColor='red' tagStyle={style} tipTitle='Route has been cancelled'>{message}</TagWithTooltip>
    default:
      return <TagWithTooltip tagColor='red' tagStyle={style} tipTitle='Something has gone wrong. Contact support.'>{message || NO_SHIPMENT_STATUS}</TagWithTooltip>
  }
}

ShipmentStatus.propTypes = {
  status: PropTypes.string,
  linkedParentType: PropTypes.oneOf(Object.values(LINKED_SHIPMENT_TYPES)),
  numberOfUndeliverableItems: PropTypes.number,
  numberOfMisdeliveredItems: PropTypes.number,
  numberOfDeliveredItems: PropTypes.number,
  numberOfProblemItems: PropTypes.number,
  totalItems: PropTypes.number,
  scheduledPostAt: PropTypes.any,
  scheduledPostEnabled: PropTypes.bool
}

function Scheduled ({ scheduledPostAtDate, style = {} }) {
  const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

  return (
    <TagWithTooltip tagColor='magenta' tagStyle={style} tipTitle={`Route has been scheduled. Will post to the driver network on ${getFormattedDateAndTimeFromDate(scheduledPostAtDate)}.`}>
      <Icon type='calendar' style={{ marginRight: 5 }} /> {weekdays[scheduledPostAtDate.getDay()]} {getFormattedTimeFromDate(scheduledPostAtDate)}
    </TagWithTooltip>
  )
}

Scheduled.propTypes = {
  scheduledPostAtDate: PropTypes.instanceOf(Date).isRequired,
  style: PropTypes.object
}

function WithUndeliverableItems ({ hasDeliveredItems, numberOfMisdeliveredItems, numberOfUndeliverableItems, totalItems, style = {} }) {
  return (
    <TagWithTooltip
      tagColor='red'
      tagStyle={style}
      tipTitle={[
        `Driver was unable to complete one or more of the deliveries and will return it.`,
        numberOfUndeliverableItems && `Undeliverables: ${numberOfUndeliverableItems}`,
        numberOfMisdeliveredItems && `Misdeliveries: ${numberOfMisdeliveredItems}`
      ].filter(Boolean).join(' ')}
    >
      {[
        numberOfUndeliverableItems > 0 && 'Undeliverable',
        numberOfMisdeliveredItems > 0 && 'Misdelivered'
      ].filter(Boolean).join(' / ')}
    </TagWithTooltip>
  )
}

WithUndeliverableItems.propTypes = {
  hasDeliveredItems: PropTypes.bool.isRequired,
  numberOfMisdeliveredItems: PropTypes.number.isRequired,
  numberOfUndeliverableItems: PropTypes.number.isRequired,
  totalItems: PropTypes.number.isRequired,
  style: PropTypes.object
}

export function Extended ({
  status = '',
  routeIncentiveData = null,
  isAdmin = false,
  linkedParentType = null,
  numberOfUndeliverableItems = 0,
  numberOfMisdeliveredItems = 0,
  numberOfDeliveredItems = 0,
  numberOfProblemItems = 0,
  totalItems = 0,
  scheduledPostAt = null,
  scheduledPostEnabled = null,
  errors = [],
  isShipmentLocked = false
}) {
  return (
    <div style={{ flexDirection: 'row', whiteSpace: 'nowrap' }}>
      {errors.length ? (
        <Popover
          content={(
            <div style={{ maxWidth: 500 }}>
              <ul style={{ paddingLeft: 16, marginBottom: 0 }}>
                {errors.map((error, index) => (
                  <li key={index}>
                    {getFormattedDateAndTimeFromDate(new Date(error.date))}: {error.message}
                  </li>
                ))}
              </ul>
            </div>
          )}
          title={`Issue${errors.length === 1 ? '' : 's'}`}
        >
          <Icon type='exclamation-circle' theme='twoTone' twoToneColor='red' style={{ marginRight: 5 }} />
        </Popover>
      ) : null}
      {routeIncentiveData && status === 'WAITING_FOR_ACCEPTANCE' ? (
        <Popover
          content={(
            <div style={{ maxWidth: 500 }}>
              {isAdmin ? `${getFormattedMonetaryValueString(routeIncentiveData.incentive)} surge bonus applied (${routeIncentiveData.incentivesOffered}/${routeIncentiveData.totalIncentives} incentives offered)` : 'We are aware of the delay to pickup and have our people on it'}
            </div>
          )}
          title={'Delay to pickup acknowledged'}
        >
          <Icon type='eye' theme='filled' style={{ marginRight: 5 }} />
        </Popover>
      ) : null}
      <ShipmentStatus
        status={status}
        scheduledPostAt={scheduledPostAt}
        scheduledPostEnabled={scheduledPostEnabled}
        linkedParentType={linkedParentType}
        numberOfUndeliverableItems={numberOfUndeliverableItems}
        numberOfMisdeliveredItems={numberOfMisdeliveredItems}
        numberOfDeliveredItems={numberOfDeliveredItems}
        numberOfProblemItems={numberOfProblemItems}
        totalItems={totalItems}
      />
      {isShipmentLocked ? (
        <Icon type='lock' style={{ marginLeft: 4, fontSize: 12 }} />
      ) : null}
    </div>
  )
}

Extended.propTypes = {
  status: PropTypes.string,
  isAdmin: PropTypes.bool,
  routeIncentiveData: PropTypes.shape({ incentive: PropTypes.number, incentivesOffered: PropTypes.number, totalIncentives: PropTypes.number }),
  linkedParentType: PropTypes.oneOf(Object.values(LINKED_SHIPMENT_TYPES)),
  numberOfUndeliverableItems: PropTypes.number,
  numberOfMisdeliveredItems: PropTypes.number,
  numberOfDeliveredItems: PropTypes.number,
  numberOfProblemItems: PropTypes.number,
  totalItems: PropTypes.number,
  scheduledPostAt: PropTypes.any,
  scheduledPostEnabled: PropTypes.bool,
  isShipmentLocked: PropTypes.bool,
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.oneOfType([
        PropTypes.instanceOf(Date),
        PropTypes.string,
        PropTypes.number
      ]),
      message: PropTypes.string
    })
  )
}

ShipmentStatus.Extended = Extended
