import * as React from 'react'
import * as PropTypes from 'prop-types'
import { Alert } from 'antd'

/**
 * Validator component is a wrapper component intended to wrap a controlled "input"
 * component of any kind so long as the input component accepts an "onChange"
 * and "value" props.
 *
 * When the input's value is invalid the "renderAlert" render prop is used to
 * render an inline warning directly underneath the the input element. By default
 * uses antd.Alert.
 *
 * The input component's "value" and "onChange" props will be replaced appropriately
 * based on the "onChange" and "value" props passed to this component.
 *
 * If "value" is "undefined" or "null" this is always considered valid.
 *
 * @example
 * const emailValidator = value => ({
 *   valid: /@/.test(value),
 *   message: 'Email must contain an "@" symbol'
 * })
 * <Validator value={email} onChange={emailChange} validator={emailValidator}>
 *   <Input name='email' value={email} onChange={emailChange} />
 * </Validator>
 * @example
 * const renderAlert = message => <Error message />
 * <Validator value={email} onChange={emailChange} validator={emailValidator} renderAlert={renderAlert}>
 *   <Input name='email' />
 * </Validator>
 */

Validator.propTypes = {
  /**
   * Callback called to validate the input's value.
   *
   * @type { (value) => { valid: boolean, message?: string } }
   */
  validator: PropTypes.func.isRequired,
  /**
   * Only the input element can be nested as a child.
   */
  children: PropTypes.element.isRequired,
  /**
   * Render prop called to render the inline error message.
   *
   * @type { (message: string) => React.ReactNode }
   */
  renderAlert: PropTypes.func,
  value: PropTypes.any,
  /**
   * Called when the input's value changes.
   *
   * @type { (event) => void }
   */
  onChange: PropTypes.func,
  /**
   * Callback called when the input's value changes to an invalid value.
   *
   * @type { () => void }
   */
  onInvalid: PropTypes.func
}

Validator.defaultProps = {
  // NOTE (dschnare): Delete this default prop and this component should be able
  // to be used in React Native as-is.
  renderAlert: (message) => (<Alert message type='error' />)
}

function Validator (props) {
  const {
    validator,
    children,
    renderAlert,
    onChange,
    onInvalid,
    value
  } = props

  const [validationResult, setValidationResult] =
    React.useState(validate(validator, value))

  const inputEl = React.cloneElement(children, {
    value,
    onChange: (event) => {
      if (typeof onChange === 'function') {
        onChange(event)
      }

      let value = event

      if (event && event.nativeEvent && event.target) {
        value = event.target.value
      }

      const vr = validate(validator, value)

      // If we're changing to an invalid state then we call the onInvalid()
      // callback. Otherwise we don't call it.
      if (!vr.valid && validationResult.valid && typeof onInvalid === 'function') {
        onInvalid()
      }

      setValidationResult(vr)
    }
  })

  const alertEl = validationResult.valid
    ? null
    : renderAlert(validationResult.message)

  return (
    <React.Fragment>
      {inputEl}
      {alertEl}
    </React.Fragment>
  )
}

const validate = (validator, value) => {
  return (value === undefined || value === null)
    ? { valid: true, message: '' }
    : validator(value)
}

export { Validator as default }
