const { convertOrdersToArray } = require('../models/ShipmentOrder')

function getNextOrderToDeliver ({
  sortedOrders
}) {
  const ordersArray = convertOrdersToArray(sortedOrders)
  return ordersArray.find((o) => !o.deliveredAt)
}

exports.getNextOrderToDeliver = getNextOrderToDeliver
