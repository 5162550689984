import { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { gsap } from 'gsap'
import { COLORS } from '@trexity/common/color/constants'
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'

export const TextButton = ({
  type = 'button',
  children,
  style,
  light = false,
  animatingIn = true,
  animatingOut,
  animatingDelay = 0,
  marginLeft = 0,
  ...rest
}) => {
  const button = useRef()

  useEffect(() => {
    const tl = gsap.timeline()

    if (animatingIn) {
      tl.to(button.current, { x: 0, opacity: 1, delay: animatingDelay })
    } else if (animatingOut) {
      tl.to(button.current, { x: 20, opacity: 0 })
    }
  }, [animatingIn, animatingOut, animatingDelay])

  return (
    <button
      ref={button}
      type={type}
      css={TextButtonStyles(style, light, marginLeft)}
      {...rest}
    >{children}</button>
  )
}

export const TextButtonStyles = (style, light, marginLeft) => ({
  backgroundColor: 'transparent',
  border: 'none',
  color: light ? COLORS.WHITE : COLORS.TREXITY_BLUE,
  textDecoration: 'underline',
  marginLeft,
  cursor: 'pointer',
  padding: 0,
  opacity: 0,
  transform: 'translateX(20px)',
  outline: 0,
  ...style
})

TextButton.propTypes = {
  children: PropTypes.node,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  style: PropTypes.object,
  marginLeft: PropTypes.number,
  animatingIn: PropTypes.bool,
  animatingOut: PropTypes.bool,
  animatingDelay: PropTypes.number,
  light: PropTypes.bool
}

export default TextButton
