/*
This component shows a map of our delivery zones and optionally adds markers
to the map.
*/

import * as React from 'react'
import * as PropTypes from 'prop-types'
import { COLORS } from '@trexity/common/color/constants'
import { GoogleMap, Marker, Polygon } from '@react-google-maps/api'
import ZoneMapWrapper from './ZoneMapWrapper'

export function MapMarker ({ title, lat, lng, google }) {
  const position = React.useMemo(() => ({ lat, lng }), [lat, lng])

  if (!google || !position) return null

  return (
    <Marker
      title={title}
      position={position}
      animation={google.maps.Animation.DROP}
      clickable={false}
    />
  )
}

MapMarker.propTypes = {
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
  google: PropTypes.object.isRequired,
  title: PropTypes.string
}

const CENTER_ON_LOAD = { lat: 54.48829883320676, lng: -100.30726608878926 }
const ZOOM_ON_LOAD = 4

export function ZoneMap ({
  id,
  enableLegend,
  enableAutoMarker,
  validityResults,
  pickupZoneVisibleOnLoad = true,
  deliveryZoneVisibleOnLoad = true,
  extendedDeliveryZoneVisibleOnLoad = true,
  loader,
  forceLoading = false,
  height = '100%',
  width = '100%',
  center = null,
  serviceCity = '',
  markers = [],
  zoom = 9,
  deps
}) {
  const { tc, google } = deps

  const [map, setMap] = React.useState(null)
  const [isLoadingPolygons, setIsLoadingPolygons] = React.useState(false)
  const [pickupZoneVisible, setPickupZoneVisible] = React.useState(pickupZoneVisibleOnLoad)
  const [deliveryZoneVisible, setDeliveryZoneVisible] = React.useState(deliveryZoneVisibleOnLoad)
  const [extendedDeliveryZoneVisible, setExtendedDeliveryZoneVisible] = React.useState(extendedDeliveryZoneVisibleOnLoad)
  const [disablePickupZoneToggle, setDisablePickupZoneToggle] = React.useState(false)
  const [disableDeliveryZoneToggle, setDisableDeliveryZoneVisible] = React.useState(false)
  const [disableExtendedDeliveryZoneToggle, setDisableExtendedDeliveryZoneVisible] = React.useState(false)
  const [polygons, setPolygons] = React.useState(null)
  const [mapPolygons, setMapPolygons] = React.useState([])
  const mapContainerStyle = React.useMemo(() => ({ height, width }), [height, width])

  const onMapLoad = React.useCallback((map) => {
    setMap(map)
  }, [])

  const onMapUnmount = React.useCallback(() => setMap(null), [])

  React.useEffect(() => {
    if (map && !center && !serviceCity) {
      map.panTo(CENTER_ON_LOAD)
      map.setZoom(ZOOM_ON_LOAD)
    }
  }, [map])

  React.useEffect(() => {
    loadPolygons()
  }, [])

  React.useEffect(() => {
    center && map && map.panTo(center)

    if (center && map && map.getZoom() < 8) {
      map.setZoom(8)
    }
  }, [map, center])

  React.useEffect(() => {
    if (polygons) {
      drawPolygons()
    }
  }, [serviceCity, polygons, pickupZoneVisible, deliveryZoneVisible, extendedDeliveryZoneVisible])

  React.useEffect(() => {
    if (serviceCity && polygons) {
      let mergedPolygons = []

      for (const polygonsKey in polygons) {
        if (polygons[polygonsKey]) {
          mergedPolygons = mergedPolygons.concat(polygons[polygonsKey]
            .filter((o) => (o.serviceCity === serviceCity))
            .map((o) => (o.geometry.coordinates)))
        }
      }

      fitPolygonsToBounds(mergedPolygons)
    }
  }, [serviceCity, polygons])

  async function loadPolygons () {
    try {
      setIsLoadingPolygons(true)
      const deliveryZones = await tc.regions.getServiceCityZones()
      setPolygons(deliveryZones && typeof deliveryZones === 'object' ? deliveryZones : null)
    } finally {
      setIsLoadingPolygons(false)
    }
  }

  function generatePolygons ({ coordinates, key, options, visible }) {
    const polygonComponents = []
    const extractedPolygons = extractNestedPolygons(coordinates)

    if (extractedPolygons.length) {
      extractedPolygons.forEach((extractedPolygon, extractedPolygonIndex) => {
        polygonComponents.push(
          <Polygon
            options={options}
            key={`${key}-${extractedPolygonIndex}`}
            paths={extractedPolygon}
            visible={visible}
          />
        )
      })
    }

    return polygonComponents
  }

  function extractNestedPolygons (coordinates) {
    let polygons = []

    if (coordinates.length && Array.isArray(coordinates[0][0])) {
      coordinates.forEach((coordinateItem) => {
        polygons = polygons.concat(extractNestedPolygons(coordinateItem))
      })
    } else if (coordinates.length) {
      polygons.push(coordinates.map(([lng, lat]) => ({ lat, lng })))
    }

    return polygons
  }

  async function drawPolygons () {
    const categorizedPolys = []

    const pickupPolygons = polygons.pickup.filter((o) => (o.serviceCity === serviceCity))
    const deliveryPolygons = polygons.delivery.filter((o) => (o.serviceCity === serviceCity))
    const surchargePolygons = polygons.surcharge.filter((o) => (o.serviceCity === serviceCity))

    categorizedPolys.push(pickupPolygons)
    categorizedPolys.push(deliveryPolygons)
    categorizedPolys.push(surchargePolygons)

    setDisablePickupZoneToggle(!pickupPolygons.length)
    setDisableDeliveryZoneVisible(!deliveryPolygons.length)
    setDisableExtendedDeliveryZoneVisible(!surchargePolygons.length)

    let mapPolygons = []

    categorizedPolys.forEach((polys, index) => {
      polys.forEach((poly, secondIndex) => {
        let pickupOptions = null
        let deliveryOptions = null
        let extendedOptions = null

        switch (index) {
          case 0:
            pickupOptions = {
              fillColor: COLORS.TREXITY_MERCHANT_BLUE,
              fillOpacity: 0.15,
              strokeColor: COLORS.TREXITY_MERCHANT_BLUE,
              strokeOpacity: 1,
              strokeWeight: 8,
              zIndex: 1
            }

            mapPolygons = mapPolygons.concat(generatePolygons({
              coordinates: poly.geometry.coordinates,
              key: `${index}-${secondIndex}`,
              options: pickupOptions,
              visible: pickupZoneVisible
            }))
            break
          case 1:
            deliveryOptions = {
              fillColor: COLORS.TREXITY_MERCHANT_PINK,
              fillOpacity: 0,
              strokeColor: COLORS.TREXITY_MERCHANT_PINK,
              strokeOpacity: 1,
              strokeWeight: 3,
              zIndex: 2
            }

            mapPolygons = mapPolygons.concat(generatePolygons({
              coordinates: poly.geometry.coordinates,
              key: `${index}-${secondIndex}`,
              options: deliveryOptions,
              visible: deliveryZoneVisible
            }))
            break
          case 2:
            extendedOptions = {
              fillColor: COLORS.TREXITY_MERCHANT_LIGHT_BLUE,
              fillOpacity: 0,
              strokeColor: COLORS.TREXITY_MERCHANT_LIGHT_BLUE,
              strokeOpacity: 1,
              strokeWeight: 2,
              zIndex: 3
            }

            mapPolygons = mapPolygons.concat(generatePolygons({
              coordinates: poly.geometry.coordinates,
              key: `${index}-${secondIndex}`,
              options: extendedOptions,
              visible: extendedDeliveryZoneVisible
            }))
            break
        }
      })
    })

    setMapPolygons(mapPolygons)
  }

  function fitPolygonsToBounds (coordinateGroups) {
    if (coordinateGroups.length) {
      const extractedPolygons = extractNestedPolygons(coordinateGroups)
      const bounds = new google.maps.LatLngBounds()

      extractedPolygons.forEach((coordinates) => {
        coordinates.forEach((latLng) => {
          bounds.extend(latLng)
        })
      })

      map.fitBounds(bounds)
    }
  }

  function onLegendItemToggled ({ legendName, enabled }) {
    switch (legendName) {
      case 'pickup':
        setPickupZoneVisible(enabled)
        break
      case 'delivery':
        setDeliveryZoneVisible(enabled)
        break
      case 'extended':
        setExtendedDeliveryZoneVisible(enabled)
        break
    }
  }

  // Could render an indeterminate spinner
  if (!google) return null

  const mapMarkers = (enableAutoMarker ? (center ? [center] : []) : markers).map((marker) => (<MapMarker {...marker} google={google} key={String(marker.lat) + String(marker.lng)} />))

  const googleMap = (
    <GoogleMap
      id={id || 'zone-map'}
      onLoad={onMapLoad}
      onUnmount={onMapUnmount}
      mapContainerStyle={mapContainerStyle}
      zoom={zoom}
    >
      {mapPolygons}
      {mapMarkers}
    </GoogleMap>
  )

  const wrappedGoogleMap = validityResults ? (
    <div style={{ position: 'relative', width: '100%' }}>
      {googleMap}
      <div style={Styles.validityResults}>{validityResults}</div>
    </div>
  ) : googleMap

  const isLoading = (
    isLoadingPolygons ||
    forceLoading
  )

  return enableLegend ? (
    <ZoneMapWrapper
      loader={loader}
      pickupZoneVisibleOnLoad={pickupZoneVisibleOnLoad}
      deliveryZoneVisibleOnLoad={deliveryZoneVisibleOnLoad}
      extendedDeliveryZoneVisibleOnLoad={extendedDeliveryZoneVisibleOnLoad}
      disablePickupZoneToggle={disablePickupZoneToggle}
      disableDeliveryZoneToggle={disableDeliveryZoneToggle}
      disableExtendedDeliveryZoneToggle={disableExtendedDeliveryZoneToggle}
      isLoading={isLoading}
      onLegendItemClicked={onLegendItemToggled}
    >
      {wrappedGoogleMap}
    </ZoneMapWrapper>
  ) : wrappedGoogleMap
}

const Styles = {
  validityResults: {
    position: 'absolute',
    bottom: 20,
    left: 20,
    maxWidth: 280,
    backgroundColor: COLORS.WHITE,
    boxShadow: '0px 2px 15px rgba(0,0,0,0.10)'
  }
}

ZoneMap.propTypes = {
  id: PropTypes.string,
  enableLegend: PropTypes.bool,
  enableAutoMarker: PropTypes.bool,
  validityResults: PropTypes.node,
  pickupZoneVisibleOnLoad: PropTypes.bool,
  deliveryZoneVisibleOnLoad: PropTypes.bool,
  extendedDeliveryZoneVisibleOnLoad: PropTypes.bool,
  loader: PropTypes.node,
  center: PropTypes.shape({ lat: PropTypes.number.isRequired, lng: PropTypes.number.isRequired }),
  serviceCity: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  markers: PropTypes.arrayOf(PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
    title: PropTypes.string
  })),
  zoom: PropTypes.number,
  deps: PropTypes.exact({
    tc: PropTypes.object,
    google: PropTypes.object
  }).isRequired
}
