module.exports = function getWeekdays () {
  return [
    { name: 'Sunday', shortName: 'Sun' },
    { name: 'Monday', shortName: 'Mon' },
    { name: 'Tuesday', shortName: 'Tue' },
    { name: 'Wednesday', shortName: 'Wed' },
    { name: 'Thursday', shortName: 'Thu' },
    { name: 'Friday', shortName: 'Fri' },
    { name: 'Saturday', shortName: 'Sat' }
  ]
}
