import { stringifyUrl } from './stringifyUrl'

export async function wrappedFetch (url, {
  method,
  jwtToken,
  queryParams,
  bodyData,
  headers = {},
  includeResponse
} = {}) {
  try {
    url = stringifyUrl(url, { queryParams })

    const finalHeaders = Object.assign({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': jwtToken ? `Bearer ${jwtToken}` : undefined
    }, headers)

    // The content-type header will only be set by fetch() if it is not already
    // set. We need fetch() to set the content-type header to something like:
    // Content-Type: multipart/form-data; boundary: slkdjfsoifw0ef0hwe0cndicnsdc
    if (bodyData instanceof FormData) {
      delete finalHeaders['Content-Type']
    }

    const response = await fetch(url, {
      method,
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      headers: finalHeaders,
      body: bodyData instanceof FormData
        ? bodyData
        : (bodyData ? JSON.stringify(bodyData) : undefined)
    })

    const contentType = response.headers.get('content-type')

    let result = null

    const responseHeader = contentType || finalHeaders.Accept

    if (responseHeader.includes('application/json')) {
      result = await response.json()
    } else if (responseHeader.includes('text/html')) {
      result = await response.text()
    } else if (responseHeader.includes('application/pdf')) {
      result = await response.blob()
    }

    return { result, status: response.status, statusText: response.statusText }
  } catch (err) {
    if (err.text && typeof err.text === 'function') {
      err.text()
        .then((errorObj) => {
          if (errorObj.indexOf('{') > -1) {
            errorObj = JSON.parse(errorObj)
            errorObj = errorObj.error ? errorObj.error : errorObj
            throw Object.assign(new Error(errorObj.message), { status: errorObj.status, code: errorObj.code })
          }

          throw new Error(String(errorObj))
        }).catch((catchError) => {
          throw new Error(catchError)
        })
    } else {
      throw err
    }
  }
}
