/**
 * Eg. usage:
 * Given the parameter variables: merchantName, merchantLogo, customerName, orderId, addressLine1;
 * If any of the parameters below fail to be true based on their logic expression,
 * missing parameter array will be returned or an error thrown (if configured to do so)
 * extractInvalidParams({
    'Merchant Name or Merchant Logo': merchantName || merchantLogo,
    'Customer Name': customerName,
    'Order ID': orderId,
    'Delivery Address': () => addressLine1 // functions will be called and evaluated to be truthy/false-y
  }, { throwError: true })
 *
 * @param {object} paramLabelsMap - key / value hash map of labels mapped to parameter conditions to check, exclude a label/param if you don't want to assess it
 * @param {boolean} [throwError] - if missing params are extracted throw an error instead of returning the missing parameters
 * @returns {undefined|string[]} - if no array is returned then all parameters assessed are valid
 */
module.exports = function extractInvalidParams (paramLabelsMap, { throwError } = {}) {
  const missingParams = Object.entries(paramLabelsMap)
    .filter(([, value]) => (typeof value === 'function' ? !value() : !value))
    .map(([key]) => key)

  if (throwError && missingParams && missingParams.length) {
    throw Object.assign(
      new Error(`Missing parameter${missingParams.length === 1 ? '' : 's'}: "${missingParams.join('", "')}" required`),
      { missingParams }
    )
  }

  return missingParams && missingParams.length ? missingParams : null
}
