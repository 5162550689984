// TODO: Each of these should be commented individually with detail as to how
// they are meant to be used, including any "data" payload that is typically
// associated with them. Otherwise we have no visibility into how to consume
// these events.

const EVENT_LOG = {
  DRIVERS: {
    DRIVER_ACTIVITY_CHANGE: 'driver activity change'
  },
  UNPAIRED_DRIVERS_MERCHANTS: {
    UNPAIRED: 'unpaired driver from merchant',
    CANCELLED: 'cancelled unpaired driver merchant',
    UPDATED_NOTES: 'updated unpaired driver merchant notes'
  },
  SHIPMENTS: {
    NO_SHIPMENT_STATUS: 'shipment draft',
    WAITING_FOR_ACCEPTANCE: 'shipment waiting for acceptance',
    WAITING_TO_START: 'shipment accepted',
    EN_ROUTE_TO_PICKUP: 'shipment en route',
    ARRIVED_AT_PICKUP: 'shipment arrived at pickup',
    OUT_FOR_DELIVERY: 'shipment out for delivery',
    ARRIVED_AT_DELIVERY: 'shipment arrived at delivery',
    DELIVERED: 'shipment delivered',
    RENOUNCED_BY_DRIVER: 'shipment renounced by driver',
    CANCELLED: 'shipment cancelled',
    ON_HOLD: 'shipment put on hold',
    EXPIRED: 'Expired',

    POTENTIAL_DRIVER_ACTIVITY: 'shipments potential driver activity',
    DELIVERIES_IN_PROGRESS: 'shipments deliveries in progress', // encompasses shipments EN_ROUTE_TO_PICKUP, ARRIVED_AT_PICKUP, OUT_FOR_DELIVERY, and ARRIVED_AT_DELIVERY

    DRIVER_PINGED: 'driver pinged',
    DRIVER_ACCEPTED: 'driver accepted',
    DRIVER_DISMISSED: 'driver dismissed',
    DRIVER_UNDISMISSED: 'driver undismissed',
    DRIVER_RENOUNCED: 'driver renounced'
  }
}

const SHIPMENT_STATUS_BY_EVENT_LOG = {
  [EVENT_LOG.SHIPMENTS.NO_SHIPMENT_STATUS]: 'NO_SHIPMENT_STATUS',
  [EVENT_LOG.SHIPMENTS.WAITING_FOR_ACCEPTANCE]: 'WAITING_FOR_ACCEPTANCE',
  [EVENT_LOG.SHIPMENTS.WAITING_TO_START]: 'WAITING_TO_START',
  [EVENT_LOG.SHIPMENTS.EN_ROUTE_TO_PICKUP]: 'EN_ROUTE_TO_PICKUP',
  [EVENT_LOG.SHIPMENTS.ARRIVED_AT_PICKUP]: 'ARRIVED_AT_PICKUP',
  [EVENT_LOG.SHIPMENTS.OUT_FOR_DELIVERY]: 'OUT_FOR_DELIVERY',
  [EVENT_LOG.SHIPMENTS.ARRIVED_AT_DELIVERY]: 'ARRIVED_AT_DELIVERY',
  [EVENT_LOG.SHIPMENTS.DELIVERED]: 'DELIVERED',
  [EVENT_LOG.SHIPMENTS.RENOUNCED_BY_DRIVER]: 'RENOUNCED_BY_DRIVER',
  [EVENT_LOG.SHIPMENTS.CANCELLED]: 'CANCELLED',
  [EVENT_LOG.SHIPMENTS.ON_HOLD]: 'ON_HOLD',
  [EVENT_LOG.SHIPMENTS.EXPIRED]: 'EXPIRED' // backwards-compat
}

const EVENT_LOG_BY_SHIPMENT_STATUS = {
  NO_SHIPMENT_STATUS: EVENT_LOG.SHIPMENTS.NO_SHIPMENT_STATUS,
  WAITING_FOR_ACCEPTANCE: EVENT_LOG.SHIPMENTS.WAITING_FOR_ACCEPTANCE,
  WAITING_TO_START: EVENT_LOG.SHIPMENTS.WAITING_TO_START,
  EN_ROUTE_TO_PICKUP: EVENT_LOG.SHIPMENTS.EN_ROUTE_TO_PICKUP,
  ARRIVED_AT_PICKUP: EVENT_LOG.SHIPMENTS.ARRIVED_AT_PICKUP,
  OUT_FOR_DELIVERY: EVENT_LOG.SHIPMENTS.OUT_FOR_DELIVERY,
  ARRIVED_AT_DELIVERY: EVENT_LOG.SHIPMENTS.ARRIVED_AT_DELIVERY,
  DELIVERED: EVENT_LOG.SHIPMENTS.DELIVERED,
  RENOUNCED_BY_DRIVER: EVENT_LOG.SHIPMENTS.RENOUNCED_BY_DRIVER,
  CANCELLED: EVENT_LOG.SHIPMENTS.CANCELLED,
  ON_HOLD: EVENT_LOG.SHIPMENTS.ON_HOLD,
  EXPIRED: EVENT_LOG.SHIPMENTS.EXPIRED
}

exports.EVENT_LOG = EVENT_LOG
exports.SHIPMENT_STATUS_BY_EVENT_LOG = SHIPMENT_STATUS_BY_EVENT_LOG
exports.EVENT_LOG_BY_SHIPMENT_STATUS = EVENT_LOG_BY_SHIPMENT_STATUS
