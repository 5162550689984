import React from 'react'
import { getFormattedDateAndTimeFromDate, getFormattedDurationString } from '@trexity/common/temporal'
import { DriverDisabled } from '@trexity/common/drivers'
import { Std } from '@trexity/common/core'
import HoverPhoto from './HoverPhoto'
import InputSearchWithFilter from './InputSearchWithFilter'

import {
  Button,
  Table,
  Row,
  Col,
  Tag,
  Icon,
  Tooltip
} from 'antd'

const driverStatus = {
  'approved': 'Approved',
  'unapproved': 'Unapproved',
  'onboarded': 'Onboarded',
  'not-onboarded': 'Not Onboarded',
  'suspended': 'Suspended',
  'not-suspended': 'Not Suspended'
}

const searchKeys = [
  'cachedAuthEmail',
  'fullName',
  'contactPhone',
  'vehicleModel',
  'vehicleMake'
]

const defaultSearchFilters = {
  keywords: '',
  pageNum: 1,
  citiesFilter: [],
  statusFilter: [],
  hasAllDocsFilter: false,
  isNewFilter: false,
  startDate: new Date(Date.now() - 2 * 365 * 24 * 60 * 60 * 1000),
  endDate: new Date(new Date().setHours(23, 59, 59, 999))
}

export default function Drivers ({
  isAdmin,
  deps
}) {
  const {
    send,
    current,
    data: {
      drivers = [],
      driversPageNum,
      driversSearchQuery,
      driversStartDate,
      driversEndDate,
      driversCitiesFilter = [],
      driversServiceCities = [],
      driversStatusFilter = [],
      driversHasAllDocsFilter = undefined,
      driversIsNewFilter = undefined
    },
    RouteLink,
    tc
  } = deps

  const [searchFilters, setSearchFilters] = React.useState({
    keywords: driversSearchQuery || defaultSearchFilters.keywords,
    pageNum: driversPageNum || defaultSearchFilters.pageNum,
    citiesFilter: driversCitiesFilter || defaultSearchFilters.citiesFilter,
    statusFilter: driversStatusFilter || defaultSearchFilters.statusFilter,
    hasAllDocsFilter: driversHasAllDocsFilter !== undefined ? driversHasAllDocsFilter : defaultSearchFilters.hasAllDocsFilter,
    isNewFilter: driversIsNewFilter !== undefined ? driversIsNewFilter : defaultSearchFilters.isNewFilter,
    startDate: driversStartDate || defaultSearchFilters.startDate,
    endDate: driversEndDate || defaultSearchFilters.endDate
  })

  React.useEffect(() => {
    send({ type: 'update driver list params', ...searchFilters })
  }, [searchFilters])

  const isLoading = current.within('Loading')

  const cities = driversServiceCities.map(({ name }) => name)

  const filteredDrivers = drivers
    .filter((driver) => {
      if (!driver.createdAt) return true
      const createdAt = driver.createdAt.toDate()
      return (
        (searchFilters.startDate ? createdAt >= searchFilters.startDate : true) &&
        (searchFilters.endDate ? createdAt <= searchFilters.endDate : true)
      )
    })
    .filter((driver) => {
      return searchFilters.citiesFilter.length
        ? searchFilters.citiesFilter.includes(driver.mailingAddressServiceCity)
        : true
    })
    .filter((driver) => {
      if (searchFilters.hasAllDocsFilter) {
        return tc.drivers.isDriverProfileComplete({ driver })
      }

      return true
    })
    .filter((driver) => {
      if (searchFilters.isNewFilter) {
        return driver.lifetimeDeliveryCount === 0
      }

      return true
    })
    .filter((driver) => {
      return searchFilters.statusFilter.every((status) => {
        switch (status) {
          case 'approved':
            return driver.approved === true
          case 'unapproved':
            return driver.approved !== true
          case 'onboarded':
            return driver.onboarded === true
          case 'not-onboarded':
            return driver.onboarded !== true
          case 'suspended':
            return DriverDisabled.isDisabled(driver)
          case 'not-suspended':
            return !DriverDisabled.isDisabled(driver)
          default:
            return false
        }
      })
    })
    .filter((driver) => {
      return searchFilters.keywords ? searchKeys.some((key) => {
        return (
          driver[key] &&
          String(driver[key])
            .toLowerCase()
            .includes(searchFilters.keywords.toLowerCase())
        )
      }) : true
    })

  const dataSource = filteredDrivers.map((driver) => ({
    key: driver.id,
    vehicle: `${driver.vehicleColor} ${driver.vehicleModel} ${driver.vehicleMake} ${driver.vehicleYear}`,
    tags: [
      DriverDisabled.isDisabled(driver)
        ? { key: 'suspended', color: 'volcano', text: 'Suspended', tooltip: (<div>{DriverDisabled.getReason(driver)}<br />Expires on: {DriverDisabled.getFormattedExpiryDate(driver)}</div>) }
        : null,
      driver.approved
        ? { key: 'approved', color: 'green', text: 'Approved' }
        : { key: 'approved', color: 'red', text: 'Not Approved' },
      driver.onboarded
        ? { key: 'onboarded', color: 'purple', text: 'Onboarded' }
        : { key: 'onboarded', color: 'red', text: 'Not Onboarded' }
    ].filter(Boolean),
    ...driver,
    workingTime: driver.workingTime || 0
  }))

  return (
    <React.Fragment>
      <div style={{ marginBottom: 20 }}>
        <Row type='flex' justify='space-between' gutter={[{ xs: 8, sm: 8 }, { xs: 8, sm: 8 }]}>
          <Col xs={24} sm={16} lg={16}>
            <InputSearchWithFilter
              disabled={isLoading}
              placeholder='Search…'
              filters={searchFilters}
              onReset={() => setSearchFilters({ ...defaultSearchFilters })}
              onFilter={(filters) => setSearchFilters({ ...filters, pageNum: 1 })}
            >
              <InputSearchWithFilter.Select
                $label='Cities'
                filterKey='citiesFilter'
                placeholder='Filter city'
                multiple
                options={cities.map((city) => ({
                  value: city,
                  label: city
                }))}
                $tag={({ citiesFilter }) => ({
                  visible: Boolean(citiesFilter.length),
                  label: <React.Fragment><strong>Cit{citiesFilter.length === 1 ? 'y' : 'ies'}:</strong> {citiesFilter.map((city) => `[${city}]`).join(', ')}</React.Fragment>,
                  reset: !Std.eq(citiesFilter, defaultSearchFilters.citiesFilter) && { citiesFilter: defaultSearchFilters.citiesFilter }
                })}
              />

              <InputSearchWithFilter.Select
                $label='Status'
                filterKey='statusFilter'
                placeholder='Filter status'
                multiple
                options={Object.entries(driverStatus).map(([value, label]) => ({ value, label }))}
                $tag={({ statusFilter }, { options }) => ({
                  visible: Boolean(statusFilter.length),
                  label: <React.Fragment><strong>Status:</strong> {statusFilter.map((status) => `[${(options.find((obj) => obj.value === status) || {}).label || '?'}]`).join(', ')}</React.Fragment>,
                  reset: !Std.eq(statusFilter, defaultSearchFilters.statusFilter) && { statusFilter: defaultSearchFilters.statusFilter }
                })}
              />

              <InputSearchWithFilter.Checkbox
                filterKey='hasAllDocsFilter'
                $tag={({ hasAllDocsFilter }, { children }) => ({
                  visible: Boolean(hasAllDocsFilter),
                  label: children,
                  reset: hasAllDocsFilter !== defaultSearchFilters.hasAllDocsFilter && { hasAllDocsFilter: defaultSearchFilters.hasAllDocsFilter }
                })}
              >Has all docs</InputSearchWithFilter.Checkbox>

              <InputSearchWithFilter.Checkbox
                filterKey='isNewFilter'
                $tag={({ isNewFilter }, { children }) => ({
                  visible: Boolean(isNewFilter),
                  label: children,
                  reset: isNewFilter !== defaultSearchFilters.isNewFilter && { isNewFilter: defaultSearchFilters.isNewFilter }
                })}
              >Is new</InputSearchWithFilter.Checkbox>

              <InputSearchWithFilter.DateRange
                $label='Filter by date'
                startDateKey='startDate'
                endDateKey='endDate'
                showTime
                $tag={({ startDate, endDate }) => ({
                  visible: Boolean(startDate && endDate),
                  label: startDate && endDate && <React.Fragment><strong>Date:</strong> {getFormattedDateAndTimeFromDate(startDate)} – {getFormattedDateAndTimeFromDate(endDate)}</React.Fragment>,
                  reset: (
                    !Std.eq(startDate, defaultSearchFilters.startDate) ||
                    !Std.eq(endDate, defaultSearchFilters.endDate)
                  ) && { startDate: defaultSearchFilters.startDate, endDate: defaultSearchFilters.endDate }
                })}
              />
            </InputSearchWithFilter>
          </Col>
          <Col xs={24} sm={8} lg={8} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
            <Row type='flex' justify='end' gutter={[{ xs: 8, sm: 8 }, { xs: 8, sm: 8 }]}>
              <Col>
                <Button
                  size='large'
                  disabled={isLoading || !filteredDrivers || filteredDrivers.length === 0}
                  type='primary'
                  icon='export'
                  onClick={() => send({ type: 'export csv', data: filteredDrivers })}
                >
                  Export CSV
                </Button>
              </Col>
              <Col>
                <Button
                  size='large'
                  disabled={isLoading}
                  loading={isLoading}
                  icon='reload'
                  onClick={() => send('reload all')}
                  style={{ marginLeft: 10 }}
                >
                  Reload
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <Table
        scroll={{ x: true }}
        size='small'
        dataSource={dataSource}
        pagination={{
          pageSize: 10,
          total: dataSource.length,
          current: searchFilters.pageNum,
          onChange: (pageNum) => setSearchFilters({ ...searchFilters, pageNum }),
          showTotal: (total, range) => (total ? `Total: ${total}` : '')
        }}
        loading={isLoading}
      >
        <Table.Column
          key='photo'
          width={25}
          render={(_, { fullName, driverPhotoUrl }) => (
            driverPhotoUrl ? (
              <HoverPhoto
                title={`${fullName}'s Photo`}
                photoSrc={driverPhotoUrl}
              />
            ) : null
          )}
        />
        <Table.Column
          title='Email'
          dataIndex='cachedAuthEmail'
          key='cachedAuthEmail'
        />
        <Table.Column
          title='Full name'
          dataIndex='fullName'
          key='fullName'
          render={(_, { driverId, lifetimeDeliveryCount, fullName }) => {
            return (
              <span>
                {lifetimeDeliveryCount === 0 ? (
                  <Tooltip title={'0 deliveries completed'}>
                    <Tag color={'gold'}><strong>NEW</strong></Tag>
                  </Tooltip>
                ) : null}
                {fullName}
                {lifetimeDeliveryCount < 50 && lifetimeDeliveryCount > 0 ? (
                  <Tooltip title={`Less than 50 deliveries completed (currently at ${lifetimeDeliveryCount})`}>
                    <span className='material-icons' style={{ fontSize: 18, verticalAlign: 'middle', marginBottom: 3, marginLeft: 3, color: 'gold' }}>new_releases</span>
                  </Tooltip>
                ) : null}
              </span>
            )
          }}
        />
        <Table.Column
          title='City'
          dataIndex='mailingAddressServiceCity'
          key='mailingAddressServiceCity'
        />
        <Table.Column
          title='Created At'
          dataIndex='createdAt'
          key='createdAt'
          sorter={(a, b) => {
            if (!a.createdAt) {
              return -1
            }

            if (!b.createdAt) {
              return 1
            }

            return a.createdAt.toDate() - b.createdAt.toDate()
          }}
          render={(createdAt) => (
            (createdAt && getFormattedDateAndTimeFromDate(createdAt.toDate())) ||
            'N/A'
          )}
        />
        <Table.Column
          title='Last Delivered At'
          dataIndex='lastDeliveredAt'
          key='lastDeliveredAt'
          sorter={(a, b) => {
            if (!a.lastDeliveredAt) {
              return -1
            }

            if (!b.lastDeliveredAt) {
              return 1
            }

            return a.lastDeliveredAt.toDate() - b.lastDeliveredAt.toDate()
          }}
          render={(lastDeliveredAt) => (
            (lastDeliveredAt && getFormattedDateAndTimeFromDate(lastDeliveredAt.toDate())) ||
            'N/A'
          )}
        />
        <Table.Column
          title='Working Time'
          dataIndex='workingTime'
          key='workingTime'
          width={150}
          sorter={(a, b) => a.workingTime - b.workingTime}
          sortDirections={['ascend', 'descend']}
          render={(workingTime) => getFormattedDurationString(workingTime / 1000)}
        />
        <Table.Column
          title='Active'
          dataIndex='active'
          key='active'
          width={1}
          render={(active) => active ? (
            <Icon type='check-circle' theme='twoTone' twoToneColor='#52c41a' />
          ) : (
            <Icon type='close-circle' theme='twoTone' twoToneColor='red' />
          )}
        />
        <Table.Column
          dataIndex='tags'
          key='tags'
          width={270}
          render={(tags) => tags.map(({ key, color, text, tooltip = null }) => {
            const tag = <Tag color={color} key={key}>{text}</Tag>

            return tooltip ? (
              <Tooltip key={`${key}-tooltip`} title={tooltip}>{tag}</Tooltip>
            ) : tag
          })}
        />
        <Table.Column
          title='Action'
          key='action'
          width={1}
          align='right'
          render={(_, { id }) => (
            <div style={{ whiteSpace: 'nowrap' }}>
              <RouteLink
                routeKey='Driver Dashboard'
                params={{ id }}
              >Edit</RouteLink>
            </div>
          )}
        />
      </Table>
    </React.Fragment>
  )
}
