exports.COLORS = {
  OTHER: '#A1A8B5',
  MEDIUM_LIGHT: '#E5E5E5',
  MEDIUM: '#9f9f9f',
  MEDIUM_DARK: '#777777',
  DARK: '#3C3C3C',

  // Typically used for anything generically Trexity.
  // Things that only apply to Trexity as a company, not to drivers or merchants.
  TREXITY_BLUE: '#6096BA',

  // For anything related to Trexity as a company that requires a lighter touch.
  // Doubles as a merchant color
  TREXITY_LIGHT_BLUE: '#DDF0FF',

  // Success, Go, Affirmative. Used as an accent colour or colour to convey a positive action.
  TREXITY_GREENISH_TEAL: '#A2FACF',

  // The primary colour for Drivers. This navy should be the underlying colour/theme
  // for anything that is Driver related.
  TREXITY_DRIVER_NAVY: '#43506B',

  // The primary colour for Merchants. This burgundy should be the underlying colour/theme
  // for anything that is Merchant related.
  TREXITY_MERCHANT_BURGUNDY: '#624163',

  // Secondary to Trexity Blues, this colour acts as a purely functional/notice colour.
  // To show alerts or pending information. Doubles as a "To Customer" colour.
  TREXITY_YELLOW: '#FFE760',

  // Stop, Cancel, Decline. I think you get it.
  // Only used for functional/negative actions.
  TREXITY_RED: '#FF004E',

  // Success, Go, OK. I think you get it.
  // Only used for functional/positive actions.
  TREXITY_GREEN: '#31D300',

  // Every other colour is very strong, honestly we just needed something neutral that can span
  // all sections without clashing,
  TREXITY_TAN: '#FFEDE3',

  // This isn't used much, but it should be used as a neutral colour that can span accross
  // all section as well.
  TREXITY_LIGHT_GREY: '#F7F7F7',

  // Seriously? It's white.
  WHITE: '#FFFFFF',

  TREXITY_MERCHANT_PINK: '#f72585',

  TREXITY_MERCHANT_LIGHT_BLUE: '#4cc9f0',

  TREXITY_MERCHANT_BLUE: '#4351ee'
}
