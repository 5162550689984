/**
 * @param {Date} date
 * @param {boolean} [showSeconds]
 * @returns {string}
 */
module.exports = function getFormattedTimeFromDate (date, { showMinutes = true, showSeconds = false } = {}) {
  if (showSeconds) {
    return date.toLocaleTimeString()
  }

  const localizedTimeString = date.toLocaleTimeString()

  const splitLocalizedTimeString = localizedTimeString.split(':')
  const [hours, minutes, rest] = splitLocalizedTimeString

  const fullHoursString = hours.toString()

  let antiMeridiem = ''

  if (rest && (rest.includes('AM') || rest.includes('PM'))) {
    antiMeridiem = rest.includes('AM') ? 'AM' : 'PM'
  }

  let fullMinutesString = minutes.toString()

  if (parseInt(fullMinutesString, 10) < 10) {
    fullMinutesString = `0${parseInt(fullMinutesString, 10)}`
  }

  if (showMinutes) {
    return `${fullHoursString}:${fullMinutesString} ${antiMeridiem}`
  } else {
    return `${fullHoursString} ${antiMeridiem}`
  }
}
