const { Std } = require('../../core')
const { Time } = require('../../types')
const { isServiceHours } = require('./isServiceHours')

/**
 * Given a service hours object, retrieve the weekday hours and sort the weekdays
 * in ISO order with Sunday first and Saturday last.
 *
 * @return {WeekdayDeliveryDay[]}
 */
function getAllWeekdayHoursInISOOrder (serviceHours) {
  if (!isServiceHours(serviceHours)) {
    throw Std.err('TypeError')
  }

  // Ensure the days are always sorted in ISO order (Sun, M, Tu, W, Th, F, Sat)
  const days = [...serviceHours.days].sort((a, b) => a.day - b.day).map(Std.clone)

  // Also ensure that the ranges are sorted
  for (const day of days) {
    day.ranges = [...day.ranges].sort((a, b) => Time.from(a.fromTime).value - Time.from(b.fromTime).value)
  }

  return days
}

exports.getAllWeekdayHoursInISOOrder = getAllWeekdayHoursInISOOrder
