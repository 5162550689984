const { PHONE_EXTENSIONS } = require('./constants')

const { STORED_EXTENSION } = PHONE_EXTENSIONS

module.exports = function formatPhoneNumber (text) {
  if (text === '+' || text === '+1' || !text) {
    return text
  }

  // Remove space and special characters, leaving only numbers and the extension separator
  const regex = new RegExp(`[^0-9${STORED_EXTENSION}]`, 'gu')
  text = text.replace(regex, '')

  const parts = String(text).split(STORED_EXTENSION)
  text = parts[0]

  // For formatting purposes need to remove the + or 1 or +1 that precedes the actual number
  if (text.startsWith('+1')) {
    text = text.substring(2)
  } else if (text.startsWith('+') || text.startsWith('1')) {
    text = text.substring(1)
  }

  const textArray = text.split('')

  if (textArray.length > 3) {
    textArray.splice(3, 0, ' ')
  }

  if (textArray.length > 7) {
    textArray.splice(7, 0, ' ')
  }

  return `+1 ${textArray.join('')}${parts[1] ? `${STORED_EXTENSION}${parts[1]}` : ''}`
}
