import React from 'react'
import PropTypes from 'prop-types'
import { Modal, InputNumber, Form, DatePicker, TimePicker } from 'antd'
import 'moment-timezone'
import moment from 'moment'
import { getNearestAcceptableDate } from '@trexity/common/temporal'
import { LoadingWrapper } from './LoadingWrapper'

export function SetScheduleDialog ({
  scheduleInterval,
  deps
}) {
  const {
    send,
    current,
    data
  } = deps

  const scheduledPostAtDisabledMinutes = [...Array(60)].map((_, index) => index).filter((_, index) => index % scheduleInterval !== 0)

  const context = data.setScheduleContext || {}

  const showSetScheduleDialog = current.within('Showing Set Schedule Dialog')
  const isBusy = current.within('Setting Schedule')

  const minutePrecision = scheduleInterval
  const getDefaultDate = () => null

  const [scheduleDate, setScheduleDate] = React.useState(getDefaultDate())
  const [staggerEach, setStaggerEach] = React.useState(1)
  const [staggerMinutes, setStaggerMinutes] = React.useState(minutePrecision)

  const resetState = () => {
    setScheduleDate(getDefaultDate())
  }

  React.useEffect(() => {
    if (!showSetScheduleDialog) {
      resetState()
    }
  }, [showSetScheduleDialog])

  return (
    <Modal
      title='Set Schedule'
      visible={showSetScheduleDialog}
      onOk={() => {
        if (isBusy) {
          return
        }

        const fn = () => {
          send({
            type: 'set schedule',
            shipmentIdRouteIdentifierPairs: context.shipmentIdRouteIdentifierPairs,
            scheduleDate,
            staggerEach,
            staggerMinutes
          })
        }

        if (!scheduleDate) {
          Modal.confirm({
            title: 'Date not chosen',
            content: 'You have not chosen a date. This will unschedule the route.',
            onOk: () => {
              fn()
            }
          })
        } else {
          fn()
        }
      }}
      onCancel={() => {
        send('close set schedule dialog')
      }}
    >
      <LoadingWrapper active={isBusy}>
        <Form
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 14 }}
        >
          <Form.Item label='When' required>
            <DatePicker
              allowClear
              value={scheduleDate ? moment(scheduleDate) : getDefaultDate()}
              onChange={(date) => {
                if (date) {
                  setScheduleDate(getNearestAcceptableDate(date.toDate(), { interval: scheduleInterval }))
                } else {
                  setScheduleDate(null)
                }
              }}
              disabledDate={(current) => {
                // Cannot select days in the past
                return current && moment().isAfter(current, 'day')
              }}
              style={{ marginRight: 5 }}
            />
            <TimePicker
              allowClear
              use12Hours
              disabledMinutes={() => scheduledPostAtDisabledMinutes}
              hideDisabledOptions={true}
              format='h:mm a'
              value={scheduleDate ? moment(scheduleDate) : getDefaultDate()}
              onChange={(date) => {
                if (date) {
                  setScheduleDate(getNearestAcceptableDate(date.toDate(), { interval: scheduleInterval }))
                } else {
                  setScheduleDate(null)
                }
              }}
            />
          </Form.Item>
          <Form.Item label='Stagger every'>
            <InputNumber
              value={staggerMinutes}
              min={0}
              step={minutePrecision}
              onChange={(value) => setStaggerMinutes(value)}
            />
            <span className='ant-form-text'> minute{staggerMinutes === 1 ? '' : 's'}</span>
          </Form.Item>
          <Form.Item label='For every'>
            <InputNumber
              value={staggerEach}
              min={1}
              onChange={(value) => setStaggerEach(value)}
            />
            <span className='ant-form-text'> route{staggerEach === 1 ? '' : 's'}</span>
          </Form.Item>
        </Form>
      </LoadingWrapper>
    </Modal>
  )
}

SetScheduleDialog.propTypes = {
  scheduleInterval: PropTypes.number,
  deps: PropTypes.exact({
    send: PropTypes.func,
    current: PropTypes.object,
    data: PropTypes.object
  }).isRequired
}
