export function parseApiResponse (jsonResponse) {
  if (jsonResponse && jsonResponse.result) {
    const result = jsonResponse.result

    if (result.error) {
      throw Object.assign(
        new Error(result.error.message), { ...result.error, status: jsonResponse.status }
      )
    }

    return result
  }

  return jsonResponse
}
