/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import PropTypes from 'prop-types'
import { shiftColor } from '@trexity/common/color'
import { COLORS } from '@trexity/common/color/constants'

export const Pill = ({
  light,
  label,
  color,
  type,
  ...rest
}) => {
  const setStyle = () => {
    switch (type) {
      case 'Neutral':
        return COLORS.TREXITY_TAN
      case 'Dropoff':
        return COLORS.TREXITY_YELLOW
      case 'Pickup':
        return COLORS.TREXITY_LIGHT_BLUE
      case 'Delivered':
        return COLORS.TREXITY_GREENISH_TEAL
      case 'Stale':
        return COLORS.TREXITY_LIGHT_GREY
      case 'Draft':
        return COLORS.TREXITY_MERCHANT_BURGUNDY
      case 'Cancel':
        return COLORS.TREXITY_RED
      case 'Base':
        return COLORS.TREXITY_BLUE
      case 'Driver':
        return COLORS.TREXITY_DRIVER_NAVY
      default:
        return COLORS.TREXITY_TAN
    }
  }

  return (
    <div css={PillStyles(setStyle(), light)} {...rest}>
      {label}
    </div>
  )
}

export const PillStyles = (color, light) => ({
  display: 'inline-block',
  padding: '3px 10px',
  fontSize: '13px',
  borderWidth: 1,
  borderStyle: 'solid',
  borderRadius: 5,
  backgroundColor: color,
  borderColor: shiftColor(color, -20),
  color: light ? COLORS.WHITE : COLORS.DARK
})

Pill.propTypes = {
  type: PropTypes.string,
  light: PropTypes.bool,
  label: PropTypes.string,
  color: PropTypes.string
}
