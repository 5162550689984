exports.MODES = {
  SERVER_READ: 1,
  SERVER_WRITE: 2,
  CLIENT_READ: 4,
  CLIENT_WRITE: 8,
  CLIENT_ROLE_MERCHANT_READ: 16,
  CLIENT_ROLE_DRIVER_READ: 32,
  CLIENT_ROLE_MERCHANT_WRITE: 64,
  CLIENT_ROLE_DRIVER_WRITE: 128
}
