const { isobj } = require('../core/check')

function isAutoRenounceEnabled (config, serviceCity) {
  let enabled = null

  if (isobj(config)) {
    // This is the object format, which configures per city values
    if (serviceCity in config) {
      enabled = Boolean(config[serviceCity])
    } else {
      enabled = ['Ottawa, ON'].includes(serviceCity) // sane fallback in case of misconfiguration
    }
  } else {
    // Fallback to old behavior
    enabled = Boolean(config)
  }

  return enabled
}

function getPickupWarningMinutes (config, serviceCity) {
  let minutes = null

  if (isobj(config)) {
    // This is the object format, which configures per city values
    if (serviceCity in config) {
      minutes = Number(config[serviceCity])
    } else {
      minutes = 10 // sane fallback in case of misconfiguration
    }
  } else {
    // We assume that any other value is the numeric value version
    minutes = Number(config)
  }

  return minutes
}

function getPickupRenounceMinutes (config, serviceCity) {
  let minutes = null

  if (isobj(config)) {
    // This is the object format, which configures per city values. If the
    // service city is NOT specified, it is assumed that it does not support
    // auto-renouncing
    if (serviceCity in config) {
      minutes = Number(config[serviceCity])
    } else {
      minutes = 45 // sane fallback in case of misconfiguration
    }
  } else {
    // We assume that any other value is the numeric value version
    minutes = Number(config)
  }

  return minutes
}

function getDeliverWarningMinutes (config, serviceCity) {
  let minutes = null

  if (isobj(config)) {
    // This is the object format, which configures per city values. If the
    // service city is NOT specified, it is assumed that it does not support
    // auto-renouncing
    if (serviceCity in config) {
      minutes = Number(config[serviceCity])
    } else {
      minutes = 45 // sane fallback in case of misconfiguration
    }
  } else {
    // We assume that any other value is the numeric value version
    minutes = Number(config)
  }

  return minutes
}

exports.isAutoRenounceEnabled = isAutoRenounceEnabled
exports.getPickupWarningMinutes = getPickupWarningMinutes
exports.getPickupRenounceMinutes = getPickupRenounceMinutes
exports.getDeliverWarningMinutes = getDeliverWarningMinutes
