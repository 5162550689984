const splitTime = (time) => {
  const s = time.split(':')
  return [Number(s[0]), Number(s[1])]
}

module.exports = function isTimeInInterval (time, fromTime, toTime) {
  const [timeHH, timeMM] = splitTime(time)
  const [fromTimeHH, fromTimeMM] = splitTime(fromTime)
  const [toTimeHH, toTimeMM] = splitTime(toTime)

  const isInRange =
    ((fromTimeHH < timeHH) || (fromTimeHH === timeHH && fromTimeMM <= timeMM)) &&
    ((timeHH < toTimeHH) || (timeHH === toTimeHH && timeMM <= toTimeMM))

  return isInRange
}
