/*
Namespace that exposes general functions for working with foundational concepts.
*/

/* eslint-disable padding-line-between-statements */

// #region Working with Errors and asserting -----------------------------------

const isnullish = (val) => val === null || val === undefined
const isNonEmptyString = (val) => typeof val === 'string' && Boolean(val.trim())

/**
 * Create a named Error instance with an optional detail object.
 * @param {string} name
 * @param {{ message?:string, detail?: {} }} [params]
 */
const err = (name, { message = name, detail = {} } = {}) => {
  if (!isNonEmptyString(name)) throw new Error('name must be a non-empty string')
  if (!isnullish(message) && !isNonEmptyString(message)) throw new Error('message must be a non-empty string')
  message = message || name
  const e = Object.assign(new Error(message), { name, message, detail: { ...detail } })

  e.stack = e.stack
    .split('\n')
    .slice(2)
    .join('\n')

  return e
}

/**
 * Asserts the val is truthy. If val is falsey then throws an AssertionError
 * when message string is provided, otherwise throws the provided error.
 * @param {any} val
 * @param {string|Error} [messageOrError]
 */
const assert = (val, messageOrError = 'Assert failed') => {
  if (!val && typeof messageOrError === 'string') {
    const e = err('AssertionError', { message: messageOrError })

    e.stack = e.stack
      .split('\n')
      .filter((s) => !s.includes('at assert '))
      .join('\n')

    throw e
  }

  if (!val) throw messageOrError
}

exports.err = err
exports.assert = assert

// #endregion

// #region Working with Functions ----------------------------------------------

/** Attempt to call a function with args and return its result. If fn is not a function then return fn. */
const call = (fn, ...args) => typeof fn === 'function' ? fn(...args) : fn

/**
 * Partially applies arguments to a function and returns a new function.
 * @param {Function} fn
 * @return {Function}
 */
const partial = (fn, ...args) => {
  assert(typeof fn === 'function', 'fn must be a function')
  return fn.bind(undefined, ...args)
}

/**
 * Creates and returns a new function that juxtaposes a list of functions by calling
 * each function on the arguments.
 * @example juxt(double, triple)(1) // [2, 3]
 * @example juxt(evenFilter, oddFilter)([1, 2, 4]) // [[2, 4], [1]]
 * @param {Array<Function|Function[]>} fns
 * @return {(...args) => any[]}
 */
const juxt = (...fns) => {
  fns = [].concat(...fns)
  assert(fns.every((fn) => typeof fn === 'function'), 'fn must be a function')
  return (...args) => fns.map((fn) => fn(...args))
}

/**
 * Creates and returns a function that pipes a list of functions, passing the return
 * value from the previous function as the argument to the next function. The first
 * function will be called with the arguments.
 * @example pipe((a, b) => a, double)(2, 3) // 4
 * @param {Array<Function|Function[]>} fns
 * @return {(...args) => any}
 */
const pipe = (...fns) => {
  fns = [].concat(...fns)
  assert(fns.every((fn) => typeof fn === 'function'), 'fn must be a function')
  return (...args) => {
    if (!fns.length) return null
    return fns.slice(1).reduce((acc, fn) => fn(acc), fns[0](...args))
  }
}

/**
 * Creates and returns a function that takes an argument, calls fn with it as
 * an argument then returns the argument. Useful for tapping a pipe.
 * @example pipe((a, b) => a, tap(console.log), double, tap(console.log))(3) // 6
 * @param {(x) => any} fn
 * @return {(x) => any}
 */
const tap = (fn) => {
  assert(typeof fn === 'function', 'fn must be a function')
  return (x) => [fn(x), x][1]
}

/**
 * Creates and returns a dynamically curried function.
 * A curried function is a sequence of functions that each takes one or more arguments.
 * @example
 * const add = curry((a, b) => a + b)
 * add(5)(4) // 9
 * add(5, 4) // 9
 * @param {(...args) => any}
 * @return {(...args) => any}
 */
const curry = (fn) => {
  assert(typeof fn === 'function', 'fn must be a function')
  assert(fn.length > 0, 'fn must have formal arguments')

  return function curried (...args) {
    assert(args.length <= fn.length, 'too many arguments provided to function')
    if (args.length === fn.length) return fn(...args)
    return curried.bind(undefined, ...args)
  }
}

exports.call = call
exports.partial = partial
exports.juxt = juxt
exports.pipe = pipe
exports.tap = tap
exports.curry = curry

// #endregion ------------------------------------------------------------------

// #region Working with Objects and Maps ---------------------------------------

/**
 * Attempts to retrieve a key path from the map or object.
 * @example get({ age: 3 }, 'age') // 3
 * @example get({ age: 3 }, 'nope') // null
 * @example get({ child: { age: 4 } }, ['child', 'age']) // 4
 * @example get(new Map([['one', 1]]), 'one') // 1
 * @param {Map|{}|any} map
 * @param {string|symbol|any|Array<string|symbol|any>}
 * @return {any?}
 */
const get = (map, path) => {
  if (!Array.isArray(path)) path = [path]

  for (const key of path) {
    if (key === undefined) continue

    if (map === null || map === undefined) {
      map = null
    } else if (map instanceof Map) {
      ((key === '' && map.has(key)) || key !== '') && (map = map.get(key))
    } else {
      ((key === '' && key in map) || key !== '') && (map = map[key])
    }
  }

  return map
}

/**
 * Attempts to set the key path on a map or object inplace and returns the map or null
 * if the key path cannot be set. Attempts to create objects along the path when a key is undefined.
 * @example set({}, 'age', 4) // { age: 4 }
 * @example set({}, ['child', 'age'], 5) // { child: { age: 5 } }
 * @example set({}, '', 5) // 5
 * @example set({}, [], 5) // 5
 * @example set({}, [''], 5) // 5
 * @param {Map|{}|any} map
 * @param {string|symbol|any|Array<string|symbol|any>}
 * @param {any} val
 * @return {any?} The modified object or map
 */
const set = (map, path, val) => {
  if (!Array.isArray(path)) path = [path]
  const isempty = (val) => [null, undefined, ''].includes(val)
  if (!path.length || (path.length === 1 && isempty(path[0]))) return val

  let o = map
  for (const key of path.slice(0, -1)) {
    if (isempty(key)) continue
    if (o === null || o === undefined) o = null
    else if (o instanceof Map && o.has(key)) o = o.get(key)
    else if (o instanceof Map && !o.has(key)) o = o.set(key, {}).get(key)
    else if (o && o[key] !== undefined) o = o[key]
    else if (o && o[key] === undefined) o = o[key] = {}
  }

  const key = path[path.length - 1]
  if (!o || isempty(key)) return null
  if (o instanceof Map) o.set(key, val)
  else o[key] = val

  return map
}

/**
 * Retrieve the entries of an object or Map.
 * @example entries({one:1}) // [['one', 1]]
 * @example entries(new Map([['one',1]])) // [['one', 1]]
 * @example entries([1, 2]) // [[0, 1], [1, 2]]
 * @example entries(null) // []
 * @example entries(4) // []
 * @return {[key:any, val:any][]}
 */
const entries = (map) => {
  if (map instanceof Map) return [...map.entries()]
  if (map === null || map === undefined) return []
  return Object.entries(map)
}

/** Retrieve the keys of an object or Map. */
const keys = (map) => entries(map).map(([k]) => k)
/** Retrieve the values of an object or Map. */
const values = (map) => entries(map).map(([_, v]) => v)
/** Determines if an object or Map has a key. */
const hasKey = (map, key) => map instanceof Map ? map.has(key) : (map ? map[key] !== undefined : false)

/**
 * Retrieves keys from an object or map and returns an object with the selected keys.
 * If map is a Map instance then returns a Map instance.
 * @example selectKeys({ one: 1, two: 2 }, ['two']) // { two: 2 }
 * @param {any} map
 * @param {any[]} keys
 * @param {{}}
 */
const selectKeys = (map, keys) => {
  assert(Array.isArray(keys), 'keys must be an array')
  return exports.keys(map).reduce((entries, key) => {
    if (keys.includes(key)) set(entries, key, get(map, key))
    return entries
  }, map instanceof Map ? new Map() : {})
}

/**
 * Retrieves an inverse of keys from an object or map and returns an object with the selected keys.
 * If map is a Map instance then returns a Map instance.
 * @example selectKeysInverse({ one: 1, two: 2 }, ['two']) // { one: 1 }
 * @param {any} map
 * @param {any[]} keys
 * @param {{}}
 */
const selectKeysInverse = (map, keys) => selectKeys(map, exports.keys(map).filter((k) => !keys.includes(k)))

exports.get = get
exports.set = set
exports.entries = entries
exports.keys = keys
exports.values = values
exports.hasKey = hasKey
exports.selectKeys = selectKeys
exports.selectKeysInverse = selectKeysInverse

// #endregion ------------------------------------------------------------------

// #region Working with Values -------------------------------------------------

/**
 * Perform a deep equality check against two values.
 * If two objects have an "eq(other):boolean" method then these methods will be called
 * to determine equality between the two objects.
 */
const eq = (a, b) => {
  if (a === b) return true
  if (a && b && typeof a.eq === 'function' && typeof b.eq === 'function') return a.eq(b) && b.eq(a)
  if (a instanceof Date && b instanceof Date) return a.getTime() === b.getTime()
  if (a instanceof RegExp && b instanceof RegExp) return a.toString() === b.toString()
  if (Array.isArray(a) && Array.isArray(b)) return a.length === b.length && a.every((it, i) => eq(it, b[i]))
  if (a && typeof a[Symbol.iterator] === 'function' && b && typeof b[Symbol.iterator] === 'function') return eq([...a], [...b])
  if (a && typeof a === 'object' && b && typeof b === 'object') return eq(entries(a), entries(b))
  return false
}

/**
 * Perform a shallow copy of a value. The type of the value is preserved when
 * it's a Map, Set, Date, RegExp, array, or a typed array. When the value is an
 * iterable it will be copied to an array. Otherwise the value will be copied to
 * a new object when it's an object.
 */
const copy = (val) => {
  if (typeof val === 'string') return val
  if (val instanceof Map) return new Map(val)
  if (val instanceof Set) return new Set(val)
  if (val instanceof Date) return new Date(val.getTime())
  if (val instanceof RegExp) return new RegExp(val.toString().split('/').slice(1, -1).join('/'), val.flags)
  if (val instanceof Int8Array) return Int8Array.from(val)
  if (val instanceof Uint8Array) return Uint8Array.from(val)
  if (val instanceof Uint8ClampedArray) return Uint8ClampedArray.from(val)
  if (val instanceof Int16Array) return Int16Array.from(val)
  if (val instanceof Int32Array) return Int32Array.from(val)
  if (val instanceof Uint32Array) return Uint32Array.from(val)
  if (val instanceof Float32Array) return Float32Array.from(val)
  if (val instanceof Float64Array) return Float64Array.from(val)
  // NOTE: These are relatively new and we likely will not use them
  // if (val instanceof BigInt64Array) return BigInt64Array.from(val)
  // if (val instanceof BigUint64Array) return BigUint64Array.from(val)
  if (val && typeof val[Symbol.iterator] === 'function') return [...val]
  if (val && typeof val === 'object') return { ...val }
  return val
}

/**
 * Perform a deep copy of a value. Map, Set, Date, RegExp, array, and typed arrays
 * will be copied to an instance of the same type. Iterables will be copied to a
 * new array. Objects will be copied to a new object.
 */
const clone = (val) => {
  if (typeof val === 'string') return val
  if (val instanceof Map) return new Map([...val].map(([k, v]) => [k, clone(v)]))
  if (val instanceof Set) return new Set([...val].map(clone))
  if (val instanceof Date) return new Date(val.getTime())
  if (val instanceof RegExp) return new RegExp(val.toString().split('/').slice(1, -1).join('/'), val.flags)
  if (val instanceof Int8Array) return Int8Array.from(val)
  if (val instanceof Uint8Array) return Uint8Array.from(val)
  if (val instanceof Uint8ClampedArray) return Uint8ClampedArray.from(val)
  if (val instanceof Int16Array) return Int16Array.from(val)
  if (val instanceof Int32Array) return Int32Array.from(val)
  if (val instanceof Uint32Array) return Uint32Array.from(val)
  if (val instanceof Float32Array) return Float32Array.from(val)
  if (val instanceof Float64Array) return Float64Array.from(val)
  // NOTE: These are relatively new and we likely will not use them
  // if (val instanceof BigInt64Array) return BigInt64Array.from(val)
  // if (val instanceof BigUint64Array) return BigUint64Array.from(val)
  if (Array.isArray(val)) return val.map(clone)
  if (val && typeof val[Symbol.iterator] === 'function') return [...val].map(clone)
  if (val && typeof val === 'object') return asobj(entries(val).map(([k, v]) => [k, clone(v)]))
  return val
}

/**
 * Always converts a value to an object no matter its type.
 *
 * Can accept an array of key:value tuples, the kind passed to the Map constructor.
 * Non-string, -symbol keys will be converted to string when assigning to the object.
 *
 * RegExp is converted to an object like
 *
 *   { pattern:string, flags:string }
 *
 * Date is converted to an object like (all components are in UTC)
 *
 *   { year, month, date, hours, minutes, seconds, milliseconds }
 *
 * @example asobj(null) // {}
 * @example asobj(4) // {}
 * @example asobj('hi') // {}
 * @example asobj([]) // {}
 * @example asobj([1, 2]) // { '0': 1, '1': 2 }
 * @example asobj(new Map([[1, 2]])) // { '1': 2 }
 * @example asobj(/hi/u) // { pattern: 'hi', flags: 'u' }
 * @return {{}}
 */
const asobj = (val) => {
  if (Array.isArray(val) && !val.length) return {}
  if (typeof val === 'string') return {}

  if (Array.isArray(val)) {
    return val.reduce((acc, v, k) => {
      if (Array.isArray(v) && v.length === 2) ([k, v] = v)
      return Object.assign(acc, { [k]: v })
    }, {})
  }

  if (val instanceof RegExp) {
    return {
      pattern: val.toString().split('/').slice(1, -1).join('/'),
      flags: val.flags
    }
  }

  if (val instanceof Date) {
    return {
      year: val.getUTCFullYear(),
      month: val.getUTCMonth(),
      date: val.getUTCDate(),
      hours: val.getUTCHours(),
      minutes: val.getUTCMinutes(),
      seconds: val.getUTCSeconds(),
      milliseconds: val.getUTCMilliseconds()
    }
  }

  if (val instanceof Map) return asobj([...val])

  return { ...val }
}

/**
 * Always converts a value to an array no matter its type.
 *
 * @example asarr([]) // []
 * @example asarr(4) // [4]
 * @example asarr(null) // []
 * @example asarr('hi') // ['hi']
 * @example asarr(new Map([['one', 1]])) // [['one', 1]]
 * @example asarr(new Set(['one', 1])) // ['one', 1]
 * @example asarr({ one: 1, two: 2 }) // [['one', 1], ['two', 2]]
 * @return {any[]}
 */
const asarr = (val) => {
  if (val === null || val === undefined) return []
  if (typeof val === 'string') return [val]
  if (val instanceof Map) return [...val]
  if (val instanceof Set) return [...val]
  if (val && typeof val[Symbol.iterator] === 'function') return [...val]

  if (val && typeof val === 'object') {
    return entries(val).sort((a, b) => a[0].localeCompare(b[0]))
  }

  return [val]
}

/**
 * Compares the keys and values of two object or Maps using eq(), then returns a
 * diff object that identifies the keys that have had their values added, changed
 * or removed with respect to the "a".
 *
 * @example diff(null, { two:2 }) // { added: { two:2 }, changed: {}, removed: {} }
 * @example diff({ one:1 }, { two:2 }) // { added: { two:2 }, changed: {}, removed: { one:1 } }
 * @return {{ added:{}, changed:{}, removed:{} }}
 */
const diff = (a, b) => {
  if (a === b) return { added: {}, removed: {}, changed: {} }
  if (a instanceof Date && b instanceof Date) return diff(asobj(a), asobj(b))
  if (a instanceof RegExp && b instanceof RegExp) return diff(asobj(a), asobj(b))

  const aentries = entries(a)
  const bentries = entries(b)

  return {
    added: asobj(bentries.filter(([bk]) => !aentries.find(([ak]) => ak === bk))),
    removed: asobj(aentries.filter(([ak]) => !bentries.find(([bk]) => ak === bk))),
    changed: asobj(bentries.filter(([bk, bv]) => aentries.filter(([ak]) => ak === bk).some(([, av]) => !eq(av, bv))))
  }
}

exports.eq = eq
exports.copy = copy
exports.clone = clone
exports.asobj = asobj
exports.asarr = asarr
exports.diff = diff

// #endregion ------------------------------------------------------------------
