const AddressExtractor = require('./address-extractor')

/**
 * Parses and validates an address text that matches an international mailing
 * address.
 *
 * Optionally, if 'withoutAddressee' is truthy then <ADDRESSEE>
 * will be assumed to not be in the text and instead <STREET> will
 * assume to be the first line in the text.
 *
 * Optionally, if 'postalCodeOptional' is truthy then the postal code
 * will be optional, otherwise the postal code will be required in order
 * for the address to match.
 *
 * See formatting rules for each country's address extractor separately.
 *
 * If 'country' is specified and is recognized then the country's extractor
 * will be used, otherwise if the country is not recognized or if no country is
 * specified then all country extractors will be tried until one matches.
 *
 * The following country values are recognized (case insensitive):
 * - ca, canada = Canada extractor
 * - us, usa, united states, united states of america = American extractor
 *
 * @param {string} text
 * @param {{ withoutAddressee?: boolean, postalCodeOptional?: boolean, country?: string }} [options]
 * @return {ReturnType<typeof import('./address-extractor').Address>}
 */
function parseAddressSync (text, { withoutAddressee = true, postalCodeOptional = false, country = null } = {}) {
  const [addr] = AddressExtractor.address(
    text,
    {
      withoutAddressee,
      postalCodeOptional,
      country
    }
  )

  // Always return an Address instance
  if (!addr) {
    return AddressExtractor.Address()
  } else {
    return addr
  }
}

exports.parseAddressSync = parseAddressSync
