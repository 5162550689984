module.exports = function stateValueToPaths (obj, paths = [], ancestry = []) {
  if (typeof obj === 'string') {
    paths.push([...ancestry, obj].join('.'))
  } else {
    const keys = Object.keys(obj)

    if (keys.length) {
      Object.keys(obj).forEach((key) => {
        paths = stateValueToPaths(obj[key], paths, [...ancestry, key])
      })
    } else {
      paths.push(ancestry.join('.'))
    }
  }

  return paths
}
