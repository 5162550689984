const { Std, Check } = require('../../core')
const { isServiceHours } = require('./isServiceHours')
const { isCalendarDay } = require('./isCalendarDay')
const { isTimeRange } = require('./isTimeRange')
const { pad } = require('./pad')
const { create } = require('./create')

/**
 * Returns a new service hours object with the time range(s) for the specified calendar day set.
 *
 * @param {ServiceHours} serviceHours
 * @param {string|[number,number]|Date} day String like 'MM-DD', [month (1-based), date] tuple, or Date instance (uses local month and date)
 * @param {TimeRange|TimeRange[]} ranges The time range or time ranges to set
 * @return {CalendarDeliveryDay}
 */
function setCalendarDayHours (serviceHours, day, ranges) {
  if (!isServiceHours(serviceHours)) {
    throw Std.err('TypeError', 'Service hours is invalid')
  }

  if (Check.isdate(day)) {
    day = [day.getMonth() + 1, day.getDate()]
  }

  if (Check.and(Check.isarr, Check.of(Check.isint), (x) => x.length === 2)(day)) {
    day = `${pad(day[0])}-${pad(day[1])}`
  }

  if (!isCalendarDay(day)) {
    throw Std.err('ArgumentError', `calendar day is invalid : ${day}`)
  }

  if ((!Array.isArray(ranges) && !isTimeRange(ranges)) || !ranges.every(isTimeRange)) {
    throw Std.err('TypeError', 'ranges are is invalid')
  }

  serviceHours = create(serviceHours)

  for (const dd of serviceHours.calendarDays) {
    if (dd.day === day) {
      dd.ranges = Array.isArray(ranges) ? ranges : [ranges]
      return create(serviceHours)
    }
  }

  serviceHours.calendarDays.push({ day, ranges })
  return create(serviceHours)
}

exports.setCalendarDayHours = setCalendarDayHours
