import React from 'react'
import PropTypes from 'prop-types'

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'

export const Strong = ({
  children,
  style,
  ...rest
}) => {
  return (
    <strong css={[StrongStyles, style]} {...rest}>{children}</strong>
  )
}

export const StrongStyles = {
  fontWeight: 600,
  fontSize: 'inherit'
}

Strong.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object
}
