/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import PropTypes from 'prop-types'

export const MenuItem = ({
  style,
  children
}) => {
  return (
    <li css={itemStyle} style={{ ...style }}>{children}</li>
  )
}

export const itemStyle = css`
  list-style: none;
`

MenuItem.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.number,
  style: PropTypes.object,
  children: PropTypes.node
}
