import React from 'react'
import PropTypes from 'prop-types'
import { AutoComplete, Input, Icon } from 'antd'

export default function AddressAutoComplete ({
  value,
  onChange,
  onError = null,
  disabled = false,
  placeholder = '',
  debounceMs = 800,
  size = 'default',
  style = {},
  forceLoading = false,
  deps
}) {
  const {
    tc,
    logger = null
  } = deps

  const [scratch, setScratch] = React.useState('')
  const [addressResults, setAddressResults] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(false)
  const searchAddressDebounceRef = React.useRef(null)

  React.useEffect(() => {
    setScratch(value)
  }, [value])

  React.useEffect(() => {
    clearTimeout(searchAddressDebounceRef.current)
  }, [])

  const onSearchAddress = React.useCallback((query) => {
    clearTimeout(searchAddressDebounceRef.current)

    if (!query) {
      onChange('')
      setAddressResults([])
      return
    }

    searchAddressDebounceRef.current = setTimeout(async () => {
      try {
        setIsLoading(true)

        const addressList = await tc.addresses.search(query)
        const addressResults = addressList.map(({ text, id, provider }) => ({ text, value: JSON.stringify([provider, id, text]) }))

        setAddressResults(addressResults)
      } catch (error) {
        if (typeof onError === 'function') {
          onError(error)
        }

        if (logger?.error) {
          logger.error('components/AddressAutoComplete', 'error during search', error)
        }
      } finally {
        setIsLoading(false)
      }
    }, debounceMs)
  }, [scratch, setAddressResults])

  async function retrieveAddress ([provider, id, text]) {
    try {
      setIsLoading(true)

      if (id) {
        const addressObj = await tc.addresses.retrieve(id, { provider, text })
        const address = addressObj.toString()
        onChange(address)
      }
    } catch (error) {
      if (typeof onError === 'function') {
        onError(error)
      }

      if (logger?.error) {
        logger.error('components/AddressAutoComplete', 'error during retrieve', error)
      }
    } finally {
      setIsLoading(false)
    }
  }

  const loading = isLoading || forceLoading

  return (
    <React.Fragment>
      <style
        dangerouslySetInnerHTML={{
          __html: `.address-auto-complete > input { padding-right: 11px !important; }`
        }}
      />

      <AutoComplete
        style={style}
        value={scratch}
        loading={false}
        onSearch={onSearchAddress}
        onChange={(value) => setScratch(value)}
        onSelect={(key) => retrieveAddress(JSON.parse(key))}
        placeholder={placeholder}
        disabled={disabled}
        size={size}
        onBlur={() => {
          if (scratch !== value) {
            setScratch(value)
          }
        }}
        dataSource={addressResults}
      >
        <Input
          type='text'
          style={{ cursor: 'auto' }}
          className='address-auto-complete'
          suffix={(
            <Icon
              type={loading ? 'loading' : 'search'}
              style={{
                color: loading ? '#1890ff' : 'transparent',
                backgroundColor: loading ? 'rgba(255, 255, 255, 0.4)' : 'transparent'
              }}
            />
          )}
        />
      </AutoComplete>
    </React.Fragment>
  )
}

AddressAutoComplete.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onError: PropTypes.func,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  debounceMs: PropTypes.number,
  size: PropTypes.oneOf(['default', 'large', 'small']),
  style: PropTypes.object,
  forceLoading: PropTypes.bool,
  deps: PropTypes.exact({
    tc: PropTypes.object.isRequired,
    logger: PropTypes.object
  }).isRequired
}

export { AddressAutoComplete }
