// NOTE: this was experimental to see if were possible to cut-down on the
// amount of re-renders by whitelisting certain data properties. This doesn't
// go quite far enough -- we would need to also whitelist states as well. For
// example, you could have a <WhenState> or current.within(), so you need to
// re-render if those could change.

import * as React from 'react'

export const withMachineContext = (...args) => (props) => {
  let Context = null
  let changeList = null
  let Component = null

  if (args.length === 2) {
    Context = args[0]
    Component = args[1]
  } else if (args.length === 3) {
    Context = args[0]
    changeList = args[1]
    Component = args[2]
  } else {
    throw new TypeError('Unexpected number of arguments')
  }

  const getPartialContext = (ctx) => ({ send: ctx.send })

  const context = React.useContext(Context)
  const partialContextRef = React.useRef(getPartialContext(context))

  React.useEffect(() => {
    partialContextRef.current = getPartialContext(context)
  }, [context])

  const changeData = {}

  for (const changeKey of changeList) {
    changeData[changeKey] = (context.data || {})[changeKey]
  }

  return React.useMemo(() => {
    return (
      <Component
        {...props}
        context={{
          ...partialContextRef.current,
          current: context.current,
          data: changeData
        }}
      />
    )
  }, [...Object.values(changeData), context.current, props])
}
