module.exports = function validateShipmentQuery (shipmentQuery) {
  const { PropTypes } = require('../util')
  const { createModel } = require('../models')

  const ValidData = createModel('ValidData', {
    merchantId: {
      type: PropTypes.id,
      default: null
    },
    driverId: {
      type: PropTypes.id,
      default: null
    },
    searchTerms: {
      type: PropTypes.arrayOf(PropTypes.string),
      default: null
    },
    timestampField: {
      type: PropTypes.string
    },
    startTimestamp: {
      type: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
      ])
    },
    endTimestamp: {
      type: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
      ])
    }
  })

  try {
    ValidData.complete(shipmentQuery)
    return true
  } catch (error) {
    return false
  }
}
