class MerchantDiscountActivationLogic {
  constructor ({
    merchantDiscountLogic,
    maxNumbersOfDeliveriesEligible,
    numberOfDeliveriesWithDiscountApplied,
    totalBudget,
    totalBudgetUsed,
    type
  }) {
    this.merchantDiscountLogic = merchantDiscountLogic

    // Things that we need to conclude whether the activation is exhausted.
    this.maxNumbersOfDeliveriesEligible = maxNumbersOfDeliveriesEligible
    this.totalBudget = totalBudget
    this.numberOfDeliveriesWithDiscountApplied = numberOfDeliveriesWithDiscountApplied
    this.totalBudgetUsed = totalBudgetUsed
    this.type = type
  }

  isExhausted () {
    if (this.type === 'fixed') {
      if (this.maxNumbersOfDeliveriesEligible - this.numberOfDeliveriesWithDiscountApplied === 0) {
        return true
      }
    } else if (this.type === 'percentage') {
      if (this.totalBudget - this.totalBudgetUsed === 0) {
        return true
      }
    }

    return false
  }

  getStatus () {
    if (this.isExhausted()) {
      return 'exhausted'
    }

    return this.merchantDiscountLogic.getStatus()
  }
}

module.exports = exports = MerchantDiscountActivationLogic
