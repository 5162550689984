import React from 'react'
import PropTypes from 'prop-types'
import { CAPABILITIES } from '@trexity/common/capReq/constants'
import { Select, Checkbox, Form } from 'antd'

export default function RequirementInput ({
  requirement,
  value,
  disabled,
  onChange,
  meta = {},
  mode = 'default',
  size
}) {
  switch (`${requirement}-${mode}`) {
    case 'vehicleType-default':
    case 'vehicleType-driver':
    case 'vehicleType-merchant':
      return (
        <React.Fragment>
          <Select
            value={value}
            disabled={disabled}
            onSelect={onChange}
            size={size}
          >
            <Select.Option key='any' value='any'>Any</Select.Option>
            {CAPABILITIES.vehicleType.data
              .map(({ id, title, description }) => (
                <Select.Option key={id} value={id}>
                  {title} {description ? `(${description})` : ''}
                </Select.Option>
              ))}
          </Select>
          {meta.label ? (
            <small style={{ color: '#888' }}>{meta.label}</small>
           ) : null}
        </React.Fragment>
      )

    case 'specificMerchantId-default':
    case 'specificMerchantId-driver':
      if (typeof meta.onSearch !== 'function') {
        throw new TypeError(`Requirement '${requirement}' needs the 'onSearch' meta prop`)
      }

      if (!Array.isArray(meta.merchantList)) {
        throw new TypeError(`Requirement '${requirement}' needs the 'merchantList' meta prop`)
      }

      return (
        <React.Fragment>
          <Select
            showSearch
            value={value}
            disabled={disabled}
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            notFoundContent={null}
            onSearch={meta.onSearch}
            onChange={onChange}
          >
            {[
              { key: '@any', text: '(Any merchant)' },
              ...meta.merchantList.map(({ id, name }) => ({ key: id, text: name }))
            ].map(({ key, text }) => (
              <Select.Option key={key}>{text}</Select.Option>
            ))}
          </Select>
          {meta.label ? (
            <small style={{ color: '#888' }}>{meta.label}</small>
          ) : null}
        </React.Fragment>
      )

    case 'legalAgeAlcohol-default':
    case 'legalAgeAlcohol-driver':
    case 'legalAgeAlcohol-merchant':
    case 'perishable-default':
    case 'perishable-driver':
    case 'perishable-merchant':
    case 'internalTrexityStaff-default':
    case 'internalTrexityStaff-driver':
    case 'internalTrexityStaff-merchant':
    case 'internalTrexityDriverSupport-default':
    case 'internalTrexityDriverSupport-driver':
    case 'internalTrexityDriverSupport-merchant':
    case 'chargeless-default':
    case 'chargeless-driver':
    case 'chargeless-merchant':
    case 'returnReusables-default':
    case 'returnReusables-driver':
    case 'returnReusables-merchant':
    case 'specificMerchantId-merchant':
      return (
        <Checkbox
          name={requirement}
          checked={!!value}
          disabled={disabled}
          onChange={(evt) => onChange(evt.target.checked)}
        >{meta.label || ''}</Checkbox>
      )

    default:
      return (
        <div>Unknown requirement</div>
      )
  }
}

RequirementInput.propTypes = {
  value: PropTypes.any,
  requirement: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  meta: PropTypes.object,
  mode: PropTypes.oneOf(['merchant', 'driver', 'default']),
  size: PropTypes.string
}
