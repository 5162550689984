import { Field } from '../Field/Field'
import { SelectWrapper } from './SelectWrapper'
import { useEffect, useState } from 'react'
import { SelectItem } from './SelectItem'
import PropTypes from 'prop-types'
import { COLORS } from '@trexity/common/color/constants'
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'

export const SelectList = ({
  placeholder,
  items,
  selectedItem = () => { },
  defaultValue,
  disabled = false,
  marginBottom,
  bg = COLORS.WHITE
}) => {
  const [selectOpen, setSelectOpen] = useState(false)
  const [selectedItemInternal, setSelectedItem] = useState(defaultValue)

  useEffect(() => {
    setSelectedItem(defaultValue)
  }, [defaultValue])

  return (
    <div
      css={{ position: 'relative', width: '100%' }}
      onBlur={
        (e) => {
          setTimeout(() => {
            setSelectOpen(false)
          }, 150)
        }
      }
    >
      <div css={{ cursor: 'pointer', width: '100%' }}>
        <Field
          marginBottom={marginBottom}
          disabled={disabled}
          animatingIn
          label={placeholder}
          placeholder={placeholder}
          value={selectedItemInternal ? (selectedItemInternal.label || selectedItemInternal) : ''}
          iconRight={'keyboard_arrow_down_icon'}
          iconRightClick={() => {
            if (!disabled) {
            setSelectOpen(true)
            }
          }}
          onFocus={() => {
            if (!disabled) {
            setSelectOpen(true)
            }
          }}
        />
      </div>
      {selectOpen
        ? (
          <SelectWrapper active={selectOpen}>
            {items.map((item, index) => {
              return (
                <SelectItem
                  onClick={() => {
                    setSelectedItem(item)
                    selectedItem(item, index)
                    setSelectOpen(false)
                  }}
                  key={index}
                >{item.label || item}</SelectItem>
              )
            })}
          </SelectWrapper>
        )
        : null}
    </div>
  )
}

SelectList.propTypes = {
  placeholder: PropTypes.string,
  items: PropTypes.array,
  selectedItem: PropTypes.func,
  defaultValue: PropTypes.any,
  disabled: PropTypes.bool,
  marginBottom: PropTypes.number,
  bg: PropTypes.string
}
