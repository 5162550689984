const isLabelInvalidated = ({
  prevRequirements,
  prevPickupAddress,
  prevOrder,
  requirements,
  pickupAddress,
  order
}) => {
  const fields = [
    ['orderId', 'Order ID'],
    ['numLabels', 'Number of Labels'],
    ['address', 'Delivery Address'],
    ['name', 'Recipient Name']
  ]

  for (let i = 0; i < fields.length; i++) {
    const [field, label] = fields[i]

    if (prevOrder[field] !== order[field]) {
      return [true, `"${label}" has changed`]
    }
  }

  if (pickupAddress) {
    if (prevPickupAddress !== pickupAddress) {
      return [true, 'Pickup address has changed']
    }
  }
  // if (newRequirements) {
  //   const oldKeys = [...new Set(Object.keys(prevRequirements))]
  //   const newKeys = [...new Set(Object.keys(newRequirements))]

  //   if (oldKeys.length !== newKeys.length) {
  //     return [true, '']
  //   }

  //   const allKeysIdentical = oldKeys.every((oldKey) => {
  //     return newKeys.includes(oldKey)
  //   })

  //   if (!allKeysIdentical) {
  //     return [true, '']
  //   }

  //   for (let i = 0; i < oldKeys.length; i++) {
  //     const k = oldKeys[i]

  //     if (prevRequirements[k] !== newRequirements[k]) {
  //       return [true, '']
  //     }
  //   }
  // }

  return [false, '']
}

exports.isLabelInvalidated = isLabelInvalidated
