/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import PropTypes from 'prop-types'
import Icon from '@material-ui/core/Icon'

export const MaterialIcon = ({
  icon,
  style,
  color = 'inherit',
  size = 16
}) => {
  return (
    <Icon css={iconStyle} style={{ color: color, fontSize: size, ...style }}>{icon}</Icon>
  )
}

export const iconStyle = css`
  position: relative;
  vertical-align: middle;
  bottom: 0.1em;
`

MaterialIcon.propTypes = {
  icon: PropTypes.string,
  color: PropTypes.string,
  lineHeight: PropTypes.string,
  size: PropTypes.number,
  style: PropTypes.object
}
