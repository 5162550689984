exports.SHIPMENT_STATUS = {
  WAITING_FOR_ACCEPTANCE: 'Waiting For Acceptance',
  WAITING_TO_START: 'Waiting To Start',
  EN_ROUTE_TO_PICKUP: 'En Route To Pickup',
  ARRIVED_AT_PICKUP: 'Arrived At Pickup',
  OUT_FOR_DELIVERY: 'Out For Delivery',
  ARRIVED_AT_DELIVERY: 'Arrived At Delivery',
  DELIVERED: 'Delivered',
  RENOUNCED_BY_DRIVER: 'Renounced By Driver',
  CANCELLED: 'Cancelled',
  EXPIRED: 'Expired',
  ON_HOLD: 'On Hold'
}

// We rely on having no status docs in the statuses collection as a way to
// indicate that the shipment is sort of in "Draft" mode. This makes it easier
// when saving many times prior to kicking off a shipment to drivers, as we
// don't have to check if we need to insert or update a status document with,
// for example, a "NOT_YET_POSTED" status.

exports.NO_SHIPMENT_STATUS = 'Draft'

exports.LINKED_SHIPMENT_TYPES = {
  RETURN_TO_SENDER: 'return_to_sender',
  RETURN_REUSABLES: 'return_reusables',
  CLONE: 'clone',
  MOVED_ORDERS: 'moved_orders',

  // DEPRECATED
  NONE: '',
  PARTIAL_RENOUNCE_REPLACEMENT: 'partial_renounce_replacement',
  PARTIAL_RENOUNCE_RETURN: 'partial_renounce_return'
}

/* RF: Max. number of waypoints is 25 (excluding origin/destination)
  *
  * Reference:
  * https://developers.google.com/maps/documentation/directions/intro#Waypoints
  *
  */
// Until we pay for Bing Maps Enterprise, don't allow the pickup + delivery
// address count to be any higher than 20
// const MAX_WAYPOINTS = 25
// const MAX_WAYPOINTS = 19
// After a discussion with Rob (Dec 15 2020), merchants making such long
// deliveries hog our driver pool for too long, and doesn't allow us to
// distribute load across multiple drivers
// exports.MAX_DELIVERY_WAYPOINTS = 8
// Up back to 15 based on conversation with Rob (Jan 12 2021) due to Meal Prep
exports.MAX_DELIVERY_WAYPOINTS = 15

exports.RETURN_REUSABLES_ORDERID = '[RtnThermCont]'

exports.INTERNAL_TYPES = {}
