/**
 * Given CSV text, will cause the browser to prompt the user to download and
 * save the text to a file.
 *
 * Will automatically add timestamps to the file basename.
 *
 * @example
 * downloadAsCsv(myCsvText, { fileBasename: 'my-file.csv' }) // prompts user to download 'my-file[-{date}][-{endDate}].csv'
 * downloadAsCsv(myCsvText, { fileBasename: 'my-file.txt' }) // prompts user to download 'my-file[-{date}][-{endDate}].csv'
 * downloadAsCsv(myCsvText, { fileBasename: 'my-file.something' }) // prompts user to download 'my-file[-{date}][-{endDate}].something'
 * @param {string} csvText
 * @param {Object} [options]
 * @param {string} [options.fileBasename]
 */
export const downloadAsCSV = (
  csvText,
  {
    fileBasename = 'file.csv',
    date = new Date(),
    endDate = null
  } = {}
) => {
  const ymd = (d) => `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, '0')}-${String(d.getDate()).padStart(2, '0')}`

  const downloadFilename = [
    fileBasename.split('.').slice(0, -1).join('.'),
    // Optionally add date
    date && `-${ymd(date)}`,
    // Optionally add endDate
    endDate && `-${ymd(endDate)}`,
    // Put original extension back
    `.${fileBasename.split('.').slice(-1)[0]}`
  ].join('')

  const el = document.createElement('a')

  try {
    el.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvText)
    el.target = '_blank'
    el.download = downloadFilename
    el.click()
  } catch (_) {
    // Do nothing
  } finally {
    el.remove()
  }
}
