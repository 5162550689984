module.exports = (Model) => (obj) => {
  const shipment = Model.partial(obj, Model.CLIENT_READ)

  return {
    ...shipment,

    cachedActualMerchantName: shipment.cachedMerchantName,
    cachedMerchantName: shipment.partnerData
      ? (shipment.partnerData.name || shipment.cachedMerchantName)
      : shipment.cachedMerchantName,

    cachedActualMerchantPhone: shipment.cachedMerchantPhone,
    cachedMerchantPhone: shipment.partnerData
      ? shipment.partnerData.phone || ''
      : shipment.cachedMerchantPhone,

    cachedActualMerchantEmail: shipment.cachedMerchantEmail,
    cachedMerchantEmail: shipment.partnerData
      ? shipment.partnerData.email || ''
      : shipment.cachedMerchantEmail
  }
}
