import PropTypes from 'prop-types'
import { COLORS } from '@trexity/common/color/constants'

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'

export const P = ({
  children,
  lede,
  light,
  style,
  ...rest
}) => {
  return (
    <p css={[ParagraphStyles(lede, light), style]} {...rest}>{children}</p>
  )
}

export const ParagraphStyles = (lede, light) => ({
  marginTop: 0,
  fontSize: lede ? '20px' : '16px',
  lineHeight: '1.4em',
  color: light ? COLORS.WHITE : COLORS.DARK
})

P.propTypes = {
  lede: PropTypes.bool,
  light: PropTypes.bool,
  children: PropTypes.node,
  style: PropTypes.object
}
