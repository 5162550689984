/**
 * Standardize the display of distance throughout the packages.
 * @param {number} distanceInMeters
 * @param {number} [digits=1]
 * @returns {string|object}
 */
module.exports = function getFormattedDistanceString (distanceInMeters, digits = 1, asComponents = false) {
  if (distanceInMeters > 1000) {
    const km = Number(distanceInMeters / 1000).toFixed(digits)
    return asComponents ? {
      distance: `${km}`,
      unit: `kilometer${Number(km) === 1 ? '' : 's'}`
    } : `${km}\u00A0km`
  }

  const meters = Math.round(Number(distanceInMeters))
  return asComponents ? {
    distance: `${meters}`,
    unit: `meter${meters === 1 ? '' : 's'}`
  } : `${meters}\u00A0m`
}
