import React from 'react'
import PropTypes from 'prop-types'
import { COLORS } from '@trexity/common/color/constants'

export default function MarkerDot ({
  index,
  size = 20,
  color = COLORS.TREXITY_RED,
  textColor = COLORS.WHITE,
  style = {}
}) {
  const charCode = 65 + index % 26
  const letter = String.fromCharCode(charCode)
  const fontSize = Math.round(size * 0.55)
  const lineHeight = size

  return (
    <span
      style={{
        ...style,
        display: 'inline-block',
        backgroundColor: color,
        width: `${size}px`,
        height: `${size}px`,
        borderRadius: `${size}px`,
        fontSize: `${fontSize}px`,
        lineHeight: `${lineHeight}px`,
        fontWeight: 'bold',
        color: textColor,
        textAlign: 'center'
      }}
    >
      {letter}
    </span>
  )
}

MarkerDot.propTypes = {
  index: PropTypes.number.isRequired,
  size: PropTypes.number,
  color: PropTypes.string,
  textColor: PropTypes.string,
  style: PropTypes.object
}
