import { stateValueLeafIds } from '../util'

function whenStateCondition (current, matches = null, is = null, within = null) {
  if (matches) {
    return Array.isArray(matches)
      ? matches.some((value) => current.matches(value))
      : current.matches(matches)
  } else if (is) {
    return Array.isArray(is)
      ? is.some((value) => stateValueLeafIds(current.value).includes(value))
      : stateValueLeafIds(current.value).includes(is)
  } else if (within) {
    const states = current.toStrings()

    if (Array.isArray(within)) {
      return within.some((value) => {
        const count = value.split('.').length
        return states.some((state) => state.split('.').slice(-count).join('.') === value)
      })
    } else {
      const count = within.split('.').length
      return states.some((state) => state.split('.').slice(-count).join('.') === within)
    }
  } else {
    throw new Error('matches, is, or within must be specified')
  }
}

export function WhenState ({ current, matches = null, is = null, within = null, children }) {
  const condition = whenStateCondition(current, matches, is, within)
  return condition ? children : null
}

export function WhenNotState ({ current, matches = null, is = null, within = null, children }) {
  const condition = whenStateCondition(current, matches, is, within)
  return !condition ? children : null
}
