class MerchantDiscountLogic {
  constructor ({
    suspended,
    numberOfAvailableSeats,
    numberOfDeliveries,
    validFromAt,
    validToAt,
    totalBudget,
    numberOfSeats,
    type
  }) {
    this.suspended = Boolean(suspended)
    this.numberOfAvailableSeats = numberOfAvailableSeats
    // Takes care of ISO string and an actual Date instance:
    this.validFromAt = new Date(validFromAt)
    this.validToAt = new Date(validToAt)
    this.totalBudget = totalBudget
    this.numberOfSeats = numberOfSeats
    this.type = type
    this.numberOfDeliveries = numberOfDeliveries
  }

  isSuspended () {
    return this.suspended === true
  }

  hasSeatsAvailable () {
    return this.numberOfAvailableSeats > 0
  }

  canBeActivatedOnDate (date) {
    const msec = date.getTime()

    return (msec < this.validToAt.getTime())
  }

  canBeActivated () {
    return !this.isSuspended() &&
      this.hasSeatsAvailable() &&
      this.canBeActivatedOnDate(new Date())
  }

  getIndividualMerchantBudget () {
    return this.totalBudget / this.numberOfSeats
  }

  getAllocatedBudget () {
    return this.getIndividualMerchantBudget() * (this.numberOfSeats - this.numberOfAvailableSeats)
  }

  getRemainingBudget () {
    return this.getIndividualMerchantBudget() * (this.numberOfAvailableSeats)
  }

  getStatus () {
    const now = Date.now()
    const validFromAt = new Date(this.validFromAt).getTime()
    const validToAt = new Date(this.validToAt).getTime()

    if (this.suspended) {
      return 'suspended'
    }

    if (validFromAt < now && validToAt > now) {
      return 'open'
    } else if (validToAt < now) {
      return 'closed'
    } else if (now < validFromAt) {
      return 'pending'
    }
  }

  getNumberOfActivations () {
    return this.numberOfSeats - this.numberOfAvailableSeats
  }

  getActivationsAsPercentage () {
    return 100 * this.getNumberOfActivations() / this.numberOfSeats
  }

  // Only makes sense for type === fixed
  getDiscountPerDelivery () {
    return this.getIndividualMerchantBudget() / this.numberOfDeliveries
  }
}

module.exports = exports = MerchantDiscountLogic
