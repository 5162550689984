/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import PropTypes from 'prop-types'
import { COLORS } from '@trexity/common/color/constants'
import { H5 } from '../Typography/Headings/H5'
import Icon from '@material-ui/core/Icon'

export const Menu = ({
  style,
  color = COLORS.TREXITY_YELLOW,
  label = 'label',
  children
}) => {
  return (
    <div>
      <H5 style={{ color: color, marginBottom: 5, ...style }}>{label}</H5>
      <ul css={listContainer}>
        {children}
      </ul>
    </div>
  )
}

export const listContainer = css`
  list-style: none;
  padding: 0;
  margin: 5px 0 30px 0;
`

Menu.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.number,
  style: PropTypes.object,
  children: PropTypes.node
}
