import React from 'react'
import PropTypes from 'prop-types'
import { TRANSACTION_TYPE_LABELS } from '@trexity/common/pricing/constants'
import { getFormattedDateAndTimeFromDate } from '@trexity/common/temporal'
import { getFormattedMonetaryValueString } from '@trexity/common/pricing'

import {
  Skeleton,
  Icon,
  Button,
  Table
} from 'antd'

export default function Transactions ({
  isAdmin = false,
  deps
}) {
  const {
    current,
    send,
    data
  } = deps

  const {
    shipmentDashboardTransactions: transactions
  } = data

  const isLoading = current.within('Loading Transactions')
  const isReady = current.within('Showing Transactions')

  const columns = [
    { title: 'Type', dataIndex: 'type', key: 'type' },
    { title: 'Amount', dataIndex: 'amount', key: 'amount' },
    { title: 'Date', dataIndex: 'date', key: 'date' },
    { title: 'Status', dataIndex: 'status', key: 'status' },
    { title: 'Note', dataIndex: 'note', key: 'note' },
    { title: 'Receipt', dataIndex: 'receipt', key: 'receipt' },
    { title: 'ID', dataIndex: 'chargeId', key: 'chargeId' }
  ]

  const dataSource = (transactions || []).map((tx) => {
    let status = null

    if (tx.type === 'preauth' || tx.type === 'preauth-reversal' || tx.type === 'refund' || tx.type === 'transfer-reversal') {
      if ((tx.type === 'transfer-reversal' || (tx.type === 'preauth' ? tx.data.paid : tx.data.status === 'succeeded'))) {
        status = <label><Icon type='check-circle' style={{ color: 'green', marginRight: 5 }} />OK</label>
      } else {
        <label><Icon type='close-circle' style={{ color: 'red', marginRight: 5 }} />Failed</label>
      }
    } else if (tx.type === 'transfer' || tx.type === 'transfer-manual' || tx.type === 'transfer-customer-tip' || tx.type === 'transfer-discretionary' || tx.type === 'transfer-driver-challenge' || tx.type === 'transfer-driver-referral' || tx.data.paid) {
      status = <label><Icon type='check-circle' style={{ color: 'green', marginRight: 5 }} />OK</label>
    } else if (tx.type === 'pending-transfer-request') {
      status = <label><Icon type='clock-circle' style={{ color: 'orange', marginRight: 5 }} />Pending</label>
    } else {
      status = <label><Icon type='close-circle' style={{ color: 'red', marginRight: 5 }} />Failed</label>
    }

    const receipt = tx.data.receipt_url ? (
      <a href={tx.data.receipt_url} target='_blank' rel='noopener noreferrer'>View</a>
    ) : (
      <span>N/A</span>
    )

    return {
      key: tx.id,
      type: TRANSACTION_TYPE_LABELS[tx.type],
      amount: tx.type === 'penalty'
        ? getFormattedMonetaryValueString(tx.data.amount - tx.data.amount_refunded)
        : getFormattedMonetaryValueString(tx.data.amount),
      date: getFormattedDateAndTimeFromDate(tx.createdAt),
      chargeId: <span style={{ fontSize: 12 }}>{tx.data.id}</span>,
      note: tx.note || 'N/A',
      status,
      receipt
    }
  })

  return (
    isReady ? (
      <React.Fragment>
        <Table
          size='small'
          dataSource={dataSource}
          columns={columns}
          loading={isLoading}
          scroll={{ x: true }}
        />
        <Button
          disabled={isLoading}
          loading={isLoading}
          icon='reload'
          type='ghost'
          onClick={() => send('reload transactions')}
          style={dataSource.length ? { top: -40 } : { marginTop: 10 }}
        >
          Reload
        </Button>
      </React.Fragment>
    ) : (
      <Skeleton active />
    )
  )
}

Transactions.propTypes = {
  isAdmin: PropTypes.bool,
  deps: PropTypes.exact({
    send: PropTypes.func,
    current: PropTypes.object,
    data: PropTypes.object,
    tc: PropTypes.object
  }).isRequired
}
