/**
 * Allows history management of state/event changes (browser agnostic)
 * Eg. usage:
 * const {
    sendInitialRoutingEvent,
    listen,
    updateHistory,
    constructPathnameAndSearch,
    getCurrentRouteParams,
    getCurrentRoute
  } = EventHistory({
    PathToRegexp: { pathToRegexp, compile },
    history,
    routes,
    getPathname: (location = window.location) => (location.pathname),
    getSearch: (location = window.location) => (location.search)
  })
 * @param {function} pathToRegexp - from "path-to-regexp" library: https://www.npmjs.com/package/path-to-regexp
 * @param {function} compile - from "path-to-regexp" library
 * @param {{ listen: (function), replace: (function), push: (function) }} history - custom defined
 * or generated from "createBrowserHistory()" in the browser history API
 * @param {object} routes - eg. object: {
 *   <key>: {
 *     state: <label>,
 *     route: <unique string>,
 *     event: <event name>,
 *     options: { <key1>: {}, <key2>: { default: <value> }, ... }
*    },
 *   ...
 * }
 * @param {function} getPathname
 * @param {function} getSearch
 * @param {function} setTitle=null
 * @returns {{getCurrentRouteParams: (function(*=): *), getCurrentRoute: (function(*=): (*|null)), sendInitialRoutingEvent: sendInitialRoutingEvent, constructPathnameAndSearch: (function(*, *=, *=): [undefined, (string|string)]), listen: (function(*=): *), updateHistory: updateHistory}}
 * @constructor
 */
export function EventHistory ({
  PathToRegexp: {
    pathToRegexp,
    compile
  },
  history,
  routes,
  getPathname,
  getSearch,
  setTitle = null
}) {
  const findRouteByKeyOrState = (routeList, key) => {
    return (
      routeList[key] ||
      Object.values(routeList).find((route) => route.state === key)
    )
  }

  const matchPathname = (route, pathname, params) => {
    const keys = []
    const pattern = pathToRegexp(route, keys)
    const match = pattern.exec(pathname)

    if (!match) {
      return false
    }

    for (let i = 1; i < match.length; i += 1) {
      if (i - 1 < keys.length) {
        params[keys[i - 1].name] = match[i] !== undefined ? match[i] : undefined
      }
    }

    return true
  }

  const matchSearch = (options, search, params) => {
    const searchParams = new URLSearchParams(search)

    for (const [key, option] of Object.entries(options)) {
      if (searchParams.has(key)) {
        const result = searchParams.getAll(key)
        params[key] = result.length > 1 ? result : result.length ? result[0] : null
      } else if ('default' in option && option.default) {
        params[key] = option.default
      }
    }

    return true
  }

  const match = (route, options, pathname, search) => {
    const params = {}

    if (!matchPathname(route, pathname, params)) {
      return false
    }

    if (options) {
      if (!matchSearch(options, search, params)) {
        return false
      }
    }

    return params
  }

  const attemptSendRouteEvent = (send, pathname, search) => {
    let params = null

    const key = Object.keys(routes).find((key) => {
      const route = routes[key]
      params = match(route.route, route.options, pathname, search)
      return Boolean(params)
    })

    if (key) {
      const route = routes[key]
      send({ type: route.event, reachedByHistory: true, ...(params || {}) })
    }
  }

  let lastPathname = getPathname()
  let lastSearch = getSearch()

  const sendInitialRoutingEvent = (send) => {
    attemptSendRouteEvent(send, getPathname(), getSearch())
  }

  const listen = (send) => history.listen((location, action) => {
    const pathname = getPathname(location)
    const search = getSearch(location)

    if (lastPathname !== pathname || lastSearch !== search) {
      lastPathname = pathname
      lastSearch = search
      attemptSendRouteEvent(send, pathname, search)
    }
  })

  const constructTitle = (key, params = {}, routeList = routes) => {
    const route = findRouteByKeyOrState(routeList, key)
    return typeof route.title === 'function' ? route.title(params) : (route.title || '')
  }

  const updateHistory = (key, params = {}, replace = false) => {
    const [pathname, search] = constructPathnameAndSearch(key, params)

    if (lastPathname !== pathname || lastSearch !== search) {
      const pathnameAndSearch = [pathname, search].join('')

      if (replace) {
        history.replace(pathnameAndSearch)
      } else {
        history.push(pathnameAndSearch)
      }
    }

    if (typeof setTitle === 'function') {
      setTitle(constructTitle(key, params))
    }
  }

  const toSearch = (options, params) => {
    const searchParams = new URLSearchParams()

    Object.entries(options || {}).forEach(([key, option]) => {
      const value = (
        key in params ? params[key]
          : 'default' in option ? (typeof option.default === 'function' ? option.default(new URLSearchParams(getSearch())) : option.default)
            : undefined
      )

      if (value !== undefined) {
        if (Array.isArray(value)) {
          value.forEach((val, index) => {
            searchParams.append(key, val)
          })
        } else {
          searchParams.append(key, value)
        }
      }
    })

    const search = searchParams.toString()

    return search ? `?${search}` : ''
  }

  const constructPathnameAndSearch = (key, params = {}, routeList = routes) => {
    const route = findRouteByKeyOrState(routeList, key)

    const toPathname = compile(route.route)

    const onlyPathnameParams = Object.entries(params)
      .filter(([key]) => !(key in (route.options || {})))
      .reduce((memo, [key, value]) => ({ ...memo, [key]: value }), {})

    const onlySearchParams = Object.entries(params)
      .filter(([key]) => (key in (route.options || {})))
      .reduce((memo, [key, value]) => ({ ...memo, [key]: value }), {})

    return [toPathname(onlyPathnameParams), toSearch(route.options, onlySearchParams)]
  }

  const getCurrentRouteParams = (routeList = routes) => {
    const route = getCurrentRoute(routeList)

    const result = route
      ? match(route.route, route.options, getPathname(), getSearch())
      : {}

    return result
  }

  const getCurrentRoute = (routeList = routes) => {
    const routeKeys = Object.keys(routeList)

    const routeKey = routeKeys.find((key) => {
      const route = routeList[key]
      return Boolean(match(route.route, route.options, getPathname(), getSearch()))
    })

    if (routeKey) {
      return routeList[routeKey]
    }

    return null
  }

  return {
    sendInitialRoutingEvent,
    listen,
    updateHistory,
    constructPathnameAndSearch,
    getCurrentRouteParams,
    getCurrentRoute
  }
}
