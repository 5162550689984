module.exports = function getOrientation (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.readAsArrayBuffer(file)

    reader.addEventListener('error', (err) => reject(err))

    reader.addEventListener('load', () => {
      const view = new DataView(reader.result)

      if (view.getUint16(0, false) !== 0xFFD8) {
        return resolve(-2)
      }

      const length = view.byteLength
      let offset = 2

      while (offset < length) {
        if (view.getUint16(offset + 2, false) <= 8) {
          return resolve(-1)
        }

        const marker = view.getUint16(offset, false)
        offset += 2

        if (marker === 0xFFE1) {
          if (view.getUint32(offset += 2, false) !== 0x45786966) {
            return resolve(-1)
          }

          const little = view.getUint16(offset += 6, false) === 0x4949
          offset += view.getUint32(offset + 4, little)
          const tags = view.getUint16(offset, little)
          offset += 2

          for (let i = 0; i < tags; i++) {
            if (view.getUint16(offset + (i * 12), little) === 0x0112) {
              return resolve(view.getUint16(offset + (i * 12) + 8, little))
            }
          }
        } else if ((marker & 0xFF00) !== 0xFF00) {
          break
        } else {
          offset += view.getUint16(offset, false)
        }
      }

      resolve(-1)
    })
  })
}
