/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import PropTypes from 'prop-types'
import { COLORS } from '@trexity/common/color/constants'
import { MarkerStyle } from './markers.styles'

export const Circle = ({
  bg,
  border,
  light,
  label
}) => {
  return (
    <div css={[MarkerStyle, CircleStyles(bg, border, light)]}>
      {label}
    </div>
  )
}

export const CircleStyles = (bg, border, light) => ({
  borderRadius: 100,
  backgroundColor: bg,
  borderColor: border,
  color: light ? COLORS.WHITE : COLORS.DARK
})

Circle.propTypes = {
  bg: PropTypes.string,
  border: PropTypes.string,
  light: PropTypes.bool,
  label: PropTypes.string
}
