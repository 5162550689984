/*
Module that exports an Address factory function.
*/

const { stripCivicAddressFractionSuffix, stripUnitPrefix } = require('../clean')
const StreetSuffix = require('../streetSuffix')

// Normalizes accent characters by removing all diacritical characters
// See: https://stackoverflow.com/a/37511463/908171
const normalizeAccentedChars = (str) => str.normalize('NFD').replace(/[\u0300-\u036f]/gu, '')

/**
 * Factory function that creates an Address object.
 */
const Address = ({
  addressee = '',
  // Additional delivery information (i.e. the Attention line)
  // i.e. Marketing Dept
  // i.e. Company Name
  // i.e. Buzzer 234 Floor 4
  // see: https://www.canadapost.ca/tools/pg/manual/PGaddress-e.asp#1417000
  deliveryInfo = '',
  // Apartment, suite, or unit (i.e. 412, A, F, etc.)
  apartment = '',
  // The name of the original addressee
  careof = '',
  // Street level information (i.e. 34 Shafer Ave)
  street = '',
  city = '',
  // Additionaly address lines
  lines = [],
  // Full province or state name
  province = '',
  // Abbreviated province or state name
  provinceAbbr = '',
  // Postal or zip code
  postalCode = '',
  // Full country name
  country = '',
  // Country code
  countryAbbr = '',
  // Custom street formating when toLabel() and toPostalLabel() are called.
  // Default: APT-STREET for a Canadian postal label format.
  streetLabel = ({ street, apartment }) => {
    return [apartment && apartment.split(' ').pop(), street].filter(Boolean).join('-').toUpperCase()
  },
  streetString = ({ street, apartment }) => {
    return [apartment && apartment.split(' ').pop(), street].filter(Boolean).join('-')
  }
} = {}) => {
  postalCode = String(postalCode || '').toUpperCase()

  return {
    /** Determines if this address is a valid street address. */
    isValid: !!street && !!city && !!province,
    addressee,
    deliveryInfo,
    apartment,
    careof,
    street,
    city,
    lines: lines.filter(Boolean),
    province,
    provinceAbbr,
    postalCode,
    country,
    countryAbbr,
    // fullStreet include the apartment number, if any
    get fullStreet () {
      return streetString({ street: this.street, apartment: this.apartment })
    },
    toString ({ delimiter = ', ' } = {}) {
      return [
        streetString({ street: this.street, apartment: this.apartment }),
        [this.city, this.provinceAbbr, this.postalCode].filter(Boolean).join(' '),
        this.countryAbbr
      ].filter(Boolean).join(delimiter)
    },
    toStringForGeocoding ({ delimiter = ', ' } = {}) {
      return [
        // Remove the civic address fraction suffix if exists.
        // We do this so that when we geocode we are more likely to geocode to the correct house.
        stripUnitPrefix(stripCivicAddressFractionSuffix(this.street)),
        [this.city, this.provinceAbbr, this.postalCode].filter(Boolean).join(' '),
        this.country
      ].filter(Boolean).join(delimiter)
    },
    // Label for Trexity's needs
    toLabel ({ addressee = this.addressee, careof = this.careof } = {}) {
      return [
        String(addressee).trim().toUpperCase(),
        String(careof).trim() && `c/o. ${String(careof).trim().toUpperCase()}`,
        streetLabel({ street: this.street, apartment: this.apartment }),
        [this.city, this.provinceAbbr].join(' ').toUpperCase(),
        ...[this.deliveryInfo].concat(this.lines).filter(Boolean).map((line) => `(${line.toUpperCase()})`)
      ].filter(Boolean).join('\n')
    },
    /**
     * Test equality of an address that excludes checking the postal code.
     */
    equals (addrObj) {
      if (!Address.isAddress(addrObj)) return false
      return this.toHash() === addrObj.toHash()
    },
    toHash () {
      return [
        normalizeAccentedChars(this.country).replace(/\W/gu, ''),
        normalizeAccentedChars(this.province).replace(/\W/gu, ''),
        normalizeAccentedChars(this.city).replace(/\W/gu, ''),
        normalizeAccentedChars(StreetSuffix.normalize(this.street)).replace(/\W/gu, ''),
        this.apartment.replace(/\W/gu, '')
      ].filter(Boolean).join('-').toLowerCase()
    },
    toJSON () {
      return {
        addressee,
        deliveryInfo,
        apartment,
        careof,
        street,
        city,
        lines,
        province,
        provinceAbbr,
        postalCode,
        country,
        countryAbbr
      }
    }
  }
}

Address.isAddress = (val) => {
  return !!val && typeof val === 'object' && [
    'isValid' in val,
    'addressee' in val,
    'deliveryInfo' in val,
    'apartment' in val,
    'careof' in val,
    'street' in val,
    'city' in val,
    'lines' in val,
    'province' in val,
    'provinceAbbr' in val,
    'postalCode' in val,
    'country' in val,
    'countryAbbr' in val,
    typeof val.toLabel === 'function',
    typeof val.toStringForGeocoding === 'function',
    typeof val.equals === 'function',
    typeof val.toHash === 'function'
  ].every(Boolean)
}

/**
 * Sorting function used to order addresses from most specific to least specific.
 */
Address.specificity = (a, b) => {
  if (!Address.isAddress(a)) return 1
  if (!Address.isAddress(b)) return -1

  // Use the number of segements in the hash plus if postal code is truthy.
  // The has contains normalized segments for all parts of the address except
  // for the deliveryInfo and postal code.
  const _a = a.toHash().split('-').length + (a.postalCode ? 1 : 0)
  const _b = b.toHash().split('-').length + (b.postalCode ? 1 : 0)

  return _b - _a
}

exports.Address = Address
