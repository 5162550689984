import React from 'react'

export default function usePropertyWhenDefined (getProperty, { checkIntervalMs = 50 } = {}) {
  const [value, setValue] = React.useState(null)

  React.useEffect(() => {
    const propertyValue = getProperty()

    if (propertyValue) {
      setValue(propertyValue)
      return
    }

    const interval = setInterval(() => {
      const propertyValue = getProperty()

      if (propertyValue) {
        clearInterval(interval)
        setValue(propertyValue)
      }
    }, checkIntervalMs)

    return () => clearInterval(interval)
  }, [checkIntervalMs])

  return value
}
