/*
Takes internal-order objects and displays them in a table to be selected for
bulk creation or updating.
*/

import React from 'react'
import PropTypes from 'prop-types'
import { Table } from 'antd'
import { getFormattedDateAndTimeFromDate } from '@trexity/common/temporal'

// Convert a value to a string.
// Return empty string if value is null or undefined.
const tostr = (val) => {
  if (val === null || val === undefined) return ''
  return val.toString()
}

const getOrderKey = (order) => `${order.rowNum}`

const renderDeliveryAddress = (text, order, index) => {
  if (!tostr(order.finalDeliveryAddress) && !tostr(order.deliveryAddressNotes)) {
    return '—'
  }

  return (
    <React.Fragment>
      {order.finalDeliveryAddress}
      {order.deliveryAddressNotes ? <React.Fragment><br />{order.deliveryAddressNotes}</React.Fragment> : null}
    </React.Fragment>
  )
}

const renderScheduledDate = (text, order, index) => {
  return order.scheduledDate
    ? getFormattedDateAndTimeFromDate(new Date(order.scheduledDate))
    : '—'
}

const renderAction = (order) => {
  return order.orderUuid ? '(update)' : '(create)'
}

const renderFieldFactory = (field) => (text, order, index) => {
  return tostr(order[field]) || '—'
}

const renderCustomerName = renderFieldFactory('name')
const renderOrderId = renderFieldFactory('orderId')

const style = { marginBottom: 15 }
const scroll = { y: 340, x: 1200 }
const getTimeZoneName = () => new Date().toString().split('(').pop().slice(0, -1)

InputSuccessTable.propTypes = {
  // Called when an order/delivery is checked/unchecked: (orders) => void
  onSelection: PropTypes.func.isRequired,
  orders: PropTypes.arrayOf(PropTypes.shape({
    rowNum: PropTypes.number.isRequired,
    orderId: PropTypes.string,
    name: PropTypes.string,
    finalDeliveryAddress: PropTypes.string.isRequired,
    deliveryAddressNotes: PropTypes.string
  })).isRequired
}

function InputSuccessTable (props) {
  const { orders, onSelection } = props

  const [selectedKeys, setSelectedKeys] = React.useState([])

  const orderKeyMap = React.useMemo(() => {
    return orders.reduce((memo, order) => ({ ...memo, [getOrderKey(order)]: order }), {})
  }, [orders])

  return (
    <Table
      pagination={false}
      size='small'
      bordered={false}
      dataSource={orders}
      style={style}
      scroll={scroll}
      rowKey={getOrderKey}
      rowSelection={{
        selectedRowKeys: selectedKeys,
        onChange: (selectedKeys) => {
          setSelectedKeys(selectedKeys)
          onSelection(selectedKeys.map((orderKey) => orderKeyMap[orderKey]))
        },
        hideDefaultSelections: true
      }}
    >
      <Table.Column
        dataIndex=''
        key='action'
        title='Action'
        render={renderAction}
        width={90}
      />
      <Table.Column
        dataIndex='rowNum'
        key='rowNum'
        title='Row #'
        width={70}
      />
      <Table.Column
        dataIndex='orderId'
        key='orderId'
        title='Order ID'
        width={180}
        render={renderOrderId}
      />
      <Table.Column
        dataIndex='name'
        key='name'
        title='Customer Name'
        width={200}
        render={renderCustomerName}
      />
      <Table.Column
        dataIndex='deliveryAddress'
        key='deliveryAddress'
        title='Delivery Address'
        render={renderDeliveryAddress}
      />
      <Table.Column
        dataIndex='scheduledDate'
        key='scheduledDate'
        title={<React.Fragment>Scheduled date<br /><small>({getTimeZoneName()})</small></React.Fragment>}
        width={180}
        render={renderScheduledDate}
      />
    </Table>
  )
}

export { InputSuccessTable }
