const { convertOrdersToArray } = require('../models/ShipmentOrder')
const { md5 } = require('../vendor/md5')

function createQrCodeUrl (shipment, orderUuid, labelIndex, version = null) {
  const shipmentId = shipment.id
  const orders = convertOrdersToArray(shipment.orders)
  const deliveryIndex = orders.findIndex(({ id }) => id === orderUuid)
  const order = orders[deliveryIndex] || {}
  const orderId = order.orderId
  const deliveryName = order.name

  // v1 is the first production iteration. v3 superceded it Feb 12 2021
  function v1 () {
    // First 9 chars of MD5 hash
    const components = [
      shipmentId,
      deliveryIndex,
      orderId
    ]

    const hash = md5(components.join('/'))
    const partialHash = hash.substr(0, 9)

    return `trx1://${partialHash}`
  }

  // v2 is a non-lossy protocol, but has the flaw of using shipmentId. We don't
  // use this version
  function v2 () {
    // TRX380629610-R59 0 KPTZ-5343
    // trx2://0R59/0/KPTZ-5343

    const components = [
      `${shipmentId.substr(-6).replace('-', '')}`,
      deliveryIndex,
      orderId
    ]

    return `trx2://${components.join('/')}`
  }

  // v3 is similar to v1 but substitutes shipmentId for deliveryName.
  // This is a stop-gap solution until v4 is in production, and attempts to
  // solve the issue of shipments being deleted and moved to distributions
  function v3 () {
    // First 9 chars of MD5 hash
    const components = [
      deliveryName,
      deliveryIndex,
      orderId
    ]

    const hash = md5(components.join('/'))
    const partialHash = hash.substr(0, 9)

    return `trx3://${partialHash}`
  }

  // v4 is based on the stable UUID of each order
  function v4 () {
    // First 8 chars of the UUID
    return `trx4://${orderUuid.substr(0, 8)}`
  }

  // v5 is based on v3, but solves the flaw that the deliveryIndex can change
  function v5 () {
    // First 9 chars of MD5 hash
    const components = [
      deliveryName,
      orderId
    ]

    const hash = md5(components.join('/'))
    const partialHash = hash.substr(0, 9)

    return `trx5://${partialHash}`
  }

  // v6 is based on the stable UUID of each order with optional label indexes
  // (derived from numLabels)
  function v6 () {
    // Last 7 chars of the UUID + labelIndex
    return `trx6://${orderUuid.substr(-7)}${labelIndex || 0}`
  }

  function v7 () {
    // Same as v6 except without a scheme-based prefix
    return `7/${orderUuid.substr(-7)}${labelIndex || 0}`
  }

  if (version === 1) {
    return v1()
  } else if (version === 2) {
    return v2()
  } else if (version === 3) {
    return v3()
  } else if (version === 4) {
    return v4()
  } else if (version === 5) {
    return v5()
  } else if (version === 6) {
    return v6()
  } else if (version === 7) {
    return v7()
  } else {
    // Preferred protocol is v6
    return v7()
  }
}

function testQrCodeUrl (url, shipment, orderUuid, labelIndex, version = null) {
  const shipmentId = shipment.id
  const ordersArray = convertOrdersToArray(shipment.orders)
  const deliveryIndex = ordersArray.findIndex(({ id }) => id === orderUuid)
  const order = ordersArray[deliveryIndex] || {}
  const orderId = order.orderId

  function v1 () {
    if (!url.startsWith('trx1://')) {
      return false
    }

    const testUrl = createQrCodeUrl(shipment, orderUuid, labelIndex, 1)
    const matches = testUrl === url

    return {
      matches,
      last3: matches ? shipmentId.substr(-3) : null,
      orderId: matches ? orderId : null,
      expected: testUrl
    }
  }

  function v2 () {
    if (!url.startsWith('trx2://')) {
      return false
    }

    const parts = url.replace('trx2://', '').split('/')
    const last3 = parts[0].substr(-3)
    const index = Number(parts[1])
    const orderId = parts[2]

    const matches = shipmentId.substr(-3) === last3 && deliveryIndex === index

    return {
      matches,
      last3,
      orderId,
      expected: createQrCodeUrl(shipment, orderUuid, labelIndex, 2)
    }
  }

  function v3 () {
    if (!url.startsWith('trx3://')) {
      return false
    }

    const testUrl = createQrCodeUrl(shipment, orderUuid, labelIndex, 3)
    const matches = testUrl === url

    return {
      matches,
      last3: matches ? shipmentId.substr(-3) : null,
      orderId: matches ? orderId : null,
      expected: testUrl
    }
  }

  function v4 () {
    if (!url.startsWith('trx4://')) {
      return false
    }

    const testUrl = createQrCodeUrl(shipment, orderUuid, labelIndex, 4)
    const matches = testUrl === url

    return {
      matches,
      last3: matches ? shipmentId.substr(-3) : null,
      orderId: matches ? orderId : null,
      expected: testUrl
    }
  }

  function v5 () {
    if (!url.startsWith('trx5://')) {
      return false
    }

    const testUrl = createQrCodeUrl(shipment, orderUuid, labelIndex, 5)
    const matches = testUrl === url

    return {
      matches,
      last3: matches ? shipmentId.substr(-3) : null,
      orderId: matches ? orderId : null,
      expected: testUrl
    }
  }

  function v6 () {
    if (!url.startsWith('trx6://')) {
      return false
    }

    const testUrl = createQrCodeUrl(shipment, orderUuid, labelIndex, 6)
    const matches = testUrl === url

    return {
      matches,
      last3: matches ? shipmentId.substr(-3) : null,
      orderId: matches ? orderId : null,
      expected: testUrl
    }
  }

  function v7 () {
    if (!url.startsWith('7/')) {
      return false
    }

    const testUrl = createQrCodeUrl(shipment, orderUuid, labelIndex, 7)
    const matches = testUrl === url

    return {
      matches,
      last3: matches ? shipmentId.substr(-3) : null,
      orderId: matches ? orderId : null,
      expected: testUrl
    }
  }

  if (version === 1) {
    return v1()
  } else if (version === 2) {
    return v2()
  } else if (version === 3) {
    return v3()
  } else if (version === 4) {
    return v4()
  } else if (version === 5) {
    return v5()
  } else if (version === 6) {
    return v6()
  } else if (version === 7) {
    return v7()
  } else {
    // Try all versions in priority of use
    return [v7, v6, v4, v5, v3, v1, v2].reduce((memo, vfn) => memo || vfn(), false)
  }
}

exports.createQrCodeUrl = createQrCodeUrl
exports.testQrCodeUrl = testQrCodeUrl
