import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Card, Radio } from 'antd'
import SearchSelect from '../../SearchSelect'
import { LoadingWrapper } from '../../LoadingWrapper'

export function MoveDeliveryDialog ({
  deps
}) {
  const {
    send,
    current,
    data
  } = deps

  const context = data.moveOrderContext || {}

  const results = (data.shipmentSearchResults || [])
    .filter(({ id: shipmentId }) => shipmentId !== context.shipmentId)

  const showMoveDeliveryDialog = current.within('Showing Move Order Dialog')
  const isBusy = current.within('Moving Order Busy')

  const [choice, setChoice] = React.useState('new')
  const [searchId, setSearchId] = React.useState('')

  const resetState = () => {
    setChoice('new')
    setSearchId('')
  }

  React.useEffect(() => {
    if (!showMoveDeliveryDialog) {
      resetState()
    }
  }, [showMoveDeliveryDialog])

  return (
    <Modal
      title={`Move Delivery ${context.orderId || ''}`}
      visible={showMoveDeliveryDialog}
      onOk={() => {
        if (choice === 'new') {
          send({ type: 'move order to new shipment', originalShipmentId: context.shipmentId, orderUuid: context.id })
        } else if (choice === 'existing') {
          if (!searchId) {
            Modal.warning({
              title: 'Missing information',
              content: 'Please choose a target Route ID'
            })
          } else {
            send({ type: 'move order to existing shipment', originalShipmentId: context.shipmentId, targetShipmentId: searchId, orderUuid: context.id })
          }
        }
      }}
      onCancel={() => {
        send('close move order dialog')
      }}
    >
      <LoadingWrapper active={isBusy}>
        <Radio.Group onChange={(evt) => setChoice(evt.target.value)} value={choice} style={{ width: '100%' }}>
          <Card size='small' style={{ marginBottom: 10 }}>
            <Radio value='new'>
              To a new route
            </Radio>
          </Card>
          <Card size='small'>
            <Radio value='existing' style={{ marginBottom: 10 }}>
              To an existing route:
            </Radio>
            {showMoveDeliveryDialog ? (
              <SearchSelect
                placeholder='Search…'
                value={[searchId]}
                disabled={choice !== 'existing'}
                dynamicOptions={
                  results.map(({ id }) => {
                    return { value: id, label: id }
                  })
                }
                onSearch={(query) => {
                  send({ type: 'search shipments', query, merchantId: context.merchantId })
                }}
                onChange={(selectedShipmentIds) => {
                  const [searchId] = selectedShipmentIds
                  setSearchId(searchId)
                }}
              />
            ) : null}
          </Card>
        </Radio.Group>
      </LoadingWrapper>
    </Modal>
  )
}

MoveDeliveryDialog.propTypes = {
  deps: PropTypes.exact({
    send: PropTypes.func,
    current: PropTypes.object,
    data: PropTypes.object
  }).isRequired
}
