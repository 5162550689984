/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'

export const ButtonBase = {
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: 600,
  textTransform: 'uppercase',
  borderWidth: 1,
  borderStyle: 'solid',
  borderRadius: 10,
  padding: '0.8em 1.8em',
  cursor: 'pointer'
}
