const { Check } = require('../../core')
const { isCalendarDay } = require('./isCalendarDay')
const { isTimeRange } = require('./isTimeRange')

/**
 * Determines if a value is a valid service hours object.
 *
 * @param {any} val
 * @return {boolean}
 */
function isServiceHours (val) {
  return Check.keys({
    noExtraKeys: true,
    opt: [
      Check.key('days', Check.and(Check.isarr, Check.of(Check.keys({
        req: [
          Check.key('day', Check.and(Check.isint, (x) => x >= 0 && x <= 6)),
          Check.key('ranges', Check.and(Check.isarr, Check.of(isTimeRange)))
        ]
      })))),
      Check.key('calendarDays', Check.and(Check.isarr, Check.of(Check.keys({
        req: [
          Check.key('day', Check.and(isCalendarDay)),
          Check.key('ranges', Check.and(Check.isarr, Check.of(isTimeRange)))
        ]
      }))))
    ]
  }, val)
}

exports.isServiceHours = isServiceHours
