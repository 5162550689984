import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'antd'
import { COLORS } from '@trexity/common/color/constants'

export default function ZoneMapResults ({
  addressCheckResult,
  addressCheckError,
  rejectIcon,
  acceptIcon,
  infoIcon,
  errorIcon,
  zonesMap
}) {
  return (
    <React.Fragment>
      {addressCheckError ? (
        <div key={addressCheckError}>
          <AddressCheckResults
            color={COLORS.TREXITY_RED}
            icon={errorIcon}
            label={addressCheckError}
          />
        </div>
      ) : null}
      {addressCheckResult ? (
        <div>
          {addressCheckResult.delivery === 'none' ? (
            <AddressCheckResults
              color={COLORS.TREXITY_RED}
              icon={rejectIcon}
              label={(<span>Unfortunately, the address is outside of our zones and is not eligible for delivery at this time.</span>)}
            />
          ) : null}
          {addressCheckResult.delivery === 'normal' ? (
            <AddressCheckResults
              color={COLORS.TREXITY_GREEN}
              icon={acceptIcon}
              label={(<span>The address is in the <strong>normal</strong> delivery zone, normal rates apply.</span>)}
            />
          ) : null}
          {addressCheckResult.delivery === 'extended' ? (
            <AddressCheckResults
              color={COLORS.TREXITY_BLUE}
              icon={infoIcon}
              label={(<span>The address is in the <strong>extended</strong> delivery zone, additional costs apply.</span>)}
            />
          ) : null}
          {addressCheckResult.pickup ? (
            <AddressCheckResults
              color={COLORS.TREXITY_GREEN}
              icon={acceptIcon}
              label='The address is an eligible business address for Trexity.'
              cta={zonesMap ? (
                <Button
                  type='primary'
                  size='large'
                  href='/signup'
                  target='_parent'
                >Become a merchant</Button>
              ) : null}
            />
          ) : (
            <AddressCheckResults
              color={COLORS.TREXITY_RED}
              icon={rejectIcon}
              label='Unfortunately, this business address does not fit in our pickup area at this time.'
            />
          )}
        </div>
      ) : null}
    </React.Fragment>
  )
}

export const AddressCheckResults = ({ icon, color, label, cta }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', padding: '10px 20px' }}>
      <div style={{ fontSize: 40, color: color, marginRight: 20 }}>{icon}</div>
      <div style={{ fontSize: 14, paddingTop: 7 }}>
        {label}
        {cta ? (
          <div style={{ marginTop: 20 }}>{cta}</div>
        ) : null}
      </div>
    </div>
  )
}

ZoneMapResults.propTypes = {
  addressCheckResult: PropTypes.object,
  addressCheckError: PropTypes.string,
  rejectIcon: PropTypes.node.isRequired,
  acceptIcon: PropTypes.node.isRequired,
  infoIcon: PropTypes.node.isRequired,
  errorIcon: PropTypes.node.isRequired
}
