/*
Takes internal-order objects from the bulk create/update endpoint and displays
a summary of the operation's result.
*/

import React from 'react'
import PropTypes from 'prop-types'
import { Table } from 'antd'

// Convert a value to a string.
// Return empty string if value is null or undefined.
const tostr = (val) => {
  if (val === null || val === undefined) return ''
  return val.toString()
}

const getOrderKey = (order) => `${order.rowNum}`

const renderAction = (order) => {
  return order.__error
    ? '—'
    : (order.__updated ? '(updated)' : '(created)')
}

const renderErrors = (text, order, index) => {
  return tostr(order.__error) || '—'
}

const renderDeliveryAddress = (text, order, index) => {
  if (!tostr(order.finalDeliveryAddress) && !tostr(order.deliveryAddressNotes)) {
    return '—'
  }

  return (
    <React.Fragment>
      {order.finalDeliveryAddress}
      {order.deliveryAddressNotes ? <React.Fragment><br />{order.deliveryAddressNotes}</React.Fragment> : null}
    </React.Fragment>
  )
}

const renderFieldFactory = (field) => (text, order, index) => {
  return tostr(order[field]) || '—'
}

const renderCustomerName = renderFieldFactory('name')
const renderOrderId = renderFieldFactory('orderId')
const renderRouteId = renderFieldFactory('shipmentId')

const rowClassName = (order) => order.__error ? 'import-error' : ''
const style = { marginBottom: 15 }
const scroll = { y: 340, x: 1200 }

SummaryTable.propTypes = {
  orders: PropTypes.arrayOf(PropTypes.shape({
    rowNum: PropTypes.number.isRequired,
    orderId: PropTypes.string,
    name: PropTypes.string,
    __error: PropTypes.string
  })).isRequired
}

function SummaryTable (props) {
  const { orders } = props

  return (
    <Table
      pagination={false}
      size='small'
      bordered={false}
      dataSource={orders}
      style={style}
      scroll={scroll}
      rowKey={getOrderKey}
      rowClassName={rowClassName}
    >
      <Table.Column
        dataIndex=''
        key='action'
        title='Action'
        render={renderAction}
        width={90}
        align='center'
      />
      <Table.Column
        dataIndex='rowNum'
        key='rowNum'
        title='Row #'
        width={70}
        align='center'
      />
      <Table.Column
        dataIndex='orderId'
        key='orderId'
        title='Order ID'
        render={renderOrderId}
        width={180}
      />
      <Table.Column
        dataIndex='shipmentId'
        key='routeId'
        title='Route ID'
        render={renderRouteId}
        width={180}
      />
      <Table.Column
        dataIndex='name'
        key='name'
        title='Customer Name'
        render={renderCustomerName}
        width={200}
      />
      <Table.Column
        dataIndex='deliveryAddress'
        key='deliveryAddress'
        title='Delivery Address'
        render={renderDeliveryAddress}
      />
      <Table.Column
        dataIndex='errors'
        key='errors'
        title='Errors'
        render={renderErrors}
      />
    </Table>
  )
}

export { SummaryTable }
