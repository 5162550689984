const _12HR_FORMAT = /^(0?[1-9]|1[0-2])(?::([0-5][0-9]))? ?(am|pm)$/iu
const _24HR_FORMAT = /^([0-1][0-9]|2[0-3]):([0-5][0-9])$/u

/**
 * Represents a 24-hour time value.
 */
class Time {
  /**
   * Attempts to create a new Time value from a string or Time value. Returns a
   * notification with all errors.
   *
   * When a string is passed in, the following formats are supported:
   *
   * 12-hour clock time (case insensitive): 9 am, 9:00 am, 09:00 am, 11:59 pm
   * 24-hour clock time: 09:00, 23:59
   *
   * @example
   * Time.from('9 am')
   * Time.from('9:00 am')
   * Time.from('9:00')
   * Time.from('13:00').toString({ format: '12hour' }) // "1 pm"
   * Time.from('13:00').toString({ format: '12hourPadded' }) // "01:00 pm"
   * @param {string|number|Time} value The formatted string, magnitude in minutes or an existing Time instance
   * @return {{ errors: string[], value?: Time }}
   */
  static from (value) {
    const notification = { errors: [], value: null }

    if (value instanceof Time) {
      notification.value = new Time(value.hours, value.minutes)
      return notification
    }

    if (Number.isSafeInteger(value) && value >= 0) {
      const h = Math.floor(value / 60)
      const m = value - (h * 60)

      try {
        notification.value = new Time(h, m)
        return notification
      } catch (error) {
        notification.errors.push([error.name, error.message].filter(Boolean).join(': '))
        return notification
      }
    }

    if (typeof value !== 'string') {
      notification.errors.push('can only convert from a string')
      return notification
    }

    let hours = 0
    let minutes = 0
    let match = _12HR_FORMAT.exec(value)

    if (match) {
      hours = parseInt(match[1], 10)
      minutes = parseInt(match[2], 10) || 0
      const ampm = match[3].toLowerCase()

      if (hours > 12) {
        notification.errors.push('hour out of range')
      } else {
        switch (ampm) {
          case 'am':
            if (hours === 12) {
              hours = 0
            }

            break

          default:
            if (hours < 12) {
              hours += 12
            }
        }
      }
    } else {
      match = _24HR_FORMAT.exec(value)

      if (match) {
        hours = parseInt(match[1], 10)
        minutes = parseInt(match[2], 10)
      } else {
        notification.errors.push('cannot convert time value')
      }
    }

    // Wrap back around. 24 means midnight (24 == 0).
    if (hours === 24) {
      hours = 0
    }

    if (hours > 23) {
      notification.errors.push('hours is out of range')
    }

    if (minutes > 59) {
      notification.errors.push('minutes" is out of range')
    }

    if (!notification.errors.length) {
      notification.value = new Time(hours, minutes)
    }

    return notification
  }

  /**
   * Construct a 24-hour time value.
   *
   * @example
   * new Time(0, 0).toString() // '00:00'
   * new Time(13, 45).toString() // '13:45'
   * @param {number} hours
   * @param {number} minutes
   */
  constructor (hours, minutes) {
    if (!Number.isSafeInteger(hours) || hours < 0) {
      throw new TypeError('Argument "hours" must be a positive integer')
    }

    if (hours > 23) {
      throw Object.assign(
        new Error(`Argument "hours" is out of range: ${hours}. Expected 0-23.`),
        { name: 'ArgumentError', argName: 'hours', argValue: hours }
      )
    }

    if (!Number.isSafeInteger(minutes) || minutes < 0) {
      throw new TypeError('Argument "minutes" must be a positive integer')
    }

    if (minutes > 59) {
      throw Object.assign(
        new Error(`Argument "minutes" is out of range: ${minutes}. Expected 0-59`),
        { name: 'ArgumentError', argName: 'minutes', argValue: minutes }
      )
    }

    this._hours = hours
    this._minutes = minutes
  }

  /**
   * The magnitude of this Time object in minutes.
   */
  get magnitude () {
    return this.hours * 60 + this.minutes
  }

  /**
   * Hours in 24-hour time
   * @type {number}
   */
  get hours () {
    return this._hours
  }

  /**
   * Minutes in 24-hour time
   * @type {number}
   */
  get minutes () {
    return this._minutes
  }

  /**
   * @param {Time} other
   * @return {number}
   */
  compare (other) {
    if (other instanceof Time) {
      return this.valueOf() - other.valueOf()
    } else {
      throw new Error('Argument "other" must be a Time instance')
    }
  }

  /**
   * @param {Time} other
   * @return {number}
   */
  equals (other) {
    if (other instanceof Time) {
      return this.compare(other) === 0
    } else {
      return false
    }
  }

  valueOf () {
    return this.magnitude
  }

  /**
   * Convert time to string representation, optionally in the specified format.
   *
   * Formats:
   *
   * '12hour'            9 am, 9:05 am, 10:45 pm
   * '12hourPadded'      09:00 am, 09:05 am, 10:45 pm
   * '24hour' (default)  09:00, 09:05, 22:45
   *
   * Errors:
   *
   *   TypeError                When options is not an object
   *
   *   ArgumentError            When options.format is not supported
   *
   *                            { argName: 'options.format', argValue: format }
   *
   * @param {{ format?: '12hour' | '12hourPadded' | '24hour' }} [options]
   */
  toString (options = {}) {
    if (Object(options) !== options) {
      throw new TypeError('Argument "options" must be an object')
    }

    const { format = '24hour' } = options
    let ampm = ''
    let h = 0

    switch (format) {
      case '12hour':
        ampm = this.hours >= 12 ? 'pm' : 'am'
        h = this.hours

        if (h > 12) {
          h -= 12
        } else if (h === 0) {
          h = 12
        }

        if (this.minutes) {
          return `${h}:${pad(this.minutes)} ${ampm}`
        } else {
          return `${h} ${ampm}`
        }

      case '12hourPadded':
        ampm = this.hours >= 12 ? 'pm' : 'am'
        h = this.hours

        if (h > 12) {
          h -= 12
        } else if (h === 0) {
          h = 12
        }

        return `${pad(h)}:${pad(this.minutes)} ${ampm}`
      case '24hour':
        return `${pad(this.hours)}:${pad(this.minutes)}`
      default:
        throw Object.assign(
          new Error(`Argument "options.format" not supported: ${format}`),
          {
            name: 'ArgumentError',
            argName: 'options.format',
            argValue: format
          }
        )
    }
  }
}

const pad = (n) => {
  if (n < 10) {
    return `0${n}`
  } else {
    return `${n}`
  }
}

exports.Time = Time
