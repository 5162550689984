import PropTypes from 'prop-types'

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'

export const ClearButton = ({ children, active = false, style, ...rest }) => {
  return (
    <button
      css={{
        backgroundColor: 'transparent',
        border: 'none',
        cursor: 'pointer',
        fontWeight: active ? '600' : 400,
        outline: 0,
        ...style
      }}
      {...rest}
    >{children}</button>
  )
}

ClearButton.propTypes = {
  children: PropTypes.node,
  active: PropTypes.bool,
  style: PropTypes.object
}
