const { EARTH_RADIUS_METERS } = require('./constants')
const deg2rad = require('./deg2rad')

module.exports = function calculateDistance (coord1, coord2) {
  const R = EARTH_RADIUS_METERS
  const lat1 = coord1.latitude
  const lat2 = coord2.latitude
  const lon1 = coord1.longitude
  const lon2 = coord2.longitude
  const φ1 = deg2rad(lat1)
  const φ2 = deg2rad(lat2)
  const Δφ = deg2rad(lat2 - lat1)
  const Δλ = deg2rad(lon2 - lon1)

  const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) + Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

  return R * c
}
