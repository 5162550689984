import React from 'react'
import PropTypes from 'prop-types'
import { HeadingBase } from './heading.styles'
import { gsap } from 'gsap'

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'

export const H3 = ({
  children,
  color,
  style,
  animatingIn = true,
  animatingOut,
  ...rest
}) => {
  const h3 = React.useRef()

  React.useEffect(() => {
    if (animatingIn) {
      gsap.to(h3.current, { opacity: 1 })
    }

    if (animatingOut) {
      gsap.to(h3.current, { opacity: 0 })
    }
  }, [animatingIn, animatingOut])

  return (
    <h3 ref={h3} css={[{ opacity: 0 }, HeadingBase, Heading3Styles(color), style]} {...rest}>{children}</h3>
  )
}

export const Heading3Styles = (color) => ({
  fontSize: '20px',
  color: color
})

H3.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  style: PropTypes.object,
  animatingIn: PropTypes.bool,
  animatingOut: PropTypes.bool
}
