module.exports = function getShipmentCommonAttributes (shipment, {
  includeRootMetadata = false,
  includeOrderMetadata = false
} = {}) {
  return {
    merchantId: shipment.merchantId,
    requirements: shipment.requirements,
    partnerData: shipment.partnerData,

    // cached merchant information
    cachedMerchantName: shipment.cachedMerchantName,
    cachedMerchantPhone: shipment.cachedMerchantPhone,
    cachedMerchantEmail: shipment.cachedMerchantEmail,
    cachedMerchantAddress: shipment.cachedMerchantAddress,

    // pick up related properties
    pickupAddress: shipment.pickupAddress,
    pickupAddressNotes: shipment.pickupAddressNotes,
    pickupAddressCity: shipment.pickupAddressCity,
    pickupAddressServiceCity: shipment.pickupAddressServiceCity,
    pickupAddressCountry: shipment.pickupAddressCountry,
    pickupAddressLocation: shipment.pickupAddressLocation,
    pickupAddressProvince: shipment.pickupAddressProvince,
    pickupNotes: shipment.pickupNotes,

    ...(includeRootMetadata && { metadata: shipment.metadata || {} }),

    // delivery related properties
    orders: Object.entries(shipment.orders).reduce((memo, [sidx, order]) => {
      order = order || {}

      if (order.internalType) {
        // Don't clone internal orders
        return memo
      }

      return {
        ...memo,
        [sidx]: {
          initialPhotoUrl: order.initialPhotoUrl,
          name: order.name,
          phone: order.phone,
          email: order.email,
          address: order.address,
          addressNotes: order.addressNotes,
          province: order.province,
          city: order.city,
          serviceCity: order.serviceCity,
          country: order.country,
          location: order.location,
          orderId: order.orderId,
          value: order.value,
          description: order.description,
          containsAlcohol: order.containsAlcohol,
          requiresPersonHandoff: order.requiresPersonHandoff,
          deliveryInstructions: order.deliveryInstructions,
          numLabels: order.numLabels,

          ...(includeOrderMetadata && { metadata: order.metadata || {} })
        }
      }
    }, {})
  }
}
