import React from 'react'
import PropTypes from 'prop-types'
import { COLORS } from '@trexity/common/color/constants'

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'

export const Table = ({
  heading,
  data,
  color,
  overflow,
  containerStyle,
  headingStyle,
  rowStyle,
  colStyle,
  valign = 'top'
}) => {
  return (
    <div css={overflow ? TableContainer : null} style={{ width: '100%', ...containerStyle }}>
      <table style={{ width: '100%' }}>
        <thead>
          <tr css={HeaderRow}>
            {heading.map((col, index) => <td css={[TableCol, TableHeading]} style={{ color: color, ...headingStyle }} key={index}>{col}</td>)}
          </tr>
        </thead>
        <tbody>
          {data.map((row) => (
            <tr css={TableRow} style={{ verticalAlign: valign, ...rowStyle }} key={row}>
              {row.map((col, index) => <td css={TableCol} style={{ ...colStyle }} key={index}>{col}</td>)}
            </tr>
          )
          )}
        </tbody>
      </table>
    </div>
  )
}

export const TableContainer = css`
  overflow-x: scroll;
`

export const HeaderRow = css`
  border-width: 0 0 1px 0;
  border-color: ${COLORS.MEDIUM_LIGHT};
  border-style: solid;
`

export const TableRow = css`
  border-width: 0 0 1px 0;
  border-color: ${COLORS.MEDIUM_LIGHT};
  border-style: solid;

  &:last-of-type { border: none; }
`

export const TableCol = css`
  padding: 8px 15px;
  font-size: 14px;

  &:first-of-type { padding-left: 0; }
`

export const TableHeading = css`
  white-space: nowrap;
  font-weight: 600;
  font-size: 18px;
`

Table.propTypes = {
  color: PropTypes.string,
  style: PropTypes.object,
  containerStyle: PropTypes.object,
  headingStyle: PropTypes.object,
  rowStyle: PropTypes.object,
  colStyle: PropTypes.object,
  overflow: PropTypes.bool,
  heading: PropTypes.array,
  valign: PropTypes.string,
  data: PropTypes.array
}
