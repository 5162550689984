/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import PropTypes from 'prop-types'
import { COLORS } from '@trexity/common/color/constants'
import { MarkerStyle } from './markers.styles'

export const Square = ({
  bg,
  border,
  light,
  label
}) => {
  return (
    <div css={[MarkerStyle, SquareStyles(bg, border, light)]}>
      {label}
    </div>
  )
}

export const SquareStyles = (bg, border, light) => ({
  backgroundColor: bg,
  borderColor: border,
  color: light ? COLORS.WHITE : COLORS.DARK
})

Square.propTypes = {
  bg: PropTypes.string,
  border: PropTypes.string,
  light: PropTypes.bool,
  label: PropTypes.string
}
