module.exports = function getMonths () {
  return [
    { name: 'January', shortName: 'Jan' },
    { name: 'February', shortName: 'Feb' },
    { name: 'March', shortName: 'Mar' },
    { name: 'April', shortName: 'Apr' },
    { name: 'May', shortName: 'May' },
    { name: 'June', shortName: 'Jun' },
    { name: 'July', shortName: 'Jul' },
    { name: 'August', shortName: 'Aug' },
    { name: 'September', shortName: 'Sep' },
    { name: 'October', shortName: 'Oct' },
    { name: 'November', shortName: 'Nov' },
    { name: 'December', shortName: 'Dec' }
  ]
}
