const { formatDate } = require('./formatDate')
const { formatTime } = require('./formatTime')

/**
 * @param {Date|{ toDate: () => any }|{ toLocaleDateString: (locale:string, { timeZone:string }) => string, toLocaleTimeString: (locale:string, { timeZone:string }) => string, toISOString: () => string }} date
 * @param {Object} [options]
 * @param {string} [options.locale]
 * @param {string} [options.timeZone]
 * @param {string} [options.delimiter]
 * @param {string} [options.notAvailable] The string to return when date is falsey or invalid
 * @param {boolean} [options.iso8601] If truthy or locale or time zone are falsey then will ignore all other options and return simplified ISO 8601 string
 * @return {string}
 */
const formatDateTime = (date, { locale = 'en-CA', timeZone = 'UTC', delimiter = ' ', notAvailable = 'n/a', iso8601 = false } = {}) => {
  if (!date || String(date) === 'Invalid Date') {
    return String(notAvailable)
  }

  if (date && typeof date.toDate === 'function') {
    date = date.toDate()
  }

  if (iso8601 || !locale || !timeZone) {
    if (typeof date.toISOString !== 'function') {
      throw new Error('date must have toISOString() method')
    }

    return date.toISOString()
  }

  return [
    formatDate(date, { locale, timeZone }),
    formatTime(date, { locale, timeZone })
  ].join(delimiter)
}

exports.formatDateTime = formatDateTime
