import React from 'react'
import PropTypes from 'prop-types'
import { Tabs } from 'antd'
import Details from './Details'
import Timeline from './Timeline'
import Map from './Map'
import Transactions from './Transactions'
import Drivers from './Drivers'
import Recordings from './Recordings'

export default function ShipmentDashboard ({
  isAdmin,
  deps
}) {
  const {
    current,
    send,
    data,
    tc,
    DriverSearch,
    RouteLink,
    google,
    callDriverEnabled
  } = deps

  const {
    shipmentDashboardShipmentId: shipmentId
  } = data

  let activeKey = null

  if (current.within('Shipment Dashboard Timeline')) {
    activeKey = 'timeline'
  } else if (current.within('Shipment Dashboard Map')) {
    activeKey = 'map'
  } else if (current.within('Shipment Dashboard Transactions')) {
    activeKey = 'transactions'
  } else if (current.within('Shipment Dashboard Drivers')) {
    activeKey = 'drivers'
  } else if (current.within('Shipment Dashboard Recordings')) {
    activeKey = 'recordings'
  } else {
    activeKey = 'details'
  }

  return (
    <Tabs
      type='card'
      activeKey={activeKey}
      onChange={(key) => send({ type: 'route shipment dashboard', tab: key, id: shipmentId })}
      tabBarStyle={{ marginBottom: 1 }}
    >
      <Tabs.TabPane tab={<span>Details</span>} key='details' style={{ padding: '10px 5px' }}>
        <Details isAdmin={isAdmin} deps={{ current, send, data, tc, RouteLink, DriverSearch, callDriverEnabled }} />
      </Tabs.TabPane>

      <Tabs.TabPane tab={<span>Timeline</span>} key='timeline' style={{ padding: '10px 5px' }}>
        <Timeline isAdmin={isAdmin} deps={{ send, current, data, tc, RouteLink }} />
      </Tabs.TabPane>

      <Tabs.TabPane tab={<span>Map</span>} key='map' style={{ padding: '10px 5px' }}>
        <Map isAdmin={isAdmin} deps={{ current, data, google, tc }} />
      </Tabs.TabPane>

      <Tabs.TabPane tab={<span>Transactions</span>} key='transactions' style={{ padding: '10px 5px' }}>
        <Transactions isAdmin={isAdmin} deps={{ current, send, data, tc }} />
      </Tabs.TabPane>

      {isAdmin ? (
        <Tabs.TabPane tab={<span>Drivers</span>} key='drivers' style={{ padding: '10px 5px' }}>
          <Drivers deps={{ current, send, data, tc, RouteLink }} />
        </Tabs.TabPane>
      ) : null}

      {isAdmin ? (
        <Tabs.TabPane tab={<span>Recordings</span>} key='recordings' style={{ padding: '10px 5px' }}>
          <Recordings deps={{ current, send, data, tc, RouteLink }} />
        </Tabs.TabPane>
      ) : null}
    </Tabs>
  )
}

ShipmentDashboard.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  deps: PropTypes.exact({
    current: PropTypes.object,
    send: PropTypes.func,
    data: PropTypes.object,
    tc: PropTypes.object,
    DriverSearch: PropTypes.func,
    RouteLink: PropTypes.func,
    google: PropTypes.object,
    callDriverEnabled: PropTypes.bool
  }).isRequired
}
