class DriverChallengeQualificationLogic {
  constructor ({
    disqualifiedAt,
    disqualifiedReason,
    requiredNumberOfDeliveries,
    numberOfCompletedDeliveries,
    validFromAt,
    validToAt,
    earnedAt,
    status
  }) {
    this.status = status
    this.disqualified = Boolean(disqualifiedAt)
    this.disqualifiedAt = disqualifiedAt
    this.disqualifiedReason = disqualifiedReason
    this.requiredNumberOfDeliveries = requiredNumberOfDeliveries
    this.numberOfCompletedDeliveries = numberOfCompletedDeliveries
    // Takes care of ISO string and an actual Date instance:
    this.validFromAt = new Date(validFromAt)
    this.validToAt = new Date(validToAt)
    this.earnedAt = earnedAt
  }

  isDisqualified () {
    return this.disqualified === true
  }

  isValidOnDate (date) {
    const msec = date.getTime()

    return (this.validFromAt.getTime() < msec && msec < this.validToAt.getTime())
  }

  getStatus () {
    const now = Date.now()
    const validFromAt = new Date(this.validFromAt).getTime()
    const validToAt = new Date(this.validToAt).getTime()

    if (this.status === 'suspended') {
      return 'suspended'
    }

    if (this.isDisqualified()) {
      return 'disqualified'
    }

    if (this.earnedAt) {
      return 'earned'
    }

    if (validFromAt < now && validToAt > now) {
      return 'active'
    } else if (validToAt < now) {
      return 'expired'
    } else if (now < validFromAt) {
      return 'pending'
    }
  }

  getProgressAsPercentage () {
    return 100 * this.numberOfCompletedDeliveries / this.requiredNumberOfDeliveries
  }
}

module.exports = exports = DriverChallengeQualificationLogic
