const { valueToBool } = require('../util')

const ENV_REGEX = '([A-Z0-9_]+)'

/**
 * Any environment variable that is required should be wrapped in a call to
 * this "required" function, but also, reading the process.env.XXX variable must
 * be wrapped in a simple lambda. We leverage toString on this lambda to extract
 * the process.env variable name in order to throw with a helpful error message.
 * e.g. APP_SERVICE_BASE_URL: required(() => process.env.APP_SERVICE_BASE_URL)
 */
function required (valueFn) {
  const value = valueFn()

  if (value === undefined) {
    const [, name] = valueFn.toString().match(new RegExp(ENV_REGEX, 'u'))
    throw new Error(`Missing required environment variable: ${name}`)
  }

  return value
}

function createConfig (config) {
  return new Proxy(config, {
    set: function (target, prop) {
      throw new Error(`Config is read-only. Cannot write to property: ${prop}`)
    },
    get: function (target, prop, receiver) {
      if (!(prop in target) && new RegExp(`^${ENV_REGEX}$`, 'u').test(prop)) {
        throw new Error(`Trying to read missing Config property: ${prop}`)
      }

      return Reflect.get(...arguments)
    }
  })
}

exports.required = required
exports.createConfig = createConfig
exports.toBool = valueToBool
