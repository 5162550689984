/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useState } from 'react'
import { COLORS } from '@trexity/common/color/constants'
import PropTypes from 'prop-types'

export const SelectItem = ({
  children,
  onMouseEnter = () => { },
  onFocus = () => { },
  onMouseLeave = () => { },
  onBlur = () => { },
  ...rest
}) => {
  const [active, setActive] = useState()
  return (
    <button
      {...rest}
      style={DropItemStyles(active)}
      onMouseEnter={() => { onMouseEnter(); setActive(true) }}
      onFocus={() => { onFocus(); setActive(true) }}
      onMouseLeave={() => { onMouseLeave(); setActive(false) }}
      onBlur={() => { onBlur(); setActive(false) }}
    >
      {children}
    </button>
  )
}

SelectItem.propTypes = {
  children: PropTypes.node,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func
}

export const DropItemStyles = (active) => ({
  backgroundColor: active ? COLORS.LIGHT : COLORS.DARK,
  border: 'none',
  padding: 10,
  fontSize: 'inherit',
  cursor: 'pointer',
  width: '100%',
  color: active ? COLORS.DARK : COLORS.WHITE,
  textAlign: 'left',
  borderWidth: 'none',
  outline: 'none'
})
