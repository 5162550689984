module.exports = function validateVehicleYear (year) {
  const yearNumber = parseInt(year, 10)
  const currentYear = new Date().getFullYear()

  // Note: we accept up to one year ahead because newest cars are usually dated as such
  return (
    yearNumber &&
    yearNumber > 1900 &&
    yearNumber <= currentYear + 1
  )
}
