import React from 'react'
import { Tabs } from 'antd'
import Editor from './Editor'

export default function DriverDashboard ({
  isAdmin,
  mode,
  deps
}) {
  const {
    send,
    current,
    data
  } = deps

  const {
    driverDashboardDriverId: driverId
  } = data

  let activeKey = null

  if (current.within('Driver Dashboard TBD')) {
    activeKey = 'tbd'
  } else {
    activeKey = 'editor'
  }

  return (
    <Tabs
      type='card'
      activeKey={activeKey}
      onChange={(key) => send({ type: 'route driver dashboard', tab: key, id: driverId })}
      tabBarStyle={{ marginBottom: 1 }}
    >
      <Tabs.TabPane tab={<span>Editor</span>} key='editor' style={{ padding: '10px 5px' }}>
        <Editor isAdmin={isAdmin} mode={mode} deps={deps} />
      </Tabs.TabPane>
    </Tabs>
  )
}
