/**
 * Standardize the display of money throughout the packages.
 * @param {number} valueInCents
 * @returns {string}
 */
module.exports = function getFormattedMonetaryValueString (valueInCents, { locale = 'en-CA', currency = 'CAD', significantDigits = 2 } = {}) {
  locale = locale || 'en-CA'
  currency = currency || 'CAD'

  let dollars = parseFloat((valueInCents / 100).toFixed(significantDigits))
  dollars = Number.isFinite(dollars) ? dollars : 0

  if (typeof Intl === 'object') {
    return new Intl.NumberFormat(locale, { minimumFractionDigits: significantDigits, maximumFractionDigits: significantDigits, style: 'currency', currency })
      .format(dollars)
      // TEMPORARY:
      // Node < 13.0 hardcodes locale to en-US so the country
      // code will be added to the text returned.
      .replace(locale.split('-')[1], '')
  }

  return `$\u00A0${dollars}`
}
