import * as React from 'react'
import * as PropTypes from 'prop-types'
import { Select } from 'antd'
import { debounce } from '@trexity/common/util'

/**
 * Component that renders a select field that performs a search for
 * matching data to provide new select options.
 *
 * The onSearch and onChange callbacks are automatically debounced with a 300ms
 * wait time so they will not be called too frequently.
 *
 * Options objects can have these values:
 * value, label and disabled
 *
 * value and label are required.
 *
 * @example
 * <SearchSelect
 *   placeholder='Select driver…'
 *   staticOptions={[
 *     { value: '@any', label: 'Any driver' }
 *   ]},
 *   dynamicOptions={
 *     searchedValues.map(x => ({ value: x.id, label: x.name }))
 *   }
 *   onSearch={searchPattern => …}
 *   onChange={selectedValues => …} />
 */
export default function SearchSelect (props) {
  const {
    placeholder,
    value,
    staticOptions = [],
    dynamicOptions = [],
    multiple = false,
    disabled = false
  } = props

  // State used to indicate that we are waiting for a response
  // from the onSearch callback. So long as the array passed
  // in as dynamicOptions is a different array each time onSearch
  // is called then this component will automatically indicate
  // loading state as expected.
  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {
    setLoading(false)
  }, [dynamicOptions])

  const onSearch = React.useMemo(() => {
    return debounce((pattern) => {
      setLoading(true)
      props.onSearch.call(undefined, pattern)
    }, 300)
  }, [props.onSearch])

  const onChange = React.useMemo(() => {
    return debounce((selected) => {
      props.onChange.call(undefined, [].concat(selected))
    }, 300)
  }, [props.onChange])

  const conditionalProps = {}

  if (multiple) {
    conditionalProps.mode = 'multiple'
  }

  // If the value specified does not exist in the options list
  // then we don't set the value on the Select component so that
  // the placeholder shows up.
  const options = [].concat(staticOptions, dynamicOptions)
  const values = [].concat(value)
  const hasOption = options.some((o) => values.includes(o.value))

  if (hasOption) {
    conditionalProps.value = value
  }

  return (
    <Select
      {...conditionalProps}
      showSearch
      showArrow
      loading={loading}
      disabled={disabled}
      placeholder={placeholder}
      defaultActiveFirstOption={false}
      filterOption={false}
      notFoundContent={null}
      style={{ width: '100%' }}
      onSearch={onSearch}
      onChange={onChange}
    >
      {renderOptions(staticOptions, dynamicOptions)}
    </Select>
  )
}

SearchSelect.propTypes = {
  placeholder: PropTypes.string.isRequired,
  // Event handler called when a record search operation should be performed.
  // (searchPattern: string): void
  onSearch: PropTypes.func.isRequired,
  // Event handler called when a record is selected.
  // (selectedValues: Array<string|number>): void
  onChange: PropTypes.func.isRequired,
  // The selected value(s)
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.number)
  ]),
  // Permits selecting multiple options from the list.
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  // Array of options that will always be listed at the top of the select list.
  staticOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ]).isRequired,
      label: PropTypes.string.isRequired,
      disabled: PropTypes.bool
    })
  ),
  // Array of dynamic options loaded by the onSearch callback.
  dynamicOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ]).isRequired,
      label: PropTypes.string.isRequired
    })
  )
}

const renderOptions = (...options) => {
  return [].concat(...options)
    .filter(Boolean)
    .map(({ value, label, disabled = false }) => (
      <Select.Option value={value} key={value} disabled={disabled}>{label}</Select.Option>
    ))
}
