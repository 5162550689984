/*
Namespace that exposes type checking predicate functions.
*/

/* eslint-disable padding-line-between-statements */

const Std = require('../std')

const isany = () => true
const isnull = (val) => val === null
const isund = (val) => val === undefined
/** Checks if a value is null or undefined. */
const isnullish = (val) => isnull(val) || isund(val)
/** Checks if value is a number. */
const isnum = (val) => typeof val === 'number'
/** Checks if value is a finite number. */
const isnumf = Number.isFinite
const isnan = (val) => isnum(val) && isNaN(val)
/** Checks if a value is a safe integer. */
const isint = Number.isSafeInteger
const isarr = Array.isArray
const isfunc = (val) => typeof val === 'function'
const isstr = (val) => typeof val === 'string'
const isobj = (val) => typeof val === 'object' && !!val
/** Checks if value is a Symbol. */
const issym = (val) => typeof val === 'symbol'
const isbool = (val) => typeof val === 'boolean'
/** Checks if value is a valid Date instance. */
const isdate = (val) => val instanceof Date && isnumf(val.getTime())
/** Checks if value is an iterable. */
const isiter = (val) => isobj(val) && isfunc(val[Symbol.iterator])
/** Checks if value is an async iterable. */
const isiterasync = (val) => isobj(val) && isfunc(val[Symbol.asynciIterator])
/** Checks if value is a generator function. */
const isgen = (val) => isfunc(val) && val.toString().replace(' ', '').startsWith('function*')
/** Checks if value looks like a Promise (i.e. has then, catch and finally methods). */
const isprom = (val) => isobj(val) && isfunc(val.then) && isfunc(val.catch) && isfunc(val.finally)

/**
 * Checks if value is an instance of a constructor function.
 * @curried
 * @example isa(Date, myDate) // true
 * @example [3, 4, new Map()].map(isa(Map)) // [false, false, true]
 * @type {{
 *   (Ctor: Function, val) => boolean
 *   (Ctor: Function) => (val) => boolean
 * }}
 */
const isa = Std.curry((Ctor, val) => {
  if (!isfunc(Ctor)) throw new Error('Ctor must be a function')
  return val instanceof Ctor
})

const isset = isa(Set)
const ismap = isa(Map)
const isregex = isa(RegExp)
const isarrint8 = isa(Int8Array)
const isarruint8 = isa(Uint8Array)
const isarruint8c = isa(Uint8ClampedArray)
const isarrint16 = isa(Int16Array)
const isarruint16 = isa(Uint16Array)
const isarrint32 = isa(Int32Array)
const isarruint32 = isa(Uint32Array)
const isarrfloat32 = isa(Float32Array)
const isarrfloat64 = isa(Float64Array)
// NOTE: These are relatively new and eslint complains
// const isarrbigint64 = isa(BigInt64Array)
// const isarrbiguint64 = isa(BigUint64Array)

const istypedarr = (val) => [
  isarrint8,
  isarruint8,
  isarruint8c,
  isarrint16,
  isarruint16,
  isarrint32,
  isarruint32,
  isarrfloat32,
  isarrfloat64
  // isarrbigint64,
  // isarrbiguint64
].some((pred) => pred(val))

/**
 * Checks if value is an iterable where each item passes the predicate.
 * @curried
 * @example of(isstr, []) // true
 * @example of(isstr, ['hi']) // true
 * @example of(isstr, ['hi', 4]) // false
 * @example of(isstr, {}) // false
 * @example ['hi', '1', 3].map(of(isstr)) // [true, true, false]
 * @type {{
 *   (pred: (val) => boolean, val) => boolean
 *   (pred: (val) => boolean) => (val) => boolean
 * }}
 */
const of = function (pred, val) {
  Std.assert(isfunc(pred), 'pred must be a function')

  const validate = (val) => {
    const problems = []

    if (!isiter(val)) {
      problems.push({ key: '', message: 'val must be an iterable', val })
      return problems
    }

    [...val].forEach((it, i) => {
      if (!pred(it)) problems.push({ key: String(i), message: `index ${i} is invalid`, val: it })
    })

    return problems
  }

  if (arguments.length === 1) return Object.assign((val) => of(pred, val), { validate })

  return !validate(val).length
}

const arrof = Std.curry((pred, val) => isarr(val) && of(pred, val))

/**
 * Checks if value is a tuple. A tuple is an array with fixed length
 * and each item is strictly typed.
 * @curried
 * @example istup([isstr, isnum], ['hi', 2]) // true
 * @example istup([isstr, isnum], ['hi', '2']) // false
 * @example istup([isstr, isnum], []) // false
 * @example [['1', 2], [1, 2]].map(istup([isstr, isnum])) // [true, false]
 * @type {{
 *   (tup: Array<(val) => boolean>, val) => boolean
 *   (tup: Array<(val) => boolean>) => (val) => boolean
 * }}
 */
const istup = Std.curry((tup, val) => {
  Std.assert(isarr(tup) && tup.length, 'tup must be a non-empty array')
  Std.assert(of(isfunc, tup), 'tup must be an array of predicate functions')
  return isarr(val) &&
    val.length === tup.length &&
    tup.every((pred, i) => pred(val[i]))
})

/**
 * Checks if value is strictly equal to one of the values from a list of values.
 * @curried
 * @example isoneof(['active', 'inactive'], 'active') // true
 * @example isoneof(['active', 'inactive'], 'nope') // false
 * @example isoneof(['active', 'inactive'], 4) // false
 * @example [4, 'inactive'].map(isoneof(['active', 'inactive'])) // [true, false]
 * @type {{
 *   (vals: any[], val) => boolean
 *   (vals: any[]) => (val) => boolean
 * }}
 */
const isoneof = Std.curry((vals, val) => {
  Std.assert(isiter(vals), 'vals must be an iterable')
  vals = [...vals]
  Std.assert(vals.length, 'vals must be a non-empty iterable')
  return vals.includes(val)
})

/** @typedef {{ (val) => boolean, key }} KeyedPredicate */

/**
 * Checks a key on an object or Map.
 * @example key('age', isint, { age: 45 }) // true
 * @example key('age', isint, { age: 45.4 }) // false
 * @example key('age', isint, { name: 'Dave' }) // false
 * @example key('age', isint, null) // false
 * @example key('age', isint)({ name: 'Dave' }) // false
 * @example key('age', isint).key // 'age'
 * @type {{
 *   (key, pred: (val) => boolean, val) => boolean
 *   (key, pred: (val) => boolean) => KeyedPredicate
 * }}
 */
const key = function _key (key, pred, val) {
  Std.assert(isfunc(pred), 'pred must be a function')
  if (arguments.length === 2) return Object.assign((val) => _key(key, pred, val), { key })
  return Std.hasKey(val, key) && pred(Std.get(val, key))
}

/** @typedef {{ req?: KeyedPredicate[], opt?: KeyedPredicate[], noExtraKeys?: boolean }} KeysPredicates */

/**
 * Check keys on an object or Map.
 * @curried
 * @example keys({ req: [key('name', isstr)] }, { name: 'Dave' }) // true
 * @example keys({ req: [key('name', isstr)] }, new Map(Object.entries({ name: 4 }))) // false
 * @example keys({ req: [key('name', isstr)] }, { name: null }) // false
 * @example keys({ req: [key('name', isstr)] }, { name: undefined }) // false
 * @example keys({ req: [key('name', isstr)] }, {}) // false
 * @example keys({ req: [key('name', isstr)], opt: [key('age', isint)] }, { name: 'Dave' }) // true
 * @example keys({ req: [key('name', isstr)], opt: [key('age', isint)] }).validate({ name: 'Dave', age: 'hi' }) // [{ key: 'age', message: 'age key is invalid', val: 'hi' }]
 * @type {{
 *   (preds: KeysPredicates, val) => boolean
 *   (preds: KeysPredicates) => {
 *     (val) => boolean
 *     validate: (val) => { key, message:string, val }[]
 *   }
 * }}
 */
const keys = function ({ req = [], opt = [], noExtraKeys = false }, val) {
  Std.assert(or(isnullish, of(isfunc))(req), 'req must be an iterable of predicate functions')
  Std.assert(or(isnullish, of(isfunc))(opt), 'opt must be an iterable of predicate functions')

  req = req || [...req]
  opt = opt || [...opt]

  const validate = (val) => {
    const problems = []

    if (!isobj(val)) {
      problems.push({ key: '', message: 'val must be an object', val })
      return problems
    }

    for (const pred of req) {
      if (!pred(val)) problems.push({ key: pred.key, message: `${pred.key} key is invalid`, val: Std.get(val, pred.key) })
    }

    for (const pred of opt) {
      if (pred.key && isnullish(Std.get(val, pred.key))) continue
      if (!pred(val)) problems.push({ key: pred.key, message: `${pred.value} key is invalid`, val: Std.get(val, pred.key) })
    }

    if (noExtraKeys) {
      const allowedKeys = req.map((pred) => pred.key).concat(opt.map((pred) => pred.key))

      Std.keys(val).forEach((key) => {
        if (!allowedKeys.includes(key)) {
          problems.push({ key, message: 'unsupported key found', val: Std.get(val, key) })
        }
      })
    }

    return problems
  }

  if (arguments.length === 1) return Object.assign((val) => keys({ req, opt, noExtraKeys }, val), { validate })

  return !validate(val).length
}

/**
 * Return a new function that will check that the passed value passes all predicates.
 * @example and(isint, x => x >= 0)(4) // true
 * @example and(isint, x => x >= 0)(-4) // false
 * @param {Array<(val) => boolean | Array<(val) => boolean>>} preds
 * @return {(val) => boolean}
 */
const and = (...preds) => {
  preds = [].concat(...preds)
  Std.assert(of(isfunc, preds), 'preds must be an iterable of predicate functions')
  return (val) => preds.every((pred) => pred(val))
}

/**
 * Return a new function that will check that the passed value passes some of the predicates.
 * @example or(isint, isstr)(4) // true
 * @example or([isint], [isstr])(-4) // true
 * @example or([isint, isstr])('4') // true
 * @example or(isint, isstr)([]) // false
 * @example or(isint, isstr)(null) // false
 * @param {Array<(val) => boolean | Array<(val) => boolean>>} preds
 * @return {(val) => boolean}
 */
const or = (...preds) => {
  preds = [].concat(...preds)
  Std.assert(of(isfunc, preds), 'preds must be predicate functions')
  return (val) => preds.some((pred) => pred(val))
}

exports.isany = isany
exports.issym = issym
exports.isnull = isnull
exports.isund = isund
exports.isnullish = isnullish
exports.isnum = isnum
exports.isnumf = isnumf
exports.isnan = isnan
exports.isint = isint
exports.isarr = isarr
exports.isfunc = isfunc
exports.isbool = isbool
exports.isstr = isstr
exports.isobj = isobj
exports.isdate = isdate
exports.isiter = isiter
exports.isiterasync = isiterasync
exports.isgen = isgen
exports.isprom = isprom
exports.isa = isa
exports.isset = isset
exports.ismap = ismap
exports.isregex = isregex
exports.isarrint8 = isarrint8
exports.isarruint8 = isarruint8
exports.isarruint8c = isarruint8c
exports.isarrint16 = isarrint16
exports.isarruint16 = isarruint16
exports.isarrint32 = isarrint32
exports.isarruint32 = isarruint32
exports.isarrfloat32 = isarrfloat32
exports.isarrfloat64 = isarrfloat64
// exports.isarrbigint64 = isarrbigint64
// exports.isarrbiguint64 = isarrbiguint64
exports.istypedarr = istypedarr
exports.of = of
exports.arrof = arrof
exports.istup = istup
exports.isoneof = isoneof
exports.key = key
exports.keys = keys
exports.and = and
exports.or = or
