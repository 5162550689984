import React from 'react'
import PropTypes from 'prop-types'
import { Skeleton, Input, Table, Modal, Button, Tag, Descriptions, Tabs } from 'antd'
import { convertOrdersToArray } from '@trexity/common/models/ShipmentOrder'
import { getFormattedDateAndTimeFromDate, getFormattedDurationString } from '@trexity/common/temporal'
import { formatPhoneNumber } from '@trexity/common/phone'

const HIGHLIGHTED_TEXT_COLOR = 'black'
const CHANNEL_ONE_COLOR = 'red'
const CHANNEL_TWO_COLOR = 'blue'

export default function Recordings ({
  deps
}) {
  const {
    current,
    data,
    RouteLink
  } = deps

  const {
    shipmentDashboardShipment: shipment,
    shipmentDashboardProxySessions: proxySessions
  } = data

  const isLoading = current.within('Loading Recordings')
  const isReady = current.within('Showing Recordings')

  const [recordingSearchValue, setRecordingSearchValue] = React.useState('')
  const [modalIsVisible, setModalIsVisible] = React.useState(false)
  const [modalProxySession, setModalProxySession] = React.useState(null)
  const [channelOneRole, setChannelOneRole] = React.useState('')
  const [channelTwoRole, setChannelTwoRole] = React.useState('')
  const [currentModalOrder, setCurrentModalOrder] = React.useState(null)

  const audioEl = React.useRef(null)
  const fragmentEl = React.useRef(null)

  const tryRewindTo = (time) => {
    if (audioEl.current) {
      audioEl.current.currentTime = time
    }
  }

  const filteredProxySessions = proxySessions || []

  const columns = [
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: function Status (_, record) {
        if (record.endedAt) {
          return <Tag color='green'>Completed</Tag>
        }

        if (record.pickedUpAt) {
          return <Tag color='orange'>In progress</Tag>
        }

        if (record.calledAt) {
          const now = Date.now()
          const secondsSinceCalling = (now - record.calledAt.getTime()) / 1000

          if (secondsSinceCalling > 2 * 60) {
            // After 5 minutes of ringing, we assume nobody picked up.

            return <Tag color='red'>Not answered</Tag>
          }

          return <Tag color='yellow'>Ringing</Tag>
        }

        return <Tag color='red'>Uninitiated</Tag>
      }
    },
    {
      title: 'Called At',
      dataIndex: 'calledAt',
      key: 'calledAt',
      render: function CalledAt (_, record) {
        return record.calledAt ? getFormattedDateAndTimeFromDate(record.calledAt) : '/'
      }
    },
    {
      title: 'Duration',
      dataIndex: 'duration',
      key: 'duration',
      render: function Duration (_, record) {
        if (record.pickedUpAt && record.endedAt) {
          return getFormattedDurationString((record.endedAt.getTime() - record.pickedUpAt.getTime()) / 1000)
        }

        return ''
      }
    },
    { title: 'Initiated By', dataIndex: 'initiatedBy', key: 'initiatedBy' },
    { title: 'Received By', dataIndex: 'receivedBy', key: 'receivedBy' },
    {
      title: 'Order ID',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => {
        if (shipment) {
          const orders = convertOrdersToArray(shipment.orders)

          if (record.orderUuid) {
            const order = orders && orders.find((order) => order.id === record.orderUuid)
            return (order && order.orderId) || 'N/A'
          } else {
            return 'N/A'
          }
        } else {
          return 'N/A'
        }
      }
    },
    {
      title: 'Recording Status',
      dataIndex: 'recordingStatus',
      key: 'recordingStatus',
      render: function RecordingStatus (_, record) {
        if (!record.recordingStorageUrl) {
          return <Tag color='red'>Not available</Tag>
        } else {
          return <Tag color='green'>Available</Tag>
        }
      }
    },
    {
      title: 'Transcription Status',
      dataIndex: 'transcriptionStatus',
      key: 'transcriptionStatus',
      render: function TranscriptionStatus (_, record) {
        if (record.transcriptionStatus === 'unstarted') {
          return <Tag color='yellow'>Unstarted</Tag>
        } else if (record.transcriptionStatus === 'in-progress') {
          return <Tag color='orange'>Processing</Tag>
        } else {
          return <Tag color='green'>Available</Tag>
        }
      }
    },
    {
      title: 'Details',
      dataIndex: 'recordingStorageUrl',
      key: 'recordingStorageUrl',
      render: function Details (_, record) {
        if (!record.recordingStorageUrl) {
          return 'Not available yet'
        }

        return (
          <Button onClick={() => {
            if (record.orderUuid) {
              const orders = convertOrdersToArray(shipment.orders)

              const order = orders && orders.find((order) => order.id === record.orderUuid)
              setCurrentModalOrder(order)
            }

            setModalProxySession(record)
            setChannelOneRole(record.initiatedBy)
            setChannelTwoRole(record.receivedBy)
            setModalIsVisible(true)
          }}
          >
            Open
          </Button>
        )
      }
    }
  ]

  const renderTranscript = (transcriptionResponse) => {
    const allWords = (transcriptionResponse && transcriptionResponse.results && transcriptionResponse.results.reduce((acc, val) => {
      const words = val.alternatives[0].words.map((word) => ({
        ...word,
        channelTag: val.channelTag
      }))

      return [
        ...acc,
        ...words
      ]
    }, [])) || []

    return (
      <div ref={fragmentEl}>
        {allWords.map((word) => {
          // nodejs-speech and Google REST API return two different things.
          // startTime is an object in nodejs-speech; it is a string in Google REST API.
          // We handle both, just in case.
          // (nanos may not be present, in some exceptional cases - undocumented)
          if (typeof (word.startTime) === 'object') {
            word.startTime = `${Number(word.startTime.seconds) + Number((word.startTime.nanos || 0) / 1000000000)}s`
          }

          if (typeof (word.endTime) === 'object') {
            word.endTime = `${Number(word.endTime.seconds) + Number((word.endTime.nanos || 0) / 1000000000)}s`
          }

          const startTime = Number(word.startTime.slice(0, -1))
          const endTime = Number(word.endTime.slice(0, -1))

          const fallbackColor = Number(word.channelTag) === 1 ? CHANNEL_ONE_COLOR : CHANNEL_TWO_COLOR

          return (
            <div
              style={{ display: 'inline-block', marginLeft: '3px', cursor: 'pointer', color: fallbackColor }}
              onClick={() => {
                tryRewindTo(startTime)
              }}
              starttime={startTime}
              endtime={endTime}
              fallbackcolor={fallbackColor}
              key={`${word.channelTag}-${startTime}-${endTime}`}
            >
              {word.word}
            </div>
          )
        })}
      </div>
    )
  }

  return (
    isReady ? (
      <React.Fragment>
        <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'row' }}>
          <Input.Search
            placeholder='Search…'
            enterButton='Search'
            defaultValue={recordingSearchValue}
            onSearch={(searchQuery) => setRecordingSearchValue(searchQuery)}
          />
        </div>
        <Table
          columns={columns}
          dataSource={filteredProxySessions}
          loading={isLoading}
          size='small'
          bordered={false}
          scroll={{ x: true }}
          pagination={{
            pageSize: 15
          }}
        />

        <Modal
          footer={null}
          title='Recording and Subscriptions'
          visible={modalIsVisible}
          afterClose={() => {
            setModalProxySession(null)
            setModalIsVisible(false)
          }}
          onCancel={() => {
            setModalProxySession(null)
            setModalIsVisible(false)
          }}
          onOk={() => {
            setModalProxySession(null)
            setModalIsVisible(false)
          }}
        >
          {modalProxySession ? (
            <Tabs defaultActiveKey='1'>
              <Tabs.TabPane tab='Transcription and Recording' key='1'>
                <div style={{
                  marginTop: 10,
                  padding: 15,
                  marginBottom: 10,
                  border: '2px solid black',
                  maxHeight: '500px',
                  overflowY: 'scroll'
                }}
                >
                  <div>
                    <div style={{ display: 'inline-block', marginRight: '3px', marginBottom: '5px' }}>
                      {channelOneRole === 'driver' ? (
                        <RouteLink
                          routeKey='Driver Dashboard'
                          params={{ id: modalProxySession.driverId }}
                        >
                          <span style={{ color: CHANNEL_ONE_COLOR }}>Driver</span>
                        </RouteLink>
                      ) : null}
                      {channelOneRole === 'merchant' ? (
                        <RouteLink
                          routeKey='Merchant Dashboard'
                          params={{ id: modalProxySession.merchantId }}
                        >
                          <span style={{ color: CHANNEL_ONE_COLOR }}>Merchant</span>
                        </RouteLink>
                      ) : null}
                    </div>

                    <div style={{ display: 'inline-block', marginRight: '3px' }}>
                      {channelTwoRole === 'driver' ? (
                        <RouteLink
                          routeKey='Driver Dashboard'
                          params={{ id: modalProxySession.driverId }}
                        >
                          <span style={{ color: CHANNEL_TWO_COLOR }}>Driver</span>
                        </RouteLink>
                      ) : null}
                      {channelTwoRole === 'merchant' ? (
                        <RouteLink
                          routeKey='Merchant Dashboard'
                          params={{ id: modalProxySession.merchantId }}
                        >
                          <span style={{ color: CHANNEL_TWO_COLOR }}>Merchant</span>
                        </RouteLink>
                      ) : null}
                      {channelTwoRole === 'customer' ? (
                        <span style={{ color: CHANNEL_TWO_COLOR }}>Customer ({currentModalOrder ? currentModalOrder.name : 'N/A'})</span>
                      ) : null}
                    </div>
                  </div>

                  {((modalProxySession.transcriptionStatus === 'completed') && renderTranscript(modalProxySession.transcriptionResponse)) || 'Transcription not available yet; please try again later.'}
                </div>

                {modalProxySession.recordingStorageUrl ? (
                  <audio
                    ref={audioEl}
                    controls
                    muted
                    onTimeUpdate={(s) => {
                      const divs = fragmentEl.current && fragmentEl.current.children
                      const currentTime = (audioEl.current && audioEl.current.currentTime) || 0

                      // TODO: Make sure this element is in viewport (auto-scroll to it if needed)

                      for (const div of divs) {
                        const startTime = Number(div.getAttribute('starttime'))
                        const endTime = Number(div.getAttribute('endtime'))
                        const fallbackColor = div.getAttribute('fallbackColor')

                        if (startTime <= currentTime && currentTime <= endTime) {
                          div.style.color = HIGHLIGHTED_TEXT_COLOR
                          div.style['font-weight'] = 'bold'
                          div.style['text-decoration'] = 'underline'
                        } else {
                          div.style.color = fallbackColor
                          div.style['font-weight'] = 'normal'
                          div.style['text-decoration'] = 'none'
                        }
                      }
                    }}
                  >
                    <source src={modalProxySession.recordingStorageUrl} />
                  </audio>
                ) : null}
              </Tabs.TabPane>
              <Tabs.TabPane tab='Details' key='2'>
                <Descriptions bordered size='small' column={1}>
                  <Descriptions.Item label='Proxy Session Id'>{modalProxySession.id}</Descriptions.Item>
                  <Descriptions.Item label='Created At'>{getFormattedDateAndTimeFromDate(modalProxySession.createdAt)}</Descriptions.Item>
                  <Descriptions.Item label='Called At'>{modalProxySession.calledAt && getFormattedDateAndTimeFromDate(modalProxySession.calledAt)}</Descriptions.Item>
                  <Descriptions.Item label='Picked Up At'>{modalProxySession.pickedUpAt && getFormattedDateAndTimeFromDate(modalProxySession.pickedUpAt)}</Descriptions.Item>
                  <Descriptions.Item label='Ended At'>{modalProxySession.endedAt && getFormattedDateAndTimeFromDate(modalProxySession.endedAt)}</Descriptions.Item>
                  <Descriptions.Item label='Proxy Phone #'>{modalProxySession.calledPhoneNumber && formatPhoneNumber(modalProxySession.calledPhoneNumber)}</Descriptions.Item>
                  <Descriptions.Item label={`Caller Phone # (${modalProxySession.initiatedBy})`}>{modalProxySession.callerPhoneNumber && formatPhoneNumber(modalProxySession.callerPhoneNumber)}</Descriptions.Item>
                  <Descriptions.Item label={`Callee Phone # (${modalProxySession.receivedBy})`}>{modalProxySession.dialPhoneNumber && formatPhoneNumber(modalProxySession.dialPhoneNumber)}</Descriptions.Item>
                </Descriptions>
              </Tabs.TabPane>
            </Tabs>
          ) : null}

        </Modal>
      </React.Fragment>
    ) : (
      <Skeleton active />
    )
  )
}

Recordings.propTypes = {
  deps: PropTypes.exact({
    send: PropTypes.func,
    current: PropTypes.object,
    data: PropTypes.object,
    tc: PropTypes.object,
    RouteLink: PropTypes.func
  }).isRequired
}
