/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { COLORS } from '@trexity/common/color/constants'
import PropTypes from '@trexity/common/util/PropTypes'

export const CenteredLayout = ({ children, vertical }) => {
  return (
    <section css={{ display: 'flex', justifyContent: 'center', backgroundColor: COLORS.WHITE, height: '100%', alignItems: vertical ? 'center' : 'flex-start', position: 'relative' }}>
      <div css={{ width: '50%', minWidth: 480 }}>
        {children}
      </div>
    </section>
  )
}

CenteredLayout.propTypes = {
  children: PropTypes.node,
  vertical: PropTypes.bool
}
