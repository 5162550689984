import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'antd'

export function ForcePickupButton ({
  shipment,
  style = {},
  deps
}) {
  const {
    send,
    current
  } = deps

  const forcePickupLoading = (
    current.within('Force Picking Up Shipment')
  )

  const canForcePickup = (
    shipment.currentStatus === 'EN_ROUTE_TO_PICKUP' ||
    shipment.currentStatus === 'ARRIVED_AT_PICKUP'
  )

  return (
    <Button
      type='primary'
      loading={forcePickupLoading}
      disabled={!canForcePickup}
      style={style}
      onClick={() => {
        send({ type: 'force pickup shipment', shipmentId: shipment.id, driverId: shipment.driverId })
      }}
    >Force Pickup</Button>
  )
}

ForcePickupButton.propTypes = {
  shipment: PropTypes.object.isRequired,
  style: PropTypes.object,
  deps: PropTypes.exact({
    send: PropTypes.func,
    current: PropTypes.object
  }).isRequired
}
