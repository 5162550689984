const stringToHash = (stringVal) => {
  let hash = 0

  for (let i = 0; i < stringVal.length; i++) {
    hash = stringVal.charCodeAt(i) + ((hash << 5) - hash)
  }

  return hash
}

const stringToHslColor = (stringVal, saturation, lightness) => {
  const hash = stringToHash(stringVal)

  const hue = hash % 360
  return 'hsl(' + hue + ', ' + saturation + '%, ' + lightness + '%)'
}

module.exports = stringToHslColor
