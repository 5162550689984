import { useState, useEffect, useCallback } from 'react'
import ReactDOM from 'react-dom'
import { COLORS } from '@trexity/common/color/constants'
import PropTypes from 'prop-types'
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { IconMarker } from '../../Markers/Icon'
import { ClearButton } from '../../Button/ClearButton'

// TRX-SCOTT-TODO::: Double check this jives with Mat's changes to StyledPlacesDropdown

// TODO: This 'value' should be 'defaultValue' or 'firstValue' or something like that, because
// of this warning:
//
// Warning: A component is changing an uncontrolled input of type undefined to be controlled.

export const Input = ({
  style,
  errorActive,
  value,
  onChange,
  setInputNode,
  multi = false,
  iconRight,
  iconRightClick = () => { },
  label,
  ...rest
}) => {
  const [focused, setFocused] = useState(false)
  const [hovered, setHovered] = useState(false)
  const [internalValue, setInternalValue] = useState(value)
  let borderColor = COLORS.MEDIUM_LIGHT

  if (errorActive) {
    borderColor = COLORS.TREXITY_RED
  } else if (hovered || focused) {
    borderColor = COLORS.TREXITY_BLUE
  }

  const inputRef = useCallback((node) => {
    if (node !== null && typeof setInputNode === 'function') {
      // eslint-disable-next-line react/no-find-dom-node
      setInputNode(ReactDOM.findDOMNode(node))
    }
  }, [setInputNode])

  useEffect(() => {
    setInternalValue(value)
  }, [value])

  const inputContainer = {
    position: 'relative',
    zIndex: 1
  }

  const placeholderContainer = {
    position: 'absolute',
    top: 5,
    left: 10,
    fontSize: '10px',
    lineHeight: '10px',
    fontWeight: 600,
    color: COLORS.DARK,
    zIndex: 2,
    transition: 'all ease-in-out 0.3s'
  }

  const inProps = {
    ref: inputRef,
    value: internalValue,
    onChange: (e) => { e.stopPropagation(); e.preventDefault(); setInternalValue(e.target.value); if (onChange) { onChange(e) } },
    onMouseEnter: () => setHovered(true),
    onMouseLeave: () => setHovered(false),
    onFocus: () => setFocused(true),
    onBlur: () => setFocused(false),
    placeholder: label,
    css: InputStyles({ borderColor, internalValue, focused, style, multi }),
    ...rest
  }

  const InputComponent = multi ? <textarea rows={4} {...inProps} /> : <input {...inProps} />

  return (
    <div style={inputContainer}>
      {internalValue ? (
        <div style={placeholderContainer}>
          {label}
        </div>
      ) : null}
      {InputComponent}
      {iconRight ? <div css={{ position: 'absolute', right: 10, top: 9, zIndex: 1 }}><ClearButton onClick={iconRightClick}><IconMarker light icon={iconRight} bg={COLORS.DARK} /></ClearButton></div> : null}
    </div>
  )
}

export const InputStyles = ({ borderColor, internalValue, focused, style, multi }) => (
  {
    display: 'block',
    borderStyle: 'solid',
    borderColor,
    borderWidth: 1,
    borderRadius: 10,
    transition: 'all ease-in-out 0.3s',
    padding: internalValue ? '16px 10px 4px 10px' : 10,
    height: multi ? 'auto' : 44,
    outline: 0,
    color: COLORS.DARK,
    position: 'relative',
    zIndex: 1,
    width: '100%',
    maxHeight: multi ? '100px' : 'none',
    boxShadow: focused ? `0px 0px 1px ${COLORS.TREXITY_BLUE}` : 'none',
    ...style
  })

Input.propTypes = {
  style: PropTypes.object,
  errorActive: PropTypes.bool,
  value: PropTypes.string,
  setValue: PropTypes.func,
  setInputNode: PropTypes.func,
  multi: PropTypes.bool,
  onChange: PropTypes.func,
  iconRight: PropTypes.string,
  iconRightClick: PropTypes.func,
  label: PropTypes.string
}
