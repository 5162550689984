const { PHONE_EXTENSIONS } = require('./constants')

const { STORED_EXTENSION } = PHONE_EXTENSIONS

module.exports = function validatePhoneNumber (phone) {
  const phoneWithoutExt = String(phone).split(STORED_EXTENSION)[0]

  // Previous implementation: https://www.oreilly.com/library/view/regular-expressions-cookbook/9781449327453/ch04s03.html
  // const regex = /^\+(?:[0-9] ?){6,14}[0-9]$/u

  // Current, strict north-american implementation
  const regex = /^\+1 [0-9]{3} [0-9]{3} [0-9]{4}$/u

  // Exact length of 15 for north-american only, so that it follows this exact
  // pattern: "+1 111 111 1111"
  return phoneWithoutExt.length === 15 && regex.test(phoneWithoutExt)
}
