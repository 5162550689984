import React from 'react'
import { Spin } from 'antd'
import ReactJson from 'react-json-view'

export default function DriverDevicesTable ({
  devices = [],
  loading = false
}) {
  const LoadingWrapper = loading
    ? ({ children }) => <Spin>{children}</Spin>
    : ({ children }) => children

  return (
    <LoadingWrapper>
      <div style={{ overflowX: 'auto' }}>
        <table border={1} cellPadding={2} style={{ borderColor: '#aaa', width: '100%' }}>
          <thead>
            <tr>
              <th>OS</th>
              <th>Version</th>
              <th>Full details</th>
            </tr>
          </thead>
          <tbody>
            {devices.length ? (
              devices.map((device) => {
                return (
                  <tr key={device.id}>
                    <td>{device.os}</td>
                    <td>{device.version}</td>
                    <td>
                      <ReactJson collapsed={true} src={device} />
                    </td>
                  </tr>
                )
              })
            ) : (
              <tr><td colSpan={99}><em>No devices available</em></td></tr>
            )}
          </tbody>
        </table>
      </div>
    </LoadingWrapper>
  )
}
