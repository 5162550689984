/**
 * Factory function used to create an executable pipeline.
 *
 * If any pipe in the pipeline returns `undefined` then it is treated the same as
 * returning `data`.
 *
 * If any pipe in the pipeline returns `null` then no other pipes will be called and
 * the pipeline return value will be `null`.
 *
 * If any pipe in the pipeline returns an `Error` instance then the error is thrown.
 *
 * @example
 * const summer = Pipeline(
 *   (sum) => sum + 10,
 *   (sum, offset) => sum + offset
 * )
 * summer(0, 10) // 20
 * @param {...{(data, ...args): any}} pipes
 * @return {(data, ...args) => any}
 */
exports.Pipeline = (...pipes) => {
  if (pipes.some((x) => typeof x !== 'function')) {
    throw new TypeError('All "pipes" must be a function')
  }

  return (data, ...args) => {
    return pipes.reduce((data, pipe) => {
      if (data === null) {
        return data
      }

      const result = pipe(data, ...args)

      if (result instanceof Error) {
        throw result
      }

      return result === undefined ? data : result
    }, data)
  }
}

/**
 * Factory function used to create an asynchronous executable pipeline.
 *
 * If any pipe in the pipeline returns or resolves `undefined` then it is treated
 * the same as returning `data`.
 *
 * If any pipe in the pipeline returns or resolves to `null` then no other pipes
 * will be called and the pipeline return value will be `null`.
 *
 * If any pipe in the pipeline returns or resolves an `Error` instance then the
 * error is thrown.
 *
 * @example
 * const summer = Pipeline.async(
 *   (sum) => sum + 10,
 *   (sum, offset) => sum + offset
 * )
 * summer(0, 10).then(sum => console.log(sum)) // 20
 * @param {...{(data, ...args): Promise<any>}} pipes
 * @return {(data, ...args) => Promise<any>}
 */
exports.Pipeline.async = (...pipes) => {
  if (pipes.some((x) => typeof x !== 'function')) {
    throw new TypeError('All "pipes" must be a function')
  }

  return (data, ...args) => {
    return pipes.reduce((p, pipe) => {
      return p.then((data) => {
        if (data === null) {
          return data
        }

        return Promise.resolve(pipe(data, ...args))
          .then((result) => {
            if (result instanceof Error) {
              throw result
            }

            return result === undefined ? data : result
          })
      })
    }, Promise.resolve(data))
  }
}
