// @ts-nocheck
module.exports = function correctOrientation (base64image, orientation, size, maxWidth = null) {
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')
  const { width, height } = size

  let targetWidth = null
  let targetHeight = null

  if ([5, 6, 7, 8].includes(orientation)) {
    targetWidth = height
    targetHeight = width
  } else {
    targetWidth = width
    targetHeight = height
  }

  canvas.width = targetWidth
  canvas.height = targetHeight

  if (orientation === 2) {
    ctx.transform(-1, 0, 0, 1, width, 0)
  } else if (orientation === 3) {
    ctx.transform(-1, 0, 0, -1, width, height)
  } else if (orientation === 4) {
    ctx.transform(1, 0, 0, -1, 0, height)
  } else if (orientation === 5) {
    ctx.transform(0, 1, 1, 0, 0, 0)
  } else if (orientation === 6) {
    ctx.transform(0, 1, -1, 0, height, 0)
  } else if (orientation === 7) {
    ctx.transform(0, -1, -1, 0, height, width)
  } else if (orientation === 8) {
    ctx.transform(0, -1, 1, 0, 0, width)
  } else {
    ctx.transform(1, 0, 0, 1, 0, 0)
  }

  return new Promise((resolve, reject) => {
    const image = new Image()

    image.onload = function () {
      // Out of convenience, we allow a "maxWidth" parameter so as to render
      // this image with a maximum width, with height adjusted proportionally.
      // Normally, we would create a separate function to do this, but we've
      // decided to skip the extra processing step and do it now:
      const scaledWidth = maxWidth !== null && targetWidth > maxWidth
        ? maxWidth
        : targetWidth

      const scaledHeight = maxWidth !== null && targetWidth > maxWidth
        ? (targetHeight / targetWidth) * maxWidth
        : targetHeight

      const resizedCanvas = document.createElement('canvas')
      const resizedCtx = resizedCanvas.getContext('2d')

      resizedCanvas.width = scaledWidth
      resizedCanvas.height = scaledHeight

      ctx.drawImage(image, 0, 0)
      resizedCtx.drawImage(canvas, 0, 0, scaledWidth, scaledHeight)

      resizedCanvas.toBlob((blobFile) => {
        const reader = new FileReader()
        reader.readAsDataURL(blobFile)
        reader.addEventListener('load', () => resolve(reader.result))
        reader.addEventListener('error', (error) => reject(error))
      })
    }

    image.src = base64image
  })
}
