const Luxon = require('./vendor/luxon-business-days')

// This file defines holidays that are considered "bank holidays". Do not add a holiday
// here that the bank does not respect as a business holiday where the bank is closed.

exports['Ottawa, ON'] = []
exports['Toronto, ON'] = []
exports['Gatineau, QC'] = []
exports['Winnipeg, MB'] = []
exports['Calgary, AB'] = []
