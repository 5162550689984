const { Std } = require('../../core')
const { isServiceHours } = require('./isServiceHours')
const { getAllWeekdayHoursInISOOrder } = require('./getAllWeekdayHoursInISOOrder')

/**
 * Given a service hours object, retrieve the weekday hours and sort the weekdays
 * in workweek order with Monday first and Sunday last.
 *
 * @return {WeekdayDeliveryDay[]}
 */
function getAllWeekdayHoursInWorkweekOrder (serviceHours) {
  if (!isServiceHours(serviceHours)) {
    throw Std.err('TypeError')
  }

  // Ensure the days are always sorted in workweek order (M, Tu, W, Th, F, Sat, Sun)
  const days = getAllWeekdayHoursInISOOrder(serviceHours)
  const sunday = days.find((d) => d.day === 0)

  if (sunday) {
    days.shift()
    days.push(sunday)
  }

  return days
}

exports.getAllWeekdayHoursInWorkweekOrder = getAllWeekdayHoursInWorkweekOrder
