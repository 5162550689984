import React from 'react'
import PropTypes from 'prop-types'
import { Empty, Card } from 'antd'
import HoverPhoto from './HoverPhoto'

export default function DriverDetails ({
  driverId,
  driver,
  isAdmin = false,
  deps
}) {
  const {
    RouteLink
  } = deps

  const {
    licencePlateNumber,
    fullName,
    vehicleColor,
    vehicleMake,
    vehicleModel,
    driverPhotoUrl,
    contactPhone
  } = driver

  return (
    driverId ? (
      <Card>
        <Card.Meta
          avatar={(
            <HoverPhoto
              title={`${fullName}'s photo`}
              photoSrc={driverPhotoUrl || 'https://www.flaticon.com/premium-icon/icons/svg/666/666201.svg'}
              thumbnailSize={50}
            />
          )}
          title={isAdmin ? (
            <RouteLink routeKey='Driver Dashboard' params={{ id: driverId }}>{fullName}</RouteLink>
          ) : (
            String(fullName || 'driver').split(' ', 2)[0]
          )}
          description={(
            <div>
              <div><strong>{licencePlateNumber}</strong> ({vehicleColor} {vehicleMake} {vehicleModel})</div>
              {isAdmin ? (
                <div><strong>Contact:</strong> <a href={`tel:${contactPhone}`}>{contactPhone}</a></div>
              ) : null}
            </div>
          )}
        />
      </Card>
    ) : (
      <Empty
        description='Driver unassigned'
        image={<img src='/empty-car-seat.png' alt='Empty car seat' />}
      />
    )
  )
}

DriverDetails.propTypes = {
  driverId: PropTypes.string,
  driver: PropTypes.shape({
    fullName: PropTypes.string,
    licencePlateNumber: PropTypes.string,
    vehicleColor: PropTypes.string,
    vehicleMake: PropTypes.string,
    vehicleModel: PropTypes.string,
    driverPhotoUrl: PropTypes.string,
    contactPhone: PropTypes.string
  }).isRequired,
  isAdmin: PropTypes.bool,
  deps: PropTypes.exact({
    RouteLink: PropTypes.func
  }).isRequired
}
