// assumes a1<=a2, b1<=b2;
// if the sum of two segments cannot fit in the (min,max) interval,
// that must mean they intersect somewhat.
// Otherwise, they have no intersection / no overlap.

/**
 * @param {{start: number, end: number}} interval1 An object describing the first interval segment [start, end]
 * @param {{start: number, end: number}} interval2 An object describing the second interval segment [start, end]
 * @return {boolean} Returns true if there is intersection between the two specified intervals.
 */

module.exports = function isIntervalOverlapping (interval1, interval2) {
  const { start: a1, end: a2 } = interval1
  const { start: b1, end: b2 } = interval2

  if (a1 > a2 || b1 > b2) {
    throw new Error('isIntervalOverlapping: invalid intervals specified (empty sets)')
  }

  return Math.max(a2, b2) - Math.min(a1, b1) < (a2 - a1) + (b2 - b1)
}
