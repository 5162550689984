const CAPABILITIES = {
  vehicleType: {
    displayName: 'Vehicle Type',
    description: 'Vehicle size that can best fit this shipment.',
    default: 'any', // since 'any' isn't here, it will have an index of -1
    oversized: 'large',
    order: 0,
    data: [
      {
        id: 'small',
        description: 'Sedan, hatchback, etc',
        title: 'Small',
        maxWeightKg: 385 * 0.8 // Honda Civic
      },
      {
        id: 'medium',
        title: 'Medium',
        description: 'SUV, minivan, etc',
        maxWeightKg: 680 * 0.8 // Toyota Sienna
      },
      {
        id: 'large',
        description: 'Cargo van, pickup truck, etc',
        title: 'Large',
        maxWeightKg: 1465 * 0.8 // Ford F150
      }
    ],
    filter: function (capability, requirement) {
      if (requirement === 'too_heavy') return false
      const capIndex = this.data.findIndex((obj) => obj.id === capability)
      const reqIndex = this.data.findIndex((obj) => obj.id === requirement)
      return capIndex >= reqIndex
    }
  },
  legalAgeAlcohol: {
    displayName: 'Can Deliver Alcohol',
    description: 'Must be delivered by a driver who can deliver alcohol.',
    default: false,
    order: 3,
    filter: function (capability, requirement) {
      return requirement
        ? capability === requirement
        : true
    }
  },
  perishable: {
    displayName: 'Can Deliver Perishables',
    description: 'Must be delivered by a driver who is equipped to deliver perishable items.',
    default: false,
    order: 4,
    filter: function (capability, requirement) {
      return requirement
        ? capability === requirement
        : true
    }
  },
  returnReusables: {
    displayName: 'Can Return Reusables',
    description: 'Must be delivered by a driver who will return reusables back to pickup. An extra charge may apply.',
    default: false,
    order: 5,
    filter: function (capability, requirement) {
      return requirement
        ? capability === requirement
        : true
    }
  },
  specificMerchantId: {
    displayName: 'Specific Merchant',
    description: 'Shipments will only be sent to drivers who are tied to this merchant',
    default: true,
    order: 6,
    filter: function (capability, requirement, driver, shipment) {
      return requirement
        ? capability === shipment.merchantId
        : true
    }
  },
  internalTrexityStaff: {
    displayName: 'Internal Trexity Staff',
    description: 'Shipment cost will be $0.50',
    default: false,
    order: 97, // apply this almost last (chargeless has higher priority)
    filter: function (capability, requirement) {
      return requirement
        ? capability === requirement
        : true
    }
  },
  internalTrexityDriverSupport: {
    displayName: 'Internal Trexity Driver',
    description: 'Excluded from various offerings only available to regular drivers',
    default: false,
    order: 98, // apply this almost last (chargeless has higher priority)
    filter: function (capability, requirement) {
      return requirement
        ? capability === requirement
        : true
    }
  },
  chargeless: {
    displayName: 'Will Deliver At No Charge',
    description: 'Route cost will be zero',
    default: true,
    order: 100, // always apply this one last
    filter: function (capability, requirement) {
      return requirement
        ? capability === requirement
        : true
    }
  }
}

const DEPRECATED_CAPABILITIES = {
  constructionSiteCompliant: {
    displayName: 'Construction Site Compliant',
    description: 'Has the necessary equipment required to deliver at a construction site.',
    default: false,
    order: 1,
    filter: function (capability, requirement) {
      return requirement
        ? capability === requirement
        : true
    }
  },
  femaleOnly: {
    displayName: 'Female Only',
    description: 'Must be delivered by a female driver.',
    default: false,
    order: 2,
    filter: function (capability, requirement) {
      return requirement
        ? capability === requirement
        : true
    }
  },
  customGadar: {
    displayName: 'GADAR — Custom',
    description: 'Custom flat-rate pricing for GADAR',
    default: true,
    order: 10,
    filter: function (capability, requirement) {
      return requirement
        ? capability === requirement
        : true
    }
  },
  customShoppersDrugMart: {
    displayName: 'Shoppers Drug Mart',
    description: 'Custom flat-rate pricing for Shoppers Drug Mart',
    default: true,
    order: 10,
    filter: function (capability, requirement) {
      return requirement
        ? capability === requirement
        : true
    }
  },
  customLaBottega: {
    displayName: 'La Bottega — Custom',
    description: 'Custom flat-rate pricing for La Bottega',
    default: true,
    order: 10,
    filter: function (capability, requirement) {
      return requirement
        ? capability === requirement
        : true
    }
  },
  customSysco: {
    displayName: 'Sysco — Custom',
    description: 'Custom flat-rate pricing for Sysco',
    default: true,
    order: 10,
    filter: function (capability, requirement) {
      return requirement
        ? capability === requirement
        : true
    }
  },
  customSyscoEdmonton: {
    displayName: 'Sysco Edmonton — Custom',
    description: 'Custom flat-rate pricing for Sysco Edmonton',
    default: true,
    order: 10,
    filter: function (capability, requirement) {
      return requirement
        ? capability === requirement
        : true
    }
  },
  customNextDayNancy: {
    displayName: 'Next Day Nancy',
    description: 'Flat-fee Rate: $5 base + $5 / 20km + $6 / extra stop',
    default: true,
    order: 10,
    filter: function (capability, requirement) {
      return requirement
        ? capability === requirement
        : true
    }
  },
  customBestEffortBetty: {
    displayName: 'Best Effort Betty',
    description: 'Flat-fee Rate: $8 base + $5 / 20km + $6 / extra stop',
    default: true,
    order: 10,
    filter: function (capability, requirement) {
      return requirement
        ? capability === requirement
        : true
    }
  },
  customFreddyFoodthumb: {
    displayName: 'Freddy Foodthumb',
    description: 'Flat-fee Rate: $5 base + $5 / 15km + $6 / extra stop',
    default: true,
    order: 10,
    filter: function (capability, requirement) {
      return requirement
        ? capability === requirement
        : true
    }
  },
  lowMinimum: {
    displayName: 'Low Minimum',
    description: 'Standard rates with $8.00 minimum',
    default: true,
    order: 10,
    filter: function (capability, requirement) {
      return requirement
        ? capability === requirement
        : true
    }
  }
}

exports.CAPABILITIES = CAPABILITIES

exports.LEGACY_CAPABILITIES = {
  ...CAPABILITIES,
  ...DEPRECATED_CAPABILITIES
}
