import { useEffect, useRef, useState } from 'react'
import { MaterialIcon } from './MaterialIcon'
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import PropTypes from 'prop-types'
import { COLORS } from '@trexity/common/color/constants'
import gsap from 'gsap'

export const IconExplosion = ({ active, loop, icon, iconSize = 100, iconColor = COLORS.TREXITY_GREEN, delay = 0.4 }) => {
  const iconRef = useRef()
  const [dotArray] = useState([...Array(100).keys()])

  useEffect(() => {
    if (active && iconRef && iconRef.current) {
      gsap.fromTo(iconRef.current, { scale: 0 }, { scale: 1, delay, transformOrigin: 'center' }, 0)
      gsap.fromTo(iconRef.current, { rotation: 360 }, { rotation: 0, delay, transformOrigin: 'center' }, 0)
      const dots = document.querySelectorAll('.dotexplosion')

      dots.forEach((dot, index) => {
        const angle = (360 / dots.length) * index
        const radius = iconSize / 1.2
        const x = radius * Math.sin(Math.PI * 2 * angle / 360)

        const y = radius * Math.cos(Math.PI * 2 * angle / 360)
        console.log('TO:', { x, y })
        const tl = gsap.timeline()
        tl.fromTo(dot, { x: 0, y: 0, scale: 0 }, { scale: 1, x, y, transformOrigin: 'center', delay: delay + (index / 1000) })
        tl.to(dot, { scale: 0, transformOrigin: 'center' }, '-=0.3')
      })
    }
  }, [active])

  return (
    <div css={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div
        css={css`
        transform: scale(0) rotate(-180deg);
        transform-origin: center;
        position:relative;
        z-index:2
    `}
        ref={iconRef}
      >
        <MaterialIcon icon={icon} size={iconSize} color={iconColor} />
      </div>
      <div css={{ position: 'absolute', zIndex: 1 }}>
        {dotArray.map((item, index) => {
        return <div key={index} className='dotexplosion' css={{ position: 'absolute', display: 'block', width: 10, height: 10, borderRadius: '50%', backgroundColor: COLORS.TREXITY_GREEN }}></div>
          })}
      </div>
    </div>
  )
}

IconExplosion.propTypes = {
  active: PropTypes.bool,
  loop: PropTypes.bool,
  icon: PropTypes.string,
  iconSize: PropTypes.number,
  iconColor: PropTypes.string,
  delay: PropTypes.number
}
