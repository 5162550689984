/*
Namespace that exposes functions for working with enumerations.

Example:
```js
const { Enum } = require('@trexity/common/core')

const DeliveryStatus = {
  Pending: 'pending',
  Dispatched: 'dispatched',
  Accepted: 'accepted'
}

Enum.names(DeliveryStatus) // ['Pending', 'Dispatched', 'Accepted']
Enum.values(DeliveryStatus) // ['pending', 'dispatched', 'accepted']
Enum.valid(DeliveryStatus, 'Pending') // true
Enum.valid(DeliveryStatus, 'pending') // true
Enum.valid(DeliveryStatus, 'nope') // false
Enum.from(DeliveryStatus, 'Pending') // 'pending'
Enum.from(DeliveryStatus, 'pending') // 'pending'
Enum.from(DeliveryStatus, 'nope') // null
Enum.toName(DeliveryStatus, 'Pending') // 'Pending'
Enum.toName(DeliveryStatus, 'pending') // 'Pending'
Enum.toName(DeliveryStatus, 'nope') // null
```
*/

const Std = require('../std')

/**
 * Get the alphanumerically sorted enumeration names from an object or Map.
 * @return {any[]}
 */
const names = (map) => Std.keys(map).sort((a, b) => String(a).localeCompare(String(b)))
/**
 * Get the enumeration values from an object of Map.
 * @return {any[]}
 */
const values = (map) => names(map).map((n) => Std.get(map, n))
/**
 * Determines if the value is within the enumeration object or Map.
 * @return {boolean}
 */
const valid = (map, val) => [...names(map), ...values(map)].includes(val)
/**
 * Retrieves the enumeration name given an enumeration name or value.
 * If no enumeration name is found then returns null.
 * @return {any?}
 */
const toName = (map, val) => names(map).find((n) => n === val || Std.get(map, n) === val) || null

/**
 * Retrieves the enumeration value given an enumeration name or value.
 * If no enumeration value is found then returns null.
 * @return {any}
 */
const from = (map, val) => {
  val = Std.get(map, toName(map, val))
  return val === undefined ? null : val
}

exports.names = names
exports.values = values
exports.valid = valid
exports.from = from
exports.toName = toName
