const splitTime = (time) => {
  const s = time.split(':')
  return [Number(s[0]), Number(s[1])]
}

const isValidTime = (time) => {
  const s = time.split(':')

  if (s.length !== 2) {
    return false
  }

  // if (time.length !== 5) {
  //   return false
  // }

  const hh = Number(s[0])
  const mm = Number(s[1])

  if (hh < 0 || hh > 24 || mm < 0 || mm > 59) {
    return false
  }

  return true
}

module.exports = (operatingHours) => {
  if (typeof operatingHours !== 'object' || !operatingHours) {
    return false
  }

  const keys = Object.keys(operatingHours)

  // For now, operatingHours = { days: [{ day: ..., ranges: [ ...  ]}] }
  // is all we allow

  if (keys.length !== 1 || keys[0] !== 'days') {
    return false
  }

  const days = operatingHours.days

  if (!Array.isArray(days)) {
    return false
  }

  if (days.length > 7) {
    return false
  }

  for (let i = 0; i < days.length; i++) {
    if (typeof (days[i]) !== 'object') {
      return false
    }

    const day = days[i].day

    if (typeof day !== 'number') {
      return false
    }

    if (day < 0 || day > 6) {
      return false
    }

    const ranges = days[i].ranges

    if (!Array.isArray(ranges)) {
      return false
    }

    for (let k = 0; k < ranges.length; k++) {
      const range = ranges[k]

      if (typeof range !== 'object') {
        return false
      }

      const rangeKeys = Object.keys(range)

      if (rangeKeys.length !== 2) {
        return false
      }

      if (!rangeKeys.includes('fromTime') || !rangeKeys.includes('toTime')) {
        return false
      }

      const {
        fromTime,
        toTime
      } = range

      if (!isValidTime(fromTime) || !isValidTime(toTime)) {
        return false
      }

      const [fromTimeHH, fromTimeMM] = splitTime(fromTime)
      const [toTimeHH, toTimeMM] = splitTime(toTime)

      if (fromTimeHH > toTimeHH ||
        (fromTimeHH === toTimeHH && fromTimeMM > toTimeMM)) {
        return false
      }
    }
  }

  return true
}
