import { useState, useLayoutEffect } from 'react'

function findHighestNode (nodesList) {
  let pageHeight = null

  for (let i = nodesList.length - 1; i >= 0; i--) {
    if (nodesList[i].scrollHeight && nodesList[i].clientHeight) {
      const elHeight = Math.max(nodesList[i].scrollHeight, nodesList[i].clientHeight)
      pageHeight = Math.max(elHeight, pageHeight)
    }

    if (nodesList[i].childNodes.length) {
      findHighestNode(nodesList[i].childNodes)
    }
  }

  return pageHeight
}

export function useWindowSize () {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/

  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
    bodyHeight: undefined
  })

  // NOTE: (Alex) changed this to useLayoutEffect instead of useEffect
  useLayoutEffect(() => {
    // Handler to call on window resize
    function handleResize () {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        bodyHeight: findHighestNode(document.documentElement.childNodes)
      })
    }

    // Add event listener
    window.addEventListener('resize', handleResize)

    // Call handler right away so state gets updated with initial window size
    handleResize()

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize)
  }, []) // Empty array ensures that effect is only run on mount

  return windowSize
}
