/**
 * Utility function to manage an array or single record item passed through an hydrate function
 * @param {object} response
 * @param {function} hydrator
 * @returns {{object}|*}
 */
export function hydrateResponse (response, hydrator) {
  if (typeof hydrator !== 'function') {
    throw new Error('"hydrator" required to hydrateResponse')
  }

  const hydrateParsedData = (parsedData) => Array.isArray(parsedData) ? parsedData.map(hydrator) : hydrator(parsedData)

  if (response.data) {
    return Object.assign(response, { data: hydrateParsedData(response.data) })
  } else if (response) {
    return hydrateParsedData(response)
  } else {
    return response
  }
}
