/* eslint-disable no-alert */
import React from 'react'
import PropTypes from 'prop-types'
import { getFormattedDateAndTimeFromDate, getFormattedDurationString } from '@trexity/common/temporal'
import { getFormattedDistanceString } from '@trexity/common/geo'
import { getFormattedMonetaryValueString } from '@trexity/common/pricing'
import { LINKED_SHIPMENT_TYPES } from '@trexity/common/shipments/constants'
import { LEGACY_CAPABILITIES } from '@trexity/common/capReq/constants'
import { GDPR } from '@trexity/common/gdpr/constants'
import { stripSpaces } from '@trexity/common/util'
import { DateTime } from '@trexity/common/date'
import { convertOrdersToArray } from '@trexity/common/models/ShipmentOrder'
import { formatPhoneNumber, validatePhoneNumber } from '@trexity/common/phone'
import { ModalConfirmWord, ShipmentStatus } from '@trexity/common-client/lib/components'
import { sortOrdersByShipmentAndDriver } from '@trexity/common/shipments'
import LinkedParentType from '../../LinkedParentType'
import { shipmentOrderToViewModel } from '../../../util'
import { ShipmentRateBreakdownFromShipment } from '../../ShipmentRateBreakdown'
import { MoveDeliveryDialog } from './MoveDeliveryDialog'
import { PenalizeDriverDialog } from './PenalizeDriverDialog'
import { ForceAcceptButton } from './ForceAcceptButton'
import { ForcePickupButton } from './ForcePickupButton'

import {
  Descriptions,
  Icon,
  Button,
  Modal,
  Table,
  Skeleton,
  Popover,
  Input,
  Tooltip,
  Dropdown,
  Menu,
  Card,
  Tag,
  AutoComplete
} from 'antd'

const { RETURN_TO_SENDER, RETURN_REUSABLES } = LINKED_SHIPMENT_TYPES

const confirmUnlinkShipment = (shipmentId, linkedParentShipmentId, send) => {
  Modal.confirm({
    title: `Are you sure you wish to unlink ${shipmentId} from its parent ${linkedParentShipmentId}?`,
    description: 'This will cause any association between the two to be lost.',
    okText: 'Yes',
    cancelText: 'No',
    onOk () {
      send({ type: 'unlink shipment', shipmentId })
    }
  })
}

export default function Details ({
  isAdmin = false,
  deps,
  ...restProps
}) {
  const {
    current,
    send,
    data,
    tc,
    RouteLink,
    DriverSearch,
    callDriverEnabled
  } = deps

  const {
    shipmentDashboardShipment: shipment,
    shipmentDashboardMerchant: merchant,
    shipmentDashboardDriver: driver,
    shipmentDashboardTrackingUrl: trackingUrl,
    forceDeliveringOrderUuid = null,
    forceUndeliverableOrderUuid = null,
    toggleUndeliverableOrderUuid = null,
    toggleMisdeliveredOrderUuid = null,
    latestProxySessions,
    user
  } = data

  const {
    id: shipmentId,
    createdAt,
    pickupNotes,
    pickupAddress,
    requirements,
    currentStatus,
    merchantId,
    driverId,
    cachedMerchantName,
    cachedMerchantPhone,
    pickupAddressNotes = '',
    postedAt = null,
    scheduledPostAt = null,
    scheduledPostEnabled = false,
    cachedTotal = 0,
    cachedDriverCompensation = 0,
    cachedPenaltyDriverCompensation = 0,
    cachedTotalRouteDistance = 0,
    cachedTotalRouteDuration = 0,
    linkedParentShipment = null,
    cachedLinkedChildShipments = null,
    postErrors = null,
    broadcastErrors = null,
    targetDeliveryDuration = 0,
    routeIdentifier = null,
    shipmentRate = null
  } = shipment || {}

  const activeProxySession = (
    data.activeProxySession ||
    (latestProxySessions || []).find((s) => (s.shipmentId === shipmentId) && !s.isExpired)
  )

  const ordersArray = shipment
    ? convertOrdersToArray(sortOrdersByShipmentAndDriver({ shipment, driver }))
    : []

  const linkedParentType = (linkedParentShipment && linkedParentShipment.type) || null

  const isCancellingOrEstimatingCancellation = current.within(['Cancelling Shipment', 'Estimating Cancellation Fee'])
  const isPostingShipment = current.within('Posting Shipment')
  const isPuttingShipmentOnHold = current.within('Putting Shipment On Hold')
  const isReady = current.within('Showing Details') && shipment && shipment.id
  const isCreatingTrackingUrl = current.within('Creating Tracking URL')
  const isCreatingShippingLabel = current.within('Generate Label')
  const isClearingSoftDismissed = current.within('Clearing Soft Dismissed')
  const showTrackingUrl = current.within('Showing Tracking URL')
  const isCreatingProxySession = current.within('Creating Proxy Session')

  const dashboardCallDriverButtonLoading = current.within([
    'Loading Latest Sessions',
    'Showing Call Driver Modal'
  ])

  const callDriverModalVisible = (
    current.within('Showing Call Driver Modal')
  )

  const [callerPhoneNumber, setCallerPhoneNumber] = React.useState(cachedMerchantPhone)

  const callDriverButtonLoading = isCreatingProxySession
  const modalCallDriverButtonDisabled = Boolean(activeProxySession) || !(callerPhoneNumber)

  const phonesFromSessions = [...(new Set((latestProxySessions || []).map((s) => s.callerPhoneNumber)))]
  const [recentlyUsedCallerPhoneNumbers, setRecentlyUsedCallerPhoneNumbers] = React.useState(phonesFromSessions)
  const [internalRouteNotesIsVisible, setInternalRouteNotesIsVisible] = React.useState(false)

  React.useEffect(() => {
    const allNumbers = [...new Set([
      ...recentlyUsedCallerPhoneNumbers,
      cachedMerchantPhone,
      ...phonesFromSessions
    ].filter(Boolean).map((s) => stripSpaces(s)))]

    if (allNumbers.find((number) => !recentlyUsedCallerPhoneNumbers.includes(number))) {
      setRecentlyUsedCallerPhoneNumbers(allNumbers)
    }
  }, [cachedMerchantPhone, phonesFromSessions])

  const isLoading = (
    current.within('Loading Details') ||
    current.within('Force Accepting Shipment') ||
    current.within('Force Picking Up Shipment') ||
    current.within('Force Delivering Order') ||
    current.within('Force Undeliverable Order') ||
    current.within('Toggle Undeliverable Order') ||
    isCancellingOrEstimatingCancellation ||
    isPuttingShipmentOnHold ||
    isPostingShipment ||
    isCreatingTrackingUrl
  )

  const canPutShipmentOnHold = (
    !isLoading &&
    (
      currentStatus === 'WAITING_FOR_ACCEPTANCE' ||
      currentStatus === 'RENOUNCED_BY_DRIVER'
    )
  )

  const canPostShipment = (
    !isLoading &&
    !driverId &&
    (
      !currentStatus ||
      currentStatus === 'ON_HOLD'
    )
  )

  const canCreateShippingLabelPreview = (
    !isLoading && isAdmin
  )

  const canCreateShippingLabel = (
    !isLoading &&
    !(
      currentStatus === 'OUT_FOR_DELIVERY' ||
      currentStatus === 'ARRIVED_AT_DELIVERY' ||
      currentStatus === 'DELIVERED' ||
      currentStatus === 'CANCELLED'
    )
  )

  const canCancelShipment = (
    !isLoading &&
    (
      !currentStatus ||
      !(
        currentStatus === 'DELIVERED' ||
        currentStatus === 'CANCELLED'
      )
    )
  )

  const canEditShipment = (
    !currentStatus ||
    currentStatus === 'WAITING_FOR_ACCEPTANCE' ||
    currentStatus === 'ON_HOLD' ||
    (
      isAdmin && (
        currentStatus === 'EN_ROUTE_TO_PICKUP' ||
        currentStatus === 'ARRIVED_AT_PICKUP' ||
        currentStatus === 'OUT_FOR_DELIVERY' ||
        currentStatus === 'ARRIVED_AT_DELIVERY' ||
        currentStatus === 'RENOUNCED_BY_DRIVER'
      )
    )
  )

  const canClearSoftDismissed = (
    isAdmin &&
    (
      currentStatus === 'WAITING_FOR_ACCEPTANCE' ||
      currentStatus === 'ON_HOLD'
    )
  )

  const canUnbundleRoute = (
    (
      isAdmin
        ? (
          !currentStatus ||
          currentStatus === 'ON_HOLD' ||
          currentStatus === 'RENOUNCED_BY_DRIVER' ||
          currentStatus === 'WAITING_FOR_ACCEPTANCE' ||
          currentStatus === 'EN_ROUTE_TO_PICKUP' ||
          currentStatus === 'ARRIVED_AT_PICKUP' ||
          currentStatus === 'OUT_FOR_DELIVERY' ||
          currentStatus === 'ARRIVED_AT_DELIVERY'
        ) // admins can unbundle in many statuses
        : (
          !currentStatus
        ) // merchants can only unbundle with no status
    ) &&
    ordersArray.length > 1 && // should have more than one order (bundle)
    !ordersArray.find((o) => o.deliveredAt) // should not have any delivered orders
  )

  const canDeleteRoute = (
    !currentStatus
  )

  const displayTotalCost = shipment && Number.isFinite(shipment.cachedTotal)
    ? (
      <Popover
        title='Total Cost Breakdown'
        trigger='click'
        placement='bottom'
        content={(<ShipmentRateBreakdownFromShipment shipment={shipment} isAdmin={isAdmin} mode='shipment' />)}
      >
        <a href='#'>{getFormattedMonetaryValueString(shipment.cachedPenaltyTotal || shipment.cachedTotal || 0)}</a>
      </Popover>
    )
    : '(Unknown)'

  const displayDriverEarn = shipment && Number.isFinite(shipment.cachedPenaltyDriverCompensation || shipment.cachedDriverCompensation)
    ? (
      <Popover
        title='Driver Earn Breakdown'
        trigger='click'
        placement='bottom'
        content={(<ShipmentRateBreakdownFromShipment shipment={shipment} isAdmin={isAdmin} mode='driver' />)}
      >
        <a href='#'>{getFormattedMonetaryValueString(shipment.cachedPenaltyDriverCompensation || shipment.cachedDriverCompensation || 0)}</a>
      </Popover>
    )
    : '(Unknown)'

  const cachedTotalRouteDistanceFormatted = cachedTotalRouteDistance ? getFormattedDistanceString(cachedTotalRouteDistance) : 'Unknown'
  const cachedTotalRouteDurationFormatted = cachedTotalRouteDuration ? getFormattedDurationString(cachedTotalRouteDuration) : 'Unknown'

  const isLinkedShipment = Boolean(linkedParentShipment && linkedParentShipment.type)
  const isReturn = [RETURN_TO_SENDER, RETURN_REUSABLES].includes(linkedParentShipment && linkedParentShipment.type)
  const unlinkingDisabled = [RETURN_TO_SENDER, RETURN_REUSABLES].includes(linkedParentShipment && linkedParentShipment.type)
  const cloningDisabled = isLinkedShipment
  const exportingOrdersDisabled = isLinkedShipment

  const requirementKeys = Object.keys(requirements || {})
  requirementKeys.sort()

  const errors = [
    ...(Array.isArray(postErrors) ? postErrors : []),
    ...(Array.isArray(broadcastErrors) ? broadcastErrors : [])
  ]
    .filter(({ acknowledged }) => !acknowledged)

  const columns = [
    { title: 'Order ID', dataIndex: 'orderId', key: 'orderId' },
    { title: 'Status', dataIndex: 'status', key: 'status' },
    { title: 'Label', dataIndex: 'label', key: 'label' },
    { title: 'Address', dataIndex: 'address', key: 'address' },
    { title: 'Line 2', dataIndex: 'addressNotes', key: 'addressNotes' },
    { title: 'Description', dataIndex: 'description', key: 'description' },
    { title: 'Instructions', dataIndex: 'deliveryInstructions', key: 'deliveryInstructions' },
    { title: 'Name', dataIndex: 'name', key: 'name' },
    { title: 'Email', dataIndex: 'email', key: 'email' },
    { title: 'Phone', dataIndex: 'phone', key: 'phone' },
    { title: 'Value', dataIndex: 'value', key: 'value' },
    { title: 'Tips', dataIndex: 'tip', key: 'tip' },
    { title: 'Alcoholic', dataIndex: 'alcohol', key: 'alcohol' },
    { title: 'Handoff', dataIndex: 'handoff', key: 'handoff' },
    { title: 'PIN', dataIndex: 'handoffPin', key: 'handoffPin' },
    { title: 'Source', dataIndex: 'source', key: 'source' }
  ]

  columns.push({ title: ' ', dataIndex: 'action', key: 'action', align: 'right', fixed: 'right' })

  const [driverPicker, updateDriverPicker] = React.useState({
    visible: false,
    driverId: '',
    driverName: '',
    callback: null
  })

  const dataSource = ordersArray
    .filter((order) => !order.internalType)
    .map((order, index) => {
      order = order || {}

      const forceDeliverLoading = (
        current.within('Force Delivering Order') &&
        forceDeliveringOrderUuid === order.id
      )

      const forceUndeliverableLoading = (
        current.within('Force Undeliverable Order') &&
        forceUndeliverableOrderUuid === order.id
      )

      const toggleUndeliverableLoading = (
        current.within('Toggle Undeliverable Order') &&
        toggleUndeliverableOrderUuid === order.id
      )

      const toggleMisdeliveredLoading = (
        current.within('Toggle Misdelivered Order') &&
        toggleMisdeliveredOrderUuid === order.id
      )

      const canForceDeliver = (
        ['OUT_FOR_DELIVERY', 'ARRIVED_AT_DELIVERY'].includes(shipment.currentStatus) &&
        !order.deliveredAt
      )

      const canForceUndeliverable = (
        ['OUT_FOR_DELIVERY', 'ARRIVED_AT_DELIVERY'].includes(shipment.currentStatus) &&
        !order.deliveredAt &&
        !isReturn
      )

      const canToggleUndeliverable = (
        Boolean(order.deliveredAt)
      )

      const canToggleMisdelivered = (
        ['OUT_FOR_DELIVERY', 'ARRIVED_AT_DELIVERY', 'DELIVERED', 'ON_HOLD'].includes(shipment.currentStatus)
      )

      const canMoveOrder = (
        !order.deliveredAt
      )

      const canPenalizeDriver = (
        shipment.archived &&
        shipment.currentStatus === 'DELIVERED' &&
        !(order.metadata || {}).driver_penalizedDate
      )

      const vm = shipmentOrderToViewModel(shipment, order, { Popover, isAdmin })

      return {
        ...vm,
        key: index,
        orderId: (
          <Tooltip placement='top' title={`${order.id} (source: ${vm.source})`}>
            <div className='nowrap'>
              {canEditShipment && !isAdmin ? (
                <RouteLink
                  routeKey='Edit Delivery'
                  params={{ id: shipmentId, uuid: order.id }}
                >
                  {vm.orderId}
                </RouteLink>
              ) : (
                <div style={{ display: 'inline-block' }}>{vm.orderId}</div>
              )}
            </div>
          </Tooltip>
        ),
        status: (
          (vm.status === 'Delivered') ? (
            <label className='nowrap'><Icon type='check-circle' style={{ color: 'green', marginRight: 5 }} />{vm.status}</label>
          ) : (vm.status === 'Undeliverable') ? (
            <label className='nowrap'><Icon type='warning' style={{ color: 'orange', marginRight: 5 }} />{vm.status}</label>
          ) : (vm.status === 'Misdelivered') ? (
            <label className='nowrap'><Icon type='close-circle' style={{ color: 'red', marginRight: 5 }} />{vm.status}</label>
          ) : (vm.status === 'In Progress') ? (
            <label className='nowrap'><Icon type='clock-circle' style={{ color: '#909090', marginRight: 5 }} />{vm.status}</label>
          ) : (
            <label className='nowrap'><Icon type='pause-circle' style={{ color: '#909090', marginRight: 5 }} />{vm.status}</label>
          )
        ),
        label: (
          (vm.label === 'labelled') ? (
            <Tooltip placement='top' title='Label has been printed'>
              <Tag color="green"><Icon type='check' style={{ marginRight: 5 }} />Printed</Tag>
            </Tooltip>
          ) : (vm.label === 'invalid') ? (
            <Tooltip placement='top' title='Delivery has been modified, you must reprint this label'>
              <Tag color="red"><Icon type='stop' style={{ marginRight: 5 }} />Please Reprint</Tag>
            </Tooltip>
          ) : (vm.label === 'outdated') ? (
            <Tooltip placement='top' title='Delivery was modified after pick up, label details will not match, package scanning not affected'>
              <Tag color="blue"><Icon type='info-circle' style={{ marginRight: 5 }} />Outdated</Tag>
            </Tooltip>
          ) : (
            <Tooltip placement='top' title='A label must be printed'>
              <Tag color="blue"><Icon type='warning' style={{ marginRight: 5 }} />Not Printed</Tag>
            </Tooltip>
          )
        ),
        address: vm.address,
        addressNotes: vm.addressNotes,
        description: (
          <Popover
            content={(
              <div style={{ whiteSpace: 'pre-wrap' }}>{vm.description}</div>
            )}
            title={vm.orderId}
            trigger='click'
          >
            <Button
              type='ghost'
              size='small'
            >View</Button>
          </Popover>
        ),
        deliveryInstructions: (
          vm.deliveryInstructions ? (
            <Popover
              content={(
                <div style={{ whiteSpace: 'pre-wrap' }}>{vm.deliveryInstructions}</div>
              )}
              title={vm.orderId}
              trigger='click'
            >
              <Button
                type='ghost'
                size='small'
              >View</Button>
            </Popover>
          ) : ''
        ),
        email: (
          !vm.email || vm.email === GDPR.REDACTED_KEYWORD ? (
            vm.email || ''
          ) : (
            <a href={`mailto:${vm.email}`}>{vm.email}</a>
          )
        ),
        phone: (
          !vm.phone || vm.phone === GDPR.REDACTED_KEYWORD ? (
            vm.phone || ''
          ) : (
            <a href={`tel:${vm.phone}`}>{vm.phone}</a>
          )
        ),
        handoff: (
          vm.handoff === 'Yes'
            ? <label className='nowrap'><Icon type='check-circle' style={{ color: 'green', marginRight: 5 }} />{vm.handoff}</label>
            : <label className='nowrap'><Icon type='minus-circle' style={{ color: '#909090', marginRight: 5 }} />{vm.handoff}</label>
        ),
        handoffPin: (
          <code>{vm.handoff === 'Yes' ? vm.handoffPin : ''}</code>
        ),
        alcohol: (
          vm.alcohol === 'Yes'
            ? <label className='nowrap'><Icon type='check-circle' style={{ color: 'green', marginRight: 5 }} />{vm.alcohol}</label>
            : <label className='nowrap'><Icon type='minus-circle' style={{ color: '#909090', marginRight: 5 }} />{vm.alcohol}</label>
        ),
        source: (vm.source || ''),
        ...(isAdmin ? {
          misdelivered: (
            vm.misdelivered ? 'Yes' : 'No'
          )
        } : null),
        action: (
          <Dropdown
            disabled={isLoading}
            trigger={['click']}
            overlay={(
              <Menu>
                <Menu.Item
                  key='tracking-url'
                  icon='environment'
                  disabled={isCreatingTrackingUrl}
                  onClick={() => send({ type: 'create tracking url', shipmentId, orderUuid: order.id })}
                >
                  <Icon type='environment' />
                  Tracking URL
                </Menu.Item>
                {isAdmin ? (
                  <Menu.Item
                    key='label'
                    disabled={!canCreateShippingLabelPreview}
                    onClick={() => {
                      send({ type: 'shipment generate label', shipmentId, orderUuids: [order.id], orderId: order.orderId, previewOnly: true })
                    }}
                  >
                    <Icon type='printer' />
                    Preview Label
                  </Menu.Item>
                ) : (
                  <Menu.Item
                    key='label'
                    disabled={!canCreateShippingLabel}
                    onClick={() => {
                      send({ type: 'shipment generate label', shipmentId, orderUuids: [order.id], orderId: order.orderId })
                    }}
                  >
                    <Icon type='printer' />
                    Print Label
                  </Menu.Item>
                  )
                }

                {isAdmin ? null : (
                  <Menu.Item
                    key='edit'
                    disabled={!canEditShipment}
                    onClick={() => send({ type: 'route edit delivery', id: shipmentId, uuid: order.id })}
                  >
                    <Icon type='edit' />
                    Edit
                  </Menu.Item>
                )}
                {isAdmin ? (
                  <Menu.Item
                    key='force-deliver'
                    disabled={!canForceDeliver || forceDeliverLoading}
                    onClick={() => {
                      send({ type: 'force deliver order', shipmentId, driverId: shipment.driverId, orderUuid: order.id })
                    }}
                  >
                    <Icon type='step-forward' />
                    Force Deliver
                  </Menu.Item>
                ) : null}
                {isAdmin ? (
                  canToggleUndeliverable ? (
                    <Menu.Item
                      key='toggle-undeliverable'
                      disabled={!canToggleUndeliverable || toggleUndeliverableLoading}
                      onClick={() => {
                        send({ type: 'toggle undeliverable order', shipmentId, orderUuid: order.id })
                      }}
                    >
                      <Icon type='rollback' />
                      Toggle Undeliverable
                    </Menu.Item>
                  ) : (
                    <Menu.Item
                      key='force-undeliverable'
                      disabled={!canForceUndeliverable || forceUndeliverableLoading}
                      onClick={() => {
                        send({ type: 'force undeliverable order', shipmentId, driverId: shipment.driverId, orderUuid: order.id })
                      }}
                    >
                      <Icon type='rollback' />
                      Force Undeliverable
                    </Menu.Item>
                  )
                ) : null}
                {isAdmin ? (
                  <Menu.Item
                    key='toggle-misdelivered'
                    disabled={!canToggleMisdelivered || toggleMisdeliveredLoading}
                    onClick={() => {
                      send({ type: 'toggle misdelivered order', shipmentId, orderUuid: order.id })
                    }}
                  >
                    <Icon type='branches' />
                    Toggle Misdelivered
                  </Menu.Item>
                ) : null}
                {isAdmin ? (
                  <Menu.Item
                    key='move-order'
                    disabled={!canMoveOrder}
                    onClick={() => {
                      send({ type: 'move order', shipmentId, orderUuid: order.id, orderId: order.orderId, merchantId: shipment.merchantId })
                    }}
                  >
                    <Icon type='scissor' />
                    Move
                  </Menu.Item>
                ) : null}
                {isAdmin ? (
                  <Menu.Item
                    key='penalize-driver'
                    disabled={!canPenalizeDriver}
                    onClick={() => {
                      send({ type: 'penalize driver', orderUuid: order.id, orderId: order.orderId })
                    }}
                  >
                    <Icon type='frown' />
                    Penalize Driver
                  </Menu.Item>
                ) : null}
              </Menu>
            )}
          >
            <Button
              loading={isLoading}
              type='primary'
              size='small'
              icon='ellipsis'
            />
          </Dropdown>
        )
      }
    })

  const callDriver = (driverId, shipmentId) => {
    setCallerPhoneNumber(shipment.cachedMerchantPhone)
    send({ type: 'open call driver modal', driverId, shipmentId })
  }

  const routeIdCaption = shipmentId && (
    <code className='nowrap'>
      {shipmentId.substr(-3)}{routeIdentifier ? ` ${routeIdentifier}` : ''}
    </code>
  )

  const onDelete = (evt) => {
    ModalConfirmWord({
      confirmText: (word) => (<p>Confirm that you would like to delete this route by entering the number of deliveries (<strong>{word}</strong>) that will be deleted:</p>),
      confirmWord: String(ordersArray.length)
    }, {
      title: 'Are you sure you wish to delete this route?',
      okText: `Delete ${ordersArray.length} deliver${ordersArray.length === 1 ? 'y' : 'ies'}`,
      okType: 'danger',
      cancelText: 'Cancel',
      onOk () {
        send({ type: 'delete shipment', shipmentId })
      }
    })
  }

  const unbundleRoute = () => {
    ModalConfirmWord({
      confirmText: (word) => (<p>Confirm that you would like to unbundle this route by entering the number of deliveries (<strong>{word}</strong>) that will be unbundled:</p>),
      confirmWord: String(ordersArray.length)
    }, {
      title: 'Please confirm that you would like to unbundle all deliveries from this route:',
      okText: `Unbundle ${ordersArray.length} deliver${ordersArray.length === 1 ? 'y' : 'ies'}`,
      cancelText: 'Cancel',
      onOk () {
        send({
          type: 'unbundle route',
          shipmentId,
          orderUuids: ordersArray.map(({ id }) => id)
        })
      }
    })
  }

  const routeIncentiveData = shipmentRate?.incentive ? {
    incentive: shipmentRate.incentive,
    incentivesOffered: (shipmentRate?.shipmentContext?.completedRules?.length || 0),
    totalIncentives: shipmentRate?.rateCard?.sequencedIncentiveRules?.length
  } : null

  return (
    isReady ? (
      <React.Fragment>
        <div style={{ marginBottom: 20 }}>
          <Descriptions bordered size='small'>
            <Descriptions.Item label='Route ID'>
              {canEditShipment ? (
                <RouteLink
                  routeKey='Edit Shipment'
                  params={{ id: shipmentId }}
                >
                  {routeIdCaption}
                </RouteLink>
              ) : routeIdCaption}
              <Button
                icon='edit'
                size='small'
                type='primary'
                disabled={!canEditShipment}
                onClick={() => send({ type: 'route edit shipment', id: shipmentId })}
                style={{ marginLeft: 5 }}
              >Edit</Button>
            </Descriptions.Item>
            <Descriptions.Item label='Status'>
              {routeIncentiveData && currentStatus === 'WAITING_FOR_ACCEPTANCE' ? (
                <Popover
                  content={(
                    <div style={{ maxWidth: 500 }}>
                      {isAdmin ? `${getFormattedMonetaryValueString(routeIncentiveData.incentive)} surge bonus applied (${routeIncentiveData.incentivesOffered}/${routeIncentiveData.totalIncentives} incentives offered)` : 'We are aware of the delay to pickup and have our people on it'}
                    </div>
                  )}
                  title={'Delay to pickup acknowledged'}
                >
                  <Icon type='eye' theme='filled' style={{ marginRight: 5 }} />
                </Popover>
              ) : null}
              <ShipmentStatus
                status={currentStatus}
                scheduledPostAt={scheduledPostAt}
                scheduledPostEnabled={scheduledPostEnabled}
                linkedParentType={linkedParentType}
                numberOfUndeliverableItems={ordersArray.filter(({ deliveredAt, undeliverable, misdelivered }) => deliveredAt && undeliverable).length}
                numberOfMisdeliveredItems={ordersArray.filter(({ deliveredAt, undeliverable, misdelivered }) => deliveredAt && (isAdmin && misdelivered)).length}
                numberOfDeliveredItems={ordersArray.filter(({ deliveredAt, undeliverable, misdelivered }) => deliveredAt && !undeliverable && !(isAdmin && misdelivered)).length}
                numberOfProblemItems={ordersArray.filter(({ deliveredAt, undeliverable, misdelivered }) => deliveredAt && (undeliverable || (isAdmin && misdelivered))).length}
                totalItems={ordersArray.length}
              />
            </Descriptions.Item>
            {isAdmin ? (
              <Descriptions.Item label='Merchant'>
                <RouteLink
                  routeKey='Merchant Dashboard'
                  params={{ id: merchantId }}
                >{cachedMerchantName}</RouteLink>
              </Descriptions.Item>
            ) : null}
            <Descriptions.Item label='Created on'>{getFormattedDateAndTimeFromDate(createdAt.toDate())}</Descriptions.Item>
            {postedAt ? (
              <Descriptions.Item label='Posted on'>{getFormattedDateAndTimeFromDate(postedAt.toDate())}</Descriptions.Item>
            ) : null}
            {scheduledPostAt ? (
              <Descriptions.Item label='Scheduled for'>
                {getFormattedDateAndTimeFromDate(scheduledPostAt.toDate())}
                {scheduledPostEnabled ? null : (
                  <div>
                    <small style={{ background: '#ff0', color: '#000' }}>
                      route will not post automatically
                    </small>
                  </div>
                )}
              </Descriptions.Item>
            ) : null}
            <Descriptions.Item label='Max. Delivery Duration'>{targetDeliveryDuration > 0 ? getFormattedDurationString(targetDeliveryDuration) : 'Unlimited'}</Descriptions.Item>
            <Descriptions.Item label='Driver'>
              {driver ? (
                isAdmin ? (
                  <RouteLink
                    routeKey='Driver Dashboard'
                    params={{ id: driver.id }}
                  >{driver.fullName || ''}</RouteLink>
                ) : (driver.fullName || '')
              ) : <em>None</em>}

              {callDriverEnabled ? (
                driver && !isAdmin ? (
                  <Button
                    onClick={() => callDriver(driver.id, shipmentId)}
                    type='primary'
                    icon='phone'
                    size='small'
                    loading={dashboardCallDriverButtonLoading}
                    style={{ marginLeft: 3 }}
                  >
                    Call Driver
                  </Button>
                ) : null)
              : null}
            </Descriptions.Item>
            <Descriptions.Item label='Total Cost'>{displayTotalCost}</Descriptions.Item>
            {isAdmin ? (
              <Descriptions.Item label='Driver Earn'>{displayDriverEarn}</Descriptions.Item>
            ) : null}
            <Descriptions.Item label='Pickup Address'>
              {pickupAddress}
              {pickupAddressNotes ? <React.Fragment><br />{pickupAddressNotes}</React.Fragment> : null}
            </Descriptions.Item>

            <Descriptions.Item label='Pickup Notes'>{pickupNotes}</Descriptions.Item>
            <Descriptions.Item label='Est. Distance'>{cachedTotalRouteDistanceFormatted}</Descriptions.Item>
            <Descriptions.Item label='Est. Time'>{cachedTotalRouteDurationFormatted}</Descriptions.Item>
            <Descriptions.Item label='Requirements'>
              <table style={{ tableLayout: 'auto' }}>
                <tbody>
                  {requirementKeys.filter((req) => (
                    requirements[req]
                  )).map((req) => {
                    const label = LEGACY_CAPABILITIES[req].displayName
                    const value = requirements[req]

                    let displayValue = null

                    if ('data' in LEGACY_CAPABILITIES[req] && Array.isArray(LEGACY_CAPABILITIES[req].data)) {
                      const entry = LEGACY_CAPABILITIES[req].data.find((row) => row.id === value)
                      displayValue = entry && entry.title
                    }

                    if (!displayValue) {
                      displayValue = Boolean(value) === value
                        ? <Icon type='check-circle' theme='twoTone' twoToneColor='#52c41a' />
                        : String(value)
                    }

                    return (
                      <tr key={req}>
                        <th style={{ textAlign: 'right', whiteSpace: 'nowrap', paddingRight: 5 }}>{label}:</th>
                        <td style={{ width: '100%' }}>{displayValue}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </Descriptions.Item>

            {linkedParentShipment ? (
              <Descriptions.Item label='Linked Parent Route'>
                <RouteLink
                  routeKey='Shipment Dashboard'
                  params={{ id: linkedParentShipment.shipmentId }}
                >{linkedParentShipment.shipmentId}</RouteLink>
                &nbsp;
                <LinkedParentType type={linkedParentShipment.type} />
                {/* The Unlink button can be used if a Return To Sender needs to
                be converted into a regular route */}
                {isAdmin ? (
                  <Button
                    style={{ marginLeft: 5 }}
                    type='danger'
                    size='small'
                    disabled={unlinkingDisabled}
                    loading={current.within(['Unlinking Shipment'])}
                    onClick={() => confirmUnlinkShipment(shipmentId, linkedParentShipment.shipmentId, send)}
                  >Unlink</Button>
                ) : null}
              </Descriptions.Item>
            ) : null}
            {cachedLinkedChildShipments && cachedLinkedChildShipments.length ? (
              <Descriptions.Item label='Linked Child Shipments'>
                {cachedLinkedChildShipments.length > 1 ? (
                  <ul style={{ marginBottom: 0, paddingLeft: 16 }}>
                    {cachedLinkedChildShipments.map((clcs) => (
                      <li key={clcs.shipmentId}>
                        <RouteLink
                          routeKey='Shipment Dashboard'
                          params={{ id: clcs.shipmentId }}
                        >{clcs.shipmentId}</RouteLink>
                      </li>
                    )
                    )}
                  </ul>
                ) : (
                  <RouteLink
                    routeKey='Shipment Dashboard'
                    params={{ id: cachedLinkedChildShipments[0].shipmentId }}
                  >{cachedLinkedChildShipments[0].shipmentId}</RouteLink>
                )}
              </Descriptions.Item>
            ) : null}
            {errors.length ? (
              <Descriptions.Item label={`Post Error${errors.length === 1 ? '' : 's'}`}>
                <ul style={{ paddingLeft: 16, marginBottom: 0 }}>
                  {errors.map((error, index) => (
                    <li key={index}>
                      {getFormattedDateAndTimeFromDate(new Date(error.date))}: {error.message}
                    </li>
                  ))}
                </ul>
              </Descriptions.Item>
            ) : null}
          </Descriptions>

          {isAdmin ? (
            <Descriptions bordered size='small' column={2} style={{ marginTop: 10 }}>
              <Descriptions.Item label='Internal Merchant Notes'>
                <div style={{ whiteSpace: 'pre-wrap' }}>{(merchant && merchant.internalNotes) || <em>None</em>}</div>
              </Descriptions.Item>
              <Descriptions.Item label='Internal Driver Notes'>
                <div style={{ whiteSpace: 'pre-wrap' }}>{(driver && driver.internalNotes) || <em>None</em>}</div>
              </Descriptions.Item>
              <Descriptions.Item
                label={(
                  <span>Internal Route Notes
                    <Button
                      style={{ marginLeft: 5 }}
                      size='small'
                      onClick={() => setInternalRouteNotesIsVisible(true)}
                    ><Icon type='edit' /></Button>
                  </span>
                )}
              >
                <div style={{ whiteSpace: 'pre-wrap', maxHeight: 100, overflowY: 'scroll' }}>
                  {shipment?.internalNotes || <em>None</em>}
                </div>
              </Descriptions.Item>
            </Descriptions>
          ) : null}
        </div>

        <Card size='small' style={{ marginBottom: 20 }} bodyStyle={{ paddingBottom: 0 }}>
          <Tooltip
            title={(
              <React.Fragment>
                <p>Cancels an ongoing route. Cancellation fees are as follows:</p>
                <ul>
                  <li><em>Prior to Acceptance:</em> no cancellation fee</li>
                  <li><em>Before Pickup:</em> 50% of base fare up to $5 (plus admin fees)</li>
                  <li><em>Arrived at Pickup:</em> 50% of base fare up to $10 (plus admin fees)</li>
                  <li><em>After Pickup:</em> full price</li>
                </ul>
                {isAdmin ? <p><strong>As an admin, cancellation fees will be circumvented.</strong></p> : null}
                <p><small><em>NOTE: Cancellation fees are only applicable if delivery is not a DRAFT.</em></small></p>
              </React.Fragment>
            )}
          >
            <Button
              type='danger'
              loading={isCancellingOrEstimatingCancellation}
              disabled={!canCancelShipment}
              style={{ marginRight: 10, marginBottom: 10 }}
              onClick={() => {
                const type = isAdmin ? 'confirm cancellation' : 'estimate cancellation fee'
                send({ type, id: shipmentId, currentStatus })
              }}
            >Cancel Route</Button>
          </Tooltip>

          <Tooltip
            title={(
              <p>A route can be put on hold while still waiting for acceptance. The pre-authorization on the credit card will be reversed.</p>
            )}
          >
            <Button
              loading={isPuttingShipmentOnHold}
              disabled={!canPutShipmentOnHold}
              style={{ marginRight: 10, marginBottom: 10 }}
              onClick={() => {
                send({ type: 'put shipment on hold', shipmentId })
              }}
            >Put on Hold</Button>
          </Tooltip>

          <Tooltip
            title={(
              <p>Posts the route to the driver network immediately, even if the route was scheduled for later.</p>
            )}
          >
            <Button
              type='primary'
              className='route-post-now'
              loading={isPostingShipment}
              disabled={!canPostShipment}
              style={{ marginRight: 10, marginBottom: 10 }}
              onClick={() => {
                send({ type: 'post shipment', shipmentId })
              }}
            >Post Route NOW</Button>
          </Tooltip>

          <Tooltip
            title={(
              <p>Get all labels, as one .pdf file, from this route. Each page in the .pdf corresponds to a delivery.</p>
            )}
          >
            {isAdmin ? (
              <Button
                type='primary'
                icon='printer'
                loading={isCreatingShippingLabel}
                disabled={!canCreateShippingLabelPreview}
                style={{ marginRight: 10, marginBottom: 10 }}
                onClick={() => {
                  send({ type: 'shipment generate label', shipmentId, orderUuids: ordersArray.map((o) => o.id), previewOnly: true })
                }}
              >Preview Labels</Button>
            ) : (
              <Button
                type='primary'
                icon='printer'
                loading={isCreatingShippingLabel}
                disabled={!canCreateShippingLabel}
                style={{ marginRight: 10, marginBottom: 10 }}
                onClick={() => {
                  send({ type: 'shipment generate label', shipmentId, orderUuids: ordersArray.map((o) => o.id) })
                }}
              >Print label{ordersArray.length === 1 ? '' : 's'}</Button>
            )}
          </Tooltip>

          {isAdmin ? (
            <Tooltip
              title={(
                <React.Fragment>
                  <p>Allows an admin user to assign this route directly to a particular driver, posting the route as needed.</p>
                  <p>A dialog box will appear upon clicking this button which will allow you to search for a driver.</p>
                </React.Fragment>
              )}
            >
              <ForceAcceptButton
                shipment={shipment}
                updateDriverPicker={updateDriverPicker}
                driverPicker={driverPicker}
                driverId={driverId}
                isLoading={isLoading}
                style={{ marginRight: 10, marginBottom: 10 }}
                deps={{ send, current }}
              />
            </Tooltip>
          ) : null}

          {isAdmin ? (
            <Tooltip
              title={(
                <p>Allows an admin user to simulate the pickup action on behalf of the driver assigned to this delivery.</p>
              )}
            >
              <ForcePickupButton
                shipment={shipment}
                style={{ marginRight: 10, marginBottom: 10 }}
                deps={{ send, current }}
              />
            </Tooltip>
          ) : null}

          {isAdmin ? (
            <Tooltip
              title={(
                <p>When drivers are pinged, if they did not respond in a timely fashion, they are added to the Soft-Dismissed List, and ignored for subsequent pings. If the broadcast process exhausts all possible drivers, this list is cleared so these drivers can be re-considered again for pings. This button clears this same list so drivers can be re-considered immediately.</p>
              )}
            >
              <Button
                type='primary'
                loading={isClearingSoftDismissed}
                disabled={!canClearSoftDismissed}
                style={{ marginRight: 10, marginBottom: 10 }}
                onClick={() => {
                  send({ type: 'clear soft dismissed', shipmentId })
                }}
              >Clear Soft-Dismissed List</Button>
            </Tooltip>
          ) : null}

          {isAdmin ? (
            <Tooltip
              title={(
                <p>Allows you to create a draft copy of this route.</p>
              )}
            >
              <Button
                type='primary'
                loading={current.within('Cloning Shipment')}
                disabled={cloningDisabled}
                style={{ marginRight: 10, marginBottom: 10 }}
                onClick={() => {
                  send({ type: 'clone shipment', shipmentId })
                }}
              >Clone Route</Button>
            </Tooltip>
          ) : null}

          <Tooltip
            title={(
              <p>Export all deliveries in this route as CSV.</p>
            )}
          >
            <Button
              type='primary'
              loading={current.within('Exporting Orders')}
              disabled={exportingOrdersDisabled}
              style={{ marginRight: 10, marginBottom: 10 }}
              onClick={() => {
                send({ type: 'export orders', shipmentIds: [shipmentId] })
              }}
            >Export Deliveries</Button>
          </Tooltip>

          <Tooltip
            title={(
              <p>Unbundle all deliveries in this route</p>
            )}
          >
            <Button
              icon='fork'
              loading={current.within('Unbundling Route')}
              disabled={!canUnbundleRoute}
              style={{ marginRight: 10, marginBottom: 10 }}
              onClick={unbundleRoute}
            >Unbundle Route</Button>
          </Tooltip>

          <Tooltip
            title={(
              <p>Deletes route and all deliveries in it</p>
            )}
          >
            <Button
              type='danger'
              icon='delete'
              loading={current.within('Deleting')}
              disabled={!canDeleteRoute}
              style={{ marginRight: 10, marginBottom: 10 }}
              onClick={onDelete}
            >Delete Route</Button>
          </Tooltip>
        </Card>

        <Table
          size='small'
          dataSource={dataSource}
          columns={columns}
          loading={isLoading}
          scroll={{ x: true }}
        />

        <Modal
          title='Call Driver'
          visible={callDriverModalVisible}
          keyboard={false}
          maskClosable={false}
          footer={null}
          onCancel={() => {
            if (activeProxySession) {
              send('close call driver modal')
            } else {
              send('close call driver modal')
            }
          }}
        >
          {!activeProxySession ? (
            <React.Fragment>
              <div>Please choose the number you are going to call from.</div>

              <AutoComplete
                defaultValue={cachedMerchantPhone}
                dataSource={recentlyUsedCallerPhoneNumbers.map((number) => formatPhoneNumber(number))}
                style={{ width: 200 }}
                onSelect={(value) => {
                  setCallerPhoneNumber(stripSpaces(value))
                }}
                onChange={(value) => {
                  setCallerPhoneNumber(stripSpaces(value))
                }}
                placeholder='Your phone number'
              />

              <Button
                type='primary'
                loading={callDriverButtonLoading}
                disabled={modalCallDriverButtonDisabled}
                style={{ marginLeft: 10 }}
                onClick={() => {
                  if (!validatePhoneNumber(formatPhoneNumber(callerPhoneNumber))) {
                    alert('Please use a valid phone number (for example: +1 613 123 1234)')
                    return
                  }

                  send({ type: 'create proxy session', shipmentId, callerPhoneNumber })
                }}
              >Call Driver</Button>

            </React.Fragment>
          ) : null}

          {activeProxySession ? (
            <div>
              <span style={{ fontSize: '22px' }}>
                Dial <strong>{formatPhoneNumber(activeProxySession.calledPhoneNumber)}</strong> to reach the driver.
              </span>

              <br />
              <br />

              Please call from this number: {formatPhoneNumber(activeProxySession.callerPhoneNumber)}.
              <br />

              Please be aware that the session will timeout in approximately 3 minutes, if you do not call.
            </div>
          ) : null}
        </Modal>

        <Modal
          title='Tracking URL'
          visible={showTrackingUrl}
          onOk={() => send('done showing tracking url')}
          onCancel={() => send('done showing tracking url')}
        >
          <Input
            value={trackingUrl}
            readOnly
            onFocus={(evt) => evt.target.select()}
          />
        </Modal>

        {isAdmin ? (
          <InternalRouteNotesEditor
            csrName={String(user?.displayName || '')}
            csrUid={user?.uid}
            existingNotes={String(shipment?.internalNotes || '')}
            shipmentId={shipmentId}
            visible={internalRouteNotesIsVisible}
            setVisible={setInternalRouteNotesIsVisible}
            deps={{ tc }}
          />
         ) : null}

        {isAdmin ? (
          <Modal
            title='Select Driver'
            visible={driverPicker.visible}
            onOk={() => {
              updateDriverPicker({
                ...driverPicker,
                visible: false,
                callback: null
              })

              if (typeof driverPicker.callback === 'function') {
                driverPicker.callback(driverPicker.driverId, driverPicker.driverName)
              }
            }}
            onCancel={() => {
              updateDriverPicker({
                ...driverPicker,
                visible: false,
                callback: null
              })
            }}
          >
            <DriverSearch
              value={driverPicker.driverId}
              defaultDriver={{
                id: driverPicker.driverId,
                fullName: driverPicker.driverName
              }}
              match={driverPicker.match}
              onChange={(driverId, driverName) => {
                updateDriverPicker({
                  ...driverPicker,
                  driverId,
                  driverName
                })
              }}
            />
          </Modal>
        ) : null}

        <MoveDeliveryDialog
          deps={{ send, current, data }}
        />

        <PenalizeDriverDialog
          deps={{ send, current, data }}
        />
      </React.Fragment>
    ) : (
      <Skeleton active />
    )
  )
}

Details.propTypes = {
  isAdmin: PropTypes.bool,
  deps: PropTypes.exact({
    send: PropTypes.func,
    current: PropTypes.object,
    data: PropTypes.object,
    tc: PropTypes.object,
    RouteLink: PropTypes.func,
    DriverSearch: PropTypes.func,
    callDriverEnabled: PropTypes.bool
  }).isRequired
}

function InternalRouteNotesEditor ({
  csrName,
  csrUid,
  existingNotes,
  shipmentId,
  visible,
  setVisible,
  deps
}) {
  const { tc } = deps

  const [internalNotes, setInternalNotes] = React.useState('')
  const [isSaving, setIsSaving] = React.useState(false)

  return (
    <Modal
      style={{ maxWidth: 800 }}
      width='95%'
      okText='Add note'
      okButtonProps={{ loading: isSaving, disabled: !internalNotes.trim() }}
      cancelButtonProps={{ disabled: isSaving }}
      title='Internal Route Notes'
      visible={visible}
      onOk={async () => {
        try {
          setIsSaving(true)

          const csrInitials = csrName.split(' ').map((s) => s.substring(0, 1).toUpperCase()).join('') || '00'
          const notes = String(internalNotes || '').trim()

          const finalInternalNotes = [
            existingNotes,
            notes && `${DateTime().format()} ${csrInitials}-${csrUid.substring(0, 2)} ${notes}`
          ].filter(Boolean).join('\n')

          await tc.shipments.updateShipmentById(shipmentId, { internalNotes: finalInternalNotes })

          setVisible(false)
        } catch (error) {
          Modal.error({
            title: 'Oops! There was a problem.',
            content: error.message
          })
        } finally {
          setInternalNotes('')
          setIsSaving(false)
        }
      }}
      onCancel={() => setVisible(false)}
    >
      {existingNotes ? (
        <Card
          size='small'
          style={{ marginBottom: 15 }}
        >
          <div style={{ whiteSpace: 'pre-wrap' }}>{existingNotes}</div>
        </Card>
      ) : null}
      <Input.TextArea
        autoSize={{ minRows: 3, maxRows: 5 }}
        value={internalNotes}
        onChange={(evt) => setInternalNotes(evt.target.value)}
        disabled={isSaving}
      />
    </Modal>
  )
}
