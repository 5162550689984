import { useEffect, useRef, useState } from 'react'
import { COLORS } from '@trexity/common/color/constants'
import PropTypes from 'prop-types'
import { gsap } from 'gsap'
import { ButtonBase } from './button.styles'

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'

export const OutlineButton = ({
  onClick,
  animatingIn = true,
  animatingOut,
  animatingDelay = 0,
  children,
  loading = false,
  color = COLORS.TREXITY_BLUE,
  bg = 'transparent',
  size,
  disabled = false,
  disableAnimations,
  onFocus = () => { },
  onBlur = () => { },
  ...rest
}) => {
  const button = useRef()
  const spinnyT = useRef()
  const [active, setActive] = useState(false)

  useEffect(() => {
    const tl = gsap.timeline()

    if (!disableAnimations) {
      if (animatingIn) {
        tl.to(button.current, { y: 0, opacity: 1, delay: animatingDelay })
      } else if (animatingOut) {
        tl.to(button.current, { y: 20, opacity: 0 })
      }
    }
  }, [animatingIn, animatingOut, animatingDelay])

  useEffect(() => {
    if (loading) {
      gsap.to(spinnyT.current, { rotate: 5000, duration: 30 })
    }
  }, [loading])

  const setSize = () => {
    switch (size) {
      case 'small':
        return 10
      case 'medium':
        return 14
      case 'large':
        return 20
      default:
        return 14
    }
  }

  return (
    <button
      ref={button}
      onClick={onClick}
      onFocus={(e) => { onFocus(e); setActive(true) }}
      onBlur={(e) => { onBlur(e); setActive(false) }}
      css={[ButtonBase, OutlineButtonAnimation(active, disableAnimations), OutlineButtonStyles(disabled, color, setSize(), bg)]}
      disabled={disabled}
      {...rest}
    >
      {loading
        ? <div style={{ fontFamily: 'CaslonGraphiqueEF' }} ref={spinnyT}>T</div>
        : children}
    </button>
  )
}

export const OutlineButtonAnimation = (active, disableAnimations) => ({
  opacity: disableAnimations ? 1 : 0,
  boxShadow: active ? `0px 0px 5px ${COLORS.TREXITY_BLUE}` : 'none',
  transform: disableAnimations ? 'translateY(0px)' : 'translateY(20px)',
  transition: 'none'
})

export const OutlineButtonStyles = (disabled, color, size, bg) => ({
  backgroundColor: bg,
  fontSize: size,
  borderColor: color,
  color: color,
  opacity: disabled ? 0.4 : 1
})

const OutlineButtonPropTypes = {
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  children: PropTypes.node,
  animatingIn: PropTypes.bool,
  animatingOut: PropTypes.bool,
  animatingDelay: PropTypes.number,
  loading: PropTypes.bool,
  color: PropTypes.string,
  bg: PropTypes.string,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  disableAnimations: PropTypes.bool
}

OutlineButton.propTypes = OutlineButtonPropTypes
