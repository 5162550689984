/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import PropTypes from 'prop-types'
import { COLORS } from '@trexity/common/color/constants'
import { ButtonBase } from './button.styles'
import Icon from '@material-ui/core/Icon'

export const IconButton = ({
  bg,
  icon,
  size,
  light,
  onClick,
  children
}) => {
  const setSize = () => {
    switch (size) {
      case 'small':
        return 10
      case 'medium':
        return 14
      case 'large':
        return 20
      default:
        return 14
    }
  }

  return (
    <div css={[ButtonBase, IconButtonStyles(bg, setSize(), light)]} onClick={onClick}>
      <Icon style={{ fontSize: setSize() + 2 }}>{icon}</Icon> <span style={{ marginLeft: setSize() / 3 }}>{children}</span>
    </div>
  )
}

export const IconButtonStyles = (bg, size, light) => ({
  backgroundColor: bg,
  fontSize: size,
  borderColor: bg,
  color: light ? COLORS.WHITE : COLORS.DARK
})

IconButton.propTypes = {
  bg: PropTypes.string,
  icon: PropTypes.string,
  border: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  light: PropTypes.bool,
  size: PropTypes.string,
  onClick: PropTypes.func
}
