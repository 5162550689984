import React from 'react'
import PropTypes from 'prop-types'
import { LINKED_SHIPMENT_TYPES } from '@trexity/common/shipments/constants'
import TagWithTooltip from './TagWithTooltip'

const {
  RETURN_TO_SENDER,
  RETURN_REUSABLES,
  CLONE
} = LINKED_SHIPMENT_TYPES

export default function LinkedParentType ({ type = '' }) {
  const tagColor = 'black'

  switch (type) {
    case RETURN_TO_SENDER:
      return (
        <TagWithTooltip tagColor={tagColor} tipTitle='A special type of delivery for the driver to return undeliverable items back to the merchant.'>
          Return To Sender
        </TagWithTooltip>
      )
    case RETURN_REUSABLES:
      return (
        <TagWithTooltip tagColor={tagColor} tipTitle='A special type of delivery for the driver to return any reusables collected along their route back to the merchant.'>
          Return Reusables
        </TagWithTooltip>
      )
    case CLONE:
      return (
        <TagWithTooltip tagColor={tagColor} tipTitle='A copy of another delivery.'>
          Clone
        </TagWithTooltip>
      )
    default:
      return null
  }
}

LinkedParentType.propTypes = {
  type: PropTypes.oneOf(Object.values(LINKED_SHIPMENT_TYPES))
}
