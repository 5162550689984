/*
This module is a namespace for address related extractors.
*/

const { Address } = require('./address')
const Helpers = require('./helpers')
const { canadianAddress } = require('./canadianAddress')
const { americanAddress } = require('./americanAddress')

/**
 * Full-extractor that matches an international mailing address.
 *
 * Optionally, if 'withoutAddressee' is truthy then <ADDRESSEE>
 * will be assumed to not be in the text and instead <STREET> will
 * assume to be the first line in the text.
 *
 * Optionally, if 'postalCodeOptional' is truthy then the postal code
 * will be optional, otherwise the postal code will be required in order
 * for the address to match.
 *
 * See formatting rules for each country's address extractor separately.
 *
 * If 'country' is specified and is recognized then the country's extractor
 * will be used, otherwise if the country is not recognized or if no country is
 * specified then all country extractors will be tried until one matches.
 *
 * The following country values are recognized (case insensitive):
 * - ca, canada = Canada extractor
 * - us, usa, united states, united states of america = American extractor
 *
 * @param {string} text
 * @param {{ withoutAddressee?: boolean, postalCodeOptional?: boolean, country?: string }} [options]
 * @return {[ReturnType<typeof Address>, string]}
 */
function address (text, { withoutAddressee = false, postalCodeOptional = false, country = null } = {}) {
  let address = null
  let txt = ''

  // IMPORTANT: Put Canada parser last because it's the only one with
  // country default optional. All address parsers outside Canada should
  // default to country required.
  let extractors = [
    americanAddress,
    canadianAddress
  ]

  const countryAbbr = toCountryAbbr(country)

  // If we know the country we are expecting then we make country optional
  // for that parser.
  switch (countryAbbr) {
    case 'CA':
      extractors = [canadianAddress]
      break
    case 'USA':
      extractors = [(text, opts = {}) => americanAddress(text, { ...opts, countryOptional: true })]
      break
  }

  for (const extractor of extractors) {
    ([address, txt] = extractor(text, { withoutAddressee, postalCodeOptional }))

    if (address) {
      return [address, txt]
    }
  }

  return [null, text]
}

function toCountryAbbr (country) {
  const c = String(country).replace(/\./ug, '').toLowerCase()

  if (['ca', 'canada', 'can'].includes(c)) {
    return 'CA'
  } else if (['us', 'usa', 'united states', 'united states of america'].includes(c)) {
    return 'USA'
  } else {
    return country
  }
}

/**
 * Attempts to convert a string that is a province full name or abbreviation to
 * a province abbreviation.
 *
 * If country is not specified then all countries will be searched and if mutiple
 * provinces are applicable then return province as-is. Currently this is not an
 * issue between Canada and United States.
 */
function toProvinceAbbr (province, country = null) {
  const countryAbbr = toCountryAbbr(country)

  const list = [
    Helpers.toProvinceAbbr(Helpers.CA_PROVINCE_ABBR, province),
    Helpers.toProvinceAbbr(Helpers.US_STATE_ABBR, province)
  ].filter(Boolean)

  switch (countryAbbr) {
    case 'CA':
      return Helpers.toProvinceAbbr(Helpers.CA_PROVINCE_ABBR, province) || province
    case 'USA':
      return Helpers.toProvinceAbbr(Helpers.US_STATE_ABBR, province) || province
    default:
      return list.length === 1 ? list[0] : province
  }
}

exports.Address = Address
exports.address = address
exports.canadianAddress = canadianAddress
exports.americanAddress = americanAddress
exports.toCountryAbbr = toCountryAbbr
exports.toProvinceAbbr = toProvinceAbbr
