/*
A module namespace of common address extractors/converters.
*/

const CA_PROVINCE_ABBR = new Map([
  ['AB', 'Alberta'],
  ['BC', 'British Columbia'],
  ['MB', 'Manitoba'],
  ['NB', 'New Brunswick'],
  ['NL', 'Newfoundland and Labrador,Newfoundland'],
  ['NT', 'Northwest Territories'],
  ['NS', 'Nova Scotia'],
  ['NU', 'Nunavut'],
  ['ON', 'Ontario'],
  ['PE', 'Prince Edward Island,PEI'],
  ['QC', 'Québec,Quebec'],
  ['SK', 'Saskatchewan'],
  ['YT', 'Yukon']
])

const US_STATE_ABBR = new Map([
  ['AL', 'Alabama'],
  ['AK', 'Alaska'],
  ['AS', 'American Samoa'],
  ['AZ', 'Arizona'],
  ['AR', 'Arkansas'],
  // ['AE', 'Armed Forces Canada,Armed Forces Africa,Armed Forces Europe,Armed Forces Middle East'],
  // ['AA', 'Armed Forces Americas'],
  // ['AP', 'Armed Forces Pacific'],
  ['CA', 'California'],
  ['CO', 'Colorado'],
  ['CT', 'Connecticut'],
  ['DE', 'Delaware'],
  ['DC', 'District of Columbia'],
  ['FL', 'Florida'],
  ['GA', 'Georgia'],
  ['GU', 'Guam'],
  ['HI', 'Hawaii'],
  ['ID', 'Idaho'],
  ['IL', 'Illinois'],
  ['IN', 'Indiana'],
  ['IA', 'Iowa'],
  ['KS', 'Kansas'],
  ['KY', 'Kentucky'],
  ['LA', 'Louisiana'],
  ['ME', 'Main'],
  ['MH', 'Marshall Islands'],
  ['MA', 'Massachusetts'],
  ['MI', 'Michigan'],
  ['FM', 'Micronesia'],
  ['MN', 'Minesota'],
  ['UM', 'Minor Outlying Islands'],
  ['MS', 'Mississippi'],
  ['MO', 'Missouri'],
  ['MT', 'Montana'],
  ['NE', 'Nebraska'],
  ['NV', 'Nevada'],
  ['NH', 'New Hampshire'],
  ['NJ', 'New Jersey'],
  ['NM', 'New Mexico'],
  ['NY', 'New York'],
  ['NC', 'North Carolina'],
  ['ND', 'North Dakota'],
  ['MP', 'North Mariana Islands'],
  ['OH', 'Ohio'],
  ['OK', 'Oklahoma'],
  ['OR', 'Oregon'],
  ['PW', 'Palau'],
  ['PA', 'Pennsylvania'],
  ['PR', 'Puerto Rico'],
  ['RI', 'Rhode Island'],
  ['SC', 'South Carolina'],
  ['SD', 'South Dakota'],
  ['TN', 'Tennessee'],
  ['TX', 'Texas'],
  ['UT', 'Utah'],
  ['VT', 'Vermont'],
  ['VA', 'Virginia'],
  ['VI', 'Virgin Islands'],
  ['WA', 'Washington'],
  ['WV', 'West Virgina'],
  ['WI', 'Wisconsin'],
  ['WY', 'Wyoming']
])

function apartment (str) {
  const apt =
    /^(([0-9a-z]+)\s*[—–-]\s*)\d+/imu.exec(str) ||
    /((apt\.?|apartment|unit|suite)\s+#?\s*([0-9a-z]+))/iu.exec(str) ||
    /(^#([0-9a-z]+)\s*$)/imu.exec(str)

  if (apt) {
    return [
      apt[3]
        ? `${apt[3]}`
        : `${apt[2]}`,
      str
        .replace(apt[1], '')
        .replace(/ {2,}/gu, ' ')
        // The \W handles (Unit 4 - buzz 456) where "Unit 4 - " is removed
        // The \W handles (Unit 4 / buzz 456) where "Unit 4 / " is removed
        .replace(/^(\W*)\s*[—–\-/]\s*/gmu, '$1')
        .trim()
    ]
  }

  return ['', str]
}

function careof (str) {
  const careof = /\s*c[/.]o\.? +(.+)$/imu.exec(str)

  if (careof) {
    return [
      careof[1].trim(),
      str
        .replace(careof[0], '')
        .replace(/ {2,}/gu, ' ')
        .trim()
    ]
  }

  return ['', str]
}

function lines (str) {
  return String(str || '').trim()
    .split(/[\n,]+/u)
    .map((s) => {
      return s.trim()
        // For each line:
        // remove trailing whitespace and commas AND
        .replace(/[\s,]+$/gu, '')
        // remove leading and trailing brackets ( )
        .replace(/^\(+([^)]+)\)+$/gu, '$1')
        .trim()
    })
    .filter(Boolean)
}

function buzzer (str) {
  const buzzer = /\b(?:buz+|ring)(?:er)? [#0-9][0-9a-z*]*/iu.exec(str)

  if (buzzer) {
    return [buzzer[0], str.replace(buzzer[0], '')]
  }

  return ['', str]
}

function toProvinceName (abbrMap, province) {
  province = province.toUpperCase()

  if (abbrMap.has(province)) {
    return abbrMap.get(province).split(',')[0]
  } else {
    const entries = ([...abbrMap.entries()])
      .filter(([, names]) => {
        return names
          .split(',')
          .filter((name) => name.toUpperCase() === province)
          .length
      })

    return entries
      .slice(0, 1)
      .map(([_, names]) => names.split(',')[0])
      .pop()
  }
}

function toProvinceAbbr (abbrMap, province) {
  province = String(province).replace(/\./ug, '').toUpperCase()

  if (abbrMap.has(province)) {
    return province
  } else {
    const entries = ([...abbrMap.entries()])
      .filter(([, names]) => {
        return names
          .split(',')
          .filter((name) => name.toUpperCase() === province)
          .length
      })

    return entries
      .slice(0, 1)
      .map(([abbr]) => abbr)
      .pop()
  }
}

exports.CA_PROVINCE_ABBR = CA_PROVINCE_ABBR
exports.US_STATE_ABBR = US_STATE_ABBR
exports.apartment = apartment
exports.buzzer = buzzer
exports.careof = careof
exports.lines = lines
exports.toProvinceName = toProvinceName
exports.toProvinceAbbr = toProvinceAbbr
