import React, { useState } from 'react'
import { COLORS } from '@trexity/common/color/constants'
import PropTypes from 'prop-types'

export const Checkbox = ({ label, id, checked: checkIn, value, onChange = () => {}, ...rest }) => {
  const [checked, setChecked] = useState(checkIn)

  const checkboxContainer = {
    position: 'relative',
    cursor: 'pointer'
  }

  const checkboxStyles = {
    display: 'inline-block',
    width: 15,
    height: 15,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: COLORS.MEDIUM,
    borderStyle: 'solid',
    marginRight: 5,
    marginBottom: '0.1em',
    verticalAlign: 'middle'
  }

  const checkboxCheck = {
    width: '100%',
    height: '100%',
    backgroundColor: checked ? COLORS.TREXITY_BLUE : 'transparent',
    borderRadius: 5,
    borderWidth: 2,
    borderColor: COLORS.WHITE,
    borderStyle: 'solid'
  }

  return (
    <label style={checkboxContainer} htmlFor={id}>
      <input type='checkbox' id={id} checked={checked} value={value} onChange={() => { setChecked(!checked); onChange(!checked, id) }} style={{ display: 'none' }}></input>
      <div style={checkboxStyles}>
        <div style={checkboxCheck}></div>
      </div>
      {label}
    </label>
  )
}

Checkbox.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  id: PropTypes.string,
  value: PropTypes.any
}
