/* eslint-disable no-eq-null */
const { PropTypes } = require('../vendor')
const { assertPropTypes } = require('../vendor/check-prop-types')
const GeoJSON = require('../geo/GeoJSON')
const { validatePhoneNumber, formatPhoneNumber } = require('../phone')
const validateEmail = require('../email/validateEmail')
const validateEmails = require('../email/validateEmails')
const validateISODateString = require('../temporal/validateISODateString')
const validateUrl = require('../url/validateUrl')
const validateHttpsUrl = require('../url/validateHttpsUrl')
const validateShipmentQuery = require('../shipments/validateShipmentQuery')
const isValidOperatingHours = require('../temporal/isValidOperatingHours')

// #region Base Factories

/** @typedef {(props, propName: string, componentName: string, location: string, propFullName?: string) => Error?} PropTypeFunc */

/**
 * Create a new property type check function.
 *
 * @param {(value, props, propName: string, componentName: string, location: string, propFullName?: string) => Error | boolean | void} validator
 * @param {string} [message]
 * @return {PropTypeFunc & { isRequired: PropTypeFunc }}
 */
const createPropType = (validator, message = 'Validation failed.') => {
  const Factory = (required, props, propName, componentName, location, propFullName) => {
    const failMessage = 'Invalid prop `' + (propFullName || propName) + '` supplied to' +
      ' `' + componentName + '`. ' + message

    const missingMessage = 'Invalid prop `' + (propFullName || propName) + '` supplied to' +
      ' `' + componentName + '`. ' + message

    const value = props[propName]
    const isMissing = required && (value === null || value === undefined)
    /** @type {any} */
    let isValid = false

    if (required) {
      isValid = !isMissing &&
        validator(value, props, propName, componentName, location, propFullName)
    } else {
      isValid = value === null || value === undefined ||
        validator(value, props, propName, componentName, location, propFullName)
    }

    if (isMissing) {
      return new Error(missingMessage)
    }

    if (isValid instanceof Error) {
      return isValid
    }

    if (!isValid) {
      return new Error(failMessage)
    }
  }

  return Object.assign(Factory.bind(undefined, false), {
    isRequired: (...args) => Factory(true, ...args)
  })
}

// #endregion

const MAX_ID_LENGTH = 128
const MAX_EMAIL_LENGTH = 256

/************************************
 * PropTypes.maxString(n)[.isRequired]
 ************************************/

const maxString = (maxLength) => createPropType((value) => {
  return typeof value === 'string' && value.length <= maxLength
}, `String cannot be longer than ${maxLength} characters.`)

/************************************
 * PropTypes.minString(n)[.isRequired]
 ************************************/

const minString = (minLength) => createPropType((value) => {
  return typeof value === 'string' && value.length >= minLength
}, `String cannot be shorter than ${minLength} characters.`)

/************************************
 * PropTypes.emptyString(n)[.isRequired]
 ************************************/

const emptyString = createPropType((value) => {
  return typeof value === 'string' && !value.length
}, 'String must be an empty string.')

/************************************
 * PropTypes.email[.isRequired]
 ************************************/

const email = createPropType((value) => {
  return typeof value === 'string' && validateEmail(value) && value.length <= MAX_EMAIL_LENGTH
}, `Email must be valid and less than ${MAX_EMAIL_LENGTH} characters.`)

/************************************
 * PropTypes.emails[.isRequired]
 ************************************/

const emails = createPropType((value) => {
  return typeof value === 'string' &&
    validateEmails(value) &&
    value.split(',')
      .map((x) => x.trim())
      .filter(Boolean)
      .every((x) => x.length <= MAX_EMAIL_LENGTH)
}, `Comma separated emails must be valid and each email must be less than ${MAX_EMAIL_LENGTH} characters`)

/************************************
 * PropTypes.id[.isRequired]
 ************************************/

const id = createPropType((value) => {
  return typeof value === 'string' && value.length <= MAX_ID_LENGTH
}, `String must be a valid ID that is less than ${MAX_ID_LENGTH} characters.`)

/************************************
 * PropTypes.phone[.isRequired]
 ************************************/

const phone = createPropType((value) => {
  // TEMP: auto-format as well as validate. But we need to adjust the API v1
  // endpoints to format instead.
  return typeof value === 'string' && validatePhoneNumber(formatPhoneNumber(value))
}, 'Phone number must be a valid international phone number without an extension.')

/************************************
 * PropTypes.shipmentQuery[.isRequired]
 ************************************/

const shipmentQuery = createPropType((value) => {
  return typeof value === 'object' && Object(value) === value && validateShipmentQuery(shipmentQuery)
}, 'Shipment query must conform to spec.')

/************************************
 * PropTypes.ISODateString[.isRequired]
 ************************************/

const ISODateString = createPropType((value) => {
  return typeof value === 'string' && validateISODateString(value)
}, 'Date string must be in simplified ISO8601 format: YYYY-MM-DDTHH:mm:ss.sssZ')

/************************************
 * PropTypes.dateOnlyString[.isRequired]
 ************************************/

const dateOnlyString = createPropType((value) => {
  return typeof value === 'string' && /^(?<YYYY>\d{4})-(?<MM>0[1-9]|1[0-2])-(?<DD>0[1-9]|[1-2][0-9]|3[0-1])$/u.exec(value)
}, 'Date string must be in date-only format: YYYY-MM-DD')

/************************************
 * PropTypes.dateMonthString[.isRequired]
 ************************************/

const dateMonthString = createPropType((value) => {
  return typeof value === 'string' && /^(?<YYYY>\d{4})-(?<MM>0[1-9]|1[0-2])$/u.exec(value)
}, 'Date string must be in year-month format: YYYY-MM')

/************************************
 * PropTypes.positiveInteger[.isRequired]
 ************************************/

const positiveInteger = createPropType((value) => {
  return Number.isSafeInteger(value) && value > 0
})

/************************************
 * PropTypes.nonNegativeInteger[.isRequired]
 ************************************/

const nonNegativeInteger = createPropType((value) => {
  return Number.isSafeInteger(value) && value >= 0
})

/************************************
 * PropTypes.numericRange(min, max)[.isRequired]
 ************************************/

const numericRange = (min, max = Number.MAX_VALUE) => {
  if (!Number.isFinite(min) || !Number.isFinite(max) || min >= max) {
    throw new Error('Arguments "min" and "max" must be finite numbers and "min" must be less than "max"')
  }

  return createPropType((value) => {
    return Number.isFinite(value) && value >= min && value <= max
  })
}

/************************************
 * PropTypes.integerRange(min, max)[.isRequired]
 ************************************/

const integerRange = (min, max = Number.MAX_SAFE_INTEGER) => {
  if (!Number.isSafeInteger(min) || !Number.isSafeInteger(max) || min >= max) {
    throw new Error('Arguments "min" and "max" must be integers and "min" must be less than "max"')
  }

  return createPropType((value) => {
    return Number.isSafeInteger(value) && value >= min && value <= max
  })
}

/************************************
 * PropTypes.geoJsonGeometry([type])[.isRequired]
 ************************************/

/**
 * @param {'Point'|'MultiPoint'|'LineString'|'LineString'|'Polygon'|'MultiPolygon'} [type]
 */
const geoJsonGeometry = (type = null) => createPropType((value) => {
  return Object(value) === value &&
    (!type || value.type === type) &&
    GeoJSON.isGeoJSONGeometry(value)
})

/************************************
 * PropTypes.url[.isRequired]
 ************************************/

/** @type {PropTypeFunc & { isRequired: PropTypeFunc, https: PropTypeFunc & { isRequired: PropTypeFunc } }} */
// @ts-ignore
const url = createPropType(validateUrl, 'Expected valid url')
url.https = createPropType(validateHttpsUrl, 'Expected valid https url')

/************************************
 * PropTypes.operatingHours[.isRequired]
 ************************************/

const operatingHours = createPropType(isValidOperatingHours)

/************************************
 * PropTypes.firestoreTimestamp[.isRequired]
 ************************************/

const firestoreTimestamp = createPropType((value) => {
  return (
    (
      value === '' // backwards compatibility
    ) ||
    (
      value instanceof Date && !isNaN(value.getTime())
    ) ||
    (
      !isNaN(new Date(value).getTime())
    ) ||
    (
      value &&
      Object(value) === value &&
      '_seconds' in value &&
      '_nanoseconds' in value
    ) ||
    (
      value &&
      Object(value) === value &&
      'seconds' in value &&
      'nanoseconds' in value
    ) ||
    (
      value &&
      Object(value) === value &&
      'toDate' in value &&
      typeof value.toDate === 'function'
    )
  )
}, 'Expected Firestore Timestamp-like object or Date instance')

/************************************
 * PropTypes.pattern(regex, { error?: string }?)[.isRequired]
 ************************************/

const pattern = (regex, message = 'Text does not match the pattern.') => {
  if (!(regex instanceof RegExp)) {
    throw new TypeError('Argument "regex" must be an instance of RegExp')
  }

  return createPropType((value) => {
    return typeof value === 'string' && regex.test(value)
  }, message)
}

/************************************
 * PropTypes.and([PropTypes.XXX, ...])
 ************************************/

const and = (types) => {
  if (!Array.isArray(types)) {
    throw new TypeError('Argument "types" must be an array')
  }

  if (types.length <= 1) {
    throw new Error('Argument "types" must have at least 2 types')
  }

  return (props, propName, componentName, location, propFullName) => {
    return types.reduce((error, type) => {
      if (error) {
        return error
      }

      try {
        assertPropTypes(
          { [propName]: type },
          props,
          location,
          componentName
        )
      } catch (error) {
        return error
      }

      return null
    }, null)
  }
}

/************************************
 * PropTypes.someOf(values[])[.isRequired]
 ************************************/

const someOf = (values) => createPropType((value) => {
  return Array.isArray(value) && value.every((x) => values.includes(x))
})

/************************************
 * PropTypes.conditional(condition, propType)
 ************************************/

/**
 * @param {(props, propName: string) => boolean} condition
 * @param {PropTypeFunc} propType
 * @return {PropTypeFunc}
 */
const conditional = (condition, propType) => (props, propName, componentName, location, propFullName) => {
  if (condition(props, propName)) {
    try {
      assertPropTypes(
        { [propName]: propType },
        props,
        location,
        componentName
      )
    } catch (error) {
      return error
    }
  }
}

module.exports = {
  ...PropTypes,
  createPropType,
  maxString,
  emptyString,
  email,
  emails,
  phone,
  ISODateString,
  dateOnlyString,
  dateMonthString,
  id,
  positiveInteger,
  nonNegativeInteger,
  and,
  someOf,
  numericRange,
  integerRange,
  geoJsonGeometry,
  pattern,
  minString,
  conditional,
  url,
  shipmentQuery,
  operatingHours,
  firestoreTimestamp
}
