const { Time } = require('../../types')

// A time range is an object like { fromTime: string, toTime: string }
// Where the time value must conform to the format supported by Time.from.
function isTimeRange (range) {
  if (typeof range !== 'object') {
    return false
  }

  const rangeKeys = Object.keys(range)

  if (rangeKeys.length !== 2) {
    return false
  }

  if (!rangeKeys.includes('fromTime') || !rangeKeys.includes('toTime')) {
    return false
  }

  const fromResult = Time.from(range.fromTime)
  const toResult = Time.from(range.toTime)

  if (fromResult.errors.length || toResult.errors.length) {
    return false
  }

  const from = fromResult.value
  const to = toResult.value

  return from.magnitude <= to.magnitude
}

exports.isTimeRange = isTimeRange
