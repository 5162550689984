module.exports = function getSemVerParts (version) {
  if (typeof version !== 'string' || /^\d+(\.\d+){0,2}$/u.test(version) === false) {
    return {
      major: '0',
      minor: '0',
      patch: '0'
    }
  }

  const parts = version.split('.')

  return {
    major: parts[0],
    minor: parts[1] || '0',
    patch: parts[2] || '0'
  }
}
