const { sorter } = require('../sort')
const { convertOrdersToArray, convertOrdersArrayToObject } = require('../models/ShipmentOrder')

function sortOrdersByDeliveredAt (shipment) {
  return convertOrdersArrayToObject(
    convertOrdersToArray(shipment.orders).sort(sorter('deliveredAt', { direction: 'asc' }))
  )
}

exports.sortOrdersByDeliveredAt = sortOrdersByDeliveredAt
