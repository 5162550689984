class DriverChallengeLogic {
  constructor ({
    requiredNumberOfDeliveries,
    incentive,
    suspended,
    totalBudget,
    totalBudgetUsed,
    numberOfQualifiedDrivers,
    validFromAt,
    validToAt
  }) {
    this.suspended = suspended
    this.requiredNumberOfDeliveries = Boolean(requiredNumberOfDeliveries)
    this.incentive = incentive
    // Takes care of ISO string and an actual Date instance:
    this.validFromAt = new Date(validFromAt)
    this.validToAt = new Date(validToAt)
    this.totalBudget = totalBudget
    this.totalBudgetUsed = totalBudgetUsed
    this.numberOfQualifiedDrivers = numberOfQualifiedDrivers
  }

  isSuspended () {
    return this.suspended === true
  }

  isValidOnDate (date) {
    const msec = date.getTime()

    return (this.validFromAt.getTime() < msec && msec < this.validToAt.getTime())
  }

  canBeActivated () {
    return !this.isSuspended() &&
      this.isValidOnDate(new Date())
  }

  getStatus () {
    const now = Date.now()
    const validFromAt = new Date(this.validFromAt).getTime()
    const validToAt = new Date(this.validToAt).getTime()

    if (this.suspended) {
      return 'suspended'
    }

    if (validFromAt < now && validToAt > now) {
      return 'open'
    } else if (validToAt < now) {
      return 'closed'
    } else if (now < validFromAt) {
      return 'pending'
    }
  }

  getNumberOfQualifiedDrivers () {
    return this.numberOfQualifiedDrivers
  }
}

module.exports = exports = DriverChallengeLogic
