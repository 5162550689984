import React from 'react'
import PropTypes from 'prop-types'

export default function RouteLink ({
  routeKey,
  routes,
  constructPathname,
  updateBrowserUrl,
  send = null,
  params = {},
  className = null,
  style = null,
  updateUrl = false,
  children = null
}) {
  return (
    <a
      href={constructPathname(routeKey, params, routes)}
      target='_blank'
      rel='noopener noreferrer'
      className={className}
      style={style}
      onClick={(evt) => {
        if (typeof send === 'function') {
          if (!evt.metaKey && !evt.ctrlKey) {
            evt.preventDefault()
            const event = routes[routeKey].event

            if (event) {
              send({ type: event, ...params })

              if (updateUrl) {
                updateBrowserUrl(routeKey)
              }
            }
          }
        }
      }}
    >{children}</a>
  )
}

RouteLink.propTypes = {
  routeKey: PropTypes.string.isRequired,
  routes: PropTypes.object.isRequired,
  constructPathname: PropTypes.func.isRequired,
  send: PropTypes.func,
  params: PropTypes.object,
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node
}
