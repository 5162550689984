const { Std } = require('../../core')
const { isServiceHours } = require('./isServiceHours')

/**
 * Retrieve a copy of the weekday hours for the specified weekday. If the weekday has no
 * hours then returns a day object with an empty 'ranges' array meaning "closed".
 *
 * @param {ServiceHours} serviceHours
 * @param {number} weekday O = Sunday, 6 = Saturday
 * @return {WeekdayDeliveryDay}
 */
function getWeekdayHours (serviceHours, weekday) {
  if (!isServiceHours(serviceHours)) {
    throw Std.err('TypeError', 'Service hours is invalid')
  }

  if (weekday < 0 || weekday > 6) {
    throw Std.err('TypeError', 'weekday is out of range')
  }

  return Std.clone((serviceHours.days || []).find((d) => d.day === weekday) || { day: weekday, ranges: [] })
}

exports.getWeekdayHours = getWeekdayHours
