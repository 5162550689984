import React from 'react'
import PropTypes from 'prop-types'
import 'moment-timezone'
import moment from 'moment'
import { CAPABILITIES } from '@trexity/common/capReq/constants'
import { MAX_DELIVERY_WAYPOINTS } from '@trexity/common/shipments/constants'
import { getAutoGeneratedOrderId, isLabelInvalidated } from '@trexity/common/shipments'
import { getFormattedMonetaryValueString } from '@trexity/common/pricing'
import { PHONE_EXTENSIONS } from '@trexity/common/phone/constants'
import { formatPhoneNumber, validatePhoneNumber } from '@trexity/common/phone'
import { validateEmail } from '@trexity/common/email'
import { isOperatingAt, getFormattedDurationString, getNearestAcceptableDate } from '@trexity/common/temporal'
import { getFormattedDistanceString } from '@trexity/common/geo'
import { shouldEnforceRequiresPersonHandoff, shouldEnforceLegalAgeAlcohol, convertOrdersToArray } from '@trexity/common/models/ShipmentOrder'
import Map from './Map'
import RequirementInput from './RequirementInput'
import { AddressAutoComplete } from './AddressAutoComplete'

import {
  Alert,
  Icon,
  Button,
  Radio,
  Form,
  Input,
  InputNumber,
  Modal,
  Tag,
  Checkbox,
  Card,
  Typography,
  Spin,
  Tabs,
  Row,
  Col,
  DatePicker,
  TimePicker,
  Tooltip,
  Skeleton
} from 'antd'

const { STORED_EXTENSION } = PHONE_EXTENSIONS

export default function ShipmentEditor ({
  mode = 'create',
  onPickupAddressChange,
  onOrdersChange,
  scheduleInterval,
  maxNumLabels,
  deps
}) {
  const {
    current,
    send,
    data,
    tc
  } = deps

  const {
    shipmentForm,
    estimatedShippingDirectionsResult = null,
    estimatedShippingDeliveryZone = null,
    estimatedShippingDuration = null,
    estimatedShippingDeliveryLocations = null,
    estimatedShippingOversizedVars = null,
    currentShipmentId: shipmentId,
    currentShipmentOrderUuid: orderUuid,
    merchantId,
    isAdmin = false
  } = data

  const merchant = React.useMemo(() => data.merchant || {}, [data.merchant])
  const scheduledPostAtDisabledMinutes = [...Array(60)].map((_, index) => index).filter((_, index) => index % scheduleInterval !== 0)

  const availableRequirements = React.useMemo(() => merchant.availableRequirements || {}, [merchant])
  const currentStatus = shipmentForm.currentStatus

  const [pickupAddress, setPickupAddress] = React.useState('')
  const [pickupAddressNotes, setPickupAddressNotes] = React.useState('')
  const [pickupNotes, setPickupNotes] = React.useState('')
  const [requirements, setRequirements] = React.useState({})
  const [orders, setOrders] = React.useState(null)
  const [scheduledPostEnabled, setScheduledPostEnabled] = React.useState(false)

  const [scheduledPostAt, setScheduledPostAt] = React.useState(
    shipmentForm.scheduledPostAt
      ? shipmentForm.scheduledPostAt.toDate()
      : null
  )

  const [activeKey, setActiveKey] = React.useState('0')

  const ordersArray = convertOrdersToArray(orders)
  const isCreating = mode === 'create'
  const isSingleOrderMode = !isAdmin
  const isBundledRoute = ordersArray.length > 1
  const isEditingRoute = !orderUuid
  const isRouteLevelInputDisabled = isSingleOrderMode && !isCreating && !isEditingRoute && isBundledRoute
  const canShowDeliveryArea = !isSingleOrderMode || (isSingleOrderMode && (!isEditingRoute || !isBundledRoute)) || isCreating
  const canShowRouteArea = !isSingleOrderMode || (isSingleOrderMode && (isEditingRoute || !isBundledRoute)) || isCreating

  React.useEffect(() => {
    setOrders(
      Object.entries(shipmentForm.orders || {}).reduce((memo, [sidx, order]) => ({
        ...memo,
        [sidx]: {
          ...order,
          requiresPersonHandoff: (
            order.id || shipmentForm.__cloned
              ? order.requiresPersonHandoff
              : Boolean(merchant.defaultRequiresPersonHandoff)
          ),
          containsAlcohol: (
            order.id || shipmentForm.__cloned
              ? order.containsAlcohol
              : Boolean(merchant.defaultContainsAlcohol)
          )
        }
      }), {})
    )
  }, [shipmentForm.orders, merchant.defaultRequiresPersonHandoff, merchant.defaultContainsAlcohol])

  React.useEffect(() => {
    const postAt = (
      (shipmentForm.scheduledPostAt && shipmentForm.scheduledPostAt.toDate())
    )

    setScheduledPostAt(postAt || null)
    setScheduledPostEnabled(shipmentForm.scheduledPostEnabled)

    setPickupAddressNotes(
      shipmentForm.pickupAddressNotes ||
      (isCreating ? (merchant && merchant.addressNotes) : '')
    )

    setPickupAddress(
      shipmentForm.pickupAddress ||
      (isCreating ? (merchant && merchant.address) : '')
    )

    setPickupNotes(
      shipmentForm.pickupNotes ||
      (isCreating ? (merchant && merchant.defaultPickupNotes) : '')
    )

    // Iterate through all requirements that are available to this merchant,
    // and re-create the requirements object based on what is required on this
    // shipment along with including default values for those requirements not
    // specified
    const existingRequirements = shipmentForm.requirements || {}

    const requirements = Object.keys(availableRequirements)
      .reduce((memo, key) => ({
        ...memo,
        [key]: key in existingRequirements
          ? existingRequirements[key]
          : availableRequirements[key].defaultValue
      }), {})

    setRequirements(requirements)

    setActiveKey(String(convertOrdersToArray(shipmentForm.orders).findIndex((order) => !order.internalType)))
  }, [
    shipmentForm.orders,
    shipmentForm.scheduledPostAt,
    shipmentForm.scheduledPostEnabled,
    shipmentForm.pickupAddressNotes,
    shipmentForm.pickupAddress,
    shipmentForm.pickupNotes,
    shipmentForm.requirements,
    availableRequirements,
    isCreating,
    merchant
  ])

  React.useEffect(() => {
    if (scheduledPostAt && !allowChangingScheduledPostEnabled) {
      // If scheduledPostAt is set, we need to enable scheduledPostEnabled
      setScheduledPostEnabled(true)
    } else {
      // If scheduledPostAt is cleared, we need to disable scheduledPostEnabled
      setScheduledPostEnabled(false)
    }
  }, [scheduledPostAt])

  React.useEffect(() => {
    if (Array.isArray(estimatedShippingDeliveryLocations)) {
      estimatedShippingDeliveryLocations.forEach((location, index) => {
        updateDelivery(index, { location })
      })
    }
  }, [estimatedShippingDeliveryLocations])

  const calculateEstimatedCostDebounceRef = React.useRef(null)

  React.useEffect(() => {
    clearTimeout(calculateEstimatedCostDebounceRef.current)
  }, [])

  const calculateEstimatedCost = React.useCallback((pickupAddress, requirements, ordersArray) => {
    clearTimeout(calculateEstimatedCostDebounceRef.current)

    calculateEstimatedCostDebounceRef.current = setTimeout(() => {
      send({
        type: 'calculate estimated cost',
        data: {
          pickupAddress,
          deliveryOrders: ordersArray.filter(({ internalType }) => !internalType),
          requirements,
          linkedParentType: (shipmentForm.linkedParentShipment && shipmentForm.linkedParentShipment.type) || null,
          linkedParentShipmentRate: (shipmentForm.linkedParentShipment && shipmentForm.linkedParentShipment.shipmentRate) || null,
          combinedLinkedParentTypes: (shipmentForm.metadata && (shipmentForm.metadata.combinedLinkedParentTypes || (shipmentForm.metadata.any && shipmentForm.metadata.any.combinedLinkedParentTypes))) || null
        }
      })
    }, 750)
  }, [pickupAddress, requirements, ordersArray, shipmentForm.linkedParentShipment, shipmentForm.metadata])

  // Send changes to certain data upwards
  React.useEffect(() => onPickupAddressChange(pickupAddress || ''), [pickupAddress])
  React.useEffect(() => onOrdersChange(orders || {}), [orders])

  const getCurrentFormState = (overrides) => ({
    pickupAddress,
    pickupAddressNotes,
    pickupNotes,
    requirements,
    orders,
    scheduledPostAt: scheduledPostAt ? scheduledPostAt.toISOString() : null,
    scheduledPostEnabled,
    linkedParentShipment: shipmentForm.linkedParentShipment,
    targetDeliveryDuration: shipmentForm.targetDeliveryDuration,
    ...overrides
  })

  const isStateSaving = current.within(['Shipment Saving.Creating', 'Shipment Saving.Updating'])
  const isStateLoading = current.within(['Loading Shipment', 'Loading Merchant'])
  const isStateDeleting = current.within('Shipment Saving.Deleting')
  const isStateUnbundlingDelivery = current.within('Shipment Saving.Unbundling Delivery')
  const isAnyStateActive = isStateSaving || isStateLoading || isStateDeleting || isStateUnbundlingDelivery
  const canBeReposted = currentStatus === 'ON_HOLD'
  const isGeneratingLabel = current.within('Requesting Shipping Labels')
  // const isReturn = [RETURN_TO_SENDER, RETURN_REUSABLES].includes(shipmentForm.linkedParentShipment && shipmentForm.linkedParentShipment.type)

  const canMakeSignificantChanges = (
    !currentStatus ||
    canBeReposted ||
    (
      isAdmin && (
        currentStatus === 'WAITING_FOR_ACCEPTANCE' ||
        currentStatus === 'EN_ROUTE_TO_PICKUP' ||
        currentStatus === 'ARRIVED_AT_PICKUP' ||
        currentStatus === 'OUT_FOR_DELIVERY' ||
        currentStatus === 'ARRIVED_AT_DELIVERY' ||
        currentStatus === 'RENOUNCED_BY_DRIVER'
      )
    )
  )

  const allowChangingScheduledPostEnabled = (
    !shipmentForm.scheduledPostEnabled &&
    Boolean(shipmentForm.scheduledPostAt)
  )

  const inputDisabled = isAnyStateActive || !canMakeSignificantChanges

  const merchantAddressLngLat = merchant.addressLocation && merchant.addressLocation.coordinates
    ? merchant.addressLocation.coordinates
    : null

  const proximity = (
    merchantAddressLngLat ||
    [-75.6971931, 45.4215296] // Ottawa default
  )

  const validationTests = [
    {
      test: ordersArray.every(({ orderId }) => Boolean(orderId)),
      message: `Missing order ID for deliveries ${ordersArray.map(({ orderId }, idx) => orderId ? 0 : `#${idx + 1}`).filter((x) => x).join(', ')}`
    },
    {
      test: ordersArray.every(({ description }) => Boolean(description)),
      message: `Missing description for deliveries ${ordersArray.map(({ description }, idx) => description ? 0 : `#${idx + 1}`).filter((x) => x).join(', ')}`
    },
    {
      test: Boolean(pickupAddress),
      message: 'Missing pickup address'
    },
    {
      test: ordersArray.every(({ name }) => Boolean(name)),
      message: `Missing recipient name for deliveries ${ordersArray.map(({ name }, idx) => name ? 0 : `#${idx + 1}`).filter((x) => x).join(', ')}`
    },
    {
      test: ordersArray.every(({ address }) => Boolean(address)),
      message: `Missing delivery address for deliveries ${ordersArray.map(({ address }, idx) => address ? 0 : `#${idx + 1}`).filter((x) => x).join(', ')}`
    },
    {
      test: ordersArray.every(({ value }) => value >= 0),
      message: `Missing or incorrect value of goods for deliveries ${ordersArray.map(({ value }, idx) => value >= 0 ? 0 : `#${idx + 1}`).filter((x) => x).join(', ')}`
    },
    {
      test: ordersArray.every(({ email }) => Boolean(email)),
      message: `Missing delivery email for deliveries ${ordersArray.map(({ email }, idx) => (email && !validateEmail(email)) ? `#${idx + 1}` : 0).filter((x) => x).join(', ')}`
    },
    {
      test: ordersArray.every(({ phone }) => Boolean(phone)),
      message: `Missing delivery phone for deliveries ${ordersArray.map(({ phone }, idx) => (phone && !validatePhoneNumber(formatPhoneNumber(phone))) ? `#${idx + 1}` : 0).filter((x) => x).join(', ')}`
    },
    {
      test: ordersArray.every(({ email }) => email ? validateEmail(email) : true),
      message: `Invalid delivery email for deliveries ${ordersArray.map(({ email }, idx) => (email && !validateEmail(email)) ? `#${idx + 1}` : 0).filter((x) => x).join(', ')}`
    },
    {
      test: ordersArray.every(({ phone }) => phone ? validatePhoneNumber(formatPhoneNumber(phone)) : true),
      message: `Invalid delivery phone for deliveries ${ordersArray.map(({ phone }, idx) => (phone && !validatePhoneNumber(formatPhoneNumber(phone))) ? `#${idx + 1}` : 0).filter((x) => x).join(', ')}`
    },
    scheduledPostAt && {
      test: isOperatingAt(merchant.operatingHours, merchant.timezone, scheduledPostAt.getTime()),
      message: 'Scheduled date and time are outside your delivery hours. You can adjust them in your Profile.'
    },
    {
      test: ordersArray.every(({ value }) => value > 0),
      message: `Missing value of goods for deliveries ${ordersArray.map(({ value }, idx) => (value <= 0) ? `#${idx + 1}` : 0).filter((x) => x).join(', ')}`
    }
  ].filter(Boolean)

  const validationMessages = validationTests.filter(({ test }) => !test).map(({ message }) => message)

  const directionsResult = (
    estimatedShippingDirectionsResult ||
    shipmentForm.directionsResult
  )

  const deliveryZone = (
    estimatedShippingDeliveryZone ||
    shipmentForm.deliveryZone
  )

  const routeDuration = (
    estimatedShippingDuration ||
    shipmentForm.cachedTotalRouteDuration
  )

  const oversizedVars = (
    estimatedShippingOversizedVars ||
    (shipmentForm.shipmentRate && shipmentForm.shipmentRate.rateCard.oversized) ||
    null
  )

  const verifyTargetDeliveryDuration = ({ exceeds: { override, abort }, within }) => {
    const target = (
      Number.isInteger(shipmentForm.targetDeliveryDuration)
        ? shipmentForm.targetDeliveryDuration
        : merchant.alwaysIgnoreTargetDeliveryDuration
          ? 0
          : (merchant.defaultTargetDeliveryDuration || 0)
    )

    if (target > 0 && routeDuration > target) {
      const title = `This route exceeds the total target delivery time of ${getFormattedDurationString(target)} by ${getFormattedDurationString(routeDuration - target, { includeSeconds: true })}. Please confirm that you still want to allow this change:`

      Modal.confirm({
        title: title,
        okText: 'Confirm',
        cancelText: 'Cancel',
        onOk () {
          override()
        },
        onCancel () {
          abort()
        }
      })
    } else {
      within()
    }
  }

  const onSave = (evt) => {
    evt.preventDefault()

    const saveStep = (overrides) => {
      send({
        type: isCreating ? 'create new shipment' : 'update shipment',
        shipmentId,
        merchantId,
        shipmentForm: getCurrentFormState(overrides)
      })
    }

    const legalStep = () => {
      const enabled = (
        !isAdmin
      )

      if (enabled) {
        Modal.confirm({
          title: 'Liability Waiver',
          content: (
            <div>
              <div>You are responsible for the information that you have provided to Trexity herein and Trexity shall not be liable to you for any missing, inaccurate or incomplete information. Title to the goods and risk of loss shall pass to you upon delivery of the goods at the location specified and you assume all risk for any theft, loss or damage, whether direct, indirect, consequential or otherwise, once the goods have been delivered.</div>
            </div>
          ),
          okText: 'I accept',
          cancelText: 'Cancel',
          onOk () {
            saveStep()
          }
        })
      } else {
        saveStep()
      }
    }

    const verifyStep = () => {
      verifyTargetDeliveryDuration({
        exceeds: {
          override: () => legalStep(),
          abort: () => {}
        },
        within: () => legalStep()
      })
    }

    const validateStep = () => {
      if (validationMessages.length) {
        Modal.confirm({
          title: 'The following fields are invalid:',
          content: (
            <ul>{validationMessages.map((message, idx) => <li key={idx}>{message}</li>)}</ul>
          ),
          cancelButtonProps: { style: { display: 'none' } }
        })
      } else {
        verifyStep()
      }
    }

    const labelInvalidatedStep = () => {
      const prevOrdersArray = convertOrdersToArray(shipmentForm.orders)

      const invalidatedLabelResults = ordersArray
        .map((order) => ({
          order,
          prevOrder: prevOrdersArray.find((o) => o.id === order.id)
        }))
        // If this is a new order, we won't find it in the originals. It's
        // therefore impossible for it to be invalidated
        .filter(({ prevOrder }) => prevOrder)
        // Only check orders who currently have a valid label
        .filter(({ prevOrder }) => prevOrder.labelValid)
        // Verify if specific order data has changed that would invalidate
        // the label
        .map(({ prevOrder, order }) => ({
          prevOrder,
          order,
          result: isLabelInvalidated({
            prevPickupAddress: shipmentForm.pickupAddress,
            prevRequirements: shipmentForm.requirements,
            prevOrder,
            pickupAddress,
            requirements,
            order
          })
        }))
        // Only retain the results whose label is invalidated
        .filter(({ result }) => result[0])

      if (invalidatedLabelResults.length) {
        Modal.confirm({
          title: 'Changes made to the following deliveries will invalidate their downloaded labels:',
          content: (
            <ul>{invalidatedLabelResults.map(({ order, result }, idx) => <li key={idx}>{order.orderId}: {result[1]}</li>)}</ul>
          ),
          okText: 'Proceed',
          cancelText: 'Cancel',
          onOk () {
            validateStep()
          }
        })
      } else {
        validateStep()
      }
    }

    labelInvalidatedStep()
  }

  const addDelivery = () => {
    if (ordersArray.length >= MAX_DELIVERY_WAYPOINTS) {
      // eslint-disable-next-line no-alert
      window.alert('You cannot add another delivery: limit exceeded')
      return
    }

    const nextSidx = String(ordersArray.length)

    const newOrders = {
      ...orders,
      [nextSidx]: {
        orderId: '',
        address: '',
        addressNotes: '',
        deliveryInstructions: merchant.defaultDeliveryInstructions || '',
        name: '',
        email: '',
        phone: '',
        description: '',
        value: 0,
        numLabels: 1,
        requiresPersonHandoff: Boolean(merchant.defaultRequiresPersonHandoff),
        containsAlcohol: Boolean(merchant.defaultContainsAlcohol)
      }
    }

    setOrders(newOrders)
    setActiveKey(nextSidx)

    return newOrders
  }

  const removeDelivery = (index) => {
    const newOrders = ordersArray.reduce((memo, order, idx) => {
      order = order || {}

      return {
        ...memo,
        ...(idx !== index && { [String(Object.keys(memo).length)]: order })
      }
    }, {})

    setOrders(newOrders)
    setActiveKey(String(Math.max(Number(activeKey) - 1, 0)))

    return newOrders
  }

  const updateDelivery = (index, data) => {
    const newOrders = ordersArray.reduce((memo, order, idx) => {
      order = order || {}

      return {
        ...memo,
        ...(idx === index
          ? { [idx]: { ...order, ...data } }
          : { [idx]: order }
        )
      }
    }, {})

    setOrders(newOrders)

    return newOrders
  }

  const onChangeRequirementValue = (key, recalc = true) => (value) => {
    const updatedRequirements = {
      ...requirements,
      [key]: value
    }

    setRequirements(updatedRequirements)

    if (recalc) {
      calculateEstimatedCost(pickupAddress, updatedRequirements, ordersArray)
    }

    return updatedRequirements
  }

  const isFalsyButNotBoolean = (value) => typeof value !== 'boolean' && !value

  const getRequirementValue = (req) => (
    isFalsyButNotBoolean(requirements[req])
      ? availableRequirements[req].defaultValue
      : requirements[req]
  )

  const onDeleteDelivery = (evt) => {
    Modal.confirm({
      title: 'Are you sure you wish to delete this delivery?',
      okText: 'Delete delivery',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk () {
        if (ordersArray.length > 1) {
          const index = ordersArray.findIndex(({ id }) => id === orderUuid)
          const newOrders = removeDelivery(index)

          send({
            type: 'update shipment',
            shipmentId,
            merchantId,
            shipmentForm: getCurrentFormState({
              orders: newOrders
            })
          })
        } else {
          send({ type: 'delete shipment', shipmentId })
        }
      }
    })
  }

  const unbundleDelivery = () => {
    if (!isRouteLevelInputDisabled) {
      return
    }

    const order = ordersArray.find(({ id }) => id === orderUuid)

    Modal.confirm({
      title: 'Please confirm that you would like to unbundle this delivery from this route:',
      content: (
        <p>This will move <strong>{order.orderId}</strong> to a new single delivery route. All the details, requirements, and schedule will be the same and ready for you to edit.</p>
      ),
      okText: 'Confirm',
      cancelText: 'Cancel',
      onOk () {
        send({
          type: 'unbundle delivery',
          shipmentId,
          orderUuid
        })
      }
    })
  }

  return (
    <Form
      onSubmit={onSave}
      layout='vertical'
      className='shipment-editor'
    >
      {canShowDeliveryArea && !isStateLoading ? (
        <div style={{ width: '100%', marginBottom: 24 }} id='packages'>
          {isRouteLevelInputDisabled ? (
            <Alert
              message='This delivery is part of an optimized route and some fields are not accessible.'
              description={(
                <React.Fragment>
                  <p>To change the <strong>Delivery Address</strong>, this delivery must be unbundled from the route.</p>
                  <Button
                    type='primary'
                    disabled={inputDisabled}
                    loading={isStateUnbundlingDelivery}
                    onClick={unbundleDelivery}
                  >Unbundle this delivery</Button>
                </React.Fragment>
              )}
              type='info'
              showIcon
              style={{ marginBottom: 24 }}
            />
          ) : null}
          <TabsWrapper
            isSingleOrderMode={isSingleOrderMode}
            activeKey={activeKey}
            onChange={(key) => setActiveKey(key)}
            tabPosition='top'
            type={isAdmin ? 'editable-card' : 'line'}
            animated={false}
            tabBarStyle={{ marginBottom: 0 }}
            onEdit={(key, action) => {
              if (inputDisabled) return

              if (action === 'remove' && ordersArray.length > 1) {
                const index = Number(key)
                const newOrders = removeDelivery(index)
                calculateEstimatedCost(pickupAddress, requirements, Object.values(newOrders))
              } else if (action === 'add' && isAdmin) {
                addDelivery()
              }
            }}
          >
            {ordersArray.map((order, index) => {
              order = order || {}

              if (order.internalType) {
                // We need to make sure the index is accurate, so this is why we
                // prefer to check for order.internalType within the map as opposed to
                // filtering it out ahead of time
                return false
              }

              if (orderUuid && order.id !== orderUuid) {
                return false
              }

              const shipmentDeliveryPane = (
                <ShipmentDeliveryPane
                  key={order.id || index}
                  inputDisabled={inputDisabled || Boolean(order.deliveredAt)}
                  currentOrder={order}
                  proximity={proximity}
                  isGeneratingLabel={isGeneratingLabel}
                  isRouteLevelInputDisabled={isRouteLevelInputDisabled}
                  isAdmin={isAdmin}
                  isSingleOrderMode={isSingleOrderMode}
                  maxNumLabels={maxNumLabels}
                  merchant={merchant || {}}
                  oversizedVars={oversizedVars}
                  availableRequirements={availableRequirements}
                  onGenerateLabel={() => {
                    send({ type: 'shipment generate label', shipmentId, orderUuids: [order.id], orderId: order.orderId })
                  }}
                  onChange={(data) => {
                    const otherOrderIds = ordersArray.map(({ orderId }) => orderId).filter((_, idx) => idx !== index)

                    const shouldAutoGenerateOrderId = (
                      data.orderId === '' ||
                      getAutoGeneratedOrderId(order, otherOrderIds) === data.orderId
                    )

                    if (shouldAutoGenerateOrderId) {
                      data.orderId = getAutoGeneratedOrderId(data, otherOrderIds)
                    }

                    if (data.containsAlcohol !== order.containsAlcohol) {
                      if (shouldEnforceRequiresPersonHandoff({ shipment: shipmentForm, order: data })) {
                        data.requiresPersonHandoff = true
                      }
                    }

                    const newOrders = updateDelivery(index, data)

                    let updatedRequirements = { ...requirements }

                    // We've opted not to update Legal Age Alcohol on the client
                    // in case they toggle it back & forth. We choose to instead
                    // let the server handle enforcement when saving
                    const ENFORCE_LEGAL_AGE_ON_CLIENT = false

                    if (data.containsAlcohol !== order.containsAlcohol && ENFORCE_LEGAL_AGE_ON_CLIENT) {
                      if (shouldEnforceLegalAgeAlcohol({ shipment: { orders: newOrders } })) {
                        updatedRequirements = onChangeRequirementValue('legalAgeAlcohol')(true, false)
                      }
                    }

                    const shouldRecalculateCost = (
                      order.address !== data.address ||
                      order.requiresPersonHandoff !== data.requiresPersonHandoff ||
                      order.numLabels !== data.numLabels ||
                      requirements.legalAgeAlcohol !== updatedRequirements.legalAgeAlcohol
                    )

                    if (shouldRecalculateCost) {
                      calculateEstimatedCost(pickupAddress, updatedRequirements, Object.values(newOrders))
                    }
                  }}
                  deps={{ tc }}
                />
              )

              return (
                isSingleOrderMode ? (
                  <div key={order.id || index} style={{ marginBottom: 10 }}>
                    {shipmentDeliveryPane}
                  </div>
                ) : (
                  <Tabs.TabPane
                    key={index}
                    tab={(
                      order.orderId
                        ? `${order.orderId}`
                        : `Delivery #${index + 1}`
                    )}
                    closable={ordersArray.length > 1 && !order.deliveredAt}
                  >{shipmentDeliveryPane}</Tabs.TabPane>
                )
              )
            })}
          </TabsWrapper>
        </div>
      ) : null}

      {canShowRouteArea && !isStateLoading ? (
        <React.Fragment>
          {directionsResult && isAdmin ? (
            <Map
              directionsResult={directionsResult}
              deliveryZone={deliveryZone}
              height={300}
              width='100%'
              shipment={{ orders, id: String(shipmentId || '') }}
              deps={{ google: window.google }}
            />
          ) : null}

          <Card style={{ marginBottom: 24 }}>
            <Row gutter={[20, 20]}>
              <Col xs={24} sm={12} className='pickup-information-area'>
                <Typography.Title level={3}>Pickup Information</Typography.Title>
                <Form.Item label='Address' required style={{ marginBottom: 0 }}>
                  <AddressAutoComplete
                    value={pickupAddress || ''}
                    placeholder='e.g. 100-123 Example St, Ottawa ON K1P 6E5, CA'
                    onChange={(pickupAddress) => {
                      setPickupAddress(pickupAddress)
                      calculateEstimatedCost(pickupAddress, requirements, ordersArray)
                    }}
                    disabled={inputDisabled || isRouteLevelInputDisabled}
                    deps={{ tc }}
                  />
                </Form.Item>
                <Form.Item>
                  <Input.TextArea
                    value={pickupAddressNotes}
                    disabled={inputDisabled || isRouteLevelInputDisabled}
                    autoSize={{ minRows: 1, maxRows: 5 }}
                    placeholder='Line 2 (e.g. buzzer)'
                    onChange={(evt) => setPickupAddressNotes(evt.target.value)}
                  />
                </Form.Item>
                <Form.Item label={<span>Pickup Notes <Tooltip title='Notes for the driver for how to pickup the orders from your store'><Icon type="info-circle" style={{ marginLeft: 5 }} /></Tooltip></span>}>
                  <Input.TextArea
                    value={pickupNotes}
                    rows={3}
                    disabled={inputDisabled || isRouteLevelInputDisabled}
                    placeholder={(merchant && merchant.defaultPickupNotes) || ''}
                    onChange={(evt) => setPickupNotes(evt.target.value)}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <div className='driver-requirements-area'>
                  <Typography.Title level={3}>Driver Requirements</Typography.Title>
                  {'vehicleType' in availableRequirements ? (
                    <Form.Item label={<span>{CAPABILITIES.vehicleType.displayName} <Tooltip title={CAPABILITIES.vehicleType.description}><Icon type="info-circle" style={{ marginLeft: 5 }} /></Tooltip></span>}>
                      <RequirementInput
                        requirement='vehicleType'
                        disabled={inputDisabled || isRouteLevelInputDisabled}
                        value={getRequirementValue('vehicleType')}
                        onChange={onChangeRequirementValue('vehicleType')}
                      />
                    </Form.Item>
                  ) : null}
                  {'legalAgeAlcohol' in availableRequirements ? (
                    <Form.Item className={'can-deliver-alcohol-area'}>
                      <RequirementInput
                        requirement='legalAgeAlcohol'
                        disabled={inputDisabled || isRouteLevelInputDisabled}
                        value={getRequirementValue('legalAgeAlcohol')}
                        onChange={onChangeRequirementValue('legalAgeAlcohol')}
                        meta={{ label: <span>{CAPABILITIES.legalAgeAlcohol.displayName} <Tooltip title={CAPABILITIES.legalAgeAlcohol.description}><Icon type="info-circle" style={{ marginLeft: 5 }} /></Tooltip></span> }}
                      />
                    </Form.Item>
                  ) : null}
                  {'perishable' in availableRequirements ? (
                    <Form.Item className={'can-deliver-perishables-area'}>
                      <RequirementInput
                        requirement='perishable'
                        disabled={inputDisabled || isRouteLevelInputDisabled}
                        value={getRequirementValue('perishable')}
                        onChange={onChangeRequirementValue('perishable')}
                        meta={{ label: <span>{CAPABILITIES.perishable.displayName} <Tooltip title={CAPABILITIES.perishable.description}><Icon type="info-circle" style={{ marginLeft: 5 }} /></Tooltip></span> }}
                      />
                    </Form.Item>
                  ) : null}
                  {'returnReusables' in availableRequirements ? (
                    <Form.Item className={'can-return-reusables-area'}>
                      <RequirementInput
                        requirement='returnReusables'
                        disabled={inputDisabled || isRouteLevelInputDisabled}
                        value={getRequirementValue('returnReusables')}
                        onChange={onChangeRequirementValue('returnReusables')}
                        meta={{ label: <span>{CAPABILITIES.returnReusables.displayName} <Tooltip title={CAPABILITIES.returnReusables.description}><Icon type="info-circle" style={{ marginLeft: 5 }} /></Tooltip></span> }}
                      />
                    </Form.Item>
                  ) : null}
                  {'internalTrexityStaff' in availableRequirements ? (
                    <Form.Item>
                      <RequirementInput
                        requirement='internalTrexityStaff'
                        disabled={inputDisabled || isRouteLevelInputDisabled}
                        value={getRequirementValue('internalTrexityStaff')}
                        onChange={onChangeRequirementValue('internalTrexityStaff')}
                        meta={{ label: <span>{CAPABILITIES.internalTrexityStaff.displayName} <Tooltip title={CAPABILITIES.internalTrexityStaff.description}><Icon type="info-circle" style={{ marginLeft: 5 }} /></Tooltip></span> }}
                      />
                    </Form.Item>
                  ) : null}
                  {'internalTrexityDriverSupport' in availableRequirements ? (
                    <Form.Item>
                      <RequirementInput
                        requirement='internalTrexityDriverSupport'
                        disabled={inputDisabled || isRouteLevelInputDisabled}
                        value={getRequirementValue('internalTrexityDriverSupport')}
                        onChange={onChangeRequirementValue('internalTrexityDriverSupport')}
                        meta={{ label: <span>{CAPABILITIES.internalTrexityDriverSupport.displayName} <Tooltip title={CAPABILITIES.internalTrexityDriverSupport.description}><Icon type="info-circle" style={{ marginLeft: 5 }} /></Tooltip></span> }}
                      />
                    </Form.Item>
                  ) : null}

                  {/*
                    Note that we have purposely omitted custom pricing requirements,
                    as these are not optional for those merchants who have them.
                  */}
                </div>
              </Col>
            </Row>
          </Card>

          <Card className='scheduling-information-area' style={{ marginBottom: 24 }}>
            <Typography.Title level={3}>Scheduling
              <Tooltip
                title={(
                  <React.Fragment>
                    If set, drivers will automatically be pinged at set date and time, otherwise, it will ping ASAP.<br />
                    <br />
                    {scheduledPostAt ? <span>Date and time are in:<br /><strong>{scheduledPostAt.toTimeString().split(' ').slice(1).join(' ')}</strong></span> : ''}
                  </React.Fragment>
                )}
              >
                <Icon type="info-circle" style={{ marginLeft: 5, fontSize: 16 }} />
              </Tooltip>
            </Typography.Title>

            <Form.Item>
              <DatePicker
                placeholder='ASAP'
                allowClear
                disabled={inputDisabled || isRouteLevelInputDisabled}
                value={(scheduledPostAt && moment(scheduledPostAt)) || null}
                onChange={(date) => {
                  if (date) {
                    setScheduledPostAt(getNearestAcceptableDate(date.toDate(), { interval: scheduleInterval }))
                  } else {
                    setScheduledPostAt(null)
                  }
                }}
                disabledDate={(current) => {
                  // Can not select days in the past
                  return current && moment().isAfter(current, 'day')
                }}
                style={{ marginRight: 5 }}
              />
              <TimePicker
                placeholder='ASAP'
                allowClear
                disabled={inputDisabled || isRouteLevelInputDisabled}
                use12Hours
                format='h:mm a'
                value={(scheduledPostAt && moment(scheduledPostAt)) || null}
                disabledMinutes={() => scheduledPostAtDisabledMinutes}
                hideDisabledOptions={true}
                onChange={(date) => {
                  if (date) {
                    setScheduledPostAt(getNearestAcceptableDate(date.toDate(), { interval: scheduleInterval }))
                  } else {
                    setScheduledPostAt(null)
                  }
                }}
              />
              {isAdmin && scheduledPostAt ? (
                <span style={{ marginLeft: 5 }}>{scheduledPostAt.toTimeString().split(' ').slice(1).join(' ')}</span>
              ) : null}
            </Form.Item>

            {allowChangingScheduledPostEnabled ? (
              <Form.Item>
                <Checkbox
                  checked={Boolean(scheduledPostEnabled)}
                  disabled={!scheduledPostAt || inputDisabled || isRouteLevelInputDisabled}
                  onChange={() => setScheduledPostEnabled(!scheduledPostEnabled)}
                >Automatically post at the scheduled date and time</Checkbox>
              </Form.Item>
            ) : null}
          </Card>
        </React.Fragment>
      ) : null}

      <Card className='summary-area' style={{ marginBottom: 24 }}>
        <Typography.Title level={3}>Summary</Typography.Title>
        <HeaderExtra
          pickupAddress={pickupAddress || ''}
          orders={orders || {}}
          isAdmin={isAdmin}
          deps={{
            current,
            data
          }}
        />
      </Card>

      {!isStateLoading ? (
        <Card className='button-area' size='small'>
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            {isSingleOrderMode && !isCreating && (!isEditingRoute || !isBundledRoute) ? (
              <Button
                disabled={inputDisabled}
                loading={isStateSaving /* in this case, we're saving without the delivery */}
                type='danger'
                htmlType='button'
                size='large'
                icon='delete'
                onClick={onDeleteDelivery}
              >
                Delete delivery
              </Button>
            ) : <div />}

            <Button
              disabled={inputDisabled}
              loading={isStateSaving}
              type='primary'
              htmlType='submit'
              size='large'
            >
              {isCreating ? 'Create' : 'Update'}
            </Button>
          </div>
        </Card>
      ) : null}

      {isStateLoading ? (
        <Card>
          <Skeleton spinning />
        </Card>
      ) : null}
    </Form>
  )
}

ShipmentEditor.propTypes = {
  mode: PropTypes.oneOf(['create', 'edit']),
  onPickupAddressChange: PropTypes.func.isRequired,
  onOrdersChange: PropTypes.func.isRequired,
  scheduleInterval: PropTypes.number.isRequired,
  maxNumLabels: PropTypes.number.isRequired,
  deps: PropTypes.exact({
    current: PropTypes.object,
    send: PropTypes.func,
    data: PropTypes.object,
    tc: PropTypes.object.isRequired
  }).isRequired
}

function HeaderExtra ({
  pickupAddress,
  orders,
  isAdmin,
  deps
}) {
  const {
    data,
    current
  } = deps

  const {
    shipmentForm = {},
    currentShipmentOrderUuid: orderUuid,
    estimatedShippingCost = null,
    estimatedShippingDuration = null,
    estimatedShippingDistance = null
  } = data

  const ordersArray = convertOrdersToArray(orders)
  const isSingleOrderMode = !isAdmin
  const isEditingRoute = !orderUuid
  const isBundledRoute = ordersArray.length > 1

  const showDeliveryCount = (
    !isSingleOrderMode ||
    (isBundledRoute && isEditingRoute)
  )

  const showAveragePerDelivery = (
    isSingleOrderMode &&
    !isEditingRoute &&
    isBundledRoute
  )

  const isEstimatingCost = current.is('Calculate Estimated Cost')

  const isCostUnknown = (
    !isEstimatingCost &&
    (!shipmentForm.cachedTotal && shipmentForm.cachedTotal !== 0) &&
    (
      estimatedShippingCost === null ||
      !ordersArray.every(({ address }) => !!address) ||
      !pickupAddress
    )
  )

  let isAverage = false

  let finalCost = isCostUnknown || estimatedShippingCost === 0
    ? 0
    : ((
      estimatedShippingCost ? estimatedShippingCost * 100 : 0
    ) || shipmentForm.cachedTotal)

  let discount = shipmentForm.shipmentRate ? shipmentForm.shipmentRate.discount : 0

  if (showAveragePerDelivery) {
    // Show the average price per delivery when in single-order mode, as the
    // total route value will be confusing
    isAverage = true
    finalCost = Math.round(finalCost / ordersArray.length)
    discount = Math.round(discount / ordersArray.length)
  }

  const preDiscountCost = finalCost + discount

  const formattedFinalCost = isAverage
    ? `Average per delivery: ${getFormattedMonetaryValueString(finalCost)}`
    : getFormattedMonetaryValueString(finalCost)

  const displayCost = discount
    ? (
      <React.Fragment>
        <s>{getFormattedMonetaryValueString(preDiscountCost)}</s> {formattedFinalCost}
      </React.Fragment>
    )
    : formattedFinalCost

  const displayedLinkedParentShipmentId = (
    (shipmentForm.linkedParentShipment && shipmentForm.linkedParentShipment.shipmentId)
  )

  const routeDuration = (
    estimatedShippingDuration ||
    shipmentForm.cachedTotalRouteDuration
  )

  const routeDistance = (
    estimatedShippingDistance ||
    shipmentForm.cachedTotalRouteDistance
  )

  return (
    <span>
      {displayedLinkedParentShipmentId ? (
        <span style={{ marginRight: 10 }}><strong>Parent:</strong> {displayedLinkedParentShipmentId}</span>
      ) : null}
      {isCostUnknown || isEstimatingCost || !isAdmin ? null : (
        <Tag><strong>{getFormattedDurationString(routeDuration)} / {getFormattedDistanceString(routeDistance)}</strong></Tag>
      )}
      <Tag color={isCostUnknown ? 'gold' : 'green'}>
        {isEstimatingCost ? (
          <Spin size='small' style={{ color: '#aaa' }} />
        ) : (
          <strong>{displayCost}</strong>
        )}
      </Tag>
      {showDeliveryCount ? (
        <Tag color='blue'>
          <strong>Deliveries: {ordersArray.length}</strong>
        </Tag>
      ) : null}
    </span>
  )
}

HeaderExtra.propTypes = {
  pickupAddress: PropTypes.string.isRequired,
  orders: PropTypes.objectOf(PropTypes.object).isRequired,
  isAdmin: PropTypes.bool.isRequired,
  deps: PropTypes.exact({
    current: PropTypes.object,
    data: PropTypes.object
  }).isRequired
}

ShipmentEditor.HeaderExtra = HeaderExtra

function ShipmentDeliveryPane ({
  currentOrder = {},
  inputDisabled,
  proximity,
  onChange,
  merchant,
  isAdmin,
  isSingleOrderMode,
  isRouteLevelInputDisabled,
  maxNumLabels,
  oversizedVars,
  availableRequirements,
  deps
}) {
  const { tc } = deps

  const [address, setAddress] = React.useState('')
  const [addressNotes, setAddressNotes] = React.useState('')
  const [deliveryInstructions, setDeliveryInstructions] = React.useState('')
  const [orderId, setOrderId] = React.useState('')
  const [description, setDescription] = React.useState('')
  const [value, setValue] = React.useState(0)
  const [numLabels, setNumLabels] = React.useState(1)
  const [name, setName] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [phone, setPhone] = React.useState('')
  const [phoneExtension, setPhoneExtension] = React.useState('')
  const [requiresPersonHandoff, setRequiresPersonHandoff] = React.useState(null)
  const [containsAlcohol, setContainsAlcohol] = React.useState(null)

  React.useEffect(() => {
    setAddress(currentOrder.address || '')
    setAddressNotes(currentOrder.addressNotes || '')
    setDeliveryInstructions(currentOrder.deliveryInstructions || '')
    setOrderId(currentOrder.orderId || '')
    setDescription(currentOrder.description || '')
    setValue(currentOrder.value || 0)
    setNumLabels(currentOrder.numLabels || 1)
    setName(currentOrder.name || '')
    setEmail(currentOrder.email || '')

    setRequiresPersonHandoff(
      currentOrder.requiresPersonHandoff === true || currentOrder.requiresPersonHandoff === false
        ? currentOrder.requiresPersonHandoff
        : Boolean(merchant.defaultRequiresPersonHandoff)
    )

    setContainsAlcohol(
      currentOrder.containsAlcohol === true || currentOrder.containsAlcohol === false
        ? currentOrder.containsAlcohol
        : Boolean(merchant.defaultContainsAlcohol)
    )

    const [phone, extension] = (currentOrder.phone || '').split(STORED_EXTENSION)
    setPhone(phone || '')
    setPhoneExtension(extension || '')
  }, [
    currentOrder.requiresPersonHandoff,
    currentOrder.containsAlcohol,
    currentOrder.address,
    currentOrder.addressNotes,
    currentOrder.deliveryInstructions,
    currentOrder.orderId,
    currentOrder.description,
    currentOrder.value,
    currentOrder.numLabels,
    currentOrder.name,
    currentOrder.email,
    currentOrder.phone,
    merchant.defaultRequiresPersonHandoff,
    merchant.defaultContainsAlcohol
  ])

  const getCurrentFormState = (overrides) => ({
    requiresPersonHandoff,
    containsAlcohol,
    address,
    addressNotes,
    deliveryInstructions,
    orderId,
    description,
    value,
    numLabels,
    name,
    email,
    phone: (
      phoneExtension
        ? `${phone}${STORED_EXTENSION}${phoneExtension}`
        : phone
    ),
    ...overrides
  })

  const isOversized = oversizedVars && Object(oversizedVars) === oversizedVars
    ? numLabels >= oversizedVars.threshold
    : false

  return (
    <Card className='delivery-information-area' style={isSingleOrderMode ? null : { borderTop: 0 }} >
      {isSingleOrderMode ? (
        <Typography.Title level={3}>Delivery information</Typography.Title>
      ) : null}
      <Row gutter={30}>
        <Col xs={24} sm={12}>
          <Form.Item className={'order-id-area'} label={<span>Order ID <Tooltip title='For your own reference, the driver may also use this'><Icon type="info-circle" style={{ marginLeft: 5 }} /></Tooltip></span>} required>
            <Input
              value={orderId}
              disabled={inputDisabled}
              onChange={(evt) => setOrderId(evt.target.value)}
              onBlur={() => onChange(getCurrentFormState())}
              maxLength={20}
            />
          </Form.Item>
          <Form.Item label='Delivery Address' required style={{ marginBottom: 0 }}>
            <AddressAutoComplete
              value={address || ''}
              placeholder='e.g. 100-123 Example St, Ottawa ON K1P 6E5, CA'
              onChange={(address) => {
                setAddress(address)
                onChange(getCurrentFormState({ address }))
              }}
              disabled={inputDisabled || isRouteLevelInputDisabled}
              deps={{ tc }}
            />
          </Form.Item>
          <Form.Item>
            <Input.TextArea
              value={addressNotes}
              autoSize={{ minRows: 1, maxRows: 5 }}
              disabled={inputDisabled}
              placeholder='Buzzer'
              onChange={(evt) => setAddressNotes(evt.target.value)}
              onBlur={() => onChange(getCurrentFormState())}
            />
          </Form.Item>

          <Form.Item label='Delivery Instructions'>
            <Input.TextArea
              value={deliveryInstructions}
              disabled={inputDisabled}
              placeholder='e.g. "Do not ring door bell, use side door"'
              onChange={(evt) => setDeliveryInstructions(evt.target.value)}
              onBlur={() => onChange(getCurrentFormState())}
            />
          </Form.Item>

          <Form.Item label='Description' required>
            <Input.TextArea
              value={description}
              disabled={inputDisabled}
              placeholder='Briefly describe the products in this order'
              onChange={(evt) => setDescription(evt.target.value)}
              onBlur={() => onChange(getCurrentFormState())}
            />
          </Form.Item>

          <Form.Item className={'value-of-goods-area'} label={<span>Value of Goods <Tooltip title='We insure up to $100'><Icon type="info-circle" style={{ marginLeft: 5 }} /></Tooltip></span>} required>
            <InputNumber
              min={0}
              value={((value || 0) / 100)}
              precision={2}
              disabled={inputDisabled}
              onChange={(val) => {
                setValue(Math.round(Math.max(parseFloat(String(val).replace(/,/gu, '') || '0', 10) * 100, 0)))
              }}
              onBlur={() => onChange(getCurrentFormState())}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <div className={'recipient-information-area'}>
            <Form.Item label='Recipient Name' required>
              <Input
                value={name}
                disabled={inputDisabled}
                onChange={(evt) => setName(evt.target.value)}
                onBlur={() => onChange(getCurrentFormState())}
              />
            </Form.Item>
            <Form.Item label={<span>Recipient Email <Tooltip title='Separate multiple email addresses with a comma (,)'><Icon type="info-circle" style={{ marginLeft: 5 }} /></Tooltip></span>} required>
              <Input
                value={email}
                disabled={inputDisabled}
                placeholder=''
                onChange={(evt) => setEmail(evt.target.value)}
                onBlur={() => onChange(getCurrentFormState())}
              />
            </Form.Item>
            <Form.Item label='Recipient Phone' required>
              <Input.Group>
                <Row gutter={5}>
                  <Col span={18}>
                    <Input
                      placeholder='+1 xxx xxx xxxx'
                      value={phone}
                      disabled={inputDisabled}
                      onChange={(evt) => setPhone(formatPhoneNumber(evt.target.value))}
                      onBlur={() => onChange(getCurrentFormState())}
                    />
                  </Col>
                  <Col span={6}>
                    <Input
                      placeholder='Ext.'
                      value={phoneExtension}
                      disabled={inputDisabled}
                      onChange={(evt) => setPhoneExtension(evt.target.value)}
                      onBlur={() => onChange(getCurrentFormState())}
                    />
                  </Col>
                </Row>
              </Input.Group>
            </Form.Item>
          </div>
          <Form.Item
            className='num-labels-area'
            label={(
              <span>Number of Labels (one per package) <Tooltip title='All labels must be affixed to individual packages. Do not choose an amount that is more or less than the amount of packages you plan on having delivered. MISSING OR EXTRA LABELS WILL CAUSE DELAYS.'><Icon type="info-circle" style={{ marginLeft: 5 }} /></Tooltip></span>
            )}
          >
            <InputNumber
              value={numLabels}
              min={1}
              {...(maxNumLabels > 0 && { max: maxNumLabels })}
              disabled={inputDisabled}
              onChange={(numLabels) => {
                numLabels = parseInt(numLabels, 10) || 1
                setNumLabels(numLabels)
                onChange(getCurrentFormState({ numLabels }))
              }}
              onBlur={() => onChange(getCurrentFormState())}
            />
            {isOversized ? (
              <Alert
                message='Large Order'
                description={`Labels/packages beyond the first ${oversizedVars.threshold - 1} carry a surcharge fee of ${getFormattedMonetaryValueString(oversizedVars.amountPerLabel)} each.`}
                type='warning'
                showIcon
                style={{ marginTop: 10 }}
              />
            ) : null}
          </Form.Item>
          <Form.Item label='Proof of Delivery' required className='proof-of-delivery-area'>
            <Radio.Group
              onChange={(evt) => {
                const value = evt.target.value
                setRequiresPersonHandoff(value)
                onChange(getCurrentFormState({ requiresPersonHandoff: value }))
              }}
              value={Boolean(requiresPersonHandoff)}
              disabled={inputDisabled}
            >
              <Radio value={false} style={{ display: 'block', height: 30, lineHeight: '36px' }}>
                Photo
                <Tooltip title="The driver will leave the package(s) on the customer’s doorstep and will take a photo that includes both the package(s) and the house/unit number (when possible)."><Icon type='info-circle' style={{ marginLeft: 8 }} /></Tooltip>
              </Radio>
              <Radio value={true} style={{ display: 'block', height: 30, lineHeight: '36px' }}>
                Delivery PIN Verification
                <Tooltip title="A PIN will be sent to the customer. They will need to provide it to the driver to complete the delivery. An extra charge will be applied to pay for the return of the delivery if there is no one to receive it."><Icon type='info-circle' style={{ marginLeft: 8 }} /></Tooltip>
              </Radio>
            </Radio.Group>
          </Form.Item>
          {'legalAgeAlcohol' in availableRequirements ? (
            <Form.Item label='Alcohol' className='alcohol-area'>
              <Checkbox
                checked={!!containsAlcohol}
                disabled={inputDisabled}
                onChange={() => {
                  setContainsAlcohol(!containsAlcohol)
                  onChange(getCurrentFormState({ containsAlcohol: !containsAlcohol }))
                }}
              >Contains Alcoholic Products</Checkbox>
              <Tooltip title="Recipient will be required to present valid ID and be of legal drinking age to receive this delivery. “Delivery PIN Verification” will be enabled automatically."><Icon type="info-circle" /></Tooltip>
            </Form.Item>
            ) : null}
        </Col>
      </Row>
    </Card>
  )
}

ShipmentDeliveryPane.propTypes = {
  currentOrder: PropTypes.shape({
    address: PropTypes.string.isRequired,
    addressNotes: PropTypes.string.isRequired,
    deliveryInstructions: PropTypes.string.isRequired,
    orderId: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    numLabels: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    requiresPersonHandoff: PropTypes.bool.isRequired,
    containsAlcohol: PropTypes.bool.isRequired
  }),
  inputDisabled: PropTypes.bool.isRequired,
  proximity: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  isGeneratingLabel: PropTypes.bool.isRequired,
  onGenerateLabel: PropTypes.func,
  merchant: PropTypes.object.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isSingleOrderMode: PropTypes.bool.isRequired,
  isRouteLevelInputDisabled: PropTypes.bool.isRequired,
  maxNumLabels: PropTypes.number.isRequired,
  oversizedVars: PropTypes.exact({
    threshold: PropTypes.number.isRequired,
    amountPerLabel: PropTypes.number.isRequired
  }),
  availableRequirements: PropTypes.object.isRequired,
  deps: PropTypes.exact({
    tc: PropTypes.object.isRequired
  }).isRequired
}

function TabsWrapper (props) {
  const { isSingleOrderMode, ...rest } = props

  if (isSingleOrderMode) {
    return props.children
  } else {
    return <Tabs {...rest} />
  }
}

TabsWrapper.propTypes = {
  ...Tabs.propTypes,
  isSingleOrderMode: PropTypes.bool.isRequired
}
