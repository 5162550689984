import * as React from 'react'
import * as PropTypes from 'prop-types'
import { TimePicker, Descriptions, Modal, Icon, Button, Table } from 'antd'
import moment from 'moment'
import { isEqual } from '@trexity/common/util'
import { dayIndexToWord, isTimeIntervalOverlapping } from '@trexity/common/temporal'
import { isSmartDevice } from '../util'

MerchantOperatingHoursPicker.propTypes = {
  onChange: PropTypes.func,
  initialData: PropTypes.object,
  maxRanges: PropTypes.number,
  onStateValid: PropTypes.func,
  onStateInvalid: PropTypes.func,
  style: PropTypes.object
}

const getWidth = () => (
  window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth
)

const daysWeekSort = (a, b) => {
  // Sunday's "day" is zero; move it at last position.
  if (a.day === 0) {
    return 1
  }

  if (b.day === 0) {
    return -1
  }

  return (a.day > b.day)
}

const findFirstTimeSlot = (ranges, maxRanges) => {
  let maxHour = 0
  let maxMinute = 0

  if (ranges.length === maxRanges) {
    return null
  }

  for (let i = 0; i < ranges.length; i++) {
    const s = ranges[i].toTime.split(':')

    if (Number(s[0]) >= maxHour) {
      maxHour = Number(s[0])

      if (Number(s[1]) > maxMinute) {
        maxMinute = Number(s[1])
      }
    }
  }

  if (maxHour < 23) {
    if (maxMinute < 59) {
      return {
        fromTime: `${String(maxHour).padStart(2, '0')}:${String(maxMinute).padStart(2, '0')}`,
        toTime: `${String(maxHour).padStart(2, '0')}:${String(maxMinute + 1).padStart(2, '0')}`
      }
    } else {
      return {
        fromTime: `${String(maxHour + 1).padStart(2, '0')}:00`,
        toTime: `${String(maxHour + 1).padStart(2, '0')}:05`
      }
    }
  }

  return null
}

const rangeSort = (b, a) => {
  const [aFromHour, aFromMinute] = a.fromTime.split(':').map((n) => Number(n))
  const [bFromHour, bFromMinute] = b.fromTime.split(':').map((n) => Number(n))

  if (aFromHour < bFromHour) {
    return 1
  }

  if (aFromHour > bFromHour) {
    return -1
  }

  if (aFromHour === bFromHour) {
    if (aFromMinute < bFromMinute) {
      return 1
    }

    if (aFromMinute > bFromMinute) {
      return -1
    }

    return 0
  }

  return 0
}

const isOverlapping = (overlappingIntervalsInner, dayIndex, rangeIndex) => {
  return overlappingIntervalsInner[dayIndex]
    ? overlappingIntervalsInner[dayIndex].includes(rangeIndex)
    : false
}

const findOverlappingIntervalsOfRange = (range, ranges, excludeIndex) => {
  const { fromTime: aFromTime, toTime: aToTime } = range
  const result = []

  for (let i = 0; i < ranges.length && i !== excludeIndex; i++) {
    const { fromTime: bFromTime, toTime: bToTime } = ranges[i]

    if (isTimeIntervalOverlapping(
      { fromTime: aFromTime, toTime: aToTime },
      { fromTime: bFromTime, toTime: bToTime })
    ) {
      result.push(i)
    }
  }

  return result
}

const findOverlappingIntervals = (days) => {
  const overlappingIntervalsInner = {}

  for (let i = 0; i < days.length; i++) {
    const ranges = days[i].ranges

    for (let r = 0; r < ranges.length; r++) {
      if (isOverlapping(overlappingIntervalsInner, i, r)) {
        continue
      }

      const overlapped = findOverlappingIntervalsOfRange(ranges[r], ranges, r)

      if (overlapped.length) {
        for (let j = 0; j < overlapped.length; j++) {
          if (!overlappingIntervalsInner[i]) {
            overlappingIntervalsInner[i] = []
          }

          overlappingIntervalsInner[i].push(overlapped[j])
        }

        overlappingIntervalsInner[i].push(r)
      }
    }
  }

  return overlappingIntervalsInner
}

function RangeTag ({
  onClick = () => {},
  fromTime = '',
  toTime = '',
  state = 'valid'
}) {
  const theme = state === 'invalid' || state === 'closed'
    ? 'red'
    : 'green'

  return (
    <div
      onClick={onClick}
      style={{
        color: theme === 'red' ? '#f5222d' : '#52c41a',
        backgroundColor: theme === 'red' ? '#fff1f0' : '#f6ffed',
        borderColor: theme === 'red' ? '#ffa39e' : '#b7eb8f',
        borderWidth: 1,
        borderStyle: 'solid',
        display: 'inline-block',
        marginRight: 8,
        marginBottom: 2,
        padding: '0 7px',
        borderRadius: 4,
        whiteSpace: 'nowrap',
        fontSize: 12,
        lineHeight: '20px',
        cursor: 'pointer'
      }}
    >
      {state === 'closed' ? (
        <span>Closed</span>
      ) : (
        <React.Fragment>
          {fromTime} – {toTime}
          <div style={{ display: 'inline-block', marginLeft: 10 }}>
            <Icon type='edit' style={{ fontSize: 12, color: theme === 'red' ? '#f5222d' : '#52c41a' }} />
          </div>
        </React.Fragment>
      )}
    </div>
  )
}

RangeTag.propTypes = {
  onClick: PropTypes.func,
  fromTime: PropTypes.string,
  toTime: PropTypes.string,
  state: PropTypes.oneOf(['valid', 'invalid', 'closed'])
}

export default function MerchantOperatingHoursPicker ({
  maxRanges,
  onChange,
  onStateValid = () => {},
  onStateInvalid = () => {},
  initialData = { days: [] },
  style = {}
}) {
  const { days = [] } = initialData || {}

  const sortedResult = days.sort(daysWeekSort)

  const [daysInState, setDaysInState] = React.useState(sortedResult)
  const [modalIsVisible, setModalIsVisible] = React.useState(false)

  const [modalData, setModalData] = React.useState({
    timeString: '08:00',
    dayIndex: 0,
    day: 0
  })

  const [width, setWidth] = React.useState(getWidth())

  const copy = [...daysInState]

  React.useEffect(() => {
    const { days = [] } = initialData || {}
    const sortedResult = days.slice().sort(daysWeekSort)
    setDaysInState(sortedResult)
  }, [initialData])

  const onRemove = (day, dayIndex, rangeIndex) => {
    copy[dayIndex].ranges.splice(rangeIndex, 1)
    setDaysInState(copy)
    onChange({ days: copy })
  }

  const onAddNew = (day, dayIndex) => {
    const firstAvailableTimeSlot = findFirstTimeSlot(copy[dayIndex].ranges, maxRanges)

    if (!firstAvailableTimeSlot) {
      // eslint-disable-next-line no-alert
      alert('No more ranges allowed.')
    } else {
      copy[dayIndex].ranges.push({
        fromTime: firstAvailableTimeSlot.fromTime,
        toTime: firstAvailableTimeSlot.toTime
      })
      setDaysInState(copy)
      onChange({ days: copy })
    }
  }

  const onTryEdit = (day, dayIndex, rangeIndex) => {
    setModalData({
      day,
      dayIndex,
      rangeIndex,
      fromTimeString: daysInState[dayIndex].ranges[rangeIndex].fromTime,
      toTimeString: daysInState[dayIndex].ranges[rangeIndex].toTime
    })
    setModalIsVisible(true)
  }

  const getSortedRangesInDay = (dayIndex) => {
    return [
      ...copy
    ].map((day, index) => {
      return {
        ...day,
        ranges: index === dayIndex ? day.ranges.slice().sort(rangeSort) : day.ranges
      }
    })
  }

  const sortRangesInDay = (dayIndex) => {
    const beforeSorted = getSortedRangesInDay(dayIndex)
    onChange({ days: beforeSorted })
    setDaysInState(beforeSorted)
  }

  React.useEffect(() => {
    daysInState.forEach(({ days }, dayIndex) => {
      const sorted = getSortedRangesInDay(dayIndex)

      if (!isEqual(sorted[dayIndex].ranges, copy[dayIndex].ranges)) {
        onChange({ days: sorted })
        setDaysInState(sorted)
      }
    })
  }, [daysInState, sortRangesInDay])

  const overlappingIntervals = findOverlappingIntervals(copy)
  const isStateValid = !Object.keys(overlappingIntervals).length

  React.useEffect(() => {
    const overlappingIntervals = findOverlappingIntervals(daysInState)
    const isStateValid = !Object.keys(overlappingIntervals).length

    if (isStateValid) {
      onStateValid(true)
    } else {
      onStateInvalid(true)
    }
  }, [daysInState])

  const columns = [
    {
      title: 'Day of week',
      dataIndex: 'day',
      key: 'day',
      render: (text, record, index) => {
        return dayIndexToWord(Number(record.day))
      },
      width: '15%'
    },
    {
      title: 'Working hours windows',
      dataIndex: 'ranges',
      key: 'ranges',
      render: (text, record, index) => {
        return (
          <React.Fragment>
            {record.ranges.length === 0 ? (
              <RangeTag state='closed' />
            ) : (
              record.ranges.map(({ fromTime = '', toTime = '' }, rangeIndex) => {
                const overlaps = isOverlapping(overlappingIntervals, record.dayIndex, rangeIndex)

                return (
                  <RangeTag
                    key={rangeIndex}
                    fromTime={fromTime}
                    toTime={toTime}
                    onClick={() => {
                      onTryEdit(record.day, record.dayIndex, rangeIndex)
                    }}
                    state={overlaps ? 'invalid' : 'valid'}
                  />
                )
              })
            )}
            <Button
              size='small'
              disabled={record.ranges.length >= maxRanges}
              style={{
                fontSize: 12
              }}
              onClick={() => {
                onAddNew(record.day, record.dayIndex)
              }}
            >
              <Icon type='plus' />
            </Button>
          </React.Fragment>
        )
      }
    }
  ]

  React.useEffect(() => {
    const onResize = () => setWidth(getWidth())
    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [])

  const dataSource = daysInState.map(({ day, ranges }, dayIndex) => ({ key: dayIndex, day, dayIndex, ranges }))

  const horizontalTable = (
    <Table
      size='small'
      dataSource={dataSource}
      columns={columns}
      pagination={false}
      style={style}
    />
  )

  const verticalTable = (
    <React.Fragment>
      {daysInState.map(({ day, ranges }, dayIndex) => {
        return (
          <Descriptions
            key={dayIndex}
            bordered
            size='small'
            column={1}
            layout='vertical'
            style={style}
          >
            <Descriptions.Item label={dayIndexToWord(day)}>
              {ranges.length === 0 ? (
                <RangeTag state='closed' />
               ) : null}
              {ranges.length && ranges.map(({ fromTime = '', toTime = '' }, rangeIndex) => (
                <RangeTag
                  key={rangeIndex}
                  onClick={() => {
                    onTryEdit(day, dayIndex, rangeIndex)
                  }}
                  fromTime={fromTime}
                  toTime={toTime}
                  state={isOverlapping(overlappingIntervals, dayIndex, rangeIndex) ? 'invalid' : 'valid'}
                />
              ))}

              <Button
                size='small'
                disabled={ranges.length >= maxRanges}
                style={{
                  fontSize: 12
                }}
                onClick={() => {
                  onAddNew(day, dayIndex)
                }}
              >
                <Icon type='plus' />
              </Button>
            </Descriptions.Item>
          </Descriptions>
        )
      })}
    </React.Fragment>
  )

  return (
    <React.Fragment>
      <Modal
        title='Set Time Range'
        visible={modalIsVisible}
        onCancel={() => { setModalIsVisible(false) }}
        onOk={() => {
          copy[modalData.dayIndex].ranges[modalData.rangeIndex] = {
            fromTime: modalData.fromTimeString,
            toTime: modalData.toTimeString
          }
          setDaysInState(copy)
          onChange({ days: copy })
          setModalIsVisible(false)
        }}
      >
        {isSmartDevice(navigator.userAgent, navigator.platform) ? (
          <React.Fragment>
            <div style={{ display: 'inline-block' }}>
              <input
                type='time'
                onChange={(event) => {
                  // RF: On Safari, this fires all the time (even while scrolling the values),
                  // though it shouldn't.
                  // It should only fire once committed, but that's not the case.
                  // onInput isn't better. There is not much we can do, since we don't
                  // know *when* is the picker closed.
                  const fromTimeTmp = event.target.value

                  const fromTimeMoment = moment(fromTimeTmp, 'HH:mm')
                  const toTimeMoment = moment(modalData.toTimeString, 'HH:mm')

                  if (toTimeMoment.isBefore(fromTimeMoment)) {
                    const newToTimeString = (Number(fromTimeMoment.hours()) === 23 && Number(fromTimeMoment.minutes()) === 59)
                      ? '23:59'
                      : fromTimeMoment.add(1, 'minutes').format('HH:mm')

                    setModalData({
                      ...modalData,
                      toTimeString: newToTimeString,
                      fromTimeString: fromTimeTmp
                    })
                  } else {
                    setModalData({
                      ...modalData,
                      fromTimeString: fromTimeTmp
                    })
                  }
                }}
                value={modalData.fromTimeString}
              />
            </div>
            <div style={{ display: 'inline-block', marginLeft: '5px', marginRight: '5px' }}>to</div>
            <div style={{ display: 'inline-block', marginRight: '5px' }}>
              <input
                type='time'
                onChange={(event) => {
                  const toTimeTmp = event.target.value

                  const fromTimeMoment = moment(modalData.fromTimeString, 'HH:mm')
                  const toTimeMoment = moment(toTimeTmp, 'HH:mm')

                  if (toTimeMoment.isBefore(fromTimeMoment)) {
                    setModalData({
                      ...modalData,
                      toTimeString: toTimeTmp,
                      fromTimeString:
                        (Number(toTimeMoment.hours()) === 0 && Number(toTimeMoment.minutes()) === 0)
                          ? '00:00'
                          : toTimeMoment.subtract(1, 'minutes').format('HH:mm')
                    })
                  } else {
                    setModalData({
                      ...modalData,
                      toTimeString: toTimeTmp
                    })
                  }
                }}
                value={modalData.toTimeString}
              />
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div style={{ display: 'inline-block' }}>
              <TimePicker
                format='HH:mm'
                value={moment(modalData.fromTimeString, 'HH:mm')}
                onOpenChange={(open) => {
                  if (!open) {
                    const fromTimeMoment = moment(modalData.fromTimeString, 'HH:mm')
                    const currentToTimeMoment = moment(modalData.toTimeString, 'HH:mm')

                    if (currentToTimeMoment.isBefore(fromTimeMoment)) {
                      setModalData({
                        ...modalData,
                        toTimeString:
                          (Number(fromTimeMoment.hours()) === 23 && Number(fromTimeMoment.minutes()) === 59)
                            ? '23:59'
                            : fromTimeMoment.add(1, 'minutes').format('HH:mm')
                      })
                    }
                  }
                }}
                onChange={(time, timeString) => {
                  if (timeString) {
                    setModalData({
                      ...modalData,
                      fromTimeString: timeString
                    })
                  }
                }}
              />
            </div>

            <div style={{ display: 'inline-block', marginLeft: '5px', marginRight: '5px' }}>to</div>

            <div style={{ display: 'inline-block', marginRight: '5px' }}>
              <TimePicker
                format='HH:mm'
                value={moment(modalData.toTimeString, 'HH:mm')}
                onChange={(time, timeString) => {
                  if (timeString) {
                    setModalData({
                      ...modalData,
                      toTimeString: timeString
                    })
                  }
                }}
                onOpenChange={(open) => {
                  if (!open) {
                    const currentFromTimeMoment = moment(modalData.fromTimeString, 'HH:mm')
                    const toTimeMoment = moment(modalData.toTimeString, 'HH:mm')

                    if (toTimeMoment.isBefore(currentFromTimeMoment)) {
                      setModalData({
                        ...modalData,
                        fromTimeString:
                          (Number(toTimeMoment.hours()) === 0 && Number(toTimeMoment.minutes()) === 0)
                            ? '00:00'
                            : toTimeMoment.subtract(1, 'minutes').format('HH:mm')
                      })
                    }
                  }
                }}
              />
            </div>
          </React.Fragment>
        )}

        <div
          style={{
            display: 'inline-block',
            padding: '3px',
            float: 'right',
            cursor: 'pointer'
          }}
          onClick={() => {
            // eslint-disable-next-line no-alert
            if (confirm('Are you sure you want to remove this range?')) {
              onRemove(modalData.day, modalData.dayIndex, modalData.rangeIndex)
              setModalIsVisible(false)
            }
          }}
        >
          <Icon type='delete' style={{ fontSize: '22px', color: '#FF0000' }} />
        </div>
      </Modal>

      {!isStateValid
        ? <p style={{ color: 'red' }}>Please make sure there are no overlapping time periods in your delivery hours.</p>
        : null
      }

      {width > 600 ? horizontalTable : verticalTable}
    </React.Fragment>
  )
}
