module.exports = function mergeChange (docs, doc, changeType, addToStartOfArray = false) {
  let merged = null
  let index = null

  switch (changeType) {
    case 'added':
    case 'modified':
      index = docs.findIndex((s) => s.id === doc.id)

      if (index >= 0) {
        merged = docs.map((d, idx) => idx === index ? { ...d, ...doc } : d)
      } else {
        if (addToStartOfArray) {
          merged = [doc, ...docs]
        } else {
          merged = [...docs, doc]
        }
      }

      break
    case 'removed':
      merged = docs.filter((d) => d.id !== doc.id)
      break
  }

  return merged
}
