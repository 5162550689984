/*
A functional namespace to deal with service hour objects (formerly known as operating hours).
*/

const { isServiceHours } = require('./isServiceHours')
const { getAllWeekdayHoursInWorkweekOrder } = require('./getAllWeekdayHoursInWorkweekOrder')
const { setWeekdayHours } = require('./setWeekdayHours')
const { setWeekdayHoursClosed } = require('./setWeekdayHoursClosed')
const { getWeekdayHours } = require('./getWeekdayHours')
const { isWeekdayClosed } = require('./isWeekdayClosed')
const { setCalendarDayHours } = require('./setCalendarDayHours')
const { setCalendarDayHoursClosed } = require('./setCalendarDayHoursClosed')
const { clearCalendarDayHours } = require('./clearCalendarDayHours')
const { create } = require('./create')
const { getCalendarDayHours } = require('./getCalendarDayHours')
const { isCalendarDayClosed } = require('./isCalendarDayClosed')
const { containsDate } = require('./containsDate')
const { conformTo } = require('./conformTo')

require('./types')

exports.create = create
exports.isServiceHours = isServiceHours
exports.containsDate = containsDate
exports.conformTo = conformTo
exports.setWeekdayHours = setWeekdayHours
exports.setWeekdayHoursClosed = setWeekdayHoursClosed
exports.clearCalendarDayHours = clearCalendarDayHours
exports.getWeekdayHours = getWeekdayHours
exports.isWeekdayClosed = isWeekdayClosed
exports.setCalendarDayHours = setCalendarDayHours
exports.setCalendarDayHoursClosed = setCalendarDayHoursClosed
exports.getCalendarDayHours = getCalendarDayHours
exports.isCalendarDayClosed = isCalendarDayClosed
exports.getAllWeekdayHoursInWorkweekOrder = getAllWeekdayHoursInWorkweekOrder
