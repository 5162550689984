function valueToBool (value, {
  yes = true,
  no = false,
  otherwise = false
} = {}) {
  if (isYes(value)) {
    return yes
  } else if (isNo(value)) {
    return no
  } else {
    return otherwise
  }
}

function isYes (value) {
  if (typeof value === 'string') {
    value = value.toLowerCase()
  }

  return (
    value === 'yes' ||
    value === 'y' ||
    value === 'true' ||
    value === 'on' ||
    value === '1' ||
    value === 1 ||
    value === true ||
    value === 'x'
  )
}

function isNo (value) {
  if (typeof value === 'string') {
    value = value.toLowerCase()
  }

  return (
    value === 'no' ||
    value === 'n' ||
    value === 'false' ||
    value === 'off' ||
    value === '0' ||
    value === 0 ||
    value === false ||
    value === '-' ||
    value === '' ||
    value === null ||
    value === undefined
  )
}

module.exports = valueToBool
