function stripUnitPrefix (address) {
  const addressParts = String(address).split(' ')

  if (addressParts.length > 1) {
    const prefix = addressParts.shift()
    const unitParts = prefix.split('-')

    if (unitParts.length > 1) {
      unitParts.shift()
      return `${unitParts.join('-')} ${addressParts.join(' ')}`
    }
  }

  return address
}

function fixInvalidDashAfterSpace (address) {
  return String(address).replace(/(.*[^-\s]+)[\s]*-[\s]*([^-\s]+.*)/gu, '$1-$2')
}

function replaceUnitDashWithSpace (address) {
  const addressParts = fixInvalidDashAfterSpace(address).split(' ')

  if (addressParts.length > 1) {
    const prefix = addressParts.shift()
    const unitParts = prefix.split('-')

    if (unitParts.length > 1) {
      return `${unitParts.join(' ')} ${addressParts.join(' ')}`
    }
  }

  return address
}

function stripCivicAddressFractionSuffix (address) {
  return address.replace(/\s+\d+\/\d+\s+/u, ' ')
}

function extractSubBuildingAndBuildingNumber (address) {
  address = address.trim()

  if (address.toUpperCase().slice(0, 3) === 'RR ') {
    address = address.slice(3)
  }

  const matches = address.match(/^([\dA-Za-z]+\s?-\s?)?([\dA-Za-z]+)(.*)/u)

  const parts = Array.isArray(matches)
    ? [String(matches[1] || '').trim(), String(matches[2] || '').trim(), String(matches[3] || '').trim()]
    : ['', '', address]

  if (parts[0].slice(-1) === '-') {
    parts[0] = parts[0].slice(0, -1).trim()
  }

  return parts
}

exports.stripUnitPrefix = stripUnitPrefix
exports.fixInvalidDashAfterSpace = fixInvalidDashAfterSpace
exports.replaceUnitDashWithSpace = replaceUnitDashWithSpace
exports.stripCivicAddressFractionSuffix = stripCivicAddressFractionSuffix
exports.extractSubBuildingAndBuildingNumber = extractSubBuildingAndBuildingNumber
