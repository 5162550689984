const GeoJSON = require('../vendor/geojson')
const { isGeoJSONFast } = require('./GeoJSON')

function generateCoordinatesFromData (data, depth = 1) {
  let coordinates = []

  if (Array.isArray(data)) {
    data.forEach((datum) => {
      coordinates = [generateCoordinatesFromData(datum, depth + 1).coordinates]
    })
  } else if (data && ((data.longitude && data.latitude) || (data._longitude && data._latitude))) {
    coordinates.push([data.longitude || data._longitude, data.latitude || data._latitude])
  } else {
    throw new Error('value in "data" param is not an array, GeoJSON geometry object, or an object of { longitude, latitude }')
  }

  return { coordinates, depth }
}

module.exports = function convertDataToGeoJSON (data, geometryType) {
  geometryType = geometryType.toUpperCase()

  if (data && typeof data === 'object') {
    let geoJson = null
    let confirmedGeometryType = null

    if (isGeoJSONFast(data)) {
      geoJson = data
    } else {
      switch (geometryType) {
        case 'POINT':
          confirmedGeometryType = 'Point'
          break
        case 'MULTIPOLYGON':
          confirmedGeometryType = 'MultiPolygon'
          break
        case 'MULTIPOINT':
          confirmedGeometryType = 'MultiPoint'
          break
        case 'POLYGON':
          confirmedGeometryType = 'Polygon'
          break
        case 'LINESTRING':
          confirmedGeometryType = 'LineString'
          break
        case 'MULTLINESTRING':
          confirmedGeometryType = 'MultiLineString'
          break
      }

      const arraySample = Array.isArray(data) && data.length ? data[0] : null

      if (confirmedGeometryType && arraySample && (/^\d*\.?\d+$/u.test(arraySample) || typeof arraySample === 'string')) {
        geoJson = GeoJSON.parse({ coordinates: data }, { [confirmedGeometryType]: 'coordinates' })
      } else {
        let coordinateResult = null

        switch (confirmedGeometryType && confirmedGeometryType.toUpperCase()) {
          case 'POINT':
            if (data._longitude && data._latitude) {
              geoJson = { type: 'Point', coordinates: [data._longitude, data._latitude] }
            } else if (data.longitude && data.latitude) {
              geoJson = { type: 'Point', coordinates: [data.longitude, data.latitude] }
            }

            break
          case 'MULTIPOLYGON':
          case 'MULTLINESTRING':
            coordinateResult = generateCoordinatesFromData(data)

            if (coordinateResult.depth !== 3) {
              throw new Error(`array depth ${coordinateResult.depth} for ${confirmedGeometryType} should be 3`)
            }

            geoJson = GeoJSON.parse({ coordinates: coordinateResult.coordinates }, { [confirmedGeometryType]: 'coordinates' })
            break
          case 'MULTIPOINT':
          case 'POLYGON':
          case 'LINESTRING':
            coordinateResult = generateCoordinatesFromData(data)

            if (coordinateResult.depth !== 1) {
              throw new Error(`array depth ${coordinateResult.depth} for ${confirmedGeometryType} should be 1`)
            }

            geoJson = GeoJSON.parse({ coordinates: coordinateResult.coordinates }, { [confirmedGeometryType]: 'coordinates' })
            break
        }
      }
    }

    return geoJson
  }
}
