export function calculateEstimatedMinutes (seconds, lastComputedAt) {
  return Math.max(0, Math.round(
    (
      seconds -
      (
        lastComputedAt && lastComputedAt.toDate
          ? ((new Date().getTime() - lastComputedAt.toDate().getTime()) / 1000)
          : 0
      )
    ) / 60
  ))
}
