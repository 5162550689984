import React from 'react'
import PropTypes from 'prop-types'
import { getFormattedDateAndTimeFromDate } from '@trexity/common/temporal'
import { haversineDistance } from '@trexity/common/geo'
import { NO_SHIPMENT_STATUS, LINKED_SHIPMENT_TYPES } from '@trexity/common/shipments/constants'
import { convertOrdersToArray } from '@trexity/common/models/ShipmentOrder'
import { sortOrdersByShipmentAndDriver } from '@trexity/common/shipments'
import { Tag, Steps, Icon, Button, Card, Spin, Modal } from 'antd'
import HoverPhoto from './HoverPhoto'
import DriverDetails from './DriverDetails'
import { calculateEstimatedMinutes } from '../util'

const { RETURN_TO_SENDER, RETURN_REUSABLES } = LINKED_SHIPMENT_TYPES

const confirmBroadcastShipment = (shipment, send) => {
  const { id } = shipment

  Modal.confirm({
    title: `Are you sure you wish to broadcast ${id}?`,
    content: `This will put ${id} back into the pool of routes up for grabs by the drivers.`,
    okText: 'Yes',
    cancelText: 'No',
    onOk () {
      send({ type: 're-broadcasting shipment', id })
    }
  })
}

export default function ShipmentStatusTimeline ({
  shipment,
  driver = null,
  statuses = null,
  isLoading = false,
  isAdmin = false,
  deps
}) {
  const {
    send,
    current,
    RouteLink
  } = deps

  const {
    id: shipmentId,
    driverId,
    pickupAddress,
    pickupPhotoUrl,
    currentStatus,
    postedAt,
    currentDriverEstimatedSecondsToPickup,
    currentDriverEstimatedSecondsToPickupComputedAt,
    linkedParentShipment,
    cachedLinkedChildShipments
  } = shipment

  const ordersArray = convertOrdersToArray(sortOrdersByShipmentAndDriver({ shipment, driver }))

  if (statuses === null) {
    statuses = shipment.statuses || []
  }

  const showEstPickupArrival = currentDriverEstimatedSecondsToPickup === 0 || currentDriverEstimatedSecondsToPickup > 0
  const estPickupArrivalMins = calculateEstimatedMinutes(currentDriverEstimatedSecondsToPickup, currentDriverEstimatedSecondsToPickupComputedAt)

  const estPickupArrivalText = currentDriverEstimatedSecondsToPickupComputedAt
    ? `${estPickupArrivalMins} min${estPickupArrivalMins === 1 ? '' : 's'}`
    : 'Calculating…'

  const returnToSenderChild = (
    cachedLinkedChildShipments &&
    cachedLinkedChildShipments.find((child) => [RETURN_TO_SENDER].includes(child.type))
  )

  const returnReusablesChild = (
    cachedLinkedChildShipments &&
    cachedLinkedChildShipments.find((child) => [RETURN_REUSABLES].includes(child.type))
  )

  const steps = statuses.map((shipmentStatus, index) => {
    const next = statuses[index + 1]

    const nextStatusIsExpiredCancelledOnHoldOrRenounced = next && (
      next.status === 'EXPIRED' || // backwards-compat
      next.status === 'CANCELLED' ||
      next.status === 'ON_HOLD' ||
      next.status === 'RENOUNCED_BY_DRIVER'
    )

    const createdAt = getFormattedDateAndTimeFromDate(shipmentStatus.createdAt.toDate())
    const completedAt = next && !nextStatusIsExpiredCancelledOnHoldOrRenounced ? getFormattedDateAndTimeFromDate(next.createdAt.toDate()) : null
    const isCurrentStatus = shipmentStatus.status === currentStatus && !next

    const notesCard = shipmentStatus.notes && isAdmin
      ? (
        <Card size='small' type='inner' style={{ marginTop: 20 }} title={'Notes from the driver:'}>
          <div dangerouslySetInnerHTML={{ __html: shipmentStatus.notes }} />
        </Card>
      )
      : null

    const isReturnShipment = linkedParentShipment && (
      linkedParentShipment.type === LINKED_SHIPMENT_TYPES.RETURN_TO_SENDER
    )

    shipmentStatus.__order = (
      shipmentStatus.orderUuid
        ? ordersArray.find(({ id }) => id === shipmentStatus.orderUuid)
        : ordersArray[shipmentStatus.deliveryIndex]
    ) || {}

    const driver = shipmentStatus.cachedDriver || {}

    const driverName = isAdmin
      ? driver.fullName || 'driver'
      : String(driver.fullName || 'driver').split(' ', 2)[0]

    const nextDriver = (next && next.cachedDriver) || {}

    const nextDriverName = isAdmin
      ? nextDriver.fullName || 'driver'
      : String(nextDriver.fullName || 'driver').split(' ', 2)[0]

    let iconType = null

    if (nextStatusIsExpiredCancelledOnHoldOrRenounced) {
      // We know the next status will be expired, therefore let's consider this
      // step before as not having been "checked off" and instead show an
      // exclamation circle
      iconType = 'exclamation'
    } else if (shipmentStatus.status === 'RENOUNCED_BY_DRIVER' || shipmentStatus.status === 'ON_HOLD' || (shipmentStatus.status === 'DELIVERED' && shipmentStatus.__order.undeliverable)) {
      // The RENOUNCED_BY_DRIVER and ON_HOLD statuses have a warning icon, and
      // so does a DELIVERED status for an order that is "undeliverable"
      iconType = 'warning'
    } else if (shipmentStatus.status === 'EXPIRED' || shipmentStatus.status === 'CANCELLED') {
      // EXPIRED for backwards-compat
      // This status is "EXPIRED" or "CANCELLED" so it always has an X error-like state
      iconType = 'close'
    } else if (shipmentStatus.status === 'DELIVERED') {
      // This status is "DELIVERED" so it should use the default checked icon
      iconType = 'check'
    } else if (!next) {
      // This is the current status, so it's considered "active". Show a spinner
      iconType = 'loading'
    }

    const icon = iconType ? <Icon type={iconType} /> : null

    switch (shipmentStatus.status) {
      case 'WAITING_FOR_ACCEPTANCE': {
        return (
          <Steps.Step
            key={shipmentStatus.id}
            icon={icon}
            title={(
              completedAt ? (
                <span>Accepted by <strong>
                  {isAdmin ? (
                    <RouteLink routeKey='Driver Dashboard' params={{ id: nextDriver.id }}>{nextDriverName}</RouteLink>
                  ) : (
                    nextDriverName
                  )}
                </strong></span>
              ) : (
                <span>Waiting for a driver to accept</span>
              )
            )}
            description={(
              (completedAt || nextDriver.id || (!isAdmin && isCurrentStatus)) && (
                <Card size='small'>
                  <Card.Meta
                    description={(
                      <React.Fragment>
                        {completedAt && (
                          <div><strong>Accepted on:</strong> <span style={{ color: '#666' }}>{completedAt}</span></div>
                        )}
                        {nextDriver.id && (
                          <DriverDetails
                            driverId={nextDriver.id}
                            driver={nextDriver}
                            isAdmin={isAdmin}
                            deps={{ RouteLink }}
                          />
                        )}
                        {!isAdmin && isCurrentStatus && (
                          <Button
                            disabled={isLoading}
                            loading={isLoading}
                            onClick={() => send({ type: 'route edit shipment', id: shipmentId })}
                          >Edit Route</Button>
                        )}
                      </React.Fragment>
                    )}
                  />
                </Card>
              )
            )}
          />
        )
      }

      case 'WAITING_TO_START': {
        return (
          <Steps.Step
            key={shipmentStatus.id}
            icon={icon}
            title={(
              completedAt ? (
                <span>Started</span>
              ) : (
                <span>Waiting for {driverName} to start</span>
              )
            )}
            description={(
              completedAt && (
                <Card size='small'>
                  <Card.Meta
                    description={(
                      completedAt && (
                        <div>
                          <strong>Started on:</strong> <span style={{ color: '#666' }}>{completedAt}</span>
                        </div>
                      )
                    )}
                  />
                </Card>
              )
            )}
          />
        )
      }

      case 'EN_ROUTE_TO_PICKUP': {
        return (
          <Steps.Step
            key={shipmentStatus.id}
            icon={icon}
            title={(
              completedAt ? (
                <span>Arrived at pickup location</span>
              ) : (
                <React.Fragment>
                  <div><span>En route to pickup location</span></div>
                  {showEstPickupArrival ? (
                    <div style={{ marginTop: -8, marginBottom: 5 }}><small>Est. Arrival: <Tag color="#000">{estPickupArrivalText}</Tag></small></div>
                  ) : null}
                </React.Fragment>
              )
            )}
            description={(
              <Card size='small'>
                <Card.Meta
                  description={(
                    <React.Fragment>
                      <div><strong>Address:</strong> <span style={{ color: '#666' }}>{pickupAddress}</span></div>
                      {completedAt && (
                        <div><strong>Arrived on:</strong> <span style={{ color: '#666' }}>{completedAt}</span></div>
                      )}
                    </React.Fragment>
                  )}
                />
              </Card>
            )}
          />
        )
      }

      case 'ARRIVED_AT_PICKUP': {
        const photoUrl = (
          'cachedImageUrl' in shipmentStatus
            ? shipmentStatus.cachedImageUrl
            : pickupPhotoUrl
        )

        return (
          <Steps.Step
            key={shipmentStatus.id}
            icon={icon}
            title={(
              completedAt ? (
                <span>Delivery picked up</span>
              ) : (
                <span>Picking up delivery</span>
              )
            )}
            description={(
              <Card size='small'>
                <Card.Meta
                  avatar={(
                    photoUrl ? (
                      <HoverPhoto
                        title='Pickup Photo'
                        photoSrc={photoUrl}
                      />
                    ) : null
                  )}
                  description={(
                    <React.Fragment>
                      <div><strong>Address:</strong> <span style={{ color: '#666' }}>{pickupAddress}</span></div>
                      {completedAt && (
                        <div><strong>Picked up on:</strong> <span style={{ color: '#666' }}>{completedAt}</span></div>
                      )}
                    </React.Fragment>
                  )}
                />
              </Card>
            )}
          />
        )
      }

      case 'OUT_FOR_DELIVERY': {
        let orderUuid = null
        let deliveryIndex = null

        if (next && ['ARRIVED_AT_DELIVERY', 'DELIVERED'].includes(next.status)) {
          // The next status is "ARRIVED_AT_DELIVERY", which means this status
          // has happened in the past. We have sufficient information to infer
          // which address the driver was heading towards for this status.
          orderUuid = next.orderUuid
          deliveryIndex = next.deliveryIndex
        } else {
          // The next status is not "ARRIVED_AT_DELIVERY", so we actually don't
          // know for certain where this driver is going, primarily in a grouped
          // shipment situation. Let's assume they are going to the first
          // available index that is not delivered.
          // Note that since we enforce a certain order now, the comment above
          // isn't really true. We've sorted ordersArray above based on the
          // shipment's directionsResult, so the next order is very likely to be
          // accurate now.
          deliveryIndex = ordersArray.findIndex(({ deliveredAt }) => !deliveredAt)
          orderUuid = (ordersArray[deliveryIndex] || {}).id
        }

        const order = (orderUuid ? ordersArray.find(({ id }) => id === orderUuid) : ordersArray[deliveryIndex]) || {}

        const deliveryAddress = order.address || 'N/A'
        const orderId = order.orderId || 'N/A'

        const showEstDeliveryArrival = Number.isFinite(order.currentDriverEstimatedSecondsToDelivery)
        const estDeliveryArrivalMins = calculateEstimatedMinutes(order.currentDriverEstimatedSecondsToDelivery, order.currentDriverEstimatedSecondsToDeliveryComputedAt)

        const estDeliveryArrivalText = currentDriverEstimatedSecondsToPickupComputedAt
          ? `${estDeliveryArrivalMins} min${estDeliveryArrivalMins === 1 ? '' : 's'}`
          : 'Calculating…'

        return (
          <Steps.Step
            key={shipmentStatus.id}
            icon={icon}
            title={(
              completedAt ? (
                <span>Arrived at destination</span>
              ) : (
                <React.Fragment>
                  <div><span>{isReturnShipment ? 'En route to return address' : 'En route to destination'}</span></div>
                  {showEstDeliveryArrival ? (
                    <div style={{ marginTop: -8, marginBottom: 5 }}><small>Est. Arrival: <Tag color="#000">{estDeliveryArrivalText}</Tag></small></div>
                  ) : null}
                </React.Fragment>
              )
            )}
            description={(
              <Card size='small'>
                <Card.Meta
                  description={(
                    <React.Fragment>
                      <div><strong>Order ID:</strong> <span style={{ color: '#666' }}>{orderId}</span></div>
                      <div><strong>Address:</strong> <span style={{ color: '#666' }}>{deliveryAddress}</span></div>
                      {completedAt && (
                        <div><strong>Arrived on:</strong> <span style={{ color: '#666' }}>{completedAt}</span></div>
                      )}
                    </React.Fragment>
                  )}
                />
              </Card>
            )}
          />
        )
      }

      case 'ARRIVED_AT_DELIVERY': {
        const order = shipmentStatus.__order
        const deliveryAddress = order.address || 'N/A'
        const orderId = order.orderId || 'N/A'

        return (
          <Steps.Step
            key={shipmentStatus.id}
            icon={icon}
            title={(
              completedAt ? (
                <span>Delivery dropped-off</span>
              ) : (
                <span>{isReturnShipment ? 'Dropping-off return' : 'Dropping-off'}</span>
              )
            )}
            description={(
              <Card size='small'>
                <Card.Meta
                  description={(
                    <React.Fragment>
                      <div><strong>Order ID:</strong> <span style={{ color: '#666' }}>{orderId}</span></div>
                      <div><strong>Address:</strong> <span style={{ color: '#666' }}>{deliveryAddress}</span></div>
                      {completedAt && (
                        <div><strong>Delivered on:</strong> <span style={{ color: '#666' }}>{completedAt}</span></div>
                      )}
                    </React.Fragment>
                  )}
                />
              </Card>
            )}
          />
        )
      }

      case 'DELIVERED': {
        const order = shipmentStatus.__order
        const orderId = order.orderId || 'N/A'

        const photoUrl = (
          'cachedImageUrl' in shipmentStatus
            ? shipmentStatus.cachedImageUrl
            : (order.deliveryPhotoUrl)
        )

        let distanceFromLocationToAddressInMeters = null

        if (order.cachedDeliveryLocation && order.location) {
          const { latitude: deliveryLocationLat, longitude: deliveryLocationLong } = order.cachedDeliveryLocation
          const { latitude: deliveryAddressLat, longitude: deliveryAddressLong } = order.location

          const dist = haversineDistance(deliveryLocationLat, deliveryLocationLong, deliveryAddressLat, deliveryAddressLong)
          distanceFromLocationToAddressInMeters = Math.round(dist * 1000)
        }

        return (
          order.undeliverable ? (
            <Steps.Step
              key={shipmentStatus.id}
              icon={icon}
              title={<span>Unable to deliver</span>}
              description={(
                <React.Fragment>
                  <Card size='small'>
                    <div><strong>Order ID:</strong> <span style={{ color: '#666' }}>{orderId}</span></div>
                    <Card size='small' type='inner' style={{ marginTop: 20 }} title={'Customer was not available to receive order'}>
                      <div>{orderId} will be returned along with any other orders which the driver was unable to deliver.</div>
                      <div>Additional charges for this operation may be applied.</div>
                    </Card>
                  </Card>
                  {notesCard}
                </React.Fragment>
              )}
            />
          ) : (
            <Steps.Step
              key={shipmentStatus.id}
              icon={icon}
              title={<span>{`Delivered${driverId ? '' : ' by Merchant'}`}</span>}
              description={(
                <React.Fragment>
                  <Card size='small'>
                    <Card.Meta
                      avatar={photoUrl ? (
                        <HoverPhoto
                          title='Delivery Photo'
                          photoSrc={photoUrl}
                        />
                    ) : null}
                      description={(
                        <React.Fragment>
                          <div><strong>Delivered on:</strong> <span style={{ color: '#666' }}>{createdAt}</span></div>
                          <div>
                            <strong>Drop-off distance: </strong>{distanceFromLocationToAddressInMeters !== null ? `${distanceFromLocationToAddressInMeters} meters` : 'N/A'}*
                          </div>
                          <small>* This is an estimate based on the driver’s last known GPS coordinates.</small>
                        </React.Fragment>
                    )}
                    />
                  </Card>
                  {notesCard}
                </React.Fragment>
              )}
            />
          )
        )
      }

      case 'RENOUNCED_BY_DRIVER': {
        return (
          <Steps.Step
            key={shipmentStatus.id}
            icon={icon}
            status='error'
            title={`Renounced by ${driverName}`}
            description={(
              <Card size='small'>
                <Card.Meta
                  description={(
                    <React.Fragment>
                      <div><strong>Renounced by driver on:</strong> <span style={{ color: '#666' }}>{createdAt}</span></div>
                      {notesCard}
                      {!isAdmin && (currentStatus === shipmentStatus.status) ? (
                        <div style={{ marginTop: 10 }}>
                          <Button
                            block
                            key='broadcast'
                            type='primary'
                            size='small'
                            disabled={isLoading}
                            loading={current.within('Broadcasting Shipment')}
                            style={{ marginBottom: 5 }}
                            onClick={() => confirmBroadcastShipment(shipment, send)}
                          >Broadcast</Button>
                          <Button
                            block
                            key='cancel'
                            type='danger'
                            size='small'
                            disabled={isLoading}
                            loading={current.within(['Cancelling Shipment', 'Estimating Cancellation Fee'])}
                            style={{ marginBottom: 5 }}
                            onClick={() => send({ type: 'estimate cancellation fee', id: shipment.id, currentStatus })}
                          >Cancel</Button>
                          <Button
                            block
                            size='small'
                            disabled={isLoading}
                            loading={current.within('Putting Shipment On Hold')}
                            onClick={() => send({ type: 'put shipment on hold', shipmentId: shipment.id, currentStatus })}
                          >Put On Hold</Button>
                        </div>
                      ) : null}
                    </React.Fragment>
                  )}
                />
              </Card>
            )}
          />
        )
      }

      case 'CANCELLED': {
        return (
          <Steps.Step
            key={shipmentStatus.id}
            icon={icon}
            status='error'
            title={'Cancelled'}
            description={(
              <Card size='small'>
                <Card.Meta
                  description={(
                    <div><strong>Cancelled on:</strong> <span style={{ color: '#666' }}>{createdAt}</span></div>
                  )}
                />
              </Card>
            )}
          />
        )
      }

      case 'ON_HOLD': {
        const resubmitted = postedAt && getFormattedDateAndTimeFromDate(postedAt.toDate())

        return (
          <Steps.Step
            key={shipmentStatus.id}
            icon={icon}
            title={'On Hold'}
            description={(
              <Card size='small'>
                <Card.Meta
                  description={(
                    <React.Fragment>
                      <div><strong>On hold since:</strong> <span style={{ color: '#666' }}>{createdAt}</span></div>
                      {
                        completedAt
                          ? <div style={{ paddingTop: 10 }}><strong>Resubmitted on:</strong> <span style={{ color: '#666' }}>{resubmitted}</span></div>
                          : null
                      }
                    </React.Fragment>
                  )}
                />
              </Card>
            )}
          />
        )
      }

      // backwards-compat
      case 'EXPIRED': {
        const resubmitted = postedAt && getFormattedDateAndTimeFromDate(postedAt.toDate())

        return (
          <Steps.Step
            key={shipmentStatus.id}
            icon={icon}
            status='error'
            title={'Expired'}
            description={(
              <Card size='small'>
                <Card.Meta
                  description={(
                    <React.Fragment>
                      <div><strong>Expired on:</strong> <span style={{ color: '#666' }}>{createdAt}</span></div>
                      {
                        completedAt
                          ? <div style={{ paddingTop: 10 }}><strong>Resubmitted on:</strong> <span style={{ color: '#666' }}>{resubmitted}</span></div>
                          : null
                      }
                    </React.Fragment>
                  )}
                />
              </Card>
            )}
          />
        )
      }

      default:
        return false
    }
  }).filter(Boolean)

  if (!currentStatus) {
    return <p>Timeline not available; status is <em>{NO_SHIPMENT_STATUS}</em></p>
  }

  if (!steps.length) {
    return <Spin />
  }

  if (
    ordersArray.find(({ undeliverable }) => undeliverable) &&
    returnToSenderChild
  ) {
    steps.push(
      <Steps.Step
        key='undeliverable-notice'
        icon={<Icon type='rollback' />}
        description={(
          <Card size='small'>
            <Card.Meta
              description={(
                <div>Undeliverable orders returned via <RouteLink routeKey='Shipment Dashboard' params={{ id: returnToSenderChild.shipmentId }}>{returnToSenderChild.shipmentId}</RouteLink></div>
              )}
            />
          </Card>
        )}
      />
    )
  }

  if (returnReusablesChild) {
    steps.push(
      <Steps.Step
        key='return-reusables-notice'
        icon={<Icon type='rollback' />}
        description={(
          <Card size='small'>
            <Card.Meta
              description={(
                <div>Reusables returned via <RouteLink routeKey='Shipment Dashboard' params={{ id: returnReusablesChild.shipmentId }}>{returnReusablesChild.shipmentId}</RouteLink></div>
              )}
            />
          </Card>
        )}
      />
    )
  }

  return (
    <Steps direction='vertical' current={steps.length - 1}>
      {steps}
    </Steps>
  )
}

ShipmentStatusTimeline.propTypes = {
  shipment: PropTypes.object.isRequired,
  driver: PropTypes.object,
  statuses: PropTypes.array,
  isLoading: PropTypes.bool,
  isAdmin: PropTypes.bool,
  deps: PropTypes.exact({
    send: PropTypes.func,
    current: PropTypes.object,
    RouteLink: PropTypes.func
  }).isRequired
}
