/* eslint-disable padding-line-between-statements */
/*
A module that exports an 'address' extractor for domestic Canadian addresses.
*/

const { Address } = require('./address')
const Helpers = require('./helpers')

const provinceNames = ([...Helpers.CA_PROVINCE_ABBR.values()]
  .reduce((a, b) => a.concat(b.split(',')), []))

// A regex pattern factory for matching domestic addresses
function Pattern ({ postalCodeOptional = false, countryOptional = false } = {}) {
  return new RegExp([
    `(\\d+[a-z]*\\s*)?`, // can't be prefixed by a street number (i.e. 80 Ontario St.) (only necessary if postal code is optional)
    // province
    `(` + [
      ...provinceNames,
      ...[...Helpers.CA_PROVINCE_ABBR.keys()].map((p) => `\\b${p}\\b`)
    ].join('|') + ')\\.?', // so we support ON., and ON., and Ontario., and Ontario.,
    `[\\s,]*(?:([a-zA-Z]\\d[a-zA-Z](?: *\\d[a-zA-Z]\\d)?)\\b)${postalCodeOptional ? '?' : ''}`, // postal code (normally required to disambiguate the province abbr.)
    `(?:[\\s,]*(?:[Cc]anada|CAN|CA))${countryOptional ? '?' : ''}` // country
  ].join(''), 'gu')
}

/**
 * Full-extractor that matches a Canadian domestic mailing address.
 *
 * Country is by default optional.
 *
 * Optionally, if 'withoutAddressee' is truthy then <ADDRESSEE>
 * will be assumed to not be in the text and instead <STREET> will
 * assume to be the first line in the text.
 *
 * Optionally, if 'postalCodeOptional' is truthy then the postal code
 * will be optional, otherwise the postal code will be required in order
 * for the address to match.
 *
 * Format:
 *
 *  <ADDRESSEE> <NL>|<COMMA>
 *  (<ADDNTL. ADDRESS INFO> <NL>|<COMMA>)?
 *  <STREET> <NL>|<COMMA> --- this will include site and compartment info in addition to the rural route line
 *  (LINE <NL>|<COMMA>)*
 *  <CITY> <NL>|<COMMA>
 *  <PROVINCE> (<NL>|<COMMA>)?
 *  <POSTAL CODE>? (<NL>|<COMMA>)?
 *  <COUNTRY>?
 *  (<NL>|<COMMA> LINE)*
 *
 * Where whitespace characters are prerved in the STREET and CITY.
 *
 * Where PROVINCE can be the full name or standard postal abbreviation.
 * Additionally, 'PEI' and 'Newfoundland' are accepted.
 *
 * Where apartment as "apt|unit|suite <number>" or "#<number>"" is accepted anywhere in the text.
 *
 * @see https://www.canadapost.ca/web/en/kb/details.page?article=how_to_address_mail_&cattype=kb&cat=sending&subcat=generalinformation
 * @see https://www.canadapost.ca/tools/pg/manual/PGaddress-e.asp?ecid=murl10006450#1417682
 * @param {string} text
 * @param {{ withoutAddressee?: boolean, postalCodeOptional?: boolean, countryOptional?: boolean }} [options]
 * @return {[ReturnType<typeof Address>, string]}
 */
function canadianAddress (text, { withoutAddressee = false, postalCodeOptional = false, countryOptional = true } = {}) {
  if (typeof text !== 'string') {
    throw new TypeError('text must be a string')
  }

  // const mtAlbertaPat = MountAlbertaPattern({ postalCodeOptional, countryOptional })
  const pat = Pattern({ postalCodeOptional, countryOptional })

  const cleanedText = text
    .trim()
    .split('\n')
    .map((l) => l.trim())
    .filter(Boolean)
    .join('\n')
    .replace(/\t/gu, ' ')

  let m = pat.exec(cleanedText)

  // If first capture group is there then it means the street has the province/state
  // in the street name. So we run the regex again from the lastIndex.
  if (m && m[1]) {
    m = pat.exec(cleanedText)
  }

  if (m) {
    let strLines = cleanedText.substring(0, m.index)
    let strExtraLines = cleanedText.substring(pat.lastIndex)
    let apartment = ''
    const buzzer = ''
    let careof = ''
    let lines = []
    let extraLines = []

    // ;([buzzer, strLines] = Helpers.buzzer(strLines))
    ;([apartment, strLines] = Helpers.apartment(strLines))
    ;([careof, strLines] = Helpers.careof(strLines))
    lines = Helpers.lines(strLines)

    if (!apartment) {
      ([apartment, strExtraLines] = Helpers.apartment(strExtraLines))
    }

    if (!careof) {
      ([careof, strExtraLines] = Helpers.careof(strExtraLines))
    }

    extraLines = Helpers.lines(strExtraLines)

    const addressee = withoutAddressee && lines.length ? '' : (lines.shift() || '')

    const deliveryInfo = [
      buzzer,
      lines.length && !/^po\s+box|^site\s+|^rr\s+|^\d+[a-z]*\s+(\d+\/\d+\s+|\d+\s*)?[^0-9\s]/iu.test(lines[0])
        ? lines.shift().trim()
        : ''
    ].filter(Boolean).join(' ')

    return [
      Address({
        addressee,
        careof,
        apartment,
        street: (function () {
          const street = []

          // PO BOX
          // i.e. PO BOX 4001 STN A
          if (lines.length && /^po\s+box/iu.test(lines[0])) {
            street.push(lines.shift())
            return street.join('')
          }

          // House
          // i.e. 123 MAIN ST
          // i.e 11239 180 St NW -- (Edmonton)
          // i.e 123 1/2 Main St
          if (lines.length && /^\d+[a-z]*\s+(\d+\/\d+\s+|\d+\s*)?[^0-9\s]/iu.test(lines[0])) {
            street.push(lines.shift())
            return street.join('')
          }

          // Site and compartment info
          // i.e. SITE 6 COMP 10
          if (lines.length && /^site\s+/iu.test(lines[0])) {
            street.push(lines.shift())
          }

          // Rural route
          // i.e. RR 6 STN MAIN
          if (lines.length && /^rr\s+/iu.test(lines[0])) {
            street.push(lines.shift())
          }

          return street.join(', ')
        }()),
        city: lines.pop() || '',
        deliveryInfo: [deliveryInfo, ...lines].filter(Boolean).join(' '),
        lines: extraLines,
        province: Helpers.toProvinceName(Helpers.CA_PROVINCE_ABBR, m[2]),
        provinceAbbr: Helpers.toProvinceAbbr(Helpers.CA_PROVINCE_ABBR, m[2]),
        postalCode: m[3] ? [m[3].substr(0, 3), m[3].slice(3).substr(-3)].join(' ') : '',
        country: 'Canada',
        countryAbbr: 'CA'
      }),
      ''
    ]
  } else {
    return [null, text]
  }
}

exports.canadianAddress = canadianAddress
