import * as React from 'react'
import * as PropTypes from 'prop-types'
import { Select } from 'antd'

/*
 * Generic search-input component that will perform an asynchronous search for
 * items to populate a selection list with. Only one selection can be made at a
 * time.
 *
 * The `fetchData` prop must resolve to an array of objects of the form:
 *
 *   { key: string, text: string }
 *
 * The `onChange` prop is optional and must be a function with the following
 * signagure:
 *
 *   (key: string) => void
 *
 * Example:
 *
 * const lookupCars = text => {
 *   text = text.toLowerCase()
 *   return CARS.filter(car => car.model.toLowerCase().includes(text))
 *     .map(car => ({ key: car.id, text: car.model }))
 * }
 * <SearchInput
 *   placeholder='Car model...'
 *   fetchData={lookupCars}
 *   onChange={carId => console.log(carId)}
 * />
 *
 * @see https://ant.design/components/select/
 */

const callable = (fn) => typeof fn === 'function' ? fn : () => fn

const partial = (fn, ...args) => {
  return callable(fn).bind(undefined, ...args)
}

const apply = (fn, ...args) => {
  return callable(fn).apply(undefined, args)
}

const handleSearch = (setData, fetchData, value) => {
  if (value) {
    fetchData(value)
      .then(setData)
      .catch((error) => {
        if (typeof console !== 'undefined' &&
          typeof console.warn === 'function') {
          console.warn(error.toString())
        }
      })
  } else {
    setData([])
  }
}

const BASE_STYLE = {
  width: '100%'
}

SearchInput.propTypes = {
  /**
   * @type {() => Promise<{ key: string, text: string }>}
   */
  fetchData: PropTypes.func.isRequired,
  /**
   * @type {(key: string) => void}
   */
  onChange: PropTypes.func,
  placeholder: PropTypes.string
}

SearchInput.defaultProps = {
  placeholder: 'Search…'
}

function SearchInput (props = {}) {
  const { fetchData, onChange, placeholder } = props

  const [data, setData] = React.useState([])
  const [value, setValue] = React.useState()

  const options = data.map((d) => {
    return <Select.Option key={d.key}>{d.text}</Select.Option>
  })

  if (typeof fetchData !== 'function') {
    throw new TypeError('Prop "fetchData" must be a function')
  }

  const style = { ...BASE_STYLE }

  return (
    <Select
      showSearch
      value={value}
      placeholder={placeholder}
      style={style}
      defaultActiveFirstOption={false}
      showArrow={false}
      filterOption={false}
      onSearch={partial(handleSearch, setData, fetchData)}
      onChange={(value) => {
        setValue(value)
        apply(onChange, value)
      }}
      notFoundContent={null}
    >
      {options}
    </Select>
  )
}

export { SearchInput as default }
