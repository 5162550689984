// Reference: https://www.canadapost-postescanada.ca/scp/fr/soutien/sujet/directives-adressage/symboles-et-abreviations.page

/*

################################################################################

STOP: Becareful about changing the mappings! We must ensure that the same mappings
apply. Adding new mappings or changing mappings will need to re-key all existing
Location entities.

    // One way is to load each location then just update the address without
    // any change. This will automatically recompute and update the key.
    await locationRepo.update(location.id, { addressObj: location.addressObj })

################################################################################

Copy this block of code in the browser console of the page referenced above.
Your copy buffer will have the code already. Now paste into a file and remove
all double quotes. At the end of the block there is a copy() function call that
will copy a global variable into the copy buffer, but this has only been tested
on Chrome and webkit-based browsers.

This block will scrape the page and assemble the terms into an array like this:

    [
      [key, [val, val, ...]],
      ...
    ]

Where `key` is the capitalized, full street suffix (i.e. the preferred value
after normalizing).

Each `val` is to be a known abbreviation. These can be all uppercase or not.
However, if a suffix does not have any known abbreviations then key it to itself:

    [key, [key]]

--------------------------------------------------------------------------------
a = (function extractCanadaPost () {
  const vals = [].concat(
    ...[...document.querySelectorAll('table')]
      .slice(0, 2)
      .map(t => [...t.querySelectorAll('td')].map(e => e.textContent.trim()))
  ).map(val => val.replace(/\(.*\)/u, '').trim())

  const pairs = []

  for (let i = 0; i < vals.length; i += 2) {
    pairs.push([vals[i], [vals[i + 1]]])
  }

  return pairs.map(([a, b]) => `['${a}', ['${b.join("', '")}']]`)
}())
copy(a)
--------------------------------------------------------------------------------
*/

const pairs = [
  ['Abbey', ['ABBEY']],
  ['Acres', ['ACRES']],
  ['Allée', ['ALLÉE']],
  ['Alley', ['ALLEY']],
  ['Avenue', ['AVE']],
  ['Avenue', ['AV']],
  ['Bay', ['BAY']],
  ['Beach', ['BEACH']],
  ['Bend', ['BEND']],
  ['Boulevard', ['BLVD']],
  ['Boulevard', ['BOUL']],
  ['By-pass', ['BYPASS']],
  ['Byway', ['BYWAY']],
  ['Campus', ['CAMPUS']],
  ['Cape', ['CAPE']],
  ['Carré', ['CAR']],
  ['Carrefour', ['CARREF']],
  ['Centre', ['CTR']],
  ['Centre', ['C']],
  ['Cercle', ['Cercle']],
  ['Chase', ['CHASE']],
  ['Chemin', ['CH']],
  ['Circle', ['CIR']],
  ['Circuit', ['CIRCT']],
  ['Close', ['CLOSE']],
  ['Common', ['COMMON']],
  ['Concession', ['CONC']],
  ['Corners', ['CRNRS']],
  ['Côte', ['CÔTE']],
  ['Cour', ['COUR']],
  ['Croissant', ['CROIS']],
  ['Crossing', ['CROSS']],
  ['Crossing', ['CROSS']],
  ['Cul-de-sac', ['CDS']],
  ['Dale', ['DALE']],
  ['Dell', ['DELL']],
  ['Diversion', ['DIVERS']],
  ['Downs', ['DOWNS']],
  ['Drive', ['DR']],
  ['Échangeur', ['ÉCH']],
  ['End', ['END']],
  ['Esplanade', ['ESPL']],
  ['Estates', ['ESTATE']],
  ['Expressway', ['EXPY']],
  ['Extension', ['EXTEN']],
  ['Farm', ['FARM']],
  ['Field', ['FIELD']],
  ['Forest', ['FOREST']],
  ['Freeway', ['FWY']],
  ['Front', ['FRONT']],
  ['Gardens', ['GDNS']],
  ['Gate', ['GATE']],
  ['Glade', ['GLADE']],
  ['Glen', ['GLEN&']],
  ['Green', ['GREEN']],
  ['Grounds', ['GRNDS']],
  ['Grove', ['GROVE']],
  ['Harbour', ['HARBR']],
  ['Heath', ['HEATH']],
  ['Heights', ['HTS']],
  ['Highlands', ['HGHLDS']],
  ['Highway', ['HWY']],
  ['Hill', ['HILL']],
  ['Hollow', ['HOLLOW']],
  ['Île', ['ÎLE']],
  ['Impasse', ['IMP']],
  ['Inlet', ['INLET']],
  ['Island', ['ISLAND']],
  ['Key', ['KEY']],
  ['Knoll', ['KNOLL']],
  ['Landing', ['LANDNG']],
  ['Lane', ['LANE']],
  ['Limits', ['LMTS']],
  ['Line', ['LINE']],
  ['Link', ['LINK']],
  ['Lookout', ['LKOUT']],
  ['Loop', ['LOOP']],
  ['Mall', ['MALL']],
  ['Manor', ['MANOR']],
  ['Maze', ['MAZE']],
  ['Meadow', ['MEADOW']],
  ['Mews', ['MEWS']],
  ['Montée', ['MONTÉE']],
  ['Moor', ['MOOR']],
  ['Mount', ['MOUNT']],
  ['Mountain', ['MTN']],
  ['Orchard', ['ORCH']],
  ['Parade', ['PARADE']],
  ['Parc', ['PARC']],
  ['Park', ['PK']],
  ['Parkway', ['PKY']],
  ['Passage', ['PASS']],
  ['Path', ['PATH']],
  ['Pathway', ['PTWAY']],
  ['Pines', ['PINES']],
  ['Place', ['PLACE']],
  ['Plateau', ['PLAT']],
  ['Plaza', ['PLAZA']],
  ['Point', ['PT']],
  ['Pointe', ['POINTE']],
  ['Port', ['PORT']],
  ['Private', ['PVT']],
  ['Promenade', ['PROM']],
  ['Quai', ['QUAI']],
  ['Quay', ['QUAY']],
  ['Ramp', ['RAMP']],
  ['Rang', ['RANG']],
  ['Range', ['RG']],
  ['Ridge', ['RIDGE']],
  ['Rise', ['RISE']],
  ['Road', ['RD']],
  ['Rond-point', ['RDPT']],
  ['Route', ['RTE']],
  ['Row', ['ROW']],
  ['Rue', ['RUE']],
  ['Ruelle', ['RLE']],
  ['Run', ['RUN']],
  ['Sentier', ['SENT']],
  ['Square', ['SQ']],
  ['Street', ['ST']],
  ['Subdivision', ['SUBDIV']],
  ['Terrace', ['TERR']],
  ['Terrasse', ['TSSE']],
  ['Thicket', ['THICK']],
  ['Towers', ['TOWERS']],
  ['Townline', ['TLINE']],
  ['Trail', ['TRAIL']],
  ['Turnabout', ['TRNABT']],
  ['Vale', ['VALE']],
  ['Via', ['VIA']],
  ['View', ['VIEW']],
  ['Village', ['VILLGE']],
  ['Villas', ['VILLAS']],
  ['Vista', ['VISTA']],
  ['Voie', ['VOIE']],
  ['Walk', ['WALK']],
  ['Way', ['WAY']],
  ['Wharf', ['WHARF']],
  ['Wood', ['WOOD']],
  ['Wynd', ['WYND']]
]

exports.pairs = pairs
