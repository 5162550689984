const { Std } = require('../../core')
const { setWeekdayHours } = require('./setWeekdayHours')
const { isServiceHours } = require('./isServiceHours')

/**
 * Returns a new service hours object with the specified day of the week set as "closed".
 *
 * @param {ServiceHours} serviceHours
 * @param {number} weekday The index of the day of the week (0 = Sun, 6 = Sat)
 * @return {ServiceHours}
 */
function setWeekdayHoursClosed (serviceHours, weekday) {
  if (!isServiceHours(serviceHours)) {
    throw Std.err('TypeError', 'Service hours is invalid')
  }

  if (weekday < 0 || weekday > 6) {
    throw Std.err('TypeError', 'weekday is out of range')
  }

  return setWeekdayHours(serviceHours, weekday, [])
}

exports.setWeekdayHoursClosed = setWeekdayHoursClosed
