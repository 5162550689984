import * as React from 'react'
import PropTypes from '@trexity/common/util/PropTypes'
import { getFormattedMonetaryValueString } from '@trexity/common/pricing'
import { Alert } from 'antd'

const money = getFormattedMonetaryValueString
const truncate = (s, len = 50) => s.length <= len ? s : s.substring(0, len - 1) + '…'

const Style = {
  line: { borderTop: '1px solid black' }
}

export function ShipmentRateBreakdown (props) {
  const surcharges = [
    // FOR TESTING
    // { message: 'Outside delivery area', amount: 100 },
    // { message: 'Fuel surcharge', amount: 100, type: 'bonus-fuel' }
  ].concat(props.surcharges)
    .filter(Boolean)
    .map((s) => ({ ...s, message: truncate(s.message) }))

  const totalSurcharge = surcharges.reduce((memo, { amount }) => memo + amount, 0)

  if (props.mode === 'shipment') {
    const discounts = props.discounts || []
    const estimatedDiscounts = props.estimatedDiscounts || []

    const taxes = props.taxes.map(({ code, amount, rate }) => {
      return { code, amount, rate: (rate * 100).toFixed(3) + '%' }
    })

    const totalDiscounts = discounts.reduce((memo, { totalDiscount }) => memo + totalDiscount, 0)
    const totalEstimatedDiscounts = estimatedDiscounts.reduce((memo, { totalDiscount }) => memo + totalDiscount, 0)
    const subtotal = props.totalPayableWithoutTaxes
    const cost = subtotal - totalSurcharge + totalDiscounts

    const subtotalWithEstimatedDiscounts = subtotal - totalEstimatedDiscounts
    const subtotalWithDiscountsTaxes = taxes.reduce((memo, t) => memo + Math.round(subtotalWithEstimatedDiscounts * (parseFloat(t.rate) / 100)), 0)

    return (
      <table>
        <tbody>
          <tr><td width='180'>Delivery Cost</td><td>{money(cost)}</td></tr>
          {surcharges.map((s) => (<tr key={s.message}><td>{s.message}</td><td>{money(s.amount)}</td></tr>))}
          {Array.isArray(discounts) ? (
            discounts.map(({ name, totalDiscount }, idx) => <tr key={`${name}_${idx}`}><td>{name}</td><td>{money(-totalDiscount)}</td></tr>)
          ) : null}
          <tr style={Style.line}><td>Subtotal</td><td><strong>{money(subtotal)}</strong></td></tr>
          {taxes.map((t) => (<tr key={t.code}><td>{t.code} @ {t.rate}</td><td>{money(t.amount)}</td></tr>))}
          <tr style={Style.line}><td>Total</td><td><strong>{money(props.totalPayableWithTaxes)}</strong></td></tr>
          {estimatedDiscounts.length ? (
            <tr>
              <td colSpan={2}>
                <Alert
                  message={(
                    <React.Fragment>
                      <h4>Eligible for discount*</h4>
                      <div className='nowrap'>Total after discount: <strong>{money(subtotalWithEstimatedDiscounts + subtotalWithDiscountsTaxes)}</strong></div>
                      <div className='nowrap'><small><em>* applied automatically upon posting</em></small></div>
                    </React.Fragment>
                  )}
                  type='success'
                  style={{ marginTop: 5 }}
                />
              </td>
            </tr>
          ) : null}
        </tbody>
      </table>
    )
  } else if (props.mode === 'driver') {
    const driverDividend = props.driverDividend || 0
    const incentive = props.incentive || 0
    const totalDriverSurcharge = surcharges.find((o) => (o.driverAmount)) ? surcharges.reduce((memo, { driverAmount }) => memo + driverAmount, 0) : 0
    const baseDriverEarn = driverDividend - incentive - (totalDriverSurcharge || totalSurcharge)

    return (
      <table>
        <tbody>
          <tr><td width='180'>Driver Earn</td><td>{money(baseDriverEarn)}</td></tr>
          {incentive ? <tr><td width='180'>Surge Bonus</td><td>{money(incentive)}</td></tr> : null}
          {surcharges.map((s) => (<tr key={s.message}><td>{s.message}</td><td>{money('driverAmount' in s ? s.driverAmount : s.amount)}</td></tr>))}
          <tr style={Style.line}><td>Total</td><td><strong>{money(driverDividend)}</strong></td></tr>
        </tbody>
      </table>
    )
  } else {
    throw new TypeError('Unrecognized mode: ' + props.mode)
  }
}

ShipmentRateBreakdown.propTypes = {
  incentive: PropTypes.number,
  driverDividend: PropTypes.number,
  isAdmin: PropTypes.bool,
  // Total without taxes in cents
  totalPayableWithoutTaxes: PropTypes.nonNegativeInteger.isRequired,
  // Total with taxes in cents
  totalPayableWithTaxes: PropTypes.nonNegativeInteger.isRequired,
  taxes: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string.isRequired,
    // Amount charged for this tax code in cents
    amount: PropTypes.positiveInteger.isRequired,
    // Value between 0 and 1 that represents a percent
    rate: PropTypes.number.isRequired
  })).isRequired,
  // Discounts in cents
  discounts: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    totalDiscount: PropTypes.number.isRequired
  })),
  // Estimated discounts in cents
  estimatedDiscounts: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    totalDiscount: PropTypes.number.isRequired
  })),
  surcharges: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string,
    // Brief message describing the surcharge (will be truncated at 50 characters)
    message: PropTypes.string.isRequired,
    // Amount of surcharge in cents
    amount: PropTypes.nonNegativeInteger.isRequired,
    driverAmount: PropTypes.nonNegativeInteger
  }))
}

// Convenient wrappers for accepting a shipment or shipment rate as a prop directly.

/**
 * A convenience ShipmentRateBreakdown wrapper that takes a "shipmentRate" directly as a prop.
 */
export function ShipmentRateBreakdownFromShipmentRate (props) {
  const { shipmentRate, estimatedDiscounts, isAdmin, mode = 'shipment' } = props

  if (!shipmentRate) return null

  return (
    <ShipmentRateBreakdown
      mode={mode}
      incentive={shipmentRate.incentive}
      driverDividend={shipmentRate.driverDividend}
      isAdmin={isAdmin}
      totalPayableWithTaxes={shipmentRate.totalPayable}
      totalPayableWithoutTaxes={shipmentRate.subtotal}
      discounts={shipmentRate.discounts}
      estimatedDiscounts={estimatedDiscounts}
      taxes={shipmentRate.taxBreakdown}
      surcharges={[].concat(shipmentRate.surcharges).filter(Boolean)}
    />
  )
}

ShipmentRateBreakdownFromShipmentRate.propTypes = {
  discounts: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    totalDiscount: PropTypes.number.isRequired
  })),
  estimatedDiscounts: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    totalDiscount: PropTypes.number.isRequired
  })),
  incentive: PropTypes.number,
  driverDividend: PropTypes.number,
  isAdmin: PropTypes.bool,
  mode: PropTypes.oneOf(['shipment', 'driver']),
  shipmentRate: PropTypes.shape({
    totalPayable: PropTypes.nonNegativeInteger.isRequired,
    subtotal: PropTypes.nonNegativeInteger.isRequired,
    discounts: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      totalDiscount: PropTypes.number.isRequired
    })),
    taxBreakdown: PropTypes.arrayOf(PropTypes.shape({
      code: PropTypes.string.isRequired,
      // Amount charged for this tax code in cents
      amount: PropTypes.positiveInteger.isRequired,
      // Value between 0 and 1 that represents a percent
      rate: PropTypes.number.isRequired
    })).isRequired,
    surcharges: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.string,
      // Brief message describing the surcharge (will be truncated at 50 characters)
      message: PropTypes.string.isRequired,
      // Amount of surcharge in cents
      amount: PropTypes.nonNegativeInteger.isRequired,
      driverAmount: PropTypes.nonNegativeInteger
    }))
  })
}

/**
 * A convenience ShipmentRateBreakdown wrapper that takes a "shipment" directly as a prop.
 */
export function ShipmentRateBreakdownFromShipment (props) {
  const { shipment, isAdmin, mode = 'shipment' } = props
  const [estimatedDiscounts, setEstimatedDiscounts] = React.useState(null)

  const {
    shipmentRate,
    cancellationPenaltyRate,
    currentStatus,
    __getDiscountEstimate: getDiscountEstimate
  } = shipment || {}

  React.useEffect(async () => {
    if (!currentStatus && typeof getDiscountEstimate === 'function') {
      const result = await getDiscountEstimate()
      setEstimatedDiscounts(result.discounts)
    }
  }, [])

  if (!shipment) return null

  // We prefer rendering from shipment rate, but if we don't have it then
  // we make do (i.e. for older shipments at rest that don't conform to our schema).
  if (cancellationPenaltyRate) {
    return (
      <ShipmentRateBreakdownFromShipmentRate
        isAdmin={isAdmin}
        mode={mode}
        discounts={cancellationPenaltyRate.discounts}
        estimatedDiscounts={estimatedDiscounts}
        shipmentRate={cancellationPenaltyRate}
      />
    )
  } else if (shipmentRate) {
    return (
      <ShipmentRateBreakdownFromShipmentRate
        isAdmin={isAdmin}
        mode={mode}
        discounts={shipmentRate.discounts}
        estimatedDiscounts={estimatedDiscounts}
        shipmentRate={shipmentRate}
      />
    )
  }

  if (shipment.cachedPenaltyTotal) {
    const taxes = Object.entries(shipment.cachedPenaltyTaxes)
      .map(([code, amount]) => ({
        code: code.toUpperCase(),
        amount,
        rate: shipment.cachedTaxRates[code] // sadly we didn't store cachedPenaltyTaxRates
      }))

    return (
      <ShipmentRateBreakdown
        mode={mode}
        driverDividend={shipment.cachedPenaltyDriverCompensation}
        totalPayableWithTaxes={shipment.cachedPenaltyTotal}
        totalPayableWithoutTaxes={shipment.cachedPenaltyTotalWithoutTaxes}
        estimatedDiscounts={estimatedDiscounts}
        taxes={taxes}
      />
    )
  } else {
    const taxes = Object.entries(shipment.cachedTaxes)
      .map(([code, amount]) => ({
        code: code.toUpperCase(),
        amount,
        rate: shipment.cachedTaxRates[code]
      }))

    return (
      <ShipmentRateBreakdown
        mode={mode}
        driverDividend={shipment.cachedDriverCompensation}
        totalPayableWithTaxes={shipment.cachedTotal}
        totalPayableWithoutTaxes={shipment.cachedTotalWithoutTaxes}
        estimatedDiscounts={estimatedDiscounts}
        taxes={taxes}
      />
    )
  }
}

ShipmentRateBreakdownFromShipment.propTypes = {
  mode: PropTypes.oneOf(['shipment', 'driver']),
  isAdmin: PropTypes.bool,
  shipment: PropTypes.shape({

    cachedTotal: PropTypes.nonNegativeInteger.isRequired,
    cachedTotalWithoutTaxes: PropTypes.nonNegativeInteger.isRequired,
    // { [code:string]: number } e.g. { 'HST': 237 }
    cachedTaxes: PropTypes.object.isRequired,
    // { [code:string]: number } e.g { 'HST': 0.13 }
    cachedTaxRates: PropTypes.object.isRequired,
    shipmentRate: PropTypes.object,

    cachedPenaltyTotal: PropTypes.nonNegativeInteger,
    cachedPenaltyTotalWithoutTaxes: PropTypes.nonNegativeInteger,
    // { [code:string]: number } e.g. { 'HST': 237 }
    cachedPenaltyTaxes: PropTypes.object,
    cancellationPenaltyRate: PropTypes.object
  })
}
