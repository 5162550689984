/*
Takes internal-order objects with errors and displays them in a table.
In other words shows what is wrong with the input.
*/

import React from 'react'
import PropTypes from 'prop-types'
import { Table, Tag } from 'antd'

// Convert a value to a string.
// Return empty string if value is null or undefined.
const tostr = (val) => {
  if (val === null || val === undefined) return ''
  return val.toString()
}

InputErrorTable.propTypes = {
  orders: PropTypes.arrayOf(PropTypes.shape({
    rowNum: PropTypes.number.isRequired,
    orderId: PropTypes.string,
    name: PropTypes.string,
    errors: PropTypes.arrayOf(PropTypes.shape({
      message: PropTypes.string.isRequired,
      value: PropTypes.any,
      columns: PropTypes.arrayOf(PropTypes.string).isRequired
    })).isRequired
  })).isRequired
}

const getOrderKey = (order) => `${order.rowNum}`

const renderErrors = (text, order, index) => {
  return order.errors.map(renderError)
}

const renderError = (error) => {
  return (
    <div key={error.columns.join('-')}>
      <p>{error.columns.map((col) => (<Tag key={col} color='red'>{col}</Tag>))}</p>
      <p>{error.message}</p>
      {
        error.value
          ? (<p><code>{String(error.value)}</code></p>)
          : null
      }
    </div>
  )
}

const renderFieldFactory = (field) => (text, order, index) => {
  return tostr(order[field]) || '—'
}

const renderCustomerName = renderFieldFactory('name')
const renderOrderId = renderFieldFactory('orderId')

const rowClassName = () => 'import-error'
const style = { marginBottom: 15 }
const scroll = { y: 340, x: 1200 }

function InputErrorTable (props) {
  const { orders } = props

  return (
    <Table
      pagination={false}
      size='small'
      bordered={false}
      dataSource={orders}
      style={style}
      scroll={scroll}
      rowClassName={rowClassName}
      rowKey={getOrderKey}
    >
      <Table.Column
        dataIndex='rowNum'
        key='rowNum'
        title='Row #'
        width={65}
        align='center'
      />
      <Table.Column
        dataIndex='orderId'
        key='orderId'
        title='Order ID'
        render={renderOrderId}
        width={180}
      />
      <Table.Column
        dataIndex='name'
        key='name'
        title='Customer Name'
        render={renderCustomerName}
        width={200}
      />
      <Table.Column
        dataIndex='errors'
        key='errors'
        title='Errors'
        render={renderErrors}
      />
    </Table>
  )
}

export { InputErrorTable }
