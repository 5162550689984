const { Time } = require('../../types')

/**
 * @param {TimeRange<import('../../types/Time').Time>} range
 * @return {TimeRange<import('../../types/Time').Time>}
 */
function toTimeRange (range) {
  return {
    fromTime: Time.from(range.fromTime).value,
    toTime: Time.from(range.toTime).value
  }
}

exports.toTimeRange = toTimeRange
