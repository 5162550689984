const getFormattedTimeFromDate = require('./getFormattedTimeFromDate')
const getFormattedDateFromDate = require('./getFormattedDateFromDate')

/**
 * @param {Date} date
 * @param {boolean} [showSeconds]
 * @returns {string}
 */
module.exports = function getFormattedDateAndTimeFromDate (date, { showMinutes = true, showSeconds = false } = {}) {
  if (isNaN(date.getTime())) return date.toString()
  return `${getFormattedDateFromDate(date)} at ${getFormattedTimeFromDate(date, { showMinutes, showSeconds })}`
}
