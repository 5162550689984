import React from 'react'
import PropTypes from 'prop-types'
import { Skeleton } from 'antd'
import MapView from '../Map'

export default function Map ({
  isAdmin = false,
  deps
}) {
  const {
    current,
    data,
    google
  } = deps

  const {
    shipmentDashboardShipment: shipment,
    shipmentDashboardDriver: driver,
    shipmentDashboardDriverLatestLocation: driverLatestLocation,
    shipmentDashboardDriverLocations: driverLocations
  } = data

  const isLoading = current.within('Loading Map')
  const isReady = current.within('Showing Map') && shipment && shipment.id

  if (isReady) {
    let directionsResult = shipment.directionsResult
    let involvedRoutePlanResults = null

    // Prefer cached snapshot of shipment over driver results
    const routePlanResults = (
      shipment.cachedRoutePlanResults && shipment.cachedRoutePlanResults.length ? shipment.cachedRoutePlanResults
        : driver && driver.routePlanResults && driver.routePlanResults.length ? driver.routePlanResults
          : null
    )

    if (shipment.driverId && routePlanResults) {
      try {
        involvedRoutePlanResults = routePlanResults
          .filter(({ shipmentIds }) => shipmentIds.includes(shipment.id))
          .map((result) => ({
            ...result,
            data: JSON.parse(result.data)
          }))

        directionsResult = shipment.directionsResult
      } catch (error) {
        // If something goes wrong during parsing, fallback to shipment version
        involvedRoutePlanResults = null
        directionsResult = shipment.directionsResult
      }
    }

    return (
      <MapView
        directionsResult={directionsResult || null}
        routePlanResults={involvedRoutePlanResults || null}
        driver={driver}
        driverLatestLocation={driverLatestLocation}
        shipment={shipment}
        driverLocations={driverLocations}
        height={600}
        isLoading={isLoading}
        showTimeline={isAdmin}
        deps={{ google }}
      />
    )
  } else {
    return (
      <Skeleton active />
    )
  }
}

Map.propTypes = {
  isAdmin: PropTypes.bool,
  deps: PropTypes.exact({
    current: PropTypes.object,
    data: PropTypes.object,
    google: PropTypes.object,
    tc: PropTypes.object
  }).isRequired
}
