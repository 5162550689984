import React from 'react'
import PropTypes from 'prop-types'
import { HeadingBase } from './heading.styles'

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'

export const H4 = ({
  children,
  color,
  style,
  ...rest
}) => {
  return (
    <h4 css={[HeadingBase, Heading4Styles(color), style]} {...rest}>{children}</h4>
  )
}

export const Heading4Styles = (color) => ({
  fontSize: '16px',
  textTransform: 'uppercase',
  color: color
})

H4.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  style: PropTypes.object
}
