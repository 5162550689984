/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { COLORS } from '@trexity/common/color/constants'
import PropTypes from 'prop-types'
import { ClearButton } from '../Button/ClearButton'

export const Modal = ({ visible, children, onClose }) => {
  const visibleStyles = visible ? { pointerEvents: 'all', opacity: 1 } : { pointerEvents: 'none', opacity: 0 }
  return (
    <div
      css={{
        position: 'fixed',
        zIndex: 9999,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        height: '100vh',
        width: '100%',
        backgroundColor: 'rgba(0,0,0,0.6)',
        ...visibleStyles
      }}
    >
      <div css={{ position: 'absolute', top: 20, right: 20 }}>
        <ClearButton onClick={onClose}>
          CLOSE
        </ClearButton>
      </div>
      <div css={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '100%' }}>
        <div css={{ backgroundColor: COLORS.WHITE, padding: 40, borderRadius: 5 }}>
          {children}
        </div>
      </div>
    </div>
  )
}

Modal.propTypes = {
  visible: PropTypes.bool,
  children: PropTypes.node,
  onClose: PropTypes.func
}
