/* eslint-disable no-alert */
import React from 'react'
import moment from 'moment'
import { CAPABILITIES } from '@trexity/common/capReq/constants'
import { formatPhoneNumber, validatePhoneNumber } from '@trexity/common/phone'
import { validateEmail } from '@trexity/common/email'
import { validateLicensePlate, validateVehicleYear } from '@trexity/common/vehicle'
import { getImageSize, getOrientation, correctOrientation } from '@trexity/common/imaging'
import { getFormattedMonetaryValueString } from '@trexity/common/pricing'
import { getFormattedDateAndTimeFromDate, getFormattedDateFromDate, getFormattedDurationString } from '@trexity/common/temporal'
import { MAX_WORKING_TIME, MIN_BREAK_TIME } from '@trexity/common/drivers/constants'
import { DriverChallengeQualificationLogic } from '@trexity/common/driverChallenges'
import { useInterval } from '../../hooks'
import HoverPhoto from '../HoverPhoto'
import RequirementInput from '../RequirementInput'
import AddressAutoComplete from '../AddressAutoComplete'
import DriverChallengeQualificationStatusTag from '../DriverChallengeQualificationStatusTag'
import ShipmentsTable from './ShipmentsTable'
import BroadcastDisqualificationLogsTable from './BroadcastDisqualificationLogsTable'
import DriverDevicesTable from './DriverDevicesTable'

import {
  DatePicker,
  Divider,
  Button,
  Form,
  Input,
  Modal,
  Checkbox,
  Alert,
  Card,
  Upload,
  Row,
  Col,
  Icon,
  Descriptions,
  Select,
  Table
} from 'antd'

export default function Editor ({
  isAdmin,
  mode,
  deps
}) {
  const {
    send,
    current,
    data: {
      driverForm = {},
      driverLatestLocation = {},
      driverDashboardDriverId: driverId,
      merchantSearchResults,
      driverShipments,
      driverDisqualificationLogs = [],
      driverDevices = [],
      allDriverChallenges = [],
      unpairedMerchants = []
    },
    RouteLink,
    UnpairedDriverMerchantPicker = () => null,
    tc
  } = deps

  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [fullName, setFullName] = React.useState('')
  const [contactPhone, setContactPhone] = React.useState('')
  const [mailingAddress, setMailingAddress] = React.useState('')
  const [mailingAddressNotes, setMailingAddressNotes] = React.useState('')
  const [driversLicenceNumber, setDriversLicenceNumber] = React.useState('')
  const [driversLicenceExpiresAt, setDriversLicenceExpiresAt] = React.useState('')
  const [driverBase64image, setDriverBase64image] = React.useState('')
  const [driverPhotoUrl, setDriverPhotoUrl] = React.useState('')
  const [driversLicenseFrontPhotoBase64image, setDriversLicenseFrontPhotoBase64image] = React.useState('')
  const [driversLicenseFrontPhotoUrl, setDriversLicenseFrontPhotoUrl] = React.useState('')
  const [driversLicenseBackPhotoBase64image, setDriversLicenseBackPhotoBase64image] = React.useState('')
  const [driversLicenseBackPhotoUrl, setDriversLicenseBackPhotoUrl] = React.useState('')
  const [driversInsurancePhotoBase64image, setDriversInsurancePhotoBase64image] = React.useState('')
  const [driversInsurancePhotoUrl, setDriversInsurancePhotoUrl] = React.useState('')
  const [vehiclePhotoUrl, setVehiclePhotoUrl] = React.useState('')
  const [vehiclePhotoBase64image, setVehiclePhotoBase64image] = React.useState('')
  const [vehicleMake, setVehicleMake] = React.useState('')
  const [vehicleModel, setVehicleModel] = React.useState('')
  const [vehicleColor, setVehicleColor] = React.useState('')
  const [vehicleYear, setVehicleYear] = React.useState('')
  const [licencePlateNumber, setLicencePlateNumber] = React.useState('')
  const [insuranceProvider, setInsuranceProvider] = React.useState('')
  const [insuranceExpiresAt, setInsuranceExpiresAt] = React.useState('')
  const [vehicleIdNumber, setVehicleIdNumber] = React.useState('')
  const [workEligibilityPhotoBase64image, setWorkEligibilityPhotoBase64image] = React.useState('')
  const [workEligibilityPhotoUrl, setWorkEligibilityPhotoUrl] = React.useState('')
  const [workEligibilityType, setWorkEligibilityType] = React.useState('')
  const [workEligibilityExpiresAt, setWorkEligibilityExpiresAt] = React.useState('')
  const [approved, setApproved] = React.useState(false)
  const [onboarded, setOnboarded] = React.useState(false)
  const [capabilities, setCapabilities] = React.useState({})
  const [internalNotes, setInternalNotes] = React.useState('')
  const [contactPhoneVerified, setContactPhoneVerified] = React.useState(false)
  const [disabledEvents, setDisabledEvents] = React.useState([])

  React.useEffect(() => {
    setEmail(driverForm.cachedAuthEmail || '')
    setFullName(driverForm.fullName || '')
    setContactPhone(driverForm.contactPhone || '')
    setMailingAddress(driverForm.mailingAddress || '')
    setMailingAddressNotes(driverForm.mailingAddressNotes || '')
    setDriversLicenceNumber(driverForm.driversLicenceNumber || '')
    setDriversLicenceExpiresAt((driverForm.driversLicenceExpiresAt && driverForm.driversLicenceExpiresAt.toDate()) || '')
    setDriverBase64image(driverForm.driverBase64image || '')
    setDriverPhotoUrl(driverForm.driverPhotoUrl || '')
    setDriversLicenseFrontPhotoBase64image(driverForm.driversLicenseFrontPhotoBase64image || '')
    setDriversLicenseFrontPhotoUrl(driverForm.driversLicenseFrontPhotoUrl || '')
    setDriversLicenseBackPhotoBase64image(driverForm.driversLicenseBackPhotoBase64image || '')
    setDriversLicenseBackPhotoUrl(driverForm.driversLicenseBackPhotoUrl || '')
    setDriversInsurancePhotoBase64image(driverForm.driversInsurancePhotoBase64image || '')
    setDriversInsurancePhotoUrl(driverForm.driversInsurancePhotoUrl || '')
    setInsuranceExpiresAt((driverForm.insuranceExpiresAt && driverForm.insuranceExpiresAt.toDate()) || '')
    setVehiclePhotoUrl(driverForm.vehiclePhotoUrl || '')
    setVehiclePhotoBase64image(driverForm.vehiclePhotoBase64image || '')
    setVehicleMake(driverForm.vehicleMake || '')
    setVehicleModel(driverForm.vehicleModel || '')
    setVehicleColor(driverForm.vehicleColor || '')
    setVehicleYear(driverForm.vehicleYear || '')
    setVehicleIdNumber(driverForm.vehicleIdNumber || '')
    setLicencePlateNumber(driverForm.licencePlateNumber || '')
    setInsuranceProvider(driverForm.insuranceProvider || '')
    setWorkEligibilityPhotoUrl(driverForm.workEligibilityPhotoUrl || '')
    setWorkEligibilityPhotoBase64image(driverForm.workEligibilityPhotoBase64image || '')
    setWorkEligibilityExpiresAt((driverForm.workEligibilityExpiresAt && driverForm.workEligibilityExpiresAt.toDate()) || '')
    setWorkEligibilityType(driverForm.workEligibilityType || '')
    setApproved(Boolean(driverForm.approved))
    setOnboarded(Boolean(driverForm.onboarded))
    setCapabilities(driverForm.capabilities || {})
    setInternalNotes(driverForm.internalNotes || '')
    setContactPhoneVerified(Boolean(driverForm.contactPhoneVerified))
    setDisabledEvents(driverForm.disabledEvents || [])
  }, [driverForm])

  const [isUploading, setIsUploading] = React.useState(false)
  const [searchQuery, setSearchQuery] = React.useState('')
  const [logsSearchQuery, setLogsSearchQuery] = React.useState('')
  const isCreating = mode === 'create'

  const [filteredUnpairedMerchants, setFilteredUnpairedMerchants] = React.useState()

  React.useEffect(() => {
    setFilteredUnpairedMerchants(unpairedMerchants)
  }, [unpairedMerchants])

  const isStateSaving = current.within('Saving Driver')
  const isStateLoading = current.within(['Loading Driver', 'Loading Driver Challenges'])
  const isStateDeleting = current.within('Deleting Driver')
  const isStateLoadingShipments = current.within('Loading Driver Shipments')
  const isOnboardingDriver = current.within('Onboarding Driver')
  const isResettingDriverWorkingTime = current.within('Resetting Driver Working Time')
  const isTogglingDriverActive = current.within('Toggling Driver Active')
  const isAnyStateActive = isStateSaving || isStateLoading || isStateDeleting || isOnboardingDriver || isUploading || isStateLoadingShipments || isResettingDriverWorkingTime || isTogglingDriverActive

  const validationTests = [
    isCreating && {
      test: password,
      message: 'Missing Password'
    },
    {
      test: email,
      message: 'Missing Email'
    },
    {
      test: fullName,
      message: 'Missing Full Name'
    },
    {
      test: contactPhone,
      message: 'Missing Contact Phone'
    },
    {
      test: mailingAddress,
      message: 'Missing Address'
    },
    email && {
      test: validateEmail(email),
      message: 'Invalid Email'
    },
    contactPhone && {
      test: validatePhoneNumber(contactPhone),
      message: 'Invalid Phone Number'
    },
    licencePlateNumber && {
      test: validateLicensePlate(licencePlateNumber),
      message: 'Invalid License Plate Number'
    },
    vehicleYear && {
      test: validateVehicleYear(vehicleYear),
      message: 'Invalid Vehicle Year'
    }
  ].filter(Boolean)

  const validationMessages = validationTests.filter(({ test }) => !test).map(({ message }) => message)

  const inputDisabled = isAnyStateActive
  const saveDisabled = isAnyStateActive
  const saveLoading = isStateSaving
  const deleteDisabled = isAnyStateActive || driverForm.driverId
  const deleteLoading = isStateDeleting

  const filteredShipments = driverShipments.filter((shipment) => {
    const lowerCaseSearchQuery = searchQuery.toLowerCase()

    return (
      shipment.cachedMerchantName.toLowerCase().includes(lowerCaseSearchQuery) ||
      shipment.id.toLowerCase().includes(lowerCaseSearchQuery) ||
      shipment.pickupAddress.toLowerCase().includes(lowerCaseSearchQuery)
    )
  })

  const filteredBroadcastDisqualificationLogs = (driverDisqualificationLogs || [])
    .filter((log) => {
      if (!logsSearchQuery) {
        return true
      }

      const lowerCaseSearchQuery = logsSearchQuery.toLowerCase()

      return (
        log.reason.toLowerCase().includes(lowerCaseSearchQuery) ||
        log.shipmentId.toLowerCase().includes(lowerCaseSearchQuery)
      )
    })

  function onFilterUnpairedRecords (unpairedSearchQuery) {
    setFilteredUnpairedMerchants(unpairedMerchants.filter((unpairedDriverMerchant) => {
      const lowerCaseSearchQuery = unpairedSearchQuery.toLowerCase()

      return (
        unpairedDriverMerchant.merchant.name.toLowerCase().indexOf(lowerCaseSearchQuery) > -1 ||
        unpairedDriverMerchant.merchant.contactName.toLowerCase().indexOf(lowerCaseSearchQuery) > -1 ||
        unpairedDriverMerchant.merchant.contactEmail.toLowerCase().indexOf(lowerCaseSearchQuery) > -1
      )
    }))
  }

  const onSave = (evt) => {
    evt.preventDefault()

    if (validationMessages.length) {
      Modal.error({
        title: 'The following fields require your attention:',
        content: (
          <ul>{validationMessages.map((message, idx) => <li key={idx}>{message}</li>)}</ul>
        )
      })
    } else {
      send({
        type: 'save driver',
        email,
        password,

        approved,
        onboarded,
        disabledEvents,

        driverBase64image,
        driversLicenseFrontPhotoBase64image,
        driversLicenseBackPhotoBase64image,
        driversInsurancePhotoBase64image,
        vehiclePhotoBase64image,
        workEligibilityPhotoBase64image,

        driverPhotoUrl,
        driversLicenseFrontPhotoUrl,
        driversLicenseBackPhotoUrl,
        driversInsurancePhotoUrl,
        vehiclePhotoUrl,
        workEligibilityPhotoUrl,

        driverForm: {
          fullName,
          contactPhone,
          mailingAddress,
          mailingAddressNotes,

          insuranceProvider,
          insuranceExpiresAt: insuranceExpiresAt || null,

          driversLicenceNumber,
          driversLicenceExpiresAt: driversLicenceExpiresAt || null,

          workEligibilityType,
          workEligibilityExpiresAt: workEligibilityExpiresAt || null,

          vehicleMake,
          vehicleModel,
          vehicleColor,
          vehicleYear,
          vehicleIdNumber,

          licencePlateNumber,
          capabilities,
          internalNotes,
          contactPhoneVerified
        }
      })

      setPassword('')
    }
  }

  const onDelete = (evt) => {
    Modal.confirm({
      title: 'Are you sure you wish to delete this driver?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk () {
        send({ type: 'delete driver', driverId })
      }
    })
  }

  const driverAddressLngLat = driverForm.mailingAddressLocation && Object.keys(driverForm.mailingAddressLocation).length
    ? [driverForm.mailingAddressLocation.longitude, driverForm.mailingAddressLocation.latitude]
    : null

  const proximity = (
    driverAddressLngLat ||
    [-75.6971931, 45.4215296] // Ottawa default
  )

  const onChangeCapabilityValue = (key) => (value) => {
    setCapabilities({ ...capabilities, [key]: value })
  }

  // ---- Working Time computations

  const [liveNow, setLiveNow] = React.useState(Date.now())
  useInterval(() => setLiveNow(Date.now()), 60000)

  const workingTimeFormatted = Number.isSafeInteger(driverForm.workingTime)
    ? getFormattedDurationString(driverForm.workingTime / 1000)
    : 'N/A'

  const isOnForcedBreak = (
    driverForm.workingTime >= MAX_WORKING_TIME &&
    (liveNow - driverForm.workingTimeLastUpdated) < MIN_BREAK_TIME
  )

  const timeForcedBreakEnds = new Date(driverForm.workingTimeLastUpdated + MIN_BREAK_TIME)
  const breakSecondsRemaining = (timeForcedBreakEnds.getTime() - liveNow) / 1000

  const breakTimeRemainingFormatted = isOnForcedBreak ? (
    <React.Fragment>
      <Icon type='clock-circle' theme='twoTone' twoToneColor='#efe023' style={{ marginRight: 10 }} />
      {getFormattedDurationString(breakSecondsRemaining)}
    </React.Fragment>
  ) : (
    <React.Fragment>
      <Icon type='check-circle' theme='twoTone' twoToneColor='#52c41a' style={{ marginRight: 10 }} />
      {getFormattedDurationString(0)}
    </React.Fragment>
  )

  return (
    <Form
      {...formItemLayout}
      onSubmit={onSave}
      labelCol={{ md: { span: 24 }, lg: { span: 5 } }}
      wrapperCol={{ md: { span: 24 }, lg: { span: 19 } }}
    >
      <Row gutter={16}>
        <Col lg={24} xl={12}>
          <Form.Item label='Auth Email' required>
            <Input
              value={email}
              disabled={inputDisabled}
              onChange={(evt) => setEmail(evt.target.value)}
            />
          </Form.Item>
          <Form.Item label='Password' required={isCreating}>
            <Input
              type='password'
              value={password}
              disabled={inputDisabled}
              onChange={(evt) => setPassword(evt.target.value)}
            />
          </Form.Item>

          <Divider />

          <Form.Item label='Full Name' required>
            <Input
              value={fullName}
              disabled={inputDisabled}
              onChange={(evt) => setFullName(evt.target.value)}
            />
          </Form.Item>

          <Form.Item label='Contact Number' required>
            <Input
              placeholder='+1 xxx xxx xxxx'
              value={contactPhone}
              disabled={inputDisabled}
              onChange={(evt) => setContactPhone(formatPhoneNumber(evt.target.value))}
            />
          </Form.Item>

          <Form.Item label={'Number Verified'}>
            <Checkbox
              checked={Boolean(contactPhoneVerified)}
              disabled={inputDisabled}
              onChange={(evt) => setContactPhoneVerified(evt.target.checked)}
            >
              Confirmation that this number belongs to the driver
            </Checkbox>
          </Form.Item>

          <Form.Item label='Address' required>
            <AddressAutoComplete
              value={mailingAddress}
              onChange={setMailingAddress}
              disabled={inputDisabled}
              deps={{ tc }}
            />
            <Input.TextArea
              value={mailingAddressNotes}
              rows={1}
              disabled={inputDisabled}
              placeholder='Buzzer'
              onChange={(evt) => setMailingAddressNotes(evt.target.value)}
            />
          </Form.Item>

          <Divider />

          <Form.Item label='Internal Notes' help='Free-form notes concerning this driver that only admins can see' required>
            <Input.TextArea
              value={internalNotes}
              disabled={inputDisabled}
              rows={6}
              onChange={(evt) => setInternalNotes(evt.target.value)}
            />
          </Form.Item>

          <Card
            title='Suspensions'
            type='inner'
            size='small'
            style={{ marginBottom: 20 }}
            extra={(
              <Button
                disabled={isAnyStateActive}
                icon='plus'
                size='small'
                onClick={() => setDisabledEvents([...disabledEvents, { created: null, expires: null, reason: '' }])}
              >Add Suspension</Button>
            )}
          >
            <div style={{ overflowX: 'auto' }}>
              <table border={1} cellPadding={2} style={{ borderColor: '#aaa', width: '100%' }}>
                <thead>
                  <tr>
                    <th>Created</th>
                    <th>Expiry</th>
                    <th>Reason</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {disabledEvents.length ? (
                    disabledEvents.map((event, idx) => {
                      const editMode = !event.created
                      const created = (event.created || new Date())
                      const expires = (event.expires || new Date())
                      const createdDate = new Date(created)
                      const expiresDate = new Date(expires)

                      function updateProp (key, value) {
                        const updatedDisabledEvents = [...disabledEvents]

                        updatedDisabledEvents[idx] = {
                          ...updatedDisabledEvents[idx],
                          [key]: value
                        }

                        setDisabledEvents(updatedDisabledEvents)
                      }

                      return (
                        <tr key={idx}>
                          <td>{getFormattedDateAndTimeFromDate(createdDate)}</td>
                          <td>
                            {editMode ? (
                              <DatePicker
                                className='ant-input'
                                disabled={inputDisabled}
                                value={moment(expiresDate)}
                                onChange={(date) => updateProp('expires', (date && date.toDate()) || null)}
                                disabledDate={(current) => {
                                  // Can not select days in the past
                                  return current && moment().isAfter(current, 'day')
                                }}
                              />
                            ) : (
                              getFormattedDateFromDate(expiresDate)
                            )}
                          </td>
                          <td>
                            {editMode ? (
                              <Input.TextArea
                                value={event.reason || ''}
                                disabled={inputDisabled}
                                autoSize
                                onChange={(evt) => updateProp('reason', evt.target.value)}
                              />
                            ) : (
                              (event.reason || <em>No reason given.</em>)
                            )}
                          </td>
                          <td width={1}>
                            <Button
                              type='danger'
                              size='small'
                              onClick={() => setDisabledEvents(disabledEvents.filter((_, index) => index !== idx))}
                            >X</Button>
                          </td>
                        </tr>
                      )
                    })
                  ) : (
                    <tr>
                      <td colSpan={4} style={{ textAlign: 'center' }}><em>No suspensions.</em></td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </Card>

          <Form.Item label='Approved'>
            <Checkbox
              checked={Boolean(approved)}
              disabled={inputDisabled}
              onChange={(evt) => setApproved(evt.target.checked)}
            >
              {approved && driverForm.approvedAt ? (
                <span>Last approved on <strong>{getFormattedDateAndTimeFromDate(driverForm.approvedAt.toDate())}</strong></span>
              ) : null}
            </Checkbox>
          </Form.Item>
          <Form.Item label='Onboarded'>
            <Checkbox
              checked={Boolean(onboarded)}
              disabled={inputDisabled}
              onChange={(evt) => setOnboarded(evt.target.checked)}
            >
              {onboarded && driverForm.onboardedAt ? (
                <span>Last onboarded on <strong>{getFormattedDateAndTimeFromDate(driverForm.onboardedAt.toDate())}</strong></span>
              ) : null}
            </Checkbox>
          </Form.Item>
        </Col>
        <Col lg={24} xl={12}>
          <div style={{ marginBottom: 20 }}>
            <Card
              title='Driver Shipments'
              type='inner'
              size='small'
              style={{ marginBottom: 10 }}
              extra={(
                <Button
                  disabled={isAnyStateActive}
                  loading={isStateLoadingShipments}
                  icon='reload'
                  size='small'
                  onClick={() => send('reload driver shipments')}
                />
              )}
            >
              {isCreating ? (
                <p>N/A</p>
              ) : (
                <React.Fragment>
                  <Input.Search
                    placeholder='Search…'
                    defaultValue={searchQuery}
                    onChange={(evt) => setSearchQuery(evt.target.value)}
                    style={{ marginBottom: 10 }}
                  />
                  <ShipmentsTable
                    shipments={filteredShipments}
                    driver={driverForm}
                    onClickMerchantName={(merchantId) => send({ type: 'route edit merchant', id: merchantId })}
                    displayDropOffsColumn={true}
                    loading={isStateLoading}
                    deps={{ RouteLink }}
                  />
                </React.Fragment>
              )}
            </Card>
            {driverId ? (
              <Descriptions bordered column={1} size='small' style={{ marginBottom: 20 }}>
                <Descriptions.Item label='Active'>
                  {driverForm.active ? (
                    <Icon type='check-circle' theme='twoTone' twoToneColor='#52c41a' />
                  ) : (
                    <Icon type='close-circle' theme='twoTone' twoToneColor='red' />
                  )}
                  <Button
                    size='small'
                    disabled={inputDisabled}
                    loading={isTogglingDriverActive}
                    type='primary'
                    style={{ marginLeft: 10 }}
                    onClick={() => {
                      send({ type: 'toggle driver active', driverId, currentActive: driverForm.active })
                    }}
                  >
                    Toggle
                  </Button>
                </Descriptions.Item>
                <Descriptions.Item label='Working Time'>
                  {workingTimeFormatted}
                  <Button
                    size='small'
                    disabled={inputDisabled}
                    loading={isResettingDriverWorkingTime}
                    type='primary'
                    style={{ marginLeft: 10 }}
                    onClick={() => {
                      if (confirm('Are you sure you want to reset the working time of this driver?')) {
                        send({ type: 'reset driver working time', driverId })
                      }
                    }}
                  >
                    Reset
                  </Button>
                  <Button
                    disabled={isAnyStateActive}
                    loading={isStateLoadingShipments}
                    icon='reload'
                    size='small'
                    style={{ float: 'right' }}
                    onClick={() => send('reload driver')}
                  />
                </Descriptions.Item>
                <Descriptions.Item label='Break Remaining'>
                  {breakTimeRemainingFormatted}
                </Descriptions.Item>
                <Descriptions.Item label='Last Known Location'>
                  {driverLatestLocation.receivedAt ? (
                    <React.Fragment>
                      <div>
                        <strong>Lng/Lat: </strong>
                        <a
                          href={`https://www.google.com/maps/search/?api=1&query=${driverLatestLocation.location.latitude},${driverLatestLocation.location.longitude}`}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          {driverLatestLocation.location.longitude}, {driverLatestLocation.location.latitude}
                        </a>
                      </div>
                      <div><strong>Received: </strong>{getFormattedDateAndTimeFromDate(driverLatestLocation.receivedAt.toDate())}</div>
                    </React.Fragment>
                  ) : null}
                </Descriptions.Item>
                {driverForm.stripeId ? (
                  <Descriptions.Item label='Stripe Account'>
                    <React.Fragment>
                      <div>
                        <strong>Account: </strong>
                        <a
                          href={`https://dashboard.stripe.com/connect/accounts/${driverForm.stripeId}`}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          {driverForm.stripeId}
                        </a>
                      </div>
                      <div><strong>Status: </strong>{driverForm.stripeStatus}</div>
                    </React.Fragment>
                  </Descriptions.Item>
                ) : null}
                <Descriptions.Item label='Lifetime Counts'>
                  <React.Fragment>
                    <div><strong>Deliveries: </strong>{driverForm.lifetimeDeliveryCount || 0}</div>
                    <div><strong>Routes: </strong>{driverForm.lifetimeShipmentsCount || 0}</div>
                  </React.Fragment>
                </Descriptions.Item>
                <Descriptions.Item label='Referrals'>
                  <React.Fragment>
                    <div><strong>Referral Code: </strong><code>{driverForm.referralCode}</code></div>
                    <div><strong>Referred By Code: </strong>{driverForm.referredByCode ? <code>{driverForm.referredByCode}</code> : 'N/A'}</div>
                  </React.Fragment>
                </Descriptions.Item>
              </Descriptions>
            ) : null}

            <div style={{ marginBottom: 20 }}>
              <Card
                title='Broadcast Disqualification Logs'
                type='inner'
                size='small'
                style={{ marginBottom: 10 }}
                extra={(
                  <Button
                    disabled={isAnyStateActive}
                    loading={isStateLoading}
                    icon='reload'
                    size='small'
                    onClick={() => send('reload driver')}
                  />
                )}
              >
                {isCreating ? (
                  <p>N/A</p>
                ) : (
                  <React.Fragment>
                    <Input.Search
                      placeholder='Search…'
                      defaultValue={logsSearchQuery}
                      onChange={(evt) => setLogsSearchQuery(evt.target.value)}
                      style={{ marginBottom: 10 }}
                    />
                    <BroadcastDisqualificationLogsTable
                      logs={filteredBroadcastDisqualificationLogs}
                      loading={isStateLoading}
                      deps={{ RouteLink }}
                    />
                  </React.Fragment>
                )}
              </Card>
            </div>

            {isAdmin ? (
              <div style={{ marginBottom: 20 }}>
                <UnpairedDriverMerchantPicker
                  title='Unpaired Merchants'
                  isAnyStateActive={isAnyStateActive}
                  isStateLoading={isStateLoading}
                  onNewPairingClick={() => send({ type: 'new driver unpairing', driverId })}
                  onReloadClick={() => send('reload unpaired merchants')}
                  unpairedRecords={filteredUnpairedMerchants || []}
                  totalUnpairedRecords={unpairedMerchants ? unpairedMerchants.length : null}
                  onSearchChange={onFilterUnpairedRecords}
                  onRecordDetailsClick={(id) => send({ type: 'edit unpaired merchant', unpairedId: id })}
                  onRecordPairClick={(id) => send({ type: 'pair back merchant', unpairIdToCancel: id })}
                  enableEditor={current.within('View Unpaired Merchant')}
                  displayMerchantTable={false}
                />
              </div>
            ) : null}

            <div style={{ marginBottom: 20 }}>
              <Card
                title='Driver Devices'
                type='inner'
                size='small'
                style={{ marginBottom: 10 }}
              >
                <DriverDevicesTable
                  devices={driverDevices}
                  loading={isStateLoading}
                />
              </Card>
            </div>
          </div>
        </Col>
      </Row>

      <Card
        title='Challenges'
        type='inner'
        size='small'
        style={{ marginBottom: 10 }}
      >
        <Table
          style={{
            backgroundColor: '#ffffff'
          }}
          size='small'
          dataSource={allDriverChallenges}
          scroll={{ x: true }}
          bordered={false}
          loading={isStateLoading}
        >
          <Table.Column
            title='Program Name'
            dataIndex='name'
            key='name'
            render={(text, record, index) => {
              return (
                <div>
                  <RouteLink
                    routeKey='Driver Challenge Details'
                    params={{ id: record.driverChallengeId }}
                  >
                    {record.name}
                  </RouteLink>
                </div>
              )
            }}
          />
          <Table.Column
            title='Bonus'
            dataIndex='bonus'
            key='incentive'
            render={(text, record, index) => {
              return (<div>{getFormattedMonetaryValueString(record.incentive)}</div>)
            }}
          />
          <Table.Column
            title='Ending'
            dataIndex='period'
            key='period'
            render={(_, record, index) => {
              return <div>{getFormattedDateFromDate(new Date(record.validToAt))}</div>
            }}
          />
          <Table.Column
            title='Progress'
            dataIndex='progress'
            key='progress'
            render={(_, record, index) => {
              return <div>{record.numberOfCompletedDeliveries} / {record.requiredNumberOfDeliveries} deliveries</div>
            }}
          />
          <Table.Column
            title='Earned on'
            dataIndex='earnedOn'
            key='earnedOn'
            render={(_, record, index) => {
              return <div>{record.earnedAt ? getFormattedDateAndTimeFromDate(new Date(record.earnedAt)) : 'Not yet'}</div>
            }}
          />
          <Table.Column
            title='Status'
            dataIndex='status'
            key='status'
            render={(text, record, index) => {
              const d = new DriverChallengeQualificationLogic(record)
              return <DriverChallengeQualificationStatusTag status={d.getStatus()} />
            }}
          />
        </Table>
      </Card>

      <Card
        title={isCreating ? 'Driver Documents' : 'Driver Documents and Photos'}
        type='inner'
        size='small'
        style={{ marginBottom: 20 }}
      >
        {isCreating ? null : (
          <React.Fragment>
            <Form.Item label='Driver Photo Upload'>
              <Row gutter={16}>
                <Col lg={24} xl={8}>
                  <Upload
                    accept='image/*'
                    disabled={inputDisabled || isUploading}
                    showUploadList={false}
                    style={{ marginBottom: 11 }}
                    beforeUpload={handleImageUpload(
                    (driverBase64image) => setDriverBase64image(driverBase64image),
                    () => setIsUploading(true),
                    () => setIsUploading(false)
                  )}
                  >
                    <Button
                      disabled={inputDisabled || isUploading}
                      type='primary'
                      icon='upload'
                      block
                      style={{ marginBottom: 10 }}
                    >
                      Upload Driver Photo
                    </Button>
                  </Upload>
                  {driverBase64image || driverPhotoUrl ? (
                    <div>
                      <HoverPhoto
                        title='Driver Photo Preview'
                        photoSrc={driverBase64image || driverPhotoUrl}
                        thumbnailSize={100}
                      />
                      <Button
                        style={{ display: 'block', marginTop: 10 }}
                        size='small'
                        onClick={() => {
                        setDriverBase64image('')
                        setDriverPhotoUrl('')
                      }}
                      >Clear Photo</Button>
                      {driverPhotoUrl ? (
                        <Button
                          type='danger'
                          style={{ display: 'block', marginTop: 10 }}
                          size='small'
                          onClick={() => {
                            if (confirm('This will remove the photo and send an e-mail to the driver. Continue?')) {
                              send({ type: 'reject driver photo', driverId })
                            }
                          }}
                        >Reject Photo</Button>
                      ) : null}
                    </div>
                  ) : null}
                  {isStateLoading ? null : (
                    driverBase64image || driverPhotoUrl ? null : (
                      <Alert
                        message='Missing Driver Photo'
                        type='warning'
                        showIcon
                        style={{ marginTop: 10 }}
                      />
                    )
                  )}
                </Col>
              </Row>
            </Form.Item>

            <Divider />

            <Form.Item label='Driver’s License Number'>
              <Input
                value={driversLicenceNumber}
                disabled={inputDisabled}
                onChange={(evt) => setDriversLicenceNumber(evt.target.value)}
              />
            </Form.Item>

            <Form.Item label='Driver’s License Expiration Date'>
              <DatePicker
                className='ant-input'
                allowClear
                placeholder='Select License Expiration Date'
                disabled={inputDisabled}
                value={(driversLicenceExpiresAt && moment(driversLicenceExpiresAt)) || null}
                onChange={(date) => {
                  setDriversLicenceExpiresAt(date.toDate() || null)
                }}
                disabledDate={(current) => {
                  // Can not select days in the past
                  return current && moment().isAfter(current, 'day')
                }}
                style={{ marginRight: 5 }}
              />
            </Form.Item>

            <Form.Item label='License Photos Upload'>
              <Row gutter={16}>
                <Col lg={24} xl={8}>
                  <Upload
                    accept='image/*'
                    disabled={inputDisabled || isUploading}
                    showUploadList={false}
                    beforeUpload={handleImageUpload(
                    (driversLicenseFrontPhotoBase64image) => setDriversLicenseFrontPhotoBase64image(driversLicenseFrontPhotoBase64image),
                    () => setIsUploading(true),
                    () => setIsUploading(false)
                  )}
                  >
                    <Button
                      disabled={inputDisabled || isUploading}
                      type='primary'
                      icon='upload'
                      block
                      style={{ marginBottom: 10 }}
                    >
                      Upload License Photo (front)
                    </Button>
                  </Upload>
                  {driversLicenseFrontPhotoBase64image || driversLicenseFrontPhotoUrl ? (
                    <div>
                      <HoverPhoto
                        title='Drivers License (Front) Photo Preview'
                        photoSrc={driversLicenseFrontPhotoBase64image || driversLicenseFrontPhotoUrl}
                        thumbnailSize={100}
                      />
                      <Button
                        style={{ display: 'block', marginTop: 10 }}
                        size='small'
                        onClick={() => {
                        setDriversLicenseFrontPhotoBase64image('')
                        setDriversLicenseFrontPhotoUrl('')
                      }}
                      >Clear Photo</Button>
                    </div>
                ) : null}
                  {driversLicenseFrontPhotoUrl ? (
                    <Button
                      type='danger'
                      style={{ display: 'block', marginTop: 10 }}
                      size='small'
                      onClick={() => {
                        if (confirm('This will remove the photo and send an e-mail to the driver. Continue?')) {
                          send({ type: 'reject license front photo', driverId })
                        }
                      }}
                    >Reject Photo</Button>
                  ) : null}
                  {isStateLoading ? null : (
                  (
                    driversLicenseFrontPhotoBase64image ||
                    driversLicenseFrontPhotoUrl
                  ) ? null : (
                    <Alert
                      message='Missing License (front) Photo'
                      type='warning'
                      showIcon
                      style={{ marginTop: 10 }}
                    />
                  )
                )}
                </Col>
                <Col lg={24} xl={8}>
                  <Upload
                    accept='image/*'
                    disabled={inputDisabled || isUploading}
                    showUploadList={false}
                    beforeUpload={handleImageUpload(
                    (driversLicenseBackPhotoBase64image) => setDriversLicenseBackPhotoBase64image(driversLicenseBackPhotoBase64image),
                    () => setIsUploading(true),
                    () => setIsUploading(false)
                  )}
                  >
                    <Button
                      disabled={inputDisabled || isUploading}
                      type='primary'
                      icon='upload'
                      block
                      style={{ marginBottom: 10 }}
                    >
                      Upload License Photo (back)
                    </Button>
                  </Upload>
                  {driversLicenseBackPhotoBase64image || driversLicenseBackPhotoUrl ? (
                    <div>
                      <HoverPhoto
                        title='Drivers License (Back) Photo Preview'
                        photoSrc={driversLicenseBackPhotoBase64image || driversLicenseBackPhotoUrl}
                        thumbnailSize={100}
                      />
                      <Button
                        style={{ display: 'block', marginTop: 10 }}
                        size='small'
                        onClick={() => {
                        setDriversLicenseBackPhotoBase64image('')
                        setDriversLicenseBackPhotoUrl('')
                      }}
                      >Clear Photo</Button>
                      {driversLicenseBackPhotoUrl ? (
                        <Button
                          type='danger'
                          style={{ display: 'block', marginTop: 10 }}
                          size='small'
                          onClick={() => {
                            if (confirm('This will remove the photo and send an e-mail to the driver. Continue?')) {
                              send({ type: 'reject license back photo', driverId })
                            }
                          }}
                        >Reject Photo</Button>
                      ) : null}
                    </div>
                ) : null}
                  {isStateLoading ? null : (
                  (
                    driversLicenseBackPhotoBase64image ||
                    driversLicenseBackPhotoUrl
                  ) ? null : (
                    <Alert
                      message='Missing License (back) Photo'
                      type='warning'
                      showIcon
                      style={{ marginTop: 10 }}
                    />
                  )
                )}
                </Col>
              </Row>
            </Form.Item>

            <Divider />

            <Form.Item label='Insurance Provider'>
              <Input
                value={insuranceProvider}
                disabled={inputDisabled}
                onChange={(evt) => setInsuranceProvider(evt.target.value)}
              />
            </Form.Item>

            <Form.Item label='Insurance Expiration Date'>
              <DatePicker
                className='ant-input'
                allowClear
                placeholder='Select Insurance Expiration Date'
                disabled={inputDisabled}
                value={(insuranceExpiresAt && moment(insuranceExpiresAt)) || null}
                onChange={(date) => {
                  setInsuranceExpiresAt(date.toDate() || null)
                }}
                disabledDate={(current) => {
                  // Can not select days in the past
                  return current && moment().isAfter(current, 'day')
                }}
                style={{ marginRight: 5 }}
              />
            </Form.Item>

            <Form.Item label='Insurance Photo Upload'>
              <Row gutter={16}>
                <Col lg={24} xl={8}>
                  <Upload
                    accept='image/*'
                    disabled={inputDisabled || isUploading}
                    showUploadList={false}
                    beforeUpload={handleImageUpload(
                    (driversInsurancePhotoBase64image) => setDriversInsurancePhotoBase64image(driversInsurancePhotoBase64image),
                    () => setIsUploading(true),
                    () => setIsUploading(false)
                  )}
                  >
                    <Button
                      disabled={inputDisabled || isUploading}
                      type='primary'
                      icon='upload'
                      block
                      style={{ marginBottom: 10 }}
                    >
                      Upload Insurance Photo
                    </Button>
                  </Upload>
                  {driversInsurancePhotoBase64image || driversInsurancePhotoUrl ? (
                    <div>
                      <HoverPhoto
                        title='Drivers Insurance Photo Preview'
                        photoSrc={driversInsurancePhotoBase64image || driversInsurancePhotoUrl}
                        thumbnailSize={100}
                      />
                      <Button
                        style={{ display: 'block', marginTop: 10 }}
                        size='small'
                        onClick={() => {
                        setDriversInsurancePhotoUrl('')
                        setDriversInsurancePhotoBase64image('')
                      }}
                      >Clear Photo</Button>
                      {driversInsurancePhotoUrl ? (
                        <Button
                          type='danger'
                          style={{ display: 'block', marginTop: 10 }}
                          size='small'
                          onClick={() => {
                            if (confirm('This will remove the photo and send an e-mail to the driver. Continue?')) {
                              send({ type: 'reject driver insurance photo', driverId })
                            }
                          }}
                        >Reject Photo</Button>
                      ) : null}
                    </div>
                  ) : null}
                  {isStateLoading ? null : (
                    (
                      driversInsurancePhotoBase64image ||
                      driversInsurancePhotoUrl
                    ) ? null : (
                      <Alert
                        message='Missing Insurance Photo'
                        type='warning'
                        showIcon
                        style={{ marginTop: 10 }}
                      />
                    )
                  )}
                </Col>
              </Row>
            </Form.Item>

            <Divider />

            <Form.Item label='Work Eligibility Type'>
              <Select
                disabled={inputDisabled}
                value={workEligibilityType}
                onChange={(selected) => setWorkEligibilityType(selected)}
              >
                <Select.Option value=''>Unknown</Select.Option>
                {/* <Select.Option value='ca-sin'>Canadian Social Insurance Number</Select.Option> */}
                <Select.Option value='ca-birth'>Canadian birth certificate</Select.Option>
                <Select.Option value='ca-passport'>Canadian passport (photo page)</Select.Option>
                <Select.Option value='ca-resident'>Canadian Permanent Resident Card</Select.Option>
                <Select.Option value='ca-work-student'>Canadian Work/Student Permit</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item label='Work Eligibility Expiration Date'>
              <DatePicker
                className='ant-input'
                allowClear
                placeholder='Select Work Eligibility Expiration Date'
                disabled={inputDisabled}
                value={(workEligibilityExpiresAt && moment(workEligibilityExpiresAt)) || null}
                onChange={(date) => {
                  setWorkEligibilityExpiresAt(date.toDate() || null)
                }}
                disabledDate={(current) => {
                  // Can not select days in the past
                  return current && moment().isAfter(current, 'day')
                }}
                style={{ marginRight: 5 }}
              />
            </Form.Item>

            <Form.Item label='Work Eligibility Photo Upload'>
              <Row gutter={16}>
                <Col lg={24} xl={8}>
                  <Upload
                    accept='image/*'
                    disabled={inputDisabled || isUploading}
                    showUploadList={false}
                    beforeUpload={handleImageUpload(
                    (base64) => setWorkEligibilityPhotoBase64image(base64),
                    () => setIsUploading(true),
                    () => setIsUploading(false)
                  )}
                  >
                    <Button
                      disabled={inputDisabled || isUploading}
                      type='primary'
                      icon='upload'
                      block
                      style={{ marginBottom: 10 }}
                    >
                      Upload Work Eligibility Photo
                    </Button>
                  </Upload>
                  {workEligibilityPhotoBase64image || workEligibilityPhotoUrl ? (
                    <div>
                      <HoverPhoto
                        title='Work Permit Photo Preview'
                        photoSrc={workEligibilityPhotoBase64image || workEligibilityPhotoUrl}
                        thumbnailSize={100}
                      />
                      <Button
                        style={{ display: 'block', marginTop: 10 }}
                        size='small'
                        onClick={() => {
                        setWorkEligibilityPhotoUrl('')
                        setWorkEligibilityPhotoBase64image('')
                      }}
                      >Clear Photo</Button>
                      {workEligibilityPhotoUrl ? (
                        <Button
                          type='danger'
                          style={{ display: 'block', marginTop: 10 }}
                          size='small'
                          onClick={() => {
                            if (confirm('This will remove the photo and send an e-mail to the driver. Continue?')) {
                              send({ type: 'reject work eligibility photo', driverId })
                            }
                          }}
                        >Reject Photo</Button>
                      ) : null}
                    </div>
                ) : null}
                  {isStateLoading ? null : (
                  (
                    workEligibilityPhotoBase64image ||
                    workEligibilityPhotoUrl
                  ) ? null : (
                    <Alert
                      message='Missing Work Eligibility Photo'
                      type='warning'
                      showIcon
                      style={{ marginTop: 10 }}
                    />
                  )
                )}
                </Col>
              </Row>
            </Form.Item>
          </React.Fragment>
        )}
      </Card>

      <Card
        title='Vehicle Information'
        type='inner'
        size='small'
        style={{ marginBottom: 20 }}
      >
        <Form.Item label='Vehicle Make'>
          <Input
            value={vehicleMake}
            disabled={inputDisabled}
            onChange={(evt) => setVehicleMake(evt.target.value)}
          />
        </Form.Item>
        <Form.Item label='Vehicle Model'>
          <Input
            value={vehicleModel}
            disabled={inputDisabled}
            onChange={(evt) => setVehicleModel(evt.target.value)}
          />
        </Form.Item>
        <Form.Item label='Vehicle Color'>
          <Input
            value={vehicleColor}
            disabled={inputDisabled}
            onChange={(evt) => setVehicleColor(evt.target.value)}
          />
        </Form.Item>
        <Form.Item label='Vehicle Year'>
          <Input
            type='number'
            min={1900}
            max={(new Date()).getFullYear() + 1}
            value={Number(vehicleYear)}
            disabled={inputDisabled}
            onChange={(evt) => setVehicleYear(String(evt.target.value))}
          />
        </Form.Item>
        <Form.Item label='License Plate Number'>
          <Input
            value={licencePlateNumber}
            disabled={inputDisabled}
            onChange={(evt) => setLicencePlateNumber(evt.target.value)}
          />
        </Form.Item>
        <Form.Item label='Vehicle ID Number (VIN)'>
          <Input
            value={vehicleIdNumber}
            disabled={inputDisabled}
            onChange={(evt) => setVehicleIdNumber(evt.target.value)}
          />
        </Form.Item>
        <Form.Item label='Rear Vehicle Photo Upload'>
          <Row gutter={16}>
            <Col lg={24} xl={8}>
              <Upload
                accept='image/*'
                disabled={inputDisabled || isUploading}
                showUploadList={false}
                beforeUpload={handleImageUpload(
                    (vehiclePhotoBase64image) => setVehiclePhotoBase64image(vehiclePhotoBase64image),
                    () => setIsUploading(true),
                    () => setIsUploading(false)
                  )}
              >
                <Button
                  disabled={inputDisabled || isUploading}
                  type='primary'
                  icon='upload'
                  block
                  style={{ marginBottom: 10 }}
                >
                  Upload Rear Vehicle Photo
                </Button>
              </Upload>
              {vehiclePhotoBase64image || vehiclePhotoUrl ? (
                <div>
                  <HoverPhoto
                    title='Rear Vehicle Photo Preview'
                    photoSrc={vehiclePhotoBase64image || vehiclePhotoUrl}
                    thumbnailSize={100}
                  />
                  <Button
                    style={{ display: 'block', marginTop: 10 }}
                    size='small'
                    onClick={() => {
                        setVehiclePhotoUrl('')
                        setVehiclePhotoBase64image('')
                      }}
                  >Clear Photo</Button>
                  {vehiclePhotoUrl ? (
                    <Button
                      type='danger'
                      style={{ display: 'block', marginTop: 10 }}
                      size='small'
                      onClick={() => {
                        if (confirm('This will remove the photo and send an e-mail to the driver. Continue?')) {
                          send({ type: 'reject vehicle photo', driverId })
                        }
                      }}
                    >Reject Photo</Button>
                  ) : null}
                </div>
                ) : null}
              {isStateLoading ? null : (
                (
                  vehiclePhotoBase64image ||
                  vehiclePhotoUrl
                ) ? null : (
                  <Alert
                    message='Missing Rear Vehicle Photo'
                    type='warning'
                    showIcon
                    style={{ marginTop: 10 }}
                  />
                )
              )}
            </Col>
          </Row>
        </Form.Item>
      </Card>

      <Card type='inner' title='Capabilities' style={{ marginBottom: 20 }}>
        <Form.Item label={CAPABILITIES.vehicleType.displayName}>
          <RequirementInput
            requirement='vehicleType'
            value={capabilities.vehicleType}
            disabled={inputDisabled}
            onChange={onChangeCapabilityValue('vehicleType')}
          />
        </Form.Item>

        <Form.Item label={CAPABILITIES.specificMerchantId.displayName}>
          <RequirementInput
            requirement='specificMerchantId'
            value={capabilities.specificMerchantId}
            disabled={inputDisabled}
            onChange={(specificMerchantId) => {
              if (specificMerchantId === '@any' || !specificMerchantId) {
                specificMerchantId = null
              }

              onChangeCapabilityValue('specificMerchantId')(specificMerchantId)
            }}
            meta={{
              merchantList: merchantSearchResults,
              onSearch: (namePrefix) => send({ type: 'search merchants', namePrefix })
            }}
          />
        </Form.Item>

        <Form.Item label='Personal'>
          <RequirementInput
            requirement='legalAgeAlcohol'
            value={capabilities.legalAgeAlcohol}
            disabled={inputDisabled}
            onChange={onChangeCapabilityValue('legalAgeAlcohol')}
            meta={{ label: CAPABILITIES.legalAgeAlcohol.displayName }}
          />

          <RequirementInput
            requirement='perishable'
            value={capabilities.perishable}
            disabled={inputDisabled}
            onChange={onChangeCapabilityValue('perishable')}
            meta={{ label: CAPABILITIES.perishable.displayName }}
          />

          <RequirementInput
            requirement='returnReusables'
            value={capabilities.returnReusables}
            disabled={inputDisabled}
            onChange={onChangeCapabilityValue('returnReusables')}
            meta={{ label: CAPABILITIES.returnReusables.displayName }}
          />
        </Form.Item>

        <Form.Item label='Advanced'>
          <RequirementInput
            requirement='internalTrexityStaff'
            value={capabilities.internalTrexityStaff}
            disabled={inputDisabled}
            onChange={onChangeCapabilityValue('internalTrexityStaff')}
            meta={{ label: CAPABILITIES.internalTrexityStaff.displayName }}
          />
          <br />
          <RequirementInput
            requirement='internalTrexityDriverSupport'
            value={capabilities.internalTrexityDriverSupport}
            disabled={inputDisabled}
            onChange={onChangeCapabilityValue('internalTrexityDriverSupport')}
            meta={{ label: CAPABILITIES.internalTrexityDriverSupport.displayName }}
          />
          <br />
          <RequirementInput
            requirement='chargeless'
            value={capabilities.chargeless}
            disabled={inputDisabled}
            onChange={onChangeCapabilityValue('chargeless')}
            meta={{ label: CAPABILITIES.chargeless.displayName }}
          />
        </Form.Item>
      </Card>

      <Form.Item {...tailFormItemLayout}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button.Group>
            <Button
              size='large'
              disabled={saveDisabled}
              loading={saveLoading}
              type='primary'
              htmlType='submit'
            >
              Save
            </Button>
          </Button.Group>
          {!isCreating && (
            <Button
              size='large'
              disabled={deleteDisabled}
              loading={deleteLoading}
              type='danger'
              htmlType='button'
              onClick={onDelete}
            >
              Delete
            </Button>
          )}
        </div>
      </Form.Item>
    </Form>
  )
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 5 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 19 }
  }
}

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0
    },
    sm: {
      span: 20,
      offset: 4
    }
  }
}

function handleImageUpload (onPhoto, onUploadStart, onUploadEnd) {
  return (file) => {
    onUploadStart()

    const reader = new FileReader()

    reader.readAsDataURL(file)

    reader.addEventListener('load', async () => {
      const base64image = reader.result
      const size = await getImageSize(base64image)
      const orientation = await getOrientation(file)
      const correctedBase64image = await correctOrientation(base64image, orientation, size, 800)

      onUploadEnd()
      onPhoto(correctedBase64image)
    })

    reader.addEventListener('error', (err) => {
      console.error('[handleImageUpload]', err)
      onUploadEnd()
    })

    return false
  }
}
