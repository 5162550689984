module.exports = [
  { color: '#5C0A27', text: 'light' },
  { color: '#D82536', text: 'light' },
  { color: '#1A161E', text: 'light' },
  { color: '#DB812A', text: 'dark' },
  { color: '#DC446B', text: 'dark' },
  { color: '#5D2970', text: 'light' },
  { color: '#FAE817', text: 'dark' },
  { color: '#E69C92', text: 'dark' },
  { color: '#222734', text: 'light' },
  { color: '#C0815C', text: 'dark' },
  { color: '#30293A', text: 'light' },
  { color: '#A74A53', text: 'light' },
  { color: '#609388', text: 'dark' },
  { color: '#0EA2D6', text: 'dark' },
  { color: '#F15319', text: 'dark' },
  { color: '#1A961D', text: 'dark' },
  { color: '#1775EB', text: 'dark' },
  { color: '#A46830', text: 'dark' },
  { color: '#D55949', text: 'dark' },
  { color: '#F57D46', text: 'dark' },
  { color: '#E8F6F7', text: 'dark' },
  { color: '#C74F4C', text: 'dark' },
  { color: '#C58D19', text: 'dark' },
  { color: '#433137', text: 'light' },
  { color: '#475D57', text: 'light' },
  { color: '#292B2C', text: 'light' },
  { color: '#C6101F', text: 'light' },
  // This is just duplicated twice from above this line. To support up to 52 groups
  { color: '#5C0A27', text: 'light' },
  { color: '#D82536', text: 'light' },
  { color: '#1A161E', text: 'light' },
  { color: '#DB812A', text: 'dark' },
  { color: '#DC446B', text: 'dark' },
  { color: '#5D2970', text: 'light' },
  { color: '#FAE817', text: 'dark' },
  { color: '#E69C92', text: 'dark' },
  { color: '#222734', text: 'light' },
  { color: '#C0815C', text: 'dark' },
  { color: '#30293A', text: 'light' },
  { color: '#A74A53', text: 'light' },
  { color: '#609388', text: 'dark' },
  { color: '#0EA2D6', text: 'dark' },
  { color: '#F15319', text: 'dark' },
  { color: '#1A961D', text: 'dark' },
  { color: '#1775EB', text: 'dark' },
  { color: '#A46830', text: 'dark' },
  { color: '#D55949', text: 'dark' },
  { color: '#F57D46', text: 'dark' },
  { color: '#E8F6F7', text: 'dark' },
  { color: '#C74F4C', text: 'dark' },
  { color: '#C58D19', text: 'dark' },
  { color: '#433137', text: 'light' },
  { color: '#475D57', text: 'light' },
  { color: '#292B2C', text: 'light' },
  { color: '#C6101F', text: 'light' },

  // This is just duplicated twice from above this line. To support up to 78 groups
  { color: '#5C0A27', text: 'light' },
  { color: '#D82536', text: 'light' },
  { color: '#1A161E', text: 'light' },
  { color: '#DB812A', text: 'dark' },
  { color: '#DC446B', text: 'dark' },
  { color: '#5D2970', text: 'light' },
  { color: '#FAE817', text: 'dark' },
  { color: '#E69C92', text: 'dark' },
  { color: '#222734', text: 'light' },
  { color: '#C0815C', text: 'dark' },
  { color: '#30293A', text: 'light' },
  { color: '#A74A53', text: 'light' },
  { color: '#609388', text: 'dark' },
  { color: '#0EA2D6', text: 'dark' },
  { color: '#F15319', text: 'dark' },
  { color: '#1A961D', text: 'dark' },
  { color: '#1775EB', text: 'dark' },
  { color: '#A46830', text: 'dark' },
  { color: '#D55949', text: 'dark' },
  { color: '#F57D46', text: 'dark' },
  { color: '#E8F6F7', text: 'dark' },
  { color: '#C74F4C', text: 'dark' },
  { color: '#C58D19', text: 'dark' },
  { color: '#433137', text: 'light' },
  { color: '#475D57', text: 'light' },
  { color: '#292B2C', text: 'light' },
  { color: '#C6101F', text: 'light' },
  // This is just duplicated  from above this line. To support up to 104 groups
  { color: '#5C0A27', text: 'light' },
  { color: '#D82536', text: 'light' },
  { color: '#1A161E', text: 'light' },
  { color: '#DB812A', text: 'dark' },
  { color: '#DC446B', text: 'dark' },
  { color: '#5D2970', text: 'light' },
  { color: '#FAE817', text: 'dark' },
  { color: '#E69C92', text: 'dark' },
  { color: '#222734', text: 'light' },
  { color: '#C0815C', text: 'dark' },
  { color: '#30293A', text: 'light' },
  { color: '#A74A53', text: 'light' },
  { color: '#609388', text: 'dark' },
  { color: '#0EA2D6', text: 'dark' },
  { color: '#F15319', text: 'dark' },
  { color: '#1A961D', text: 'dark' },
  { color: '#1775EB', text: 'dark' },
  { color: '#A46830', text: 'dark' },
  { color: '#D55949', text: 'dark' },
  { color: '#F57D46', text: 'dark' },
  { color: '#E8F6F7', text: 'dark' },
  { color: '#C74F4C', text: 'dark' },
  { color: '#C58D19', text: 'dark' },
  { color: '#433137', text: 'light' },
  { color: '#475D57', text: 'light' },
  { color: '#292B2C', text: 'light' },
  { color: '#C6101F', text: 'light' }
]
