import React from 'react'
import PropTypes from 'prop-types'
import { Slider, Tag } from 'antd'
import { COLORS } from '@trexity/common/color/constants'
import { haversineDistance, decodePath, getFormattedDistanceString } from '@trexity/common/geo'
import { convertOrdersToArray } from '@trexity/common/models/ShipmentOrder'
import { getFormattedDateAndTimeFromDate } from '@trexity/common/temporal'
import { DriverPosition } from '@trexity/common/drivers'
import { sorter } from '@trexity/common/sort'
import { decodeItineraryItemName, sortOrdersByShipmentAndDriver } from '@trexity/common/shipments'
import { useInterval } from '@trexity/common-client/lib/hooks'
import { calculateEstimatedMinutes } from '../util'

import {
  GoogleMap,
  OverlayView,
  Marker,
  Polygon,
  Polyline
} from '@react-google-maps/api'

export default function Map ({
  directionsResult = null,
  routePlanResults = null,
  deliveryZone = null,
  driver = {},
  driverLatestLocation = null,
  shipment = {},
  driverLocations = [],
  height = 500,
  width = '100%',
  showTimeline = false,
  deps
}) {
  const {
    google
  } = deps

  const {
    driverPhotoUrl
  } = driver || {}

  const {
    location,
    receivedAt
  } = driverLatestLocation || {}

  const {
    currentDriverEstimatedSecondsToPickup,
    currentDriverEstimatedSecondsToPickupComputedAt,
    currentStatus,
    driverPickedUpAt,
    pickupAddressLocation,
    createdAt
  } = shipment || {}

  const [scrubberTime, setScrubberTime] = React.useState(Infinity)

  const [liveNow, setLiveNow] = React.useState(Date.now())
  useInterval(() => setLiveNow(Date.now()), 60000)

  const ordersArray = shipment
    ? convertOrdersToArray(sortOrdersByShipmentAndDriver({ shipment, driver }))
    : []

  let estPickupArrivalText = null
  let estDeliveryArrivalText = null

  if (['EN_ROUTE_TO_PICKUP', 'ARRIVED_AT_PICKUP'].includes(currentStatus) && currentDriverEstimatedSecondsToPickup) {
    const estPickupArrivalMins = calculateEstimatedMinutes(currentDriverEstimatedSecondsToPickup, currentDriverEstimatedSecondsToPickupComputedAt)

    estPickupArrivalText = currentDriverEstimatedSecondsToPickupComputedAt
      ? `Pickup in ${estPickupArrivalMins} min${estPickupArrivalMins === 1 ? '' : 's'}`
      : null
  }

  if (['OUT_FOR_DELIVERY', 'ARRIVED_AT_DELIVERY'].includes(currentStatus)) {
    const idx = ordersArray.findIndex(({ deliveredAt }) => !deliveredAt)
    const order = ordersArray[idx] || {}

    if (Number.isFinite(order.currentDriverEstimatedSecondsToDelivery)) {
      const estDeliveryArrivalMins = calculateEstimatedMinutes(
        order.currentDriverEstimatedSecondsToDelivery,
        order.currentDriverEstimatedSecondsToDeliveryComputedAt
      )

      estDeliveryArrivalText = order.currentDriverEstimatedSecondsToDeliveryComputedAt
        ? `Dropoff in ${estDeliveryArrivalMins} min${estDeliveryArrivalMins === 1 ? '' : 's'}`
        : null
    }
  }

  // The results we get from the server need a bit of massaging.
  // We also merge in the directionsResult into the routePlanResults and
  // fake it so it looks temporal
  const temporalRoutePlanResults = [
    ...(directionsResult ? [{
      createdAt: createdAt || { toDate: () => new Date(liveNow) },
      data: {
        directionsResult: fixDirectionsResult(directionsResult, { google }),
        itinerary: null
      }
    }] : []),
    ...((routePlanResults || []).map((result) => ({
      createdAt: result.createdAt,
      data: {
        directionsResult: fixDirectionsResult(result.data.directionsResult, { google }),
        itinerary: result.data.itinerary
      }
    })))
  ]

  const estArrivalText = (
    estPickupArrivalText ||
    estDeliveryArrivalText
  )

  let deliveryZoneGeo = null

  if (deliveryZone) {
    try {
      deliveryZoneGeo = JSON.parse(deliveryZone)
    } catch (_) {
      console.log('[createMap] could not parse deliveryZone')
    }
  }

  const deliveryZonePolygon = deliveryZoneGeo && deliveryZoneGeo.type === 'Polygon' ? (
    <Polygon
      path={deliveryZoneGeo.coordinates[0].map(([lng, lat]) => ({ lat, lng }))}
      options={{
        strokeColor: COLORS.TREXITY_MERCHANT_BURGUNDY,
        strokeOpacity: 0.7,
        strokeWeight: 1,
        fillColor: COLORS.TREXITY_MERCHANT_BURGUNDY,
        fillOpacity: 0
      }}
    />
  ) : null

  const showDeliveryZonePolygon = false

  const TimelineEvents = React.useMemo(() => interweaveDataIntoTimelineEvents({
    driverLocations,
    temporalRoutePlanResults
  }), [driverLocations, temporalRoutePlanResults])

  const timelineEvents = TimelineEvents.create()
  const firstTimelineEvent = timelineEvents.type('driverLocation').getFirst()
  const lastTimelineEvent = timelineEvents.type('driverLocation').getLast()
  const sliderMin = firstTimelineEvent.time || 0
  const sliderMax = Math.min(lastTimelineEvent.time || 0, liveNow)
  const sliderValue = Math.min(Math.max(scrubberTime, sliderMin), sliderMax)

  const isScrubbing = sliderValue < sliderMax

  const currentLocationTimelineEvent = isScrubbing
    ? timelineEvents
      .type('driverLocation')
      .ending(scrubberTime)
      .getLast()
    : {
      time: receivedAt && receivedAt.toDate().getTime(),
      type: 'driverLocation',
      data: location
    }

  const isDriverLocationStale = currentLocationTimelineEvent.data
    ? DriverPosition.fromLatitudeLongitudeObject(currentLocationTimelineEvent.data, currentLocationTimelineEvent.time).isStale(sliderValue)
    : true

  const driverLatLng = currentLocationTimelineEvent.data && {
    lat: currentLocationTimelineEvent.data.latitude,
    lng: currentLocationTimelineEvent.data.longitude
  }

  const timelineDriverLocations = timelineEvents
    .type('driverLocation')
    .ending(scrubberTime)
    .get()
    .map(({ data }) => data)

  const timelineDistanceTravelled = driverPickedUpAt
    ? timelineEvents
      .type('driverLocation')
      .starting(driverPickedUpAt.toDate())
      .ending(scrubberTime)
      .get()
      .map(({ data }) => data)
      .reduce((memo, loc) => {
        const dist = memo.lastLoc
          ? haversineDistance(memo.lastLoc.latitude, memo.lastLoc.longitude, loc.latitude, loc.longitude) * 1000
          : 0

        return {
          lastLoc: loc,
          distance: memo.distance + dist
        }
      }, {
        lastLoc: null,
        distance: 0
      })
      .distance
    : 0

  const currentRoutePlanResult = timelineEvents
    .type('routePlanResult')
    .ending(scrubberTime)
    .getLast()
    .data

  const timelineEnabled = Array.isArray(driverLocations) && Boolean(driverLocations.length)

  const markerKey = (shipmentId, id, isPickup, completed, undeliverable) => `${shipmentId}__${id || 'NA'}__${isPickup ? 'P' : 'D'}__${completed}__${undeliverable}`

  const [locations, startLocation] = computeLocations({
    currentRoutePlanResult,
    ordersArray,
    shipmentId: shipment.id,
    pickupLocation: pickupAddressLocation,
    pickedUpAtDate: driverPickedUpAt && driverPickedUpAt.toDate(),
    startLocation: currentRoutePlanResult && currentRoutePlanResult.directionsResult && currentRoutePlanResult.directionsResult.routes[0].legs[0].start_location,
    markerKey,
    liveNow
  })

  const timelineLocations = locations.map((loc) => {
    const eventTime = loc.eventDate instanceof Date
      ? loc.eventDate.getTime()
      : Infinity

    const set = {}

    if (scrubberTime < eventTime) {
      set.completed = false
      set.key = markerKey(loc.shipmentId, loc.orderUuid || loc.orderId, loc.isPickup, set.completed, loc.undeliverable)
    }

    return {
      ...loc,
      ...set
    }
  })

  const Bounds = {
    locs: [],
    push (directionsResult) {
      if (directionsResult && directionsResult.routes) {
        const route = directionsResult.routes[0]

        route.legs.forEach((leg) => {
          this.locs = this.locs.concat([
            { lat: leg.start_location.lat, lng: leg.start_location.lng },
            { lat: leg.end_location.lat, lng: leg.end_location.lng }
          ])
        })
      }
    },
    getBounds () {
      const bounds = new google.maps.LatLngBounds()
      this.locs.forEach((loc) => bounds.extend(loc))
      return bounds
    }
  }

  Bounds.push(currentRoutePlanResult && currentRoutePlanResult.directionsResult)

  return (
    <div>
      <GoogleMap
        id='map'
        onLoad={(map) => {
          map.fitBounds(Bounds.getBounds())
        }}
        mapContainerStyle={{
          height,
          width
        }}
      >
        {driverLatLng ? (
          <OverlayView
            position={driverLatLng}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          >
            <div
              style={{
                position: 'relative',
                marginLeft: -20,
                marginTop: -20,
                width: 40,
                height: 40
              }}
            >
              <img
                src={driverPhotoUrl || '/car-icon.png'}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: 40,
                  height: 40,
                  borderRadius: 40,
                  border: '2px solid #fff'
                }}
              />
              <div
                style={{
                  display: isDriverLocationStale ? 'flex' : 'none',
                  background: 'rgba(240, 240, 240, 0.7)',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: 40,
                  height: 40,
                  borderRadius: 40,
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: '#f00',
                  fontSize: 14,
                  fontWeight: 'bold'
                }}
              >
                GPS
              </div>
              {estArrivalText ? (
                <strong
                  style={{
                    position: 'absolute',
                    bottom: -18,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    background: '#000',
                    color: '#fff',
                    borderRadius: 5,
                    border: '2px solid #fff',
                    padding: '3px 6px',
                    whiteSpace: 'nowrap'
                  }}
                >{estArrivalText}</strong>
              ) : null}
            </div>
          </OverlayView>
        ) : null}

        {/* {fixedOriginalDirectionsResult && JSON.stringify(fixedCurrentDirectionsResult) !== JSON.stringify(fixedOriginalDirectionsResult) ? (
          <DirectionsMemo
            directions={fixedOriginalDirectionsResult}
            polylineOptions={{
              strokeColor: '#666666',
              strokeWeight: 4,
              strokeOpacity: 0.4
            }}
          />
        ) : null} */}

        {currentRoutePlanResult && currentRoutePlanResult.directionsResult && currentRoutePlanResult.directionsResult.routes ? (
          <React.Fragment>
            <DirectionsMemo
              directions={currentRoutePlanResult.directionsResult}
            />
            {startLocation ? (
              <DirectionMarkersMemo
                google={google}
                locations={timelineLocations}
                startLocation={startLocation}
                markerKey={markerKey}
              />
            ) : null}
          </React.Fragment>
        ) : null}

        {showDeliveryZonePolygon ? deliveryZonePolygon : null}

        {Array.isArray(timelineDriverLocations) && timelineDriverLocations.length ? (
          <Polyline
            path={timelineDriverLocations.map((loc) => ({
              lat: Number(loc.latitude),
              lng: Number(loc.longitude)
            }))}
            options={{
              strokeWeight: 0,
              icons: [
                {
                  icon: {
                    path: 'M 0,-1 0,1',
                    strokeOpacity: 1,
                    strokeColor: '#ff5c00',
                    scale: 3
                  },
                  offset: '0',
                  repeat: '15px'
                }
              ]
            }}
          />
        ) : null}
      </GoogleMap>

      {showTimeline ? (
        <div>
          <Slider
            min={sliderMin}
            max={sliderMax}
            value={sliderValue}
            disabled={!timelineEnabled}
            tipFormatter={(value) => getSliderTip(value, timelineDistanceTravelled)}
            onChange={(time) => setScrubberTime(time === sliderMax ? Infinity : time)}
          />
        </div>
      ) : null}

      {currentLocationTimelineEvent.data ? (
        <span>Driver’s last location was known on <Tag color={isDriverLocationStale ? 'red' : 'green'}>{getFormattedDateAndTimeFromDate(new Date(currentLocationTimelineEvent.time), { showSeconds: true })}</Tag></span>
      ) : null}
    </div>
  )
}

Map.propTypes = {
  directionsResult: PropTypes.object,
  routePlanResults: PropTypes.arrayOf(
    PropTypes.exact({
      createdAt: PropTypes.any,
      updatedAt: PropTypes.any,
      shipmentIds: PropTypes.array,
      data: PropTypes.exact({
        itinerary: PropTypes.object,
        directionsResult: PropTypes.object
      })
    })
  ),
  deliveryZone: PropTypes.string,
  driver: PropTypes.object,
  driverLatestLocation: PropTypes.object,
  shipment: PropTypes.object,
  driverLocations: PropTypes.array,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  showTimeline: PropTypes.bool,
  deps: PropTypes.exact({
    google: PropTypes.object
  }).isRequired
}

function Directions ({
  directions,
  polylineOptions = {
    strokeColor: '#0088FF',
    strokeWeight: 5,
    strokeOpacity: 0.8
  }
}) {
  return directions.routes[0].legs.map((leg, lindex) => {
    const path = leg.steps.reduce((memo, step) => (
      [...memo, ...step.path]
    ), [])

    return (
      <Polyline
        key={`${lindex}`}
        path={path}
        options={{
          ...polylineOptions,
          icons: [
            {
              icon: {
                path: 'M -3 3 l 3 -3 l 3 3',
                strokeOpacity: polylineOptions.strokeOpacity,
                strokeColor: polylineOptions.strokeColor,
                scale: 2
              },
              offset: '0',
              repeat: '60px'
            }
          ]
        }}
      />
    )
  })

  // return (
  //   <DirectionsRenderer
  //     onLoad={() => setIsLoaded(true)}
  //     directions={directions}
  //     options={{
  //       suppressMarkers: false,
  //       polylineOptions
  //     }}
  //   />
  // )
}

Directions.propTypes = {
  directions: PropTypes.any,
  polylineOptions: PropTypes.object
}

const DirectionsMemo = React.memo(Directions, (prevProps, nextProps) => {
  return JSON.stringify(prevProps.directions) === JSON.stringify(nextProps.directions)
})

function DirectionMarker ({
  google,
  position,
  text,
  textColor,
  type
}) {
  const labelFontSize = 12

  const labelBaseProps = {
    fontFamily: 'Courier New',
    fontSize: `${labelFontSize}px`,
    fontWeight: 'bold'
  }

  const baseMarkerIcon = {
    path: 'M -13, 0a13, 13 0 1, 0 26, 0a13, 13 0 1, 0 -26, 0',
    fillColor: COLORS.TREXITY_YELLOW,
    fillOpacity: 1.0,
    anchor: new google.maps.Point(0, 0),
    strokeWeight: 1,
    strokeColor: COLORS.DARK,
    scale: 1.0
  }

  const getWidth = (textLen, fs) => textLen * fs * 0.6
  const getPath = (text, fs) => `m -${getWidth(text.length, fs) / 2} -${getWidth(1.25, fs)} h ${getWidth(text.length, fs)} a 5 5 0 0 1 5 5 v ${getWidth(1, fs)} a 5 5 0 0 1 -5 5 h -${getWidth(text.length, fs)} a 5 5 0 0 1 -5 -5 v -${getWidth(1, fs)} a 5 5 0 0 1 5 -5 z`

  let markerIcon = null

  switch (type) {
    case 'delivered':
      markerIcon = {
        ...baseMarkerIcon,
        fillColor: COLORS.TREXITY_GREENISH_TEAL,
        strokeColor: COLORS.TREXITY_DRIVER_NAVY
      }
      break

    case 'undeliverable':
      markerIcon = {
        ...baseMarkerIcon,
        fillColor: COLORS.TREXITY_RED,
        strokeColor: COLORS.DARK
      }
      break

    case 'other':
      markerIcon = {
        ...baseMarkerIcon,
        fillColor: COLORS.MEDIUM_LIGHT,
        strokeColor: COLORS.DARK
      }
      break

    case 'start':
      markerIcon = {
        ...baseMarkerIcon,
        fillColor: COLORS.TREXITY_LIGHT_BLUE,
        strokeColor: COLORS.TREXITY_DRIVER_NAVY
      }
      break

    case 'default':
      markerIcon = baseMarkerIcon
      break

    default:
      throw new Error('Invalid type: ' + type)
  }

  return (
    <Marker
      position={position}
      icon={{
        ...markerIcon,
        path: getPath(text, labelFontSize)
      }}
      label={{
        ...labelBaseProps,
        text: text,
        color: textColor
      }}
    />
  )
}

DirectionMarker.propTypes = {
  google: PropTypes.any.isRequired,
  position: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  type: PropTypes.oneOf([
    'start',
    'other',
    'delivered',
    'undeliverable',
    'default'
  ]).isRequired
}

function DirectionMarkers ({
  google,
  startLocation,
  locations,
  markerKey
}) {
  return (
    <React.Fragment>
      <DirectionMarker
        google={google}
        position={startLocation}
        text='Start'
        textColor={COLORS.TREXITY_DRIVER_NAVY}
        type='start'
      />

      {locations
        .filter(({ location }) => location)
        .map(({
          location,
          isPickup,
          completed,
          undeliverable,
          orderId,
          orderUuid,
          shipmentId,
          isOther
        }) => {
          const last3 = shipmentId.substr(-3)

          const text = isPickup
            ? `${last3 ? `${last3}: ` : ''}Pickup`
            : `${last3 ? `${last3}: ` : ''}${orderId}`

          const textColor = (
            completed ? (undeliverable ? COLORS.WHITE : COLORS.TREXITY_DRIVER_NAVY)
              : isOther ? COLORS.DARK
                : COLORS.DARK
          )

          const type = (
            completed ? (undeliverable ? 'undeliverable' : 'delivered')
              : isOther ? 'other'
                : 'default'
          )

          return (
            <DirectionMarker
              key={markerKey(shipmentId, orderUuid || orderId, isPickup, completed, undeliverable)}
              google={google}
              position={location}
              text={text}
              textColor={textColor}
              type={type}
            />
          )
        })
      }
    </React.Fragment>
  )
}

DirectionMarkers.propTypes = {
  google: PropTypes.any,
  startLocation: PropTypes.object,
  locations: PropTypes.array,
  markerKey: PropTypes.func.isRequired
}

const DirectionMarkersMemo = React.memo(DirectionMarkers, (prevProps, nextProps) => {
  const prevStartLocation = prevProps.startLocation || {}
  const nextStartLocation = nextProps.startLocation || {}

  const prevLocations = Array.isArray(prevProps.locations) && prevProps.locations.map((obj) => obj.key).join('|')
  const nextLocations = Array.isArray(nextProps.locations) && nextProps.locations.map((obj) => obj.key).join('|')

  return (
    prevStartLocation.latitude === nextStartLocation.latitude &&
    prevStartLocation.longitude === nextStartLocation.longitude &&
    prevLocations === nextLocations
  )
})

function fixDirectionsResult (directionsResult, { google }) {
  return directionsResult && directionsResult.routes
    ? {
      ...directionsResult,
      routes: directionsResult.routes.map((route) => {
        return {
          ...route,
          bounds: new google.maps.LatLngBounds(
            new google.maps.LatLng(route.bounds.southwest.lat, route.bounds.southwest.lng),
            new google.maps.LatLng(route.bounds.northeast.lat, route.bounds.northeast.lng)
          ),
          legs: route.legs.map((leg) => {
            return {
              ...leg,
              // end_location: new google.maps.LatLng(leg.end_location.lat, leg.end_location.lng),
              // start_location: new google.maps.LatLng(leg.start_location.lat, leg.start_location.lng),
              steps: leg.steps.map((step) => {
                return {
                  ...step,
                  // end_location: new google.maps.LatLng(step.end_location.lat, step.end_location.lng),
                  // start_location: new google.maps.LatLng(step.start_location.lat, step.start_location.lng),
                  path: decodePath(step.polyline.points)
                }
              })
            }
          })
        }
      }),
      request: {
        travelMode: google.maps.TravelMode.DRIVING
      }
    }
    : null
}

function getLocationsByOrdersArray (shipmentId, ordersArray, markerKey, liveNow) {
  return ordersArray.map((order, idx) => {
    const eventDate = (order.deliveredAt && order.deliveredAt.toDate()) || new Date(liveNow + idx + 1)
    const completed = Boolean(order.deliveredAt)
    const undeliverable = Boolean(order.undeliverable)

    return {
      key: markerKey(shipmentId, order.id || order.orderId, false, completed, undeliverable),
      isPickup: false,
      orderId: order.orderId,
      shipmentId,
      isOther: false,
      location: order.location && { lat: order.location.latitude, lng: order.location.longitude },
      completed,
      undeliverable,
      eventDate
    }
  })
}

function createTimelineEvents (events) {
  return {
    type: (type) => createTimelineEvents(
      events.filter((obj) => obj.type === type)
    ),
    starting: (time) => createTimelineEvents(
      events.filter((obj) => obj.time >= time)
    ),
    ending: (time) => createTimelineEvents(
      events.filter((obj) => obj.time <= time)
    ),

    get: () => events,
    getFirst: () => events.slice(0, 1)[0] || {},
    getLast: () => events.slice(-1)[0] || {}
  }
}

function interweaveDataIntoTimelineEvents ({
  latestDriverLocation, // { latitude, longitude }
  driverLocations,
  temporalRoutePlanResults,
  // Sorting is disabled by default due to performance issues with sorting on
  // each render
  sort = false
}) {
  let timelineEvents = []

  if (Array.isArray(driverLocations)) {
    timelineEvents = timelineEvents.concat(driverLocations.map((loc) => {
      return {
        time: loc.createdAt.toDate().getTime(),
        type: 'driverLocation',
        data: loc
      }
    }))
  }

  if (latestDriverLocation) {
    timelineEvents.push({
      time: Infinity,
      type: 'driverLocation',
      data: latestDriverLocation
    })
  }

  if (Array.isArray(temporalRoutePlanResults)) {
    timelineEvents = timelineEvents.concat(temporalRoutePlanResults.map((result) => {
      return {
        time: result.createdAt.toDate().getTime(),
        type: 'routePlanResult',
        data: result.data
      }
    }))
  }

  if (sort) {
    timelineEvents.sort(sorter('time', { direction: 'asc' }))
  }

  return {
    create: () => createTimelineEvents(timelineEvents)
  }
}

function getSliderTip (timeMs, distanceTravelled) {
  return (
    <span>
      {getFormattedDateAndTimeFromDate(new Date(timeMs), { showSeconds: true })}<br />
      Travelled: {getFormattedDistanceString(distanceTravelled)}
    </span>
  )
}

function computeLocations ({
  currentRoutePlanResult,
  ordersArray,
  shipmentId,
  pickupLocation,
  pickedUpAtDate,
  startLocation,
  markerKey,
  liveNow
}) {
  let locations = []

  const pickupCompleted = Boolean(pickedUpAtDate)

  if (currentRoutePlanResult && currentRoutePlanResult.itinerary) {
    const item = currentRoutePlanResult.itinerary.instructions.find((obj) => obj.instructionType === 'LeaveFromStartPoint')

    startLocation = {
      lat: item.itineraryItem.location.latitude,
      lng: item.itineraryItem.location.longitude
    }

    const knownLocations = getLocationsByOrdersArray(shipmentId, ordersArray, markerKey, liveNow)

    const completedLocations = knownLocations
      .concat({
        isPickup: true,
        orderId: null,
        shipmentId,
        isOther: false,
        location: pickupLocation && { lat: pickupLocation.latitude, lng: pickupLocation.longitude },
        eventDate: pickedUpAtDate || new Date(liveNow),
        completed: pickupCompleted,
        undeliverable: false
      })
      .filter((loc) => loc.completed)
      .map((loc) => ({
        ...loc,
        key: markerKey(loc.shipmentId, loc.orderUuid || loc.orderId, loc.isPickup, loc.completed, loc.undeliverable)
      }))

    locations = currentRoutePlanResult.itinerary.instructions
      .filter((obj) => obj.instructionType === 'VisitLocation')
      .filter((obj) => obj.itineraryItem.name.startsWith('D_') || obj.itineraryItem.name.startsWith('P_'))
      .reduce((memo, obj, index) => {
        const { type, shipmentId: locationShipmentId, deliveryIndex, orderUuid, orderId } = decodeItineraryItemName(obj.itineraryItem.name)

        const isPickup = type === 'pickup'
        const isOther = locationShipmentId !== shipmentId
        const order = (orderUuid ? ordersArray.find(({ id }) => id === orderUuid) : ordersArray[deliveryIndex]) || {}
        const deliveredAt = !isOther && order.deliveredAt
        const deliveredAtDate = deliveredAt && deliveredAt.toDate()
        const undeliverable = Boolean(!isOther && order.undeliverable)

        const eventDate = (
          isOther
            ? null // if not the current shipmentId, can't infer this
            : isPickup
              ? (pickedUpAtDate || new Date(liveNow))
              : (deliveredAtDate || new Date(liveNow + index + 1))
        )

        const completed = (
          isOther
            ? false // if not the current shipmentId, can't infer this
            : isPickup
              ? pickupCompleted
              : Boolean(deliveredAt)
        )

        const key = markerKey(locationShipmentId, orderUuid || orderId, isPickup, completed, undeliverable)

        if (memo.find((loc) => loc.key === key)) {
          // Already in "completed" locations, skip
          return memo
        }

        return [
          ...memo,
          {
            key,
            isPickup,
            orderId,
            orderUuid,
            shipmentId: locationShipmentId,
            isOther,
            location: { lat: obj.itineraryItem.location.latitude, lng: obj.itineraryItem.location.longitude },
            eventDate,
            completed,
            undeliverable
          }
        ]
      }, completedLocations)
      .sort(sorter('eventDate', { direction: 'asc' }))
  } else {
    locations = getLocationsByOrdersArray(shipmentId, ordersArray, markerKey, liveNow)
  }

  return [locations, startLocation]
}
