import React from 'react'
import PropTypes from 'prop-types'
import { COLORS } from '@trexity/common/color/constants'
import { Button, Popover, Icon } from 'antd'

export default function OrderIdList ({
  shipmentId = null,
  generateLabel = null,
  orderIds = [],
  getMap = () => null,
  maxPopoverWidth = 250,
  minPopoverWidth = 250,
  showAsPopover = false
}) {
  const [popoverVisible, setPopoverVisible] = React.useState(false)

  const enableLabel = typeof generateLabel === 'function'

  const orders = orderIds.map(({ id, orderId, delivered, undeliverable }, index) => {
    return (
      <div
        onClick={() => {
          if (enableLabel) {
            generateLabel(shipmentId, id, orderId)
          }
        }}
        key={`${orderId}_${index}`}
        style={{
          cursor: enableLabel ? 'pointer' : 'auto',
          display: 'inline-block',
          backgroundColor: undeliverable ? COLORS.TREXITY_RED : (delivered ? COLORS.TREXITY_GREEN : COLORS.TREXITY_YELLOW),
          padding: '3px 7px',
          margin: '3px 5px 3px 0',
          borderRadius: 20,
          borderColor: undeliverable ? COLORS.TREXITY_RED : (delivered ? COLORS.TREXITY_GREEN : COLORS.DARK),
          borderWidth: 1,
          borderStyle: 'solid',
          fontSize: '12px',
          lineHeight: '12px',
          whiteSpace: 'nowrap'
        }}
      >
        <strong style={{ color: undeliverable ? COLORS.WHITE : (delivered ? COLORS.WHITE : COLORS.DARK) }}>
          {orderId}
        </strong>
        {enableLabel ? (
          <Icon type={delivered ? 'check-circle' : 'printer'} style={{ color: undeliverable ? COLORS.WHITE : (delivered ? COLORS.WHITE : COLORS.DARK), marginLeft: 5 }} />
        ) : (
          delivered ? (
            <Icon type='check-circle' style={{ color: undeliverable ? COLORS.WHITE : (delivered ? COLORS.WHITE : COLORS.DARK), marginLeft: 5 }} />
          ) : null
        )}
      </div>
    )
  })

  return (
    showAsPopover ? (
      <Popover
        content={(
          <div style={{ minWidth: minPopoverWidth, maxWidth: maxPopoverWidth }}>
            {popoverVisible ? getMap() : null}
            <div style={{ marginTop: 10 }}>
              {orders}
            </div>
          </div>
        )}
        trigger='click'
        visible={popoverVisible}
        onVisibleChange={(visible) => setPopoverVisible(visible)}
      >
        <Button
          type='ghost'
          size='small'
        >{orderIds.length} Deliver{orderIds.length === 1 ? 'y' : 'ies'}</Button>
      </Popover>
    ) : orders
  )
}

OrderIdList.propTypes = {
  orderIds: PropTypes.arrayOf(
    PropTypes.exact({
      orderId: PropTypes.string,
      delivered: PropTypes.bool,
      undeliverable: PropTypes.bool,
      id: PropTypes.string
    })
  ),
  shipmentId: PropTypes.string,
  getMap: PropTypes.func,
  generateLabel: PropTypes.func,
  maxPopoverWidth: PropTypes.number,
  minPopoverWidth: PropTypes.number,
  showAsPopover: PropTypes.bool
}
