import * as React from 'react'
import * as PropTypes from 'prop-types'

import { Modal, Button } from 'antd'

export default function ChangeVerificationModal ({
  currentValue,
  onLoadModalClicked,
  onCloseModalClicked,
  modalButtonProps,
  modalButtonLabel,
  hideChangeLabel,
  children,
  ...remainingModalProps
} = {}) {
  return (
    <div>
      {hideChangeLabel ? null : currentValue}{hideChangeLabel ? null : <span> &nbsp; </span>}
      <Button onClick={onLoadModalClicked} {...modalButtonProps}>
        {modalButtonLabel || 'Change'}
      </Button>
      <Modal
        title={'Change: ' + currentValue}
        {...remainingModalProps}
        onCancel={onCloseModalClicked}
        footer={null}
        destroyOnClose
      >
        {children}
      </Modal>
    </div>
  )
}

ChangeVerificationModal.propTypes = {
  currentValue: PropTypes.string.isRequired,
  onLoadModalClicked: PropTypes.func,
  onCloseModalClicked: PropTypes.func,
  modalButtonProps: PropTypes.object,
  modalButtonLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  hideChangeLabel: PropTypes.bool,
  children: PropTypes.node.isRequired
}
