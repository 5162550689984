function getAutoGeneratedOrderId ({ address, name }, otherOrderIds = []) {
  const constructOrderId = ({ address, name }, suffix = '') => {
    const addressParts = address.split(' ')

    const number = String(addressParts[0] || '')
      .replace(/[^0-9]/gu, '')

    const firstTwoLettersAddress = String(addressParts[1] || '')
      .replace(/[^A-Za-z]/gu, '')
      .substr(0, 2)
      .toUpperCase()

    const initials = name.split(/[- ]/gu)
      .map((word) => String(word || '').substr(0, 1))
      .filter(Boolean)
      .join('')
      .toUpperCase()

    return [
      initials,
      number,
      firstTwoLettersAddress,
      suffix
    ].filter(Boolean).join('-')
  }

  let proposedOrderId = null
  let count = 0

  do {
    proposedOrderId = constructOrderId({ address, name }, count || '')
    count++
  } while (otherOrderIds.includes(proposedOrderId))

  return proposedOrderId
}

exports.getAutoGeneratedOrderId = getAutoGeneratedOrderId
