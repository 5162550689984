const { isobj } = require('../core/check')

/**
 * Provide a function that returns a new function
 * This returned function will call the one provided in params
 * passed function and retry calling it if it rejects using an exponential
 * backoff algorithm.
 *
 * @example const myAction2 = retryable(myAction, { maxRetries: 25, maxBackoff: 32000 })
 * @template R
 * @see https://cloud.google.com/iot/docs/how-tos/exponential-backoff
 * @param {Object} params
 * @param {() => R} params.fn The function you want to retry according to the exponential backoff algorithm
 * @param {number} [params.maxRetries] Default is 15
 * @param {number} [params.maxBackoff] Default is 32000
 * @param {(statusCode:number, respOrError) => boolean} [params.isRetryable]
 * @returns {(...args) => Promise<R>}
 */
module.exports = function retryable ({ fn, maxRetries = 15, maxBackoff = 32000, isRetryable = (statusCode) => statusCode === 429 } = {}) {
  if (typeof fn !== 'function') {
    throw new TypeError('Argument "fn" must be a function')
  }

  if (!Number.isSafeInteger(maxRetries) || maxRetries < 0) {
    throw new TypeError('Argument "maxRetries" must be a positive integer')
  }

  if (!Number.isSafeInteger(maxBackoff) || maxBackoff < 0) {
    throw new TypeError('Argument "maxBackoff" must be a positive integer')
  }

  if (typeof isRetryable !== 'function') {
    throw new TypeError('Argument "isRetryable" must be a function')
  }

  const next = async (args, retries = 0) => {
    const waitTime = Math.min(
      Math.pow(2, retries) + Math.ceil(Math.random() * 1000),
      maxBackoff
    )

    // On our first try we don't wait.
    if (retries) {
      await new Promise((resolve) => setTimeout(resolve, waitTime))
    }

    try {
      const resp = await fn(...args)

      if (retries < maxRetries && resp && await isRetryable(getStatusFrom(resp), resp)) {
        return next(args, retries + 1)
      } else {
        return resp
      }
    } catch (error) {
      if (retries < maxRetries && error && isRetryable(getStatusFrom(error), error)) {
        return next(args, retries + 1)
      } else {
        throw error
      }
    }
  }

  return (...args) => next(args)
}

// Coherce a value to string and return integer value, otherwise return the value (exponential form always returns the value)
const asint = (val) => [parseInt(String(val), 10)].filter((n) => !isNaN(n) && String(val) === n.toString()).pop() || val

// Get the status code from an object. If object is falsey or no status is present
// return 0. Otherwise return the status converted to an integer if possible or
// return the status unconverted.
function getStatusFrom (obj) {
  if (!obj) {
    return 0
  }

  if (isobj(obj) && 'status' in obj) {
    return asint(obj.status)
  }

  if (isobj(obj) && 'statusCode' in obj) {
    return asint(obj.statusCode)
  }

  if (isobj(obj) && 'code' in obj) {
    return asint(obj.code)
  }

  return 0
}
