/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'

export const MarkerStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '12px',
  fontWeight: 600,
  borderWidth: 2,
  borderStyle: 'solid',
  borderRadius: 0,
  width: 30,
  height: 30
}
