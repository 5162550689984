const { Std, Check } = require('../../core')
const { isServiceHours } = require('./isServiceHours')
const { isCalendarDay } = require('./isCalendarDay')
const { setCalendarDayHours } = require('./setCalendarDayHours')
const { pad } = require('./pad')

/**
 * Returns a new service hours object with the the specified calendar day set to "closed".
 *
 * @param {ServiceHours} serviceHours
 * @param {string|[number,number]|Date} day String like 'MM-DD', [month (1-based), date] tuple, or Date instance (uses local month and date)
 * @return {CalendarDeliveryDay}
 */
function setCalendarDayHoursClosed (serviceHours, day) {
  if (!isServiceHours(serviceHours)) {
    throw Std.err('TypeError', 'Service hours is invalid')
  }

  if (Check.isdate(day)) {
    day = [day.getMonth() + 1, day.getDate()]
  }

  if (Check.and(Check.isarr, Check.of(Check.isint), (x) => x.length === 2)(day)) {
    day = `${pad(day[0])}-${pad(day[1])}`
  }

  if (!isCalendarDay(day)) {
    throw Std.err('ArgumentError', `calendar day is invalid : ${day}`)
  }

  return setCalendarDayHours(serviceHours, day, [])
}

exports.setCalendarDayHoursClosed = setCalendarDayHoursClosed
