/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import PropTypes from 'prop-types'
import { COLORS } from '@trexity/common/color/constants'
import Icon from '@material-ui/core/Icon'

export const IconMarker = ({
  bg,
  icon,
  size,
  light
}) => {
  const setSize = () => {
    switch (size) {
      case 'small':
        return 10
      case 'medium':
        return 16
      case 'large':
        return 32
      default:
        return 16
    }
  }

  return (
    <div css={IconStyles(bg, setSize(), light)}>
      <Icon style={{ fontSize: setSize() }}>{icon}</Icon>
    </div>
  )
}

export const IconStyles = (bg, size, light) => ({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderWidth: 1,
  borderStyle: 'solid',
  borderRadius: 5,
  fontSize: size,
  padding: '0.25em',
  backgroundColor: bg,
  borderColor: COLORS.WHITE,
  color: light ? COLORS.WHITE : COLORS.DARK
})

IconMarker.propTypes = {
  bg: PropTypes.string,
  icon: PropTypes.string,
  size: PropTypes.string,
  light: PropTypes.bool
}
