const validateUrl = require('./validateUrl')

module.exports = function validateHttpsUrl (url) {
  if (typeof url !== 'string') {
    return false
  }

  if (!url.startsWith('https://')) {
    return false
  }

  return validateUrl(url)
}
