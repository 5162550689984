const createModel = require('./createModel')
const { PropTypes } = require('../util')
const { MODES } = require('./constants')
const { LINKED_SHIPMENT_TYPES } = require('../shipments/constants')

const {
  SERVER_READ,
  SERVER_WRITE,
  CLIENT_READ,
  CLIENT_WRITE,
  CLIENT_ROLE_MERCHANT_READ,
  CLIENT_ROLE_DRIVER_READ
} = MODES

const schema = {
  id: {
    type: PropTypes.string,
    pgType: 'uuid',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ
  },

  // Date when this record was created
  createdAt: {
    type: PropTypes.firestoreTimestamp,
    pgType: 'timestamptz',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ
  },

  address: {
    type: PropTypes.string,
    pgType: 'text',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_WRITE | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    default: ''
  },

  addressNotes: {
    type: PropTypes.string,
    pgType: 'text',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_WRITE | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    default: ''
  },

  deliveryInstructions: {
    type: PropTypes.string,
    pgType: 'text',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_WRITE | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    default: ''
  },

  email: {
    type: PropTypes.string,
    pgType: 'text',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_WRITE | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    default: ''
  },

  // NOTE (dschnare): We actually save the phone in this format:
  // +1 123 456 7890 OR +1 123 456 7890,X
  // Where X is the extension.
  phone: {
    type: PropTypes.string,
    pgType: 'text',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_WRITE | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    default: ''
  },

  name: {
    type: PropTypes.string,
    pgType: 'text',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_WRITE | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    default: ''
  },

  deliveryPhotoUrl: {
    type: PropTypes.string,
    pgType: 'text',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    default: ''
  },

  description: {
    type: PropTypes.string,
    pgType: 'text',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_WRITE | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    default: ''
  },

  initialPhotoUrl: {
    type: PropTypes.string,
    pgType: 'text',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    default: ''
  },

  orderId: {
    type: PropTypes.maxString(20),
    pgType: 'varchar(20)',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_WRITE | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    default: ''
  },

  signature: {
    type: PropTypes.string,
    pgType: 'text',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_WRITE | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    default: ''
  },

  value: {
    type: PropTypes.nonNegativeInteger,
    pgType: 'bigint',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_WRITE | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    default: 0
  },

  location: {
    type: PropTypes.object,
    pgType: 'geometry(Point,4326)',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    default: null
  },

  province: {
    type: PropTypes.string,
    pgType: 'text',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    default: ''
  },

  country: {
    type: PropTypes.string,
    pgType: 'text',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    default: ''
  },

  city: {
    type: PropTypes.string,
    pgType: 'text',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    default: ''
  },

  serviceCity: {
    type: PropTypes.string,
    pgType: 'text',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    default: ''
  },

  secondsFromPickupToDelivery: {
    type: PropTypes.number,
    pgType: 'numeric(12,3)',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    default: null
  },

  driverTimeDelivering: {
    type: PropTypes.number,
    pgType: 'numeric(12,3)',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    default: null
  },

  arrivedAt: {
    type: PropTypes.firestoreTimestamp,
    pgType: 'timestamptz',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    default: null
  },

  deliveredAt: {
    type: PropTypes.firestoreTimestamp,
    pgType: 'timestamptz',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    default: null
  },

  undeliverable: {
    type: PropTypes.bool,
    pgType: 'boolean',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    default: false
  },

  misdelivered: {
    type: PropTypes.bool,
    pgType: 'boolean',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ,
    default: false
  },

  currentDriverEstimatedSecondsToDelivery: {
    type: PropTypes.number,
    pgType: 'numeric(12,3)',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    default: null
  },

  currentDriverEstimatedSecondsToDeliveryComputedAt: {
    type: PropTypes.firestoreTimestamp,
    pgType: 'timestamptz',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    default: null
  },

  requiresPersonHandoff: {
    type: PropTypes.bool,
    pgType: 'boolean',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_WRITE | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    default: false
  },

  personHandoffPin: {
    type: PropTypes.string,
    pgType: 'text',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_WRITE | CLIENT_ROLE_MERCHANT_READ,
    default: null
  },

  containsAlcohol: {
    type: PropTypes.bool,
    pgType: 'boolean',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_WRITE | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    default: false
  },

  cachedDeliveryLocation: {
    type: PropTypes.object,
    pgType: 'geometry(Point,4326)',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    default: null
  },

  // User cannot edit this delivery directly. It is instead manipulated by the
  // server, usually based on external factors
  // NOTE: not currently in use. If you're looking for Return Reusables, they
  // have been moved to leverage linked shipments
  internalType: {
    // type: PropTypes.oneOf(['ReturnReusables']),
    type: PropTypes.string,
    pgType: 'text',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    default: null
  },

  // Indicate to the routing algorithm at what point in the route this order
  // should take place. "null" indicates no specific preference
  routingPriority: {
    type: PropTypes.oneOf(['first', 'last']),
    pgType: 'varchar(20)',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    default: null
  },

  // Number of labels to generate for this delivery. This does not relate
  // directly to the (planned) packages system, which will supercede this
  // Note that any value below 1, including null undefined 0, imply 1.
  numLabels: {
    // Note: do NOT lower the upper range value. Unfortunately, it cannot be
    // reduced below any value stored at rest, nor should the value at rest be
    // changed. Enforcement of a different upper range must be done elsewhere
    // (i.e. during create/update etc)
    type: PropTypes.integerRange(1, 100),
    pgType: 'integer',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_WRITE | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    default: 1
  },

  labeledAt: {
    type: PropTypes.firestoreTimestamp,
    pgType: 'timestamptz',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ,
    default: null
  },

  labelValid: {
    type: PropTypes.bool,
    pgType: 'boolean',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ,
    default: null
  },

  // The order ID or shipment ID in the external system that created or is associated to this parcel.
  externalId: {
    type: PropTypes.string,
    pgType: 'varchar(256)',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_ROLE_MERCHANT_READ,
    default: null
  },

  // General metadata
  metadata: {
    type: PropTypes.shape({
      // --------------------------------------------------
      // Metadata that is namespaced under "meta" that can be set via the public API
      // --------------------------------------------------
      meta: PropTypes.object,

      // --------------------------------------------------
      // Shopify-specific metadata
      // --------------------------------------------------
      shopify: PropTypes.shape({
        orderId: PropTypes.number,
        fulfillmentId: PropTypes.number,
        fulfillmentLocationId: PropTypes.number,
        lineItemIds: PropTypes.arrayOf(PropTypes.number)
      }),

      // NOTE: the "shopify" object above is cleaner, but suffers from
      // difficulty in writing when the Map is not present. We can only
      // guarantee the presence of "metadata" as a Map, but not the "shopify"
      // object, which means we can't guarantee what will happen when trying to
      // write to e.g. `metadata.shopify.orderId`. Will this write to the
      // shopify Map as "orderId", or will it write to the metadata Map as
      // "shopify.orderId"? Only when the "shopify" Map is already present will
      // the former be the case. Thus, we've taken to writing metadata in the
      // root of "metadata" and namespacing using a prefix:

      // --------------------------------------------------
      // Customer address verification metadata
      // --------------------------------------------------
      // This gets toggled when a Shipment gets to "Scheduled" state (can be
      // on creation, or can be on update)
      customerUpdateAddress_emailSent: PropTypes.bool,
      // This gets toggled when the Zendesk ticket for this issue has
      // been created
      customerUpdateAddress_ticketCreated: PropTypes.bool,
      customerUpdateAddress_emailUuid: PropTypes.string,

      // --------------------------------------------------
      // Whether or not the driver was penalized already
      // --------------------------------------------------
      driver_penalizedDate: PropTypes.string,
      driver_penalizedAmount: PropTypes.nonNegativeInteger,

      // --------------------------------------------------
      // Customer tip cached information
      // --------------------------------------------------
      driver_customerTip_driverId: PropTypes.id,
      driver_customerTip_amount: PropTypes.nonNegativeInteger,
      driver_customerTip_originalAmount: PropTypes.nonNegativeInteger,
      driver_customerTip_receiptUrl: PropTypes.string,
      driver_customerTip_receivedAt: PropTypes.firestoreTimestamp,
      driver_customerTip_acknowledgedAt: PropTypes.firestoreTimestamp,

      // --------------------------------------------------
      // Source of the order, e.g. did it come from an API integration,
      // or Shopify/Zapiet/Ingestion/UI as merchant/UI as admin/etc.
      // --------------------------------------------------
      originSource: PropTypes.string,
      originMetadata: PropTypes.object
    }),
    pgType: 'jsonb',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ,
    get default () {
      return {}
    }
  },

  // ---------------------------------------------------------------------------
  // Actual vs planned segment timings/distances

  deliverySegmentPlannedDistance: {
    type: PropTypes.number,
    pgType: 'numeric(12,3)',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    default: null
  },

  deliverySegmentPlannedTravelDuration: {
    type: PropTypes.number,
    pgType: 'numeric(12,3)',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    default: null
  },

  deliverySegmentPlannedDwellDuration: {
    type: PropTypes.number,
    pgType: 'numeric(12,3)',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    default: null
  },

  deliverySegmentPlannedTotalDuration: {
    type: PropTypes.number,
    pgType: 'numeric(12,3)',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    default: null
  },

  deliverySegmentActualDistance: {
    type: PropTypes.number,
    pgType: 'numeric(12,3)',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    default: null
  },

  deliverySegmentActualTravelDuration: {
    type: PropTypes.number,
    pgType: 'numeric(12,3)',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    default: null
  },

  deliverySegmentActualDwellDuration: {
    type: PropTypes.number,
    pgType: 'numeric(12,3)',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    default: null
  },

  deliverySegmentActualTotalDuration: {
    type: PropTypes.number,
    pgType: 'numeric(12,3)',
    mode: SERVER_READ | SERVER_WRITE | CLIENT_READ | CLIENT_ROLE_MERCHANT_READ | CLIENT_ROLE_DRIVER_READ,
    default: null
  }
}

const ShipmentOrder = createModel('ShipmentOrder', schema, MODES, {
  ServerReadDto: require('./dto/internal/ShipmentOrderServerReadDto'),
  ServerWriteDto: require('./dto/internal/ShipmentOrderServerWriteDto'),
  ClientReadDto: require('./dto/internal/ShipmentOrderClientReadDto'),
  ClientWriteDto: require('./dto/internal/ShipmentOrderClientWriteDto')
})

const convertOrderToDottedSetters = (order, sidx, complete = false) => {
  const finalOrder = complete
    ? ShipmentOrder.complete(order)
    : order

  return Object.entries(finalOrder || {}).reduce((memo, [fieldName, fieldValue]) => ({
    ...memo,
    [`orders.${sidx}.${fieldName}`]: fieldValue
  }), {})
}

const convertObjectToDottedSetters = (orders) => {
  return Object.entries(orders || {}).reduce((memo, [sidx, order]) => {
    return {
      ...memo,
      ...convertOrderToDottedSetters(order, sidx)
    }
  }, {})
}

const convertArrayToDottedSetters = (orders) => {
  return (orders || []).reduce((memo, order, index) => {
    return {
      ...memo,
      ...convertOrderToDottedSetters(order, String(index))
    }
  }, {})
}

const convertOrdersToArray = (orders, mapFn = (order) => order) => {
  return Object.entries(orders || {}).reduce((memo, [sidx, order]) => {
    const index = Number(sidx)

    memo[index] = {
      ...mapFn(order, index)
    }

    return memo
  }, [])
}

const convertOrdersArrayToObject = (ordersArray, mapFn = (order) => order) => {
  return ordersArray
    .reduce((memo, order, index) => ({
      ...memo,
      [String(index)]: mapFn(order, index)
    }), {})
}

const shouldEnforceRequiresPersonHandoff = ({
  order,
  shipment = null
}) => {
  const linkedParentType = shipment && shipment.linkedParentShipment && shipment.linkedParentShipment.type

  const isReturnBackToMerchant = [
    LINKED_SHIPMENT_TYPES.RETURN_TO_SENDER,
    LINKED_SHIPMENT_TYPES.RETURN_REUSABLES
  ].includes(linkedParentType)

  return (
    order.containsAlcohol &&
    !isReturnBackToMerchant
  )
}

const shouldEnforceLegalAgeAlcohol = ({
  shipment = null,
  order = null
} = {}) => {
  if (shipment && !order) {
    return convertOrdersToArray(shipment.orders)
      .some((order) => order.containsAlcohol)
  } else if (!shipment && order) {
    return Boolean(order.containsAlcohol)
  } else {
    throw new TypeError('Must provide at least shipment or order')
  }
}

exports.ShipmentOrder = ShipmentOrder

exports.convertOrderToDottedSetters = convertOrderToDottedSetters
exports.convertObjectToDottedSetters = convertObjectToDottedSetters
exports.convertArrayToDottedSetters = convertArrayToDottedSetters
exports.convertOrdersToArray = convertOrdersToArray
exports.convertOrdersArrayToObject = convertOrdersArrayToObject

exports.shouldEnforceRequiresPersonHandoff = shouldEnforceRequiresPersonHandoff
exports.shouldEnforceLegalAgeAlcohol = shouldEnforceLegalAgeAlcohol
