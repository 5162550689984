const getDurationObject = require('./getDurationObject')

/**
 * Standardize the display of duration time throughout the packages.
 * If all includeHours, includeMinutes and includeSeconds are false, then it's
 * assumed that includeSeconds is true.
 *
 * @param {number} durationInSeconds
 * @param {Object} [options]
 * @param {Object} [options.includeHours] Determines if hours component is included in the formatted string.
 * @param {Object} [options.includeMinutes] Determines if minutes component is included in the formatted string.
 * @param {Object} [options.includeSeconds] Determines if seconds component is included in the formatted string.
 * @param {Object} [options.isDiff] Determines if this is a duration difference and will always include the sign in the formatted string. Otherwise only include sign when negative.
 * @returns {string}
 */
module.exports = function getFormattedDurationString (durationInSeconds, { includeHours = true, includeMinutes = true, includeSeconds = false, isDiff = false } = {}) {
  const { minutes, hours, seconds } = getDurationObject(durationInSeconds)
  const sign = durationInSeconds < 0 ? '-' : '+'
  // Only show sign if isDiff is true (i.e. we're formatting a duration difference),
  // or the sign is negative.
  const prefix = isDiff ? sign : (sign === '-' ? sign : '')
  const parts = []

  // When includeHours, includeMinutes and includeSeconds are all false then we resort to
  // includeSeconds = true.
  includeSeconds = !includeHours && !includeMinutes && !includeSeconds ? true : includeSeconds

  if (!durationInSeconds) {
    if (includeHours) parts.push('0\u00A0hr')
    else if (includeMinutes) parts.push('0\u00A0min')
    else parts.push('0\u00A0sec')
  } else {
    if (hours && includeHours) {
      parts.push(`${hours}\u00A0hr`)
    }

    if (minutes && includeMinutes) {
      parts.push(`${minutes}\u00A0min`)
    }

    if (seconds && includeSeconds) {
      parts.push(`${Math.round(seconds)}\u00A0sec`)
    }
  }

  if (parts.length && prefix) {
    parts.unshift(prefix)
  }

  return parts.join('\u00A0')
}
