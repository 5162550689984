/* eslint-disable camelcase */
const polylineToGeoJson = require('./polylineToGeoJson')

/*
 * RF: We are typically converting the overall route
 * (e.g. google maps directions result -> overview_polyline.points)
 * into GeoJSON compliant structure
 *
 * The geometry is actually "LineString" (https://tools.ietf.org/html/rfc7946 section 3.1.4)
 * which basically pieces together line segments.
 */

function mapTravelModeFromGoogleMapsDirections (inputTravelMode) {
  switch (inputTravelMode) {
    case 'DRIVING':
      return 'driving'
    default:
      throw new Error('Unsupported travelMode: ' + inputTravelMode)
  }
}

module.exports = function googleMapsDirectionsToGeoJsonDirections (
  directionsResult = {},
  fallbackTravelMode = 'DRIVING'
) {
  if (!directionsResult) {
    return null
  }

  const routes = directionsResult.routes || []

  // routes will always be an array of 1, except if we use 'provideRouteAlternatives' which we don't.

  const route = routes[0] || {}

  const bounds = route.bounds && (
    (typeof route.bounds.toJSON === 'function')
      ? route.bounds.toJSON()
      : route.bounds
  )

  const overviewPolyline = route.overview_polyline

  const travelMode = mapTravelModeFromGoogleMapsDirections(
    (directionsResult.request && directionsResult.request.travelMode) ||
    fallbackTravelMode
  )

  const legs = route.legs && route.legs.map(({
    distance = {},
    duration = {},
    end_location = null,
    start_location = null,
    steps = []
  }) => ({
    distance: distance.value,
    duration: duration.value,
    startLocation: start_location && {
      latitude: typeof start_location.lat === 'function' ? start_location.lat() : start_location.lat,
      longitude: typeof start_location.lng === 'function' ? start_location.lng() : start_location.lng
    },
    endLocation: end_location && {
      latitude: typeof end_location.lat === 'function' ? end_location.lat() : end_location.lat,
      longitude: typeof end_location.lng === 'function' ? end_location.lng() : end_location.lng
    },
    steps: steps.map(({
      travel_mode,
      polyline,
      duration,
      distance
    }) => ({
      travelMode: mapTravelModeFromGoogleMapsDirections(travel_mode),
      geoJsonPolyline: polylineToGeoJson(polyline.points),
      distance: distance.value,
      duration: duration.value
    }))
  }))

  return polylineToGeoJson(route.overview_polyline, {
    bounds,
    legs,
    travelMode,
    overviewPolyline: polylineToGeoJson(overviewPolyline)
  })
}
