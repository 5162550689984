import React from 'react'
import { GDPR } from '@trexity/common/gdpr/constants'
import { PHONE_EXTENSIONS } from '@trexity/common/phone/constants'
import { getFormattedMonetaryValueString } from '@trexity/common/pricing'

const { STORED_EXTENSION, DISPLAYED_EXTENSION } = PHONE_EXTENSIONS

const getSourceFromOriginData = (originSource, originMetadata) => {
  let source = originSource || ''

  const additionalApiDetails = originMetadata?.integrationDetails || ''
  const additionalCsvDetails = originMetadata?.csvFormat || ''

  if (source && additionalApiDetails) {
    source = `${source} (${additionalApiDetails})`
  }

  if (source && additionalCsvDetails) {
    source = `${source} (${additionalCsvDetails})`
  }

  return source
}

/**
 * @param {any} shipment Shipment model/entity
 * @param {any} order The order object that is associated to the shipment
 * @param {object} options
 * @param {node} options.Popover
 */
function shipmentOrderToViewModel (shipment, order, {
  Popover,
  isAdmin
}) {
  const { currentStatus } = shipment || {}

  order = order || {}

  const isInProgress = (
    currentStatus === 'WAITING_TO_START' ||
    currentStatus === 'EN_ROUTE_TO_PICKUP' ||
    currentStatus === 'ARRIVED_AT_PICKUP' ||
    currentStatus === 'OUT_FOR_DELIVERY' ||
    currentStatus === 'ARRIVED_AT_DELIVERY'
  )

  const metadata = order.metadata || {}

  const tipsTotal = [
    metadata.driver_customerTip_amount
  ].filter(Boolean).reduce((memo, amount) => memo + amount, 0)

  let label = ''

  if (order.labelValid) {
    label = 'labelled'
  } else if (!order.labelValid && order.labeledAt) {
    if (['OUT_FOR_DELIVERY', 'ARRIVED_AT_DELIVERY', 'DELIVERED'].includes(currentStatus)) {
      label = 'outdated'
    } else {
      label = 'invalid'
    }
  } else if (!order.labeledAt) {
    label = 'unlabelled'
  }

  const source = getSourceFromOriginData(order?.metadata?.originSource, order?.metadata?.originMetadata)

  const result = {
    id: order.id,
    orderId: order.orderId,
    address: order.address,
    addressNotes: order.addressNotes,
    description: order.description,
    name: order.name,
    label,
    email: (
      !order.email || order.email === GDPR.REDACTED_KEYWORD ? (
        order.email || ''
      ) : order.email
    ),
    phone: (
      !order.phone || order.phone === GDPR.REDACTED_KEYWORD ? (
        order.phone || ''
      ) : order.phone.replace(STORED_EXTENSION, DISPLAYED_EXTENSION)
    ),
    value: getFormattedMonetaryValueString(order.value),
    tip: (
      tipsTotal ? (
        <Popover
          content={(
            <ul style={{ paddingLeft: 16, marginBottom: 0 }}>
              {metadata.driver_customerTip_amount ? <li>Customer: <strong>{getFormattedMonetaryValueString(metadata.driver_customerTip_amount)}</strong></li> : null}
            </ul>
          )}
          title='Tips'
        >{getFormattedMonetaryValueString(tipsTotal)}</Popover>
      ) : null
    ),
    handoff: order.requiresPersonHandoff ? 'Yes' : 'No',
    handoffPin: order.personHandoffPin || '',
    alcohol: order.containsAlcohol ? 'Yes' : 'No',
    deliveryInstructions: order.deliveryInstructions || '',
    status: (
      order.deliveredAt && !order.undeliverable && !order.misdelivered ? (
        'Delivered'
      ) : order.deliveredAt && order.undeliverable && !order.misdelivered ? (
        'Undeliverable'
      ) : isAdmin && order.deliveredAt && order.misdelivered ? (
        'Misdelivered'
      ) : isInProgress ? (
        'In Progress'
      ) : (
        'Waiting'
      )
    ),
    source
  }

  if (isAdmin) {
    result.misdelivered = Boolean(order.misdelivered)
  }

  return result
}

export { shipmentOrderToViewModel }
