import React from 'react'
import PropTypes from 'prop-types'
import { Skeleton } from 'antd'
import ShipmentDrivers from '../ShipmentDrivers'

export default function Drivers ({
  deps
}) {
  const {
    current,
    send,
    data,
    RouteLink
  } = deps

  const {
    shipmentDashboardShipmentId: shipmentId,
    shipmentDashboardShipment: shipment,
    shipmentDashboardEventLogs: eventLogs,
    shipmentDashboardLatestDisqualificationLogs: disqualificationLogs
  } = data

  const isUndismissingDriver = current.within('Undismissing Shipment For Driver')
  const isRenouncingDriver = current.within('Renouncing Shipment For Driver')
  const isAcceptingDriver = current.within('Accepting Shipment For Driver')

  const isLoading = !current.within('Loaded Background Drivers')
  const isReady = current.within('Showing Drivers')

  return (
    isReady ? (
      <ShipmentDrivers
        shipment={shipment}
        eventLogs={eventLogs}
        // ------
        isLoading={isLoading}
        reloadDrivers={() => send('reload drivers')}
        // ------
        isUndismissingDriver={isUndismissingDriver}
        isRenouncingDriver={isRenouncingDriver}
        isAcceptingDriver={isAcceptingDriver}
        // ------
        undismissDriverId={(driverId) => send({ type: 'undismiss shipment', driverId, shipmentId })}
        renounceShipment={() => send({ type: 'renounce shipment', shipmentId })}
        acceptDriverId={(driverId) => send({ type: 'accept shipment', driverId, shipmentId })}
        // ------
        disqualificationLogs={disqualificationLogs}
        reloadLatestDisqualificationLogs={() => send('reload latest disqualification logs')}
        showLatestDisqualificationLogs={() => send('show latest disqualification logs')}
        hideLatestDisqualificationLogs={() => send('hide latest disqualification logs')}
        isShowingLatestDisqualificationLogs={current.within('Showing Drivers Latest Disqualification Logs')}
        isLoadingLatestDisqualificationLogs={current.within('Loading Drivers Latest Disqualification Logs')}
        // ------
        deps={{
          RouteLink
        }}
      />
    ) : (
      <Skeleton active />
    )
  )
}

Drivers.propTypes = {
  deps: PropTypes.exact({
    send: PropTypes.func,
    current: PropTypes.object,
    data: PropTypes.object,
    tc: PropTypes.object,
    RouteLink: PropTypes.func
  }).isRequired
}
