const { Std, Check } = require('../../core')
const { isServiceHours } = require('./isServiceHours')
const { isCalendarDay } = require('./isCalendarDay')
const { pad } = require('./pad')

/**
 * Determines if a calendar day is closed without considering the weekday it falls on.
 * The weekday is not considered since the weekday for a calendar day is different every year.
 *
 * @param {ServiceHours} serviceHours
 * @param {string|[number,number]|Date} day String like 'MM-DD' or tuple of [month, date], where month in tuple form is 1-based, or Date instance (uses local month and date)
 * @return {boolean}
 */
function isCalendarDayClosed (serviceHours, day) {
  if (!isServiceHours(serviceHours)) {
    throw Std.err('TypeError', 'Service hours is invalid')
  }

  if (Check.isdate(day)) {
    day = [day.getMonth() + 1, day.getDate()]
  }

  if (Check.and(Check.isarr, Check.of(Check.isint), (x) => x.length === 2)(day)) {
    day = `${pad(day[0])}-${pad(day[1])}`
  }

  if (!isCalendarDay(day)) {
    throw Std.err('ArgumentError', `calendar day is invalid : ${day}`)
  }

  if (!serviceHours.calendarDays || serviceHours.calendarDays.length === 0) {
    return false
  }

  const deliveryDay = serviceHours.calendarDays.find((d) => d.day === day)
  return Boolean(deliveryDay && deliveryDay.ranges.length === 0)
}

exports.isCalendarDayClosed = isCalendarDayClosed
