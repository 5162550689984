const checkPropTypes = require('./check-prop-types')
const EventEmitter = require('./events')

module.exports = {
  checkPropTypes,
  assertPropTypes: checkPropTypes.assertPropTypes,
  EventEmitter,
  once: EventEmitter.once,
  GeoJSON: require('./geojson'),
  ...require('./md5'),
  PropTypes: require('./prop-types'),
  ...require('./react-is')
}
