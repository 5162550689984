const boundsToCenter = require('./boundsToCenter')
const calculateDistance = require('./calculateDistance')
const { EARTH_RADIUS_METERS } = require('./constants')
const rad2deg = require('./rad2deg')
const deg2rad = require('./deg2rad')

module.exports = function boundsToRegion (bounds, aspectRatio = 1, margin = 1) {
  const [latitude, longitude] = boundsToCenter(bounds)
  const R = (calculateDistance(bounds[0], { latitude, longitude })) * margin

  return {
    latitude,
    longitude,
    ...calculateRegionDelta({ latitude }, R, aspectRatio)
  }
}

const calculateRegionDelta = ({ latitude }, radiusInMeters, aspectRatio = 1) => {
  const radiusInRad = radiusInMeters / EARTH_RADIUS_METERS
  const longitudeDelta = rad2deg(radiusInRad / Math.cos(deg2rad(latitude)))
  const latitudeDelta = aspectRatio * rad2deg(radiusInRad)

  return {
    latitudeDelta,
    longitudeDelta
  }
}
