const { convertOrdersToArray } = require('../models/ShipmentOrder')
const { sorter } = require('../sort')
const { sortOrdersByDirectionsResult } = require('./sortOrdersByDirectionsResult')
const { decodeItineraryItemName } = require('./decodeItineraryItemName')

function sortOrdersByShipmentAndDriver ({
  shipment,
  driver = null
} = {}) {
  // Prefer cached snapshot of shipment over driver results
  const routePlanResults = (
    shipment.cachedRoutePlanResults && Array.isArray(shipment.cachedRoutePlanResults) && shipment.cachedRoutePlanResults.length ? shipment.cachedRoutePlanResults
      : driver && driver.routePlanResults && Array.isArray(driver.routePlanResults) && driver.routePlanResults.length ? driver.routePlanResults
        : null
  )

  const routePlanResult = extractRoutePlanResult(routePlanResults, shipment)

  const directionsResult = routePlanResult && routePlanResult.data
    ? routePlanResult.data.directionsResult
    : shipment.directionsResult

  return sortOrdersByDirectionsResult({
    orders: shipment.orders,
    directionsResult
  })
}

function extractRoutePlanResult (cachedRoutePlanResults, shipment) {
  const routePlanResults = (
    Array.isArray(cachedRoutePlanResults) && cachedRoutePlanResults.length
      ? cachedRoutePlanResults
      : null
  )

  // Make sure that we only retain routePlanResults relevant to this shipment
  const involvedRoutePlanResults = Array.isArray(routePlanResults)
    ? routePlanResults
      .filter(({ shipmentIds }) => shipmentIds.includes(shipment.id))
    : null

  if (Array.isArray(involvedRoutePlanResults) && involvedRoutePlanResults.length) {
    const parsedRoutePlanResults = parseRoutePlanResults(involvedRoutePlanResults)
    const combinedRoutePlanResult = combineRoutePlanResults(parsedRoutePlanResults, shipment)
    return combinedRoutePlanResult
  }

  return null
}

function parseRoutePlanResults (unparsedRoutePlanResults) {
  return unparsedRoutePlanResults.map((routePlanResult) => {
    try {
      return {
        ...routePlanResult,
        data: JSON.parse(routePlanResult.data)
      }
    } catch (error) {
      console.log('parseRoutePlanResults given invalid routePlanResult', { error })

      return {
        ...routePlanResult,
        data: null
      }
    }
  })
}

// Build a unified single routePlan based on all route plans combined. It is
// basically re-creating a single route plan itinerary & directionsResult that
// reflects the final outcome of what has transpired.
// For example, if a delivery is removed in-flight, that entire segment should
// disappear from existence since it could never be delivered. Same with a
// delivery added in-flight, as the final count of orders in a shipment should
// match the count of deliveries in the merged route plan

function combineRoutePlanResults (routePlanResults, shipment) {
  // Sort routePlanResults in ascending order of when they were created. We will
  // be iterating over them in that order, where the very first (earliest) one
  // will be our "main" one that we patch up with newer ones in order
  const sortedRoutePlanResults = [].concat(routePlanResults).sort(sorter('createdAt', { direction: 'asc' }))

  // Start with the initial route plan. Note that it is modified by reference
  // throughout this code.
  const mainRoutePlanResult = sortedRoutePlanResults.shift()

  // Clear out delivery segments that no longer exist (i.e. was in a route plan,
  // but an admin deleted it or moved it out of the route)
  removeMissingDeliverySegments(mainRoutePlanResult, shipment)

  for (const routePlanResult of sortedRoutePlanResults) {
    transplantNewerSegments(mainRoutePlanResult, routePlanResult, shipment)
  }

  return mainRoutePlanResult
}

function removeMissingDeliverySegments (mainRoutePlanResult, shipment) {
  const ordersArray = convertOrdersToArray(shipment.orders)
  const orderUuids = ordersArray.map((order) => order.id)
  const deliveryIndexes = ordersArray.map((order, idx) => idx)

  const instSplices = []
  const legsSplices = []
  let legIdx = -1

  for (let idx = 0; idx < mainRoutePlanResult.data.itinerary.instructions.length; idx++) {
    const inst = mainRoutePlanResult.data.itinerary.instructions[idx]

    if (inst.instructionType === 'TravelBetweenLocations') {
      const visitInst = mainRoutePlanResult.data.itinerary.instructions[idx + 1]
      legIdx++

      if (visitInst.instructionType === 'VisitLocation') {
        const { type, orderUuid, deliveryIndex } = decodeItineraryItemName(visitInst.itineraryItem.name)

        if (type === 'delivery') {
          if (orderUuid !== null && !orderUuids.includes(orderUuid)) {
            instSplices.push(idx)
            legsSplices.push(legIdx)
          } else if (deliveryIndex !== null && !deliveryIndexes.includes(deliveryIndex)) {
            instSplices.push(idx)
            legsSplices.push(legIdx)
          }
        }
      }
    }
  }

  instSplices.reverse()
  legsSplices.reverse()

  for (const idx of instSplices) {
    mainRoutePlanResult.data.itinerary.instructions.splice(idx, 2)
  }

  for (const idx of legsSplices) {
    mainRoutePlanResult.data.directionsResult.routes[0].legs.splice(idx, 1)
  }
}

function transplantNewerSegments (mainRoutePlanResult, routePlanResult, shipment) {
  const planCreatedDate = extractDate(routePlanResult.createdAt)

  // Replace the main route plan result right after the last visit that was
  // finished. If a new delivery/pickup is added as the very first one, the
  // travel segment preceeding it will be from another segment, so that won't
  // be accurate. But we have no other choice at this point.
  const lastFinishedVisitIndex = findIndexFromRight(mainRoutePlanResult.data.itinerary.instructions, (inst) => {
    if (inst.instructionType !== 'VisitLocation') return false

    const { type, shipmentId, orderUuid, deliveryIndex } = decodeItineraryItemName(inst.itineraryItem.name)
    if (shipmentId !== shipment.id) return false

    return isFinished(type, shipment, planCreatedDate, { orderUuid, deliveryIndex })
  })

  // If nothing was finished, this route plan should replace the main one
  // in its entirety
  if (lastFinishedVisitIndex === -1) {
    mainRoutePlanResult.data.itinerary.instructions = routePlanResult.data.itinerary.instructions
    mainRoutePlanResult.data.directionsResult.routes[0].legs = routePlanResult.data.directionsResult.routes[0].legs
  } else {
    // Grab the itinerary from the first VisitLocation's previous
    // TravelBetweenLocations and on. Anything coming from this itinerary has not
    // been completed yet, so we want to grab those. We're really only excluding
    // the LeaveFromStartPoint.
    const firstTravelIndex = routePlanResult.data.itinerary.instructions
      .findIndex((inst) => inst.instructionType === 'VisitLocation') - 1

    const relevantInstructions = routePlanResult.data.itinerary.instructions
      .slice(firstTravelIndex)

    // In the case of legs, we want them all since they're all relevant
    const relevantLegs = routePlanResult.data.directionsResult.routes[0].legs
      .slice()

    const instReplaceIndex = lastFinishedVisitIndex + 1 // +1 to the following Travel

    mainRoutePlanResult.data.itinerary.instructions.splice(
      instReplaceIndex,
      mainRoutePlanResult.data.itinerary.instructions.length, // wipe everything after
      ...relevantInstructions
    )

    // We can infer the leg index based on instReplaceIndex due to the known
    // patterns of how they are constructed. The replace index we get for
    // the instructions is against a Visit
    // inst: [0] Start -> [1] Travel -> [2] Visit -> [3] Travel -> [4] Visit -> [5] Travel -> [6] Visit
    // legs:              [0] Travel         ->      [1] Travel         ->      [2] Travel
    const legsReplaceIndex = (instReplaceIndex / 2) - 1

    mainRoutePlanResult.data.directionsResult.routes[0].legs.splice(
      legsReplaceIndex + 1, // +1 to the following leg
      mainRoutePlanResult.data.directionsResult.routes[0].legs.length, // wipe everything after
      ...relevantLegs
    )
  }
}

function extractDate (value) {
  return value ? (
    value.toDate ? value.toDate()
      : value.seconds ? new Date(value.seconds * 1000)
        : new Date(value)
  ) : null
}

function extractOrder (ordersArray, orderUuid, deliveryIndex) {
  return ordersArray.find((order, idx) => {
    if (orderUuid !== null) {
      return order.id === orderUuid
    } else if (deliveryIndex !== null) {
      return idx === deliveryIndex
    } else {
      return false
    }
  })
}

function isFinished (type, shipment, planCreatedDate, { orderUuid = null, deliveryIndex = null } = {}) {
  const ordersArray = convertOrdersToArray(shipment.orders)

  if (type === 'pickup') {
    const pickedUpDate = extractDate(shipment.driverPickedUpAt)
    if (pickedUpDate && pickedUpDate < planCreatedDate) return true
  }

  if (type === 'delivery') {
    const order = extractOrder(ordersArray, orderUuid, deliveryIndex) || {}
    const deliveredDate = extractDate(order.deliveredAt)
    if (deliveredDate && deliveredDate < planCreatedDate) return true
  }

  return false
}

function findIndexFromRight (arr, pred) {
  const rev = [].concat(arr)
  rev.reverse()
  const revIdx = rev.findIndex(pred)
  return revIdx !== -1 ? (arr.length - 1) - revIdx : -1
}

exports.sortOrdersByShipmentAndDriver = sortOrdersByShipmentAndDriver
exports.extractRoutePlanResult = extractRoutePlanResult
exports.parseRoutePlanResults = parseRoutePlanResults
exports.combineRoutePlanResults = combineRoutePlanResults
