/* eslint-disable no-new */
module.exports = function validateUrl (url) {
  /* RF: This is way, way too forgiving function.
   * Use it in combination with something else (see validateHttpsUrl).
   */
  try {
    new URL(url)

    return true
  } catch (error) {
    return false
  }
}
