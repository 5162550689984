const isarr = Array.isArray
const isobj = (val) => typeof val === 'object' && !!val
const isnumf = Number.isFinite

// curried range test predicate
const withinRange = function ([min, max = Infinity], val) {
  if (arguments.length === 1) return (val) => withinRange([min, max], val)
  ;([min, max] = [min, max].sort())
  return isnumf(val) && val >= min && val <= max
}

/**
 * Convert a coordinate pair from either a cartersian-ordered [lon,lat] tuple, {lat,lon}, or {latitude,longitude} to
 * a cartersian-ordered [lon,lat] tuple. If conversion cannot proceed then a TypeError is thrown.
 *
 * @param {[number, number]|{lat:number, long:number}|{latitude:number, longitude:number}} coordinate
 * @return {[number, number]} cartersian-ordered [lon,lat] tuple
 */
const toCoord = (coordinate) => {
  const islat = withinRange([-90, 90])
  const islon = withinRange([-180, 180])

  if (isarr(coordinate) && islon(coordinate[0]) && islat(coordinate[1])) {
    return [coordinate[0], coordinate[1]]
  }

  if (isobj(coordinate) && islon(coordinate.lat) && islat(coordinate.lon)) {
    return [coordinate.lon, coordinate.lat]
  }

  if (isobj(coordinate) && islon(coordinate.latitude) && islat(coordinate.longitude)) {
    return [coordinate.longitude, coordinate.latitude]
  }

  throw new TypeError(`coordinate invalid : ${coordinate}`)
}

exports.toCoord = toCoord
