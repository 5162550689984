/*
A component that takes a MerchantDiscountProgram object (same shape as the entity),
and displays it in compact format. Compact format shows the discount per delivery
and the the total discount per activated merchant that can be applied and its constraints.

The total discount and its constraints must be revealed via tooltip.
*/

import React from 'react'
import * as PropTypes from 'prop-types'
import { Icon, Tooltip } from 'antd'
import { getFormattedMonetaryValueString } from '@trexity/common/pricing'
import { MerchantDiscountLogic } from '@trexity/common/merchantDiscounts'

export default function MerchantDiscountDisplayCompact ({ discountProgram }) {
  const dp = new MerchantDiscountLogic(discountProgram)
  const { type, percentage, numberOfDeliveries } = discountProgram
  const individualMerchantBudget = dp.getIndividualMerchantBudget()
  const discountPerDelivery = dp.getDiscountPerDelivery()

  if (type === 'fixed') {
    return (
      <div>
        up to {getFormattedMonetaryValueString(discountPerDelivery || 0)} <Tooltip title={<span>up to a total of {getFormattedMonetaryValueString(individualMerchantBudget || 0)} across {numberOfDeliveries} delivered deliveries</span>}><Icon type='info-circle' /></Tooltip>
      </div>
    )
  }

  if (type === 'percentage') {
    return (
      <div>
        {percentage}% <Tooltip title={<span>up to a total of {getFormattedMonetaryValueString(individualMerchantBudget || 0)} across all delivered deliveries</span>}><Icon type='info-circle' /></Tooltip>
      </div>
    )
  }
}

MerchantDiscountDisplayCompact.propTypes = {
  discountProgram: PropTypes.shape({
    type: PropTypes.oneOf(['fixed', 'percentage']),
    percentage: PropTypes.number,
    totalBudget: PropTypes.number.isRequired,
    numberOfSeats: PropTypes.number.isRequired,
    numberOfDeliveries: PropTypes.number.isRequired
  })
}
