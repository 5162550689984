import React from 'react'
import PropTypes from 'prop-types'
import { HeadingBase } from './heading.styles'

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'

export const H2 = ({
  children,
  color,
  style,
  ...rest
}) => {
  return (
    <h2 css={[HeadingBase, Heading2Styles(color), style]} {...rest}>{children}</h2>
  )
}

export const Heading2Styles = (color) => ({
  fontSize: '30px',
  color: color
})

H2.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  style: PropTypes.object
}
