const ISO_EXTENDED_FORMAT = /^(\d{4})-(0[1-9]|1[0-2])-([0-2][1-9]|3[0-1])$/u
const ISO_BASIC_FORMAT = /^(\d{4})(0[1-9]|1[0-2])([0-2][1-9]|3[0-1])$/u
const JULIAN_FORMAT = /^(\d{4})-(\d{3})$/u

/**
 * Represents a timezone agnostic date-only value on the Gregorian calendar.
 */
class DateOnly {
  static daysInYear (year) {
    if (year <= 0) {
      return 0
    }

    return isLeapYear(year) ? 366 : 365
  }

  /**
   * Determines the number of days in a month.
   *
   * @param {number} year
   * @param {number} month
   */
  static daysInMonth (year, month) {
    if (!Number.isSafeInteger(year) || year < 0) {
      throw new TypeError('Argument "year" must be a positive integer')
    }

    if (!Number.isSafeInteger(month) || month < 0) {
      throw new TypeError('Argument "month" must be a positive integer')
    }

    if (month > 12) {
      throw new TypeError('Argument "month" is out of range')
    }

    return daysInMonth(year, month)
  }

  /**
   * Attempts to create a new DateOnly value from a string or DateOnly value. Returns
   * a notification with all errors.
   *
   * When a string is passed in, the following formats are supported:
   *
   * ISO extended: YYYY-MM-DD
   * ISO basic: YYYYMMDD
   * Julian: YYYY-DDD
   *
   * @param {string|DateOnly} value
   * @return {{ errors: string[], value?: DateOnly }}
   */
  static from (value) {
    const notification = { errors: [], value: null }

    if (value instanceof DateOnly) {
      notification.value = new DateOnly(value.year, value.month, value.day)
      return notification
    }

    if (typeof value !== 'string') {
      notification.errors.push('can only convert string values')
      return notification
    }

    let year = 0
    let month = 1
    let day = 1

    let match = ISO_EXTENDED_FORMAT.exec(value) || ISO_BASIC_FORMAT.exec(value)

    if (match) {
      year = parseInt(match[1], 10)
      month = parseInt(match[2], 10)
      day = parseInt(match[3], 10)
    } else {
      match = JULIAN_FORMAT.exec(value)

      if (match) {
        year = parseInt(match[1], 10)
        day = parseInt(match[2], 10)

        const maxDay = isLeapYear(year) ? 366 : 365

        if (day > maxDay) {
          notification.errors.push('day out of range')
        } else {
          const d = new Date(year, 0, 1)
          d.setDate(day)
          year = d.getFullYear()
          month = d.getMonth() + 1
          day = d.getDate()
        }
      } else {
        notification.errors.push('cannot convert date value')
      }
    }

    if (month > 12) {
      notification.errors.push('month out of range')
    }

    if (day > daysInMonth(year, month)) {
      notification.errors.push('day out of range')
    }

    if (!notification.errors.length) {
      notification.value = new DateOnly(year, month, day)
    }

    return notification
  }

  /**
   * Constructs a new date-only value, where month is 1-based.
   *
   * Only valid dates are accepted (i.e. if a month or day is out of range the
   * date will not wrap). An ArgumentError will be thrown otherwise.
   *
   * @example
   * new DateOnly(2019, 1, 1).toString() // 2019-01-01
   * @param {number} year
   * @param {number} month
   * @param {number} day
   */
  constructor (year, month, day) {
    if (!Number.isSafeInteger(year) || year < 0) {
      throw new TypeError('Argument "year" must be a positive integer')
    }

    if (!Number.isSafeInteger(month) || month <= 0) {
      throw new TypeError('Argument "month" must be a positive integer not including 0')
    }

    if (month > 12) {
      throw Object.assign(
        new Error(`Argument "month" out of range: ${month}`),
        {
          name: 'ArgumentError',
          argName: 'month',
          argValue: month
        }
      )
    }

    if (!Number.isSafeInteger(day) || day <= 0) {
      throw new TypeError('Argument "day" must be a positive integer not including 0')
    }

    if (day > daysInMonth(year, month)) {
      throw Object.assign(
        new Error(`Argument "day" out of range 1-${daysInMonth(year, month)}: ${day}`),
        {
          name: 'ArgumentError',
          argName: 'day',
          argValue: day
        }
      )
    }

    this._year = year
    this._month = month
    this._day = day
  }

  /**
   * @type {number}
   */
  get year () {
    return this._year
  }

  /**
   * @type {number}
   */
  get month () {
    return this._month
  }

  /**
   * @type {number}
   */
  get day () {
    return this._day
  }

  /**
   * @type {boolean}
   */
  get isLeapYear () {
    return isLeapYear(this.year)
  }

  /**
   * @param {DateOnly} other
   * @return {number}
   */
  compare (other) {
    if (other instanceof DateOnly) {
      return this.valueOf() - other.valueOf()
    } else {
      throw new Error('Argument "other" must be a DateOnly instance')
    }
  }

  /**
   * The absolute magnitude of this DateOnly object in days.
   */
  valueOf () {
    return DateOnly.daysInYear(this.year - 1) +
      DateOnly.daysInMonth(this.year, this.month - 1) +
      this.day
  }

  /**
   * ISO-extended string representation.
   */
  toString () {
    return `${pad(this.year, { padding: 4 })}-${pad(this.month)}-${pad(this.day)}`
  }
}

// See: https://docs.microsoft.com/en-us/office/troubleshoot/excel/determine-a-leap-year
const isLeapYear = (year) => {
  const y = year

  if (y % 4 === 0) {
    if (y % 100 === 0) {
      if (y % 400 === 0) {
        return true
      }
    } else {
      return true
    }
  }

  return false
}

const daysInMonth = (year, month) => {
  if (year <= 0 || month <= 0) {
    return 0
  }

  // This is a hack to get the last day of the month.
  // The month is 1-based, not 0-based, and this works as expected
  // since Date goes to the NEXT month then tries to go 1 day
  // in the past to last day of the previous month (i.e. the month we
  // actually want).
  return new Date(year, month, 0).getDate()
}

const pad = (n, { padding = 2 } = {}) => {
  let s = n.toString()

  while (s.length < padding) {
    s = `0${s}`
  }

  return s
}

exports.DateOnly = DateOnly
exports.isLeapYear = isLeapYear
