import React, { useRef, useEffect, useState } from 'react'
import { gsap, Power3 } from 'gsap'
import { COLORS } from '@trexity/common/color/constants'
import { useMediaQuery } from 'react-responsive'
import PropTypes from 'prop-types'
import { MEDIA_QUERIES } from '../../constants'

const { MQ_MEDIUM, MQ_XLARGE, MQ_LARGE } = MEDIA_QUERIES

export const Loader = ({ active = false, color = COLORS.TREXITY_MERCHANT_BLUE, small = false }) => {
  const t1Ref = useRef()
  const rRef = useRef()
  const eRef = useRef()
  const xRef = useRef()
  const iRef = useRef()
  const t2Ref = useRef()
  const yRef = useRef()
  const h1Ref = useRef()

  const isTablet = useMediaQuery(MQ_MEDIUM)
  const isDesktop = useMediaQuery(MQ_LARGE)
  const isDesktopXLarge = useMediaQuery(MQ_XLARGE)
  const xSwingBack = isDesktop && !small ? -50 : -20
  const xSwingForward = isDesktop && !small ? 30 : 10
  const [tl] = useState(gsap.timeline({ repeat: -1, loop: true }))

  useEffect(() => {
    const which = Math.floor(Math.random() * 2)

    if (active) {
      tl.yoyo(true)
      tl.repeat(-1)

      if (which) {
        tl.fromTo(t1Ref.current, { x: 0, rotate: 0, y: 0 }, { x: xSwingBack, duration: 0.6, ease: Power3.easeOut, rotate: 30, y: -10 })
        tl.to(t1Ref.current, { x: xSwingForward, duration: 0.3, ease: Power3.easeIn, rotate: 0, y: 0 })
        tl.fromTo([rRef.current, eRef.current, xRef.current, iRef.current, t2Ref.current, yRef.current], { x: 0 }, { x: xSwingForward, duration: 0.2, stagger: 0.05 })
        tl.to([t1Ref.current, rRef.current, eRef.current, xRef.current, iRef.current, t2Ref.current, yRef.current].reverse(), { x: 0, duration: 0.3, stagger: 0.1, delay: 0.5 })
        tl.to(h1Ref.current, { scale: 1.1 })
        tl.to(h1Ref.current, { scale: 1 })
      } else if (which === 0) {
        tl.fromTo(
          [t1Ref.current, rRef.current, eRef.current, xRef.current, iRef.current, t2Ref.current, yRef.current],
          { scaleX: 1, scaleY: 1, y: 0 },
          { scaleX: 1.1, scaleY: 0.1, y: -30, transformOrigin: 'bottom', duration: 0.4, stagger: 0.05 })

        tl.to(
          [t1Ref.current, rRef.current, eRef.current, xRef.current, iRef.current, t2Ref.current, yRef.current],
          { scaleX: 1, scaleY: 1, duration: 0.4, stagger: 0.1, delay: 0.5 }, '-=0.7'
        )

        tl.to(
          [t1Ref.current, rRef.current, eRef.current, xRef.current, iRef.current, t2Ref.current, yRef.current],
          { y: 0, duration: 0.6, stagger: 0.1, delay: 0.5 }, '-=1'
        )
      }
    } else {
      tl.yoyo(false)
      tl.repeat(0)

      tl.to(
        [t1Ref.current, rRef.current, eRef.current, xRef.current, iRef.current, t2Ref.current, yRef.current],
        { y: 0, x: 0, scaleY: 1, scaleX: 1, rotate: 0 }
      )
    }

    return () => {
      tl.clear()
    }
  }, [isDesktop, active])

  const spanStyle = { display: 'inline-block' }
  let fontSize = 36

  if (!small) {
    if (isDesktopXLarge) {
      fontSize = 80
    } else if (isDesktop) {
      fontSize = 38
    } else if (isTablet) {
      fontSize = 32
    }
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <h1
        style={{
          fontWeight: 400,
          color,
          fontSize,
          marginBottom: 0,
          fontFamily: 'CaslonGraphiqueEF',
          letterSpacing: '-0.05em',
          position: 'relative',
          transition: 'font-size ease-in-out 0.3s'
        }}
        ref={h1Ref}
      >
        <span ref={t1Ref} style={spanStyle}>T</span>
        <span ref={rRef} style={spanStyle}>r</span>
        <span ref={eRef} style={spanStyle}>e</span>
        <span ref={xRef} style={spanStyle}>x</span>
        <span ref={iRef} style={spanStyle}>i</span>
        <span ref={t2Ref} style={spanStyle}>t</span>
        <span ref={yRef} style={spanStyle}>y</span>
        {/* <span
          ref={tradeRef}
          style={{
            fontSize: 12,
            top: '20%',
            border: '1px solid white',
            borderRadius: '50%',
            position: 'absolute',
            right: -20,
            width: 20,
            height: 20,
            textAlign: 'center',
            lineHeight: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        ><span style={{ position: 'relative', top: 2, left: 1 }}>
          &trade;
        </span>
      </span> */}
      </h1>
    </div>
  )
}

Loader.propTypes = {
  active: PropTypes.bool,
  color: PropTypes.string,
  small: PropTypes.bool
}
