import React from 'react'
import PropTypes from 'prop-types'
import { Tag, Tooltip } from 'antd'

export default function MerchantDiscountStatusTag ({
  status = null,
  placement = 'bottom',
  ...remainingProps
}) {
  if (status === 'suspended') {
    return (
      <Tooltip title='Program is not accepting further activations and discounts will no longer apply.' placement={placement}>
        <Tag color='gold' {...remainingProps}>Suspended</Tag>
      </Tooltip>
    )
  } else if (status === 'active') {
    return (
      <Tooltip title='Program is actively applying discounts to deliveries.' placement={placement}>
        <Tag color='green' {...remainingProps}>Active</Tag>
      </Tooltip>
    )
  } else if (status === 'open') {
    return (
      <Tooltip title='Program may accept new activations and discounts will be applied.' placement={placement}>
        <Tag color='blue' {...remainingProps}>Open</Tag>
      </Tooltip>
    )
  } else if (status === 'pending') {
    return (
      <Tooltip title='Program has not started yet.' placement={placement}>
        <Tag color='cyan' {...remainingProps}>Pending</Tag>
      </Tooltip>
    )
  } else if (status === 'closed') {
    return (
      <Tooltip title='Program has concluded.' placement={placement}>
        <Tag color='red' {...remainingProps}>Closed</Tag>
      </Tooltip>
    )
  } else {
    return 'N/A'
  }
}

MerchantDiscountStatusTag.propTypes = {
  status: PropTypes.string,
  placement: PropTypes.oneOf(['top', 'left', 'right', 'bottom', 'topLeft', 'topRight', 'bottomLeft', 'bottomRight', 'leftTop', 'leftBottom', 'rightTop', 'rightBottom'])
}
