const { Std } = require('../../core')
const { Time } = require('../../types')
const { isServiceHours } = require('./isServiceHours')
const { toTimeRange } = require('./toTimeRange')
const { getAllWeekdayHoursInISOOrder } = require('./getAllWeekdayHoursInISOOrder')

/**
 * Create a new service hours object. If no existing service hours object is
 * specified to copy from, a default service hours will be created.
 *
 * Ensures the following normalization copying:
 *  - weekday hours are sorted in ISO weekday ordering (Sunday first and Saturday last)
 *  - duplicate delivery days have time ranges merged
 *  - overlapping time ranges are merged
 *  - zero-duration time ranges are removed
 *
 * @param {Partial<ServiceHours>} [val] The service hours to copy
 * @return {ServiceHours}
 */
function create (val = null) {
  if (val && !isServiceHours(val)) {
    throw Std.err('TypeError', 'val must a service hours object')
  }

  const serviceHours = {}

  const defaultWeekdays = [
    { day: 0, ranges: [{ fromTime: '08:30', toTime: '18:30' }] }, // Sunday
    { day: 1, ranges: [{ fromTime: '08:30', toTime: '18:30' }] }, // Monday
    { day: 2, ranges: [{ fromTime: '08:30', toTime: '18:30' }] }, // Tuesday
    { day: 3, ranges: [{ fromTime: '08:30', toTime: '18:30' }] }, // Wednesday
    { day: 4, ranges: [{ fromTime: '08:30', toTime: '18:30' }] }, // Thursday
    { day: 5, ranges: [{ fromTime: '08:30', toTime: '18:30' }] }, // Friday
    { day: 6, ranges: [{ fromTime: '08:30', toTime: '18:30' }] } // Saturday
  ]

  if (val) {
    serviceHours.days = Std.clone(val.days || defaultWeekdays)
    serviceHours.calendarDays = Std.clone(val.calendarDays || [])
  } else {
    serviceHours.days = defaultWeekdays
    serviceHours.calendarDays = []
  }

  normalizeWeekdayHours(serviceHours)
  normalizeCalendarDayHours(serviceHours)

  return serviceHours
}

function normalizeWeekdayHours (serviceHours) {
  serviceHours.days = mergeDeliveryDays(serviceHours.days)
  serviceHours.days = getAllWeekdayHoursInISOOrder(serviceHours)
  serviceHours.days = serviceHours.days.map(mergeOverlappingTimeRangesInDay)
  serviceHours.days = serviceHours.days.map(removeZeroDurationTimeRangesInDay)
}

function normalizeCalendarDayHours (serviceHours) {
  serviceHours.calendarDays = mergeDeliveryDays(serviceHours.calendarDays)
  serviceHours.calendarDays = getAllCalendarDayHoursInChronologicalOrder(serviceHours)
  serviceHours.calendarDays = serviceHours.calendarDays.map(mergeOverlappingTimeRangesInDay)
  serviceHours.calendarDays = serviceHours.calendarDays.map(removeZeroDurationTimeRangesInDay)
}

/**
 * @param {DeliveryDay[]} days
 * @return {DeliveryDay[]} The new day objects
 */
function mergeDeliveryDays (days) {
  return [...days.reduce((map, day) => {
    if (map.has(day.day)) {
      const d = map.get(day.day)
      d.ranges = [...d.ranges, ...day.ranges]
      return map.set(day.day, d)
    }

    return map.set(day.day, day)
  }, new Map()).values()]
}

/**
 * @param {DeliveryDay} day
 * @return The new day object with the merged time ranges
 */
function mergeOverlappingTimeRangesInDay (day) {
  let ranges = day.ranges.map(toTimeRange)

  for (let r = 0; r < ranges.length; r += 1) {
    const range = ranges[r]
    const from = ranges.find((rng) => rng !== range && rng.fromTime >= range.fromTime && rng.toTime <= range.fromTime)
    const to = ranges.find((rng) => rng !== range && rng.fromTime <= range.toTime && rng.toTime >= range.fromTime)

    if (from) {
      from.toTime = range.toTime
      ranges.splice(r, 1)
      r -= 1
    } else if (to) {
      to.fromTime = range.fromTime
      ranges.splice(r, 1)
      r -= 1
    }
  }

  ranges = ranges.map((range) => ({
    fromTime: range.fromTime.toString(),
    toTime: range.toTime.toString()
  }))

  return { ...day, ranges }
}

/**
 * @param {DeliveryDay} day
 * @return The new day object with the removed time ranges
 */
function removeZeroDurationTimeRangesInDay (day) {
  const ranges = day.ranges.filter((r) => {
    return !Time.from(r.fromTime).value.equals(Time.from(r.toTime).value)
  })

  return { ...day, ranges }
}

/**
 * Given a service hours object, retrieve the calendary day hours and sorted chronologically.
 *
 * @return {calendarDeliveryDay[]}
 */
function getAllCalendarDayHoursInChronologicalOrder (serviceHours) {
  if (!isServiceHours(serviceHours)) {
    throw Std.err('TypeError')
  }

  // Ensure the days are always sorted in ISO order (Sun, M, Tu, W, Th, F, Sat)
  const days = [...serviceHours.calendarDays].sort((a, b) => a.day - b.day).map(Std.clone)

  // Also ensure that the ranges are sorted
  for (const day of days) {
    day.ranges = [...day.ranges].sort((a, b) => Time.from(a.fromTime).value - Time.from(b.fromTime).value)
  }

  return days
}

exports.create = create
