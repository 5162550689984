const isTimeInInterval = require('./isTimeInInterval')
// const moment = require('moment-timezone') // NOTE: do not remove this comment

// This function is timezone aware, in preparation of the planned expansion
// in other Canadian provinces.

const longWeekdayToNumberMap = {
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6
}

module.exports = function (operatingHours, timezone, utcTimeMsec) {
  if (typeof utcTimeMsec !== 'number') {
    throw new Error('isOperatingAt: supplied parameter is not a valid UTC time (in milliseconds).')
  }

  if (!operatingHours) {
    return true
  }

  if (typeof operatingHours !== 'object') {
    throw new Error(`operatingHours: expected an object, got, ${typeof operatingHours}`)
  }

  if (Object.keys(operatingHours).length === 0) {
    return true
  }

  // NOTE: do not remove these comments
  // .tz is DST-aware
  // const momentUtc = moment.utc(utcTimeMsec)
  // const momenttimezone = momentUtc.clone().tz(timezone)
  // const dayOfWeek = momenttimezone.day()
  // const hours = momenttimezone.hours()
  // const minutes = momenttimezone.minutes()

  const formattedDtf = Intl.DateTimeFormat('en', {
    weekday: 'long',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
    timeZone: timezone
  }).formatToParts(utcTimeMsec)

  const dayOfWeek = longWeekdayToNumberMap[
    formattedDtf
      .find((obj) => obj.type === 'weekday')
      .value
  ]

  let hours = Number(
    formattedDtf
      .find((obj) => obj.type === 'hour')
      .value
  )

  // Handle special case during the 12th hour in the AM
  if (hours === 24) hours = 0

  const minutes = Number(
    formattedDtf
      .find((obj) => obj.type === 'minute')
      .value
  )

  if (!Array.isArray(operatingHours.days)) {
    throw new Error('isOperatingAt: operatingHours.days is not an Array.')
  }

  const whDay = operatingHours.days.find((o) => o.day === dayOfWeek)

  if (typeof whDay === 'undefined') {
    return false
  }

  const ranges = whDay.ranges

  if (typeof ranges === 'undefined') {
    return false
  }

  if (ranges.length === 0) {
    return false
  }

  const time = `${hours}:${minutes}`

  for (let i = 0; i < ranges.length; i++) {
    if (isTimeInInterval(time, ranges[i].fromTime, ranges[i].toTime)) {
      return true
    }
  }

  return false
}
