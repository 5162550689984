// KEEP_DISCOUNTS will re-apply discounts if discounts already exist on the shipment
// (e.g. shipmentRate.discounts.length > 0), and it will not do anything if there are no discounts.
exports.KEEP_DISCOUNTS = 'keep'

// REAPPLY_DISCOUNTS will always remove and then apply discounts. Note that
// the number of discounts might change between the last time the shipment had a
// discount applied, and now.
exports.REAPPLY_DISCOUNTS = 'reapply'

// REMOVE_DISCOUNTS will remove and not apply discounts.
exports.REMOVE_DISCOUNTS = 'remove'
