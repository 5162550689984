import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip, Tag } from 'antd'

export default function TagWithTooltip ({ tipTitle, tagColor, tagStyle, children }) {
  return (
    <Tooltip title={tipTitle}>
      <Tag color={tagColor} style={tagStyle}>{children}</Tag>
    </Tooltip>
  )
}

TagWithTooltip.propTypes = {
  tipTitle: PropTypes.string,
  tagColor: PropTypes.string,
  tagStyle: PropTypes.object,
  children: PropTypes.node
}
