function decodeItineraryItemName (name) {
  const [
    typeLetter,
    shipmentId,
    deliveryIndexStr,
    orderUuidRaw,
    orderIdRaw
  ] = String(name || '').split('_', 5)

  // Backwards-compatibility for when orderId was at index 3
  const orderId = orderIdRaw === undefined ? orderUuidRaw : orderIdRaw
  const orderUuid = orderIdRaw === undefined ? null : orderUuidRaw

  const deliveryIndex = parseInt(deliveryIndexStr, 10)

  return {
    type: (
      typeLetter === 'P' ? 'pickup'
        : typeLetter === 'D' ? 'delivery'
          : 'unknown'
    ),
    shipmentId,
    deliveryIndex: deliveryIndex >= 0 ? deliveryIndex : null,
    orderId: orderId !== undefined ? orderId : null,
    orderUuid: orderUuid !== undefined ? orderUuid : null
  }
}

exports.decodeItineraryItemName = decodeItineraryItemName
