import { Modal, Radio, Divider, Checkbox } from 'antd'
import * as React from 'react'

const ModalInnerContent = ({
  onUserSelection,
  selectedText,
  queryText,
  titleText = 'Please select',
  checkboxes,
  setExtraData = () => {},
  selectedCount = 0,
  subtitleText = null,
  // String or React Element: The extra content to show before the action buttons
  extraContent = null
} = {}) => {
  const [userSelection, setUserSelection] = React.useState(
    selectedCount
      ? 'selected'
      : 'filter'
  )

  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px'
  }

  return (
    <React.Fragment>
      <h3 style={{ marginBottom: 15 }}>{titleText}</h3>
      {subtitleText ? (
        <p>{subtitleText}</p>
      ) : null}
      <Radio.Group
        onChange={(e) => {
          onUserSelection(e.target.value)
          setUserSelection(e.target.value)
        }}
        value={userSelection}
      >
        <Radio style={radioStyle} value={'selected'} disabled={!selectedCount}>
          {selectedText}{selectedCount ? ` (${selectedCount})` : ''}
        </Radio>
        <Radio style={radioStyle} value={'filter'}>
          {queryText}
        </Radio>
      </Radio.Group>
      {checkboxes ? (
        Object.keys(checkboxes).map((name) => {
          return (
            <React.Fragment key={name}>
              <Divider />
              <Checkbox
                onChange={(e) => {
                  setExtraData(name, Boolean(e.target.checked))
                }}
              >
                {checkboxes[name].label}
              </Checkbox>
            </React.Fragment>
          )
        })
      ) : null}
      {extraContent ? (<div style={{ marginTop: '20px' }}>{extraContent}</div>) : null}
    </React.Fragment>
  )
}

export default function ModalChooseSelectOrQuery ({
  selected = [],
  query = {},
  onChosenSelected = () => {

  },
  onChosenQuery = () => {

  },
  onCancel = () => {

  },
  titleText = 'Please select',
  selectedText = 'Routes selected',
  queryText = 'Routes matching current filter',
  checkboxes = null,
  subtitleText = null,
  // String or React Element: The extra content to show before the action buttons
  extraContent = null
} = {}) {
  let userSelection = selected.length ? 'selected' : 'filter'
  const extraData = {}

  Modal.confirm({
    okText: 'OK',
    cancelText: 'Cancel',
    onOk () {
      if (userSelection === 'selected') {
        onChosenSelected(extraData)
      } else {
        onChosenQuery(extraData)
      }
    },
    onCancel () {
      onCancel()
    },
    content: (
      <ModalInnerContent
        onUserSelection={(us) => {
          userSelection = us
        }}
        selectedText={selectedText}
        selectedCount={selected.length}
        queryText={queryText}
        checkboxes={checkboxes}
        setExtraData={(name, value) => {
          extraData[name] = value
        }}
        titleText={titleText}
        subtitleText={subtitleText}
        extraContent={extraContent}
      />
    )
  })
}
