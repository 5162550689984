import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import gsap from 'gsap'
import { COLORS } from '@trexity/common/color/constants'

export const InputErrorWrapper = ({
  active,
  animatingDelay = 0,
  animatingIn,
  animatingOut,
  disableAnimations,
  children,
  style
}) => {
  const wrapper = useRef()

  useEffect(() => {
    const tl = gsap.timeline()

    if (!disableAnimations) {
      if (animatingIn) {
        tl.to(wrapper.current, { y: 0, opacity: 1, delay: animatingDelay })
      } else if (animatingOut) {
        tl.to(wrapper.current, { y: 20, opacity: 0, delay: animatingDelay })
      }
    }
  }, [animatingIn, animatingOut, animatingDelay])

  useEffect(() => {
    if (active) {
      gsap.to(wrapper.current, { backgroundColor: COLORS.TREXITY_RED })
    } else {
      gsap.to(wrapper.current, { backgroundColor: 'transparent' })
    }
  }, [active])

  return (
    <div
      ref={wrapper}
      style={{
        backgroundColor: 'transparent',
        borderRadius: 10,
        transform: disableAnimations ? 'none' : 'translateY(20px)',
        opacity: disableAnimations ? 1 : 0,
        ...style
      }}
    >
      {children}
    </div>
  )
}

InputErrorWrapper.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  animatingIn: PropTypes.bool,
  animatingOut: PropTypes.bool,
  animatingDelay: PropTypes.number,
  disableAnimations: PropTypes.bool,
  style: PropTypes.object
}
