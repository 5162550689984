/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { IconMarker } from './Icon'
import { COLORS } from '@trexity/common/color/constants'
import PropTypes from 'prop-types'
import { useEffect, useRef } from 'react'
import { gsap } from 'gsap'

export const MarkerWithIcons = ({
  style = {},
  bg,
  border,
  label,
  order,
  textColor,
  id,
  labelClassName,
  className,
  onMarkerClick = () => { },
  borderRadius,
  medium,
  large,
  xlarge
}) => {
  const wrapper = useRef()
  const surrounder = useRef()

  useEffect(() => {
    gsap.to(wrapper.current, { opacity: 1 })
    gsap.to(surrounder.current, { backgroundColor: bg })
  }, [])
  const textCol = textColor === 'light' ? COLORS.WHITE : COLORS.DARK
  let fontSize = 14
  let size = 30
  let lineHeight = '21px'

  if (medium) {
    size = 32
  }

  if (large) {
    size = 35
    fontSize = 12
    lineHeight = '19px'
  }

  if (xlarge) {
    size = 45
    fontSize = 12
    lineHeight = '19px'
  }

  return (
    <div
      css={{ ...style, position: 'relative', opacity: 0, cursor: 'pointer' }}
      ref={wrapper}
      onClick={(e) => {
        e.preventDefault()
        gsap.to(surrounder.current, { opacity: 0.8, scale: 0.8, duration: 0.05 })
        onMarkerClick({ e, id })
      }
      }
    >
      <div
        ref={surrounder}
        data-id={id}
        css={{
          fontFamily: '"canada-type-gibson", sans-serif',
          width: size,
          height: size,
          fontSize: fontSize,
          lineHeight: lineHeight,
          color: textCol,
          backgroundColor: bg,
          border: `1px solid ${textCol}`,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius
        }}
        className={className}
      >
        <div className={labelClassName + 'label'} style={{ fontWeight: 700 }}>
          {label}
        </div>

      </div>
      <div css={{
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'center',
        alignItems: 'center',
        width: 40,
        position: 'absolute',
        transform: 'translate(-50%, -6px)',
        left: '50%'
      }}
      >
        {order && order.perishable ? <IconMarker size='small' icon='restaurant' bg={COLORS.TREXITY_BLUE} light /> : null}
        {order && order.legalAge ? <IconMarker size='small' icon='local_bar' bg={COLORS.TREXITY_DRIVER_NAVY} light /> : null}
        {order && order.handOff ? <IconMarker size='small' icon='person' bg={COLORS.TREXITY_YELLOW} /> : null}
        {order && order.oversized ? <IconMarker size='small' icon='local_shipping' bg={COLORS.TREXITY_GREENISH_TEAL} /> : null}
      </div>
    </div>
  )
}

MarkerWithIcons.propTypes = {
  Component: PropTypes.func,
  style: PropTypes.object,
  bg: PropTypes.string,
  border: PropTypes.string,
  label: PropTypes.any,
  order: PropTypes.object,
  textColor: PropTypes.string,
  id: PropTypes.string,
  labelClassName: PropTypes.string,
  className: PropTypes.string,
  onMarkerClick: PropTypes.func,
  borderRadius: PropTypes.string,
  medium: PropTypes.bool,
  large: PropTypes.bool,
  xlarge: PropTypes.bool
}
