/* eslint-disable no-alert */
import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Button } from 'antd'

export function ForceAcceptButton ({
  shipment,
  updateDriverPicker,
  driverPicker,
  driverId = null,
  isLoading = false,
  style = {},
  deps
}) {
  const {
    send,
    current
  } = deps

  const currentStatus = shipment.currentStatus

  const isForceAccepting = current.within('Force Accepting Shipment')
  const isPostAccepting = current.within('Post Accepting Shipment')

  const canForceAccept = (
    !isForceAccepting &&
    (
      currentStatus === 'WAITING_FOR_ACCEPTANCE'
    )
  )

  const canPostAccept = (
    !isPostAccepting &&
    (
      !currentStatus ||
      currentStatus === 'ON_HOLD'
    )
  )

  const isDisabled = (
    isLoading ||
    Boolean(driverId) ||
    !(
      canForceAccept ||
      canPostAccept
    )
  )

  return (
    <Button
      type='primary'
      loading={isForceAccepting || isPostAccepting}
      disabled={isDisabled}
      style={style}
      onClick={() => {
        updateDriverPicker({
          ...driverPicker,
          visible: true,
          match: (driver) => {
            if (!driver.approved) {
              return {
                match: false,
                reason: 'unapproved'
              }
            }

            // NOTE (dschnare): Temporarily allow force accept on a driver
            // that is not within the service city of the delivery.
            // This is so that we can force accept drivers on deliveries
            // for markets like Toronto/Mississauga and Ottawa/Gatinuea.
            // if (driver.mailingAddressServiceCity !== shipment.pickupAddressServiceCity) {
            //   return {
            //     display: false,
            //     match: false,
            //     reason: 'city mismatch'
            //   }
            // }

            if (shipment.requirements && shipment.requirements.legalAgeAlcohol) {
              if (!driver.capabilities.legalAgeAlcohol) {
                return {
                  match: false,
                  reason: 'no alcohol'
                }
              }
            }

            if (!driver.active) {
              return {
                match: true,
                reason: 'inactive'
              }
            }

            return {
              match: true
            }
          },
          callback: (selectedDriverId, driverName, driverFullRecord) => {
            if (selectedDriverId) {
              if (canForceAccept) {
                send({ type: 'force accept shipment', shipmentId: shipment.id, driverId: selectedDriverId })
              } else if (canPostAccept) {
                send({ type: 'post accept shipment', shipmentId: shipment.id, driverId: selectedDriverId })
              }
            } else {
              Modal.error({
                title: 'Force Accept Route',
                content: 'You must select a driver to use this feature.'
              })
            }
          }
        })
      }}
    >Force Accept Route</Button>
  )
}

ForceAcceptButton.propTypes = {
  shipment: PropTypes.object.isRequired,
  updateDriverPicker: PropTypes.func.isRequired,
  driverPicker: PropTypes.object.isRequired,
  driverId: PropTypes.string,
  isLoading: PropTypes.bool,
  style: PropTypes.object,
  deps: PropTypes.exact({
    send: PropTypes.func,
    current: PropTypes.object
  }).isRequired
}
