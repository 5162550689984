import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { isLocked } from '@trexity/common/util'
import { getFormattedDateAndTimeFromDate, getFormattedDurationString, getFormattedTimeFromDate } from '@trexity/common/temporal'
import { getFormattedDistanceString } from '@trexity/common/geo'
import { getFormattedMonetaryValueString } from '@trexity/common/pricing'
import { convertOrdersToArray } from '@trexity/common/models/ShipmentOrder'
import { sorter } from '@trexity/common/sort'
import { ModalConfirmWord } from '@trexity/common-client/lib/components'
import { buildCompoundSearchTermsQuery } from '@trexity/common/shipments/searchTerms'
import { sortOrdersByDirectionsResult } from '@trexity/common/shipments'
import { Std } from '@trexity/common/core'
import ShipmentStatus from './ShipmentStatus'
import Map from './Map'
import { SetScheduleDialog } from './SetScheduleDialog'
import OrderIdList from './OrderIdList'
import { InputSearchWithFilter } from '../../lib/components'
import { ShipmentRateBreakdownFromShipment } from './ShipmentRateBreakdown'

import {
  Button,
  Tabs,
  Row,
  Col,
  Table,
  Skeleton,
  Dropdown,
  Menu,
  Icon,
  Calendar,
  Popover,
  Pagination,
  Modal,
  Tag,
  Tooltip
} from 'antd'

const noActionAvailable = (currentStatus) => (
  currentStatus === 'DELIVERED' ||
  currentStatus === 'CANCELLED'
)

const pageSizes = [15, 50, 100, 500, 1000]

const defaultSearchFilters = {
  keywords: '',
  pageNum: 1,
  pageSize: pageSizes[0],
  activeTabKey: 'all',
  section: null,
  sortField: 'createdAt',
  direction: 'desc',
  citiesFilter: [],
  currentMerchantId: '',
  currentMerchantName: '',
  currentDriverId: '',
  currentDriverName: '',
  startDate: new Date(Date.now() - 2 * 7 * 24 * 60 * 60 * 1000),
  endDate: new Date(Date.now() + 2 * 30 * 24 * 60 * 60 * 1000)
}

export default function Shipments ({
  isAdmin,
  setIsLoading = () => {},
  deps
}) {
  const {
    current,
    send,
    data,
    MerchantSearch,
    DriverSearch,
    RouteLink
  } = deps

  const {
    appSettings,

    shipments = [],
    shipmentsActiveTabKey,
    shipmentsSection,
    shipmentsChangeCount: changeCount,
    shipmentsSearchTerms,
    shipmentsStartDate,
    shipmentsEndDate,
    shipmentsPageNum,
    shipmentsPageSize,
    shipmentsTotal,
    shipmentsSortField,
    shipmentsDirection,

    // Only applicable to admins (isAdmin: true)
    shipmentsCurrentMerchantId,
    shipmentsCurrentMerchantName,
    shipmentsCurrentDriverId,
    shipmentsCurrentDriverName,
    shipmentsCitiesFilter = [],
    shipmentsServiceCities = []
  } = data

  const [searchFilters, setSearchFilters] = React.useState({
    keywords: shipmentsSearchTerms || defaultSearchFilters.keywords,
    pageNum: shipmentsPageNum || defaultSearchFilters.pageNum,
    pageSize: shipmentsPageSize || defaultSearchFilters.pageSize,
    activeTabKey: shipmentsActiveTabKey || defaultSearchFilters.activeTabKey,
    section: shipmentsSection || defaultSearchFilters.section,
    sortField: shipmentsSortField || defaultSearchFilters.sortField,
    direction: shipmentsDirection || defaultSearchFilters.direction,
    citiesFilter: shipmentsCitiesFilter || defaultSearchFilters.citiesFilter,
    currentMerchantId: shipmentsCurrentMerchantId || defaultSearchFilters.currentMerchantId,
    currentMerchantName: shipmentsCurrentMerchantName || defaultSearchFilters.currentMerchantName,
    currentDriverId: shipmentsCurrentDriverId || defaultSearchFilters.currentDriverId,
    currentDriverName: shipmentsCurrentDriverName || defaultSearchFilters.currentDriverName,
    startDate: shipmentsStartDate || defaultSearchFilters.startDate,
    endDate: shipmentsEndDate || defaultSearchFilters.endDate
  })

  const precomputedSearchTerms = buildCompoundSearchTermsQuery(
    searchFilters.keywords,
    searchFilters.citiesFilter,
    searchFilters.section
  )

  const shipmentQuery = isAdmin ? {
    merchantId: searchFilters.currentMerchantId,
    driverId: searchFilters.currentDriverId,
    searchTerms: precomputedSearchTerms,
    timestampField: 'createdAt',
    startTimestamp: searchFilters.startDate && searchFilters.startDate.toISOString(),
    endTimestamp: searchFilters.endDate && searchFilters.endDate.toISOString()
  } : {
    searchTerms: precomputedSearchTerms,
    timestampField: 'createdAt',
    startTimestamp: searchFilters.startDate && searchFilters.startDate.toISOString(),
    endTimestamp: searchFilters.endDate && searchFilters.endDate.toISOString()
  }

  const cities = shipmentsServiceCities.map(({ name }) => name)

  const [calendarVisible, setCalendarVisible] = React.useState(false)
  const [selectedShipmentIds, setSelectedShipmentIds] = React.useState([])

  const isReady = current.within('Showing Shipments')
  const isLoading = current.within('Loading Shipments')
  const isBulkDeleting = current.within('Bulk Deleting Shipments')
  const isBulkCancelling = current.within('Bulk Cancelling Shipments')
  const isBulkPuttingOnHold = current.within('Bulk Putting On Hold Shipments')
  const isBulkPosting = current.within('Bulk Posting Shipments')
  const isBulkLabelInProgres = current.within('Bulk Label')

  const isBulkActionInProgress = isBulkDeleting || isBulkCancelling || isBulkPuttingOnHold || isBulkPosting || isBulkLabelInProgres

  React.useEffect(() => setIsLoading(isLoading), [isLoading])

  React.useEffect(() => {
    send({ type: 'change shipment query', ...searchFilters })
  }, [searchFilters])

  // Since the setSelectedShipmentIds information is temporary (UI-only) and the subscription callbacks know
  // which of the shipments no longer exists (if any), we must do something like this:
  // (this also takes care of moving between pages/pagination)
  React.useEffect(() => {
    const allShipmentIds = (shipments || []).map((s) => s.id)
    const selectedShipmentIdsAfterRerender = [...selectedShipmentIds].filter((id) => allShipmentIds.includes(id))
    setSelectedShipmentIds(selectedShipmentIdsAfterRerender)
  }, [shipments])

  React.useEffect(() => {
    const listener = (event) => {
      window.shiftKey = event.shiftKey
    }

    document.addEventListener('keydown', listener)
    document.addEventListener('keyup', listener)

    return () => {
      document.removeEventListener('keydown', listener)
      document.removeEventListener('keyup', listener)
    }
  }, [])

  const getWidth = () => (
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth
  )

  const [width, setWidth] = React.useState(getWidth())
  const isDesktop = (width > 667)

  React.useEffect(() => {
    const onResize = () => setWidth(getWidth())
    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [])

  const dataSource = shipments.map((shipment) => {
    const canPutOnHold = (
      shipment.currentStatus === 'WAITING_FOR_ACCEPTANCE' ||
      shipment.currentStatus === 'RENOUNCED_BY_DRIVER'
    )

    const linkedParentType = (shipment.linkedParentShipment && shipment.linkedParentShipment.type) || null

    const cancellable = shipment.currentStatus && !noActionAvailable(shipment.currentStatus)
    const createdAtFormatted = shipment.createdAt && getFormattedDateAndTimeFromDate(shipment.createdAt.toDate())
    const scheduledPostAtFormatted = shipment.scheduledPostAt && getFormattedDateAndTimeFromDate(shipment.scheduledPostAt.toDate())
    const postedAtFormatted = shipment.postedAt && getFormattedDateAndTimeFromDate(shipment.postedAt.toDate())
    const completedAtFormatted = shipment.completedAt && getFormattedDateAndTimeFromDate(shipment.completedAt.toDate())
    const driverAcceptedAtFormatted = shipment.driverAcceptedAt && getFormattedDateAndTimeFromDate(shipment.driverAcceptedAt.toDate())
    const driverPickedUpAtFormatted = shipment.driverPickedUpAt && getFormattedDateAndTimeFromDate(shipment.driverPickedUpAt.toDate())
    const cachedTotalRouteDistanceFormatted = shipment.cachedTotalRouteDistance ? getFormattedDistanceString(shipment.cachedTotalRouteDistance) : 'Unknown'
    const cachedTotalRouteDurationFormatted = shipment.cachedTotalRouteDuration ? getFormattedDurationString(shipment.cachedTotalRouteDuration) : 'Unknown'
    const isShipmentLocked = isLocked(shipment.lockedUntil)
    const isUndismissingDriver = current.within('Undismissing Shipment For Driver')
    const isRenouncingDriver = current.within('Renouncing Shipment For Driver')
    const isAcceptingDriver = current.within('Accepting Shipment For Driver')
    const isCreatingTrackingUrl = current.within('Creating Tracking URL')
    const showTrackingUrl = current.within('Showing Tracking URL')

    return {
      key: shipment.id,
      ...shipment,
      canPutOnHold,
      linkedParentType,
      cancellable,
      createdAtFormatted,
      scheduledPostAtFormatted,
      postedAtFormatted,
      completedAtFormatted,
      driverAcceptedAtFormatted,
      driverPickedUpAtFormatted,
      cachedTotalRouteDistanceFormatted,
      cachedTotalRouteDurationFormatted,
      shipment,
      isShipmentLocked,
      isUndismissingDriver,
      isRenouncingDriver,
      isAcceptingDriver,
      isCreatingTrackingUrl,
      showTrackingUrl
    }
  })

  const totalRecords = shipmentsTotal

  const preferredDateField = (
    searchFilters.activeTabKey === 'upcoming' ? 'scheduledPostAt'
      : searchFilters.activeTabKey === 'waiting' ? 'postedAt'
        : searchFilters.activeTabKey === 'to_pickup' ? 'driverAcceptedAt'
          : searchFilters.activeTabKey === 'to_delivery' ? 'driverPickedUpAt'
            : searchFilters.activeTabKey === 'delivered' ? 'completedAt'
              : searchFilters.activeTabKey === 'cancelled' ? 'completedAt'
                : 'createdAt'
  )

  const showDriverColumn = (
    searchFilters.activeTabKey === 'upcoming' ? false
      : searchFilters.activeTabKey === 'waiting' ? false
        : searchFilters.activeTabKey === 'to_pickup' ? true
          : searchFilters.activeTabKey === 'to_delivery' ? true
            : searchFilters.activeTabKey === 'delivered' ? true
              : searchFilters.activeTabKey === 'cancelled' ? true
                : searchFilters.activeTabKey === 'all'
  )

  const shipmentsList = (
    <ShipmentList
      isAdmin={isAdmin}
      isReady={isReady}
      dataSource={dataSource}
      isLoading={isLoading || isBulkActionInProgress}
      preferredDateField={preferredDateField}
      showDriverColumn={showDriverColumn}
      onSelectionChange={(newlySelectedShipmentIds) => setSelectedShipmentIds(newlySelectedShipmentIds)}
      searchFilters={searchFilters}
      setSearchFilters={setSearchFilters}
      selectedRowKeys={selectedShipmentIds}
      deps={{ send, RouteLink }}
    />
  )

  const calendarView = calendarVisible ? (
    <DeliveryCalendar
      isAdmin={isAdmin}
      shipments={shipments}
      startDate={searchFilters.startDate}
      endDate={searchFilters.endDate}
    />
  ) : null

  const pagination = (
    <Pagination
      size='small'
      pageSize={searchFilters.pageSize}
      showSizeChanger
      onShowSizeChange={(current, pageSize) => setSearchFilters({ ...searchFilters, pageSize })}
      pageSizeOptions={pageSizes.map(String)} // map to String to satisfy buggy proptypes
      total={totalRecords}
      current={searchFilters.pageNum}
      onChange={(pageNum) => setSearchFilters({ ...searchFilters, pageNum })}
    />
  )

  // const headerbar = {
  //   'merchant-search': {
  //     enabled: isAdmin,
  //     sm: isAdmin ? 4 : 0
  //   },
  //   'driver-search': {
  //     enabled: isAdmin,
  //     sm: isAdmin ? 4 : 0
  //   },
  //   'shipment-search': {
  //     enabled: true,
  //     sm: isAdmin ? 4 : 10
  //   },
  //   'cities-filter': {
  //     enabled: isAdmin,
  //     sm: isAdmin ? 3 : 0
  //   },
  //   'date-range': {
  //     enabled: true,
  //     sm: isAdmin ? 4 : 8
  //   },
  //   'controls': {
  //     enabled: true,
  //     sm: isAdmin ? 5 : 6
  //   }
  // }

  // const sm = (key) => headerbar[key].sm
  // const lg = (key) => headerbar[key].sm

  // TODO: Ask the server for this information, whenever we need it.
  // const deliveriesSelectedCount = shipmentsSelected.reduce((memo, sh) => memo + Object.keys(sh.orders || {}).length, 0)

  // const shipmentsWithSingleOrders = shipments.filter((sh) => Object.keys(sh.orders).length === 1)

  // const shouldSuggestBundling = (
  //   !isAdmin &&
  //   shipmentsWithSingleOrders.length >= 5
  // )

  return (
    isReady ? (
      <React.Fragment>
        <div style={{ marginBottom: 12 }}>
          <Row type='flex' justify='space-between' gutter={[{ xs: 8, sm: 8 }, { xs: 8, sm: 8 }]}>
            <Col xs={24} lg={10}>
              <InputSearchWithFilter
                disabled={isLoading}
                placeholder='Search (e.g. "8RT" or Order ID)'
                filters={searchFilters}
                onReset={() => setSearchFilters({ ...defaultSearchFilters })}
                onFilter={(filters) => setSearchFilters({ ...filters, pageNum: 1 })}
              >
                {isAdmin ? (
                  <React.Fragment>
                    <InputSearchWithFilter.MerchantSearch
                      $label='Merchant'
                      merchantIdKey='currentMerchantId'
                      merchantNameKey='currentMerchantName'
                      MerchantSearch={MerchantSearch}
                      $tag={({ currentMerchantId, currentMerchantName }) => ({
                        visible: Boolean(currentMerchantId),
                        label: <React.Fragment><strong>Merchant:</strong> {currentMerchantName}</React.Fragment>,
                        reset: currentMerchantId !== defaultSearchFilters.currentMerchantId && { currentMerchantId: defaultSearchFilters.currentMerchantId, currentMerchantName: defaultSearchFilters.currentMerchantName }
                      })}
                    />
                    <InputSearchWithFilter.DriverSearch
                      $label='Driver'
                      driverIdKey='currentDriverId'
                      driverNameKey='currentDriverName'
                      DriverSearch={DriverSearch}
                      $tag={({ currentDriverId, currentDriverName }) => ({
                        visible: Boolean(currentDriverId),
                        label: <React.Fragment><strong>Driver:</strong> {currentDriverName}</React.Fragment>,
                        reset: currentDriverId !== defaultSearchFilters.currentDriverId && { currentDriverId: defaultSearchFilters.currentDriverId, currentDriverName: defaultSearchFilters.currentDriverName }
                      })}
                    />
                    <InputSearchWithFilter.Select
                      $label='Cities'
                      filterKey='citiesFilter'
                      placeholder='Filter city'
                      multiple
                      options={cities.map((city) => ({
                        value: city,
                        label: city
                      }))}
                      $tag={({ citiesFilter }) => ({
                        visible: Boolean(citiesFilter.length),
                        label: <React.Fragment><strong>Cit{citiesFilter.length === 1 ? 'y' : 'ies'}:</strong> {citiesFilter.map((city) => `[${city}]`).join(', ')}</React.Fragment>,
                        reset: !Std.eq(citiesFilter, defaultSearchFilters.citiesFilter) && { citiesFilter: defaultSearchFilters.citiesFilter }
                      })}
                    />
                  </React.Fragment>
                ) : null}
                <InputSearchWithFilter.DateRange
                  $label='Filter by date'
                  startDateKey='startDate'
                  endDateKey='endDate'
                  disableClear
                  showTime
                  $tag={({ startDate, endDate }) => ({
                    visible: Boolean(startDate && endDate),
                    label: startDate && endDate && <React.Fragment><strong>Date:</strong> {getFormattedDateAndTimeFromDate(startDate)} – {getFormattedDateAndTimeFromDate(endDate)}</React.Fragment>,
                    reset: (
                      !Std.eq(startDate, defaultSearchFilters.startDate) ||
                      !Std.eq(endDate, defaultSearchFilters.endDate)
                    ) && { startDate: defaultSearchFilters.startDate, endDate: defaultSearchFilters.endDate }
                  })}
                />
              </InputSearchWithFilter>
            </Col>
            <Col xs={24} lg={14} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
              <Row type='flex' justify='end' gutter={[8, 8]}>
                <Col style={{ textAlign: 'right' }}>
                  {!isAdmin ? (
                    <Button
                      size={isDesktop ? 'large' : 'small'}
                      type='primary'
                      icon='printer'
                      disabled={!shipmentsTotal}
                      style={{ marginBottom: 10 }}
                      className='routes-print-labels'
                      onClick={() => {
                        send({
                          type: 'bulk labels select',
                          previewOnly: false,
                          shipmentIds: selectedShipmentIds,
                          shipmentQuery
                        })
                      }}
                    >
                      Print Labels
                    </Button>
                  ) : null}

                  <Button
                    size={isDesktop ? 'large' : 'small'}
                    type='primary'
                    icon='pull-request'
                    disabled={!shipmentsTotal}
                    style={{ marginLeft: 10, marginBottom: 10 }}
                    onClick={() => {
                      send({
                        type: 'verify shipment ids for bundling',
                        shipmentIds: selectedShipmentIds,
                        shipmentQuery
                      })
                    }}
                  >
                    Bundle Deliveries
                  </Button>
                  <Dropdown
                    trigger={['click']}
                    overlay={(
                      <Menu>
                        <Menu.Item
                          key='0'
                          disabled={!shipmentsTotal}
                          onClick={() => {
                            send({
                              type: 'export orders csv',
                              shipmentIds: selectedShipmentIds,
                              shipmentQuery
                            })
                          }}
                        >
                          Export Deliveries CSV
                        </Menu.Item>
                        {isAdmin ? (
                          <Menu.Item
                            key='1'
                            disabled={!shipmentsTotal}
                            onClick={() => {
                              send({
                                type: 'export distribution csv',
                                shipmentIds: selectedShipmentIds,
                                shipmentQuery
                              })
                            }}
                          >
                            Export Deliveries CSV
                            <small style={{ display: 'block' }}>(Import Format)</small>
                          </Menu.Item>
                        ) : null}
                        {isAdmin ? (
                          <Menu.Divider />
                        ) : null}
                        {isAdmin ? (
                          <Menu.Item
                            key='3'
                            disabled={!shipmentsTotal}
                            onClick={() => {
                              send({
                                type: 'bulk labels select',
                                shipmentIds: selectedShipmentIds,
                                shipmentQuery
                              })
                            }}
                          >
                            Preview Labels
                          </Menu.Item>
                        ) : null}
                        <Menu.Item
                          key='4'
                          disabled={!selectedShipmentIds.length}
                          onClick={() => {
                            send({
                              type: 'schedule selected',
                              shipmentIdRouteIdentifierPairs: shipments.filter((s) => selectedShipmentIds.includes(s.id)).map((s) => ({
                                shipmentId: s.id,
                                routeIdentifier: s.routeIdentifier || null
                              }))
                            })
                          }}
                        >
                          Set Schedule
                        </Menu.Item>
                        {isAdmin ? (
                          <Menu.Item
                            key='5'
                            disabled={!selectedShipmentIds.length}
                            onClick={() => {
                              Modal.confirm({
                                title: 'Confirmation',
                                content: 'Are you sure you want to put these route(s) on hold?',
                                onOk () {
                                  send({
                                    type: 'bulk put on hold shipments',
                                    shipments: shipments.filter((s) => selectedShipmentIds.includes(s.id))
                                  })
                                }
                              })
                            }}
                          >
                            Put Route{selectedShipmentIds.length > 1 ? 's' : ''} on Hold
                          </Menu.Item>
                        ) : null}
                        {isAdmin ? (
                          <Menu.Item
                            key='6'
                            disabled={!selectedShipmentIds.length}
                            onClick={() => {
                              Modal.confirm({
                                title: 'Dangerous action',
                                content: 'This step is irrevocable. Are you sure?',
                                onOk () {
                                  send({
                                    type: 'bulk cancel shipments',
                                    shipments: shipments.filter((s) => selectedShipmentIds.includes(s.id))
                                  })
                                }
                              })
                            }}
                          >
                            Cancel Route{selectedShipmentIds.length > 1 ? 's' : ''}
                          </Menu.Item>
                        ) : null}
                        {isAdmin ? (
                          <Menu.Item
                            key='7'
                            disabled={!selectedShipmentIds.length}
                            onClick={() => {
                              Modal.confirm({
                                title: 'Confirmation',
                                content: 'Are you sure you want to post these route(s)?',
                                onOk () {
                                  send({
                                    type: 'bulk post shipments',
                                    shipments: shipments.filter((s) => selectedShipmentIds.includes(s.id))
                                  })
                                }
                              })
                            }}
                          >
                            Post Route{selectedShipmentIds.length > 1 ? 's' : ''}
                          </Menu.Item>
                        ) : null}
                        <Menu.Divider />
                        <Menu.Item
                          key='8'
                          disabled={!selectedShipmentIds.length}
                          onClick={() => {
                            const selectedShipments = shipments.filter((s) => selectedShipmentIds.includes(s.id))
                            const routeCount = selectedShipments.length

                            const deliveryCount = selectedShipments
                              .reduce((memo, sh) => memo + Object.keys(sh.orders).length, 0)

                            ModalConfirmWord({
                              confirmText: (word) => (<p>Confirm that you would like to delete {deliveryCount} deliver{deliveryCount === 1 ? 'y' : 'ies'} across {routeCount} route{routeCount === 1 ? '' : 's'} by entering the number of deliveries (<strong>{word}</strong>) that will be deleted:</p>),
                              confirmWord: String(deliveryCount)
                            }, {
                              title: 'Dangerous action',
                              okText: `Delete ${deliveryCount} deliver${deliveryCount === 1 ? 'y' : 'ies'}`,
                              okType: 'danger',
                              cancelText: 'Cancel',
                              onOk () {
                                send({
                                  type: 'bulk delete shipments',
                                  shipments: selectedShipments
                                })
                              }
                            })
                          }}
                        >
                          <Button
                            type="danger"
                            icon="delete"
                            disabled={!selectedShipmentIds.length}
                          >
                            Delete Route{selectedShipmentIds.length > 1 ? 's' : ''}
                          </Button>
                        </Menu.Item>
                      </Menu>
                    )}
                  >
                    <Button
                      size={isDesktop ? 'large' : 'small'}
                      loading={isLoading}
                      type='primary'
                      style={{ marginLeft: 10, marginBottom: 10 }}
                      disabled={!selectedShipmentIds.length}
                    >
                      Actions <Icon type='down' />
                    </Button>
                  </Dropdown>

                  <Button
                    size={isDesktop ? 'large' : 'small'}
                    loading={isLoading}
                    type={changeCount > 0 ? 'primary' : 'default'}
                    icon='reload'
                    onClick={() => send('reload shipments')}
                    style={{ marginLeft: 10 }}
                  >
                    Reload{changeCount > 0 ? ` (${changeCount})` : ''}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>

        <div>
          <Tabs
            activeKey={searchFilters.activeTabKey}
            animated={false}
            tabBarGutter={15}
            onChange={(activeTabKey) => {
              switch (activeTabKey) {
                case 'not_yet_submitted':
                  setSearchFilters({ ...searchFilters, section: 'DRAFTS', sortField: 'createdAt', direction: 'desc', activeTabKey, unacknowledgedErrorsOnly: false, pageNum: 1 })
                  break
                case 'waiting':
                  setSearchFilters({ ...searchFilters, section: 'WAITING', sortField: 'postedAt', direction: 'desc', activeTabKey, unacknowledgedErrorsOnly: false, pageNum: 1 })
                  break
                case 'to_pickup':
                  setSearchFilters({ ...searchFilters, section: 'TO_PICKUP', sortField: 'driverAcceptedAt', direction: 'desc', activeTabKey, unacknowledgedErrorsOnly: false, pageNum: 1 })
                  break
                case 'to_delivery':
                  setSearchFilters({ ...searchFilters, section: 'TO_DELIVERY', sortField: 'driverPickedUpAt', direction: 'desc', activeTabKey, unacknowledgedErrorsOnly: false, pageNum: 1 })
                  break
                case 'delivered':
                  setSearchFilters({ ...searchFilters, section: 'DELIVERED', sortField: 'completedAt', direction: 'desc', activeTabKey, unacknowledgedErrorsOnly: false, pageNum: 1 })
                  break
                case 'cancelled':
                  setSearchFilters({ ...searchFilters, section: 'CANCELLED', sortField: 'completedAt', direction: 'desc', activeTabKey, unacknowledgedErrorsOnly: false, pageNum: 1 })
                  break
                case 'other':
                  setSearchFilters({ ...searchFilters, section: 'OTHER', sortField: 'createdAt', direction: 'desc', activeTabKey, unacknowledgedErrorsOnly: false, pageNum: 1 })
                  break
                case 'upcoming':
                  setSearchFilters({ ...searchFilters, section: 'UPCOMING', sortField: 'scheduledPostAt', direction: 'asc', activeTabKey, unacknowledgedErrorsOnly: false, pageNum: 1 })
                  break
                case 'post_error':
                  setSearchFilters({ ...searchFilters, section: null, sortField: 'createdAt', direction: 'desc', activeTabKey, unacknowledgedErrorsOnly: true, pageNum: 1 })
                  break
                case 'selected':
                  setSearchFilters({ ...searchFilters, section: null, activeTabKey, unacknowledgedErrorsOnly: false, pageNum: 1 })
                  break
                default:
                  setSearchFilters({ ...searchFilters, section: null, sortField: 'createdAt', direction: 'desc', activeTabKey, unacknowledgedErrorsOnly: false, pageNum: 1 })
              }
            }}
          >
            <Tabs.TabPane tab={<span>All</span>} key='all'>
              {searchFilters.activeTabKey === 'all' ? shipmentsList : null}
            </Tabs.TabPane>
            <Tabs.TabPane tab={<span>Drafts</span>} key='not_yet_submitted'>
              {searchFilters.activeTabKey === 'not_yet_submitted' ? shipmentsList : null}
            </Tabs.TabPane>
            <Tabs.TabPane tab={<span>Waiting</span>} key='waiting'>
              {searchFilters.activeTabKey === 'waiting' ? shipmentsList : null}
            </Tabs.TabPane>
            <Tabs.TabPane tab={<span>To Pickup</span>} key='to_pickup'>
              {searchFilters.activeTabKey === 'to_pickup' ? shipmentsList : null}
            </Tabs.TabPane>
            <Tabs.TabPane tab={<span>To Delivery</span>} key='to_delivery'>
              {searchFilters.activeTabKey === 'to_delivery' ? shipmentsList : null}
            </Tabs.TabPane>
            <Tabs.TabPane tab={<span>Delivered</span>} key='delivered'>
              {searchFilters.activeTabKey === 'delivered' ? shipmentsList : null}
            </Tabs.TabPane>
            <Tabs.TabPane tab={<span>Cancelled</span>} key='cancelled'>
              {searchFilters.activeTabKey === 'cancelled' ? shipmentsList : null}
            </Tabs.TabPane>
            <Tabs.TabPane tab={<span>Other</span>} key='other'>
              {searchFilters.activeTabKey === 'other' ? shipmentsList : null}
            </Tabs.TabPane>
            <Tabs.TabPane tab={<span>Upcoming</span>} key='upcoming'>
              {searchFilters.activeTabKey === 'upcoming' ? (calendarVisible ? calendarView : shipmentsList) : null}
            </Tabs.TabPane>
            <Tabs.TabPane tab={<span>Problematic</span>} key='post_error'>
              {searchFilters.activeTabKey === 'post_error' ? shipmentsList : null}
            </Tabs.TabPane>
          </Tabs>
        </div>

        <div style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
          {searchFilters.activeTabKey === 'upcoming' ? (
            <Button
              type='ghost'
              size='small'
              onClick={() => setCalendarVisible(!calendarVisible)}
            >Toggle Calendar</Button>
          ) : <div />}

          {searchFilters.activeTabKey ? pagination : null}
        </div>

        <SetScheduleDialog
          scheduleInterval={appSettings.SCHEDULE_INTERVAL}
          deps={{ send, current, data }}
        />
      </React.Fragment>
    ) : (
      <Skeleton active />
    )
  )
}

Shipments.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  setIsLoading: PropTypes.func,
  deps: PropTypes.exact({
    current: PropTypes.object,
    send: PropTypes.func,
    data: PropTypes.object,
    MerchantSearch: PropTypes.func,
    DriverSearch: PropTypes.func,
    RouteLink: PropTypes.func,
    Config: PropTypes.object
  }).isRequired
}

function ShipmentList ({
  isAdmin = false,
  isReady = false,
  isLoading = false,
  dataSource = [],
  preferredDateField = '',
  showDriverColumn = false,
  onSelectionChange = null,
  selectedRowKeys = [],
  style = {},
  searchFilters,
  setSearchFilters,
  deps
}) {
  const {
    send,
    RouteLink
  } = deps

  return (
    <Table
      style={{
        backgroundColor: '#ffffff',
        marginBottom: 15,
        ...style
      }}
      size='small'
      scroll={{ x: true }}
      bordered={false}
      dataSource={dataSource}
      loading={isLoading}
      pagination={false}
      rowKey={(record) => record.id}
      rowSelection={
        {
          selectedRowKeys,
          onChange: (c) => {
            onSelectionChange && onSelectionChange(c)
          }
        }
      }
      className={[
        'routes-list-table',
        isReady && 'routes-list-table-ready'
      ].filter(Boolean).join(' ')}
      rowClassName={({ __deleted }) => __deleted ? 'deleted' : ''}
    >
      <Table.Column
        title='Route'
        dataIndex='id'
        key='id'
        className='route-id-column'
        render={(_, { id, routeIdentifier, currentStatus, scheduledPostEnabled, __deleted }) => {
          const routeIdCaption = (
            <code className='nowrap'>
              {id.substr(-3)}{routeIdentifier ? ` ${routeIdentifier}` : ''}
            </code>
          )

          const status = (
            !currentStatus && scheduledPostEnabled ? 'SCHEDULED'
              : !currentStatus ? 'DRAFT'
                : currentStatus
          )

          return (
            <div className={`route-currentStatus-${status}`} data-routeid={id}>
              {__deleted ? (
                routeIdCaption
              ) : (
                <RouteLink
                  routeKey='Shipment Dashboard'
                  params={{ id }}
                >{routeIdCaption}</RouteLink>
              )}
            </div>
          )
        }}
      />
      <Table.Column
        title='Deliveries'
        dataIndex='orders'
        key='orderIds'
        render={(_, shipment) => {
          const tags = []

          if (shipment.requirements && shipment.requirements.legalAgeAlcohol) {
            tags.push({ icon: 'local_bar', tooltip: 'Alcohol' })
          }

          if (shipment.requirements && shipment.requirements.perishable) {
            tags.push({ icon: 'icecream', tooltip: 'Perishable' })
          }

          if (shipment.requirements && shipment.requirements.returnReusables) {
            tags.push({ icon: 'assignment_return', tooltip: 'Return Reusables' })
          }

          // Note that this does not take into account the actual route plan
          // version of the directionsResult that the driver is following, which
          // may have a different order. This is a best-effort ordering based on
          // the original directionsResult created during pricing.
          const sortedOrders = sortOrdersByDirectionsResult({
            orders: shipment.orders,
            directionsResult: shipment.directionsResult
          })

          return (
            <div style={{ maxWidth: 600, display: 'flex', flexWrap: 'wrap' }}>
              <OrderIdList
                shipmentId={shipment.id}
                orderIds={(
                  convertOrdersToArray(sortedOrders)
                    .filter(({ internalType }) => !internalType)
                    .map(({ id, orderId, deliveredAt, undeliverable }) => ({
                      id,
                      orderId,
                      delivered: Boolean(deliveredAt),
                      undeliverable
                    }))
                )}
                maxPopoverWidth={500}
                minPopoverWidth={300}
                getMap={() => isAdmin ? (
                  <Map
                    directionsResult={shipment.directionsResult}
                    shipment={shipment}
                    height={300}
                    deps={{ google: window.google }}
                  />
                ) : null}
                showAsPopover={isAdmin}
              />
              {tags.map((value, index) => (
                <Tooltip key={index} title={value.tooltip}>
                  <Tag style={{ width: 28, height: 24, display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: 5, marginRight: 0 }}>
                    <span className='material-icons' style={{ fontSize: 15 }}>{value.icon}</span>
                  </Tag>
                </Tooltip>
              ))}
            </div>
          )
        }}
      />
      <Table.Column
        title='Status'
        dataIndex='currentStatus'
        key='currentStatus'
        render={(_, { currentStatus, isShipmentLocked, scheduledPostAt, scheduledPostEnabled, linkedParentType, orders, postErrors, broadcastErrors, shipmentRate }) => {
          const ordersArray = convertOrdersToArray(orders)

          const errors = [
            ...(Array.isArray(postErrors) ? postErrors : []),
            ...(Array.isArray(broadcastErrors) ? broadcastErrors : [])
          ]
            .filter(({ acknowledged }) => !acknowledged)

          const routeIncentiveData = shipmentRate?.incentive ? {
            incentive: shipmentRate.incentive,
            incentivesOffered: (shipmentRate?.shipmentContext?.completedRules?.length || 0),
            totalIncentives: shipmentRate?.rateCard?.sequencedIncentiveRules?.length
          } : null

          return (
            <ShipmentStatus.Extended
              status={currentStatus}
              isAdmin={isAdmin}
              routeIncentiveData={routeIncentiveData}
              scheduledPostAt={scheduledPostAt}
              scheduledPostEnabled={scheduledPostEnabled}
              linkedParentType={linkedParentType}
              numberOfUndeliverableItems={ordersArray.filter(({ deliveredAt, undeliverable, misdelivered }) => deliveredAt && undeliverable).length}
              numberOfMisdeliveredItems={ordersArray.filter(({ deliveredAt, undeliverable, misdelivered }) => deliveredAt && (misdelivered && isAdmin)).length}
              numberOfDeliveredItems={ordersArray.filter(({ deliveredAt, undeliverable, misdelivered }) => deliveredAt && !undeliverable && !(misdelivered && isAdmin)).length}
              numberOfProblemItems={ordersArray.filter(({ deliveredAt, undeliverable, misdelivered }) => deliveredAt && (undeliverable || (isAdmin && misdelivered))).length}
              totalItems={ordersArray.length}
              isShipmentLocked={isShipmentLocked}
              errors={errors}
            />
          )
        }}
      />
      {isAdmin ? (
        <Table.Column
          title='Merchant'
          dataIndex='cachedMerchantName'
          key='cachedMerchantName'
          render={(_, { merchantId, cachedMerchantName }) => (
            <React.Fragment>
              <RouteLink
                routeKey='Merchant Dashboard'
                params={{ id: merchantId }}
              >{cachedMerchantName}</RouteLink>
              <Button
                icon='filter'
                size='small'
                type='dashed'
                style={{ marginLeft: 5, width: 18, height: 18, fontSize: 11 }}
                onClick={() => {
                  setSearchFilters({ ...searchFilters, currentMerchantId: merchantId, currentMerchantName: cachedMerchantName, pageNum: 1 })
                }}
              />
            </React.Fragment>
          )}
        />
      ) : null}
      {isAdmin && showDriverColumn ? (
        <Table.Column
          title='Driver'
          dataIndex='cachedDriverName'
          key='cachedDriverName'
          render={(_, { driverId, cachedDriverName }) => (
            driverId ? (
              <React.Fragment>
                <RouteLink
                  routeKey='Driver Dashboard'
                  params={{ id: driverId }}
                >{cachedDriverName}</RouteLink>
                <Button
                  icon='filter'
                  size='small'
                  type='dashed'
                  style={{ marginLeft: 5, width: 18, height: 18, fontSize: 11 }}
                  onClick={() => {
                    setSearchFilters({ ...searchFilters, currentDriverId: driverId, currentDriverName: cachedDriverName, pageNum: 1 })
                  }}
                />
              </React.Fragment>
            ) : (
              'N/A'
            )
          )}
        />
      ) : null}
      {preferredDateField === 'scheduledPostAt' ? (
        <Table.Column
          title='Scheduled For'
          dataIndex='scheduledPostAtFormatted'
          key='scheduledPostAtFormatted'
        />
      ) : preferredDateField === 'postedAt' ? (
        <Table.Column
          title='Posted On'
          dataIndex='postedAtFormatted'
          key='postedAtFormatted'
        />
      ) : preferredDateField === 'completedAt' ? (
        <Table.Column
          title='Completed On'
          dataIndex='completedAtFormatted'
          key='completedAtFormatted'
        />
      ) : preferredDateField === 'driverAcceptedAt' ? (
        <Table.Column
          title='Accepted On'
          dataIndex='driverAcceptedAtFormatted'
          key='driverAcceptedAtFormatted'
        />
      ) : preferredDateField === 'driverPickedUpAt' ? (
        <Table.Column
          title='Picked Up On'
          dataIndex='driverPickedUpAtFormatted'
          key='driverPickedUpAtFormatted'
        />
      ) : (
        <Table.Column
          title='Created On'
          dataIndex='createdAtFormatted'
          key='createdAtFormatted'
        />
      )}

      <Table.Column
        title='Total Cost'
        dataIndex='shipment'
        key='shipment'
        render={(shipment, record, index) => {
          const hasDiscountsApplied = record.shipmentRate && record.shipmentRate.discount

          return Number.isFinite(shipment.cachedTotal)
            ? (
              <Popover
                title='Total Cost Breakdown'
                trigger='click'
                placement='bottom'
                content={(<ShipmentRateBreakdownFromShipment shipment={shipment} isAdmin={isAdmin} mode='shipment' />)}
              >
                <a href='#'>{getFormattedMonetaryValueString(shipment.cachedTotal)}{hasDiscountsApplied ? <Icon type='tag' style={{ marginLeft: 3 }} theme='twoTone' twoToneColor='#FFA500' /> : null}</a>
              </Popover>
            )
            : '(Unknown)'
        }}
      />
      {/* <Table.Column
        title='Extra info'
        dataIndex='extraInfo'
        key='extraInfo'
        render={(_, record) => {
          const tags = []

          if (record.requirements && record.requirements.legalAgeAlcohol) {
            tags.push('Alcohol')
          }

          if (record.requirements && record.requirements.perishable) {
            tags.push('Perishable')
          }

          if (record.requirements && record.requirements.returnReusables) {
            tags.push('Reusables')
          }

          return (
            <div style={{ display: 'flex' }}>
              {tags.map((value) => (
                <Tag key={value} style={{ height: 24, lineHeight: '22px', marginLeft: 5, marginRight: 0 }}>{value}</Tag>
              ))}
            </div>
          )
        }}
      /> */}
      {isAdmin ? (
        <Table.Column
          title='Driver Earn'
          dataIndex='shipment'
          key='driverEarn'
          render={(shipment, record, index) => {
            return Number.isFinite(shipment.cachedPenaltyDriverCompensation || shipment.cachedDriverCompensation)
              ? (
                <Popover
                  title='Driver Earn Breakdown'
                  trigger='click'
                  placement='bottom'
                  content={(<ShipmentRateBreakdownFromShipment shipment={shipment} isAdmin={isAdmin} mode='driver' />)}
                >
                  <a href='#'>{getFormattedMonetaryValueString(shipment.cachedPenaltyDriverCompensation || shipment.cachedDriverCompensation)}</a>
                </Popover>
              )
              : '(Unknown)'
          }}
        />
      ) : null}
      {isAdmin ? (
        <Table.Column
          title='City'
          dataIndex='pickupAddressServiceCity'
          key='pickupAddressServiceCity'
        />
      ) : null}
    </Table>
  )
}

ShipmentList.propTypes = {
  isAdmin: PropTypes.bool,
  dataSource: PropTypes.array,
  isLoading: PropTypes.bool,
  preferredDateField: PropTypes.string,
  showDriverColumn: PropTypes.bool,
  onSelectionChange: PropTypes.func,
  selectedRowKeys: PropTypes.array,
  searchFilters: PropTypes.object.isRequired,
  setSearchFilters: PropTypes.func.isRequired,
  style: PropTypes.object,
  deps: PropTypes.exact({
    send: PropTypes.func,
    RouteLink: PropTypes.func
  }).isRequired
}

function DeliveryCalendar ({
  isAdmin,
  shipments,
  startDate,
  endDate
}) {
  const groupedDeliveries = shipments
    .filter((shipment) => shipment.scheduledPostAt)
    .map((shipment) => ({ ...shipment, scheduledPostDate: ('toDate' in shipment.scheduledPostAt ? shipment.scheduledPostAt.toDate() : new Date(shipment.scheduledPostAt)) }))
    .reduce((memo, shipment) => {
      const orders = convertOrdersToArray(shipment.orders)
        .filter(({ internalType }) => !internalType)

      const hourOnlyDate = new Date(shipment.scheduledPostDate)
      hourOnlyDate.setHours(hourOnlyDate.getHours(), 0, 0, 0)
      const timestamp = hourOnlyDate.getTime()

      memo[timestamp] = [...(memo[timestamp] || []), ...orders.map((order) => ({ orderId: order.orderId, merchantName: shipment.cachedMerchantName }))]

      return memo
    }, {})

  const sortedGroups = Object.entries(groupedDeliveries)
    .map(([timestamp, orders]) => ({ timestamp: Number(timestamp), orders }))
    .sort(sorter('timestamp', { direction: 'asc' }))

  const peakOrderCount = sortedGroups.reduce((memo, { orders }) => {
    return orders.length > memo ? orders.length : memo
  }, 0)

  const cellRender = (groups, getLabel, {
    labelMinWidth = 30,
    labelMarginRight = 0,
    orderCountMinWidth = 20,
    orderCountMarginLeft = 0
  } = {}) => {
    return groups.map(({ timestamp, orders }) => {
      const label = getLabel(timestamp)

      const content = (
        <ul style={{ paddingLeft: 16, marginBottom: 0 }}>{Object.entries(orders
          .reduce((memo, { merchantName }) => (
            { ...memo, [merchantName]: (memo[merchantName] || 0) + 1 }
          ), {}))
          .map(([merchantName, orderCount], index) => (
            <li key={index}><strong>{orderCount}</strong>{isAdmin ? ` (${merchantName})` : ''}</li>
          ))
        }</ul>
      )

      return (
        <Popover key={timestamp} content={content}>
          <div style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginBottom: 3 }}>
            <span style={{ minWidth: labelMinWidth, marginRight: labelMarginRight, fontSize: 10, lineHeight: '10px', whiteSpace: 'nowrap' }}>
              {label}
            </span>
            <div style={{ width: '100%' }}>
              <div
                style={{
                  width: `${Math.round(orders.length / peakOrderCount * 100)}%`,
                  height: 5,
                  borderRadius: 4,
                  backgroundColor: '#1890ff'
                }}
              />
            </div>
            <span style={{ minWidth: orderCountMinWidth, marginLeft: orderCountMarginLeft, marginRight: 2, fontSize: 10, lineHeight: '10px', textAlign: 'right', whiteSpace: 'nowrap' }}>
              {orders.length}
            </span>
          </div>
        </Popover>
      )
    })
  }

  const monthCellRender = (value) => {
    const groups = sortedGroups
      .filter(({ timestamp }) => value.isSame(new Date(timestamp), 'month'))

    const getLabel = (timestamp) => getFormattedDateAndTimeFromDate(new Date(Number(timestamp)), { showMinutes: false })

    return cellRender(groups, getLabel, {
      labelMinWidth: 'none',
      labelMarginRight: 5,
      orderCountMinWidth: 'none',
      orderCountMarginLeft: 5
    })
  }

  const dateCellRender = (value) => {
    const groups = sortedGroups
      .filter(({ timestamp }) => value.isSame(new Date(timestamp), 'day'))

    const getLabel = (timestamp) => getFormattedTimeFromDate(new Date(Number(timestamp)), { showMinutes: false })

    return cellRender(groups, getLabel)
  }

  return (
    <Calendar
      monthCellRender={monthCellRender}
      dateCellRender={dateCellRender}
      validRange={[moment(startDate), moment(endDate)]}
    />
  )
}

DeliveryCalendar.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  shipments: PropTypes.array.isRequired,
  startDate: PropTypes.any.isRequired,
  endDate: PropTypes.any.isRequired
}
